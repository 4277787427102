import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import React from 'react';

interface BadgeCartProps extends IconButtonProps {
  counter: number;
}

const BadgeCart: React.FC<BadgeCartProps> = ({ counter, icon, ...rest }: BadgeCartProps) => {
  return (
    <IconButton
      css={css`
        position: relative !important;
      `}
      variant='ghost'
      {...rest}
      icon={
        <>
          {icon}
          {counter > 0 && (
            <Box
              as={'span'}
              color={'white'}
              position={'absolute'}
              top={'-5px'}
              right={'-6px'}
              minW='20px'
              fontSize={'12px'}
              fontWeight='400'
              bgColor='jubelio.primary'
              rounded={'full'}
              zIndex={100}
              p={'3px'}
            >
              {counter}
            </Box>
          )}
        </>
      }
    />
  );
};

export default BadgeCart;
