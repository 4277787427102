import { ISalesman } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * @description Get all salesmen
   * @returns {Promise<ISalesman[]>}
   * @example await salesmen.get()
   */
  get: async (): Promise<ISalesman[]> => {
    const salesmen = await db.salesmen.toArray();
    return salesmen;
  },
  /**
   * @description Get a salesmen by id
   * @param {number} id
   * @returns {Promise<ISalesman>}
   * @example await salesmen.findOne(1)
   */
  findOne: async (id: number): Promise<ISalesman> => {
    const salesmen = await db.salesmen.where('id').equals(id).first();
    return salesmen;
  },
  /**
   * @description delete a salesmen by id
   * @param {number} id
   * @returns {Promise<void>}
   * @example await salesmen.delete(1)
   */
  delete: async (id: number): Promise<void> => {
    return db.salesmen.delete(id);
  },
  /**
   * @description Create a new salesmen
   * @param {ISalesman} salesmen
   * @returns {Promise<ISalesman>}
   * @example await salesmen.create({ name: 'John Doe', email: 'daasda' })
   */
  add: async (salesman: ISalesman): Promise<boolean> => {
    await db.salesmen.add(salesman);
    return true;
  },
  /**
   * @description clear all salesmen from database
   * @returns {Promise<void>}
   * @example await salesmen.clear()
   */
  clear: async (): Promise<void> => {
    return db.salesmen.clear();
  },
  /**
   * @description update a salesmen by id
   * @param {number} id
   * @param {ISalesman} salesmen
   * @returns {Promise<ISalesman>}
   * @example await salesmen.update(1, { name: 'John Doe', email: 'daasda' })
   */
  update: async (salesman: ISalesman): Promise<boolean> => {
    await db.salesmen.update(salesman.salesmen_id, salesman);
    return true;
  },
  filter: async (keyword: string): Promise<ISalesman[]> => {
    return db.salesmen
      .filter((customer) => {
        const search = new RegExp(keyword.toLowerCase());
        return (
          search.test(customer.salesmen_name ? customer.salesmen_name.toLowerCase() : '') ||
          search.test(customer.email ? customer.email.toLowerCase() : '')
        );
      })
      .toArray();
  },
};
