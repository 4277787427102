import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { ExclamationIcon } from 'components/icons';
import { SubTitle, Title } from 'components/typography';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import React from 'react';
import { DeviceCodeForm } from 'types/common.types';
import { RegisterFragment } from 'types/register.types';

import { Modal } from '.';

interface ModalDeviceCodeProps {
  isOpen: boolean;
  isLoading: boolean;
  register?: RegisterFragment;
  onClose: () => void;
  onSubmit: (code: string) => void;
}

const ModalDeviceCode: React.FC<ModalDeviceCodeProps> = ({
  isOpen,
  isLoading,
  register,
  onClose,
  onSubmit,
}: ModalDeviceCodeProps) => {
  const { notification } = useNotification();
  const [show, setShow] = React.useState(false);

  const initialValues: DeviceCodeForm = {
    code: null,
  };

  const validate = (values: DeviceCodeForm) => {
    const errors: Record<string, string> = {};

    if (!values.code) {
      errors.code = 'Harap masukan Kode Perangkat';
    }

    return errors;
  };

  const onSave = async (values: DeviceCodeForm) => {
    try {
      onSubmit(values.code ?? '');
    } catch (error) {
      notification('', 'Terjadi kesalahan, silahkan coba lagi', 'error');
    }
  };

  return (
    <Modal isShow={isOpen} onClose={onClose}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSave}>
        {({ values, errors, handleBlur, setFieldValue, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <VStack w='full' spacing={5} pb={5}>
                <ExclamationIcon h='46px' w='46px' />
                <VStack w='full' spacing={1} textAlign='center' alignItems='center'>
                  <Title>Konfirmasi Akses Mesin Kasir</Title>
                  <Title>
                    {register?.register_code} - {register?.register_name}
                  </Title>
                  <SubTitle>
                    Saat ini Anda mencoba mengakses register dari perangkat baru. Untuk melanjutkan, Anda
                    memerlukan kode akses dari Admin atau Manajer Anda.
                  </SubTitle>
                </VStack>
              </VStack>
              <Divider />
              <VStack spacing={4} w='full' mt={4}>
                <FormControl
                  isInvalid={Boolean(errors.code && touched.code)}
                  display='flex'
                  alignItems='center'
                >
                  <VStack w='full' alignItems='flex-start'>
                    <InputGroup size='md'>
                      <Input
                        id='code'
                        type={show ? 'text' : 'password'}
                        name='code'
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setFieldValue('code', e.target.value);
                          }
                        }}
                        placeholder='Masukkan Kode'
                        maxLength={6}
                        value={values?.code ?? ''}
                      />
                      <InputRightElement width='3rem'>
                        <IconButton
                          aria-label='password'
                          h='2rem'
                          variant='ghost'
                          _hover={{ bg: 'none' }}
                          _focus={{ bg: 'none', outline: 'none' }}
                          size='sm'
                          onClick={() => setShow(!show)}
                          icon={
                            show ? (
                              <ViewOffIcon h='20px' w='20px' color='#8999A5' />
                            ) : (
                              <ViewIcon h='20px' w='20px' color='#8999A5' />
                            )
                          }
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{touched.code && errors.code}</FormErrorMessage>
                  </VStack>
                </FormControl>
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <HStack spacing={2}>
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  variant='outline'
                  ml={2}
                  isDisabled={isLoading}
                >
                  Batal
                </Button>
                <Button
                  variant='primary'
                  px={5}
                  ml={3}
                  type='submit'
                  loadingText='Harap tunggu'
                  isDisabled={isLoading}
                  isLoading={isLoading}
                >
                  Konfirmasi Akses
                </Button>
              </HStack>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(ModalDeviceCode);
