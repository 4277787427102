import { Box, Select, useMediaQuery } from '@chakra-ui/react';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import TableBopis from 'components/table/TableBopis';
import React from 'react';
import { IDetailTransaction, ReadyToPickFragment } from 'types/bopis.types';

interface UnprocessedBopisProps {
  search: string;
  setSearch: (key: string, value: string) => void;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  setDetailOrder: (detailOrder: IDetailTransaction) => void;
  onlineTransaction?: ReadyToPickFragment;
  tabIndex: number;
  filter: string;
  setFilter: (key: string, value: string) => void;
}

const UnprocessedBopis: React.FC<UnprocessedBopisProps> = ({
  search,
  setSearch,
  page,
  pageSize,
  setPage,
  setPageSize,
  setDetailOrder,
  onlineTransaction,
  tabIndex,
  filter,
  setFilter,
}: UnprocessedBopisProps) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');
  const paginationRef = React.useRef<any>();

  const paginateMemo = React.useMemo(() => {
    return onlineTransaction?.data;
  }, [page, pageSize, search, onlineTransaction, filter]);

  return (
    <>
      <Box pb={2}>
        <div className='flex items-center gap-3 pb-2'>
          <SearchBar
            placeholder='Temukan Transaksi'
            onSearch={(e) => setSearch('unprocessed', e.target.value)}
            disableEnter
          />
          <Select
            w='auto'
            fontSize={isLargeScreen ? '14px' : '12px'}
            size={isLargeScreen ? 'md' : 'sm'}
            rounded='md'
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFilter('unprocessed', e.target.value);
            }}
          >
            <option value=''>Semua</option>
            <option value='instant'>Kurir Instant</option>
          </Select>
        </div>
      </Box>
      <Box position='relative' bg='white' rounded='lg' w='full'>
        <TableBopis
          isLoading={true}
          listTransaction={paginateMemo}
          setOrder={setDetailOrder}
          search={search}
          tabIndex={tabIndex}
        />
      </Box>
      <Pagination
        refPage={paginationRef}
        onChangePage={(e) => setPage(e.selected + 1)}
        totalPage={Number(onlineTransaction?.totalCount)}
        pageSize={pageSize}
        page={page}
        onChangeRow={(e) => {
          setPage(1);
          setPageSize(e);
        }}
        p='20px'
        position='absolute'
      />
    </>
  );
};
export default React.memo(UnprocessedBopis);
