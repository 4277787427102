import clsx from 'clsx';
import React from 'react';

type SideBarProps = {
  className?: string;
  children: React.ReactNode;
} & React.ComponentPropsWithRef<'aside'>;

const SideBar: React.FC<SideBarProps> = ({ children, className, ...rest }: SideBarProps) => {
  return (
    <aside
      className={clsx(
        'fixed z-10 flex h-screen w-[98px] flex-col items-center border-r border-gray-100 bg-white',
        className
      )}
      {...rest}
    >
      {children}
    </aside>
  );
};
export default SideBar;
