import { Box, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { StructSetting } from 'types/common.types';
import { maskingDataCustomer } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

type SubHeaderProps = {
  structSetting: StructSetting | null;
  printData: DetailOrderTransaction | null;
  fullName: string | null;
  isReturn?: boolean;
};

const SubHeader: React.FC<SubHeaderProps> = ({ structSetting, printData, fullName, isReturn }) => {
  const returnMode = useAppSelector((state) => state.return.returnMode);

  return (
    <Box mt={3}>
      <Text>
        {structSetting?.transaction_no_label}:{' '}
        {!isReturn ? printData?.salesorder_no : printData?.pos_return_no}
      </Text>
      <Text>
        {structSetting?.cashier_label}: {fullName}
      </Text>
      {structSetting?.show_salesman && !returnMode && <Text>Salesman: {printData?.salesmen_name}</Text>}
      <Text>
        {structSetting?.date_label}: {convertToLocalDate((printData?.transaction_date as Date) || new Date())}
      </Text>
      {structSetting?.show_customer_information && (
        <div>
          {structSetting &&
          structSetting.show_customer_name &&
          !structSetting.show_email &&
          !structSetting.show_phone_number ? (
            <Text>{`${structSetting?.customer_label}: ${printData?.customer_name ?? 'Pelanggan Umum'}`}</Text>
          ) : (
            <>
              {structSetting?.show_customer_name && (
                <Text>{`${structSetting?.customer_label}: ${
                  printData?.customer_name ?? 'Pelanggan Umum'
                }`}</Text>
              )}
              {structSetting?.show_email && structSetting?.show_masking_email ? (
                <Text>
                  Email: {maskingDataCustomer(printData?.customer?.email || printData?.contact_email || '-')}
                </Text>
              ) : (
                structSetting?.show_email && (
                  <Text>Email: {printData?.customer?.email || printData?.contact_email || '-'}</Text>
                )
              )}
              {structSetting?.show_phone_number && structSetting?.show_masking_phone_number ? (
                <Text>
                  Telp: {maskingDataCustomer(printData?.customer?.phone || printData?.contact_phone || '-')}
                </Text>
              ) : (
                structSetting?.show_phone_number && (
                  <Text>Telp: {printData?.customer?.phone || printData?.contact_phone || '-'}</Text>
                )
              )}
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default SubHeader;
