import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Input } from 'components/forms';
import { ShippingCostConfirmationBopis } from 'components/popover/ShippingCostConfirmationBopis';
import { ShippingInsurance } from 'components/popover/ShippingInsurance';
import ListShipment from 'components/ui/transaction/ListShipment';
import { alert } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import Select from 'react-select';
import shipmentRequest from 'services/http/shipment.request';
import courier from 'services/indexdb/courier';
import customTheme from 'theme/select-theme';
import { Maybe } from 'types';
import { IDetailTransaction, IShippingBopis, Items } from 'types/bopis.types';
import { ICourier } from 'types/common.types';
import { IGetRatesData, IProvince, IRegionList, IServiceCategoryData } from 'types/shipment.types';
import { currencyFormat, debounce } from 'utils';

interface ModalAddressProps {
  isOpen: boolean;
  onClose: () => void;
  detailOrder: IDetailTransaction;
  updateShipping: (detailTransaction: IDetailTransaction, values: IShippingBopis) => void;
  openShippingOption: () => void;
  setDetailOrder: (order: IDetailTransaction) => void;
}

const ModalCourierOptionBopis: React.FC<ModalAddressProps> = ({
  isOpen,
  onClose,
  detailOrder,
  updateShipping,
  openShippingOption,
  setDetailOrder,
}: ModalAddressProps) => {
  const { notification } = useNotification();
  const {
    onOpen: onOpenShippingCostConfirm,
    onClose: onCloseShippingCostConfirm,
    isOpen: isOpenShippingCostConfirm,
  } = useDisclosure();
  const shippingInfo = useAppSelector((state) => state.sales.shippingInfo);
  const settings = useAppSelector((state) => state.commons.settings);
  const location = useAppSelector((state) => state.register.location);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const [shipmentCategoryList, setShipmentCategoryList] = React.useState<Maybe<IServiceCategoryData[]>>(null);
  const [shipmentList, setShipmentList] = React.useState<IGetRatesData[]>([]);
  const [selectedShipment, setSelectedShipment] = React.useState<IGetRatesData>({} as IGetRatesData);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [regionList, setRegionList] = React.useState<IRegionList | null>(null);
  const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);

  const mutateRegions = async (e: string) => {
    if (isOnline) {
      const region = await shipmentRequest.getRegionList(e);
      setRegionList(region);
    }
  };

  const mapRegionList =
    regionList && regionList.data.length > 0
      ? ((regionList?.data.map((i) => ({
          value: i,
          label: i.name,
        })) || []) as unknown as IProvince[])
      : [];

  const validateShippingCost = (values: IShippingBopis) => {
    if ((!values.service_category || values.service_category === 0) && (values.use_jubelio_shipment ?? false))
      return notification('', alert.miss_service_category_shipment, 'warning', 5000);
    if ((!values.courier || values.courier === '') && (values.use_jubelio_shipment ?? false))
      return notification('', alert.miss_courier_shipment, 'warning', 5000);
    if ((values.shipping_cost ?? 0) <= 0) onOpenShippingCostConfirm();
    else onSave(values);
  };

  const onClosePopup = () => {
    onClose();
    setShipmentList([]);
    setSelectedShipment({} as IGetRatesData);
    setRegionList(null);
  };

  const onSave = async (values: IShippingBopis) => {
    setLoadingSubmit(true);
    const newValues = {
      ...values,
      insurance_cost: !values.shipping_insurance ? 0 : values.insurance_cost,
    };
    await updateShipping(detailOrder, newValues);
    onClosePopup();
    setDetailOrder({} as IDetailTransaction);
    setLoadingSubmit(false);
  };

  const getTotalWeight = (items: Items[]) => {
    return items.reduce((total, item) => {
      const weight = Number(item.weight_in_gram);
      const quantity = Number(item.qty_in_base);
      return total + weight * quantity;
    }, 0);
  };

  const getShipmentList = async (
    service: number,
    values: IShippingBopis,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    try {
      setIsLoading(false);
      if (location && (!location.subdistrict_id || location.subdistrict_id === '')) {
        setIsLoading(true);
        return notification('', alert.miss_subdistrict, 'warning', 5000);
      }

      if (location && (!location.post_code || location.post_code === '')) {
        setIsLoading(true);
        return notification('', alert.miss_postcode, 'warning', 5000);
      }

      if (!values.shipping_subdistrict_id || values.shipping_subdistrict_id === '') {
        setIsLoading(true);
        return notification('', alert.miss_subdistrict_recipient, 'warning', 5000);
      }

      if (!values.shipping_post_code || values.shipping_post_code === '') {
        setIsLoading(true);
        return notification('', alert.miss_postcode_recipient, 'warning', 5000);
      }

      const totalWeight = getTotalWeight(detailOrder.items);

      const payload = {
        origin: {
          area_id: location?.subdistrict_id ?? '',
          zipcode: location?.post_code ?? '',
        },
        destination: {
          area_id: values.shipping_subdistrict_id ?? '',
          zipcode: values.shipping_post_code ?? '',
        },
        weight: Number(totalWeight) > 0 ? totalWeight : 1,
        service_category_id: service,
        total_value: Number(detailOrder.sub_total ?? 0),
      };
      const res = await shipmentRequest.getRates(payload);
      const shipment = res.data.length > 0 ? res.data[0] : ({} as IGetRatesData);
      const findShipment =
        res.data &&
        res.data.find(
          (shipment) => shipment.courier_service_name.concat(' (Jubelio Shipment)') === shippingInfo?.courier
        );
      setSelectedShipment(findShipment ?? shipment);
      if (setFieldValue) {
        setFieldValue('shipping_cost', findShipment ? findShipment?.rates : shipment.rates);
        setFieldValue(
          'insurance_cost',
          findShipment ? findShipment?.shipping_insurance : shipment.shipping_insurance
        );
        setFieldValue(
          'courier',
          findShipment
            ? findShipment.courier_service_name.concat(' (Jubelio Shipment)')
            : (shipment?.courier_service_name ?? '').concat(' (Jubelio Shipment)')
        );
        setFieldValue('service_category', Number(service));
      }
      setShipmentList(res.data);
      setIsLoading(true);
    } catch (error: any) {
      setShipmentList([]);
      let message = 'Unknown Error';
      if (error) message = error.response.data.code;
      setIsLoading(true);
      return notification('', message, 'warning', 5000);
    }
  };

  const getServiceCategoryList = async () => {
    try {
      const res = await shipmentRequest.getServiceCategory();
      setShipmentCategoryList(res.data);
    } catch (error: any) {
      setShipmentCategoryList(null);
      let message = 'Unknown Error';
      if (error) message = error.response.data.message;

      return notification('', message, 'warning', 5000);
    }
  };

  const listCourier = useLiveQuery<ICourier[]>(() => {
    return courier.getAll();
  }, []);

  const optionCourier = listCourier?.map((item) => {
    return { value: item.id, label: item.text };
  });

  const initialValues: IShippingBopis = {
    shipping_full_name: detailOrder.shipping_full_name,
    shipping_phone: detailOrder.shipping_phone ?? '',
    shipping_address: detailOrder.shipping_address ?? '',
    shipping_area: detailOrder.shipping_area ?? '',
    shipping_city: detailOrder.shipping_city ?? '',
    shipping_city_id: detailOrder.shipping_city_id ?? '',
    shipping_district_id: detailOrder.shipping_district_id ?? '',
    shipping_province: detailOrder.shipping_province ?? '',
    shipping_province_id: detailOrder.shipping_province_id ?? '',
    shipping_post_code: detailOrder.shipping_post_code ?? '',
    shipping_subdistrict: detailOrder.shipping_subdistrict ?? '',
    shipping_subdistrict_id: detailOrder.shipping_subdistrict_id ?? '',
    shipping_cost: Number(detailOrder.shipping_cost ?? 0),
    shipping_insurance: detailOrder.use_shipping_insurance ?? false,
    insurance_cost: Number(detailOrder.insurance_cost ?? 0),
    courier: detailOrder.courier ?? '',
    service_category: 0,
    total_value: Number(detailOrder.sub_total ?? 0),
    use_jubelio_shipment: false,
    shipping_area_id: 0,
    shipment_promotion_id: Number(detailOrder.shipment_promotion_id ?? 0),
  };

  const validate = (values: IShippingBopis) => {
    const errors: Record<string, string> = {};
    if (!values.shipping_full_name) {
      errors.shipping_full_name = 'Harap masukan nama penerima';
    }
    if (!values.shipping_phone) {
      errors.shipping_phone = 'Harap masukan no. telepon';
    } else {
      if (values.shipping_phone.length < 11) errors.shipping_phone = 'No. telepon minimal 11 karakter';
    }
    if (!values.shipping_province_id) {
      errors.shipping_province_id = 'Harap pilih propinsi';
    }
    if (!values.shipping_city_id) {
      errors.shipping_city_id = 'Harap pilih kota';
    }
    if (!values.shipping_district_id) {
      errors.shipping_district_id = 'Harap pilih kecamatan';
    }
    if (!values.shipping_subdistrict_id) {
      errors.shipping_subdistrict_id = 'Harap pilih kelurahan';
    }
    if (!values.shipping_post_code) {
      errors.shipping_post_code = 'Harap masukkan kode pos';
    }
    if (!values.shipping_address) {
      errors.shipping_address = 'Harap masukkan detail alamat';
    }
    if (!values.use_jubelio_shipment) {
      if (!values.courier) {
        errors.courier = 'Harap pilih kurir';
      }
    }

    return errors;
  };

  const inputChangeRegion = debounce(async (e: string) => {
    if (e) mutateRegions(e);
  }, 500);

  React.useEffect(() => {
    if (isOpen) {
      getServiceCategoryList();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClosePopup} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={validateShippingCost}
          enableReinitialize={true}
        >
          {(formikProps) => {
            const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } =
              formikProps;
            return (
              <Form onSubmit={handleSubmit}>
                <ModalCloseButton />
                <ModalHeader>Ubah Alamat</ModalHeader>
                <ModalBody
                  borderTop='1px'
                  borderBottom='1px'
                  borderColor='gray.200'
                  py={4}
                  overflowX='auto'
                  overflowY='auto'
                  maxHeight='450px'
                >
                  <Box>
                    <FormControl alignItems='center'>
                      <FormLabel w='full' fontSize='14px'>
                        Informasi Alamat
                      </FormLabel>
                    </FormControl>
                    <VStack spacing={4}>
                      <FormControl
                        isInvalid={Boolean(errors.shipping_province_id && touched.shipping_province_id)}
                      >
                        <Select
                          inputId='shipping_province_id'
                          placeholder='--Pilih Alamat--'
                          options={mapRegionList || []}
                          styles={customTheme}
                          menuPlacement='auto'
                          onChange={(e: any) => {
                            const province = e?.value ? e?.value.area_id.substring(0, 2) : '';
                            const city = e?.value ? e?.value.area_id.substring(0, 4) : '';
                            const district = e?.value ? e?.value.area_id.substring(0, 6) : '';
                            setFieldValue('shipping_province', e?.value.province);
                            setFieldValue('shipping_province_id', province);
                            setFieldValue('shipping_city', e?.value.city);
                            setFieldValue('shipping_city_id', city);
                            setFieldValue('shipping_district_id', district);
                            setFieldValue('shipping_area', e?.value.district);
                            setFieldValue('shipping_area_id', e?.value.area_id);
                            setFieldValue('shipping_subdistrict_id', e?.value.area_id);
                            setFieldValue('shipping_subdistrict', e?.value.area);
                            setFieldValue('shipping_post_code', e?.value.zipcode);
                          }}
                          onInputChange={(e) => {
                            if (e) inputChangeRegion(e);
                          }}
                          onBlur={handleBlur}
                          value={mapRegionList?.filter(
                            (option: any) => option.value.area_id === values.shipping_area_id
                          )}
                          isDisabled={!isOnline}
                        />
                        <FormErrorMessage>
                          {touched.shipping_province_id && errors.shipping_province_id}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        display='flex'
                        className='space-x-[5px]'
                        alignItems='center'
                        isInvalid={Boolean(errors.shipping_address && touched.shipping_address)}
                      >
                        <VStack w='full' alignItems='flex-start'>
                          <Textarea
                            id='shipping_address'
                            name='shipping_address'
                            value={values.shipping_address ?? ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Masukkan detail alamat'
                          />
                          <FormErrorMessage>
                            {touched.shipping_address && errors.shipping_address}
                          </FormErrorMessage>
                        </VStack>
                      </FormControl>
                      <FormControl
                        alignItems='center'
                        isInvalid={Boolean(errors.shipping_phone && touched.shipping_phone)}
                      >
                        <VStack w='full' alignItems='flex-start'>
                          <Input
                            id='shipping_phone'
                            name='shipping_phone'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.shipping_phone}
                            placeholder='Masukkan no. telepon'
                          />
                          <FormErrorMessage>
                            {touched.shipping_phone && errors.shipping_phone}
                          </FormErrorMessage>
                        </VStack>
                      </FormControl>
                    </VStack>
                    <FormControl alignItems='center' mt={10}>
                      <FormLabel w='full' fontSize='14px'>
                        Informasi Kurir
                      </FormLabel>
                    </FormControl>
                    <VStack spacing={4}>
                      {settings.jubelio_shipment_active && settings.jubelio_shipment_active === true && (
                        <Checkbox
                          size='md'
                          colorScheme='red'
                          isChecked={values.use_jubelio_shipment ?? false}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('use_jubelio_shipment', e.target.checked);
                            setFieldValue('shipping_cost', e.target.checked ? values.shipping_cost : 0);
                            setFieldValue('courier', e.target.checked ? values.courier : '');
                            setFieldValue('insurance_cost', e.target.checked ? values.insurance_cost : 0);
                            setFieldValue('service_category', e.target.checked ? values.service_category : 0);
                          }}
                          w='100%'
                        >
                          Gunakan Jubelio Shipment
                        </Checkbox>
                      )}
                      {values.use_jubelio_shipment &&
                      values.use_jubelio_shipment === true &&
                      settings.jubelio_shipment_active &&
                      settings.jubelio_shipment_active === true ? (
                        <>
                          <FormControl display='flex' className='space-x-[5px]' alignItems='center'>
                            <FormLabel w='92px' htmlFor='shipping-category' fontSize='14px'>
                              Kategori Pengiriman
                            </FormLabel>
                            <RadioGroup
                              onChange={(e: string) => {
                                getShipmentList(Number(e), values, setFieldValue);
                              }}
                              value={String(values.service_category ?? 0)}
                              style={{ marginLeft: 0 }}
                            >
                              <SimpleGrid columns={[1, 3]} gap='5px'>
                                {shipmentCategoryList &&
                                  shipmentCategoryList.map((item: IServiceCategoryData, index: number) => (
                                    <Radio
                                      colorScheme='red'
                                      value={String(item.service_category_id)}
                                      key={index}
                                      fontSize={12}
                                      paddingBottom='7px'
                                    >
                                      {item.name}
                                    </Radio>
                                  ))}
                              </SimpleGrid>
                            </RadioGroup>
                          </FormControl>
                          {!isLoading && (
                            <Stack w='full'>
                              <Skeleton isLoaded={false} height='80px'></Skeleton>
                              <Skeleton isLoaded={false} height='80px'></Skeleton>
                            </Stack>
                          )}
                          {shipmentList && shipmentList.length > 0 && isLoading && (
                            <VStack
                              h='full'
                              overflowY='auto'
                              maxH='calc(100vh - 260px)'
                              spacing={3}
                              w='full'
                              marginTop={8}
                            >
                              {shipmentList?.map((item, index) => (
                                <ListShipment
                                  shipment={item}
                                  key={index}
                                  disabled={false}
                                  isActive={item.courier_id === selectedShipment.courier_id}
                                  onClick={() => {
                                    setFieldValue('shipping_cost', item.final_rates);
                                    if (item.promotion)
                                      setFieldValue('shipment_promotion_id', item.promotion.promotion_id);
                                    setFieldValue(
                                      'courier',
                                      item.courier_service_name.concat(' (Jubelio Shipment)')
                                    );
                                    setSelectedShipment(item);
                                  }}
                                />
                              ))}
                            </VStack>
                          )}
                          {shipmentList && shipmentList.length === 0 && isLoading && (
                            <Skeleton isLoaded={isLoading}>
                              <Text fontSize='14px' color='jubelio.black' textAlign='center' my={10}>
                                Tidak ada layanan pengiriman
                              </Text>
                            </Skeleton>
                          )}
                        </>
                      ) : (
                        <FormControl
                          display='flex'
                          alignItems='center'
                          isInvalid={Boolean(errors.courier && touched.courier)}
                        >
                          <VStack w='full' alignItems='flex-start'>
                            <Box w='full'>
                              <Select
                                placeholder='--Pilih Kurir--'
                                options={optionCourier || []}
                                menuPlacement='auto'
                                onChange={(e: any) => {
                                  setFieldValue('courier', e?.value);
                                }}
                                onBlur={handleBlur}
                                value={optionCourier?.filter(
                                  (option: any) => String(option.value) === String(values?.courier)
                                )}
                              />
                            </Box>
                            <FormErrorMessage>{touched.courier && errors.courier}</FormErrorMessage>
                          </VStack>
                        </FormControl>
                      )}
                      <FormControl alignItems='center'>
                        <Input
                          isgrouped
                          labelGroup='Rp'
                          format='currency'
                          value={values.shipping_cost ?? 0}
                          onChange={(e: any) => {
                            setFieldValue('shipping_cost', e);
                          }}
                          onBlur={handleBlur}
                          placeholder='Masukkan ongkos kirim'
                        />
                      </FormControl>
                      {values?.use_jubelio_shipment &&
                      values?.use_jubelio_shipment === true &&
                      settings.jubelio_shipment_active &&
                      settings.jubelio_shipment_active === true &&
                      Number(selectedShipment.shipping_insurance ?? 0) > 0 ? (
                        <FormControl style={{ marginTop: 10 }}>
                          <HStack w='full' verticalAlign={'middle'}>
                            <Checkbox
                              size='md'
                              colorScheme='red'
                              isChecked={values?.shipping_insurance ?? false}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('shipping_insurance', e.target.checked);
                                setFieldValue('insurance_cost', selectedShipment.shipping_insurance ?? 0);
                              }}
                            >
                              Asuransi Pengiriman ({' '}
                              {currencyFormat(Number(selectedShipment.shipping_insurance ?? 0))} )
                            </Checkbox>
                            <ShippingInsurance />
                          </HStack>
                        </FormControl>
                      ) : null}
                    </VStack>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      onClosePopup();
                      openShippingOption();
                    }}
                    variant='outline'
                    isDisabled={loadingSubmit}
                    mr={3}
                  >
                    Kembali
                  </Button>
                  <ShippingCostConfirmationBopis
                    isOpen={isOpenShippingCostConfirm}
                    onClose={onCloseShippingCostConfirm}
                    shippingCost={Number(values.shipping_cost ?? 0)}
                    values={values}
                    onSubmit={onSave}
                    loadingSubmit={loadingSubmit}
                  />
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ModalCourierOptionBopis;
