import { IPresets } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * This method is used to create a new preset.
   *
   * @param {string} type - The type of preset.
   * @param {string} data - The data of the preset.
   * @returns {Promise<void>}
   * @example presets.addPreset('preset', [{type: 'Tax', data: 10000}])}]);
   */
  addPresets: async (type: string, data: IPresets): Promise<boolean> => {
    try {
      switch (type) {
        case 'TAX':
          await db.presetstax.add(data);
          break;
        case 'DISCOUNT':
          await db.presetsdiscount.add(data);
          break;
      }

      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },

  /**
   * This method is used to get all discounts presets.
   * @returns {Promise<IPresets>}
   * @example with async - await presets.getDiscount()
   * @example presets.getDiscount().then(data => console.log(data))
   */
  getAll: async (tableName: string): Promise<IPresets[]> => {
    try {
      return db.table(tableName).toArray();
    } catch (error: any) {
      return error;
    }
  },

  clear: async (type: string): Promise<boolean> => {
    try {
      switch (type) {
        case 'TAX':
          await db.table('presetstax').clear();
          break;
        case 'DISCOUNT':
          await db.table('presetsdiscount').clear();
          break;
      }

      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },
};
