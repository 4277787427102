import { Button, Flex, FlexProps, Image } from '@chakra-ui/react';
import { SearchBar } from 'components/forms';
import config from 'constant';
import React from 'react';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

interface HeaderProps extends FlexProps {
  isLoading: boolean;
  onSearch: (e: string) => void;
  onScan: (e: string) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { isLoading, onSearch, onScan, ...rest } = props;
  const [value, setValue] = React.useState<string>('');
  return (
    <Flex {...rest} alignItems='center' borderBottom='1px' borderColor='gray.100'>
      <Image
        height={['30px', '30px', '30px', '40px', '40px']}
        loading='lazy'
        objectFit='cover'
        src={`${config.IMAGE_URL}?token=${LocalStorageService.getItem('token')}`}
      />

      <SearchBar
        placeholder='Cari barang disini'
        onSearch={(e) => setValue(e.target.value)}
        onEnter={() => onScan(value)}
        size='sm'
        mx='1.5rem'
      />
      <Button
        variant='primary'
        isDisabled={value === '' || isLoading}
        size='sm'
        onClick={() => onSearch(value)}
      >
        {isLoading ? 'Mencari...' : 'Cari Barang'}
      </Button>
    </Flex>
  );
};

export default Header;
