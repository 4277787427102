import { Flex, FlexProps } from '@chakra-ui/react';
import { StoreIcon } from 'components/icons';
import React from 'react';

interface Props extends FlexProps {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}

const LocationItem: React.FC<Props> = (props: Props) => {
  const { children, active, onClick } = props;
  return (
    <Flex
      alignItems='center'
      p={1}
      py={2}
      bg={active ? 'jubelio.red100' : ''}
      color={active ? 'jubelio.primary' : 'gray.700'}
      fontWeight={active ? 'bold' : 'normal'}
      onClick={onClick}
      rounded='4px'
      fontSize={['12px', '12px', '12px', '14px', '14px']}
      _hover={{ cursor: 'pointer' }}
    >
      <StoreIcon h='23px' w='23px' mr={4} isactive={active ? true : false} />
      {children}
    </Flex>
  );
};
export default LocationItem;
