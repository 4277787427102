import commonAction from 'services/http/common.request';

type InitChatProps = {
  initChat: (
    res: boolean,
    customConfig: boolean,
    email: string,
    companyName: string,
    fullName: string,
    companyId: number,
    restoreId: string | null
  ) => Promise<boolean>;
};

const useInitChat = (): InitChatProps => {
  const updateRestoreId = async (email: string, restore_id: string, company_id: number) => {
    try {
      const response = await commonAction.updateRestoreId(email, restore_id, company_id);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const initChat = async (
    res: boolean,
    customConfig: boolean,
    email: string,
    companyName: string,
    fullName: string,
    companyId: number,
    restoreId: string | null
  ): Promise<boolean> => {
    if (res) {
      if (window && window.fcWidget) {
        window.fcWidget.destroy();
        const obj = {
          token: 'a29d9925-507c-4cda-9c83-a45a82700422',
          host: 'https://cdn.wchat.freshchat.com',
          tags: ['jubelio_pos'],
          config: customConfig
            ? {
                cssNames: {
                  widget: 'fc_custom',
                },
                headerProperty: {
                  backgroundColor: '#c80e49',
                  foregroundColor: '#fff',
                  hideChatButton: true,
                  direction: 'ltr',
                },
              }
            : {
                headerProperty: {
                  backgroundColor: '#DE1A56',
                },
              },
          externalId: `${email} AT ${companyName}`, // user’s id unique to your system
          restoreId: restoreId ? restoreId : null,
        };

        const userPayload = {
          firstName: fullName,
          email: email,
          subscription: 'none',
          phone: 'none',
          companyName: companyName,
          version: 'pos',
        };

        window.fcWidget.init(obj);
        window.fcWidget.on('user:created', (response: any) => {
          const { status, data } = response;
          if (status === 200) {
            if (data.restoreId) {
              updateRestoreId(email, data.restoreId, companyId);
            }
          }
        });
        window.fcWidget.user.get((response: any) => {
          const { status } = response;
          if (status !== 200) {
            window.fcWidget.user.setProperties(userPayload);
          }
        });

        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  };

  return { initChat };
};

export default useInitChat;
