import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import BadgeError from 'components/badge/BadgeError';
import React from 'react';
import { Maybe } from 'types';

interface ModalRequestProps {
  isOpen: boolean;
  onClose: () => void;
  orderNo: string;
  request: string;
  updateStatus: () => void;
  errorMessage: string;
  saveRequest: (request: string) => void;
  isLoading: boolean;
  showStatusSyncButton?: boolean;
  showResendButton?: boolean;
}

const ModalRequest: React.FC<ModalRequestProps> = ({
  isOpen,
  onClose,
  orderNo,
  request,
  updateStatus,
  errorMessage,
  saveRequest,
  isLoading,
  showStatusSyncButton = true,
  showResendButton = false,
}: ModalRequestProps) => {
  const [copied, setCopied] = React.useState<boolean>(false);
  const [dataRequest, setDataRequest] = React.useState<Maybe<string>>(null);
  const toast = useToast();
  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopied(true);
      toast({
        title: 'Copied to clipboard.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      setCopied(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          onClick={() => {
            setCopied(false);
            onClose();
          }}
        />
        <ModalHeader className='py-3 text-sm'>{orderNo}</ModalHeader>
        <ModalBody
          borderTop='1px'
          borderBottom='1px'
          borderColor='gray.200'
          py={4}
          overflowX='auto'
          overflowY='auto'
          minHeight='450px'
        >
          <Box>
            {errorMessage && errorMessage !== null && (
              <BadgeError mb='12px' colorScheme='red' w='full' py={3} px={5}>
                <strong>Error!</strong>
                <br />
                <Text>{errorMessage}.</Text>
                <Text>
                  Kemungkinan data <strong>&quot;null&quot;</strong> atau tidak sesuai. Perbaiki
                  data sendiri atau copy dan kirim request ini ke Customer Support kami.
                </Text>
              </BadgeError>
            )}
            <VStack spacing={4}>
              <FormControl alignItems='center'>
                <FormLabel w='92px' htmlFor='address' fontSize='14px'>
                  Request
                </FormLabel>
                <Textarea
                  id='address'
                  name='s_address'
                  rows={15}
                  defaultValue={request}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setDataRequest(e.target.value)
                  }
                />
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          {showResendButton || (errorMessage && errorMessage !== null) ? (
            <Button
              variant='outline'
              onClick={() => saveRequest(dataRequest ?? request)}
              disabled={!showResendButton && (!dataRequest || isLoading)}
              isLoading={isLoading}
              loadingText={'Harap Tunggu...'}
              px={5}
              mr={3}
            >
              Kirim Ulang
            </Button>
          ) : null}
          {showStatusSyncButton && errorMessage && errorMessage !== null ? (
            <Button variant='outline' onClick={updateStatus}>
              Ubah Status
            </Button>
          ) : null}
          <Button
            variant='primary'
            onClick={() => copyToClipBoard(request)}
            px={5}
            ml={3}
            leftIcon={copied ? <CheckIcon /> : <CopyIcon />}
          >
            Copy
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalRequest;
