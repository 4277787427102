import { Box, Text } from '@chakra-ui/react';
import { Divider } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { ILocations } from 'types/register.types';
import { currencyFormat } from 'utils';

import Footer from './Footer';
import Header from './Header';
import Rows from './Rows';
import SubHeader from './SubHeader';

export const PrintStruct: React.FC = () => {
  const { structSetting, printData } = useAppSelector((state) => state.commons);
  const { location } = useAppSelector((state) => state.register);
  const { profile } = useAppSelector((state) => state.auth);

  return (
    <Box width='264px' m='auto' className='print' px='12px' mb={10}>
      {/* Header */}
      <Header structSetting={structSetting} location={location as ILocations} />
      {/* SubHeader */}
      <SubHeader
        structSetting={structSetting}
        printData={printData}
        fullName={profile?.user.full_name as string}
      />
      <Text textAlign='center' fontWeight='bold' my={2}>
        TEST PRINT
      </Text>

      <Divider />
      <Box mb={1}>
        <Text fontWeight='bold'>Produk Test</Text>
        <Text>BRG-TEST</Text>
        {/* Items Prices */}
        <Rows
          leftText={`${Number(1)} @ ${currencyFormat(10000)}`}
          rightText={currencyFormat(1 * 10000)}
        />
        <Divider />
      </Box>
      <Rows fontWeight='bold' leftText={`Total (1)`} rightText={currencyFormat(10000)} />
      <Divider />
      <Box mt={1}>
        <Rows leftText='Kas' rightText={currencyFormat(10000)} />
      </Box>
      <Divider />
      <Footer structSetting={structSetting} />
    </Box>
  );
};

class ComponentTestPrint extends React.Component {
  render(): JSX.Element {
    return <PrintStruct />;
  }
}

export default ComponentTestPrint;
