import { useGetClosure } from 'hooks';
import returnRequest from 'services/http/return.request';
import salesRequest from 'services/http/sales.request';
import { db } from 'services/indexdb/connection';
import returnOrder from 'services/indexdb/return-order';
import { DetailOrderTransaction } from 'types';
import { IOrderReturn, IResponseOrderReturn } from 'types/return.types';
import { IOrderError } from 'types/sales.types';
import { mappingLocalReturnItems, mappingOrderReturnData } from 'utils/return';

import { useAppSelector } from './redux';

type useReturnProps = {
  sendOrderReturn: (orderReturn: IOrderReturn) => Promise<DetailOrderTransaction>;
  sendOrderReturnError: (
    orderReturn: IOrderReturn & { errorMessage: string | undefined }
  ) => Promise<IOrderError>;
  cancelReturn: (
    detailsReturn: DetailOrderTransaction,
    authorizedUser?: number
  ) => Promise<DetailOrderTransaction>;
};

const useReturn = (): useReturnProps => {
  const { listItemReturn, listPayments, customer, sendStructByEmail } = useAppSelector(
    (state) => state.return
  );
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { getClosure } = useGetClosure();

  const sendOrderReturn = async (orderReturn: IOrderReturn): Promise<DetailOrderTransaction> => {
    let errorMessage = ''; // message error;
    const payload = mappingOrderReturnData(orderReturn);
    const isWmsMigrated = localStorage.getItem('is_pos_wms');
    // Placeholder for returnId and invoiceId
    const res = {
      returnId: 0,
      invoiceId: { invoiceId: 0, syncQty: false },
      contact: {
        contact_id: orderReturn.customer_id ?? 0,
        store_credit: '0.0000',
        contact_name: orderReturn.customer_name ?? '',
        email: orderReturn.customer_email ?? '',
        is_loyalty_member: false,
        is_dropshipper: false,
        contact_type: 0,
        category_id: 0,
      },
    };

    await updateDatabaseAndContact(payload, res, orderReturn);

    if (isOnline) {
      getClosure().then(async (resClosure) => {
        if (!resClosure) return;
        try {
          payload.is_send_email = orderReturn.is_send_email ? orderReturn.is_send_email : sendStructByEmail;
          returnRequest
            .sendReturn(payload, isWmsMigrated ?? 'pos')
            .then(async (response) => {
              console.log('[INFO] Return created successfully');
              await updateDatabaseAndContact(payload, response, orderReturn);
            })
            .catch((error) => {
              errorMessage = error?.response?.data?.code ?? error?.response?.data.message;
              updateDatabaseAndContact(payload, res, orderReturn, errorMessage);
            });
        } catch (error: any) {
          errorMessage = error?.response?.data?.code ?? error?.response?.data.message;
          updateDatabaseAndContact(payload, res, orderReturn, errorMessage);
        }
      });
    } else {
      updateDatabaseAndContact(
        payload,
        {
          returnId: 0,
          invoiceId: {
            invoiceId: 0,
            syncQty: false,
          },
          contact: {
            contact_id: 0,
            store_credit: '0.0000',
            contact_name: '',
            email: null,
            is_loyalty_member: false,
            is_dropshipper: false,
            contact_type: 0,
            category_id: 0,
          },
        },
        orderReturn
      );
    }

    const dataRetur = await db.return.where('pos_return_no').equals(orderReturn.pos_return_no).first();

    return dataRetur;
  };

  const updateDatabaseAndContact = async (
    payload: IOrderReturn,
    res: IResponseOrderReturn,
    orderReturn: IOrderReturn,
    errorMessage = ''
  ) => {
    const mappingFinalPayments = listPayments.map((item) => {
      const findPayment = payload?.payments?.find((payment) => payment.payment_id === item.payment_id);
      if (findPayment) {
        return {
          ...item,
          payment_charge: findPayment.payment_charge,
          payment_amount: findPayment.payment_amount,
        };
      }
      return item;
    });

    const notPaid = !isOnline || res?.returnId === 0 ? 0 : 1;
    const isSending = res.returnId === 0 ? false : true;
    const isSendingEmail = payload.is_send_email ? payload.is_send_email : sendStructByEmail;
    const newReturn = Object.assign({}, payload, {
      pos_return_id: res?.returnId ?? 0,
      is_paid: notPaid,
      is_sending: isSending,
      is_return: 1,
      is_canceled: payload.is_canceled ? 1 : 0,
      is_send_email: isSendingEmail,
      hasRetur: false,
      returnMode: true,
      request_payload: JSON.stringify(payload),
      payments: mappingFinalPayments,
      total_tax: Math.abs(payload.total_minus_tax ?? 0),
      items: mappingLocalReturnItems(listItemReturn),
      contact_email: orderReturn.is_update_email === true ? orderReturn.customer_email : customer?.email,
      errorMessage,
    });

    try {
      const checkOrder = await db.order
        .where('salesorder_no')
        .equals(payload.salesorder_no as string)
        .first();

      if (checkOrder) {
        await db.order
          .where('salesorder_no')
          .equals(payload.salesorder_no as string)
          .modify({
            is_return: 1,
            hasRetur: true,
            pos_return_id: res?.returnId ?? 0,
            pos_return_no: payload.pos_return_no ?? '',
            return_canceled: false,
            errorMessage,
          });
      }

      const checkReturn = await db.return
        .where('pos_return_no')
        .equals(payload.pos_return_no ?? '')
        .first();

      if (checkReturn) {
        await db.return
          .where('pos_return_no')
          .equals(payload.pos_return_no ?? '')
          .modify({
            is_paid: notPaid,
            is_sending: isSending,
            salesorder_id: checkReturn.salesorder_id ?? 0,
            errorMessage,
          });
      } else {
        await returnOrder.add(newReturn);
      }

      if (res?.contact) await saveContact(payload, res as IResponseOrderReturn);
    } catch (error: any) {
      db.return
        .where('pos_return_no')
        .equals(orderReturn.pos_return_no ?? '')
        .modify({ errorMessage: errorMessage.concat(error.response?.data?.message ?? '') });
    }
  };

  const saveContact = async (payload: IOrderReturn, res: IResponseOrderReturn) => {
    await db.return
      .where('pos_return_no')
      .equals(payload.pos_return_no ?? '')
      .modify({
        pos_return_id: res?.returnId ?? 0,
        is_canceled: payload.is_canceled ?? false,
      });

    await db.customer
      .where('contact_id')
      .equals(res?.contact.contact_id)
      .modify({ store_credit: res?.contact.store_credit });
  };

  const cancelReturn = async (detailsReturn: DetailOrderTransaction): Promise<DetailOrderTransaction> => {
    try {
      const requestPayload = detailsReturn.request_payload
        ? JSON.parse(detailsReturn.request_payload)
        : detailsReturn;

      const returnPayload = {
        ...requestPayload,
        is_canceled: !isOnline ? 1 : true,
        authorized_user_id: detailsReturn.authorized_user_id,
        is_web: true,
      };

      if (isOnline) {
        await returnRequest.cancelReturn(
          Number(detailsReturn.pos_return_id),
          detailsReturn.authorized_user_id,
          returnPayload.is_web
        );
      }

      await db.return
        .where('pos_return_no')
        .equals(detailsReturn?.pos_return_no)
        .modify({
          is_canceled: !isOnline ? 1 : true,
          is_web: true,
          request_payload: JSON.stringify(returnPayload),
          authorized_user_id: detailsReturn.authorized_user_id,
        });

      const checkOrder = await db.order
        .where('salesorder_id')
        .equals(detailsReturn?.salesorder_id ?? 0)
        .first();

      if (checkOrder) {
        await db.order
          .where('salesorder_id')
          .equals(detailsReturn?.salesorder_id ?? 0)
          .modify({
            is_return: 0,
            hasRetur: false,
            return_canceled: true,
            pos_return_id: null,
            pos_return_no: null,
          });
      }

      const orderReturn = await db.return.where('pos_return_no').equals(detailsReturn.pos_return_no).first();

      return Promise.resolve(orderReturn);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const sendOrderReturnError = async (orderReturn: IOrderReturn & { errorMessage: string | undefined }) => {
    try {
      const paramOrderError = {
        order_error_id: 0,
        store_id: -1,
        ref_no: orderReturn.pos_return_no ?? '',
        error_message: orderReturn?.errorMessage ?? '',
        order_data: JSON.stringify(orderReturn),
      };
      let res;
      if (isOnline) {
        res = await salesRequest.sendErrorOrder(paramOrderError);
      }
      return Promise.resolve(res ?? paramOrderError);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { sendOrderReturn, cancelReturn, sendOrderReturnError };
};

export default useReturn;
