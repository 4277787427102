import { IPromotions } from 'types/promotion.types';

import { db } from './connection';

export default {
  /**
   * Get all promotions from IndexDB
   *
   * @returns {Promise<IPromotions[]>}
   * @example await promotions.get();
   */
  get: async (): Promise<IPromotions[]> => {
    return db.promotion.toArray();
  },
  /**
   * save promotion to IndexDB
   *
   * @return {Promise<number>}
   * @example await promotions.add(promotion);
   */
  add: async (promotion: IPromotions): Promise<number> => {
    return db.promotion.add(promotion);
  },
  /**
   * Bulk save promotions to IndexDB
   * @param {IPromotions[]} promotions
   * @returns {Promise<number>}
   */
  bulkAdd: async (promotions: IPromotions[]): Promise<number> => {
    return db.promotion.bulkAdd(promotions);
  },
  /**
   * Clear all promotion from indexDB
   * @returns {Promise<void>}
   * @example await promotions.clear();
   */
  clear: async (): Promise<void> => {
    return db.promotion.clear();
  },

  /**
   * Update promotion in IndexDB from backoffice in realtime
   * if promotion is not exist in IndexDB, add it
   * @param {IPromotions[]} promotions
   * @returns {Promise<void>}
   */
  updatePromotion: async (promotions: IPromotions): Promise<void> => {
    const exists = await db.promotion
      .where('promotion_id')
      .equals(promotions.promotion_id)
      .toArray();
    if (exists.length === 0) {
      await db.promotion.add(promotions);
    } else {
      await db.promotion.where('promotion_id').equals(promotions.promotion_id).modify(promotions);
    }

    return Promise.resolve();
  },
};
