import { Avatar } from '@chakra-ui/react';
import config from 'constant';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

const StoreInformation: React.FC = () => {
  const { registerInfo, location } = useAppSelector((state) => state.register);
  const { profile } = useAppSelector((state) => state.auth);
  return (
    <div className='w-full p-5'>
      <div className='rounded-md border p-4'>
        <h4 className='text-lg font-bold'>Info Toko</h4>
        <div className='my-5 border'></div>
        <div className='flex gap-10'>
          <Avatar
            h='115px'
            w='115px'
            src={`${config.IMAGE_URL}?token=${LocalStorageService.getItem('token')}`}
          />
          <div className='flex flex-col gap-5'>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Nama Toko</h5>
              <h4 className='text-md font-semibold'>{location?.location_name ?? '-'}</h4>
            </div>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Mesin Kasir</h5>
              <h4 className='text-md font-semibold'>{registerInfo?.register_name ?? '-'}</h4>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Nama Kasir</h5>
              <h4 className='text-md font-semibold'>{profile?.user.full_name}</h4>
            </div>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Email Kasir</h5>
              <h4 className='text-md font-semibold'>{profile?.user.email ?? '-'}</h4>
            </div>
          </div>
        </div>
      </div>
      {/* Address */}
      <div className='mt-5 rounded-md border p-4'>
        <h4 className='text-lg font-bold'>Alamat</h4>
        <div className='my-5 border'></div>
        <div className='grid grid-cols-3 gap-2'>
          <div className='flex flex-col gap-5'>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Kota</h5>
              <h4 className='text-md font-semibold'>{location?.city ?? '-'}</h4>
            </div>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Kode Pos</h5>
              <h4 className='text-md font-semibold'>{location?.post_code ?? '-'}</h4>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Provinsi</h5>
              <h4 className='text-md font-semibold'>{location?.province ?? '-'}</h4>
            </div>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Negara</h5>
              <h4 className='text-md font-semibold'>Indonesia</h4>
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <div className=''>
              <h5 className='text-[14px] text-gray-500'>Alamat Lengkap</h5>
              <p className='text-md font-semibold'>{location?.address ?? '-'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreInformation;
