import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { CircleIcon } from 'components/icons';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { IAnnouncement, IAnnouncementDetail } from 'types/common.types';

import '../../theme/announcement.css';

interface Props {
  announcement: IAnnouncement | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (pos_announcement_id: number) => void;
}

const ModalAnnouncement: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, announcement, onSubmit, ...rest } = props;
  const [activeAnnouncement, setActiveAnnouncement] = React.useState<number>(-1);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...rest}
      isCentered
      size={'3xl'}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          onClick={() => {
            onSubmit(announcement?.pos_announcement_id ?? 0);
          }}
        />
        <ModalHeader>{announcement?.announcement_name}</ModalHeader>
        <ModalBody borderBottom='1px' borderColor='gray.200'>
          <Image
            src={
              activeAnnouncement === -1
                ? announcement?.header_image ?? '/images/no-image-icon2.png'
                : announcement?.details
                ? announcement?.details[activeAnnouncement].header_image
                : '/images/no-image-icon2.png'
            }
            objectFit='cover'
            border='1px'
            height='full'
            borderColor='gray.300'
            rounded={8}
          />
          <HStack mt={6} spacing={8} alignItems='flex-start' mb={6}>
            {announcement?.details && (
              <VStack border='1px' borderColor='gray.300' rounded={8} p={2} spacing={2}>
                {announcement?.details.map((item: IAnnouncementDetail, index: number) => (
                  <Button
                    variant='ghost'
                    onClick={() => {
                      setActiveAnnouncement(index);
                    }}
                    type='submit'
                    key={index}
                    w='250px'
                    justifyContent='flex-start'
                    fontWeight={`${activeAnnouncement === index ? 'bold' : 'normal'}`}
                    backgroundColor={`${activeAnnouncement === index ? 'gray.100' : 'white'}`}
                    _hover={{
                      fontWeight: 'bold',
                      backgroundColor: 'gray.100',
                    }}
                    color='jubelio.black'
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      textAlign: 'left',
                    }}
                  >
                    {item.announcement_detail_name}
                  </Button>
                ))}
              </VStack>
            )}
            <VStack overflowX='auto' maxHeight='200px' spacing={2}>
              <Box>
                <Box mt={4}>
                  <ReactMarkdown
                    children={
                      activeAnnouncement === -1
                        ? String(announcement?.body)
                        : String(
                            announcement?.details && announcement?.details[activeAnnouncement].body
                          )
                    }
                    remarkPlugins={[gfm]}
                    className='reactMarkDown'
                  />
                </Box>
              </Box>
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter justifyContent='space-between'>
          <HStack spacing={3}>
            {announcement?.details &&
              announcement?.details.map((item: IAnnouncementDetail, index: number) => (
                <Box
                  key={item.pos_announcement_detail_id}
                  as='button'
                  onClick={() => {
                    setActiveAnnouncement(index);
                  }}
                >
                  <CircleIcon
                    h='16px'
                    color={`${activeAnnouncement === index ? 'jubelio.primary' : 'gray.200'}`}
                  />
                </Box>
              ))}
          </HStack>
          <HStack spacing={5}>
            {announcement?.details && (
              <Button
                variant='outline'
                size='md'
                onClick={() => {
                  setActiveAnnouncement(activeAnnouncement - 1);
                }}
              >
                Sebelumnya
              </Button>
            )}
            {(announcement?.details && activeAnnouncement + 1 === announcement?.details?.length) ||
            !announcement?.details ? (
              <Button
                variant='primary'
                onClick={() => {
                  onSubmit(announcement?.pos_announcement_id ?? 0);
                }}
                type='submit'
              >
                Tutup
              </Button>
            ) : (
              <Button
                variant='primary'
                onClick={() => {
                  setActiveAnnouncement(activeAnnouncement + 1);
                }}
                type='submit'
              >
                Selanjutnya
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAnnouncement;
