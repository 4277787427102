import { Button, Divider, FormControl, FormLabel, SimpleGrid, Stack, Textarea } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { PAYMENT_TYPE } from 'constant';
import { alert } from 'constant/messages';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { IPayments } from 'types/common.types';
import { IFormPaymentMethod } from 'types/sales.types';

import { Modal } from '.';

interface ModalPaymentProps {
  onClose: () => void;
  isOpen: boolean;
  paymentsMethod: IPayments[];
  listPayments: IPayments[];
  onSave: (paymentsMethod: IFormPaymentMethod) => void;
  duePayments: number;
  isReturn: boolean;
  continuePayment?: boolean;
  remainingPayment?: number;
}

const ModalPayment: React.FC<ModalPaymentProps> = ({
  onClose,
  isOpen,
  paymentsMethod,
  onSave,
  duePayments,
  listPayments,
  isReturn,
  continuePayment,
  remainingPayment,
}: ModalPaymentProps) => {
  const { duePayment, order } = useAppSelector((state) => state.sales);
  const { notification } = useNotification();
  const [data, setData] = React.useState<IFormPaymentMethod>({} as IFormPaymentMethod);

  React.useEffect(() => {
    if (isOpen) {
      setData({
        ...data,
        payment_amount:
          listPayments.length > 0
            ? isReturn
              ? duePayments
              : Math.abs(duePayments)
            : continuePayment
            ? Number(remainingPayment)
            : Number(order.grand_total),
      });
    }
  }, [isOpen]);

  // disable input amount
  const disableInputAmout = React.useCallback(() => {
    const findStoreCredit = paymentsMethod.find((sn) => sn.payment_type === PAYMENT_TYPE.STORE_CREDIT);
    if (findStoreCredit) {
      return true;
    }
    return false;
  }, [paymentsMethod]);

  const savePayment = React.useCallback(() => {
    if (Number(data.payment_amount) > Math.abs(duePayment)) {
      return notification('', alert.error_payment_amount_more_than_due_payment, 'warning', 5000);
    }
    onSave(data);
    setData({} as IFormPaymentMethod);
  }, [data, duePayment]);

  return (
    <Modal isShow={isOpen} onClose={onClose} title='Masukan Bank Yang Digunakan'>
      <Modal.Body>
        <Stack spacing={4}>
          <FormControl display='flex' className='space-x-[10px]' alignItems='center'>
            <FormLabel w='120px' htmlFor='nominal' fontSize='14px'>
              Nominal
            </FormLabel>
            <Input
              isgrouped
              labelGroup='Rp'
              max={100}
              format='currency'
              value={data.payment_amount}
              onChange={(e) => setData({ ...data, payment_amount: Number(e) < 0 ? 0 : e })}
              isDisabled={disableInputAmout()}
            />
          </FormControl>
          <FormControl display='flex' className='space-x-[10px]' alignItems='center'>
            <FormLabel w='120px' htmlFor='ref' fontSize='14px'>
              No Referensi
            </FormLabel>
            <Input
              value={data?.no_ref as string}
              onChange={(e) => setData({ ...data, no_ref: e.target.value })}
            />
          </FormControl>
          <FormControl display='flex' className='space-x-[10px]' alignItems='center'>
            <FormLabel w='120px' htmlFor='ref' fontSize='14px'>
              Note
            </FormLabel>
            <Textarea
              value={data?.notes as string}
              onChange={(e) => setData({ ...data, notes: e.target.value })}
            />
          </FormControl>
        </Stack>
        <Divider my='20px' />
        <SimpleGrid columns={[3]} gap={3} mb={4}>
          {paymentsMethod &&
            paymentsMethod
              .sort((a, b) => (a.payment_name < b.payment_name ? -1 : 1))
              .map((item, index) => (
                <Button
                  key={index}
                  variant={data.payments?.payment_id === item.payment_id ? 'primary' : 'outline'}
                  fontSize='14px'
                  onClick={() => setData({ ...data, payments: item })}
                >
                  {item.payment_name}
                </Button>
              ))}
        </SimpleGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' isDisabled={!data.payments} onClick={savePayment}>
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPayment;
