import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  HStack,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { Input } from 'components/forms';
import { Title } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { Maybe } from 'types';
import { IDetailTransaction } from 'types/bopis.types';

interface ScanResiProps extends BoxProps {
  detailOrder: IDetailTransaction;
  trackingNo: Maybe<string>;
  isResiValid: Maybe<boolean>;
  loadingComponent: Maybe<string>;
  loadingCompleteOrder: boolean;
  setTrackingNo: (trackingNo: Maybe<string>) => void;
  getShipmentOrder: (id: number) => void;
  sendTrackingNo: (trackingNo: string) => void;
  printShippingLabel: (id: number) => void;
  completeOrder: (id: number) => void;
  setIsResiValid: (isResiValid: Maybe<boolean>) => void;
  createShipment: () => void;
}

const ScanResi: React.FC<ScanResiProps> = ({
  detailOrder,
  trackingNo,
  isResiValid,
  loadingComponent,
  loadingCompleteOrder,
  setTrackingNo,
  getShipmentOrder,
  sendTrackingNo,
  printShippingLabel,
  completeOrder,
  setIsResiValid,
  createShipment,
}: ScanResiProps) => {
  const authSelector = useAppSelector((state) => state.auth);

  const updateResi = () => {
    sendTrackingNo(trackingNo ?? '');
  };

  return (
    <>
      <Divider />
      <Title fontSize='13px' textAlign='left' w='full' pt={2}>
        Scan Nomor Resi
      </Title>
      <InputGroup size='sm'>
        <Input
          placeholder='Masukkan nomor resi'
          onChange={(e: any) => setTrackingNo(e.target.value)}
          borderRadius='0px'
          roundedTopLeft='0px'
          roundedTopRight='0px'
          style={{ backgroundColor: 'white' }}
          mt={1}
          value={trackingNo ?? ''}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setTrackingNo(trackingNo ?? '');
            }
          }}
          isDisabled={(isResiValid ?? false) || (detailOrder.store_id ? true : false)}
          size='sm'
        />
        {isResiValid && !detailOrder.store_id && (
          <InputRightElement mt='0.25rem'>
            <Box as='button' onClick={() => setIsResiValid(false)} bgColor='transparent'>
              <EditIcon />
            </Box>
          </InputRightElement>
        )}
      </InputGroup>
      {trackingNo && trackingNo === '' && (
        <Text className='text-token text-success' mt={3} color='red.500' fontSize={14}>
          <CloseIcon /> No Resi tidak ditemukan
        </Text>
      )}
      {trackingNo && trackingNo !== '' && isResiValid && (
        <Text className='text-token text-success' mt={3} color='system.green' fontSize={14}>
          <CheckIcon /> Resi berhasil
        </Text>
      )}
      {trackingNo && trackingNo !== '' && isResiValid ? (
        <HStack w='full' mt={2}>
          <Button
            onClick={() => printShippingLabel(detailOrder.salesorder_id)}
            variant='outline'
            w='full'
            bgColor='white'
            size='sm'
            isLoading={loadingComponent === 'PRINTLABEL'}
          >
            Cetak Label
          </Button>
          <Button
            variant='primary'
            px={5}
            type='submit'
            ml={3}
            onClick={() =>
              authSelector.is_wms_migrated
                ? createShipment()
                : completeOrder(detailOrder.salesorder_id)
            }
            w='full'
            size='sm'
            isLoading={loadingCompleteOrder}
          >
            Selesaikan
          </Button>
        </HStack>
      ) : (
        <HStack w='full' mt={2}>
          {!detailOrder.store_id ? (
            <Button
              onClick={() => updateResi()}
              variant='outline'
              w='full'
              bgColor='white'
              size='sm'
              isLoading={loadingComponent === 'KIRIMRESI'}
            >
              Kirim No Resi
            </Button>
          ) : null}
          <Button
            variant='primary'
            px={5}
            type='submit'
            ml={3}
            onClick={() => getShipmentOrder(detailOrder.salesorder_id)}
            isLoading={loadingComponent === 'AMBILRESI'}
            w='full'
            size='sm'
          >
            Ambil No Resi
          </Button>
        </HStack>
      )}
    </>
  );
};
export default React.memo(ScanResi);
