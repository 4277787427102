import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import clsx from 'clsx';
import { CardMain } from 'components/card';
import { CashRegisterIcon, ChevronRight } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useInitChat from 'hooks/useInitChat';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router-dom';
import { logoutAction } from 'redux/reducer/auth';
import { setFreshchatCustomConfig } from 'redux/reducer/settings';
import authAction from 'services/http/auth.request';

const MainSetting = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ** Redux Store
  const profile = useAppSelector((state) => state.auth.profile);
  const freshchatCustomConfig = useAppSelector((state) => state.commons.freshchatCustomConfig);
  const { isCashier } = useAppSelector((state) => state.register);

  // ** Custom Hooks
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { initChat } = useInitChat();

  const routes = [
    {
      label: 'Info Toko',
      to: 'store-info',
    },
    {
      label: 'Struk',
      to: 'receipt',
    },
    {
      label: 'Transaksi Perangkat',
      to: 'device-transaction',
    },
    {
      label: 'Lainnya',
      to: 'other',
    },
  ];

  const routeList = React.useMemo(() => {
    return isCashier ? routes : routes.filter((route) => route.label !== 'Transaksi Perangkat');
  }, [isCashier]);

  const handleLogout = async () => {
    await authAction.logout();
    dispatch(logoutAction());
    window.location.href = '/';
  };

  React.useEffect(() => {
    if (isOnline) {
      if ((window.fcWidget !== undefined && !window.fcWidget.isInitialized()) || !freshchatCustomConfig) {
        window.fcWidget.destroy();
        setTimeout(() => {
          initChat(
            true,
            false,
            profile?.email ?? '',
            profile?.companies[0].company_name ?? '',
            profile?.full_name ?? '',
            profile?.companies[0].company_id as number,
            profile?.restore_id ? profile?.restore_id : null
          );
        }, 300);
      }
    }
  }, [profile, isOnline]);

  return (
    <div className='relative'>
      <div className='sticky top-0 z-20 flex w-full items-center justify-between bg-white p-4 drop-shadow-sm'>
        <h4 className='font-bold'>Pengaturan</h4>
        <div className=''>
          {!isCashier ? (
            <Button
              variant='outline'
              size='md'
              px={7}
              borderRadius='8px'
              color='jubelio.black'
              leftIcon={<CashRegisterIcon fill='jubelio.grey200' />}
              onClick={() => {
                navigate('/register');
              }}
            >
              Mesin Kasir
            </Button>
          ) : (
            <Button
              variant='outline'
              size='md'
              px={7}
              borderRadius='8px'
              color='jubelio.black'
              leftIcon={<ArrowBackIcon fill='jubelio.grey200' />}
              onClick={() => {
                dispatch(setFreshchatCustomConfig(true));
                navigate('/sales');
              }}
            >
              Kembali
            </Button>
          )}
        </div>
      </div>
      <div className='flex w-full gap-4 p-4'>
        <div className='sticky top-[88px] h-full w-1/3 rounded-card bg-white p-5 shadow-card'>
          <div className='space-y flex flex-col divide-y'>
            {routeList.map((route, index) => (
              <NavLink to={route.to} key={index}>
                {({ isActive }) => (
                  <div
                    className={clsx(
                      isActive && 'text-primary',
                      'flex items-center justify-between py-3 hover:cursor-pointer'
                    )}
                  >
                    <h4 className='text-sm font-bold'>{route.label}</h4>
                    <ChevronRight
                      fill={isActive ? 'jubelio.primary' : '#163A50'}
                      height='24px'
                      width='24px'
                    />
                  </div>
                )}
              </NavLink>
            ))}
            <div
              className='flex items-center justify-between py-3 hover:cursor-pointer'
              onClick={handleLogout}
            >
              <h4 className='text-sm font-semibold text-gray-400'>Log Out</h4>
            </div>
          </div>
        </div>
        <CardMain m={0} w='full' h='full' minH='100vh'>
          {/* this is for nested routes in setting page (info-toko, struk, lainnya, transaksi-device) */}
          <Outlet />
        </CardMain>
      </div>
    </div>
  );
};

export default MainSetting;
