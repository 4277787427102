import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import { Title } from 'components/typography';
import { LIST_ALL_PAYMENT_TYPE } from 'constant';
import { useLiveQuery } from 'dexie-react-hooks';
import { usePayments } from 'hooks';
import React from 'react';
import { IPayments } from 'types/common.types';
import { IGetPromotionData, ResPromotionTransactionAmount } from 'types/promotion.types';

interface Props {
  listPromotionAmount: ResPromotionTransactionAmount[];
  listPromotionItem: IGetPromotionData[];
}

const BoxPromotion: React.FC<Props> = ({ listPromotionAmount, listPromotionItem }: Props) => {
  const [listPromotion, setListPromotion] = React.useState<IGetPromotionData[]>([]);

  const { getListAllPayments } = usePayments();

  const listPayments = useLiveQuery<IPayments[]>(() => {
    return getListAllPayments();
  }, []);

  const paymentList = (paymentId: number[]): string => {
    if (paymentId.length === 0) return '';
    const payment = paymentId.map((payment_id) =>
      listPayments?.find((f) => f.payment_id === payment_id)
    );
    const result = payment
      .map((p) => {
        const paymentName = p?.payment_name;
        const typePayment = LIST_ALL_PAYMENT_TYPE.find((f) => f.payment_type === p?.payment_type);
        let paymentMethod = '';
        if (paymentName) paymentMethod = `${paymentName}-${typePayment?.name}`;
        else paymentMethod = 'Semua Metode Pembayaran';
        return paymentMethod;
      })
      .join(', ');
    return result;
  };

  const mergePromotion = () => {
    let promotionAmount: any = [];
    if (listPromotionAmount.length > 0) {
      const mappingPromotionAmount = listPromotionAmount.map((p) => p.promotion);
      promotionAmount = [...mappingPromotionAmount];
    }
    const mergeArray: IGetPromotionData[] = promotionAmount.concat(listPromotionItem);
    const filterDuplicatePromotion = mergeArray.filter(
      (promo, index, self) => index === self.findIndex((p) => p.promotion_id === promo.promotion_id)
    );
    setListPromotion(filterDuplicatePromotion);
  };

  React.useEffect(() => {
    mergePromotion();
  }, [listPromotionAmount, listPromotionItem]);

  return (
    <Stack pb='16px'>
      <Title>Detail Promosi</Title>
      <Stack
        border='1px'
        borderColor='system.outline'
        rounded='4px'
        p={5}
        fontSize={{ base: '12px', lg: '14px' }}
      >
        <VStack spacing={2} maxHeight='180px' overflowY='auto'>
          {listPromotion.map((p, index) => {
            return (
              <Flex
                w='full'
                alignItems='center'
                borderRadius='md'
                border='1px'
                borderColor='gray.200'
                p='1.5'
                key={index}
              >
                <Box mr='auto' textAlign='left'>
                  <Text fontSize='14px' fontWeight='bold' color='jubelio.black'>
                    {p.promotion_name}
                  </Text>
                  <Text fontSize='12px'>Metode Pembayaran: {paymentList(p.promotion_payment)}</Text>
                </Box>
              </Flex>
            );
          })}
        </VStack>
      </Stack>
    </Stack>
  );
};

export default React.memo(BoxPromotion);
