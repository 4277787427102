import { Box, TabProps, Text, useTab } from '@chakra-ui/react';
import React from 'react';

const CustomRoundedTab: React.FC<TabProps> = (props: TabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  return (
    <Box
      w='full'
      textAlign='center'
      flexGrow={0}
      p={1}
      as='button'
      bg={isSelected ? 'white' : 'transparent'}
      rounded={4}
      h='28px'
      {...tabProps}
    >
      <Text fontSize='12px' color='jubelio.black'>
        {props.children}
      </Text>
    </Box>
  );
};

export default React.memo(CustomRoundedTab);
