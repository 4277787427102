import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Input, Select } from 'components/forms';
import { ShippingInsurance } from 'components/popover/ShippingInsurance';
import { Title } from 'components/typography';
import ListShipment from 'components/ui/transaction/ListShipment';
import { useLiveQuery } from 'dexie-react-hooks';
import { FormikProps } from 'formik';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import shipmentRequest from 'services/http/shipment.request';
import courier from 'services/indexdb/courier';
import { ICourier, IShippingInfo } from 'types/common.types';
import { ISalesState } from 'types/sales.types';
import { IGetRatesData, IProvince, IRegionList, IServiceCategoryData } from 'types/shipment.types';
import { currencyFormat, debounce } from 'utils';

interface IFormShipmentProps {
  isLoading: boolean;
  dataItem: ISalesState;
  shipmentList: IGetRatesData[];
  shipmentCategoryList: IServiceCategoryData[] | null;
  selectedShipment: IGetRatesData;
  formikProps: FormikProps<IShippingInfo>;
  setDataItem: (data: ISalesState) => void;
  getShipmentList: (
    service: number,
    values: IShippingInfo,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
  onSelectShipment: (value: IGetRatesData) => void;
}

const FormShipment: React.FC<IFormShipmentProps> = ({
  dataItem,
  setDataItem,
  isLoading,
  getShipmentList,
  shipmentList,
  shipmentCategoryList,
  onSelectShipment,
  selectedShipment,
  formikProps,
}) => {
  const { shippingInfo } = dataItem;
  const { settings } = useAppSelector((state) => state.commons);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { values, errors, handleBlur, handleChange, touched, setFieldValue } = formikProps;

  const [regionList, setRegionList] = React.useState<IRegionList | null>(null);

  const mutateRegions = async (e: string) => {
    if (isOnline) {
      const region = await shipmentRequest.getRegionList(e);
      setRegionList(region);
    }
  };

  const mapRegionList =
    regionList && regionList.data.length > 0
      ? ((regionList?.data.map((i) => ({
          value: i,
          label: i.name,
        })) || []) as unknown as IProvince[])
      : [];

  const listCourier = useLiveQuery<ICourier[]>(() => {
    return courier.getAll();
  }, []);

  const optionCourier = listCourier?.map((item) => {
    return { value: item.id, label: item.text };
  });

  const inputChangeRegion = debounce(async (e: string) => {
    if (e) mutateRegions(e);
  }, 500);

  React.useEffect(() => {
    if (shippingInfo && shippingInfo.shipping_subdistrict) {
      mutateRegions(shippingInfo.shipping_subdistrict);
    }
  }, [shippingInfo]);

  return (
    <React.Fragment>
      <Flex w='full' justifyContent='space-between' my={2}>
        <Title fontSize='16px'>Pengiriman</Title>
        <FormControl style={{ textAlign: 'right' }}>
          <Checkbox
            size='md'
            colorScheme='red'
            isChecked={dataItem.pos_is_shipping ?? false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDataItem({
                ...dataItem,
                pos_is_shipping: e.target.checked,
              });
            }}
            isDisabled={!isOnline}
          />
        </FormControl>
      </Flex>
      <Accordion defaultIndex={[0]} allowMultiple w='full'>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Informasi Penerima
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <FormControl isInvalid={Boolean(errors.shipping_full_name && touched.shipping_full_name)}>
              <VStack w='full' alignItems='flex-start'>
                <Input
                  id='shipping_full_name'
                  name='shipping_full_name'
                  onChange={handleChange}
                  value={values.shipping_full_name}
                  onBlur={handleBlur}
                  placeholder='Masukkan nama penerima'
                  isDisabled={!dataItem.pos_is_shipping || false}
                />
                <FormErrorMessage>{touched.shipping_full_name && errors.shipping_full_name}</FormErrorMessage>
              </VStack>
            </FormControl>
            <FormControl mt={4} isInvalid={Boolean(errors.shipping_phone && touched.shipping_phone)}>
              <VStack w='full' alignItems='flex-start'>
                <Input
                  id='shipping_phone'
                  name='shipping_phone'
                  onChange={handleChange}
                  value={values.shipping_phone}
                  onBlur={handleBlur}
                  placeholder='Masukkan no. telepon'
                  isDisabled={!dataItem.pos_is_shipping || false}
                />
                <FormErrorMessage>{touched.shipping_phone && errors.shipping_phone}</FormErrorMessage>
              </VStack>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Informasi Alamat
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <FormControl
              alignItems='center'
              isInvalid={Boolean(errors.shipping_province_id && touched.shipping_province_id)}
            >
              <VStack w='full' alignItems='flex-start'>
                <Stack w='full'>
                  <Select
                    inputId='shipping_province_id'
                    placeholder='--Pilih Alamat--'
                    options={mapRegionList || []}
                    menuPlacement='auto'
                    onChange={(e: any) => {
                      const province = e?.value ? e?.value.area_id.substring(0, 2) : '';
                      const city = e?.value ? e?.value.area_id.substring(0, 4) : '';
                      const district = e?.value ? e?.value.area_id.substring(0, 6) : '';
                      setFieldValue('shipping_province', e?.value.province);
                      setFieldValue('shipping_province_id', province);
                      setFieldValue('shipping_city', e?.value.city);
                      setFieldValue('shipping_city_id', city);
                      setFieldValue('shipping_district_id', district);
                      setFieldValue('shipping_area', e?.value.district);
                      setFieldValue('shipping_subdistrict_id', e?.value.area_id);
                      setFieldValue('shipping_subdistrict', e?.value.area);
                      setFieldValue('shipping_post_code', e?.value.zipcode);
                      if (shipmentList) {
                        getShipmentList(
                          1,
                          {
                            ...values,
                            shipping_subdistrict_id: e?.value.area_id,
                            shipping_post_code: e?.value.zipcode,
                          },
                          setFieldValue
                        );
                      }
                    }}
                    onInputChange={(e) => {
                      if (e) inputChangeRegion(e);
                    }}
                    onBlur={handleBlur}
                    value={mapRegionList?.find(
                      (option: any) => option.value.area_id === values.shipping_subdistrict_id
                    )}
                    isDisabled={!isOnline}
                  />
                </Stack>
                <FormErrorMessage>
                  {touched.shipping_province_id && errors.shipping_province_id}
                </FormErrorMessage>
              </VStack>
            </FormControl>
            <FormControl mt={4} isInvalid={Boolean(errors.shipping_address && touched.shipping_address)}>
              <VStack w='full' alignItems='flex-start'>
                <Textarea
                  id='shipping_address'
                  name='shipping_address'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.shipping_address ?? ''}
                  placeholder='Masukkan alamat'
                  isDisabled={!dataItem.pos_is_shipping || false}
                />
                <FormErrorMessage>{touched.shipping_address && errors.shipping_address}</FormErrorMessage>
              </VStack>
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex='1' textAlign='left'>
                Informasi Kurir
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {settings.jubelio_shipment_active && settings.jubelio_shipment_active === true && (
              <Checkbox
                size='md'
                colorScheme='red'
                isChecked={values.use_jubelio_shipment ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('use_jubelio_shipment', e.target.checked);
                  setFieldValue('shipping_cost', e.target.checked ? values.shipping_cost : 0);
                  setFieldValue('courier', e.target.checked ? values.courier : '');
                  setFieldValue('insurance_cost', e.target.checked ? values.insurance_cost : 0);
                  setFieldValue('service_category', e.target.checked ? values.service_category : 0);
                }}
                pb={4}
                isDisabled={!dataItem.pos_is_shipping || false}
              >
                Gunakan Jubelio Shipment
              </Checkbox>
            )}
            {values.use_jubelio_shipment &&
            values.use_jubelio_shipment === true &&
            settings.jubelio_shipment_active &&
            settings.jubelio_shipment_active === true ? (
              <>
                <FormControl>
                  <FormLabel htmlFor='service_category' fontSize='16px'>
                    Kategori Pengiriman
                  </FormLabel>
                  <RadioGroup
                    onChange={(e: string) => {
                      getShipmentList(Number(e), values, setFieldValue);
                    }}
                    value={String(values.service_category || 0)}
                  >
                    <SimpleGrid columns={[1, 3]} gap='5px'>
                      {shipmentCategoryList &&
                        shipmentCategoryList.map((item: IServiceCategoryData, index: number) => (
                          <Radio
                            colorScheme='red'
                            value={String(item.service_category_id)}
                            key={index}
                            fontSize={12}
                            paddingRight='12px'
                            paddingBottom='7px'
                          >
                            {item.name}
                          </Radio>
                        ))}
                    </SimpleGrid>
                  </RadioGroup>
                </FormControl>
                {!isLoading && (
                  <Stack>
                    <Skeleton isLoaded={isLoading} height='80px'></Skeleton>
                    <Skeleton isLoaded={isLoading} height='80px'></Skeleton>
                  </Stack>
                )}
                {shipmentList && shipmentList.length > 0 && isLoading && (
                  <VStack
                    h='full'
                    overflowY='auto'
                    maxH='calc(100vh - 260px)'
                    spacing={3}
                    w='full'
                    marginTop={8}
                  >
                    {shipmentList?.map((item, index) => (
                      <ListShipment
                        shipment={item}
                        key={index}
                        onClick={() => {
                          onSelectShipment(item);
                          setFieldValue('shipping_cost', item.final_rates);
                          if (item.promotion)
                            setFieldValue('shipment_promotion_id', item.promotion.promotion_id);
                          setFieldValue('courier', item.courier_service_name.concat(' (Jubelio Shipment)'));
                        }}
                        isActive={item.courier_id === selectedShipment.courier_id}
                        disabled={false}
                      />
                    ))}
                  </VStack>
                )}

                {shipmentList && shipmentList.length === 0 && isLoading && (
                  <Skeleton isLoaded={isLoading}>
                    <Text fontSize='14px' color='jubelio.black' textAlign='center' my={10}>
                      Tidak ada layanan pengiriman
                    </Text>
                  </Skeleton>
                )}
              </>
            ) : (
              <>
                <FormControl
                  display='flex'
                  alignItems='center'
                  isInvalid={Boolean(errors.courier && touched.courier)}
                >
                  <VStack w='full' alignItems='flex-start'>
                    <Box w='full'>
                      <Select
                        inputId='courier'
                        name='courier'
                        placeholder='--Pilih Kurir--'
                        options={optionCourier || []}
                        menuPlacement='auto'
                        onChange={(e: any) => {
                          setFieldValue('courier', e?.value);
                        }}
                        onBlur={handleBlur}
                        value={optionCourier?.filter(
                          (option: any) => String(option.value) === String(values.courier)
                        )}
                        isDisabled={!dataItem.pos_is_shipping || false}
                      />
                    </Box>
                    <FormErrorMessage>{touched.courier && errors.courier}</FormErrorMessage>
                  </VStack>
                </FormControl>
              </>
            )}
            <FormControl style={{ marginTop: 10 }}>
              <FormLabel htmlFor='service_category' fontSize='16px'>
                Ongkos Kirim
              </FormLabel>
              <VStack w='full' alignItems='flex-start'>
                <Flex w='full'>
                  <Input
                    isgrouped
                    labelGroup='Rp'
                    width='full'
                    format='currency'
                    onChange={(e: any) => {
                      setFieldValue('shipping_cost', e);
                    }}
                    onBlur={handleBlur}
                    value={values.shipping_cost}
                    isDisabled={!dataItem.pos_is_shipping || false}
                    placeholder='Masukkan ongkos kirim'
                  />
                </Flex>
              </VStack>
            </FormControl>
            {values.use_jubelio_shipment &&
            values.use_jubelio_shipment === true &&
            settings.jubelio_shipment_active &&
            settings.jubelio_shipment_active === true &&
            Number(selectedShipment.shipping_insurance ?? 0) > 0 ? (
              <FormControl style={{ marginTop: 10 }}>
                <HStack w='full' verticalAlign={'middle'}>
                  <Checkbox
                    size='md'
                    colorScheme='red'
                    isChecked={values?.shipping_insurance ?? false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('shipping_insurance', e.target.checked);
                      setFieldValue('insurance_cost', selectedShipment.shipping_insurance ?? 0);
                    }}
                    isDisabled={!dataItem.pos_is_shipping || false}
                  >
                    Asuransi Pengiriman ( {currencyFormat(Number(selectedShipment.shipping_insurance ?? 0))} )
                  </Checkbox>
                  <ShippingInsurance />
                </HStack>
              </FormControl>
            ) : null}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </React.Fragment>
  );
};

export default FormShipment;
