import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends IconProps {
  isActive?: boolean;
}

const StoreMarkIcon: React.FC<Props> = (props: Props) => (
  <Icon width='20px' height='20px' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M16.6667 5.00065H3.33333V3.33398H16.6667V5.00065ZM11.9167 10.0007C11.25 10.8007 10.8333 11.8173 10.8333 12.9173C10.8333 13.8673 11.1917 14.884 11.6667 15.834V16.6673H3.33333V11.6673H2.5V10.0007L3.33333 5.83398H16.6667L17.25 8.75065C16.7 8.48398 16.1 8.33398 15.4667 8.33398L15.3 7.50065H4.7L4.2 10.0007H11.9167ZM10 11.6673H5V15.0007H10V11.6673ZM18.3333 12.9173C18.3333 15.084 15.4167 18.334 15.4167 18.334C15.4167 18.334 12.5 15.084 12.5 12.9173C12.5 11.334 13.8333 10.0007 15.4167 10.0007C17 10.0007 18.3333 11.334 18.3333 12.9173ZM16.4167 13.0007C16.4167 12.5007 15.9167 12.0007 15.4167 12.0007C14.9167 12.0007 14.4167 12.4173 14.4167 13.0007C14.4167 13.5007 14.8333 14.0007 15.4167 14.0007C16 14.0007 16.5 13.5007 16.4167 13.0007V13.0007Z'
      fill='#8999A5'
    />
  </Icon>
);

export default StoreMarkIcon;
