import { Box, BoxProps, Divider, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { InputNumber } from 'components/forms';
import { TagIcon } from 'components/icons';
import ListTotal from 'components/list/ListTotal';
import { TooltipBatchNumber } from 'components/popover/BatchNumber';
import { TooltipSerialNumber } from 'components/popover/SerialNumber';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { ItemReturn } from 'types/return.types';
import { IBatchNumber, ISerialNumber } from 'types/sales.types';
import { currencyFormat } from 'utils';

interface CardItemProps extends BoxProps {
  item: ItemReturn;
  returnMode?: number;
}

const CardItem: React.FC<CardItemProps> = ({ item, returnMode, ...rest }: CardItemProps) => {
  return (
    <Box border='1px' borderColor='gray.200' p='12px' w='full' borderRadius='4px' {...rest}>
      <Flex justifyContent='space-between' className='space-x-2' mb='5px' position='relative'>
        <Tooltip label={item.item_name} hasArrow bg='jubelio.black'>
          <p className='max-w-[250px] truncate text-sm font-semibold text-gray-900'>{item.item_name}</p>
        </Tooltip>
        <Title w='40%' textAlign='right' fontSize='14px' color='jubelio.purple'>
          <TagIcon mr={2} fill='jubelio.purple' />
          {currencyFormat(Number(item.price))}
        </Title>
      </Flex>
      <SubTitle fontSize='12px'>{item.item_code}</SubTitle>
      <HStack mt={2}>
        {item.serial_number && <BadgeProduct colorScheme='serial'>Serial</BadgeProduct>}
        {item.batch_number && <BadgeProduct colorScheme='batch'>Batch</BadgeProduct>}
        {item.is_bundle && <BadgeProduct colorScheme='bundle'>Bundle</BadgeProduct>}
        {!item.serial_number && !item.batch_number && !item.is_bundle && (
          <BadgeProduct colorScheme='green'>Satuan</BadgeProduct>
        )}
        {item.serial_number && Array.isArray(item.serial_number) && item.serial_number.length > 0 && (
          <TooltipSerialNumber placement='right' listSn={item.serial_number as ISerialNumber[]} />
        )}
        {item.batch_number && Array.isArray(item.batch_number) && item.batch_number.length > 0 && (
          <TooltipBatchNumber placement='right' listBn={item.batch_number as IBatchNumber[]} />
        )}
      </HStack>
      <VStack w='full' alignItems='start' mt={2}>
        {((item.disc_amount as number) > 0 || (returnMode === 1 && item.disc_amount < 0)) && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText={item?.promotion_name || 'Diskon'}
            rightText={<Text>{`-${currencyFormat(item.disc_amount)}`}</Text>}
          />
        )}
        {Number(item.tax_amount) !== 0 && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText='Pajak'
            rightText={<Text>{currencyFormat(item.tax_amount)}</Text>}
          />
        )}
      </VStack>
      <Divider my={4} bg='gray.200' />
      <HStack spacing={2}>
        <InputNumber value={Math.abs(Number(item.qty_in_base))} size='sm' disabled textAlign='center' />
        <Title w='full' ml='auto' textAlign='right'>
          {returnMode === 1
            ? currencyFormat(Number(item.price) * Math.abs(item.qty_in_base) + Number(item.disc_amount))
            : currencyFormat(Number(item.price) * Math.abs(item.qty_in_base) - Number(item.disc_amount))}
        </Title>
      </HStack>
      {item.notes && (
        <Box w='full' bg='jubelio.cream' mt={3} rounded='4px' p='9px'>
          <Title fontSize='12px' color='jubelio.grey200'>
            Notes
          </Title>
          <SubTitle color='jubelio.black' mt='8px' fontSize='12px'>
            {item.notes}
          </SubTitle>
        </Box>
      )}
    </Box>
  );
};
export default React.memo(CardItem);
