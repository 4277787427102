import { Box, Button, useToast } from '@chakra-ui/react';
import Imagetop from 'assets/images/logo-brand.png';
import { CardMain } from 'components/card';
import FormLogin from 'components/forms/FormLogin';
import { Title } from 'components/typography';
import { ToastVersion } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

import packageJson from '../../../package.json';

const Login = () => {
  const navigate = useNavigate();
  const settings = useAppSelector((state) => state.commons.settings);
  const version = JSON.parse(LocalStorageService.getItem('version'));
  const toast = useToast();
  const toastId = 'toast-version';

  const checkStatus = React.useCallback(() => {
    if (localStorage.getItem('token')) {
      navigate('/register');
    } else {
      navigate('/');
    }
  }, [history, settings]);

  React.useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  React.useEffect(() => {
    if (version !== packageJson.version) {
      if (process.env.NODE_ENV === 'production' && !toast.isActive(toastId)) {
        toast({
          id: toastId,
          duration: 3000, // 15 second
          position: 'top',
          isClosable: true,
          render: () => <ToastVersion onClick={() => window.location.reload()} text='Versi baru tersedia' />,
        });
      }
    }
  }, []);

  return (
    <Box h='100vh'>
      <div className='flex items-center justify-between bg-white px-20 py-5'>
        <img src={Imagetop} alt='logo' className='h-auto w-[120px]' />
        <div className='flex items-center space-x-8'>
          <Title fontSize={['12px', '12px', '12px', '13px', '14px']}>Belum Punya Akun?</Title>
          <Button as='a' href='https://v2.jubelio.com/' target='_blank' px={5} size='sm' variant='outline'>
            Register
          </Button>
        </div>
      </div>
      <CardMain
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        overflowY='auto'
        maxH='calc(100vh - 100px)'
        position='relative'
      >
        <Box pt={10} position='relative'>
          <FormLogin />
        </Box>
      </CardMain>
    </Box>
  );
};

export default Login;
