import { Box, Text } from '@chakra-ui/react';
import { Divider } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { ILocations } from 'types/register.types';
import {
  currencyFormat,
  currencyFormatNotFraction,
  getPaymentCharge,
  getPaymentsName,
  getTotalItemsHistory,
} from 'utils';

import Footer from './Footer';
import Header from './Header';
import ListItem from './ListItem';
import Rows from './Rows';
import SubHeader from './SubHeader';

export const PrintStruct: React.FC = () => {
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const printData = useAppSelector((state) => state.commons.printData);
  const listPromotionsItems = useAppSelector((state) => state.sales.listPromotionsItems);
  const totalDetail = useAppSelector((state) => state.sales.totalDetail);
  const location = useAppSelector((state) => state.register.location);
  const paymentCharge = React.useMemo(() => {
    return getPaymentCharge(printData?.payments ?? []);
  }, [printData]);

  const discountTrx: number =
    Math.abs(totalDetail?.discountTrx ?? 0) + Math.abs(totalDetail?.salesPromotions ?? 0);

  return (
    <Box width='264px' m='auto' className='print' px='12px' mb={10} color='#000' bgColor='white'>
      {/* Header */}
      <Header structSetting={structSetting} location={location as ILocations} />
      {/* SubHeader */}
      <SubHeader
        structSetting={structSetting}
        printData={printData}
        fullName={printData?.cashier_name as string}
        isReturn={printData?.is_return === 1 || printData?.hasRetur}
      />
      {printData?.pos_is_unpaid === true || Number(printData?.pos_is_unpaid) === 1 ? (
        <Text textAlign='center' fontWeight='bold' my={2}>
          BELUM LUNAS
        </Text>
      ) : null}
      {printData?.is_return || printData?.hasRetur ? (
        <Text textAlign='center' fontWeight='bold' my={2}>
          RETUR
        </Text>
      ) : null}
      {printData?.is_canceled || Number(printData?.is_canceled) === 1 ? (
        <Text textAlign='center' fontWeight='bold' my={2}>
          BATAL
        </Text>
      ) : null}

      <Divider />
      {printData && printData?.items?.length > 0
        ? printData?.items?.map((item, index) => (
            <ListItem
              key={index}
              structSetting={structSetting}
              item={item}
              promotionItems={listPromotionsItems}
            />
          ))
        : null}
      <Box mt={1}>
        {/* Subtotal Line */}
        <Rows
          leftText={structSetting?.subtotal_label}
          rightText={
            structSetting?.show_two_digit_fraction
              ? currencyFormat(Number(printData?.sub_total))
              : currencyFormatNotFraction(Number(printData?.sub_total))
          }
        />
        {/* Discount Items Line */}
        {printData && Number(printData.total_disc) > 0 ? (
          <Rows
            leftText='Diskon Barang'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Number(printData?.total_disc))
                : currencyFormatNotFraction(Number(printData?.total_disc))
            }
          />
        ) : null}
        {/* Outlet Discount */}
        {totalDetail && totalDetail.discountOutlet > 0 ? (
          <Rows
            leftText='Diskon Outlet'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Number(totalDetail.discountOutlet))
                : currencyFormatNotFraction(Number(totalDetail.discountOutlet))
            }
          />
        ) : null}
        {/* Show only in History transaction */}
        {!totalDetail && (Number(printData?.service_fee) !== 0 || Number(printData?.add_disc) !== 0) ? (
          <Rows
            leftText='Diskon Transaksi'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(
                    Math.abs(
                      Number(printData?.service_fee) > 0 || Number(printData?.service_fee) !== 0
                        ? Number(printData?.service_fee)
                        : Number(printData?.add_disc)
                    )
                  )
                : currencyFormatNotFraction(
                    Math.abs(
                      Number(printData?.service_fee) > 0 || Number(printData?.service_fee) !== 0
                        ? Number(printData?.service_fee)
                        : Number(printData?.add_disc)
                    )
                  )
            }
          />
        ) : null}
        {/* Show only if created new order */}
        {totalDetail && discountTrx > 0 ? (
          <Rows
            leftText='Diskon Transaksi'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(discountTrx ?? 0)
                : currencyFormatNotFraction(discountTrx ?? 0)
            }
          />
        ) : null}
        {/* Tax Lines */}
        {Number(printData?.total_tax ?? 0) > 0 || Number(printData?.total_minus_tax ?? 0) !== 0 ? (
          <Rows
            leftText={structSetting?.tax_label}
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Number(printData?.total_tax ?? printData?.total_minus_tax))
                : currencyFormatNotFraction(Number(printData?.total_tax ?? printData?.total_minus_tax))
            }
          />
        ) : null}
        {/* Shipping Fee Lines */}
        {Number(printData?.shipping_cost) > 0 ? (
          <Rows
            leftText='Ongkos Kirim'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Math.abs(Number(printData?.shipping_cost)))
                : currencyFormatNotFraction(Math.abs(Number(printData?.shipping_cost)))
            }
          />
        ) : null}
        {/* Shipping Insurance Fee Lines */}
        {Number(printData?.insurance_cost) > 0 ? (
          <Rows
            leftText='Asuransi Kirim'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Math.abs(Number(printData?.insurance_cost)))
                : currencyFormatNotFraction(Math.abs(Number(printData?.insurance_cost)))
            }
          />
        ) : null}
        {/* Other Fee Lines */}
        {Number(printData?.add_fee) !== 0 ? (
          <Rows
            leftText='Biaya Lainnya'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Math.abs(Number(printData?.add_fee)))
                : currencyFormatNotFraction(Math.abs(Number(printData?.add_fee)))
            }
          />
        ) : null}
        <Rows
          fontWeight='bold'
          leftText={`${structSetting?.total_label} (${getTotalItemsHistory(printData?.items ?? [])})`}
          rightText={
            structSetting?.show_two_digit_fraction
              ? currencyFormat(Number(printData?.grand_total))
              : currencyFormatNotFraction(Number(printData?.grand_total))
          }
        />
      </Box>
      <Divider />
      <Box mt={1}>
        {printData && printData.payments?.length > 0
          ? printData?.payments.map((payment, index) => (
              <Rows
                key={index}
                leftText={getPaymentsName(payment)}
                rightText={
                  structSetting?.show_two_digit_fraction
                    ? currencyFormat(
                        printData?.is_return
                          ? Number(payment.payment_amount)
                          : Number(payment.payment_amount) + Number(payment.payment_charge)
                      )
                    : currencyFormatNotFraction(
                        printData?.is_return
                          ? Number(payment.payment_amount)
                          : Number(payment.payment_amount) + Number(payment.payment_charge)
                      )
                }
              />
            ))
          : null}
      </Box>
      {printData?.pos_is_unpaid === true || Number(printData?.pos_is_unpaid) === 1 ? (
        <React.Fragment>
          <Divider />
          <Rows
            fontWeight='bold'
            leftText='PERLU DIBAYAR'
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Number(printData?.grand_total) - Number(printData?.payment_amount))
                : currencyFormatNotFraction(
                    Number(printData?.grand_total) - Number(printData?.payment_amount)
                  )
            }
          />
        </React.Fragment>
      ) : null}

      {Number(paymentCharge.kembalian) > 0 ? (
        <React.Fragment>
          <Divider />
          <Rows
            leftText={structSetting?.charge_label}
            rightText={
              structSetting?.show_two_digit_fraction
                ? currencyFormat(Number(paymentCharge.kembalian))
                : currencyFormatNotFraction(Number(paymentCharge.kembalian))
            }
          />
        </React.Fragment>
      ) : null}
      {printData?.note && printData?.note !== '' ? (
        <React.Fragment>
          <Divider />
          <Rows leftText='Catatan' rightText={printData.note} />
        </React.Fragment>
      ) : null}
      <Divider />
      <Footer structSetting={structSetting} />
    </Box>
  );
};

class ComponentToPrint extends React.Component {
  render(): JSX.Element {
    return <PrintStruct />;
  }
}

export default ComponentToPrint;
