import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Scalars } from 'types';

/**
 * @description Returns the current date and time in UTC format as a string
 * @returns {string}
 * @example getCurrentDateTimeUtc() // 2019-01-01T00:00:00.000Z
 */
export const customDateFormat = (date = ''): string => {
  const dateNow = date ? new Date(date) : new Date();
  const year = dateNow.getUTCFullYear();
  const month =
    dateNow.getUTCMonth() + 1 < 10 ? '0' + (dateNow.getUTCMonth() + 1) : dateNow.getUTCMonth() + 1;
  const day = dateNow.getUTCDate() < 10 ? '0' + dateNow.getUTCDate() : dateNow.getUTCDate();
  const hours = dateNow.getUTCHours() < 10 ? '0' + dateNow.getUTCHours() : dateNow.getUTCHours();
  const minutes =
    dateNow.getUTCMinutes() < 10 ? '0' + dateNow.getUTCMinutes() : dateNow.getUTCMinutes();
  const seconds =
    dateNow.getUTCSeconds() < 10 ? '0' + dateNow.getUTCSeconds() : dateNow.getUTCSeconds();

  const dateCloseClosure =
    year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

  return dateCloseClosure;
};

/**
 * @description Returns the current date and time in UTC format as a string
 * @returns {string}
 * @example dateCode() // 20210101
 */
export const dateCode = (): string => {
  const dateObj = new Date();
  const monthDefault = dateObj.getMonth() + 1; //months from 1-12
  const month = (monthDefault < 10 ? '0' : '') + monthDefault;
  const day = (dateObj.getDate() < 10 ? '0' : '') + dateObj.getDate();
  const year = dateObj.getFullYear();
  const now = `${year}${month}${day}`;
  return now;
};

/**
 * @description Returns the current hours, minutes and seconds in UTC format as a string
 * @returns {string}
 * @example timeTransaction() // 200215
 */
export const timeTransaction = (): string => {
  const dateObj = new Date();
  const hours = (dateObj.getHours() < 10 ? '0' : '') + dateObj.getHours();
  const minutes = (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes();
  const second = (dateObj.getSeconds() < 10 ? '0' : '') + dateObj.getSeconds();
  const time = `${hours}${minutes}${second}`;
  return time;
};

/**
 * @description Returns the current date and time in UTC format as a string
 * @returns {string} dateNow - 2019-01-01T00:00:00.000Z
 * @example getCurrentDateTimeUtc() // 2019-01-01T00:00:00.000Z
 */
export const formatDate = (UTC_Date: Scalars['DateTime']): string => {
  const localDate = new Date(UTC_Date);
  const objToday = localDate,
    dayOfMonth = objToday.getDate() < 10 ? '0' + objToday.getDate() : objToday.getDate(),
    curMonth =
      objToday.getMonth() + 1 < 10 ? '0' + (objToday.getMonth() + 1) : objToday.getMonth() + 1,
    curYear = objToday.getFullYear(),
    curHour = objToday.getHours(),
    curMinute = objToday.getMinutes() < 10 ? '0' + objToday.getMinutes() : objToday.getMinutes();
  const today = curYear + '-' + curMonth + '-' + dayOfMonth + ' ' + curHour + ':' + curMinute;
  return today;
};

export function dateUTCBuyItems(): string {
  const dateNow = new Date();
  // Convert the local date to UTC
  const dateUTC = utcToZonedTime(dateNow, 'UTC');
  // Format the UTC date using the desired format
  const formattedDate = format(dateUTC, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  return formattedDate;
}

export function convertToLocalDate(UTC_Date: Date): string {
  const localDate = new Date(UTC_Date);
  const objToday = localDate,
    dayOfMonth = objToday.getDate() < 10 ? '0' + objToday.getDate() : objToday.getDate(),
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
    curMonth = months[objToday.getMonth()],
    curYear = objToday.getFullYear(),
    curHour = objToday.getHours() < 10 ? '0' + objToday.getHours() : objToday.getHours(),
    curMinute = objToday.getMinutes() < 10 ? '0' + objToday.getMinutes() : objToday.getMinutes();
  const convertLocalDate =
    dayOfMonth + ' ' + curMonth + ' ' + curYear + ' ' + curHour + ':' + curMinute;
  return convertLocalDate;
}

export function convertToLocalDateWithoutTime(UTC_Date: Date): string {
  const localDate = new Date(UTC_Date);
  const objToday = localDate,
    dayOfMonth = objToday.getDate() < 10 ? '0' + objToday.getDate() : objToday.getDate(),
    months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
    curMonth = months[objToday.getMonth()],
    curYear = objToday.getFullYear();
  const convertLocalDate = dayOfMonth + ' ' + curMonth + ' ' + curYear;
  return convertLocalDate;
}

export function lastSyncDate(date: Date | string): string {
  if (!date) return '';

  const dateNow = new Date(date);
  const year = dateNow.getUTCFullYear();
  const month =
    dateNow.getUTCMonth() + 1 < 10 ? '0' + (dateNow.getUTCMonth() + 1) : dateNow.getUTCMonth() + 1;
  const day = dateNow.getUTCDate() < 10 ? '0' + dateNow.getUTCDate() : dateNow.getUTCDate();

  const dateCloseClosure = year + '-' + month + '-' + day;

  return dateCloseClosure;
}
