import * as React from 'react';
import { IGetFreeItems } from 'types/products.types';

import { useAppSelector } from './redux';

type IUseGetFreeItems = {
  data: IGetFreeItems;
};

const useGetFreeItems = (): IUseGetFreeItems => {
  const processPromotions = useAppSelector((state) => state.sales.processPromotions);
  const [data, setData] = React.useState<IGetFreeItems>({
    showModal: false,
    isCategory: false,
    isProduct: false,
    item: {},
    promotion: '',
  });

  React.useEffect(() => {
    for (const pkey of Object.keys(processPromotions)) {
      const pobj = processPromotions[pkey];
      for (const freeProduct of Object.keys(pobj.free_products)) {
        if (pobj.free_products[freeProduct].chosen === true) continue;

        setData({
          ...data,
          promotion: pkey,
          isCategory: false,
          isProduct: true,
          item: freeProduct,
          showModal: true,
        });
        return undefined;
      }

      for (const freeCategory of Object.keys(pobj.free_categories)) {
        if (pobj.free_categories[freeCategory].chosen === true) continue;

        setData({
          ...data,
          promotion: pkey,
          isCategory: true,
          isProduct: false,
          item: freeCategory,
          showModal: true,
        });

        return undefined;
      }
    }

    setData({
      ...data,
      isProduct: false,
      isCategory: false,
      showModal: false,
    });
  }, [processPromotions]);

  return {
    data,
  };
};

export default useGetFreeItems;
