import axios from 'axios';
import config from 'constant';

export const WMS_API = config.API_URL_WMS;

const wmsRequest = axios.create({
  baseURL: WMS_API,
});

wmsRequest.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default wmsRequest;
