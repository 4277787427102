import {
  Box,
  Divider,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { CardMain } from 'components/card';
import { ModalNotif } from 'components/modal';
import { CustomLineTab } from 'components/tabs';
import { Title } from 'components/typography';
import { DetailOrder } from 'components/ui/bopis';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import { fetcher } from 'lib/fetcher';
import React from 'react';
import bopisRequest from 'services/http/bopis.request';
import { WMS_API } from 'services/http/wms-request';
import useSWR from 'swr';
import {
  IDetailTransaction,
  IResponseCompleted,
  IResponsePacklist,
  IResponseShipped,
  ReadyToPickFragment,
} from 'types/bopis.types';

import CompleteBopis from './CompletedTabs';
import ShippedBopis from './ShippedTabs';
import UnprocessedBopis from './UnprocessedTabs';
import WaitingPickupBopis from './WaitingPickupTabs';

const BopisPage = () => {
  const [isLargeScreen] = useMediaQuery('(min-width: 720px)');
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);

  const [search, setSearch] = React.useState({
    unprocessed: '',
    waitingForPickup: '',
    completed: '',
    shipped: '',
  });

  const [filter, setFilter] = React.useState({
    unprocessed: '',
    waitingForPickup: '',
    completed: '',
    shipped: '',
  });

  const [loadingCompleteOrder, setLoadingCompleteOrder] = React.useState<boolean>(false);
  const [tabsIndex, setTabsIndex] = React.useState<number>(0);
  const [detailOrder, setDetailOrder] = React.useState<IDetailTransaction>({} as IDetailTransaction);

  const profile = useAppSelector((state) => state.auth.profile);
  const authSelector = useAppSelector((state) => state.auth);
  const { location } = useAppSelector((state) => state.register);
  const { notification } = useNotification();

  const { isOpen: isOpenNotif, onOpen: onOpenNotif, onClose: onCloseNotif } = useDisclosure();

  const { data: readyToPick, mutate: mutateReadyToPick } = useSWR<ReadyToPickFragment>(
    tabsIndex === 0 &&
      (authSelector.is_wms_migrated
        ? `${WMS_API}/wms/sales/orders/ready-to-process/?q=${
            search.unprocessed
          }&page=${page}&pageSize=${pageSize}&posLocIds=${
            location?.location_id as number
          }&sortDirection=DESC&sortBy=transaction_date&overrideCourierFilter=false${
            filter.unprocessed !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.unprocessed}`
              : ''
          }`
        : `/sales/orders/ready-to-pick/?q=${
            search.unprocessed
          }&page=${page}&pageSize=${pageSize}&locationId=${
            location?.location_id as number
          }&sortDirection=DESC&sortBy=transaction_date${
            filter.unprocessed !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.unprocessed}`
              : ''
          }`),
    fetcher
  );

  const { data: packlist, mutate: mutatePacklist } = useSWR<IResponsePacklist>(
    tabsIndex === 1 &&
      (authSelector.is_wms_migrated
        ? `${WMS_API}/wms/sales/order/ready-to-ship?q=${
            search.waitingForPickup
          }&page=${page}&pageSize=${pageSize}&posLocIds=${
            location?.location_id as number
          }&sortDirection=DESC&sortBy=transaction_date${
            filter.waitingForPickup !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.waitingForPickup}`
              : ''
          }`
        : `sales/packlists/?page=${page}&pageSize=${pageSize}&locationId=${
            location?.location_id as number
          }&q=${search.waitingForPickup}&sortDirection=DESC&sortBy=transaction_date${
            filter.waitingForPickup !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.waitingForPickup}`
              : ''
          }`),
    fetcher
  );

  const { data: shipped, mutate: mutateShipped } = useSWR<IResponseShipped>(
    tabsIndex === 2 &&
      (authSelector.is_wms_migrated
        ? `${WMS_API}/wms/sales/shipped/?page=${page}&pageSize=${pageSize}&posLocIds=${
            location?.location_id as number
          }&q=${search.shipped}&sortDirection=DESC&sortBy=transaction_date&skuFilter=false${
            filter.shipped !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.shipped}`
              : ''
          }`
        : `sales/packlists/shipped/?page=${page}&pageSize=${pageSize}&locationId=${
            location?.location_id as number
          }&q=${search.shipped}&sortDirection=DESC&sortBy=transaction_date${
            filter.shipped !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.shipped}`
              : ''
          }`),
    fetcher
  );

  const { data: completed, mutate: mutateComplete } = useSWR<IResponseCompleted>(
    tabsIndex === 3 &&
      (authSelector.is_wms_migrated
        ? `${WMS_API}/sales/orders/completed/?wms=true&page=${page}&pageSize=${pageSize}&sortBy=transaction_date&sortDirection=DESC&skuFilter=false&posLocIds=${
            location?.location_id as number
          }&q=${search.completed}${
            filter.completed !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.completed}`
              : ''
          }`
        : `sales/orders/completed/?page=${page}&pageSize=${pageSize}&locationId=${
            location?.location_id as number
          }&q=${search.completed}&sortDirection=DESC&sortBy=transaction_date${
            filter.completed !== ''
              ? `&advanced%5B0%5D%5Bkey%5D=shipper&advanced%5B0%5D%5Bquery%5D=${filter.completed}`
              : ''
          }`),
    fetcher
  );

  const updateSearch = (key: string, value: string) => {
    setSearch((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const completeOrder = async (id: number) => {
    try {
      setLoadingCompleteOrder(true);
      const res = await bopisRequest.completeOrder(id, authSelector.is_wms_migrated);
      if (res) {
        onOpenNotif();
        setDetailOrder({} as IDetailTransaction);
        if (tabsIndex === 1) mutatePacklist();
        if (tabsIndex === 2) mutateShipped();
      }
      setLoadingCompleteOrder(false);
    } catch (err) {
      setLoadingCompleteOrder(false);
      notification('', 'Terjadi kesalahan pada server.', 'warning');
    }
  };

  const createShipment = async () => {
    try {
      setLoadingCompleteOrder(true);
      if (detailOrder.courier === '') {
        setLoadingCompleteOrder(false);
        notification('', 'Anda belum memilih kurir', 'warning');
        return;
      }
      const listCourier = await bopisRequest.getCourierWms();
      const findCourierId = await bopisRequest.courierMapping(detailOrder.courier);
      const courierId = findCourierId.courier_id;
      const finalCourier = listCourier.find((o) => o.courier_id === courierId);

      const payload = {
        courier_new_id: Number(finalCourier?.courier_id ?? 0),
        location_id: detailOrder.location_id,
        shipment_type: 2,
        shipment_header_id: 0,
        shipment_no: '[auto]',
        courier_name: detailOrder.customer_name,
        salesorder_ids: [detailOrder.salesorder_id],
        employee_id: profile?.email ?? '',
        shipment_date: new Date(),
        is_completed: true,
      };
      const res = await bopisRequest.createShippingOrder(payload);
      if (res && res.shipment_number) {
        const shipmentId = res.shipment_number.split('-')[1];
        await bopisRequest.createShippingOrder({
          next_day: true,
          shipment_header_id: Number(shipmentId),
          shipment_no: res.shipment_number,
        });
        onOpenNotif();
        setDetailOrder({} as IDetailTransaction);
        mutatePacklist();
      }
      setLoadingCompleteOrder(false);
    } catch (err) {
      setLoadingCompleteOrder(false);
      notification('', 'Terjadi kesalahan pada server.', 'warning');
    }
  };

  const onChangeTab = (e: any) => {
    setPage(1);
    setPageSize(15);
    setTabsIndex(e);
    setDetailOrder({} as IDetailTransaction);
    if (e === 0) mutateReadyToPick();
    if (e === 1) mutatePacklist();
    if (e === 2) mutateShipped();
    if (e === 3) mutateComplete();
  };

  return (
    <>
      <CardMain>
        <Box width={{ base: 'full', sm: 'full', md: '60%', lg: '60%', xl: '60%' }}>
          <Box position='relative' h='full' bg='white' rounded='lg'>
            <Box p={4}>
              <Title fontSize='18px'>Marketplace</Title>
            </Box>
            <Divider my={3} borderColor='system.outline'></Divider>
            <Tabs
              onChange={(e: number) => {
                onChangeTab(e);
              }}
              defaultIndex={tabsIndex}
            >
              <TabList borderBottomWidth='0.5px' px={4}>
                <CustomLineTab maxW={'15%'} flexBasis={'15%'}>
                  Belum Proses
                </CustomLineTab>
                <CustomLineTab maxW={'25%'} flexBasis={'25%'}>
                  Menunggu Pengambilan
                </CustomLineTab>
                <CustomLineTab maxW={'13%'} flexBasis={'13%'}>
                  Sudah Dikirim
                </CustomLineTab>
                <CustomLineTab maxW={'13%'} flexBasis={'13%'}>
                  Selesai
                </CustomLineTab>
              </TabList>
              <TabPanels>
                {/* UnprocessedBopis Tab */}
                <TabPanel>
                  <UnprocessedBopis
                    search={search.unprocessed}
                    setSearch={updateSearch}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    setDetailOrder={setDetailOrder}
                    onlineTransaction={readyToPick}
                    tabIndex={tabsIndex}
                    filter={filter.unprocessed}
                    setFilter={updateFilter}
                  />
                </TabPanel>
                {/* WaitingPickupBopis Tab */}
                <TabPanel>
                  <WaitingPickupBopis
                    searchOnline={search.waitingForPickup}
                    setSearch={updateSearch}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    transactionsList={packlist ?? ({} as unknown as IResponsePacklist)}
                    setDetailOrder={setDetailOrder}
                    tabIndex={tabsIndex}
                    setFilter={updateFilter}
                  />
                </TabPanel>
                {/* Shipped Tab */}
                <TabPanel>
                  <ShippedBopis
                    searchOnline={search.shipped}
                    setSearch={updateSearch}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    transactionsList={shipped ?? ({} as unknown as IResponseShipped)}
                    setDetailOrder={setDetailOrder}
                    tabIndex={tabsIndex}
                    setFilter={updateFilter}
                  />
                </TabPanel>
                {/* CompleteBopis Tab */}
                <TabPanel>
                  <CompleteBopis
                    searchOnline={search.completed}
                    setSearch={updateSearch}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    transactionsList={completed ?? ({} as unknown as IResponseCompleted)}
                    setDetailOrder={setDetailOrder}
                    tabIndex={tabsIndex}
                    setFilter={updateFilter}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        {isLargeScreen && (
          <Box w='40%' position='relative'>
            <DetailOrder
              detailOrder={detailOrder}
              refreshReadyToPick={mutateReadyToPick}
              tabsKey={tabsIndex}
              completeOrder={completeOrder}
              loadingCompleteOrder={loadingCompleteOrder}
              setDetailOrder={setDetailOrder}
              createShipment={createShipment}
            />
          </Box>
        )}
      </CardMain>

      {/* Popup success */}
      <ModalNotif
        isOpen={isOpenNotif}
        onClose={onCloseNotif}
        title='Pesanan Selesai'
        text={`Pesanan telah diselesaikan`}
        showButton={false}
      />
    </>
  );
};

export default BopisPage;
