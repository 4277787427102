import { Box, Button, HStack, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CardMain } from 'components/card';
import SyncIcon from 'components/icons/SyncIcon';
import { CustomLineTab } from 'components/tabs';
import { Title } from 'components/typography';
import { DetailHistory } from 'components/ui/transaction';
import { alert } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { useGetTransaction, useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { resetOrder } from 'redux/reducer/sales';
import { setTabs } from 'redux/reducer/settings';
import transactionRequest from 'services/http/transaction.request';
import cart from 'services/indexdb/cart';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { DetailOrderTransaction } from 'types';
import { IReturnList } from 'types/return.types';
import { IDetailTransaction } from 'types/transaction.types';
import { debounce } from 'utils';

import Retur from './return';
import Sales from './sales';

const HistoryPage = () => {
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [searchOffline, setSearchOffline] = React.useState<string>('');
  const [searchOnline, setSearchOnline] = React.useState<string>('');
  const [searchReturnOnline, setSearchReturnOnline] = React.useState<string>('');
  const [searchReturnOffline, setSearchReturnOffline] = React.useState<string>('');
  const [detailOrder, setDetailOrder] = React.useState<DetailOrderTransaction>({} as DetailOrderTransaction);
  const [isSync, setIsSync] = React.useState<boolean>(false);
  const [typeSales, setTypeSales] = React.useState<number>(0);

  // ** Redux Hooks
  const dispatch = useAppDispatch();
  const syncTransaction = useAppSelector((state) => state.commons.syncTransaction);
  const tabsIndex = useAppSelector((state) => state.commons.tabsIndex);
  const location = useAppSelector((state) => state.register.location);
  const initSync = useAppSelector((state) => state.register.initSync);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const cartId = useAppSelector((state) => state.sales.cartId);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const stepDownload = useAppSelector((state) => state.register.stepDownload);

  const { syncAllTransaction, sendFailTransaction } = useGetTransaction();
  const { notification } = useNotification();
  const divRef = React.useRef<HTMLDivElement>(null);

  const [heightCard, setHeightCard] = React.useState<number>(0);

  const transactionList = useLiveQuery<IDetailTransaction[]>(() => {
    return orders.get(searchOffline, typeSales);
  }, [searchOffline, tabsIndex, typeSales]) as IDetailTransaction[];

  const returnList = useLiveQuery<IReturnList[]>(() => {
    return returnOrder.get(searchReturnOffline);
  }, [searchReturnOffline, tabsIndex]) as IReturnList[];

  const { data: onlineTransaction } = useQuery({
    queryKey: [
      'transaction-list',
      {
        location_id: location?.location_id as number,
        page,
        pageSize,
        q: searchOnline,
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
        closure_id: null,
      },
    ],
    queryFn: transactionRequest.getTransactions,
    enabled: isOnline,
  });

  const { data: onlineReturn } = useQuery({
    queryKey: [
      'return-list',
      {
        location_id: location?.location_id as number,
        page,
        pageSize,
        q: searchReturnOnline,
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
        closure_id: null,
      },
    ],
    queryFn: transactionRequest.getReturn,
    enabled: isOnline,
  });

  const handleSyncTransaction = React.useCallback(async () => {
    if (isSync) return; // Prevent multiple calls while syncing

    try {
      setIsSync(true);
      await Promise.all([sendFailTransaction(), syncAllTransaction(searchReturnOnline || searchOnline, 1)]);
      setPage(1);
      setPageSize(15);
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    } finally {
      setIsSync(false);
    }
  }, [isSync, sendFailTransaction, syncAllTransaction, searchReturnOnline, searchOnline]);

  const searchItems = debounce(async (value: string) => {
    if (tabsIndex === 0) {
      if (isOnline) {
        setSearchOnline(value);
      } else {
        setSearchOffline(value);
      }
    } else {
      if (isOnline) {
        setSearchReturnOnline(value);
      } else {
        setSearchReturnOffline(value);
      }
    }
    setPage(1);
  }, 500);

  React.useEffect(() => {
    if (!syncTransaction) {
      syncAllTransaction(searchOffline, page);
    }
  }, [syncTransaction]);

  React.useEffect(() => {
    setDetailOrder({} as DetailOrderTransaction);
  }, [tabsIndex, typeSales]);

  React.useEffect(() => {
    if (continuePayment === true) {
      if (cartId) cart.delete(cartId);
      dispatch(resetOrder());
    }
  }, [continuePayment, cartId]);

  React.useEffect(() => {
    const observeTarget = divRef.current;
    if (observeTarget) {
      const height =
        !isOnline || initSync || stepDownload === 'done'
          ? observeTarget.clientHeight / 4
          : observeTarget.clientHeight / 6;
      setHeightCard(height);
    }
  }, [isOnline, initSync]);

  return (
    <CardMain ref={divRef}>
      <div className='flex w-[60%] flex-col'>
        <div className='flex items-center justify-between p-4'>
          <Box>
            <Title fontSize='18px'>Daftar Transaksi</Title>
          </Box>
          <HStack>
            <Text
              fontWeight='600'
              color='jubelio.grey200'
              fontSize='14px'
              display={['none', 'none', 'none', 'block']}
            >
              Transaksi belum tersinkron
            </Text>
            <Button
              variant='outline'
              size='sm'
              px={7}
              borderRadius='8px'
              color='jubelio.black'
              leftIcon={<SyncIcon />}
              onClick={handleSyncTransaction}
              isDisabled={!isOnline}
              isLoading={isSync}
              loadingText='Sinkronisasi...'
            >
              <Text fontSize='14px'>Sinkron Ulang</Text>
            </Button>
          </HStack>
        </div>
        <div className='flex h-1 flex-col'>
          <Tabs
            onChange={(e: number) => {
              setPage(1);
              setPageSize(15);
              dispatch(setTabs({ name: 'tabsIndex', index: e }));
            }}
            defaultIndex={tabsIndex}
            className='relative h-full py-2'
          >
            <TabList borderBottomWidth='0.5px' px={4}>
              <CustomLineTab>Penjualan</CustomLineTab>
              <CustomLineTab>Retur</CustomLineTab>
            </TabList>
            <TabPanels
              className='relative h-full'
              style={{
                maxHeight: heightCard > 0 ? `calc(100vh - ${heightCard}px)` : 0,
                minHeight: heightCard > 0 ? `calc(100vh - ${heightCard}px)` : 0,
              }}
            >
              <TabPanel className='flex h-full flex-col'>
                <Sales
                  searchOnline={searchOnline}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  onSearch={searchItems}
                  setPageSize={setPageSize}
                  onSelectType={(e) => setTypeSales(e)}
                  setDetailOrder={setDetailOrder}
                  transactionsList={transactionList}
                  onlineTransaction={onlineTransaction}
                />
              </TabPanel>
              <TabPanel className='flex h-full flex-col'>
                <Retur
                  searchOnline={searchReturnOnline}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  transactionsList={returnList}
                  setDetailOrder={setDetailOrder}
                  onlineReturn={onlineReturn}
                  onSearch={searchItems}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
      <div className='h-full w-[40%]'>
        <DetailHistory detailOrder={detailOrder as DetailOrderTransaction} />
      </div>
    </CardMain>
  );
};

export default HistoryPage;
