import { Image } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';

const LoadingScreen: React.FC = () => (
  <div
    className={clsx('fixed inset-0 flex h-screen w-screen items-center justify-center bg-white')}
  >
    <div className='flex max-w-[500px] flex-wrap items-center justify-center gap-x-8'>
      <div className='flex flex-col items-center justify-center'>
        <Image src='/jubelio-pos.png' w='full' alt='jubelio-pos' className='scale-50' />
        <p className='loading -mt-2 font-bold'>Sedang menyiapkan halaman...</p>
      </div>
    </div>
  </div>
);

export default LoadingScreen;
