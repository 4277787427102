import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Button, Flex, HStack, Stack } from '@chakra-ui/react';
import ModalVersion from 'components/modal/ModalVersion';
import { Title } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { setUsePromotion } from 'redux/reducer/settings';
import gfm from 'remark-gfm';
import commonRequest from 'services/http/common.request';
import { IPosVersion } from 'types/common.types';

import 'theme/announcement.css';

import { CardSettingDescription, CardVersion } from '.';
import CardSettingPromotion from './CardSettingPromotion';

interface CardSettingGeneralProps extends BoxProps {
  handleClearData: () => void;
  checkUpdate: () => void;
  changeLog: IPosVersion[];
  loadingClearData: boolean;
}

const CardSettingGeneral: React.FC<CardSettingGeneralProps> = ({
  handleClearData,
  checkUpdate,
  changeLog,
  loadingClearData,
  ...rest
}: CardSettingGeneralProps) => {
  const [openCollapse, setOpenCollapse] = React.useState<boolean>(false);
  const [isOpenVersion, setOpenVersion] = React.useState<boolean>(false);
  const { initSync, isCashier, currentClosure } = useAppSelector((state) => state.register);
  const { usePromotion } = useAppSelector((state) => state.commons);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const dispatch = useDispatch();

  const updateUsePromotion = async (e: boolean) => {
    if (isOnline) {
      await commonRequest.logPos('USE-PROMOTION', JSON.stringify({ usePromotion: e }));
    }
    dispatch(setUsePromotion(e));
  };

  return (
    <Box w='full' {...rest}>
      <Box border='1px' borderColor='gray.200' borderRadius='4px' w='full'>
        <Stack w='full' spacing={4} p={4}>
          <CardVersion checkUpdate={checkUpdate} />
        </Stack>
        <Flex
          justifyContent='space-between'
          className='space-x-2'
          mb='5px'
          position='relative'
          mt={5}
          px={10}
          w='full'
        >
          <div className='flex w-full items-center justify-between text-[14px]'>
            <div className=''>
              {changeLog?.length > 0 &&
                (changeLog[0].changelog.length <= 500 ? (
                  <>
                    <Title paddingBottom={2}>{changeLog[0].version_number}</Title>
                    <ReactMarkdown
                      children={changeLog[0].changelog}
                      remarkPlugins={[gfm]}
                      className='reactMarkDown'
                    />
                  </>
                ) : (
                  <Box fontSize='14px' py={2}>
                    <HStack as='button' onClick={() => setOpenCollapse(!openCollapse)} paddingBottom={2}>
                      <TriangleDownIcon
                        h='12px'
                        color='jubelio.dark'
                        transform={openCollapse ? 'rotate(360deg)' : 'rotate(270deg)'}
                      />
                      <Title>{changeLog[0].version_number}</Title>
                    </HStack>
                    {openCollapse && (
                      <ReactMarkdown
                        children={changeLog[0].changelog}
                        remarkPlugins={[gfm]}
                        className='reactMarkDown'
                      />
                    )}
                  </Box>
                ))}
            </div>
            <Button variant='outline' onClick={() => setOpenVersion(true)}>
              Lihat Versi Lainnya
            </Button>
          </div>
        </Flex>
      </Box>
      {isCashier && (
        <CardSettingPromotion
          title='Gunakan Promosi'
          subtitle='Aktifkan jika anda ingin menggunakan promosi'
          mt={3}
          isActive={usePromotion}
          clickButton={updateUsePromotion}
        />
      )}
      {isCashier && (
        <React.Fragment>
          <CardSettingDescription
            title='Sinkronisasi Pengaturan'
            subtitle='Transaksi yang belum ke sinkron ke server akan di sinkron kembali. <br/> Transaksi yang tidak bisa di sinkron akan di simpanKe Server untuk di handle nantinya'
            labelButton='Sinkronkan'
            mt={3}
            isSync={initSync}
            clickButton={handleClearData}
            isDisabled={!currentClosure && isOnline}
            loading={loadingClearData}
          />
        </React.Fragment>
      )}
      <CardSettingDescription
        title='Pelajari Jubelio POS'
        subtitle='Pelajari kembali fitur - fitur Jubelio POS dari Help Jubelio'
        labelButton='Tour'
        isSync={false}
        mt={3}
        clickButton={() => window.open('https://education.jubelio.com/', '_blank')}
        isDisabled={false}
        loading={false}
      />

      <ModalVersion
        isShow={isOpenVersion}
        onClose={() => setOpenVersion(false)}
        listVersion={changeLog ?? []}
      />
    </Box>
  );
};
export default CardSettingGeneral;
