import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const BadgeVariant: React.FC<BoxProps> = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      {...props}
      rounded='4px'
      minW='40px'
      textAlign='center'
      px='10px'
      py='6px'
      border='1px'
      borderColor='gray.100'
    >
      {children}
    </Box>
  );
};

export default BadgeVariant;
