import { Box, Divider, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import QRCode from 'react-qr-code';
import { convertToLocalDate, currencyFormat } from 'utils';

export const PrintQRISDynamic: React.FC = () => {
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const qrisDynamic = useAppSelector((state) => state.sales.qrisDynamic);
  const orders = useAppSelector((state) => state.sales.order);
  const remainingPayment = useAppSelector((state) => state.sales.remainingPayment);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);

  return (
    <Box width='264px' m='auto' className='print' px='12px' mb={10} color='#000' bgColor='white'>
      {/* Header */}
      <Box textAlign='center' whiteSpace='pre-wrap'>
        <Text fontWeight='bold'>{structSetting?.store_name}</Text>
      </Box>
      {/* SubHeader */}
      <Box mt={3}>
        <Text>
          {structSetting?.date_label}:{' '}
          {convertToLocalDate((orders?.transaction_date as Date) || new Date())}
        </Text>
        <Text>
          {structSetting?.transaction_no_label}: {orders?.salesorder_no}
        </Text>
      </Box>

      <Divider />
      <Text textAlign='center' fontWeight='bold' my={2}>
        TAGIHAN
      </Text>
      <Divider />

      <Text textAlign='center' my={2}>
        Silahkan scan kode QR untuk membayar
      </Text>

      <Box mt={3} textAlign='center' className='flex justify-center align-middle'>
        <QRCode value={qrisDynamic?.qr_string ?? ''} size={150} />
      </Box>

      <Text textAlign='center' my={2}>
        {currencyFormat(continuePayment ? Number(remainingPayment) : Number(orders.grand_total))}
      </Text>

      <Text textAlign='center' fontWeight='bold' mt={2}>
        Ini bukan bukti pembayaran
      </Text>

      <Text textAlign='center' mb={2}>
        Powered by Jubelio POS
      </Text>
    </Box>
  );
};

class ComponentToPrintQRISDynamic extends React.Component {
  render(): JSX.Element {
    return <PrintQRISDynamic />;
  }
}

export default ComponentToPrintQRISDynamic;
