import React from 'react';

type useOnlineStatusProps = {
  isOnline: boolean;
};

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

const useOnlineStatus = (): useOnlineStatusProps => {
  const [isOnline, setIsOline] = React.useState<boolean>(getOnLineStatus());

  const handleOnline = () => {
    setIsOline(true);
  };
  const handleOffline = () => {
    setIsOline(false);
  };

  React.useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
};

export default useOnlineStatus;
