import { Button } from '@chakra-ui/button';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  FormControl,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import ListPromotion from 'components/list/ListPromotion';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import {
  setListAllPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListSelectedPromotion,
  setListValidVoucher,
  updateListFreeItemV2,
} from 'redux/reducer/sales';
import promotionRequest from 'services/http/promotion.request';
import { Maybe } from 'types';
import { IGetPromotionData, IGetPromotionItem, IValidateVoucher } from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { ITotalDetail } from 'types/sales.types';
import {
  getAmountDetailTransaction,
  getAmountItem,
  getDiscountItem,
  getDiscountOutlet,
  getSaleTotalAfterItemDiscount,
} from 'utils';
import { checkDiscountAmount } from 'utils/promotions/minimal-transaction';

interface ModalVoucherProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalVoucher: React.FC<ModalVoucherProps> = ({ isOpen, onClose }: ModalVoucherProps) => {
  const { notification } = useNotification();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const sales = useAppSelector((state) => state.sales);
  const orderItems = useAppSelector((state) => state.sales.order);
  const location = useAppSelector((state) => state.register.location);
  const settings = useAppSelector((state) => state.commons.settings);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const listPromotionAmount = useAppSelector(({ sales: { listPromotionAmount } }) => listPromotionAmount);
  const listPromotionItem = useAppSelector(({ sales: { listPromotionItem } }) => listPromotionItem);
  const listAllPromotion = useAppSelector(({ sales: { listAllPromotion } }) => listAllPromotion);
  const listValidVoucher = useAppSelector(({ sales: { listValidVoucher } }) => listValidVoucher);
  const listFreeItemV2 = useAppSelector(({ sales: { listFreeItemV2 } }) => listFreeItemV2);

  const listSelectedPromotion = useAppSelector(
    ({ sales: { listSelectedPromotion } }) => listSelectedPromotion
  );

  const dispatch = useAppDispatch();

  const [voucherCode, setVoucherCode] = React.useState<string>('');
  const [voucherValidation, setVoucherValidation] = React.useState<Maybe<IValidateVoucher>>(null);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);
  const [existVoucher, setExistVoucher] = React.useState<boolean>(false);
  const [rewardVoucher, setRewardVoucher] = React.useState<IGetPromotionData[]>([]);
  const [anotherPromotions, setAnotherPromotions] = React.useState<IGetPromotionData[]>([]);
  const [accordionIndex, setAccordionIndex] = React.useState<number[]>([0]);

  const [activePromotion, setActivePromotion] = React.useState<IGetPromotionData[]>([]);

  const totalDetails = React.useMemo(
    (): ITotalDetail => getAmountDetailTransaction(sales, location as ILocations, settings),
    [listItemCart, sales, location, settings]
  );

  const onSave = () => {
    const filterAllPromotion = listAllPromotion.filter((p) =>
      rewardVoucher.some((i) => p.promotion_id === i.promotion_id)
    );

    const allPromotion =
      filterAllPromotion.length === 0 ? [...listAllPromotion, ...rewardVoucher] : [...listAllPromotion];

    if (filterAllPromotion.length === 0) {
      const mapAllPromotion = allPromotion.map((p) => {
        if (p.skip_free_item)
          return {
            ...p,
            skip_free_item: false,
          };
        return p;
      });
      dispatch(setListAllPromotion(mapAllPromotion));
    }

    if (listSelectedPromotion.length === 0) {
      if (activePromotion.length > 0) {
        const mapActivePromotion = activePromotion.map((p) => {
          const findAllPromotion = allPromotion.find((ap) => ap.promotion_id === p.promotion_id);
          if (findAllPromotion) {
            return findAllPromotion;
          }
          return p;
        });
        const mappingPromo = mappingPromotion(mapActivePromotion);
        if (voucherValidation) {
          dispatch(setListValidVoucher([voucherValidation.data]));
        }

        const filterFreeItem = listFreeItemV2.filter((f) =>
          mapActivePromotion.some((p) => p.promotion_id === f.promotion_id)
        );
        dispatch(updateListFreeItemV2(filterFreeItem));
        dispatch(setListPromotionItem(mappingPromo.itemPromotion));
        dispatch(setListPromotionAmount(mappingPromo.transactionPromotion));
      }
      resetState();
      return;
    }

    const filterNonVoucher = allPromotion.filter((p) => !p.use_voucher);
    let finalAllPromotion = filterNonVoucher;
    if (
      activePromotion.length > 0 &&
      listSelectedPromotion.length > 0 &&
      activePromotion[0].is_overlap &&
      listSelectedPromotion[0].is_overlap
    ) {
      finalAllPromotion = [...filterNonVoucher, ...activePromotion];
    }
    const mapAllPromotion = finalAllPromotion.map((p) => {
      if (p.skip_free_item)
        return {
          ...p,
          skip_free_item: false,
        };
      return p;
    });
    dispatch(setListAllPromotion(mapAllPromotion));

    const filterOverlap = listSelectedPromotion.filter((p) => p.is_overlap);
    const filterFinalPromoOverlap = finalAllPromotion.filter((p) => p.is_overlap);
    const promotionsToMap = filterOverlap.length > 0 ? filterFinalPromoOverlap : listSelectedPromotion;
    const mappingPromo = mappingPromotion(promotionsToMap);
    const filterFreeItem = listFreeItemV2.filter((f) =>
      promotionsToMap.some((p) => p.promotion_id === f.promotion_id)
    );

    dispatch(updateListFreeItemV2(filterFreeItem));
    dispatch(setListPromotionItem(mappingPromo.itemPromotion));
    dispatch(setListPromotionAmount(mappingPromo.transactionPromotion));
    dispatch(setListSelectedPromotion([]));

    const filterValidVoucher = listValidVoucher.filter((p) => {
      return (
        mappingPromo.transactionPromotion.find((i) => i.promotion.promotion_id === p.promotion_id) ||
        mappingPromo.itemPromotion.find((i) => i.promotion_id === p.promotion_id)
      );
    });
    dispatch(setListValidVoucher(filterValidVoucher));
    resetState();
  };

  const resetState = () => {
    setVoucherValidation(null);
    setVoucherCode('');
    setExistVoucher(false);
    setRewardVoucher([]);
    onClose();
  };

  const anotherPromotion = () => {
    const filterListPromotionAmount = listPromotionAmount.map((i) => i.promotion.promotion_id);
    const filterListPromotionItem = listPromotionItem.map((i) => i.promotion_id);
    const currentPromotionActive = new Set([...filterListPromotionAmount, ...filterListPromotionItem]);

    const filterAnotherPromotion = listAllPromotion.filter(
      (p) => !currentPromotionActive.has(p.promotion_id)
    );
    setAnotherPromotions(filterAnotherPromotion);
  };

  const setupOpenAccordion = () => {
    if (anotherPromotions.length > 0 && listPromotionAmount.length === 0 && listPromotionItem.length === 0) {
      setAccordionIndex([1]);
    } else setAccordionIndex([0, 1]);
  };

  const mergePromotion = () => {
    let promotionAmount: any = [];
    if (listPromotionAmount.length > 0) {
      const mappingPromotionAmount = listPromotionAmount.map((p) => p.promotion);
      promotionAmount = [...mappingPromotionAmount];
    }
    const mergeArray: IGetPromotionData[] = promotionAmount.concat(listPromotionItem);
    const filterDuplicatePromotion = mergeArray.filter(
      (promo, index, self) => index === self.findIndex((p) => p.promotion_id === promo.promotion_id)
    );
    setActivePromotion(filterDuplicatePromotion);
  };

  React.useEffect(() => {
    mergePromotion();
    anotherPromotion();
    setupOpenAccordion();
  }, [isOpen, listPromotionAmount, listPromotionItem, listAllPromotion, listValidVoucher]);

  const mappingPromotion = (promotion: IGetPromotionData[]) => {
    const filterOverlap: IGetPromotionData[] = promotion.filter((p) => p.is_overlap === true);
    let promotionData: IGetPromotionData[] = [];
    if (promotion[0].is_overlap) {
      promotionData = filterOverlap;
    } else {
      promotionData.push(promotion[0]);
    }
    const filterDiscountPromotion = promotionData.filter(
      (p) => p.discount_reward && p.discount_reward.length > 0
    );

    const totalAfterDiscount = getSaleTotalAfterItemDiscount(listItemCart);
    const locationDiscount = getDiscountOutlet(totalAfterDiscount, location as ILocations);
    let saleAfterDiscount = totalAfterDiscount - locationDiscount;
    const transactionPromotion = [];
    for (const promotion of filterDiscountPromotion) {
      const p = checkDiscountAmount(promotion, saleAfterDiscount);
      if (p) {
        saleAfterDiscount = saleAfterDiscount - p.saleAfterDiscount;
        transactionPromotion.push(p);
      }
    }

    const filterItemPromotion = promotionData
      .filter((p) => p.product_reward && p.product_reward.length > 0)
      .map((p) => {
        return {
          ...p,
          skip_free_item: false,
        };
      });
    return {
      transactionPromotion,
      itemPromotion: filterItemPromotion,
    };
  };

  const closeVoucher = () => {
    onClose();
    setVoucherValidation(null);
    setVoucherCode('');
    setExistVoucher(false);
  };

  const validateVoucher = async (voucher: string) => {
    try {
      if (listValidVoucher.some((p) => p.voucher_code === voucher)) {
        return notification('', 'Voucher berhasil digunakan', 'success', 2000);
      }

      setLoadingValidate(true);

      const newItems: IGetPromotionItem[] =
        listItemCart?.map((item) => {
          const totalPrice = getAmountItem(item);
          const itemAmount = totalPrice - getDiscountItem(item, [], true, totalPrice);
          return { item_id: item.item_id, qty: item.quantity, amount: itemAmount };
        }) ?? [];

      const payload = {
        customer: {
          customer_id: orderItems.customer?.contact_id ?? -1,
          customer_name: orderItems.customer?.contact_name ?? 'Pelanggan Umum',
          customer_category_id: orderItems.customer?.category_id ?? -1,
        },
        voucher_code: voucher,
        current_date: new Date(),
        order: {
          location_id: orderItems.location_id ?? 0,
          grand_total: totalDetails.totalAfterDiscount ?? 0,
          items: newItems,
        },
      };

      const result = await promotionRequest.validateVoucherPromotion(payload);
      setLoadingValidate(false);
      setRewardVoucher(result.data.promotion_rewards);

      const filterReward = result.data.promotion_rewards.filter(
        (r: IGetPromotionData) => !activePromotion.some((a) => a.promotion_id === r.promotion_id)
      );

      if (result.data.promotion_rewards[0].is_overlap) {
        const overlapCurrentActivePromo = activePromotion.some((p) => p.is_overlap);
        const newActivePromo = overlapCurrentActivePromo
          ? [...activePromotion, ...filterReward]
          : filterReward;
        setActivePromotion(newActivePromo);
        const filterOtherPromo = listAllPromotion.filter(
          (p) => !newActivePromo.some((np) => np.promotion_id === p.promotion_id)
        );
        setAnotherPromotions(filterOtherPromo);
      } else {
        setActivePromotion(filterReward);
        const filterOtherPromo = listAllPromotion.filter(
          (p) => !filterReward.some((np) => np.promotion_id === p.promotion_id)
        );
        setAnotherPromotions(filterOtherPromo);
      }

      setVoucherValidation(result);
      return notification('', result.message, 'success', 2000);
    } catch (error: any) {
      setLoadingValidate(false);
      setVoucherValidation(null);
      const message = error?.response?.data?.message || 'Unknown Error';
      return notification('', message, 'warning', 5000);
    }
  };

  const closePopup = () => {
    if (activePromotion.length > 0) {
      const mapActivePromotion = activePromotion.map((p) => {
        const findAllPromotion = listAllPromotion.find((ap) => ap.promotion_id === p.promotion_id);
        if (findAllPromotion) {
          return findAllPromotion;
        }
        return p;
      });
      const mappingPromo = mappingPromotion(mapActivePromotion);

      dispatch(setListPromotionItem(mappingPromo.itemPromotion));
      dispatch(setListPromotionAmount(mappingPromo.transactionPromotion));
    }
    dispatch(setListSelectedPromotion([]));
    resetState();
  };

  React.useEffect(() => {
    if (sales.listPayments && voucherCode !== '') {
      const findExistVoucher = sales.listPayments.find((payment) => payment.no_ref === voucherCode);
      if (findExistVoucher) {
        setVoucherValidation(null);
        setExistVoucher(true);
      } else {
        setVoucherValidation(null);
        setExistVoucher(false);
      }
    }
  }, [sales.listPayments, voucherCode]);

  return (
    <Modal isOpen={isOpen} onClose={closeVoucher} size='2xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Promosi</ModalHeader>
        <ModalBody borderTop='1px' borderColor='gray.200' minHeight='460px'>
          <HStack spacing={3} py={2}>
            <FormControl alignItems='center'>
              <HStack w='full' alignItems='flex-start'>
                <Input
                  id='kode_voucher'
                  name='kode_voucher'
                  placeholder='Punya kode voucher? Masukin disini ✨'
                  onChange={(e: any) => {
                    setVoucherCode(e.target.value);
                  }}
                  value={voucherCode}
                />
                <Button
                  variant='primary'
                  onClick={() => {
                    validateVoucher(voucherCode);
                  }}
                  isDisabled={!isOnline || voucherCode === ''}
                  isLoading={loadingValidate}
                >
                  Pakai
                </Button>
              </HStack>
            </FormControl>
          </HStack>
          <VStack
            w='full'
            position='relative'
            h='100%'
            style={{ overflowY: 'auto', maxHeight: '350px' }}
            flex={1}
            alignSelf='flex-start'
          >
            <Accordion defaultIndex={accordionIndex} allowMultiple w='full'>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      Promosi Aktif
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <ListPromotion activePromotion={activePromotion} />
              </AccordionItem>
              {isOnline && settings.choose_promotion_type === 'choose-promotion' && (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex='1' textAlign='left'>
                        Promosi Lainnya
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <ListPromotion anotherPromotion={anotherPromotions} />
                </AccordionItem>
              )}
            </Accordion>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button variant='outline' size='md' onClick={closePopup}>
              Batal
            </Button>
            <Button variant='primary' onClick={onSave} type='submit' isDisabled={existVoucher || !isOnline}>
              Simpan
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(ModalVoucher);
