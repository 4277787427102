import { Button } from '@chakra-ui/button';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Flex, FormControl, FormLabel, HStack, Input, Stack, Switch } from '@chakra-ui/react';
import BadgeInformation from 'components/badge/BadgeInformation';
import { Title } from 'components/typography';
import { CardVoucher } from 'components/ui/checkout';
import config from 'constant';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import shipmentRequest from 'services/http/shipment.request';
import { Maybe } from 'types';
import { IPayments } from 'types/common.types';
import { IFormPaymentMethod } from 'types/sales.types';
import { IVoucherValidate } from 'types/shipment.types';
import { convertToLocalDate, currencyFormat } from 'utils';

interface ModalVoucherProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IFormPaymentMethod) => void;
  paymentsMethod: IPayments[];
}

const ModalVoucher: React.FC<ModalVoucherProps> = ({
  isOpen,
  onClose,
  onSubmit,
  paymentsMethod,
}: ModalVoucherProps) => {
  const { notification } = useNotification();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const sales = useAppSelector((state) => state.sales);

  const [activateVoucher, setActivateVoucher] = React.useState<boolean>(false);
  const [voucherCode, setVoucherCode] = React.useState<string>('');
  const [voucherValidation, setVoucherValidation] = React.useState<Maybe<IVoucherValidate>>(null);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);
  const [existVoucher, setExistVoucher] = React.useState<boolean>(false);

  const [data, setData] = React.useState<IFormPaymentMethod>({} as IFormPaymentMethod);

  const onSave = () => {
    const findExistVoucher = sales.listPayments.find((payment) => payment.no_ref === voucherCode);
    if (findExistVoucher) {
      return notification('', 'Voucher sudah digunakan', 'warning', 5000);
    }
    const voucherMethod = paymentsMethod.find((payment) => payment.payment_id === config.VOUCHER);
    data.payments = voucherMethod as IPayments;
    data.payments = {
      ...data.payments,
      notes: 'Voucher Jubelio Shipment',
    };
    onSubmit(data);
    setVoucherValidation(null);
    setActivateVoucher(false);
    setVoucherCode('');
    setExistVoucher(false);
    setData({} as IFormPaymentMethod);
  };

  const closeVoucher = () => {
    onClose();
    setVoucherValidation(null);
    setActivateVoucher(false);
    setVoucherCode('');
    setExistVoucher(false);
    setData({} as IFormPaymentMethod);
  };

  const validateVoucher = async (voucher: string) => {
    try {
      setLoadingValidate(true);
      const res = await shipmentRequest.validateVoucher(voucher);
      setVoucherValidation(res);
      setData({ ...data, payment_amount: res.amount ?? 0 });
      setLoadingValidate(false);
    } catch (error: any) {
      setVoucherValidation(null);
      let message = 'Unknown Error';
      if (error) message = error.response.data.message;
      setLoadingValidate(false);
      return notification('', message, 'warning', 5000);
    }
  };

  React.useEffect(() => {
    if (sales.listPayments && voucherCode !== '') {
      const findExistVoucher = sales.listPayments.find((payment) => payment.no_ref === voucherCode);
      if (findExistVoucher) {
        setVoucherValidation(null);
        setExistVoucher(true);
      } else {
        setVoucherValidation(null);
        setExistVoucher(false);
      }
    }
  }, [sales.listPayments, voucherCode]);

  return (
    <Modal isOpen={isOpen} onClose={closeVoucher} size='xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Masukkan Kode Voucher</ModalHeader>
        <ModalBody
          borderTop='1px'
          borderBottom='1px'
          borderColor='gray.200'
          py={4}
          minHeight='350px'
        >
          <BadgeInformation mb='12px' colorScheme='information' w='full' py={3} px={5}>
            Ketik atau scan kode voucher pelanggan jika voucher merupakan voucher <br /> Jubelio
            Shipment, aktifkan terlebih dahulu "Voucher Jubelio Shipment".
          </BadgeInformation>
          <Flex justifyContent='space-between' mt={4}>
            <Title fontSize={14} color='jubelio.black'>
              Aktifkan Vocuher Jubelio Shipment
            </Title>
            <Switch
              colorScheme='red'
              onChange={(e: any) => setActivateVoucher(e.target.checked)}
              isChecked={activateVoucher}
            />
          </Flex>
          <HStack spacing={3} mt={4}>
            <FormControl
              alignItems='center'
              // isInvalid={isError !== ''}
            >
              <FormLabel
                w='full'
                htmlFor='kode_voucher'
                fontSize='14px'
                fontWeight='bold'
                color='jubelio.black'
              >
                Kode Voucher
              </FormLabel>
              <HStack w='full' alignItems='flex-start'>
                <Input
                  id='kode_voucher'
                  name='kode_voucher'
                  placeholder='Masukkan Kode Voucher'
                  onChange={(e: any) => {
                    setVoucherCode(e.target.value);
                    setData({ ...data, no_ref: e.target.value });
                  }}
                  value={voucherCode}
                  isDisabled={!activateVoucher}
                />
                <Button
                  variant='primary'
                  onClick={() => {
                    validateVoucher(voucherCode);
                  }}
                  isDisabled={!activateVoucher || !isOnline || voucherCode === ''}
                  isLoading={loadingValidate}
                >
                  Cari
                </Button>
              </HStack>
            </FormControl>
          </HStack>
          {voucherValidation && (
            <Stack w='full' mt={8}>
              {voucherValidation.is_valid && !existVoucher ? (
                <CardVoucher
                  isSuccess={true}
                  title={voucherValidation.message}
                  subtitle={`Pelanggan mendapatkan potongan belanja sebesar ${currencyFormat(
                    voucherValidation.amount
                  )}`}
                  description={`Hingga ${
                    voucherValidation.end_period
                      ? convertToLocalDate(voucherValidation.end_period)
                      : ''
                  }`}
                />
              ) : (
                <CardVoucher
                  isSuccess={false}
                  title={existVoucher ? 'Voucher tidak dapat digunakan' : voucherValidation.message}
                  subtitle={`${
                    existVoucher ? 'Voucher tidak dapat digunakan' : voucherValidation.message
                  }, silahkan menggunakan voucher lain.`}
                />
              )}
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={3}>
            <Button
              variant='outline'
              size='md'
              onClick={() => {
                setVoucherValidation(null);
                setActivateVoucher(false);
                setVoucherCode('');
                setExistVoucher(false);
                setData({} as IFormPaymentMethod);
                onClose();
              }}
            >
              Batal
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                onSave();
              }}
              type='submit'
              isDisabled={!voucherValidation?.is_valid || existVoucher || !isOnline}
            >
              Simpan
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(ModalVoucher);
