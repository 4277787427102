import { Button, Checkbox, Divider, Flex, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { SubTitle, Title } from 'components/typography';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { IPayments } from 'types/common.types';
import { currencyFormat } from 'utils';

import { Modal } from '.';

interface ModalPaymentCashProps {
  isOpen: boolean;
  duePayments: number;
  grand_total: number;
  listPayments: IPayments[];
  onClose: () => void;
  onSubmit: (values: number, isDp: boolean, dpAmount: number) => void;
  continuePayment?: boolean;
  remainingPayment?: number;
}

const ModalPaymentCash: React.FC<ModalPaymentCashProps> = ({
  isOpen,
  onClose,
  onSubmit,
  grand_total,
  duePayments,
  listPayments,
  continuePayment,
  remainingPayment,
}: ModalPaymentCashProps) => {
  const { settings } = useAppSelector((state) => state.commons);
  const { notification } = useNotification();
  const [nominal, setNominal] = React.useState<number>(0);
  const [dp, setDp] = React.useState<number>(0);
  const [isDp, setIsDp] = React.useState<boolean>(false);

  const onSave = React.useCallback(() => {
    if (Number(dp) > Number(nominal)) {
      notification('', 'DP tidak boleh melebihi nominal dibayar', 'warning');
      return;
    }
    onSubmit(nominal, isDp, dp);
    onClose();
    setNominal(0);
    setIsDp(false);
    setDp(0);
  }, [dp, nominal, isDp]);

  const getNominal = () => {
    let nominal = 0;
    let dp = 0;
    let isDp = false;
    if (listPayments.length > 0) {
      const findCashPayment = listPayments.find((p) => p.payment_id === -1);
      if (!findCashPayment) {
        nominal = Math.abs(duePayments);
        dp = nominal;
      } else {
        nominal = Number(findCashPayment.payment_amount ?? 0);
        dp = findCashPayment.dp_amount ?? 0;
        isDp = findCashPayment.is_dp ?? false;
      }
    } else {
      if (continuePayment) nominal = Number(remainingPayment);
      else nominal = Number(grand_total);
      dp = nominal;
    }
    setNominal(nominal);
    setDp(dp);
    setIsDp(isDp);
  };

  React.useEffect(() => {
    if (isOpen) {
      getNominal();
    }
  }, [isOpen]);

  return (
    <Modal isShow={isOpen} onClose={onClose} title='Uang Tunai'>
      <Modal.Body>
        <SubTitle>Total yang harus dibayar</SubTitle>
        <Title my={2} color='jubelio.primary'>
          {currencyFormat(nominal)}
        </Title>
        <Divider my={4} />
        <VStack spacing={4}>
          <FormControl display='flex' className='space-x-5' alignItems='center'>
            <FormLabel w='92px' htmlFor='payment_amount' fontSize='14px'>
              Nominal
            </FormLabel>
            <Input
              id='payment_amount'
              name='payment_amount'
              format='currency'
              isgrouped
              labelGroup='Rp'
              onChange={(e) => {
                setNominal(Number(e) < 0 ? 0 : e);
                setDp(Number(e) < 0 ? 0 : e);
              }}
              value={nominal}
            />
          </FormControl>
          {settings.allow_paylater && (
            <FormControl display='flex' alignItems='flex-start'>
              <FormLabel w='92px' htmlFor='dp_amount' fontSize='14px' mt={3}>
                DP
              </FormLabel>
              <VStack w='full' ml={5}>
                <Flex w='full' gridGap={3}>
                  <Checkbox
                    size='md'
                    colorScheme='red'
                    isChecked={isDp ?? false}
                    onChange={(e) => setIsDp(e.target.checked)}
                  />
                  <Flex w='full'>
                    <Input
                      id='dp_amount'
                      name='dp_amount'
                      format='currency'
                      isgrouped
                      labelGroup='Rp'
                      onChange={(e) => setDp(Number(e) < 0 ? 0 : e)}
                      value={dp}
                      isDisabled={!isDp}
                    />
                  </Flex>
                </Flex>
                <SubTitle mt='8px' fontSize='12px' w='full'>
                  Aktifkan DP jika pembayaran memerlukan kembalian <br />
                  Contoh: DP = Rp 120.000, Nominal dibayar = Rp 150.000 <br />
                  Kembalian = Rp 30.000
                </SubTitle>
              </VStack>
            </FormControl>
          )}
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onSave} type='submit' loadingText='Menyimpan..'>
          Tunai
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ModalPaymentCash);
