import { useQuery } from '@tanstack/react-query';
import { useOrder, useReturn } from 'hooks';
import React from 'react';
import { setAutoSync } from 'redux/reducer/registers';
import { setSyncTransaction } from 'redux/reducer/settings';
import transactionRequest from 'services/http/transaction.request';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { IReturnList } from 'types/return.types';
import { IOrderData } from 'types/sales.types';
import { IDetailTransaction } from 'types/transaction.types';

import { useAppDispatch, useAppSelector } from './redux';
import useOrderDownPayment from './useOrderDownPayment';

interface IUseGetTransaction {
  syncAllTransaction: (search: string, page: number) => Promise<void>;
  sendFailTransaction: () => Promise<void>;
}

const useGetTransaction = (): IUseGetTransaction => {
  const { sendOrder, cancelOrder } = useOrder();
  const { sendOrderDownPayment } = useOrderDownPayment();
  const { sendOrderReturn, cancelReturn } = useReturn();

  const dispatch = useAppDispatch();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const location = useAppSelector((state) => state.register.location);

  const { refetch: refetchTransactions } = useQuery({
    queryKey: [
      'mutate-transactions',
      {
        page: 1,
        pageSize: 300,
        location_id: location?.location_id as number,
        closure_id: currentClosure?.closure_id as number,
      },
    ],
    queryFn: transactionRequest.getTransactions,
    enabled: isOnline,
  });

  const { refetch: refetchReturn } = useQuery({
    queryKey: [
      'mutate-return',
      {
        page: 1,
        pageSize: 300,
        location_id: location?.location_id as number,
        closure_id: currentClosure?.closure_id as number,
      },
    ],
    queryFn: transactionRequest.getReturn,
    enabled: isOnline,
  });

  /**
   * Get order transaction
   */
  const getOrderTransaction = async () => {
    try {
      const transactionList = await refetchTransactions();
      if (!transactionList.data) return;

      const transaction: IDetailTransaction[] = [];
      await Promise.all(
        transactionList.data.data.map(async (data: IDetailTransaction) => {
          const order = await transactionRequest.getTransactionDetail(data.salesorder_id);
          if (order?.items?.length) {
            order.items = order.items.map((item: any) => {
              const { promotion_price, disc_amount, promotion_name } = item;
              if (
                Number(promotion_price) > 0 &&
                (Number(promotion_price) === Number(disc_amount) || !promotion_name)
              ) {
                item.promotion_price = 0;
              }
              return item;
            });
          }
          transaction.push({
            ...data,
            ...order,
            promotions: order.promotions,
            is_return: order.pos_return_id !== null && order.return_canceled !== true ? 1 : 0,
            hasRetur: order.pos_return_id !== null && order.return_canceled !== true ? true : false,
            pos_return_id: order.pos_return_id,
            pos_return_no: order.pos_return_no,
            is_paid: 1,
          });
        })
      );

      await db.order
        .filter((o) => {
          return o.is_paid === 1 && o.closure_id === (currentClosure?.closure_id as number);
        })
        .toArray()
        .then(async (res) => {
          const listKey = res.map((order) => order.key);
          await db.order.bulkDelete(listKey);
        });

      await db.order.bulkAdd(transaction);

      return;
    } catch (error) {
      console.log('[ERROR] Get order transaction', error);
      return Promise.reject(error);
    }
  };

  /**
   * Get return transaction
   */
  const getReturnTransaction = async () => {
    try {
      const returnList = await refetchReturn();
      if (!returnList?.data) return;
      const transaction: IReturnList[] = [];

      await Promise.all(
        returnList.data.data.map(async (data) => {
          const order = await transactionRequest.getReturnDetail(data.pos_return_id);
          const notHavePayments = [
            {
              payment_amount: parseInt(order.grand_total),
              payment_charge: 0,
              payment_fee: 0,
              payment_id: -3,
              pos_payment_id: 0,
              pos_return_id: 0,
            },
          ];
          transaction.push({
            ...data,
            ...order,
            is_return: 1,
            is_paid: 1,
            payments: !order.payments ? notHavePayments : order.payments,
            promotions: [],
          });
        })
      );

      await db.return
        .filter((o) => {
          return o.is_paid === 1 && o.closure_id === (currentClosure?.closure_id as number);
        })
        .toArray()
        .then(async (res) => {
          const listKey = res.map((retur) => retur.key);
          await db.return.bulkDelete(listKey);
        });
      await db.return.bulkAdd(transaction);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Sync all transaction with this handler
   */
  const syncAllTransaction = async () => {
    try {
      if (!isOnline) return;

      await getOrderTransaction();
      await getReturnTransaction();
      dispatch(setSyncTransaction(true));
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendFailTransaction = React.useCallback(async () => {
    try {
      dispatch(setAutoSync(true));

      const [failTransaction, failCanceledTransaction, failReturn, failCanceledReturn, failContinuePayment] =
        await Promise.all([
          orders.get('', 3),
          orders.getCanceled(),
          returnOrder.getUnsync(''),
          returnOrder.getCanceled(),
          orders.getFailedContinuePayment(),
        ]);

      const processTasks = [
        ...failTransaction.map((transaction) => {
          const payload = {
            ...JSON.parse(transaction.request_payload),
            is_canceled: Boolean(JSON.parse(transaction.request_payload).is_canceled),
            is_send_email: transaction.is_send_email,
          };
          return sendOrder(payload);
        }),
        ...failCanceledTransaction.map((transaction) => cancelOrder(transaction)),
        ...failReturn.map((orderReturn) => {
          const payload = {
            ...JSON.parse(orderReturn.request_payload),
            is_canceled: Boolean(JSON.parse(orderReturn.request_payload).is_canceled),
            is_send_email: orderReturn.is_send_email,
          };
          return sendOrderReturn(payload);
        }),
        ...failCanceledReturn.map((transaction) => cancelReturn(transaction)),
        ...failContinuePayment.map((transaction) => {
          const payload = {
            ...transaction,
            pos_is_unpaid: Boolean(Number(transaction.pos_is_unpaid)),
          };
          return sendOrderDownPayment(payload as unknown as IOrderData, true);
        }),
      ];

      await Promise.all(processTasks);

      dispatch(setAutoSync(false));
    } catch (error) {
      dispatch(setAutoSync(false));
      return Promise.reject(error);
    }
  }, [dispatch, sendOrder, cancelOrder, sendOrderReturn, cancelReturn, sendOrderDownPayment]);

  return {
    syncAllTransaction,
    sendFailTransaction,
  };
};

export default useGetTransaction;
