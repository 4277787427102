import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BopisIcon = (props: IconProps): JSX.Element => (
  <Icon width='24' height='24' viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6999 10.5002V9.6002V9.3002H14.6999V9.6002V10.5002H16.4999V9.6002V9.3002H17.7854L18.8054 19.5002H7.59439L7.71439 18.3002H9.5999V16.5002H4.7999V18.3002H5.90541L5.70437 20.3106C5.67907 20.5637 5.76197 20.8156 5.93259 21.0041C6.10322 21.1926 6.34563 21.3002 6.5999 21.3002H19.7999C20.0542 21.3002 20.2966 21.1926 20.4672 21.0041C20.6378 20.8156 20.7207 20.5637 20.6954 20.3106L19.4954 8.31064C19.4494 7.85056 19.0623 7.5002 18.5999 7.5002H16.4999V6.17162C16.4999 4.3139 15.0799 2.7002 13.1999 2.7002C11.3199 2.7002 9.8999 4.3139 9.8999 6.17162V7.5002H7.7999C7.33753 7.5002 6.95038 7.85056 6.90437 8.31064L6.68541 10.5002H2.3999V12.3002H8.3999V11.4451L8.61439 9.3002H9.8999V9.6002V10.5002H11.6999ZM14.6999 6.17162V7.5002H11.6999V6.17162C11.6999 5.18903 12.429 4.5002 13.1999 4.5002C13.9709 4.5002 14.6999 5.18903 14.6999 6.17162ZM3.5999 13.5002H8.9999V15.3002H3.5999V13.5002Z'
    />
  </Icon>
);

export default BopisIcon;
