import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Card } from 'components/card';
import config from 'constant';
import React from 'react';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import { Maybe } from 'types';
import { IUserInfo } from 'types/auth.types';

interface CardProfileProps {
  profile: Maybe<IUserInfo | undefined>;
}

const CardProfile: React.FC<CardProfileProps> = ({ profile }: CardProfileProps) => {
  const IMAGE = `${config.IMAGE_URL}?token=${LocalStorageService.getItem('token')}`;
  return (
    <Card p={2} position='relative'>
      <Flex alignItems='center' position='relative'>
        <Image
          boxSize='40px'
          rounded='full'
          loading='lazy'
          objectFit='cover'
          display={['none', 'none', 'none', 'block']}
          src={IMAGE}
          fallbackSrc='https://via.placeholder.com/150'
        />
        <Box ml={2} wordBreak='break-all' fontSize={['12px', '11px', '11px', '13px', '14px']}>
          <Text fontWeight='bold'>{profile?.user.full_name}</Text>
          <Text>{profile?.email}</Text>
        </Box>
      </Flex>
    </Card>
  );
};

export default CardProfile;
