import React from 'react';
import { useLocation } from 'react-router-dom';
import commonRequest from 'services/http/common.request';
import authorizedUser from 'services/indexdb/authorized-user';
import { db } from 'services/indexdb/connection';
import contactcategory from 'services/indexdb/contact-category';
import courier from 'services/indexdb/courier';
import customers from 'services/indexdb/customers';
import marketplace from 'services/indexdb/marketplace-integration';
import paymentMethod from 'services/indexdb/payment-method';
import presets from 'services/indexdb/presets';
import salesmen from 'services/indexdb/salesmen';
import {
  IAuthorizedUser,
  IContactCategory,
  ICourier,
  ICustomerInfo,
  ICustomerInfoInput,
  ICustomerRoot,
  IMarketplaceIntegration,
  IPresets,
  ISalesman,
  ITaxes,
} from 'types/common.types';
import { mappingToSyncContact } from 'utils';

type UseCommon = {
  getPresets: (type: string) => Promise<IPresets[]>;
  getCustomers: (contactList: ICustomerRoot) => Promise<void>;
  getSalesmen: () => Promise<ISalesman[]>;
  getPayments: () => Promise<number>;
  getContactCategory: () => Promise<IContactCategory[]>;
  getMarketplace: () => Promise<IMarketplaceIntegration[]>;
  getCourier: () => Promise<ICourier[]>;
  getAuthorizedUser: (payload: { location_id: number; permission_id: number[] }) => Promise<boolean>;
  setPickedCustomer: (value: number) => void;
  sendCustomer: (values: ICustomerInfoInput) => Promise<boolean>;
  sendFailContact: () => Promise<void>;
  pickedCustomer: number;
  isGetCustomer: boolean;
  disableEditItem: boolean;
};

const useCommon = (): UseCommon => {
  const [pickedCustomer, setPickedCustomer] = React.useState<number>(0);
  const [isGetCustomer, setiSGetCustomer] = React.useState<boolean>(true);
  const location = useLocation();

  const disableEditItem = ['/sales/checkout', '/sales/return-checkout'].includes(location.pathname);

  const getPresets = async (type: string): Promise<IPresets[]> => {
    try {
      let res;
      if (type === 'DISCOUNT') {
        res = await commonRequest.getPresets(type);

        await presets.clear(type);
        res.forEach(async (presets) => {
          savePreset(type, presets);
        });
      } else {
        res = await commonRequest.getTaxes();

        await presets.clear(type);
        res.forEach(async (presets: ITaxes) => {
          const taxToPreset = {
            pos_preset_id: presets.tax_id,
            preset_type: 'TAX',
            amount_type: 1,
            preset_amount: presets.rate,
            preset_title: presets.tax_name,
          };
          savePreset(type, taxToPreset);
        });
      }

      const resDb = await presets.getAll(type);
      return Promise.resolve(resDb);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save presets to IndexDB database
   * @param type - type of presets
   * @param presets - presets to save
   * @returns {Promise<void>}
   */
  const savePreset = async (type: string, preset: any): Promise<boolean> => {
    try {
      await presets.addPresets(type, preset);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getCustomers = async (contactList: ICustomerRoot): Promise<void> => {
    try {
      setiSGetCustomer(true);
      if (contactList.data.length > 0) await saveCustomer(contactList.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const saveCustomer = async (customer: ICustomerInfo[]): Promise<void> => {
    try {
      await customers.bulkAdd(customer);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getSalesmen = async () => {
    try {
      const res = await commonRequest.getSalesman(1, 200);

      await salesmen.clear();
      res.data.forEach(async (salesman) => {
        await salesmen.add(salesman);
      });
      const resSalesmen = await salesmen.get();
      return Promise.resolve(resSalesmen);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getContactCategory = async () => {
    try {
      const res = await commonRequest.getContactCategory(1, 200);

      await contactcategory.clear();
      res.data.forEach(async (category) => {
        await contactcategory.add(category);
      });
      const resContactCategory = await contactcategory.get();
      return Promise.resolve(resContactCategory);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getMarketplace = async () => {
    try {
      const res = await commonRequest.getMarketplace();

      await marketplace.clear();
      await Promise.all(
        res.map(async (marketplaceIntegration) => await marketplace.add(marketplaceIntegration))
      );
      const resMarketplace = await marketplace.get();
      return Promise.resolve(resMarketplace);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getPayments = async () => {
    try {
      const res = await commonRequest.getPayments();
      await paymentMethod.clear();
      const result = await paymentMethod.bulkdAdd(res);

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getCourier = async (): Promise<ICourier[]> => {
    try {
      const res = await commonRequest.getCourier();
      await courier.clear();
      await saveCourier(res);

      const resDb = await courier.getAll();
      return Promise.resolve(resDb);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save courier to IndexDB database
   * @param courier - courier to save
   * @returns {Promise<void>}
   */
  const saveCourier = async (couriers: ICourier[]): Promise<boolean> => {
    try {
      await courier.addCourier(couriers);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendCustomer = async (values: ICustomerInfoInput): Promise<boolean> => {
    try {
      const res = await commonRequest.createCustomer(values);
      await db.customer
        .where('email')
        .equals(values?.email ?? '')
        .modify({
          contact_id: res.contact_id,
        });
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const sendFailContact = async () => {
    try {
      const failContact = await customers.getUnsync();
      if (failContact.length > 0) {
        for (const contact of failContact) {
          const paramContact = mappingToSyncContact(contact);
          await sendCustomer(paramContact);
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save authorizedUser to IndexDB database
   * @param type - type of authorizedUser
   * @param authorizedUser - authorizedUser to save
   * @returns {Promise<void>}
   */
  const saveAuthorizedUser = async (permissionId: number, authorizedUserList: any): Promise<boolean> => {
    try {
      await authorizedUser.addAuthorizedUser(permissionId, authorizedUserList);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAuthorizedUser = async (payload: {
    location_id: number;
    permission_id: number[];
  }): Promise<boolean> => {
    try {
      const res = await commonRequest.getAuthorizedUser(payload);

      payload.permission_id.forEach(async (permission) => {
        await authorizedUser.clear();
        res.acls[permission].forEach(async (authorizedUser: IAuthorizedUser) => {
          saveAuthorizedUser(permission, authorizedUser);
        });
      });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedCustomer !== 0 && pickedCustomer === 100) {
      setiSGetCustomer(false);
    }
  }, [pickedCustomer]);

  return {
    getCustomers,
    getPresets,
    pickedCustomer,
    isGetCustomer,
    getSalesmen,
    disableEditItem,
    getPayments,
    setPickedCustomer,
    getCourier,
    getContactCategory,
    sendCustomer,
    sendFailContact,
    getMarketplace,
    getAuthorizedUser,
  };
};

export default useCommon;
