import EmptyCartSaved from 'assets/svg/empty-cart-saved.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import { TableDeviceTransactionSales } from 'components/table';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { IDetailTransaction } from 'types/transaction.types';
import { getPagination } from 'utils';

interface SalesProps {
  searchOnline: string;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  transactionsList: IDetailTransaction[];
  setDetailOrder: (detailOrder: DetailOrderTransaction) => void;
  onSearch: (value: string) => void;
  openPopupSync: () => void;
  onOpenConfirmationDelete: () => void;
  setDeleteAll: (isAll: boolean) => void;
}

const Sales: React.FC<SalesProps> = ({
  searchOnline,
  page,
  pageSize,
  setPage,
  setPageSize,
  transactionsList,
  setDetailOrder,
  onSearch,
  openPopupSync,
  onOpenConfirmationDelete,
  setDeleteAll,
}: SalesProps) => {
  const paginationRef = React.useRef<any>();

  const paginateMemo = React.useMemo(() => {
    return getPagination(transactionsList, page, pageSize).sort((a, b) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });
  }, [page, pageSize, transactionsList]);

  return (
    <React.Fragment>
      <div className='h-full w-full rounded-lg'>
        <div className='flex items-center gap-3 pb-2'>
          <SearchBar
            placeholder='Temukan Transaksi'
            onSearch={(e) => onSearch(e.target.value)}
            value={searchOnline}
            disableEnter
          />
        </div>
        <TableDeviceTransactionSales
          isLoading={true}
          listTransaction={paginateMemo}
          setOrder={setDetailOrder}
          searchOnline={searchOnline}
          isReturn={false}
          openPopupSync={openPopupSync}
          onOpenConfirmationDelete={onOpenConfirmationDelete}
          setDeleteAll={setDeleteAll}
        />
        {paginateMemo && paginateMemo.length === 0 && (
          <div className='flex h-full max-h-[300px] w-full flex-col items-center justify-center p-5'>
            <EmptySearch
              icons={EmptyCartSaved}
              title='Belum ada transaksi'
              subtitle='Anda dapat kembali ke menu penjualan untuk melakukan penjualan'
            />
          </div>
        )}
      </div>
      <Pagination
        p='20px'
        position='absolute'
        refPage={paginationRef}
        onChangePage={(e) => setPage(e.selected + 1)}
        pageSize={pageSize}
        page={page}
        onChangeRow={(e) => {
          setPage(1);
          setPageSize(e);
        }}
        totalPage={transactionsList.length}
      />
    </React.Fragment>
  );
};
export default React.memo(Sales);
