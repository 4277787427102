import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SignOutIcon = (props: IconProps): JSX.Element => (
  <Icon width='19' height='19' viewBox='0 0 19 19' fill='none' {...props}>
    <path
      d='M4 3.5C3.46957 3.5 2.96086 3.71071 2.58579 4.08579C2.21071 4.46086 2 4.96957 2 5.5V13.5C2 14.0304 2.21071 14.5391 2.58579 14.9142C2.96086 15.2893 3.46957 15.5 4 15.5H7.33333C7.51014 15.5 7.67971 15.4298 7.80474 15.3047C7.92976 15.1797 8 15.0101 8 14.8333C8 14.6565 7.92976 14.487 7.80474 14.3619C7.67971 14.2369 7.51014 14.1667 7.33333 14.1667H4C3.82319 14.1667 3.65362 14.0964 3.5286 13.9714C3.40357 13.8464 3.33333 13.6768 3.33333 13.5V5.5C3.33333 5.32319 3.40357 5.15362 3.5286 5.0286C3.65362 4.90357 3.82319 4.83333 4 4.83333H7.33333C7.51014 4.83333 7.67971 4.7631 7.80474 4.63807C7.92976 4.51305 8 4.34348 8 4.16667C8 3.98986 7.92976 3.82029 7.80474 3.69526C7.67971 3.57024 7.51014 3.5 7.33333 3.5H4Z'
      fill='#F44336'
    ></path>
    <path
      d='M10.1953 6.36218C10.3204 6.2372 10.4899 6.16699 10.6667 6.16699C10.8434 6.16699 11.013 6.2372 11.138 6.36218L13.8047 9.02885C13.9296 9.15387 13.9999 9.32341 13.9999 9.50018C13.9999 9.67696 13.9296 9.8465 13.8047 9.97152L11.138 12.6382C11.0123 12.7596 10.8439 12.8268 10.6691 12.8253C10.4943 12.8238 10.3271 12.7537 10.2035 12.6301C10.0798 12.5065 10.0097 12.3392 10.0082 12.1645C10.0067 11.9897 10.0739 11.8213 10.1953 11.6955L11.724 10.1668H6.66667C6.48986 10.1668 6.32029 10.0966 6.19526 9.97159C6.07024 9.84656 6 9.67699 6 9.50018C6 9.32337 6.07024 9.1538 6.19526 9.02878C6.32029 8.90375 6.48986 8.83352 6.66667 8.83352H11.724L10.1953 7.30485C10.0704 7.17983 10.0001 7.01029 10.0001 6.83352C10.0001 6.65674 10.0704 6.4872 10.1953 6.36218Z'
      fill='#F44336'
    ></path>
  </Icon>
);

export default SignOutIcon;
