import EmptyCartSaved from 'assets/svg/empty-cart-saved.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import { TableHistoryTransaksi } from 'components/table';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { FragmentReturn, IReturnList } from 'types/return.types';
import { getPagination } from 'utils';

interface Props {
  searchOnline: string;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  transactionsList: IReturnList[];
  setDetailOrder: (detailOrder: DetailOrderTransaction) => void;
  onlineReturn?: FragmentReturn;
  onSearch: (value: string) => void;
}

const Retur: React.FC<Props> = ({
  page,
  setPage,
  setPageSize,
  pageSize,
  transactionsList,
  setDetailOrder,
  searchOnline,
  onlineReturn,
  onSearch,
}: Props) => {
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const currentLocation = useAppSelector((state) => state.register.location);

  const filterTransactionReturList = transactionsList
    ? transactionsList.filter((retur) => {
        if (
          retur.closure_id === Number(currentClosure?.closure_id ?? 0) &&
          retur.location_id === Number(currentLocation?.location_id ?? 0)
        )
          return retur;
      })
    : [];

  const paginateMemo = React.useMemo(() => {
    if ((searchOnline === '' || !isOnline) && filterTransactionReturList) {
      const listRetur = getPagination(filterTransactionReturList, page, pageSize).sort((a, b) => {
        return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
      });
      return listRetur;
    } else if (isOnline && searchOnline !== '') {
      return onlineReturn && onlineReturn.data
        ? onlineReturn.data.filter((retur) => {
            {
              retur.closure_id = Number(currentClosure?.closure_id);
              retur.location_id = Number(currentLocation?.location_id);

              if (
                retur.closure_id === Number(currentClosure?.closure_id ?? 0) &&
                retur.location_id === Number(currentLocation?.location_id ?? 0)
              )
                return retur;
            }
          })
        : [];
    }
  }, [page, pageSize, transactionsList, searchOnline, onlineReturn]);

  return (
    <div className='flex h-full w-full flex-col rounded-lg'>
      <div className='flex items-center gap-3 pb-2'>
        <SearchBar
          placeholder='Temukan Transaksi'
          onSearch={(e) => onSearch(e.target.value)}
          disableEnter
        />
      </div>
      <div className='flex-1 items-center justify-center overflow-y-auto'>
        {paginateMemo && paginateMemo.length === 0 ? (
          <EmptySearch
            icons={EmptyCartSaved}
            title='Belum ada transaksi'
            subtitle='Anda dapat kembali ke menu penjualan untuk melakukan penjualan'
          />
        ) : (
          <TableHistoryTransaksi
            isLoading={true}
            listTransaction={paginateMemo}
            setOrder={setDetailOrder}
            searchOnline={searchOnline}
            isReturn={true}
          />
        )}
      </div>
      <div className='bg-white pt-3'>
        <Pagination
          onChangePage={(e) => setPage(e.selected + 1)}
          totalPage={
            searchOnline === '' && transactionsList
              ? Number(filterTransactionReturList.length)
              : Number(onlineReturn?.totalCount)
          }
          pageSize={pageSize}
          page={page}
          onChangeRow={(e) => {
            setPage(1);
            setPageSize(e);
          }}
          p='20px'
          position='absolute'
        />
      </div>
    </div>
  );
};

export default Retur;
