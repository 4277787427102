/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DISCOUNT_TYPES } from 'constant';
import {
  CheckPromotionFunc,
  CheckPromotionResp,
  IGetPromotionData,
  ResPromotionTransactionAmount,
} from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { getDiscountOutlet, getSaleTotalAfterItemDiscount } from 'utils/calculation';

export const getTransactionDiscount = (promotion: ResPromotionTransaction): number => {
  let discount = 0;
  if (promotion.rules.discount_type === DISCOUNT_TYPES.Percentage) {
    discount =
      (promotion.checkLists.saleAfterDiscount.current * promotion.rules.discount_amount) / 100;
  } else {
    discount = Number(promotion.rules.discount_amount);
    if (promotion.rules.multiplicative && (promotion?.multiplier as number) > 1) {
      discount = discount * Number(promotion?.multiplier);
    }
  }

  if (promotion.rules.total_discount > 0 && discount > promotion.rules.total_discount) {
    discount = Number(promotion.rules.total_discount);
  }

  return discount;
};

export const formatMinimalTransaction = (promotion: CheckPromotionResp) => {
  return {
    promotion_id: promotion.rules.promotion_id,
    detail: {
      type: 'transaction',
      discount: promotion.transactionDiscount,
    },
  };
};

type ResPromotionTransaction = Omit<CheckPromotionResp, 'checkLists'> & {
  checkLists: {
    saleAfterDiscount: {
      current: number;
      expected: number;
    };
  };
};

export const checkMinimalTransaction = ({
  promotion,
  itemCart,
  location,
}: CheckPromotionFunc): ResPromotionTransaction | null => {
  const totalAfterDiscount = getSaleTotalAfterItemDiscount(itemCart);
  const locationDiscount = promotion.ignore_outlet_discount
    ? 0
    : getDiscountOutlet(totalAfterDiscount, location as ILocations);
  const saleAfterDiscount = totalAfterDiscount - locationDiscount;
  if (Number(promotion.minimum_spending) > saleAfterDiscount) return null;

  const p: ResPromotionTransaction = {
    rules: promotion,
    checkLists: {
      saleAfterDiscount: {
        current: saleAfterDiscount,
        expected: Number(promotion.minimum_spending),
      },
    },
    multiplier: saleAfterDiscount / Number(promotion.minimum_spending),
    transactionDiscount: 0,
  };

  p['transactionDiscount'] = getTransactionDiscount(p);

  return p;
};

export const checkDiscountAmount = (
  promotion: IGetPromotionData,
  saleAfterDiscount: number
): ResPromotionTransactionAmount | null => {
  const p: ResPromotionTransactionAmount = {
    promotion,
    saleAfterDiscount,
    transactionDiscount: 0,
  };

  p['transactionDiscount'] =
    promotion.discount_reward && promotion.discount_reward.length > 0
      ? promotion.discount_reward[0].total_discount
      : 0;

  return p;
};
