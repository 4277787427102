import { Box, Select, useMediaQuery } from '@chakra-ui/react';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import TableBopis from 'components/table/TableBopis';
import React from 'react';
import {
  ICompleted,
  IDetailTransaction,
  IPacklist,
  IReadyToPick,
  IResponseShipped,
  IShipped,
} from 'types/bopis.types';

interface Props {
  searchOnline: string;
  page: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  transactionsList: IResponseShipped;
  setDetailOrder: (detailOrder: IDetailTransaction) => void;
  tabIndex: number;
  setSearch: (key: string, value: string) => void;
  setFilter: (key: string, value: string) => void;
}

const ShippedBopis: React.FC<Props> = ({
  page,
  setPage,
  setPageSize,
  pageSize,
  transactionsList,
  setDetailOrder,
  searchOnline,
  tabIndex,
  setSearch,
  setFilter,
}: Props) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');
  return (
    <>
      <Box pb={2}>
        <div className='flex items-center gap-3 pb-2'>
          <SearchBar
            placeholder='Temukan Transaksi'
            onSearch={(e) => setSearch('shipped', e.target.value)}
            disableEnter
          />
          <Select
            w='auto'
            fontSize={isLargeScreen ? '14px' : '12px'}
            size={isLargeScreen ? 'md' : 'sm'}
            rounded='md'
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFilter('shipped', e.target.value);
            }}
          >
            <option value=''>Semua</option>
            <option value='instant'>Kurir Instant</option>
          </Select>
        </div>
      </Box>
      <Box position='relative' h='full' bg='white' rounded='lg' w='full'>
        <TableBopis
          isLoading={true}
          listTransaction={
            transactionsList.data ??
            ([] as unknown as IReadyToPick[] | IPacklist[] | ICompleted[] | IShipped[] | undefined)
          }
          setOrder={setDetailOrder}
          search={searchOnline}
          tabIndex={tabIndex}
        />
      </Box>
      <Pagination
        onChangePage={(e) => setPage(e.selected + 1)}
        totalPage={transactionsList?.totalCount ?? 0}
        pageSize={pageSize}
        page={page}
        onChangeRow={(e) => {
          setPage(1);
          setPageSize(e);
        }}
        p='20px'
        position='absolute'
      />
    </>
  );
};

export default ShippedBopis;
