import {
  ICityList,
  IDistrictList,
  IGetRatesReq,
  IGetRatesResponse,
  IProvinceList,
  IRegionList,
  IServiceCategoryResponse,
  ISubDistrictList,
  IVoucherValidate,
} from 'types/shipment.types';

import request from './request';

export default {
  getRates: async (payload: IGetRatesReq): Promise<IGetRatesResponse> => {
    try {
      const res = await request.post(`shipment/get-rates`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getServiceCategory: async (): Promise<IServiceCategoryResponse> => {
    try {
      const res = await request.get(`shipment/services-categories`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getProvinceList: async (): Promise<IProvinceList> => {
    try {
      const res = await request.get(`shipment/province`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCityList: async (provinceId: number): Promise<ICityList> => {
    try {
      const res = await request.get(`shipment/city/${provinceId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getDistrictList: async (cityId: number): Promise<IDistrictList> => {
    try {
      const res = await request.get(`shipment/district/${cityId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSubDistrictList: async (districtId: number): Promise<ISubDistrictList> => {
    try {
      const res = await request.get(`shipment/subdistrict/${districtId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  validateVoucher: async (voucher: string): Promise<IVoucherValidate> => {
    try {
      const res = await request.post(`shipment/voucher/validate`, { voucher });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getRegionList: async (e: string): Promise<IRegionList> => {
    try {
      const res = await request.get(`shipment/regions?name=${e}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
