import { Box, Flex, Text } from '@chakra-ui/react';
import { Facebook, Instagram, Tiktok, Twitter } from 'components/icons';
import React from 'react';
import { StructSetting } from 'types/common.types';

const Footer: React.FC<{
  structSetting: StructSetting | null;
}> = ({ structSetting }) => {
  return (
    <Box display='flex' flexDir='column' alignItems='center' mt={4}>
      {structSetting?.instagram_footer && (
        <Flex alignItems='center'>
          <Instagram h='15px' w='15px' />
          <Text ml={1}>{structSetting.instagram_footer}</Text>
        </Flex>
      )}
      {structSetting?.facebook_footer && (
        <Flex alignItems='center'>
          <Facebook h='15px' w='15px' />
          <Text ml={1}>{structSetting.facebook_footer}</Text>
        </Flex>
      )}
      {structSetting?.twitter_footer && (
        <Flex alignItems='center'>
          <Twitter h='15px' w='15px' />
          <Text ml={1}>{structSetting.twitter_footer}</Text>
        </Flex>
      )}
      {structSetting?.tiktok_footer && (
        <Flex alignItems='center'>
          <Tiktok h='15px' w='15px' />
          <Text ml={1}>{structSetting.tiktok_footer}</Text>
        </Flex>
      )}
      <Text mt='2px' textAlign='center' whiteSpace='pre-line'>
        {structSetting?.note}
      </Text>
    </Box>
  );
};

export default Footer;
