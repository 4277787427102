import { Box, Flex, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

const CardSkeleton: React.FC = () => {
  return (
    <Box
      bg='white'
      rounded='4px'
      border='1px'
      borderColor='#ECEFF1'
      _hover={{ cursor: 'pointer' }}
      py={2}
      px={2}
      boxShadow='shadow-item'
    >
      <Flex flexDir='row' mb={4} alignItems='center'>
        <Stack w='full'>
          <Skeleton h='12px' rounded='md' w='50%' />
          <Skeleton h='12px' rounded='md' w='80%' />
        </Stack>
        <Skeleton h='50px' w='50px' rounded='md' />
      </Flex>
      <Skeleton h='12px' rounded='md' />
    </Box>
  );
};

export default CardSkeleton;
