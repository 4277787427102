import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { SubTitle, Title } from 'components/typography';
import * as React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  cancelRef: React.RefObject<any>;
  title: string;
  description: string;
  firstButtonText: string;
  secondButtonText: string;
  onClickFirstButton: () => void;
  onClickSecondButton: () => void;
  isLoading: boolean;
}

const ModalCLosureInfo: React.FC<Props> = (props: Props) => {
  const {
    isOpen,
    onClose,
    cancelRef,
    title,
    description,
    firstButtonText,
    secondButtonText,
    onClickFirstButton,
    onClickSecondButton,
    isLoading,
  } = props;

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        leastDestructiveRef={cancelRef}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent rounded='4px'>
            <AlertDialogBody pt={8} textAlign='center'>
              <Title>{title}</Title>
              <SubTitle mt={2}>{description}</SubTitle>
            </AlertDialogBody>

            <AlertDialogFooter pb={8} w='full'>
              <Button variant='outline' ref={cancelRef} onClick={onClickFirstButton}>
                {firstButtonText}
              </Button>
              <Button
                variant='primary'
                onClick={onClickSecondButton}
                isDisabled={isLoading}
                ml={3}
                isLoading={isLoading}
              >
                {secondButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ModalCLosureInfo;
