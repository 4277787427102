import * as React from 'react';
import { IGetFreeItemsV2 } from 'types/promotion.types';

import { useAppSelector } from './redux';

type IUseGetFreeItems = {
  data: IGetFreeItemsV2;
};

const useGetFreeItemsV2 = (): IUseGetFreeItems => {
  const processPromotions = useAppSelector((state) => state.sales.listPromotionItem);
  const listValidVoucher = useAppSelector((state) => state.sales.listValidVoucher);
  const listFreeItemV2 = useAppSelector((state) => state.sales.listFreeItemV2);
  const [data, setData] = React.useState<IGetFreeItemsV2>({
    showModal: false,
    includeItem: [],
    excludeItem: [],
    promotion_id: '',
    qty: 0,
    promotion_name: '',
    count_multiply: 0,
  });

  React.useEffect(() => {
    if (processPromotions.length > 0) {
      for (const pkey of processPromotions) {
        const filterFreeItem = listFreeItemV2.find((p) => p.promotion_id === pkey.promotion_id);
        const findIncludeReward = pkey.product_reward.find((reward) => reward.rule_type === 'include');
        const findExcludeReward = pkey.product_reward.find((reward) => reward.rule_type === 'exclude');
        if (findIncludeReward || findExcludeReward) {
          const qty = findIncludeReward ? findIncludeReward.qty : findExcludeReward?.qty ?? 0;
          const calculateQty =
            qty * (pkey.counted_multiply && pkey.counted_multiply > 0 ? pkey.counted_multiply : 1);
          if (
            (pkey.use_voucher && listValidVoucher.find((p) => p.promotion_id === pkey.promotion_id)) ||
            !pkey.use_voucher
          ) {
            if ((!filterFreeItem || filterFreeItem.qty < calculateQty) && !pkey.skip_free_item) {
              setData({
                ...data,
                promotion_id: String(pkey.promotion_id),
                includeItem: findIncludeReward?.reward_items ?? [],
                excludeItem: findExcludeReward?.reward_items ?? [],
                showModal: true,
                promotion_name: pkey.promotion_name,
                qty: calculateQty ?? 0,
                promotion_tier_id: pkey.promotion_tier_id ?? 0,
                count_multiply: pkey.counted_multiply,
              });
              return;
            }
          }
        }
      }
    }
    setData({
      showModal: false,
      includeItem: [],
      excludeItem: [],
      promotion_id: '',
      qty: 0,
      promotion_name: '',
      count_multiply: 0,
    });
    return;
  }, [processPromotions, listValidVoucher, listFreeItemV2]);

  return {
    data,
  };
};

export default useGetFreeItemsV2;
