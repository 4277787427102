import { Box, Button, FormControl, FormErrorMessage, Input, Text, VStack } from '@chakra-ui/react';
import { Select } from 'components/forms';
import config from 'constant';
import { useLiveQuery } from 'dexie-react-hooks';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppDispatch } from 'hooks/redux';
import React from 'react';
import { setAuthorizedDPUser } from 'redux/reducer/sales';
import authorizedUser from 'services/indexdb/authorized-user';
import customTheme from 'theme/select-theme';
import { AuthorizedForm, IAuthorizedUser } from 'types/common.types';
import { hashing } from 'utils/hashing';

import { Modal } from '.';

interface ModalAuthDPProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  checkout: (values: AuthorizedForm) => void;
}

const ModalAuthDP: React.FC<ModalAuthDPProps> = ({
  isOpen,
  isLoading,
  onClose,
  checkout,
}: ModalAuthDPProps) => {
  const { notification } = useNotification();
  const dispatch = useAppDispatch();
  const [validatePin, setValidatePin] = React.useState<string | null>(null);
  const listAuthorizedUser = useLiveQuery<IAuthorizedUser[]>(() => {
    return authorizedUser.getAll(config.ACL_AUTH_DP);
  }, []);

  const optionAuthorizedUser = listAuthorizedUser?.map((item) => {
    return { value: item, label: item.full_name };
  });

  const initialValues: AuthorizedForm = {
    pin: null,
    user: null,
  };

  const validate = (values: AuthorizedForm) => {
    const errors: Record<string, string> = {};

    if (!values.user) {
      errors.user = 'Harap masukan Pemberi Izin';
    }
    setValidatePin(null);
    if (!values.pin) {
      errors.pin = 'Harap masukan PIN';
    }

    return errors;
  };

  const onSave = async (values: AuthorizedForm) => {
    try {
      const pinHashing = hashing(values?.pin ?? '');
      if (pinHashing !== values.user?.pin) return setValidatePin('PIN yang Anda masukkan salah');
      await dispatch(setAuthorizedDPUser(values));
      setValidatePin(null);
      checkout(values);
    } catch (error) {
      notification('', 'Terjadi kesalahan, silahkan coba lagi', 'error');
    }
  };

  const onClosePopup = () => {
    setValidatePin(null);
    onClose();
  };

  return (
    <Modal isShow={isOpen} onClose={onClosePopup} title='Otorisasi DP' disableBackrop>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSave}>
        {({ values, errors, handleBlur, setFieldValue, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <VStack spacing={4} w='full'>
                <FormControl
                  isInvalid={Boolean(errors.user && touched.user)}
                  display='flex'
                  alignItems='center'
                  w='full'
                >
                  <VStack w='full' alignItems='flex-start'>
                    <Box w='full'>
                      <Select
                        inputId='return_auth'
                        name='return_auth'
                        placeholder='--Pilih Pemberi Izin--'
                        options={optionAuthorizedUser}
                        styles={customTheme}
                        menuPlacement='auto'
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          setFieldValue('user', e?.value);
                        }}
                      />
                    </Box>
                    <FormErrorMessage>{touched.user && errors.user}</FormErrorMessage>
                  </VStack>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errors.pin && touched.pin)}
                  display='flex'
                  alignItems='center'
                >
                  <VStack w='full' alignItems='flex-start'>
                    <Input
                      id='pin'
                      type={'password'}
                      name='pin'
                      onBlur={handleBlur}
                      onChange={(e: any) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setFieldValue('pin', e.target.value);
                        }
                      }}
                      placeholder='Masukkan PIN'
                      maxLength={6}
                      value={values?.pin ?? ''}
                      disabled={values?.user === null}
                    />
                    <FormErrorMessage>{touched.pin && errors.pin}</FormErrorMessage>
                    {values.pin && validatePin && (
                      <Text color={'red.500'} fontSize={14}>
                        {validatePin}
                      </Text>
                    )}
                  </VStack>
                </FormControl>
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='primary'
                type='submit'
                loadingText='Harap Tunggu..'
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Lanjutkan
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(ModalAuthDP);
