import { AxiosError } from 'axios';

type ErrorHandlerType = {
  message: string;
};

const errorCodeServer = (code: string) => {
  const codeError = {
    '42P01': "Table or column doesn't exists",
  }[code];

  return codeError ?? `Error ${code}`;
};

export const ErrorHandler = (err: unknown, _event: string): ErrorHandlerType => {
  let message = 'Unknown Error';
  if (err instanceof Error) {
    message = err.message;
  } else if (err instanceof AxiosError) {
    message = `${err.message} - ${errorCodeServer(err.response?.data.code)}`;
  }

  return { message };
};
