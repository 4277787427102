import { Box, Button, Fade, HStack, Stack, Text } from '@chakra-ui/react';
import { ExclamationIcon } from 'components/icons';
import ListTotal from 'components/list/ListTotal';
import { Modal } from 'components/modal';
import { SubTitle, Title } from 'components/typography';
import { Dashed } from 'components/utils';
import React from 'react';
import { Maybe } from 'types';
import { ITotalDetail } from 'types/sales.types';
import { currencyFormat, getRoundMoney } from 'utils';

interface ModalConfirmationRoundingProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  cancelText: string;
  okText: string;
  totalDetail: Maybe<ITotalDetail>;
  includeRounding: boolean;
  onSubmit: (total: number) => void;
  roundMoney: number;
}

const ModalConfirmationRounding: React.FC<ModalConfirmationRoundingProps> = (
  props: ModalConfirmationRoundingProps
) => {
  const {
    isOpen,
    onClose,
    title,
    subtitle,
    cancelText,
    okText,
    onSubmit,
    totalDetail,
    includeRounding,
    roundMoney,
  } = props;

  const onClickConfirmRounding = () => {
    const roundingValue = getRoundMoney(totalDetail?.grandTotal ?? 0);
    onSubmit(
      includeRounding
        ? Number(totalDetail?.grandTotal ?? 0) + roundingValue
        : Number(totalDetail?.grandTotal ?? 0) - Number(totalDetail?.roundMoney ?? 0)
    );
  };

  return (
    <Modal isShow={isOpen} onClose={onClose} disableBackrop>
      <Modal.Body className='py-8'>
        <Stack direction={['column']}>
          <Box textAlign='center'>
            <Fade in={true}>
              <ExclamationIcon h='46px' w='46px' />
              <Title mt={4}>{title}</Title>
              <SubTitle textAlign={'left'}>{subtitle}</SubTitle>
              <br />
              <ListTotal
                leftText={`Total Bayar Sebelummnya`}
                rightText={<Text>{currencyFormat(Math.abs(Number(totalDetail?.grandTotal ?? 0)))}</Text>}
              />
              <ListTotal
                leftText={`Pembulatan`}
                rightText={
                  <Text color='jubelio.primary'>
                    {includeRounding ? '+' : '-'} {currencyFormat(Math.abs(Number(roundMoney ?? 0)))}
                  </Text>
                }
              />
              <Dashed w='full' pt={2} />
              <br />
              <ListTotal
                leftText={`Total Bayar`}
                rightText={
                  <Text>
                    {currencyFormat(
                      Math.abs(
                        includeRounding
                          ? Number(totalDetail?.grandTotal ?? 0) + roundMoney
                          : Number(totalDetail?.grandTotal ?? 0) - roundMoney
                      )
                    )}
                  </Text>
                }
              />
            </Fade>
          </Box>
        </Stack>
        <HStack mt={5} justifyContent='center' spacing={5}>
          <Button variant='outline' size='md' w='30%' onClick={onClose}>
            {cancelText}
          </Button>
          <Button variant='primary' size='md' w='30%' onClick={onClickConfirmRounding}>
            {okText}
          </Button>
        </HStack>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmationRounding;
