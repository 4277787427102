import { Box, BoxProps, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';

import packageJson from '../../../../package.json';

interface CardVersionProps extends BoxProps {
  checkUpdate: () => void;
}

const CardVersion: React.FC<CardVersionProps> = ({ checkUpdate, ...rest }: CardVersionProps) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');

  return (
    <Box
      border='1px'
      borderColor='system.outline'
      p={5}
      rounded='4px'
      bg='system.smoke'
      alignSelf='center'
      {...rest}
    >
      <Flex alignItems='center' justifyContent='space-between' className='space-x-5'>
        <Title>Versi v{packageJson.version}</Title>
        <Button
          variant='primary'
          size={isLargeScreen ? 'md' : 'sm'}
          onClick={checkUpdate}
          loadingText='Sync...'
        >
          Cek Update
        </Button>
      </Flex>
    </Box>
  );
};
export default CardVersion;
