import { useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CardProduct } from 'components/card';
import GridDisplay from 'components/list/GridDisplay';
import { ModalVariants, ModalVoucherPromotion } from 'components/modal';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalFreeItemsV2 from 'components/modal/ModalFreeItemsV2';
import messages from 'constant/messages';
import { useCheckTime, useNotification, useOrder, useStock } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGetFreeItemsV2 from 'hooks/useGetFreeItemsV2';
import React from 'react';
import {
  setListAllPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListValidVoucher,
  updateListFreeItemV2,
} from 'redux/reducer/sales';
import productsRequest from 'services/http/products.request';
import { db } from 'services/indexdb/connection';
import pricebook from 'services/indexdb/pricebook';
import { IItemCart, IProductList } from 'types/products.types';
import { IItemRewards, PromotionItem } from 'types/promotion.types';
import { formatItemVariants, mappingItemsCart, mappingItemsCartVariant } from 'utils';

type IProductListProps = {
  listProducts: IProductList[];
  page: number;
  pageSize: number;
  search: string;
  isLoadingProducts: boolean;
  setSearch: (search: string) => void;
};

const ProductList = ({
  listProducts,

  search,
  isLoadingProducts,
}: IProductListProps) => {
  const [variants, setVariants] = React.useState<IProductList>({} as unknown as IProductList);
  const [includeQtyFreeItem, setIncludeQtyFreeItem] = React.useState<number>(0);
  const [includeItem, setIncludeItem] = React.useState<IItemRewards[]>([]);
  const [excludeItem, setExcludeItem] = React.useState<IItemRewards[]>([]);

  const dispatch = useAppDispatch();

  // Redux Store
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const location = useAppSelector((state) => state.register.location);
  const settings = useAppSelector((state) => state.commons.settings);
  const listFreeItem = useAppSelector((state) => state.sales.listFreeItemV2);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo);
  const listPromotionItem = useAppSelector((state) => state.sales.listPromotionItem);
  const listPromotionAmount = useAppSelector((state) => state.sales.listPromotionAmount);
  const listAllPromotion = useAppSelector((state) => state.sales.listAllPromotion);
  const listValidVoucher = useAppSelector((state) => state.sales.listValidVoucher);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const { customNotification } = useNotification();
  const { data: dataFreeItems } = useGetFreeItemsV2();
  const { createOrder } = useOrder();
  const { checkingStockAvailable } = useStock();
  const { isOpen: isOpenVariant, onClose: onCloseVariant, onOpen: onOpenVariants } = useDisclosure();
  const { currentHours } = useCheckTime();

  const {
    isOpen: isOpenFreeItemsV2,
    onClose: onCloseFreeItemsV2,
    onOpen: onOpenFreeItemsV2,
  } = useDisclosure();

  const { onOpen: onOpenVoucher, onClose: onCloseVoucher, isOpen: isOpenVoucher } = useDisclosure();

  const {
    onOpen: onOpenConfirmCloseFreeItem,
    onClose: onCloseConfirmCloseFreeItem,
    isOpen: isOpenConfirmCloseFreeItem,
  } = useDisclosure();

  const { data: freeItems } = useQuery({
    queryKey: [
      'free-item',
      {
        location_id: location?.location_id as number,
        cursor: 0,
        page: 1,
        pageSize: 15,
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
        includeItem,
        excludeItem,
      },
    ],
    queryFn: productsRequest.getFreeItemsPromotion,
    enabled: isOnline,
  });
  // Add items to cart
  // TODO: Refactoring this function after Finishing the feature product online
  // TODO: Get variants should fetch from API instead of local storage
  const chooseItems = (product: IProductList) => {
    if (listItemCart.length === 0) createOrder();
    if (product.variants && product.variants.length > 1) {
      onOpenVariants();
      setVariants(formatItemVariants(product));
    } else {
      let newProductObj = product;
      if (isOnline) {
        newProductObj = {
          ...product,
          variants: product.variants.map((variant) => {
            return {
              ...variant,
              available: product.total_qty || variant.available,
            };
          }),
        };
      } else {
        newProductObj = product;
      }
      const variant = mappingItemsCart(
        newProductObj,
        location?.rate as number,
        location?.pos_tax as number,
        settings.tax_included,
        search
      );
      checkingStockAvailable(variant);
    }
  };

  const chooseItemModal = (item: IItemCart) => {
    const variant = mappingItemsCartVariant(
      item,
      location?.rate as number,
      location?.pos_tax as number,
      settings.tax_included,
      search
    );
    checkingStockAvailable(variant);
    onCloseVariant();
  };

  const decreaseCountFreeItem = React.useCallback(() => {
    if (includeQtyFreeItem > 0) {
      setIncludeQtyFreeItem(includeQtyFreeItem - 1);
    }
  }, [includeQtyFreeItem]);

  const countFreeItem = React.useMemo(() => {
    const filterFreeItem = listFreeItem.filter((p) => p.promotion_id === Number(dataFreeItems.promotion_id));
    return filterFreeItem.length;
  }, [listFreeItem]);

  React.useEffect(() => {
    if (dataFreeItems.showModal) {
      const filterFreeItem = listFreeItem.filter(
        (p) => p.promotion_id === Number(dataFreeItems.promotion_id)
      );
      const totalQtyFreeItem = filterFreeItem.reduce((sum, item) => sum + Number(item.qty), 0);
      if (Number(totalQtyFreeItem) !== Number(dataFreeItems.qty)) {
        setIncludeQtyFreeItem(dataFreeItems.qty - totalQtyFreeItem);
      }
    } else {
      setIncludeQtyFreeItem(0);
      onCloseFreeItemsV2();
    }
  }, [dataFreeItems, listFreeItem]);

  const validateFreeItem = React.useCallback(async () => {
    const filterFreeItem = listFreeItem.filter((p) => p.promotion_id === Number(dataFreeItems.promotion_id));
    const totalQtyFreeItem = filterFreeItem.reduce((sum, item) => sum + Number(item.qty), 0);
    if (Number(totalQtyFreeItem) !== Number(dataFreeItems.qty) && Number(includeQtyFreeItem) > 0) {
      setIncludeItem(dataFreeItems.includeItem);
      setExcludeItem(dataFreeItems.excludeItem);
      if (freeItems) {
        if (!isOpenConfirmCloseFreeItem) {
          onOpenFreeItemsV2();
          customNotification('', 'Promosi', 'Promosi Tersedia', onOpenVoucher, 120000);
          return;
        }
      } else {
        customNotification('', 'Promosi', 'Promosi Tersedia, Barang Gratis Tidak Ada', onOpenVoucher, 120000);
      }
    }
  }, [dataFreeItems, listFreeItem, countFreeItem, isOpenConfirmCloseFreeItem, includeQtyFreeItem]);

  React.useEffect(() => {
    validateFreeItem();
  }, [includeQtyFreeItem]);

  // TODO: Refactoring this function after Implement Product Online
  // get pricebook list by customer
  const getPriceBookListByCustomer = React.useCallback(async () => {
    const listPriceBook = await db.pricebook.toArray();
    await pricebook.updatePricebook(
      listPriceBook,
      customerInfo,
      currentHours,
      location?.location_id as number
    );
  }, [customerInfo]);

  React.useEffect(() => {
    getPriceBookListByCustomer();
  }, [customerInfo]);

  const submitConfirmSkipFreeItem = () => {
    setIncludeQtyFreeItem(0);
    onCloseConfirmCloseFreeItem();

    const promotionId = Number(dataFreeItems?.promotion_id ?? 0);

    const filterItemsByPromotionId = <T extends PromotionItem>(list: T[], key: keyof T = 'promotion_id') =>
      list.filter((item) => item[key] !== promotionId);

    dispatch(updateListFreeItemV2(filterItemsByPromotionId(listFreeItem)));
    dispatch(setListPromotionItem(filterItemsByPromotionId(listPromotionItem)));
    const filterPromotionAmount = listPromotionAmount.filter(
      (item) => item.promotion.promotion_id !== promotionId
    );
    dispatch(setListPromotionAmount(filterPromotionAmount));

    const filterNonVoucher = listAllPromotion.map((item) => {
      if (item.promotion_id === promotionId) {
        return { ...item, skip_free_item: true };
      }
      return item;
    });

    dispatch(setListAllPromotion(filterNonVoucher));
    dispatch(setListValidVoucher(filterItemsByPromotionId(listValidVoucher)));
  };

  return (
    <>
      {!isLoadingProducts && listProducts.length > 0 && (
        <GridDisplay isLoading={isLoadingProducts}>
          {listProducts?.map((item, index) => (
            <CardProduct
              key={index}
              product={item}
              isVariants={false}
              selectItems={() => chooseItems(item)}
            />
          ))}
        </GridDisplay>
      )}

      <ModalVariants
        isOpen={isOpenVariant}
        onClose={onCloseVariant}
        listVariants={variants}
        chooseItemModal={chooseItemModal}
      />
      <ModalVoucherPromotion isOpen={isOpenVoucher} onClose={onCloseVoucher} />
      {isOpenFreeItemsV2 && freeItems?.data && (
        <ModalFreeItemsV2
          isOpen={isOpenFreeItemsV2}
          onClose={() => {
            onCloseFreeItemsV2();
          }}
          skipFreeItem={() => {
            onCloseFreeItemsV2();
            onOpenConfirmCloseFreeItem();
          }}
          freeItems={dataFreeItems}
          decreaseCountFreeItem={decreaseCountFreeItem}
        />
      )}

      {isOpenConfirmCloseFreeItem && (
        <ModalConfirmation
          title={messages.modal.title_skip_free_item + ' ' + dataFreeItems.promotion_name}
          subtitle={messages.modal.skip_free_item + ' ' + dataFreeItems.promotion_name + ' akan dihapus'}
          cancelText='Batal'
          okText='Ya'
          isOpen={isOpenConfirmCloseFreeItem}
          isOnline
          onSubmit={submitConfirmSkipFreeItem}
          onClose={() => {
            onCloseConfirmCloseFreeItem();
            onOpenFreeItemsV2();
          }}
        />
      )}
    </>
  );
};
export default ProductList;
