import { Box, Image } from '@chakra-ui/react';
import EmptyCart from 'assets/svg/EmptyCart.svg';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

const CartEmpty: React.FC = () => {
  return (
    <Box textAlign='center'>
      <Image
        src={EmptyCart}
        display='inline-block'
        m='auto'
        boxSize={{ base: '60px', md: '80px', lg: '110px' }}
      />
      <Title mt='32px'>Belum Ada Transaksi</Title>
      <SubTitle mt='8px'>Silahkan pilih barang untuk memulai transaksi</SubTitle>
    </Box>
  );
};

export default CartEmpty;
