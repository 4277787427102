import { TriangleDownIcon } from '@chakra-ui/icons';
import { Button, Checkbox, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ModalCustomer } from 'components/modal';
import ModalAddress from 'components/modal/ModalAddress';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalEmail from 'components/modal/ModalEmail';
import { Title } from 'components/typography';
import messages from 'constant/messages';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import {
  clearPaymentsList,
  setShippingToOrder,
  updateIsCheckout,
  updateShippingOrder,
} from 'redux/reducer/sales';
import commonRequest from 'services/http/common.request';
import { db } from 'services/indexdb/connection';
import { Maybe } from 'types';
import { ICustomerInfoInput } from 'types/common.types';
import { IOrderCustomer, ITotalDetail } from 'types/sales.types';

interface Props {
  sendStructByEmail: boolean;
  isShippingActive: boolean;
  isReturn: boolean;
  email: string;
  address?: string;
  setSendStructByEmail: (sendStruct: boolean) => void;
  setPosIsShipping?: (posIsShipping: boolean) => void;
  setContactToOrder: (customer: ICustomerInfoInput) => void;
  customer?: Maybe<IOrderCustomer>;
  setEmail: (newEmail: string) => void;
  totalDetail?: ITotalDetail;
}

const BoxEmail: React.FC<Props> = ({
  sendStructByEmail,
  isShippingActive,
  isReturn,
  email,
  address,
  setPosIsShipping,
  setSendStructByEmail,
  setContactToOrder,
  customer,
  setEmail,
  totalDetail,
}: Props) => {
  const { isOpen: isOpenEmail, onOpen: onOpenEmail, onClose: onCloseEmail } = useDisclosure();
  const { onOpen: onOpenAddress, onClose: onCloseAddress, isOpen: isOpenAddress } = useDisclosure();
  const {
    onOpen: onOpenAddCustomer,
    onClose: onCloseAddCustomer,
    isOpen: isOpenAddCustomer,
  } = useDisclosure();
  const {
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
    isOpen: isOpenConfirmation,
  } = useDisclosure();
  const {
    onOpen: onOpenConfirmPosIsShipping,
    onClose: onCloseConfirmPosIsShipping,
    isOpen: isOpenConfirmPosIsShipping,
  } = useDisclosure();
  const dispatch = useAppDispatch();
  const { continuePayment } = useAppSelector((state) => state.sales);
  const shippingInfo = useAppSelector((state) => state.sales.shippingInfo);
  const isOnlineStatus = useAppSelector((state) => state.register.isOnline);
  const [isPosShipping, setIsPosShipping] = React.useState<boolean>(false);

  const onSaveEmail = async (newEmail: string, isUpdateEmail: boolean) => {
    onCloseEmail();
    if (customer) {
      if (isUpdateEmail && customer.contact_id !== -1) {
        commonRequest.updateEmailContact({
          email: newEmail,
          contact_id: customer.contact_id,
        });
        db.customer.where('contact_id').equals(customer.contact_id).modify({ email: newEmail });
      }
      dispatch(
        setContactToOrder({
          ...customer,
          email: newEmail,
          is_update_email: isUpdateEmail,
        })
      );
    }
    dispatch(setSendStructByEmail(true));
  };

  const onSubmitConfirmationChangeTransaction = () => {
    dispatch(clearPaymentsList());
    dispatch(updateIsCheckout(false));
    onCloseConfirmation();
    onOpenAddress();
  };

  const onSubmitConfirmationPosIsShipping = () => {
    dispatch(updateIsCheckout(false));
    dispatch(clearPaymentsList());
    onCloseConfirmPosIsShipping();
    setPosIsShipping && !isPosShipping && dispatch(setPosIsShipping(isPosShipping));
    const shipping = {
      ...shippingInfo,
      use_jubelio_shipment: false,
      shipping_cost: 0,
      courier: '',
      insurance_cost: 0,
      service_category: 0,
    };
    dispatch(updateShippingOrder(shipping));
    dispatch(setShippingToOrder(shipping));
    if (isPosShipping) onOpenAddress();
  };

  return (
    <Stack py='16px' spacing={3}>
      <Title>Detail Penerimaan</Title>
      <Stack
        border='1px'
        borderColor='system.outline'
        rounded='4px'
        p={5}
        fontSize={{ base: '12px', lg: '14px' }}
      >
        <HStack spacing={2}>
          <Checkbox
            size='sm'
            colorScheme='red'
            w='full'
            isChecked={sendStructByEmail}
            fontSize={{ base: '12px', lg: '14px' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setSendStructByEmail(e.target.checked))
            }
          >
            Kirim struk email
          </Checkbox>
          <Button variant='link' w='full' onClick={() => onOpenEmail()}>
            <Text
              ml='auto'
              textAlign='right'
              as='u'
              color='jubelio.soft-dark'
              fontWeight='normal'
              fontSize={{ base: '12px', lg: '14px' }}
            >
              {email === '' ? 'Tambah Email' : email}
            </Text>
            &nbsp;
            <TriangleDownIcon h='8px' color='jubelio.dark' transform='rotate(270deg)' />
          </Button>
        </HStack>
        {isOnlineStatus && (
          <HStack spacing={2}>
            <Checkbox
              size='sm'
              colorScheme='red'
              w='full'
              isChecked={isShippingActive}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsPosShipping(e.target.checked);
                onOpenConfirmPosIsShipping();
              }}
              isDisabled={isReturn || continuePayment}
              fontSize={{ base: '12px', lg: '14px' }}
            >
              Kirim ke alamat tujuan
            </Checkbox>
            <Button
              variant='link'
              w='full'
              onClick={() => onOpenConfirmation()}
              isDisabled={isReturn || continuePayment}
            >
              <Text
                ml='auto'
                textAlign='right'
                as='u'
                color='jubelio.soft-dark'
                fontWeight='normal'
                fontSize={{ base: '12px', lg: '14px' }}
              >
                {address && address !== ''
                  ? address.length > 27
                    ? `${address?.substring(0, 27)}...`
                    : `${address}`
                  : 'Tambah Alamat'}
              </Text>
              &nbsp;
              <TriangleDownIcon h='8px' color='jubelio.dark' transform='rotate(270deg)' />
            </Button>
          </HStack>
        )}
      </Stack>

      <ModalEmail
        isOpen={isOpenEmail}
        onClose={onCloseEmail}
        onSubmit={onSaveEmail}
        email={email}
        allowUpdateEmail={customer?.contact_id !== -1}
        loading={false}
        onOpenAddCustomer={onOpenAddCustomer}
        setEmail={setEmail}
      />

      <ModalCustomer
        isOpen={isOpenAddCustomer}
        onClose={onCloseAddCustomer}
        onSubmit={() => {
          onCloseAddCustomer();
          onCloseEmail();
        }}
        email={email}
        isSetCustomer={true}
      />

      <ModalConfirmation
        title={messages.modal.title_change_transaction}
        subtitle={messages.modal.change_transaction}
        cancelText='Batal'
        okText='Ya'
        isOpen={isOpenConfirmation ?? false}
        isOnline
        onSubmit={onSubmitConfirmationChangeTransaction}
        onClose={() => onCloseConfirmation()}
      />

      <ModalConfirmation
        title={messages.modal.title_change_transaction}
        subtitle={messages.modal.change_transaction}
        cancelText='Batal'
        okText='Ya'
        isOpen={isOpenConfirmPosIsShipping}
        isOnline
        onSubmit={onSubmitConfirmationPosIsShipping}
        onClose={() => onCloseConfirmPosIsShipping()}
      />

      <ModalAddress isOpen={isOpenAddress} onClose={onCloseAddress} totalDetail={totalDetail} />
    </Stack>
  );
};

export default BoxEmail;
