import { CardProductSkeleton } from 'components/card';
import React from 'react';

const GridDisplay = ({
  isLoading,
  children,
  placeholder,
}: {
  isLoading: boolean;
  children?: React.ReactNode;
  placeholder?: number[];
}) => (
  <div className='grid grid-cols-3 gap-3 p-3  md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
    {isLoading ? placeholder?.map((_, index) => <CardProductSkeleton key={index} />) : children}
  </div>
);

export default GridDisplay;
