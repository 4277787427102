/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

interface IResponseDeeplink {
  openUrl: (url: string) => void;
  destroy: () => void;
}

export function DeepLinker(options: {
  onReturn?: () => void;
  onFallback?: () => void;
  onIgnored?: () => void;
}): IResponseDeeplink {
  if (!options) {
    throw new Error('no options');
  }

  let hasFocus = true;
  let didHide = false;

  // window is blurred when dialogs are shown
  function onBlur() {
    hasFocus = false;
  }

  // document is hidden when native app is shown or browser is backgrounded
  function onVisibilityChange(e: any) {
    if (e.target.visibilityState === 'hidden') {
      didHide = true;
    }
  }

  // window is focused when dialogs are hidden, or browser comes into view
  function onFocus() {
    if (didHide) {
      if (options.onReturn) {
        options.onReturn();
      }

      didHide = false; // reset
    } else {
      // ignore duplicate focus event when returning from native app on
      // iOS Safari 13.3+
      if (!hasFocus && options.onFallback) {
        // wait for app switch transition to fully complete - only then is
        // 'visibilitychange' fired
        setTimeout(function () {
          // if browser was not hidden, the deep link failed
          if (!didHide && options.onFallback) {
            options.onFallback();
            hasFocus = false;
          }
        }, 1000);
      }
    }

    hasFocus = true;
  }

  // add/remove event listeners
  // `mode` can be "add" or "remove"
  function bindEvents(mode: any) {
    [
      [window, 'blur', onBlur],
      [document, 'visibilitychange', onVisibilityChange],
      [window, 'focus', onFocus],
    ].forEach(function (conf: any) {
      conf[0][mode + 'EventListener'](conf[1], conf[2]);
    });
  }

  // add event listeners
  bindEvents('add');
  // expose public API
  const destroy = bindEvents.bind(null, 'remove');
  const openURL = function (url: string) {
    // it can take a while for the dialog to appear
    const dialogTimeout = 2000;

    setTimeout(function () {
      if (hasFocus && options.onIgnored) {
        options.onIgnored();
      }
    }, dialogTimeout);

    window.location.href = url;
  };

  return {
    openUrl: openURL,
    destroy: destroy,
  };
}
