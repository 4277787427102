import { SimpleGrid } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import NotfoundIcon from 'assets/svg/Notfound.svg';
import { CardProduct } from 'components/card';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import GridDisplay from 'components/list/GridDisplay';
import { EmptySearch } from 'components/state';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import productsRequest from 'services/http/products.request';
import { IResponseStock } from 'types/inventory';
import { IItemCart, IProductList, Variant } from 'types/products.types';
import { debounce, getPagination } from 'utils';

import { Modal } from '.';

interface ModalVariantsProps {
  isOpen: boolean;
  onClose: () => void;
  listVariants: IProductList;
  chooseItemModal: (item: IItemCart) => void;
}

const ModalVariants: React.FC<ModalVariantsProps> = ({
  isOpen,
  onClose,
  listVariants,
  chooseItemModal,
}: ModalVariantsProps) => {
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [variants, setVariants] = React.useState<Variant[]>([]);
  const [isLoadingVariant, setIsLoadingVariant] = React.useState<boolean>(false);

  const location = useAppSelector((state) => state.register.location);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const mutateGetStock = useMutation({
    mutationKey: ['getItemStock'],
    mutationFn: productsRequest.getItemsStock,
  });

  const filteredVariants = React.useMemo(() => {
    if (!searchQuery) return variants || [];

    const search = new RegExp(searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    return (listVariants.variants || []).filter((variant) => {
      const { item_name, item_code, barcode, display_name } = variant || {};
      return (
        search.test(item_name?.toLowerCase()) ||
        search.test(item_code?.toLowerCase()) ||
        search.test(display_name?.toLowerCase() ?? '') ||
        search.test(barcode?.toLowerCase())
      );
    });
  }, [variants, searchQuery]);

  const variantPagination = React.useMemo(
    () => getPagination(filteredVariants, page, pageSize),
    [filteredVariants, page, pageSize]
  );

  const handleClose = () => {
    onClose();
    setVariants([]);
  };

  const getStockVariant = () => {
    mutateGetStock.mutate(
      {
        location_id: location?.location_id as number,
        item_ids: listVariants.variants.map((variant) => variant.item_id),
      },
      {
        onSuccess: (data) => {
          setIsLoadingVariant(false);
          const newArrayVariants = listVariants.variants.map((variant) => {
            const findVariant = data.data?.find((i: IResponseStock) => i.item_id === variant.item_id);
            if (findVariant) {
              return {
                ...variant,
                pos_check_stock: findVariant.pos_check_stock,
                available: findVariant.qty,
                qty: findVariant.qty,
              };
            }
            return variant;
          });
          setVariants(newArrayVariants);
        },
        onError: () => {
          setIsLoadingVariant(false);
        },
      }
    );
  };

  React.useEffect(() => {
    if (isOpen) {
      setIsLoadingVariant(true);
      setPage(1);
      setPageSize(15);
      setSearchQuery('');

      if (isOnline) {
        getStockVariant();
      } else {
        setVariants(listVariants.variants);
        setIsLoadingVariant(false);
      }
    }
  }, [isOpen]);

  const onSearchVariant = React.useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
      setPage(1);
    }, 500),
    []
  );

  return (
    <Modal isShow={isOpen} onClose={handleClose} title='Variants' className='max-w-3xl'>
      <Modal.Body className='h-full max-h-[700px]'>
        <div style={{ marginBottom: 10 }}>
          <SearchBar placeholder='Cari variasi' onSearch={(e) => onSearchVariant(e.target.value)} />
        </div>
        <div className='h-[400px] w-full overflow-y-auto pb-5'>
          {isLoadingVariant && (
            <div className='h-auto w-full'>
              <GridDisplay isLoading={isLoadingVariant} placeholder={Array.from({ length: 25 })} />
            </div>
          )}

          {variantPagination && variantPagination.length === 0 && (
            <div className='flex h-full w-full items-center justify-center'>
              <EmptySearch title='Yah!' subtitle='Barang tidak ditemukan' icons={NotfoundIcon} />
            </div>
          )}

          {variantPagination ? (
            <SimpleGrid columns={[2, 3, 4, 4, 4]} gap={4}>
              {variantPagination.map((variant: any, index: number) => (
                <CardProduct
                  key={index}
                  product={variant}
                  selectItems={() => chooseItemModal(variant)}
                  isVariants={true}
                  itemStock={variant.available || 0}
                />
              ))}
            </SimpleGrid>
          ) : null}
        </div>
        <div style={{ marginTop: 10 }}>
          <Pagination
            onChangePage={(e) => {
              setPage(e.selected + 1);
            }}
            totalPage={filteredVariants.length || 0}
            pageSize={pageSize}
            page={page}
            onChangeRow={(e) => {
              setPage(1);
              setPageSize(e);
            }}
            withTotal={false}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVariants;
