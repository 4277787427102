import { ITEM_ID_TYPES } from 'constant';
import { groupBy } from 'lib/helpers';
import { db } from 'services/indexdb/connection';
import { IProductList } from 'types/products.types';
import {
  IFreeItemsV2,
  IGetPromotionData,
  IPromotions,
  IValidVoucherList,
  ProcessPromotion,
  TransactionPromotion,
} from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { ISalesState } from 'types/sales.types';
import { getFinalTransactionDiscount, getSaleTotalAfterItemDiscount } from 'utils/calculation';

type FreeItemsFunc = (promotions: TransactionPromotion) => Array<{
  id_type: number;
  item_id: number;
  qty: number;
}>;

/**
 * This method is used to mapping free items of promotions
 *
 * @param promotion
 * @returns  Array<{ id_type: number, item_id: number, qty: number }>
 */
export const mappingFreeItems: FreeItemsFunc = (promotion) => {
  return promotion.rules.items.map((item) => ({
    id_type: item.id_type,
    item_id: item.item_id,
    qty: Number(promotion.multiplier) > 1 ? item.qty * Number(promotion.multiplier) : item.qty,
  }));
};

/**
 * This method is used to check if the promotion is valid for the transaction
 * which totalAfterDiscount greeter than the promotion's minimum spending
 *
 * @param promotions
 * @param sales
 * @param location
 * @returns {Promise<TransactionPromotion | null>}
 */
export const validatePromoFreeItems = (
  sales: ISalesState,
  promotion: IPromotions,
  location: ILocations
): TransactionPromotion | null => {
  const salesAfterItemDiscount = getSaleTotalAfterItemDiscount(
    sales.listItemCart,
    sales.listPromotionsItems
  );
  const discountTransaction = getFinalTransactionDiscount(sales, location);
  const totalAfterDiscount = salesAfterItemDiscount - discountTransaction;
  if (Number(promotion.minimum_spending) > totalAfterDiscount) return null;

  const p: TransactionPromotion = {
    rules: promotion,
    freeItems: [],
    checkLists: {
      saleAfterDiscount: {
        current: totalAfterDiscount,
        expected: Number(promotion.minimum_spending),
      },
    },
    multiplier: 1,
  };

  p['freeItems'] = mappingFreeItems(p);
  p['free_items'] = [];
  p['free_categories'] = {};
  p['free_products'] = [];

  return p;
};

type PromotionFreeItemRes = {
  newPromotions: ProcessPromotion;
  removedPromotions: any;
};

/**
 * This method is used to mapping new promotion items
 *
 * @param newPromotions
 * @param processPromotion
 * @returns {Promise<void>}
 */
export const getPromotionFreeItems = async (
  promotions: TransactionPromotion[],
  processPromotion: ProcessPromotion
): Promise<PromotionFreeItemRes> => {
  const newPromotions: any = [];
  const allCurrentPromotions: any = {};

  for (const promo of promotions) {
    const freeKeys: any = {};
    const pk = `key_${promo.rules.promotion_id}`;
    allCurrentPromotions[pk] = true;

    if (processPromotion[pk] !== undefined) continue;

    for (const item of promo.freeItems || []) {
      const ikey = `${item.id_type}_${item.item_id}`;
      if (freeKeys[ikey]) {
        freeKeys[ikey] += item.qty;
      } else {
        freeKeys[ikey] = item.qty;
      }
    }

    newPromotions[pk] = {
      promotion_id: promo.rules.promotion_id,
      free_products: {},
      free_categories: {},
      free_items: [],
      free_keys: freeKeys,
    };
  }

  // processed promotion maybe removed during adding/removing sale item
  // so we need to update the list of processedPromotions too
  const removedPromotions = [];
  for (const pk of Object.keys(processPromotion)) {
    if (allCurrentPromotions[pk] !== true) {
      removedPromotions.push(pk);
    }
  }

  const items = await db.products.toArray();
  const itemsMapping = mappingNewPromotionItems(newPromotions, items);

  return {
    newPromotions: itemsMapping,
    removedPromotions,
  };
};

/**
 * This method is used to mapping new promotion items to the new promotion
 * TODO: Refactoring this function
 * @param newPromotions
 * @param items
 * @return {ProcessPromotion}
 */
const mappingNewPromotionItems = (
  newPromotions: ProcessPromotion,
  items: IProductList[]
): ProcessPromotion => {
  for (const item of items) {
    const pkey = `${ITEM_ID_TYPES.Product}_${item.item_group_id}`;
    const ckey = `${ITEM_ID_TYPES.Category}_${item.item_category_id}`;

    for (const promo of Object.values(newPromotions) as any) {
      if (promo.free_keys[pkey] !== undefined) {
        if (promo.free_products[pkey] === undefined) {
          promo.free_products[pkey] = {
            item,
            is_free: true,
            quantity: promo.free_keys[pkey],
            selectedItem: null,
            chosen: false,
          };
        }
      }

      if (promo.free_keys[ckey] !== undefined) {
        if (promo.free_categories[ckey] === undefined) {
          promo.free_categories[ckey] = {
            items: [],
            is_free: true,
            selectedItem: null,
            quantity: promo.free_keys[ckey],
            chosen: false,
          };
        }

        promo.free_categories[ckey].items.push(item);
      }

      for (const variant of item.variants) {
        const vkey = `${ITEM_ID_TYPES.SKU}_${variant.item_id}`;
        if (promo.free_keys[vkey] !== undefined) {
          promo.free_items.push({
            ...variant,
            is_free: true,
            item_group_id: item.item_group_id,
            quantity: promo.free_keys[vkey],
            total: Number(item.sell_price),
            use_serial_number: item.use_serial_number,
            use_batch_number: item.use_batch_number,
          });
        }
      }
    }
  }

  return newPromotions;
};

type FormatFreeItems = {
  promotion_id: number;
  voucher_code?: string;
  detail: {
    type: string;
    items: Array<{
      item_id: number;
      qty: number;
    }>;
  };
};
export const formatFreeItemTransaction = (promotion: TransactionPromotion): FormatFreeItems => {
  const items: any = promotion.free_items?.map((item) => ({
    item_id: item.item_id,
    qty: item.quantity,
  }));

  for (const prod of Object.values(promotion.free_products || {})) {
    if (prod.selectedItem !== null) {
      items?.push({
        item_id: prod.selectedItem.item_id,
        qty: prod?.selectedItem.quantity,
      });
    }
  }

  for (const cat of Object.values(promotion.free_categories || {})) {
    if (cat.selectedItem !== null) {
      items?.push({
        item_id: cat.selectedItem.item_id,
        qty: cat.selectedItem.quantity,
      });
    }
  }

  return {
    promotion_id: promotion.rules.promotion_id,
    detail: {
      type: 'free_item',
      items,
    },
  };
};

export const getPromotionFreeItemsV2 = (
  promotions: IGetPromotionData[]
): { [key: string]: IGetPromotionData[] } => {
  return groupBy<IGetPromotionData>(promotions, (v) => {
    return String(v.promotion_id);
  });
};

export const formatFreeItemV2 = (
  promotion: IFreeItemsV2,
  listValidVoucher: IValidVoucherList[]
): FormatFreeItems => {
  const items: any = {
    item_id: promotion.item_id,
    qty: promotion.qty,
  };

  const findVoucher = listValidVoucher.find((v) => v.promotion_id === promotion.promotion_id);

  const result: FormatFreeItems = {
    promotion_id: promotion.promotion_id,
    detail: {
      type: 'free_item',
      items,
    },
  };

  if (findVoucher?.voucher_code) {
    result.voucher_code = findVoucher.voucher_code;
  }

  return result;
};
