import { Box, HStack } from '@chakra-ui/react';
import NotfoundIcon from 'assets/svg/Notfound.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { EmptySearch } from 'components/state';
import { Title } from 'components/typography';
import { useLiveQuery } from 'dexie-react-hooks';
import { useAppDispatch } from 'hooks/redux';
import React from 'react';
import { selectSaleman } from 'redux/reducer/sales';
import salesmen from 'services/indexdb/salesmen';
import { ISalesman } from 'types/common.types';
import { getPagination } from 'utils';
import { debounce } from 'utils';

import { Modal } from '.';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const ModalSalesman: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, title, ...rest } = props;
  const [search, setSearch] = React.useState<string>('');
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const dispatch = useAppDispatch();

  const chooseSaleman = (saleman: ISalesman) => {
    dispatch(selectSaleman(saleman));
    setPage(1);
    onClose();
  };

  const listSaleman = useLiveQuery<ISalesman[]>(async () => {
    if (search !== '') {
      return salesmen.filter(search);
    } else {
      return salesmen.get();
    }
  }, [search]);

  const paginationMemo = React.useMemo(() => {
    if (listSaleman) {
      return getPagination(listSaleman, page, pageSize);
    }
  }, [page, pageSize, listSaleman]);

  const searchCustomer = debounce(async (keyword) => {
    setSearch(keyword);
  }, 500);

  return (
    <Modal isShow={isOpen} title={title} onClose={onClose} {...rest} className='max-w-lg'>
      <Modal.Body>
        <HStack justify='space-between' mb='16px'>
          <SearchBar placeholder='Cari salesmen' onSearch={(e) => searchCustomer(e.target.value)} />
        </HStack>
        <Box minH='300px' h='full' overflowY='auto'>
          {paginationMemo && paginationMemo.length === 0 && (
            <EmptySearch title='Yah!' subtitle='Saleman tidak ditemukan' icons={NotfoundIcon} />
          )}
          {paginationMemo && (
            <Box overflowY='auto' maxH='300px'>
              {paginationMemo.map((item: ISalesman, key: number) => (
                <Box
                  key={key}
                  borderBottom='1px'
                  borderColor='gray.200'
                  minH='47px'
                  py={2}
                  px={2}
                  _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
                  onClick={() => chooseSaleman(item)}
                >
                  <Title fontWeight='normal' fontSize='14px'>
                    {item.salesmen_name}
                  </Title>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal.Body>
      <Box boxShadow='0px -4px 6px -1px rgba(23, 80, 22, 0.06)' p={4}>
        <Pagination
          onChangePage={(e) => setPage(e.selected + 1)}
          totalPage={listSaleman?.length as number}
          pageSize={pageSize}
          onChangeRow={(e) => setPageSize(e)}
          pageRange={2}
          page={page}
          withTotal={false}
        />
      </Box>
    </Modal>
  );
};

export default React.memo(ModalSalesman);
