import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { BoxProps, Button, Divider, HStack, Text } from '@chakra-ui/react';
import { SearchBar } from 'components/forms';
import { Title } from 'components/typography';
import React from 'react';
import { Maybe } from 'types';
import { IDetailTransaction } from 'types/bopis.types';

interface ScanTokenProps extends BoxProps {
  token: Maybe<string>;
  setToken: (token: string) => void;
  searchToken: (token: string) => void;
  completeOrder: (id: number) => void;
  isTokenValid: Maybe<boolean>;
  loadingCompleteOrder: boolean;
  isLoading?: boolean;
  detailOrder: IDetailTransaction;
}

const ScanToken: React.FC<ScanTokenProps> = ({
  token,
  setToken,
  searchToken,
  isTokenValid,
  completeOrder,
  loadingCompleteOrder,
  detailOrder,
  isLoading,
}: ScanTokenProps) => {
  return (
    <>
      <Divider />

      {!detailOrder.received_date ? (
        <React.Fragment>
          <Title fontSize='14px' textAlign='left' w='full' pt={2}>
            Scan Token
          </Title>
          <HStack spacing='0px' mt='3px'>
            <SearchBar
              placeholder='Masukkan token'
              onSearch={(e: any) => setToken(e.target.value)}
              onEnter={(e: any) => searchToken(e)}
              borderRadius='0px'
              roundedTopLeft='0px'
              roundedTopRight='0px'
              disabled={isTokenValid === true}
              style={{ backgroundColor: 'white' }}
            />
            <Button
              variant='outline'
              size='md'
              onClick={() => {
                searchToken(token ?? '');
              }}
              borderTopLeftRadius='0px'
              isLoading={isLoading}
              isDisabled={isTokenValid === true}
              borderBottomLeftRadius='0px'
              style={{ backgroundColor: 'white' }}
            >
              Scan
            </Button>
          </HStack>
        </React.Fragment>
      ) : (
        <div className='mt-2 rounded-md bg-[#4CAF5033] p-3'>
          <span className='text-sm'>
            <b>Scan Token</b> sudah dilakukan. Silahkan selesaikan Pesanan jika sudah diberikan ke Pelanggan.
          </span>
        </div>
      )}
      {isTokenValid === true ? (
        <Text className='text-token text-success' mt={3} color='system.green' fontSize={14}>
          <CheckIcon /> Berhasil : {token}
        </Text>
      ) : (
        isTokenValid === false && (
          <Text className='text-token text-success' mt={3} color='red.500' fontSize={14}>
            <CloseIcon /> {token} tidak ditemukan
          </Text>
        )
      )}
      {isTokenValid ||
        (detailOrder.received_date && (
          <Button
            variant='primary'
            type='submit'
            onClick={() => completeOrder(detailOrder.salesorder_id)}
            w='full'
            isLoading={loadingCompleteOrder}
            mt={2}
            size={'sm'}
          >
            Selesaikan
          </Button>
        ))}
    </>
  );
};
export default React.memo(ScanToken);
