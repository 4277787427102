import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  IconButton,
  ListItem,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import * as React from 'react';
import { ISerialNumber } from 'types/sales.types';

interface PresetPopover {
  placement?: Placement;
  listSn: ISerialNumber[];
}

export const TooltipSerialNumber: React.FC<PresetPopover> = ({
  placement,
  listSn,
}: PresetPopover) => {
  return (
    <Popover trigger='hover' placement={placement}>
      <PopoverTrigger>
        <IconButton
          aria-label='serialnumber'
          size='sm'
          variant='unstyled'
          icon={<InfoOutlineIcon color='jubelio.grey200' />}
        />
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: 'none' }} zIndex={11} w='auto' rounded='4px'>
        <PopoverArrow />
        <PopoverBody zIndex={11} p='14px'>
          <Box color='jubelio.black'>
            <Flex alignItems='center' gridGap={2}>
              <InfoOutlineIcon h='16px' w='16px' color='system.blue' />
              <Text fontWeight='bold' color='jubelio.black' fontSize='14px'>
                Serial Number
              </Text>
            </Flex>
            <Divider my={3} />
            <UnorderedList>
              {listSn &&
                listSn.map((item, index) => {
                  return <ListItem key={index}>{item.serial_no}</ListItem>;
                })}
            </UnorderedList>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
