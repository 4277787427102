import { Flex, Text, VStack } from '@chakra-ui/react';
import { SubTitle } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { ILocations } from 'types/register.types';
import { currencyFormat, getAmountDetailTransaction } from 'utils';

const CardTransactionPromotion: React.FC = () => {
  const sales = useAppSelector((state) => state.sales);
  const { settings } = useAppSelector((state) => state.commons);
  const { location } = useAppSelector((state) => state.register);

  const { discountTrx } = getAmountDetailTransaction(sales, location as ILocations, settings);

  return (
    <Flex
      border='1px'
      borderColor='jubelio.grey'
      p={5}
      rounded='4px'
      bg='jubelio.grey100'
      position='relative'
    >
      <VStack spacing={2} w='full' alignItems='start'>
        <SubTitle fontSize='12px'>Detail Potongan</SubTitle>
        <Flex
          justifyContent='space-between'
          className='space-x-2'
          mb='5px'
          position='relative'
          w='full'
        >
          <Text fontSize='14px'>Diskon Manual</Text>
          <Text fontSize='14px'>{currencyFormat(discountTrx)}</Text>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default CardTransactionPromotion;
