import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import request from 'services/http/request';
import { ResponseSuccess } from 'types/common.types';
import {
  BankNotesFragment,
  ILocations,
  IPingRegister,
  LocationsFragments,
} from 'types/register.types';

export default {
  getLocations: async (page: number, pageSize: number, q = ''): Promise<LocationsFragments> => {
    try {
      const response = await request.get(
        `sales/pos/v3/locations/?page=${page}&pageSize=${pageSize}&q=${q}`
      );
      return Promise.resolve(response.data);
    } catch (err) {
      const error = err as Error | AxiosError;
      return Promise.reject(error);
    }
  },
  getLocationById: async ({
    queryKey,
  }: QueryFunctionContext<[string, number]>): Promise<ILocations> => {
    const [, locationId] = queryKey;
    const response = await request.get(`sales/pos/v3/locations/${locationId}/`);
    return response.data;
  },
  getBankNotes: async (): Promise<BankNotesFragment> => {
    try {
      const response = await request.get(`sales/pos/v3/banknotes`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  pingRegister: async (
    locationId: number,
    registerId: number,
    payload: IPingRegister
  ): Promise<ResponseSuccess> => {
    try {
      const response = await request.post(
        `/sales/pos/v3/locations/${locationId}/register/${registerId}/ping`,
        payload
      );
      return Promise.resolve(response.data);
    } catch (err) {
      const error = err as Error | AxiosError;
      return Promise.reject(error);
    }
  },
};
