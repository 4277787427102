import { Box, Skeleton, Stack, Text } from '@chakra-ui/react';
import NotfoundInfo from 'components/commons/NofoundInfo';
import { SearchBar } from 'components/forms';
import LocationItem from 'components/list/LocationItem';
import { useGetCurrentLocaion, useGetLocations } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { selectLocation } from 'redux/reducer/registers';
import { LocationFragment } from 'types/register.types';
import { debounce } from 'utils';

type FilterLocationState = {
  location_id: number;
  location_name: string;
  location_code: string;
};

interface Props {
  onSelectLocation: (location_id: number) => void;
}

const ListLocation: React.FC<Props> = (props: Props) => {
  const { onSelectLocation } = props;
  const [filterLocations, setFilterLocations] = React.useState<Array<FilterLocationState>>([]);
  const [locationActive, setLocation] = React.useState<number>(0);
  const [locationName, setLocationName] = React.useState<string>('');
  const [locations, setLocations] = React.useState<Array<LocationFragment>>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  //* Custom Hook
  const { currentLocation } = useGetCurrentLocaion();
  const { getLocations } = useGetLocations();

  // ** redus
  const DEFAULT_LOCATION = -99;
  const dispatch = useAppDispatch();
  const locationSelector = useAppSelector((state) => state.register?.location);
  const profile = useAppSelector((state) => state.auth.profile);

  // filter locations and check access locations user
  const filterLocation = () => {
    let location: FilterLocationState;
    if (currentLocation && currentLocation !== DEFAULT_LOCATION) {
      const result = locations?.filter(
        ({ location_id }: Pick<FilterLocationState, 'location_id'>) =>
          location_id === currentLocation
      );

      location = result[0];
      setFilterLocations(result);
    } else {
      const result = locations?.filter(
        ({ location_id }: Pick<FilterLocationState, 'location_id'>) =>
          location_id === locationSelector?.location_id
      );
      location = result.length > 0 ? result[0] : locations[0];
      setFilterLocations(locations);
    }

    return location;
  };

  // set selected default location by index 0
  React.useEffect(() => {
    if (locations && locations.length > 0) {
      const result = filterLocation();
      onSelectLocation(result.location_id);
      setLocation(result.location_id);
      dispatch(selectLocation(result));
    }
  }, [profile, currentLocation, locations]);

  // get locations
  const requestLocation = async (value: string): Promise<LocationFragment[]> => {
    try {
      setLoading(true);
      const res = await getLocations(value);
      setLocations(res);
      return res;
    } catch (error: any) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearchLocation = React.useCallback(
    debounce(async (search: string) => {
      const resultFilter = await requestLocation(search);
      setFilterLocations(resultFilter);
      setLocationName(search);
    }, 500),
    []
  );

  React.useEffect(() => {
    requestLocation('');
  }, []);

  return (
    <>
      <SearchBar placeholder='Cari Lokasi' onSearch={(e) => onSearchLocation(e.target.value)} />
      <Box
        mt={4}
        textAlign='center'
        overflowY='auto'
        maxH='calc(100vh - 220px)'
        position='relative'
      >
        {!isLoading && filterLocations.length === 0 && <NotfoundInfo text={locationName} />}
        {!isLoading ? (
          filterLocations.length > 0 &&
          filterLocations.map((location: FilterLocationState, index) => (
            <LocationItem
              active={location.location_id === Number(locationActive) ? true : false}
              key={index}
              onClick={() => {
                if (location.location_id !== locationActive) {
                  setLocation(location.location_id);
                  onSelectLocation(location.location_id);
                  dispatch(selectLocation(location));
                }
              }}
            >
              <Text isTruncated>{location.location_name}</Text>
            </LocationItem>
          ))
        ) : (
          <>
            <Stack>
              {[1, 2, 3].map((index) => (
                <Skeleton h='20px' w='full' key={index} />
              ))}
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default React.memo(ListLocation);
