import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { VStack } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Alert, AlertIcon, FormErrorMessage, HStack } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

interface ModalEmailProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newEmail: string, isUpdateEmail: boolean) => void;
  email: string;
  allowUpdateEmail: boolean;
  loading: boolean;
  onOpenAddCustomer: () => void;
  setEmail: (email: string) => void;
  addCustomer?: (isAddCustomer: boolean) => void;
}

const ModalEmail: React.FC<ModalEmailProps> = ({
  isOpen,
  onClose,
  onSubmit,
  email,
  allowUpdateEmail,
  loading,
  onOpenAddCustomer,
  setEmail,
  addCustomer,
}: ModalEmailProps) => {
  const [isError, setIsError] = React.useState<string>('');
  const [isUpdateEmail, setIsUpdateEmail] = React.useState<boolean>(false);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  React.useEffect(() => {
    if (!email) {
      setIsError('Harap masukan email.');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setIsError('Format email tidak valid');
    } else {
      setIsError('');
    }
  }, [email]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Ubah Email</ModalHeader>
        <ModalBody borderTop='1px' borderBottom='1px' borderColor='gray.200' py={4}>
          <Alert colorScheme='information' rounded={10}>
            <AlertIcon color={'blue.500'} />
            <div className='flex flex-col text-sm'>
              <span>Gunakan sekali hanya untuk mengirim Sekali.</span>
              <span>
                {allowUpdateEmail
                  ? 'Update Email untuk memperbaharui email pada kontak.'
                  : 'Tambah pelanggan untuk membuat pelanggan baru.'}
              </span>
              {!isOnline && <span>Email ini akan terkirim saat anda dalam mode online.</span>}
            </div>
          </Alert>

          <VStack spacing={4} mt={3}>
            <FormControl display='flex' className='space-x-3' alignItems='center' isInvalid={isError !== ''}>
              <FormLabel w='92px' htmlFor='name' fontSize='14px'>
                Email
              </FormLabel>
              <VStack w='full' alignItems='flex-start'>
                <Input
                  id='name'
                  name='payment_amount'
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // setNewEmail(e.target.value);
                  }}
                  value={email}
                  isDisabled={loading}
                />
                {isError !== '' && <FormErrorMessage>{isError}</FormErrorMessage>}
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={5}>
            <Button
              variant='outline'
              size='md'
              onClick={() => {
                setIsUpdateEmail(false);
                onSubmit(email, false);
              }}
              isLoading={loading && !isUpdateEmail}
              isDisabled={isError !== ''}
            >
              Gunakan Sekali
            </Button>
            {allowUpdateEmail === true ? (
              <Button
                variant='primary'
                isDisabled={allowUpdateEmail || isError !== '' || loading}
                onClick={() => {
                  setIsUpdateEmail(true);
                  onSubmit(email, true);
                }}
                type='submit'
                isLoading={loading && isUpdateEmail}
              >
                Update Email
              </Button>
            ) : (
              <Button
                variant='primary'
                isDisabled={isError !== '' || loading}
                onClick={() => {
                  addCustomer && addCustomer(true);
                  onOpenAddCustomer();
                }}
                type='submit'
              >
                Tambah Pelanggan
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEmail;
