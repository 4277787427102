import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const WifiActiveIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 22 18' {...props}>
    <path
      d='M11 18L14.6 13.2C13.6 12.45 12.35 12 11 12C9.65001 12 8.40001 12.45 7.40001 13.2L11 18ZM11 0C6.95001 0 3.21001 1.34 0.200012 3.6L2.00001 6C4.50001 4.12 7.62001 3 11 3C14.38 3 17.5 4.12 20 6L21.8 3.6C18.79 1.34 15.05 0 11 0ZM11 6C8.30001 6 5.81001 6.89 3.80001 8.4L5.60001 10.8C7.10001 9.67 8.97001 9 11 9C13.03 9 14.9 9.67 16.4 10.8L18.2 8.4C16.19 6.89 13.7 6 11 6Z'
      // fill="#7DB942"
    />
  </Icon>
);

export default WifiActiveIcon;
