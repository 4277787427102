import {
  Box,
  HStack,
  Image,
  StackProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { CircleIcon, JubelioIcon } from 'components/icons';
import { Title } from 'components/typography';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification } from 'hooks';
import React from 'react';
import bopisRequest from 'services/http/bopis.request';
import marketplaceIntegration from 'services/indexdb/marketplace-integration';
import {
  ICompleted,
  IDetailTransaction,
  IPacklist,
  IReadyToPick,
  IShipped,
} from 'types/bopis.types';
import { IMarketplaceIntegration } from 'types/common.types';
import { currencyFormat, getChannelIcon } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

interface TableBopisState extends StackProps {
  isLoading: boolean;
  listTransaction: IReadyToPick[] | IPacklist[] | ICompleted[] | IShipped[] | undefined;
  setOrder: (order: IDetailTransaction) => void;
  search: string;
  tabIndex: number;
}

const TableBopis: React.FC<TableBopisState> = (props: TableBopisState) => {
  const { notification } = useNotification();
  const { listTransaction, setOrder, tabIndex } = props;
  const [activeKey, setActiveKey] = React.useState<number | undefined>(undefined);
  const woocommerce = useLiveQuery<IMarketplaceIntegration | undefined>(() => {
    return marketplaceIntegration.findOne(131072);
  }, undefined);

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'Nomor Transaksi',
        isNumeric: false,
      },
      {
        title: 'Pelanggan',
        isNumeric: false,
      },
      {
        title: 'Channel',
        isNumeric: false,
      },
      {
        title: 'Kurir',
        isNumeric: false,
      },
      {
        title: 'Harga',
        isNumeric: true,
      },
    ],
    []
  );

  const getTableHeader = React.useCallback(() => {
    const header = tableHeader;
    if (tabIndex === 1 || tabIndex === 2) {
      return header.filter((obj) => obj.title !== 'Harga');
    }
    return header;
  }, [tabIndex, tableHeader]);

  const getOrderDetail = async (
    dataOrder: IReadyToPick | IPacklist | ICompleted | IShipped,
    index: number
  ) => {
    try {
      const order = await bopisRequest.getTransactionDetail(dataOrder.salesorder_id);
      setOrder({ ...order, picklist_id: dataOrder.picklist_id ?? 0 });
      setActiveKey(index);
      return;
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  return (
    <Box
      bg='white'
      rounded='md'
      border='1px'
      borderColor='#ECEFF1'
      maxH='calc(100vh - 375px)'
      overflowY='auto'
      pb={2}
    >
      <Table size='sm' variant='simple' w='full'>
        <Thead position='sticky' top={0}>
          <Tr>
            {getTableHeader().map((theader, index) => (
              <Th
                key={`table-header-${index}`}
                bgColor='jubelio.grey100'
                p={4}
                isNumeric={theader.isNumeric}
              >
                <Text as='span' fontSize={['10px', '10px', '11px', '12px']}>
                  {theader.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listTransaction &&
            listTransaction.map((order, index) => (
              <Tr
                key={index}
                _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
                bg={activeKey === index ? 'jubelio.red100' : 'white'}
                onClick={() => {
                  getOrderDetail(order, index);
                }}
              >
                <Td>
                  <HStack>
                    <CircleIcon
                      h='16px'
                      color={order.shipment_type === 'pickup_in_store' ? 'green.800' : 'batch.800'}
                    />
                    <VStack alignItems='flex-start'>
                      <Title>{order.salesorder_no}</Title>
                      <Text fontSize={12}>{convertToLocalDate(order.transaction_date)}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td>{order.customer_name}</Td>
                <Td>
                  <HStack w='full'>
                    {getChannelIcon(
                      order.source_name ?? '',
                      woocommerce?.extra_info.isJubelioStore
                    ) === 'JUBELIOPOS' ? (
                      <JubelioIcon width='20px' height='20px' />
                    ) : (
                      <Image
                        src={getChannelIcon(
                          order.source_name ?? '',
                          woocommerce?.extra_info.isJubelioStore
                        )}
                        w='20px'
                      />
                    )}
                    <Text>{order.source_name}</Text>
                  </HStack>
                </Td>
                <Td>{order.shipper}</Td>
                {(tabIndex === 0 || tabIndex === 3) && (
                  <Td isNumeric>{currencyFormat(Math.abs(Number(order?.grand_total ?? 0)))}</Td>
                )}
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default React.memo(TableBopis);
