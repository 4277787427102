import { Box, BoxProps, Divider, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import NotFound from 'assets/images/notfound.png';
import { InputNumber } from 'components/forms';
import { OutofStock } from 'components/state';
import { SubTitle } from 'components/typography';
import React from 'react';
import { IItemToPickList } from 'types/bopis.types';
import { currencyFormat } from 'utils';

interface CardPickItemProps extends BoxProps {
  item: IItemToPickList;
  updateQtyPick: (e: any, salesorderDetailId: number) => void;
}

const CardPickItem: React.FC<CardPickItemProps> = ({
  item,
  updateQtyPick,
  ...rest
}: CardPickItemProps) => {
  const incrementsQtyPick = (item: IItemToPickList) => {
    if (Number(item.qty_picked) + 1 <= Number(item.qty_ordered)) {
      updateQtyPick(Number(item.qty_picked) + 1, item.salesorder_detail_id);
    }
  };

  const decrementsQtyPick = (item: IItemToPickList) => {
    if (Number(item.qty_picked) - 1 >= 0) {
      updateQtyPick(Number(item.qty_picked) - 1, item.salesorder_detail_id);
    }
  };

  return (
    <Box
      border='1px'
      borderColor='gray.200'
      p='12px'
      w='full'
      borderRadius='4px'
      position='relative'
      {...rest}
    >
      {Number(item.end_qty) < Number(item.qty_ordered) && (
        <OutofStock title='Stok kurang dari jumlah yang dipesan' />
      )}
      <Flex className='space-x-3' mb='10px' position='relative'>
        <Image src={item.thumbnail ? item.thumbnail : NotFound} width='70px' rounded='4px' />
        <VStack alignItems='flex-start' justifyContent='flex-start' w='60%'>
          <Text fontSize='14px' fontWeight='bold' isTruncated w='full'>
            {item.item_name}
          </Text>
          <SubTitle fontSize='12px' color='jubelio.black'>
            {item.item_code}
          </SubTitle>
          {item.amount ? (
            <Text fontSize='14px' fontWeight='bold'>
              {currencyFormat(item.amount)}
            </Text>
          ) : null}
        </VStack>
      </Flex>
      <Divider />
      <HStack spacing={2} mt={2} w='full'>
        <VStack w='50%' alignItems='flex-start'>
          <SubTitle color='jubelio.black' fontSize='14px'>
            Jumlah Pesanan : <strong>{Number(item.qty_ordered)} item</strong>
          </SubTitle>
          <SubTitle fontSize='12px'>Stok Tersedia : {Number(item.end_qty)}</SubTitle>
        </VStack>
        <HStack justifyContent='flex-end' w='50%'>
          <SubTitle w='50%' color='jubelio.black' fontSize='14px' textAlign='right'>
            QTY Ambil
          </SubTitle>
          <InputNumber
            value={Math.abs(Number(item.qty_picked))}
            size='sm'
            textAlign='center'
            w='full'
            max={Number(item.qty_ordered)}
            min={0}
            onChange={(e) => updateQtyPick(e.target.value, item.item_id)}
            decrement={() => decrementsQtyPick(item)}
            increment={() => incrementsQtyPick(item)}
          />
        </HStack>
      </HStack>
    </Box>
  );
};
export default React.memo(CardPickItem);
