import { Box, Flex } from '@chakra-ui/react';
import BoxIcon from 'assets/svg/BoxIcon.svg';
import NotFoundImage from 'assets/svg/Notfound.svg';
import { CardMain } from 'components/card';
import Pagination from 'components/commons/Pagination';
import MainLayout from 'components/layout/MainLayout';
import { LoadingState } from 'components/state';
import { BoxImage, Header, ListProduct } from 'components/ui/pricelist';
import DetailProduct from 'components/ui/pricelist/DetailProduct';
import { useAppSelector } from 'hooks/redux';
import { tracker } from 'lib/helpers';
import React from 'react';
import productsRequest from 'services/http/products.request';
import { FragmentProductPrice, IDetailPrice } from 'types/products.types';

const PriceCheckerPage = () => {
  const [listProducts, setProducts] = React.useState<FragmentProductPrice>(
    {} as FragmentProductPrice
  );
  const [isLoadingSearch, setLoadingSearch] = React.useState<boolean>(false);
  const [isLoadingPage, setLoadingPage] = React.useState<boolean>(false);
  const [detailProduct, setDetailProduct] = React.useState<IDetailPrice>({} as IDetailPrice);
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [searchText, setSearch] = React.useState<string>('');

  // ** Redux State
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const profile = useAppSelector((state) => state.auth.profile);
  const locationState = useAppSelector((state) => state.register.location);

  // Metadata for Openreplay
  tracker.setMetadata('name', profile?.user.full_name ?? '');
  tracker.setMetadata('email', profile?.user.email ?? '');
  tracker.setMetadata('companyName', profile?.companies[0].company_name ?? '');
  tracker.setMetadata('registerId', String(registerInfo?.register_id) ?? '');
  tracker.setMetadata(
    'registerName',
    `${registerInfo?.register_name} - ${registerInfo?.register_code}`
  );
  tracker.setMetadata('locationId', String(locationState?.location_id) ?? '');
  tracker.setMetadata(
    'locationName',
    `${locationState?.location_name} - ${locationState?.location_code}`
  );

  const handleSearch = async (
    _page: number,
    _perPage: number,
    value: string,
    method: string
  ): Promise<void> => {
    try {
      setDetailProduct({} as IDetailPrice); // reset detail product
      if (method === 'search' || method === 'scan') {
        setLoadingSearch(true);
      } else {
        setLoadingPage(true);
      }

      const res = await productsRequest.checkPrice(
        Number(locationState?.location_id),
        _page,
        _perPage,
        value
      );
      if (method === 'scan') {
        const product = res.data.length > 0 ? res.data[0] : ({} as IDetailPrice);
        await getDetailPriceChecker(product.item_id); // set detail product if scan
      }

      setProducts(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoadingPage(false);
      setLoadingSearch(false);
    }
  };

  const getDetailPriceChecker = async (itemId: number): Promise<void> => {
    try {
      setDetailProduct({} as IDetailPrice); // reset detail product
      const res = await productsRequest.checkPriceDetail(
        Number(locationState?.location_id),
        itemId
      );
      setDetailProduct(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoadingPage(false);
      setLoadingSearch(false);
    }
  };

  // handle search product and change pagination
  React.useEffect(() => {
    if (listProducts.data) {
      handleSearch(page, pageSize, searchText, 'paginate');
    }
  }, [page, pageSize]);

  // prevent user back to page
  React.useEffect(() => {
    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', function () {
      window.location.replace('/price-checker');
    });
  }, []);

  return (
    <MainLayout>
      <CardMain h='auto' position='relative'>
        <Flex
          w={{ base: '55%', lg: '65%' }}
          borderRight='1px'
          borderColor='gray.200'
          position='relative'
          flexDir='column'
          h='calc(100vh - 90px)'
        >
          <Header
            mx='32px'
            py='16px'
            isLoading={isLoadingSearch}
            onSearch={(value) => {
              setPage(1);
              handleSearch(1, pageSize, value, 'search');
              setSearch(value);
            }}
            onScan={(value) => {
              setPage(1);
              handleSearch(1, pageSize, value, 'scan');
              setSearch(value);
            }}
          />

          <Box
            position='relative'
            display='flex'
            flexDir='column'
            w='full'
            h='full'
            overflowY='auto'
          >
            {!listProducts.data && (
              <BoxImage
                startIcon={BoxIcon}
                title='Temukan Barang Disini'
                subtitle='Cari barang dan lihat harga dari setiap barang yang ada'
              />
            )}
            {listProducts.data && listProducts.data.length === 0 && (
              <BoxImage
                startIcon={NotFoundImage}
                title='Barang tidak ditemukan'
                subtitle='Barang yang kamu cari tidak ditemukan. Pastikan pencarian anda benar'
              />
            )}

            {listProducts.data && listProducts.totalCount !== 0 && (
              <React.Fragment>
                {(isLoadingPage || isLoadingSearch) && <LoadingState title='Tunggu Sebentar...' />}
                <Box position='relative'>
                  {listProducts?.data?.map((item, index) => (
                    <ListProduct
                      product={item}
                      key={index}
                      onClick={() => getDetailPriceChecker(item.item_id)}
                      isActive={item.item_id === detailProduct.item_id}
                    />
                  ))}
                </Box>
              </React.Fragment>
            )}
          </Box>
          {listProducts.data && listProducts.totalCount > 0 && (
            <div className='footer-pagination flex p-2 px-[0.8rem]'>
              <Pagination
                onChangePage={(e) => setPage(e.selected + 1)}
                totalPage={listProducts.totalCount}
                pageSize={pageSize}
                onChangeRow={(e) => {
                  setPage(1);
                  setPageSize(e);
                }}
                page={page}
              />
            </div>
          )}
        </Flex>
        <Box
          w={{ base: '45%', lg: '35%' }}
          p='16px'
          overflowY='auto'
          maxH='calc(100vh - 120px)'
          h='full'
        >
          {Object.keys(detailProduct).length > 0 && <DetailProduct product={detailProduct} />}
        </Box>
      </CardMain>
    </MainLayout>
  );
};

export default PriceCheckerPage;
