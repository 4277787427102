import { Box, BoxProps, HStack, Spinner } from '@chakra-ui/react';
import { SubTitle } from 'components/typography';
import React from 'react';

interface Props extends BoxProps {
  title?: string;
}

const LoadingState: React.FC<Props> = ({ title, ...props }: Props) => {
  return (
    <Box
      zIndex={200}
      position='absolute'
      left={0}
      top={0}
      w='full'
      h='full'
      display='flex'
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <HStack bg='white' boxShadow='md' py={2} px={4} rounded='4px'>
        <Spinner color='jubelio.primary' size='sm' />
        <SubTitle>{title}</SubTitle>
      </HStack>
    </Box>
  );
};

export default LoadingState;
