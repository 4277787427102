import config from 'constant';
import { IAuthorizedUser } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * This method is used to create a new authorized user.
   *
   * @param {string} permissionId - The permission of authorized user.
   * @param {string} data - The data of the authorized user.
   * @returns {Promise<void>}
   * @example authorizedUser.addAuthorizedUser('authorizeduser', [{user_id: 10, full_name: 'User'}])}]);
   */
  addAuthorizedUser: async (permissionId: number, data: IAuthorizedUser): Promise<boolean> => {
    try {
      await db.authorizeduser.add({ ...data, permission_id: permissionId });
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },
  /**
   * This method is used to get all authorized user.
   * @returns {Promise<IAuthorizedUser>}
   * @example with async - await authorizedUser.getAll()
   * @example authorizedUser.getAll().then(data => console.log(data))
   */
  getAll: async (permissionId: number): Promise<IAuthorizedUser[]> => {
    try {
      return db.authorizeduser.where('permission_id').equals(permissionId).toArray();
    } catch (error: any) {
      return error;
    }
  },
  clear: async (): Promise<boolean> => {
    try {
      await db.table('authorizeduser').clear();
      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },
  /**
   * Update authorized user in IndexDB from backoffice in realtime
   * @param {IAuthorizedUser} authorizedUser
   * @returns {Promise<void>}
   */
  updateAuthorizedUser: async (authorizedUser: IAuthorizedUser): Promise<void> => {
    try {
      const isOwner = authorizedUser.is_owner ?? false;
      const data = { ...authorizedUser };
      delete data.is_owner;
      if (isOwner) {
        await db.authorizeduser.where('user_id').equals(data.user_id).delete();
        [config.ACL_AUTH_CANCEL, config.ACL_AUTH_DP, config.ACL_AUTH_RETURN].forEach(
          async (permission) => {
            await db.authorizeduser.add({ ...data, permission_id: permission });
          }
        );
      } else {
        await db.authorizeduser.where('user_id').equals(data.user_id).modify(data);
      }
      return Promise.resolve();
    } catch (error: any) {
      return error;
    }
  },
};
