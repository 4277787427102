import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  IconButton,
  Tag,
  Text,
} from '@chakra-ui/react';
import ListTotal from 'components/list/ListTotal';
import { TooltipPromotion } from 'components/popover/Promotion';
import { Dashed } from 'components/utils';
import { groupBy } from 'lib/helpers';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { IPaymentsTransaction } from 'types/transaction.types';
import {
  convertToLocalDateWithoutTime,
  currencyFormat,
  getPaymentCharge,
  getPaymentsName,
  getTotalItemsHistory,
} from 'utils';

interface Props {
  showTotal: boolean;
  detailOrder: DetailOrderTransaction;
  onShowTotal: (state: boolean) => void;
}

const ListDetail: React.FC<Props> = ({ showTotal, onShowTotal, detailOrder }: Props) => {
  const paymentCharge = React.useMemo(() => {
    return getPaymentCharge(detailOrder?.payments ?? []);
  }, [detailOrder]);

  const groupingPayment = React.useMemo(() => {
    const detailPaymentList = [...detailOrder.payments];
    const listPayment =
      detailPaymentList &&
      detailPaymentList.sort((a, b) => {
        return new Date(a.created_date).getDate() - new Date(b.created_date).getDate();
      });
    return groupBy<IPaymentsTransaction>(listPayment, (v) => {
      return convertToLocalDateWithoutTime(v.created_date);
    });
  }, [detailOrder]);

  const totalPaymentPerDate = (payment: IPaymentsTransaction[]) => {
    return payment.reduce((sum, item) => {
      return sum + Number(item.payment_amount) + Number(item.payment_charge);
    }, 0);
  };

  const listPromo = React.useMemo(() => {
    const mapPromotion = detailOrder.promotions_v2
      ? Array.from(new Set(detailOrder.promotions_v2.map((p) => p.promotion_name)))
      : [];
    return mapPromotion;
  }, [detailOrder]);

  return (
    <div className='flex w-full flex-col px-2 py-2'>
      {showTotal ? (
        <div className='flex w-full flex-col px-0'>
          <ListTotal
            leftText={
              detailOrder.is_return === 1
                ? 'Sub Total'
                : `Sub Total (${getTotalItemsHistory(detailOrder.items)} Barang)`
            }
            rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.sub_total)))}</Text>}
          />
          {Number(detailOrder.total_disc) > 0 ? (
            <ListTotal
              leftText='Diskon Barang'
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(Number(detailOrder.total_disc))}
                </Text>
              }
            />
          ) : null}
          {Number(detailOrder.service_fee) !== 0 || Number(detailOrder.add_disc) !== 0 ? (
            <ListTotal
              leftText={
                <HStack>
                  <span>Diskon Transaksi</span>
                  {listPromo && listPromo.length > 0 && (
                    <TooltipPromotion placement='right' listPromotion={listPromo} />
                  )}
                </HStack>
              }
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(
                    Math.abs(
                      detailOrder.service_fee && Number(detailOrder.service_fee) !== 0
                        ? Number(detailOrder.service_fee)
                        : Number(detailOrder.add_disc)
                    )
                  )}
                </Text>
              }
            />
          ) : null}
          {Number(detailOrder.discount_trx) > 0 ? (
            <ListTotal
              leftText={
                <HStack>
                  <span>Diskon Transaksi</span>
                  {listPromo && listPromo.length > 0 && (
                    <TooltipPromotion placement='right' listPromotion={listPromo} />
                  )}
                </HStack>
              }
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(
                    Math.abs(
                      detailOrder.service_fee && Number(detailOrder.service_fee) !== 0
                        ? Number(detailOrder.service_fee)
                        : Number(detailOrder.add_disc)
                    )
                  )}
                </Text>
              }
            />
          ) : null}
          {Number(detailOrder.shipping_cost) > 0 ? (
            <ListTotal
              leftText='Ongkos Kirim'
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.shipping_cost)))}</Text>}
            />
          ) : null}

          {Number(detailOrder.insurance_cost) > 0 ? (
            <ListTotal
              leftText='Asuransi Pengiriman'
              rightText={
                <Text>{currencyFormat(Math.abs(Number(detailOrder.insurance_cost)))}</Text>
              }
            />
          ) : null}

          {Number(detailOrder.total_tax) > 0 ? (
            <ListTotal
              leftText={
                <HStack>
                  <Text>Pajak</Text>
                  {detailOrder.is_tax_included === true && (
                    <Tag
                      bg='accent.200'
                      color='accent.400'
                      fontWeight='semibold'
                      variant='solid'
                      size='sm'
                      borderRadius='4px'
                    >
                      Harga Termasuk Pajak
                    </Tag>
                  )}
                </HStack>
              }
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.total_tax)))}</Text>}
            />
          ) : null}

          {Number(detailOrder.add_fee) !== 0 ? (
            <ListTotal
              leftText='Biaya Lainnya'
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.add_fee)))}</Text>}
            />
          ) : null}
          {/* section payments */}
          {groupingPayment && Object.keys(groupingPayment).length > 1 ? (
            <>
              <Accordion allowMultiple w='full'>
                <AccordionItem>
                  <h2>
                    <AccordionButton px='0px'>
                      <Box flex='1' textAlign='left'>
                        <ListTotal
                          leftText={
                            <Text fontSize='14px' py={1}>
                              Pembayaran
                            </Text>
                          }
                          rightText={
                            <Text>
                              {currencyFormat(
                                Math.abs(Number(detailOrder.payment_amount)) +
                                  Math.abs(Number(paymentCharge.kembalian))
                              )}
                            </Text>
                          }
                        />
                      </Box>
                      <AccordionIcon fontSize={14} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} px='0px'>
                    <Accordion allowMultiple w='full'>
                      {Object.keys(groupingPayment).map((key: string, index: number) => (
                        <AccordionItem key={index}>
                          <h2>
                            <AccordionButton px='0px'>
                              <Box flex='1' textAlign='left'>
                                <ListTotal
                                  key={index}
                                  leftText={<Text fontSize={12}>{key}</Text>}
                                  rightText={
                                    <Text fontSize={12}>
                                      {currencyFormat(totalPaymentPerDate(groupingPayment[key]))}
                                    </Text>
                                  }
                                />
                              </Box>
                              <AccordionIcon fontSize={12} />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} px='0px'>
                            {groupingPayment[key].map(
                              (detail: IPaymentsTransaction, index: number) => (
                                <ListTotal
                                  key={index}
                                  leftText={
                                    <Box>
                                      <Text as='span'>{getPaymentsName(detail)}</Text>
                                      <Text
                                        as='span'
                                        display='block'
                                        fontSize='12px'
                                        color='jubelio.grey300'
                                      >
                                        {detail.no_ref}
                                      </Text>
                                    </Box>
                                  }
                                  rightText={
                                    <Text>
                                      {currencyFormat(
                                        Math.abs(Number(detail.payment_amount)) +
                                          Math.abs(Number(detail.payment_charge))
                                      )}
                                    </Text>
                                  }
                                />
                              )
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </>
          ) : (
            <div className='my-1'>
              <div className='divider my-1' />
              <Text fontSize='14px' py={1} textAlign='left' w='full'>
                Pembayaran
              </Text>
              {detailOrder.payments?.map((payment, index: number) => (
                <ListTotal
                  key={index}
                  leftText={
                    <Box>
                      <Text as='span'>{getPaymentsName(payment)}</Text>
                      <Text as='span' display='block' fontSize='12px' color='jubelio.grey300'>
                        {payment.no_ref}
                      </Text>
                    </Box>
                  }
                  rightText={
                    <Text>
                      {currencyFormat(
                        Math.abs(Number(payment.payment_amount)) +
                          Math.abs(Number(payment.payment_charge))
                      )}
                    </Text>
                  }
                />
              ))}
            </div>
          )}
          {paymentCharge.kembalian !== 0 ? (
            <ListTotal
              leftText={
                <Box>
                  <Text as='span'>Kembalian</Text>
                </Box>
              }
              rightText={
                <Text color='system.orange'>
                  {currencyFormat(Math.abs(Number(paymentCharge.kembalian)))}
                </Text>
              }
            />
          ) : null}
          <Dashed w='full' pt={2} />
        </div>
      ) : null}
      <ListTotal
        leftText={
          detailOrder.is_return === 1
            ? 'Total Pengembalian'
            : detailOrder.pos_is_unpaid === true || Number(detailOrder.pos_is_unpaid) === 1
            ? 'Sisa Bayar'
            : 'Total Bayar'
        }
        pt='5px'
        rightText={
          <HStack>
            <Text color='jubelio.primary' fontWeight='bold'>
              {currencyFormat(
                Math.abs(
                  Number(
                    detailOrder.is_return === 1 || !detailOrder.pos_is_unpaid
                      ? detailOrder.grand_total
                      : Number(detailOrder.grand_total) - Number(detailOrder.payment_amount)
                  )
                )
              )}
            </Text>
            <IconButton
              variant='ghost'
              aria-label='add to shopping cart'
              _focus={{ boxShadow: 'none' }}
              onClick={() => onShowTotal(!showTotal)}
              icon={
                showTotal ? (
                  <ChevronDownIcon h='20px' w='20px' />
                ) : (
                  <ChevronUpIcon h='20px' w='20px' />
                )
              }
              size='sm'
            />
          </HStack>
        }
      />
    </div>
  );
};

export default ListDetail;
