import { Box, Divider, Skeleton, Stack, StackProps } from '@chakra-ui/react';
import { CashRegisterIcon, ClockIcon, StoreMarkIcon } from 'components/icons';
import { SubTitle } from 'components/typography';
import * as React from 'react';
import { Maybe } from 'types';
import { IUserInfo } from 'types/auth.types';
import { formatDate } from 'utils';

interface CardInfoState extends StackProps {
  user: Maybe<IUserInfo> | undefined;
  isLoading: boolean;
}

const CardInfo: React.FC<CardInfoState> = (props: CardInfoState) => {
  const { user, isLoading, ...restProps } = props;
  return (
    <Stack
      p='0.80rem'
      w='full'
      rounded='4px'
      border='1px'
      boxShadow='main-card'
      borderColor='gray.200'
      {...restProps}
    >
      {!isLoading ? (
        <>
          <Box fontSize='14px'>
            <ClockIcon h='20px' w='20px' />
            <SubTitle as='span' ml={2} color='jubelio.black' fontWeight='500'>
              {formatDate(user?.closures?.open_date)}
            </SubTitle>
          </Box>
          <Divider py={1} colorScheme='white' />

          <Box fontSize='14px'>
            <CashRegisterIcon h='20px' w='20px' fill='jubelio.grey200' />
            <SubTitle as='span' ml={2} color='jubelio.black' fontWeight='500'>
              {user?.closures?.register_name}
            </SubTitle>
          </Box>
          <Box fontSize='14px'>
            <StoreMarkIcon h='20px' w='20px' />
            <SubTitle as='span' ml={2} color='jubelio.black' fontWeight='500'>
              {user?.closures?.location_name}
            </SubTitle>
          </Box>
        </>
      ) : (
        <Stack spacing={5}>
          <Skeleton height='15px' startColor='green.300' endColor='green.200' rounded='lg' />
          <Skeleton height='15px' startColor='green.300' endColor='green.200' rounded='lg' />
          <Skeleton height='15px' startColor='green.300' endColor='green.200' rounded='lg' />
          <Skeleton
            height='15px'
            w='150px'
            startColor='green.300'
            endColor='green.200'
            mb={4}
            rounded='lg'
          />
          <Skeleton
            height='15px'
            w='20rem'
            startColor='green.300'
            endColor='green.200'
            rounded='lg'
          />
          <Skeleton
            height='15px'
            w='20rem'
            startColor='green.300'
            endColor='green.200'
            rounded='lg'
          />
        </Stack>
      )}
    </Stack>
  );
};

export default CardInfo;
