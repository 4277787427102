import { Flex, FlexProps, HStack, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

type CardProps = FlexProps;

const CardLoadingMain: React.FC<CardProps> = ({ ...rest }: CardProps) => {
  return (
    <Flex
      boxShadow='main-card'
      rounded='0.50rem'
      m={3}
      mt='76px'
      h='calc(100vh - 30px)'
      alignItems='center'
      justifyContent='center'
      className='fixed inset-0 z-10 overflow-y-auto bg-white/40'
      {...rest}
    >
      <HStack
        bg='white'
        boxShadow='md'
        py={2}
        px={4}
        rounded='4px'
        borderColor='jubelio.gray200'
        border='1px'
      >
        <Spinner color='jubelio.primary' size='sm' />
        <Text>Harap Tunggu ...</Text>
      </HStack>
    </Flex>
  );
};

export default CardLoadingMain;
