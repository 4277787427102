/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import config from 'constant';
import React from 'react';

const websocket = new WebSocket(config.WS_CONNECTION);

export const SocketContext = React.createContext(websocket);

interface ISocketProvider {
  children: React.ReactNode;
}

export const PosServiceProvider = (props: ISocketProvider) => {
  const [ws, setWs] = React.useState<WebSocket>(websocket);

  React.useEffect(() => {
    const onClose = (event: CloseEvent) => {
      console.log('reconnecting to print service....');

      if (event.type === 'close') {
        console.log('Connection closed, to reconnect try refreshing', event.reason);
        return;
      }

      setTimeout(() => {
        setWs(new WebSocket(config.WS_CONNECTION));
        console.log('reconnected to print service....');
      }, 5000);
    };

    ws?.addEventListener('close', onClose);

    return () => ws?.removeEventListener('close', onClose);
  }, [ws, setWs]);

  return <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>;
};
