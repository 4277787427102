import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { CashRegisterIcon, SettingIcon, SignOutIcon } from 'components/icons';
import config from 'constant';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { logoutAction } from 'redux/reducer/auth';
import { removeSelectedLocation, setAsDeviceChecker } from 'redux/reducer/registers';
import authAction from 'services/http/auth.request';
import commonRequest from 'services/http/common.request';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import customTheme from 'theme/select-theme';

const NavbarRegister: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notification } = useNotification();

  const [listCompany, setListCompany] = React.useState<{ value: number; label: string }[]>([]);
  const [searchCompany, setSearchCompany] = React.useState<string>('');

  // ** Redux store
  const profile = useAppSelector((state) => state.auth.profile);
  const isPriceChecker = useAppSelector((state) => state.register.isPriceChecker);
  const location = useAppSelector((state) => state.register.location);
  const token = LocalStorageService.getItem('token');

  const handleLogout = async () => {
    await authAction.logout();

    if (isPriceChecker) {
      dispatch(setAsDeviceChecker(false));
    }
    dispatch(logoutAction());
    window.location.href = '/';
  };

  const getListCompany = async (id: number, q: string) => {
    try {
      if (id !== 0) {
        const res = await commonRequest.getCompanies(id, profile?.user.email ?? '', q);
        if (res) {
          const optionCompany = res.map((item) => {
            return { value: item.company_id, label: item.company_name };
          });
          setListCompany(optionCompany);
        }
      }
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  const switchCompany = async (id: number) => {
    try {
      if (id !== 0) {
        dispatch(removeSelectedLocation());
        await commonRequest.switchCompany(id);
        window.location.reload();
      }
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  React.useEffect(() => {
    getListCompany(profile?.companies[0]?.user_id ?? 0, '');
  }, [profile, token]);

  React.useEffect(() => {
    if (searchCompany !== '') {
      getListCompany(profile?.companies[0]?.user_id ?? 0, searchCompany);
    }
  }, [searchCompany, token]);

  return (
    <Flex
      as='nav'
      justifyContent='space-between'
      alignItems='center'
      px={[1, 4, 5, 5]}
      bg='white'
      py={3}
      w='full'
      borderBottom='1px'
      borderColor='gray.200'
      top={0}
      left={0}
      zIndex='100'
    >
      <Text fontSize='16px'>
        <b>{profile?.companies[0].company_name} - </b>
        {location?.location_name}
      </Text>
      <HStack w='50%' justifyContent='flex-end' spacing={6} zIndex={100}>
        <Box w='40%'>
          <Select
            placeholder='--Pilih Company--'
            options={listCompany || []}
            styles={customTheme}
            menuPlacement='auto'
            onInputChange={(e) => setSearchCompany(e)}
            onChange={(e) => {
              switchCompany(e?.value ?? 0);
            }}
            value={{
              value: profile?.companies[0]?.company_id,
              label: profile?.companies[0]?.company_name,
            }}
          />
        </Box>
        {window.location.pathname.indexOf('setting') > -1 && (
          <Button
            variant='outline'
            size='md'
            px={7}
            borderRadius='8px'
            color='jubelio.black'
            leftIcon={<CashRegisterIcon fill='jubelio.grey200' />}
            onClick={() => {
              navigate('/register');
            }}
          >
            Mesin Kasir
          </Button>
        )}
        <Menu placement='bottom-end' isLazy={true} lazyBehavior='unmount'>
          <MenuButton>
            <Avatar boxSize='40px' src={`${config.IMAGE_URL}?token=${token}`} />
          </MenuButton>
          <MenuList
            minH='48px'
            border='0'
            boxShadow='md'
            rounded='lg'
            fontSize='13px'
            fontWeight='800'
            color='gray.600'
            tabIndex={0}
          >
            <MenuItem flexDir='column' alignItems='start'>
              <Text fontSize='11px' color='gray.500'>
                {profile?.user.full_name}
              </Text>
              <Text color='jubelio.100' fontWeight='500'>
                {profile?.email}
              </Text>
            </MenuItem>
            <MenuItem
              icon={<SettingIcon w='14px' h='14px' />}
              onClick={() => navigate('/setting/store-info')}
            >
              Pengaturan
            </MenuItem>
            <MenuItem
              icon={<QuestionOutlineIcon w='14px' h='14px' />}
              onClick={() => window.open('https://education.jubelio.com/', '_blank')}
            >
              Bantuan
            </MenuItem>
            <MenuDivider borderColor='gray.100' />
            <MenuItem icon={<SignOutIcon />} color='red' onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};
export default NavbarRegister;
