import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { IPaymentReferenceList } from 'types/transaction.types';
import { convertToLocalDate, currencyFormat } from 'utils';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  detailOrder: DetailOrderTransaction;
  listPaymentReference: IPaymentReferenceList[];
  getPaymentReference: (amount: number, isRefresh: boolean) => void;
  isLoading: boolean;
  updateQrisRefNo: (noRef: string) => void;
}

const ModalPaymentReference: React.FC<Props> = (props: Props) => {
  const {
    isOpen,
    onClose,
    title,
    detailOrder,
    listPaymentReference,
    getPaymentReference,
    isLoading,
    updateQrisRefNo,
    ...rest
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      {...rest}
      isCentered
      size='xl'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody borderTop='1px' borderColor='gray.200' py='20px' px='16px'>
          <Stack w='full' spacing={4}>
            <Box border='1px' borderColor='system.outline' p={5} rounded='4px' bg='system.smoke'>
              <Flex alignItems='center' justifyContent='space-between'>
                <Stack spacing={2}>
                  <Title fontSize='14px'>
                    {detailOrder.is_return === 1
                      ? detailOrder.pos_return_no
                      : detailOrder.salesorder_no}
                  </Title>
                  <SubTitle color='jubelio.black'>
                    {currencyFormat(Number(detailOrder.grand_total))}
                  </SubTitle>
                  <SubTitle color='jubelio.black'>
                    {convertToLocalDate(detailOrder.transaction_date)}
                  </SubTitle>
                </Stack>
              </Flex>
            </Box>
          </Stack>
          <Divider my={4} />
          <Title>Daftar Referensi Pembayaran</Title>
          <Box minH='270px' h='full' overflowY='auto' mt={5}>
            {listPaymentReference && (
              <Box overflowY='auto' maxH='270px'>
                {listPaymentReference.map((item: IPaymentReferenceList, key: number) => (
                  <Box
                    key={key}
                    borderBottom='1px'
                    borderColor='gray.200'
                    minH='42px'
                    py={2}
                    px={2}
                    _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
                    onClick={() => updateQrisRefNo(item.xendit_qris_id)}
                  >
                    <Title fontSize='14px'>
                      Rp. {currencyFormat(item.amount)} - {convertToLocalDate(item.created_date)}
                    </Title>
                    <SubTitle color='jubelio.black' mt={2}>
                      {item.xendit_qris_id}
                    </SubTitle>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter
          boxShadow='0px -4px 6px -1px rgba(23, 80, 22, 0.06)'
          justifyContent='space-between'
        >
          <Text fontSize='12px'>Tekan refresh bila sudah melakukan pembayaran</Text>
          <Button
            variant='primary'
            onClick={() => getPaymentReference(Number(detailOrder.grand_total), true)}
            loadingText='Harap Tunggu..'
            isLoading={isLoading}
          >
            Refresh
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPaymentReference;
