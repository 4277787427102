import {
  ICustomerGetPromotion,
  IGetPricebookDiscount,
  IGetPromotion,
  IGetPromotionResponse,
  IValidatePromotionResponse,
  IValidateVoucher,
  ResPriceBookDiscount,
} from 'types/promotion.types';

import request from './request';

export default {
  getAvailablePromotion: async (payload: IGetPromotion): Promise<IGetPromotionResponse> => {
    try {
      const res = await request.post(`sales/pos/v3/promotion`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  validateVoucherPromotion: async (payload: {
    customer: ICustomerGetPromotion;
    voucher_code: string;
    current_date: Date | string;
    order: Pick<IGetPromotion, 'items' | 'location_id' | 'grand_total'>;
  }): Promise<IValidateVoucher> => {
    try {
      const res = await request.post(`/sales/pos/v3/promotion/voucher/validate`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  validatePromotion: async (payload: IGetPromotion): Promise<IValidatePromotionResponse> => {
    try {
      const res = await request.post(`/sales/pos/v3/promotion/validate`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPricebookandDiscount: async (
    payload: IGetPricebookDiscount
  ): Promise<ResPriceBookDiscount> => {
    try {
      const res = await request.post(`/sales/pos/v3/inventory/product/discount-pricebook`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
