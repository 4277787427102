import Tracker from '@openreplay/tracker';

export function isEqual(
  obj1: Record<string, any> | null,
  obj2: Record<string, any> | null
): boolean {
  if (!obj1 || !obj2) return false;

  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length !== props2.length) {
    return false;
  }
  for (let i = 0; i < props1.length; i++) {
    const val1 = obj1 && obj1[props1[i]];
    const val2 = obj2 && obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}

function isObject(object: Record<string, any>) {
  return object != null && typeof object === 'object';
}

type IGroupRes<T> = { [key: string]: T[] };

export const groupBy = <T>(array: T[] = [], predicate: (v: T) => string): IGroupRes<T> =>
  array?.reduce((acc, value) => {
    (acc[predicate(value)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export const stringSplitter = (length: number, str: string): string[] => {
  // Split up the string and use `reduce`
  // to iterate over it
  const temp = str.split(' ').reduce(
    (acc: any, c) => {
      // Get the number of nested arrays
      const currIndex = acc.length - 1;
      // Join up the last array and get its length
      const currLen = acc[currIndex].join(' ').length;
      // If the length of that content and the new word
      // in the iteration exceeds 20 chars push the new
      // word to a new array
      if (currLen + c.length > length) {
        acc.push([c]);
        // otherwise add it to the existing array
      } else {
        acc[currIndex].push(c);
      }
      return acc;
    },
    [[]]
  );
  // Join up all the nested arrays
  // return temp.map((arr) => arr.join(' '));
  return temp.map((arr: []) => arr.join(' '));
};

export const clearCache = (): void => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => caches.delete(name));
    });
  }

  window.location.reload();
};

export const tracker = new Tracker({
  projectKey: 'n7aGyDDhvtbsqDp6E1Zu',
  __DISABLE_SECURE_MODE: true,
  // ingestPoint: 'https://rum-server.jubelio.com/ingest',
});

function extractAndSortIds<T>(array: T[], idKey: keyof T): number[] {
  return array.map((obj) => obj[idKey] as unknown as number).sort((a, b) => a - b);
}

export function arraysHaveSameIds<T>(arr1: T[], arr2: T[], idKey: keyof T): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedIds1 = extractAndSortIds(arr1, idKey);
  const sortedIds2 = extractAndSortIds(arr2, idKey);
  return sortedIds1.every((id, index) => id === sortedIds2[index]);
}
