import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Image, SimpleGrid } from '@chakra-ui/react';
import clsx from 'clsx';
import { Modal } from 'components/modal';
import React from 'react';
import { IDetailPrice, Thumbnail } from 'types/products.types';

interface Props {
  data: IDetailPrice;
  isOpen: boolean;
  onClose: () => void;
}

const ModalImage: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, data, ...rest } = props;
  const rowRef = React.useRef<HTMLDivElement>(null);
  const [toggle, setToggle] = React.useState(0);

  const handleClick = (direction: string) => {
    const totalLength = data.thumbnail;
    if (direction === 'prev') {
      if (toggle === 0) {
        setToggle(0);
      } else {
        setToggle((state) => state - 1);
      }
    }

    if (direction === 'next') {
      if (toggle >= totalLength.length - 1) {
        setToggle(totalLength.length - 1);
      } else {
        setToggle((state) => state + 1);
      }
    }
  };

  return (
    <Modal isShow={isOpen} onClose={onClose} {...rest} title={data.item_name} className='max-w-4xl'>
      <Modal.Body className='flex p-10'>
        <div className='w-1/2'>
          <div className='group relative flex items-center'>
            <div
              className={clsx(
                'border-shade/200 mr-10 cursor-pointer rounded-full  border p-2 shadow-lg',
                toggle === 0 && 'disabled'
              )}
              onClick={() => handleClick('prev')}
            >
              <ChevronLeftIcon w={6} h={6} />
            </div>
            <div className='flex items-center overflow-x-auto scrollbar-hide' ref={rowRef}>
              {data &&
                data.thumbnail.map(
                  (val: Thumbnail, index: number) =>
                    toggle === index && (
                      <img
                        src={val.thumbnail}
                        alt=''
                        key={index}
                        className='h-[230px]'
                        id={`img-${val.sequence_number}`}
                      />
                    )
                )}
            </div>
            <div
              className='border-shade/200 P-8 ml-10 cursor-pointer rounded-full border p-2 shadow-lg'
              onClick={() => handleClick('next')}
            >
              <ChevronRightIcon w={6} h={6} />
            </div>
          </div>
        </div>
        <div className='ml-4'>
          <SimpleGrid columns={{ base: 5, sm: 2, md: 3, lg: 4, xl: 5 }} gap={2}>
            {data.thumbnail?.length > 0 &&
              data.thumbnail?.map((item: Thumbnail, index: number) => (
                <Image
                  src={item.thumbnail}
                  key={index}
                  boxSize='89px'
                  borderRadius='4px'
                  border='2px'
                  borderColor={toggle === index ? 'red' : 'transparent'}
                  onClick={() => setToggle(index)}
                  _hover={{ cursor: 'pointer' }}
                />
              ))}
          </SimpleGrid>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalImage;
