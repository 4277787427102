import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import RegisterIcon from 'assets/svg/RegisterIcon.svg';
import Bowser from 'bowser';
import { CardInfo, CardLoadingMain, CardMain, CardRegister, CardSkeleton } from 'components/card';
import { TagIcon } from 'components/icons';
import MainLayout from 'components/layout/MainLayout';
import { ModalAlert, ModalAnnouncement, ModalInitCash } from 'components/modal';
import ModalDeviceCode from 'components/modal/ModalDeviceCode';
import { EmptySearch, PosDisabled } from 'components/state';
import { Title } from 'components/typography';
import { CardProfile, ListLocation } from 'components/ui/register';
import config from 'constant';
import { alert } from 'constant/messages';
import {
  useGetCurrentLocaion,
  useGetLocations,
  useGetPosSetting,
  useGetRegisters,
  useNotification,
  useOnlineStatus,
  useOpenRegister,
} from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDownloadManager from 'hooks/useDownloadManager';
import useInitChat from 'hooks/useInitChat';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  setAsCashier,
  setAsDeviceChecker,
  setAskInitialCash,
  setCurrentClosure,
  setInitialCash,
  setInitSync,
  setLastSync,
  setRegisterInfo,
} from 'redux/reducer/registers';
import { setFreshchatCustomConfig } from 'redux/reducer/settings';
import announcementRequest from 'services/http/announcement.request';
import closureRequest from 'services/http/closure.request';
import { ClosureRequest } from 'types/closure.types';
import { IAnnouncement } from 'types/common.types';
import { PickBankNote, RegisterFragment } from 'types/register.types';
import { customDateFormat } from 'utils';

import packageJson from '../../../package.json';

type IOpenClosure = {
  registerInfo: RegisterFragment;
  email: string;
  cash: number;
  listBanks?: PickBankNote[] | null;
  code?: string | null;
};

const SalesRegister = () => {
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [register, setRegister] = React.useState<RegisterFragment>({} as RegisterFragment);
  const [isShowConfirm, setIsShowConfirm] = React.useState<boolean>(false);
  const [announcement, setAnnouncement] = React.useState<IAnnouncement>();
  const [loadingRegister, setLoadingRegister] = React.useState<boolean>(false);
  const [loadingOpenRegister, setLoadingOpenRegister] = React.useState<boolean>(false);

  // ** Redux Store
  const profile = useAppSelector((state) => state.auth.profile);
  const freshchatCustomConfig = useAppSelector((state) => state.commons.freshchatCustomConfig);
  const isLoadingUser = useAppSelector((state) => state.auth.isLoadingUser);
  const isPriceChecker = useAppSelector((state) => state.register.isPriceChecker);
  const posSetting = useAppSelector((state) => state.commons.settings);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);

  // ** Custom Hooks
  const { notification } = useNotification();
  const { getPosSetting } = useGetPosSetting();
  const { listRegister, getRegisters, isLoading: isLoadingGetRegister } = useGetRegisters();
  const { registerAsPriceChecker, registerAsCashier } = useOpenRegister();
  const { currentLocation } = useGetCurrentLocaion();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOnline } = useOnlineStatus();
  const { initChat } = useInitChat();

  const { locations, handleSetLocation } = useGetLocations();
  const { startDownloadCommonData } = useDownloadManager();

  const mutateOpenClosure = useMutation({
    mutationKey: ['open-cashier'],
    mutationFn: closureRequest.openClosure,
  });

  const {
    isOpen: isOpenAnnouncement,
    onOpen: onOpenAnnouncement,
    onClose: onCloseAnnouncement,
  } = useDisclosure();

  const { onOpen: onOpenCodeDevice, onClose: onCloseCodeDevice, isOpen: isOpenCodeDevice } = useDisclosure();

  // select register and show modal
  const chooseRegister = (registerInfo: RegisterFragment) => {
    setLoadingRegister(true);
    setRegister(registerInfo);
    if (
      !profile?.acls.includes(config.ACL_SALES_ALL) &&
      !profile?.isOwner &&
      profile?.email !== config.SUPPORT_EMAIL &&
      profile?.email !== config.SUPPORT_EMAIL_WMS &&
      profile?.email !== config.SUPPORT_EMAIL_V2
    ) {
      return notification(alert.not_permite_sale_title, alert.not_permite_sale_text, 'warning', 5000);
    }

    if (!profile?.closures?.closing_date && profile?.closures?.register_id !== registerInfo.register_id) {
      setLoadingRegister(false);
      setIsShowConfirm(true);
    } else {
      confirmRegisterSelected(registerInfo);
    }
  };

  // confirm to select register
  // and show modal intial cash register
  const confirmRegisterSelected = (registerInfo: RegisterFragment) => {
    const deviceId = localStorage.getItem('device_id');
    const isRegisterClosedAndCashNotSet =
      posSetting?.opening_cash && registerInfo.cash_inital === null && registerInfo.status === 'closed';

    const isDeviceMismatch = registerInfo.device_info && registerInfo.device_info.device_id !== deviceId;

    if (isRegisterClosedAndCashNotSet) {
      dispatch(setAskInitialCash(true));
      setLoadingRegister(false);
      onOpen();
    } else if (isDeviceMismatch) {
      setLoadingRegister(false);
      onOpenCodeDevice();
    } else {
      dispatch(setInitialCash(registerInfo.cash_inital ?? 0));
      chooseClosure(registerInfo, registerInfo.cash_inital, [], registerInfo.device_info?.code ?? '');
    }
  };

  // open cash register and send closure data
  const openClosure = async ({
    registerInfo,
    email,
    cash = 0,
    listBanks = [],
    code = null,
  }: IOpenClosure) => {
    const deviceId = localStorage.getItem('device_id');
    const browser = Bowser.getParser(window.navigator.userAgent);

    const payload: ClosureRequest = {
      location_id: registerInfo.location_id,
      register_id: registerInfo.register_id,
      user_name: email,
      date_opened: customDateFormat(),
      cash_initial: cash,
      banknotes: listBanks
        ?.filter((val) => val.banknote_count && val.banknote_count !== 0)
        .map((val) => ({ banknote_id: val.banknote_id, banknote_count: val.banknote_count })),
      device_info: {
        device_id: deviceId ?? '',
        device_type: 'WEB',
        device_name: browser.getOSName().concat(' - ', packageJson.version, ' - ', browser.getBrowserName()),
        code,
      },
    };

    if ((registerInfo.status === 'open' && !registerInfo.device_info) || registerInfo.status === 'closed') {
      delete payload.device_info?.code;
    }

    dispatch(setAsCashier(true));

    mutateOpenClosure.mutate(payload, {
      onSuccess: (response) => {
        dispatch(
          setCurrentClosure({
            closure_id: response.closure_id,
            register_id: response.register_id,
            closing_date: response.closing_date,
            open_date: response.open_date,
            cash_initial: response.cash_initial,
            username: response.username,
            device_info: response.device_info,
          })
        );
        setTimeout(async () => {
          navigate('/sales');
          setLoadingOpenRegister(false);
          dispatch(setInitSync(false));
          startDownloadCommonData();
        }, 1000);
      },
      onError: () => {
        setIsShowConfirm(false);
        setLoadingOpenRegister(false);
        dispatch(setInitSync(false));
        startDownloadCommonData();
      },
    });
  };

  // select closure and get important data
  // get items, payments_method, discounts and etc
  const chooseClosure = async (
    registerInfo: RegisterFragment,
    cash = 0,
    listBanks?: PickBankNote[],
    code?: string
  ) => {
    console.log('[INFO] Start opening cash register');
    const userEmail = profile?.user.email ?? '';
    if (registerInfo.register_id !== currentClosure?.register_id) await dispatch(setLastSync(null));

    dispatch(setRegisterInfo(registerInfo));
    await openClosure({ registerInfo, email: userEmail, cash, listBanks, code });
  };

  // checking user has default location id
  const checkDefaultLocation = React.useCallback(() => {
    if (Array.isArray(locations) && currentLocation && currentLocation !== config.ALL_LOCATION) {
      const filterLocation = locations.filter((location) => location.location_id === currentLocation);
      handleSetLocation(filterLocation?.[0]);
    }

    const location = locations.find((location) => location.location_id === currentLocation);
    if (location) {
      handleSetLocation(location);
    }
  }, [currentLocation, locations, handleSetLocation]);

  // run checking default location and get locations
  // when page opened
  React.useEffect(() => {
    getPosSetting();
    checkDefaultLocation();
  }, [profile]);

  React.useEffect(() => {
    if (isOnline) {
      if ((window.fcWidget !== undefined && !window.fcWidget.isInitialized()) || !freshchatCustomConfig) {
        window.fcWidget.destroy();
        setTimeout(() => {
          initChat(
            true,
            false,
            profile?.email ?? '',
            profile?.companies[0].company_name ?? '',
            profile?.full_name ?? '',
            profile?.companies[0].company_id as number,
            profile?.restore_id ? profile?.restore_id : null
          );

          dispatch(setFreshchatCustomConfig(true));
        }, 300);
      }
    }
  }, [profile, isOnline]);

  // prevent to button back broweser
  React.useEffect(() => {
    if (isPriceChecker) registerAsPriceChecker();
  }, [registerAsPriceChecker]);

  const getAnnouncement = async () => {
    try {
      const res = await announcementRequest.getAnnouncement();
      setAnnouncement(res);
      if (res) {
        onOpenAnnouncement();
      }
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      notification('', message, 'warning', 5000);
    }
  };

  const closeAnnouncement = async (id: number) => {
    try {
      await announcementRequest.setShowAnnouncement(id);
      onCloseAnnouncement();
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      notification('', message, 'warning', 5000);
    }
  };

  const closeConfirmation = () => {
    setLoadingRegister(false);
    setIsShowConfirm(false);
  };

  const closeInitCash = () => {
    setLoadingRegister(false);
    onClose();
  };

  const onSubmitDeviceCode = async (code: string) => {
    dispatch(setInitialCash(register.cash_inital ?? 0));
    chooseClosure(register, register.cash_inital, [], code);
  };

  const closePopupDeviceCode = () => {
    onCloseCodeDevice();
    setLoadingRegister(false);
  };

  React.useEffect(() => {
    registerAsCashier();
  }, [registerAsCashier]);

  React.useEffect(() => {
    getAnnouncement();
  }, []);

  return (
    <MainLayout>
      <CardMain h='calc(100vh - 90px)'>
        {posSetting.pos_enabled === false && <PosDisabled text={alert.not_integrated_title} />}
        {!profile?.acls.includes(config.ACL_SALES_ALL) &&
          !profile?.acls.includes(config.ACL_SALES_PRICE_CHECKER) &&
          !profile?.isOwner && <PosDisabled text={alert.not_permission} />}
        <Box borderRight='1px' borderColor='gray.100' w='25%'>
          <Box p={4}>
            <Title>Lokasi Mesin Kasir</Title>
          </Box>
          <Box p='0.80rem'>
            <ListLocation onSelectLocation={(locationId) => getRegisters(locationId)} />
          </Box>
        </Box>
        <Box w={['60%', '40%', '45%', '60%', '60%']} position='relative'>
          <div className='flex items-center justify-between p-3'>
            <Title>Daftar Mesin Kasir</Title>
            <Button
              variant='outline'
              size='sm'
              px={7}
              borderRadius='8px'
              color='jubelio.black'
              leftIcon={<TagIcon h='20px' w='20px' />}
              onClick={() => {
                navigate('/price-checker');
                dispatch(setAsDeviceChecker(true));
              }}
              isDisabled={!profile?.acls.includes(config.ACL_SALES_PRICE_CHECKER)}
            >
              Price Checker
            </Button>
          </div>
          {!isLoadingGetRegister && listRegister.registers?.length === 0 && (
            <div className='m-20'>
              <EmptySearch
                icons={RegisterIcon}
                title='Belum Ada Kasir'
                subtitle='Belum ada kasir yang tersedia di lokasi ini'
              />
            </div>
          )}
          <div className='grid max-h-[calc(100vh_-_155px)] grid-cols-1 gap-2 overflow-y-auto p-1  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>
            {!isLoadingGetRegister &&
              listRegister.registers?.map((cashRegister: RegisterFragment) => (
                <CardRegister
                  register={cashRegister}
                  key={cashRegister.register_id}
                  onClick={(value) => chooseRegister(value as RegisterFragment)}
                  selectedRegister={register}
                  loadingRegister={loadingRegister}
                />
              ))}
            {/* {!isLoadingGetRegister && listRegister.registers?.length === 0 && <CashRegisterRound />} */}
            {isLoadingGetRegister && [1, 2, 3, 4, 5].map((index) => <CardSkeleton key={index} />)}
          </div>
        </Box>
        <Box borderLeft='1px' borderColor='gray.100' w='30%'>
          <Box p={4} h='4rem'>
            <Title>Aktivitas Terakhir</Title>
          </Box>
          <Box p='0.80rem'>
            <CardProfile profile={profile} />
            {profile?.closures && <CardInfo mt={4} user={profile} isLoading={isLoadingUser} />}
          </Box>
        </Box>
      </CardMain>
      {loadingOpenRegister && <CardLoadingMain h='calc(100vh - 90px)'></CardLoadingMain>}
      <ModalInitCash
        isOpen={isOpen}
        onClose={() => closeInitCash()}
        onSave={(initialCash, listBanks) => chooseClosure(register, initialCash, listBanks)}
        register={register}
      />
      <ModalAlert
        isShow={isShowConfirm}
        onClose={() => closeConfirmation()}
        cancelRef={cancelRef}
        isLoading={mutateOpenClosure.isPending || loadingOpenRegister}
        openInitCash={() => confirmRegisterSelected(register)}
      />
      <ModalAnnouncement
        isOpen={isOpenAnnouncement}
        onClose={onCloseAnnouncement}
        announcement={announcement}
        onSubmit={closeAnnouncement}
      />
      <ModalDeviceCode
        isOpen={isOpenCodeDevice}
        isLoading={loadingOpenRegister || mutateOpenClosure.isPending}
        register={register}
        onClose={closePopupDeviceCode}
        onSubmit={onSubmitDeviceCode}
      />
    </MainLayout>
  );
};

export default SalesRegister;
