import { AddIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Skeleton, Stack } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import NotfoundIcon from 'assets/svg/Notfound.svg';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import { CircleIcon } from 'components/icons';
import { EmptySearch } from 'components/state';
import { SubTitle, Title } from 'components/typography';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification, usePromotions } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import {
  applyPriceBook,
  applySlashPrice,
  selectCustomer,
  setContactToOrder,
  setIsContact,
} from 'redux/reducer/sales';
import commonRequest from 'services/http/common.request';
import customers from 'services/indexdb/customers';
import { ICustomerInfo } from 'types/common.types';
import { getPagination } from 'utils';
import { debounce } from 'utils';

import { Modal } from '.';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  openAddCustomer: () => void;
  chooseReturnCustomer?: (customer: ICustomerInfo) => void;
}

const ModalListCustomer: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, title, openAddCustomer, chooseReturnCustomer } = props;
  const dispatch = useAppDispatch();
  const { returnMode } = useAppSelector((state) => state.return);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const isOnlineStore = useAppSelector((state) => state.register.isOnline);

  const { notification } = useNotification();
  const { getPriceBookListByCustomer, getSlashPriceCartOnline, getSlashPrice } = usePromotions();

  const [filter, setFilter] = React.useState({
    page: 1,
    pageSize: 15,
    search: '',
    cursor: 0,
  });

  const mutateContact = useMutation({
    mutationKey: ['contact-detail'],
    mutationFn: commonRequest.contactDetail,
    retry: true,
  });

  const { data: onlineCustomer, isLoading } = useQuery({
    queryKey: ['contacts', { pageSize: filter.pageSize, q: filter.search, cursor: filter.cursor }],
    queryFn: commonRequest.getContacts,
    select: (data) => data,
    enabled: !!isOpen && !!isOnlineStore,
  });

  const listCustomer = useLiveQuery<ICustomerInfo[]>(() => {
    if (isOpen && !isOnlineStore) {
      if (filter.search !== '') {
        return customers.filter(filter.search);
      } else {
        return customers.get();
      }
    }

    return [];
  }, [filter.search, isOnlineStore, isOpen]);

  const customersList = React.useMemo(() => {
    if (isOnlineStore) {
      return onlineCustomer?.data;
    }

    return getPagination(listCustomer || [], filter.page, filter.pageSize);
  }, [isOnlineStore, listCustomer, onlineCustomer, filter]);

  const chooseCustomer = async (customer: ICustomerInfo) => {
    let customerStoreCredit = '0';
    try {
      onClose();
      if (isOnlineStore) {
        dispatch(setIsContact(true));
        await mutateContact
          .mutateAsync(customer.contact_id)
          .then((response: any) => {
            customerStoreCredit = response.store_credit ?? '0';
            dispatch(setIsContact(false));
          })
          .catch(() => setIsContact(false));
      }

      const customerInfo: ICustomerInfo = {
        ...customer,
        store_credit: customerStoreCredit,
      };
      dispatch(selectCustomer(customerInfo));
      dispatch(setContactToOrder(customerInfo));

      if (isOnlineStore) {
        getSlashPriceCartOnline(listItemCart, customerInfo).then((result) => {
          dispatch(applySlashPrice(result.discount));
          dispatch(applyPriceBook(result.pricebook));
        });
      } else {
        getSlashPrice(listItemCart, customerInfo).then((result) => {
          dispatch(applySlashPrice(result));
        });

        getPriceBookListByCustomer(listItemCart, customerInfo).then((result) => {
          dispatch(applyPriceBook(result));
        });
      }
    } catch (error) {
      notification('', 'Gagal mengambil store kredit.', 'error');
    } finally {
      searchCustomer('');
    }
  };

  const searchCustomer = debounce(async (keyword) => {
    setFilter({
      ...filter,
      search: keyword,
      page: isOnlineStore ? 1 : filter.page,
      pageSize: isOnlineStore ? 15 : filter.pageSize,
      cursor: isOnlineStore ? 0 : filter.cursor,
    });
  }, 500);

  const handleClose = () => {
    onClose();
    setFilter({
      page: 1,
      pageSize: 15,
      search: '',
      cursor: 0,
    });
  };

  return (
    <Modal isShow={isOpen} onClose={handleClose} title={title} className='max-w-xl'>
      <Modal.Body>
        <HStack justify='space-between' mb='16px'>
          <SearchBar placeholder='Cari pelanggan' onSearch={(e) => searchCustomer(e.target.value)} />
          <IconButton aria-label='add-icon' icon={<AddIcon />} variant='primary' onClick={openAddCustomer} />
        </HStack>
        <Box minH='300px' h='full' overflowY='auto'>
          {isLoading && (
            <Stack w='full'>
              <Skeleton isLoaded={false} height='50px'></Skeleton>
              <Skeleton isLoaded={false} height='50px'></Skeleton>
            </Stack>
          )}
          {!isLoading && customersList && customersList.length === 0 && (
            <EmptySearch icons={NotfoundIcon} title='Yah!' subtitle='Customer tidak ditemukan' />
          )}
          {!isLoading && customersList && (
            <Box overflowY='auto' maxH='300px'>
              {customersList.map((item: ICustomerInfo, key: number) => (
                <Box
                  key={key}
                  borderBottom='1px'
                  borderColor='gray.200'
                  minH='42px'
                  py={2}
                  px={2}
                  _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
                  onClick={() => {
                    if (returnMode) {
                      chooseReturnCustomer && chooseReturnCustomer(item);
                    } else {
                      chooseCustomer(item);
                    }
                  }}
                >
                  <Title fontSize='14px'>{item.contact_name}</Title>
                  <SubTitle>
                    {item.email ?? 'Email belum diset'} <CircleIcon h='5px' color='jubelio.black' />
                    {item.phone ?? 'Phone belum diset'} <CircleIcon h='5px' color='jubelio.black' />
                    {item.category_name ?? 'Pelanggan Umum'}
                  </SubTitle>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal.Body>
      <Box boxShadow='0px -4px 6px -1px rgba(23, 80, 22, 0.06)' p={4}>
        <Pagination
          page={filter.page}
          pageRange={2}
          pageSize={filter.pageSize}
          withTotal={false}
          onChangeRow={(e) => setFilter({ ...filter, pageSize: e })}
          hasNext={onlineCustomer?.has_more}
          hasPrev={onlineCustomer?.has_previous}
          useCursor={isOnlineStore ? true : false}
          totalPage={isOnlineStore ? onlineCustomer?.totalCount : listCustomer?.length}
          onChangePage={(e) => setFilter({ ...filter, page: e.selected + 1 })}
          onNextCursor={() =>
            setFilter({
              ...filter,
              cursor: onlineCustomer?.has_more ? (onlineCustomer?.next_cursor as number) : 0,
            })
          }
          onPrevCursor={() =>
            setFilter({
              ...filter,
              cursor: onlineCustomer?.has_previous ? (onlineCustomer?.prev_cursor as number) : 0,
            })
          }
        />
      </Box>
    </Modal>
  );
};

export default ModalListCustomer;
