import axios from 'axios';

const API_NOTIFICATION = 'https://pos-log.staging-k8s.jubelio.com';

const logInstance = axios.create({
  baseURL: API_NOTIFICATION,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  sendNotif: async ({
    errorPayload,
    registerName,
    locationName,
    email,
    action,
    payload,
    version,
    platform,
  }: {
    errorPayload: any;
    registerName: string;
    locationName: string;
    email: string;
    action: string;
    payload?: any;
    version: string;
    platform: string;
  }): Promise<any> => {
    let message = '';

    if (errorPayload.response) {
      message = errorPayload?.response?.data.message ?? errorPayload?.response?.data.code;
    } else {
      message = errorPayload;
    }
    return logInstance.post('/logs/send', {
      message,
      payload: errorPayload?.response?.config.data ?? JSON.stringify(payload),
      url: errorPayload?.response?.config.url ?? action,
      status: errorPayload?.response?.status ?? 200,
      email: email,
      registerName: registerName ?? '',
      location_name: locationName ?? '',
      action,
      version,
      platform,
    });
  },
};
