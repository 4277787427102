import { Box, StackProps, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { Maybe } from 'types';
import { IPaymentDetail, IPaymentTrx } from 'types/closure.types';
import { currencyFormat } from 'utils';

interface TablePaymentDetailState extends StackProps {
  dataPayment: Maybe<IPaymentTrx> | undefined;
}

const TablePaymentDetail: React.FC<TablePaymentDetailState> = (props: TablePaymentDetailState) => {
  const { dataPayment } = props;

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'No Transaksi',
        isNumeric: false,
      },
      {
        title: 'Total Transaksi',
        isNumeric: true,
      },
    ],
    []
  );

  return (
    <Box
      bg='white'
      rounded='md'
      border='1px'
      borderColor='#ECEFF1'
      w='full'
      overflowX='auto'
      overflowY='auto'
      maxHeight='320px'
      mt='10px'
    >
      <Table size='md' variant='simple'>
        <Thead position='sticky' top={0}>
          <Tr>
            {tableHeader.map((theader, index) => (
              <Th
                key={`table-header-${index}`}
                borderRightColor='gray.200'
                borderRightWidth='1px'
                bgColor='jubelio.grey100'
                isNumeric={theader.isNumeric}
                fontSize='12px'
              >
                <span>{theader.title}</span>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {dataPayment?.listTrx.map((payment: IPaymentDetail, index: number) => (
            <Tr key={index}>
              <Td borderRightColor='gray.200' borderRightWidth='1px' fontSize='14px'>
                {!payment.trx_retur ? payment?.salesorder_no : payment?.pos_return_no}
              </Td>
              <Td borderRightColor='gray.200' borderRightWidth='1px' isNumeric fontSize='14px'>
                {payment?.transaction_type === 'Penjualan'
                  ? `${currencyFormat(payment?.payment_amount)}`
                  : `${currencyFormat(Math.abs(payment?.payment_amount), '-Rp')}`}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TablePaymentDetail;
