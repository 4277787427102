/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import ReactSelect, { components, ControlProps, Props } from 'react-select';
import customTheme from 'theme/select-theme';

const Control = ({ children, ...props }: ControlProps) => {
  return <components.Control {...props}>{children}</components.Control>;
};

const Select = (props: Props) => {
  return (
    <ReactSelect
      {...props}
      components={{ Control, IndicatorSeparator: () => null }}
      styles={customTheme}
    />
  );
};

export default Select;
