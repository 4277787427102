import { CloseIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';
import React from 'react';

type ButtonSelectProps = ButtonProps & {
  isSelected: boolean;
  isContact: boolean;
};

const ButtonSelectCustomer: React.FC<ButtonSelectProps> = ({
  children,
  isSelected,
  isContact,
  ...props
}: ButtonSelectProps) => {
  return (
    <Button
      variant='outline'
      justifyContent='space-between'
      color='jubelio.black'
      fontWeight='500'
      fontSize={['12px', '12px', '12px', '13px', '14px']}
      rightIcon={
        isContact ? (
          <Spinner size='xs' />
        ) : isSelected ? (
          <CloseIcon h='10px' color='jubelio.dark' />
        ) : (
          <TriangleDownIcon h='10px' color='jubelio.dark' />
        )
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonSelectCustomer;
