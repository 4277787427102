import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/input';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { BarcodeIcon } from 'components/icons';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setUseBarcode } from 'redux/reducer/settings';
interface SearchBarProps extends InputGroupProps {
  placeholder: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  onEnter?: (e: string) => void;
  onSwitchBarcodeMode?: (e: string) => void;
  disableEnter?: boolean;
  value?: string;
  disabled?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  const {
    placeholder,
    onSearch,
    readOnly,
    onEnter,
    disableEnter,
    value,
    onSwitchBarcodeMode,
    disabled,
    ...rest
  } = props;
  const [defaultValue, setDefaultValue] = React.useState<string>(value as string);
  const searchRef = React.useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const useBarcode = useAppSelector((state) => state.commons.useBarcode);

  const handleEnter = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!disableEnter && value !== '') {
      if (event.key === 'Enter') {
        if (onEnter) onEnter(defaultValue);
        setDefaultValue('');
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!useBarcode) {
      onSearch(e);
      setDefaultValue(e.target.value);
    } else {
      setDefaultValue(e.target.value);
    }
  };

  React.useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
    if (onSwitchBarcodeMode && !useBarcode) onSwitchBarcodeMode(defaultValue);
  }, [useBarcode]);

  const clickButton = (isBarcode: boolean) => {
    if (isBarcode && onSwitchBarcodeMode) onSwitchBarcodeMode('');
    dispatch(setUseBarcode(isBarcode));
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  return (
    <InputGroup variant='outline' {...rest}>
      <InputLeftElement width='12rem'>
        <ButtonGroup isAttached size='sm' bgColor='white' borderRadius='8px'>
          <Button
            leftIcon={<BarcodeIcon h='20px' w='20px' />}
            variant={useBarcode ? 'primary' : 'outline'}
            fontSize='14px'
            px='1rem'
            onClick={() => clickButton(true)}
            borderRadius='8px'
          >
            Scan
          </Button>
          <Button
            leftIcon={<SearchIcon h='15px' width='15px' />}
            variant={!useBarcode ? 'primary' : 'outline'}
            fontSize='14px'
            px='1rem'
            onClick={() => clickButton(false)}
            borderRadius='8px'
          >
            Cari
          </Button>
        </ButtonGroup>
      </InputLeftElement>
      <Input
        ref={searchRef}
        placeholder={placeholder}
        onChange={handleInputChange}
        readOnly={readOnly}
        onKeyDown={handleEnter}
        value={defaultValue}
        variant='outline'
        bgColor='gray.100'
        rounded='4px'
        autoFocus
        disabled={disabled}
        pl='12rem'
      />
    </InputGroup>
  );
};

export default SearchBar;
