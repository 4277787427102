import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, FlexProps, HStack, IconButton, Text } from '@chakra-ui/react';
import { PAYMENT_TYPE } from 'constant';
import React from 'react';
import { IPayments } from 'types/common.types';
import { currencyFormat } from 'utils';

interface ListPaymentsProps extends FlexProps {
  payments: IPayments;
  onDelete?: () => void;
}

const ListPayments: React.FC<ListPaymentsProps> = ({ payments, onDelete }: ListPaymentsProps) => {
  const getPaymentType = React.useMemo(() => {
    switch (payments.payment_type) {
      case PAYMENT_TYPE.DEBIT:
        return 'Debit';
      case PAYMENT_TYPE.CREDIT:
        return 'Kredit';
      default:
        return '';
    }
  }, [payments]);

  return (
    <Flex borderBottom='1px' borderColor='system.outline' pb='5px' alignItems='center'>
      <Box width='full'>
        <Text fontSize='13px'>
          {getPaymentType} {payments.payment_name}
        </Text>
        <Text fontSize='13px' isTruncated maxW='5rem'>
          {payments.no_ref}
        </Text>
      </Box>
      <HStack w='full'>
        <Text fontWeight='bold' color='jubelio.black' fontSize='14px' ml={4} w='full'>
          {currencyFormat(Math.abs(Number(payments?.payment_amount)))}
        </Text>
        {payments.payment_id === -1 && payments.is_dp === true && (
          <Text ml='auto' fontSize='13px' w='full'>
            {`( ${currencyFormat(Math.abs(Number(payments?.payment_charge)))} )`}
          </Text>
        )}
      </HStack>
      <Text ml='auto' fontSize='13px' w='full'>
        {payments.notes}
      </Text>
      <IconButton
        ml='auto'
        aria-label='delete'
        variant='ghost'
        icon={<DeleteIcon color='red.500' />}
        onClick={() => onDelete && onDelete()}
      />
    </Flex>
  );
};

export default ListPayments;
