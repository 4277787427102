import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { CheckCircleIcon } from 'components/icons';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

interface ModalSyncProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  text: string;
  showButton: boolean;
  textButton?: string;
  onClickButton?: () => void;
}

const ModalNotif: React.FC<ModalSyncProps> = (props: ModalSyncProps) => {
  const { isOpen, onClose, title, text, showButton, textButton, onClickButton } = props;
  const [counter, setCounter] = React.useState<number>(3);

  React.useEffect(() => {
    if (isOpen) {
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer as NodeJS.Timeout);
    } else {
      setCounter(3);
    }
  }, [counter, isOpen]);

  React.useEffect(() => {
    if (counter === 0) {
      onClose();
    }
  }, [counter]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton bg='gray.100' rounded='full' />
        <ModalBody py={10}>
          <Stack direction={['column']}>
            <Box textAlign='center'>
              <CheckCircleIcon h='46px' w='46px' />
              <Title mt={4}>{title}</Title>
              <SubTitle>{text}</SubTitle>
              {showButton && (
                <Button onClick={onClickButton} variant='outline' mt={15}>
                  {textButton}
                </Button>
              )}
            </Box>
          </Stack>
          <SubTitle textAlign='center' mt={8} fontSize='12px'>
            Popup ini akan tertutup dalam {counter} detik
          </SubTitle>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalNotif;
