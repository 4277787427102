import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { selectLocation, setRegisterInfo } from 'redux/reducer/registers';
import registerRequest from 'services/http/register.request';
import { ICashRegister } from 'types/register.types';

import { useAppDispatch, useAppSelector } from './redux';

type GetRegisterProps = {
  listRegister: ICashRegister;
  getRegisters: (locationId: number) => void;
  isLoading: boolean;
};

const useGetRegisters = (): GetRegisterProps => {
  const profile = useAppSelector((state) => state.auth.profile);

  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [locationId, setLocationId] = React.useState<number>(0);
  const { data: locationData } = useQuery({
    queryKey: ['register-list', locationId],
    queryFn: registerRequest.getLocationById,
    enabled: !!locationId,
  });

  const [listRegister, setListRegister] = React.useState<ICashRegister>([] as unknown as ICashRegister);

  const getRegisters = async (locationId: number) => {
    try {
      dispatch(setRegisterInfo(null));
      setLocationId(locationId);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    if (locationData) {
      setLoading(false);
      setListRegister({ registers: locationData?.registers || [] });
      dispatch(selectLocation(locationData));
      return;
    }
  }, [profile, locationId, locationData]);

  return { listRegister, getRegisters, isLoading };
};

export default useGetRegisters;
