import { Divider } from 'components/utils';
import { PAYMENT } from 'constant';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { ILocations } from 'types/register.types';
import { convertToLocalDate, currencyFormat, currencyFormatNotFraction, getPaymentsName } from 'utils';

import Header from './Header';
import Rows from './Rows';

export const PrintClosure: React.FC = () => {
  const { structSetting } = useAppSelector((state) => state.commons);
  const { listPayments, listBanksNote, listItemSold, notes } = useAppSelector((state) => state.closure);
  const { location, currentClosure: closure, registerInfo } = useAppSelector((state) => state.register);
  const { profile } = useAppSelector((state) => state.auth);
  const closeBankNote = React.useMemo(() => {
    return listBanksNote && listBanksNote.length > 0
      ? listBanksNote.filter((bank) => bank.closure_type === 'CLOSED' || !bank.closure_type)
      : [];
  }, [listBanksNote]);

  return (
    <div className='print m-auto mb-10 w-[264px] px-[12px]'>
      <Header structSetting={structSetting} location={location as ILocations} />
      <Divider />
      <div className='text-center'>Penutupan Kasir</div>
      <Divider />

      <Rows leftText={`No Penutupan: ${closure?.closure_id}`} />
      <Rows leftText={`Kasir: ${registerInfo ? profile?.user.full_name : closure?.username}`} />
      <Rows leftText={`Jam Buka: ${convertToLocalDate(closure?.open_date as Date)}`} />
      <Rows leftText={`Jam Tutup: ${convertToLocalDate(closure?.closing_date as Date)}`} />
      <Divider />
      <div className='text-center'>Pembayaran</div>
      <Divider />
      {listPayments &&
        listPayments?.map((payment, index) => (
          <React.Fragment key={index}>
            <div className='flex font-semibold'>
              <label>{getPaymentsName(payment)}</label>
            </div>
            {payment.payment_id === PAYMENT.CASH && (
              <div className='flex items-center justify-between'>
                <label>-Pembukaan</label>
                <label className='font-semibold'>
                  {structSetting?.show_two_digit_fraction
                    ? currencyFormat(Number(closure?.cash_initial))
                    : currencyFormatNotFraction(Number(closure?.cash_initial))}
                </label>
              </div>
            )}
            <div className='flex items-center justify-between'>
              <label>-Penjualan</label>
              <label className='font-semibold'>
                {structSetting?.show_two_digit_fraction
                  ? currencyFormat(payment.total_sales)
                  : currencyFormatNotFraction(payment.total_sales)}
              </label>
            </div>
            {[PAYMENT.CASH, PAYMENT.STORE_CREDIT].includes(payment.payment_id) && (
              <div className='flex items-center justify-between'>
                <label>-Retur</label>
                <label className='font-semibold'>
                  {structSetting?.show_two_digit_fraction
                    ? currencyFormat(payment.total_retur)
                    : currencyFormatNotFraction(payment.total_retur)}
                </label>
              </div>
            )}
          </React.Fragment>
        ))}
      <Divider />
      {closeBankNote.length > 0 && (
        <React.Fragment>
          <div className='text-center'>Pecahan Uang Tunai</div>
          <Divider />
          {closeBankNote.map(
            (banks, index) =>
              (banks.closure_type === 'CLOSED' || !banks.closure_type) && (
                <Rows
                  leftText={
                    structSetting?.show_two_digit_fraction
                      ? currencyFormat(Number(banks.note_amount))
                      : currencyFormatNotFraction(Number(banks.note_amount))
                  }
                  rightText={Number(banks.banknote_count)}
                  key={index}
                />
              )
          )}
          <Divider />
        </React.Fragment>
      )}
      {listItemSold && listItemSold.length > 0 && (
        <React.Fragment>
          <div className='text-center'>Barang </div>
          <Divider />
          {listItemSold.map((val, index) => (
            <div className='flex flex-col' key={index}>
              <label className='font-semibold'>{val.item_code}</label>
              <label className=''>
                (P) {val.sales_qty} - (R) {val.return_qty} = (T) {val.sales_qty - val.return_qty}
              </label>
            </div>
          ))}
          <Divider />
        </React.Fragment>
      )}
      <div className='text-left'>Catatan: {notes}</div>
      <Divider />
    </div>
  );
};

class ClosureToPrint extends React.Component {
  render(): JSX.Element {
    return <PrintClosure />;
  }
}

export default ClosureToPrint;
