/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SocketContext } from 'context/PosServiceProvider';
import { useContext } from 'react';

const usePosService = () => {
  const socket = useContext(SocketContext);

  return socket;
};

export default usePosService;
