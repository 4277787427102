import {
  Alert,
  AlertIcon,
  Box,
  CloseButton,
  Icon,
  IconButton,
  IconProps,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Bowser from 'bowser';
import {
  BopisIcon,
  CashRegisterIcon,
  ChatIcon,
  ClosureIcon,
  JubelioIcon,
  TransactionIcon,
  WifiActiveIcon,
} from 'components/icons';
import FullscreenIcon from 'components/icons/FullscreenIcon';
import SyncIconDot from 'components/icons/SyncIconDot';
import SideBar from 'components/layout/SideBar';
import { MenuProfile } from 'components/menu';
import ModalConnection from 'components/modal/ModalConnection';
import config from 'constant';
import { useCommon, useGetClosure, useGetLocations, useGetTransaction, useOpenRegister } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useDownloadManager from 'hooks/useDownloadManager';
import useInitChat from 'hooks/useInitChat';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetClosure } from 'redux/reducer/closure';
import {
  setAsCashier,
  setCurrentClosure,
  setInitSync,
  setOnlineStatus,
  setSyncPayload,
} from 'redux/reducer/registers';
import { togglePopupReturn } from 'redux/reducer/return';
import { resetOrder, togglePopup } from 'redux/reducer/sales';
import { toggleFullScreen } from 'utils';

import packageJson from '../../../package.json';

type SalesLayoutProps = {
  children: React.ReactNode;
};

interface SideBarRoute {
  icon: React.ComponentType<IconProps>;
  label: string;
  isActive: (pathname: string) => boolean;
  to: string;
}
const SalesLayout: React.FC<SalesLayoutProps> = ({ children }: SalesLayoutProps) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.auth.profile);
  const loadingSendOrder = useAppSelector((state) => state.sales.loadingSendOrder);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const freshchatCustomConfig = useAppSelector((state) => state.commons.freshchatCustomConfig);
  const isOnlineStore = useAppSelector((state) => state.register.isOnline);
  const isInitSync = useAppSelector((state) => state.register.initSync);
  const currentStepDownload = useAppSelector((state) => state.register.stepDownload);
  const isCashier = useAppSelector((state) => state.register.isCashier);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);

  const { getClosure } = useGetClosure();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLargeScreen] = useMediaQuery('(min-width: 755px)');

  const { initChat } = useInitChat();
  const { backToRegister } = useOpenRegister();
  const { sendFailContact } = useCommon();
  const { sendFailTransaction } = useGetTransaction();
  const { isOpen: isOpenConnection, onOpen: onOpenConnection, onClose: onCloseConnection } = useDisclosure();
  const { startDownloadCommonData, clearIndexDB } = useDownloadManager();
  const { pingRegister } = useGetLocations();

  const handlePingRegister = async () => {
    try {
      const browser = Bowser.getParser(window.navigator.userAgent);
      await pingRegister(registerInfo?.location_id ?? 0, registerInfo?.register_id ?? 0, {
        device_id: registerInfo?.device_info?.device_id ?? localStorage.getItem('device_id') ?? '',
        device_name:
          registerInfo?.device_info?.device_name ??
          browser.getOSName().concat(' - ', packageJson.version, ' - ', browser.getBrowserName()),
        device_type: registerInfo?.device_info?.device_type ?? 'WEB',
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const onClickOpenChatCustomerSupport = async () => {
    if (freshchatCustomConfig)
      if (!isOnlineStore) {
        const successPing = await handlePingRegister();
        if (!successPing) {
          return;
        }
      }
    window.fcWidget.open();
  };

  React.useEffect(() => {
    const initializeFreshchat = () => {
      if (window.fcWidget === undefined || !window.fcWidget.isInitialized() || freshchatCustomConfig) {
        window.fcWidget.destroy();
        const timer = setTimeout(() => {
          initChat(
            true,
            true,
            profile?.email ?? '',
            profile?.companies[0]?.company_name ?? '',
            profile?.full_name ?? '',
            profile?.companies[0]?.company_id ?? 0,
            profile?.restore_id ?? null
          );
        }, 300);
        return () => clearTimeout(timer);
      }
    };

    if (!isOnlineStore) {
      const successPing = handlePingRegister();
      if (!successPing) {
        return;
      }
    }
    if (isOnlineStore && freshchatCustomConfig) {
      initializeFreshchat();
    }
  }, [profile, isOnlineStore, freshchatCustomConfig, initChat]);

  React.useEffect(() => {
    if (location.pathname !== '/sales/checkout' && loadingSendOrder) {
      dispatch(resetOrder());
    }
  }, [location.pathname, loadingSendOrder, dispatch]);

  React.useEffect(() => {
    backToRegister();
  }, [backToRegister]);

  React.useEffect(() => {
    const checkDeviceAndHandleTransactions = async () => {
      const deviceId = localStorage.getItem('device_id');
      if (!currentClosure?.device_info) return;
      if (currentClosure.device_info.device_id !== deviceId) {
        await Promise.all([sendFailTransaction(), sendFailContact()]);
        dispatch(resetOrder());
        dispatch(resetClosure());
        dispatch(setAsCashier(false));
        dispatch(setCurrentClosure(null));
        // navigate('/register');
      }
    };
    if (!isOnlineStore) return;
    checkDeviceAndHandleTransactions();
  }, [currentClosure, isOnlineStore, sendFailTransaction, sendFailContact, dispatch, navigate]);

  const sideBarItems: SideBarRoute[] = React.useMemo(
    () => [
      {
        icon: CashRegisterIcon,
        label: 'Penjualan',
        isActive: (pathname: string) => pathname === '/sales' || pathname === '/sales/checkout',
        to: '/sales',
      },
      {
        icon: ClosureIcon,
        label: 'Penutupan',
        isActive: (pathname: string) => pathname === '/sales/closure',
        to: '/sales/closure',
      },
      {
        icon: TransactionIcon,
        label: 'Transaksi',
        isActive: (pathname: string) => pathname === '/sales/history' || pathname === '/sales/return',
        to: '/sales/history',
      },
      {
        icon: BopisIcon,
        label: 'Marketplace',
        isActive: (pathname: string) => pathname === '/sales/marketplace',
        to: '/sales/marketplace',
      },
    ],
    []
  );

  const handleChangeRoute = (direction: string) => {
    if (loadingSendOrder) return;

    const isCheckout = location.pathname === '/sales/checkout';
    const isReturn = location.pathname === '/sales/return' || location.pathname === '/sales/return-checkout';
    const restrictedDirections = ['/sales/marketplace', '/sales/history', '/sales/closure', '/sales'];

    if (isCheckout && restrictedDirections.includes(direction)) {
      dispatch(togglePopup(true));
    } else if (isReturn && restrictedDirections.includes(direction)) {
      dispatch(togglePopupReturn(true));
    } else {
      navigate(direction);
    }
  };

  const onSwithOfflineMode = () => {
    dispatch(setOnlineStatus(false));
    dispatch(setSyncPayload({ stepDownload: '' }));
  };

  React.useEffect(() => {
    // this is for preventing refresh page when syncing data to server
    // if user refresh page, we will set isInitSync to false and start download commons data
    clearIndexDB(true);
    startDownloadCommonData();
  }, []);

  React.useEffect(() => {
    if (!isInitSync) return;

    window.addEventListener('beforeunload', (event) => {
      event.returnValue = '';
      dispatch(setInitSync(false));
    });

    return () => {
      window.removeEventListener('beforeunload', (event) => {
        event.preventDefault();
      });
    };
  }, [isInitSync]);

  // get last closure
  React.useEffect(() => {
    if (isOnlineStore && isCashier) {
      getClosure();
    }
  }, [location, isCashier, isOnlineStore]);

  return (
    <div className='flex'>
      {isLargeScreen && (
        <SideBar>
          <div className='p-4'>
            <JubelioIcon width='36px' height='36px' />
          </div>
          <div className='relative my-0 flex snap-x flex-col items-center overflow-y-auto scrollbar-hide'>
            <div className='grow-1 my-10 flex h-screen snap-center scroll-mx-2 flex-col items-center justify-center gap-4'>
              {sideBarItems.map((item, index) => {
                if (
                  item.label === 'Marketplace' &&
                  !profile?.isOwner &&
                  profile?.email !== config.SUPPORT_EMAIL &&
                  profile?.email !== config.SUPPORT_EMAIL_WMS &&
                  profile?.email !== config.SUPPORT_EMAIL_V2 &&
                  (!profile?.acls.includes(config.ACL_MARKETPLACE_SALES) ||
                    !profile?.acls.includes(config.ACL_MARKETPLACE_PRINT_LABEL))
                ) {
                  return null;
                }
                return (
                  <div key={index}>
                    <Box
                      bg={item.isActive(location.pathname) ? 'red.100' : ''}
                      p={2}
                      rounded='12px'
                      onClick={() => {
                        if (!isOnlineStore && item.label === 'Marketplace') return;
                        handleChangeRoute(item.to);
                      }}
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      flexDir='column'
                      opacity={!isOnlineStore && item.label === 'Marketplace' ? 0.4 : 1}
                      cursor={!isOnlineStore && item.label === 'Marketplace' ? 'not-allowed' : 'pointer'}
                      _hover={{
                        bg: !isOnlineStore && item.label === 'Marketplace' ? 'none' : 'red.100',
                        cursor: !isOnlineStore && item.label === 'Marketplace' ? 'not-allowed' : 'pointer',
                      }}
                      sx={{
                        '.icon:hover': {
                          fill: !isOnlineStore && item.label === 'Marketplace' ? '' : 'jubelio.primary',
                        },
                      }}
                    >
                      <Icon
                        as={item.icon}
                        h='24px'
                        w='24px'
                        fill={item.isActive(location.pathname) ? 'jubelio.primary' : '#8999A5'}
                        className='icon'
                      />
                    </Box>
                    <label
                      className='text-[10px] font-semibold'
                      style={{
                        color: !isOnlineStore && item.label === 'Marketplace' ? '#A0AEC0' : '',
                      }}
                    >
                      {item.label}
                    </label>
                  </div>
                );
              })}
              <div className='w-full border' />
              <div
                role='button'
                className='flex flex-col items-center justify-center space-y-4 p-1 hover:cursor-pointer'
                onClick={onClickOpenChatCustomerSupport}
              >
                <Icon as={ChatIcon} fill='none' />
                <p className='max-w-[80px] break-words text-center text-[10px]'>Customer Support</p>
              </div>
              {isInitSync ? (
                <div className='flex flex-col items-center justify-center space-y-4 p-1 hover:cursor-pointer'>
                  <SyncIconDot h='24px' w='24px' color='orange.500' className='rotate-180 animate-spin' />
                  <p className='max-w-[70px] break-words text-center text-[10px]'>
                    Download {currentStepDownload || '-'}...
                  </p>
                </div>
              ) : (
                <IconButton
                  aria-label='wifi'
                  variant='ghost'
                  onClick={onOpenConnection}
                  icon={<WifiActiveIcon h='24px' w='24px' fill={isOnlineStore ? 'green.500' : 'red.500'} />}
                />
              )}

              <IconButton
                aria-label='fullscreen'
                variant='ghost'
                onClick={() => {
                  toggleFullScreen();
                }}
                icon={<FullscreenIcon h='22px' w='22px' fill='system.icon' />}
              />
            </div>
          </div>
          <div className='p-4'>
            <MenuProfile />
          </div>
        </SideBar>
      )}
      <div className='ml-[5.8rem] flex w-full flex-col'>
        <div id='main-layout'>
          {!isOnlineStore && (
            <div className='m-3'>
              <Alert status='warning' variant='subtle' className='justify-center' rounded='md'>
                <AlertIcon />
                <p>
                  Kamu dalam <b>Mode Offline</b>. Ubah untuk sinkronisasi data
                </p>
              </Alert>
            </div>
          )}
          {currentStepDownload === 'done' && (
            <div className='m-3' role='button' onClick={onSwithOfflineMode}>
              <Alert status='info' variant='subtle' className='justify-center' rounded='md'>
                <AlertIcon />
                Download data selesai. Klik disini untuk ubah ke Mode Offline
                <CloseButton
                  position='absolute'
                  right='8px'
                  top='8px'
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setSyncPayload({ stepDownload: '' }));
                  }}
                />
              </Alert>
            </div>
          )}
          {isInitSync && (
            <div className='m-3'>
              <Alert status='warning' variant='subtle' className='justify-center' rounded='md'>
                <AlertIcon />
                Sedang mendownload data. Jangan refresh halaman ini
              </Alert>
            </div>
          )}
          {children}
        </div>
      </div>

      <ModalConnection isOpen={isOpenConnection} onClose={onCloseConnection} />
      {/* <ModalConfirmation
        title='Apakah Anda yakin ingin refresh halaman?'
        subtitle='Data yang di download akan hilang'
        isOpen={isOpenSync}
        onClose={onCloseSync}
        cancelText='Batal'
        okText='Lanjutkan'
        onSubmit={() => {
          window.location.reload();
          dispatch(setInitSync(true));
          clearIndexDB();
        }}
      /> */}
    </div>
  );
};

export default SalesLayout;
