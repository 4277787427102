import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends IconProps {
  isactive: boolean;
}

const StoreIcon: React.FC<Props> = (props: Props) => {
  const { isactive, ...rest } = props;
  return (
    <Icon viewBox='0 0 20 20' fill='none' {...rest}>
      <path
        d='M16.5 17V17.75H17.25V17H16.5ZM3.5 17H2.75V17.75H3.5V17ZM15.75 8.5V17H17.25V8.5H15.75ZM16.5 16.25H3.5V17.75H16.5V16.25ZM4.25 17V8.5H2.75V17H4.25Z'
        fill={isactive ? '#DE1A56' : '#8999A5'}
      />
      <path
        d='M6 12H14V17H6V12Z'
        stroke={isactive ? '#DE1A56' : '#8999A5'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M7.75321 3H12.2468M7.75321 3L7.49594 6.23781C7.39214 7.73041 8.54309 9 10 9C11.4569 9 12.6079 7.73041 12.5041 6.23781L12.2468 3M7.75321 3H3.25961L2.55616 5.91024C2.22439 7.50046 3.40465 9 4.98805 9C6.29289 9 7.37587 7.96428 7.46884 6.62749L7.75321 3ZM12.2468 3H16.7404L17.4438 5.91024C17.7756 7.50046 16.5953 9 15.0119 9C13.7071 9 12.6241 7.96428 12.5312 6.62749L12.2468 3Z'
        stroke={isactive ? '#DE1A56' : '#8999A5'}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </Icon>
  );
};

export default StoreIcon;
