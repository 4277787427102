import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image as ChakraImage,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import EmptyOrder from 'assets/svg/EmptyOrder.svg';
import { AxiosError } from 'axios';
import BadgeProduct from 'components/badge/BadgeProduct';
import { ModalShippingOptionBopis } from 'components/modal';
import ModalCourierOptionBopis from 'components/modal/ModalCourierOptionBopis';
import ModalProcessBopis from 'components/modal/ModalProcessBopis';
import ReceiptToPrint from 'components/receipt/ReceiptToPrint';
import { SubTitle, Title } from 'components/typography';
import { CHANNEL } from 'constant';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import useBopis from 'hooks/useBopis';
import React from 'react';
import bopisRequest from 'services/http/bopis.request';
import { Maybe } from 'types';
import {
  IBinDefault,
  IDetailTransaction,
  IItemToPickList,
  IOrderSetting,
  IShippingBopis,
  Items,
} from 'types/bopis.types';
import { convertToLocalDate } from 'utils';
import { mappingUpdateOrder } from 'utils/bopis';
import { hashing } from 'utils/hashing';

import CardItem from './CardItem';
import { ListDetail, ScanResi, ScanToken } from '../bopis';
import Footer from '../cart/Footer';

interface ICardDetailProps {
  detailOrder: IDetailTransaction;
  refreshReadyToPick: () => void;
  tabsKey: number;
  completeOrder: (id: number) => void;
  loadingCompleteOrder: boolean;
  setDetailOrder: (order: IDetailTransaction) => void;
  createShipment: () => void;
}

export const DetailOrder: React.FC<ICardDetailProps> = ({
  detailOrder,
  refreshReadyToPick,
  tabsKey,
  completeOrder,
  loadingCompleteOrder,
  createShipment,
  setDetailOrder,
}: ICardDetailProps) => {
  const [token, setToken] = React.useState<Maybe<string>>(null);
  const [isTokenValid, setIsTokenValid] = React.useState<Maybe<boolean>>(null);
  const [showTotal, setShowTotal] = React.useState<boolean>(true);
  const [itemList, setItemList] = React.useState<IItemToPickList[]>([]);
  const [trackingNo, setTrackingNo] = React.useState<Maybe<string>>(null);
  const [isResiValid, setIsResiValid] = React.useState<Maybe<boolean>>(null);
  const [loadingComponent, setLoadingComponent] = React.useState<Maybe<string>>(null);
  const { getItemToPick, getAirwayBill } = useBopis();
  const { notification } = useNotification();
  const [isLoadingToken, setLoadingToken] = React.useState<boolean>(false);
  const [orderSetting, setOrderSetting] = React.useState<IOrderSetting | null>(null);
  const [defaultBin, setDefaultBin] = React.useState<IBinDefault | null>(null);

  const {
    onOpen: onOpenShippingOption,
    onClose: onCloseShippingOption,
    isOpen: isOpenShippingOption,
  } = useDisclosure();

  const authSelector = useAppSelector((state) => state.auth);

  const componentRef = React.useRef(null);

  // Modal Hooks
  const {
    onOpen: onOpenProcessOrderBopis,
    onClose: onCloseProcessOrderBopis,
    isOpen: isOpenProcessOrderBopis,
  } = useDisclosure();
  const {
    onOpen: onOpenCourierOption,
    onClose: onCloseCourierOption,
    isOpen: isOpenCourierOption,
  } = useDisclosure();

  const createOrUpdatePicklist = async () => {
    try {
      const getItem = await getItemToPick(detailOrder, authSelector.is_wms_migrated);
      if (getItem) {
        setItemList(getItem);
        onOpenProcessOrderBopis();
      }
    } catch (err) {
      notification('', 'Terjadi kesalahan pada server.', 'warning');
    }
  };

  const searchToken = async (value: string) => {
    try {
      setLoadingToken(true);
      const resultHash = hashing(value);
      if (resultHash !== detailOrder.internal_do_number) {
        setIsTokenValid(false);
        return notification('', 'Token salah atau tidak ditemukan', 'warning');
      }

      const res = await bopisRequest.scanToken(resultHash);
      setDetailOrder({ ...detailOrder, received_date: res.data?.received_date });
      setToken(value);
      setIsTokenValid(true);
      notification('', 'Token berhasil discan', 'success');
    } catch (error) {
      const err = error as Error | AxiosError;
      notification('', err.message, 'warning');
    } finally {
      setLoadingToken(false);
    }
  };

  const printPicklist = async (id: number) => {
    try {
      const res = await bopisRequest.getPickupLabel([id], authSelector.is_wms_migrated);
      window.open(res.url, '_blank');
    } catch (err) {
      notification('', 'Terjadi kesalahan pada server.', 'warning');
    }
  };

  const getShipmentOrder = async (id: number) => {
    try {
      setLoadingComponent('AMBILRESI');
      if (detailOrder.source === CHANNEL.SHOPEE || detailOrder.source === CHANNEL.EVERMOS) {
        await bopisRequest.shipperPickupTime(id, authSelector.is_wms_migrated);
      }
      const res = await getAirwayBill(id, authSelector.is_wms_migrated);
      setTrackingNo(res.tracking_no);
      if (res.tracking_no === null || res.tracking_no === '') {
        setIsResiValid(false);
        notification('', 'Gagal ambil No Resi, silahkan coba lagi atau masukkan No Resi', 'warning');
      } else {
        setIsResiValid(true);
      }
      setLoadingComponent(null);
    } catch (err) {
      notification('', 'Terjadi kesalahan pada server.', 'warning');
    }
  };

  const sendTrackingNo = async (trackingNo: string) => {
    try {
      setLoadingComponent('KIRIMRESI');
      setTrackingNo(null);
      let res;
      if (!authSelector.is_wms_migrated) {
        res = await bopisRequest.updateAirWaybill({
          salesorder_id: detailOrder.salesorder_id,
          tracking_no: trackingNo,
          shipper: detailOrder.shipper,
        });
      } else {
        res = await bopisRequest.updateManualTrackingNo({
          picklist_id: detailOrder.picklist_id ?? 0,
          salesorder_id: detailOrder.salesorder_id,
          tracking_no: trackingNo ?? '',
          shipper: detailOrder.shipper,
        });
      }
      if (res) {
        setTrackingNo(trackingNo);
        detailOrder.tracking_no = trackingNo;
        setIsResiValid(true);
      }
      setLoadingComponent(null);
    } catch (err) {
      notification('', 'Terjadi kesalahan pada server.', 'warning');
      setLoadingComponent(null);
    }
  };

  const printShippingLabel = async (id: number) => {
    try {
      setLoadingComponent('PRINTLABEL');
      const res = await bopisRequest.printShippingLabel(id);
      window.open(res.url, '_blank');
      setLoadingComponent(null);
    } catch (err) {
      notification('', 'Terjadi kesalahan pada server.', 'warning');
      setLoadingComponent(null);
    }
  };

  const updateShipping = async (detailOrder: IDetailTransaction, values: IShippingBopis) => {
    try {
      const payload = mappingUpdateOrder(detailOrder, values);
      await bopisRequest.updateOrder(payload, authSelector.is_wms_migrated);
      return notification('', 'Data pengiriman berhasil diperbarui', 'success');
    } catch (error) {
      const err = error as Error | AxiosError;
      return notification('', err.message, 'warning');
    }
  };

  const getOrderSetting = async () => {
    try {
      const res = await bopisRequest.getOrderSetting();
      setOrderSetting(res);
    } catch (error) {
      const err = error as Error | AxiosError;
      notification('', err.message, 'warning');
    }
  };

  const getDefaultBin = async (location_id: number) => {
    try {
      const res = await bopisRequest.getDefaultBin(location_id);
      setDefaultBin(res);
    } catch (error) {
      const err = error as Error | AxiosError;
      notification('', err.message, 'warning');
    }
  };

  React.useEffect(() => {
    setToken(null);
    setIsTokenValid(null);
    setTrackingNo(null);
    setTrackingNo(detailOrder.tracking_no);
    if (detailOrder && detailOrder.tracking_no !== '' && detailOrder.tracking_no !== null) {
      setIsResiValid(true);
    } else {
      setIsResiValid(false);
    }
  }, [detailOrder]);

  React.useEffect(() => {
    setTimeout(() => {
      setIsTokenValid(null);
    }, 1000);
  }, [isTokenValid]);

  React.useEffect(() => {
    if (authSelector.is_wms_migrated) getOrderSetting();
  }, [authSelector.is_wms_migrated]);

  React.useEffect(() => {
    if (detailOrder.location_id && authSelector.is_wms_migrated) getDefaultBin(detailOrder.location_id);
  }, [detailOrder.location_id, authSelector.is_wms_migrated]);

  return (
    <Flex
      bg='white'
      position='relative'
      flexDir='column'
      w='full'
      borderLeft='1px'
      borderColor='gray.200'
      roundedBottomRight='lg'
      h='full'
      overflowY='auto'
    >
      {Object.keys(detailOrder).length === 0 && (
        <Stack alignItems='center' h='full' justifyContent='center' spacing={8}>
          <ChakraImage src={EmptyOrder} display='inline-block' />
          <Box textAlign='center' w='65%'>
            <Title>Pilih Untuk Detail Transaksi</Title>
            <SubTitle fontSize='16px' mt={4}>
              Cari barang dan lihat harga dari setiap barang yang ada
            </SubTitle>
          </Box>
        </Stack>
      )}
      {Object.keys(detailOrder).length > 0 && (
        <React.Fragment>
          <Flex alignItems='center' justifyContent='space-between' px={4} py={2}>
            <Box>
              <Text fontWeight='bold' fontSize={14}>
                Detail Order
              </Text>
              <SubTitle fontSize={13}>{detailOrder.salesorder_no}</SubTitle>
            </Box>
            {tabsKey === 1 && detailOrder.shipment_type === 'pickup_in_store' && (
              <Button
                onClick={() => printPicklist(detailOrder.salesorder_id)}
                variant='outline'
                style={{ backgroundColor: 'white', fontSize: 12 }}
                w='40%'
                size='sm'
              >
                Cetak Label Pengambilan
              </Button>
            )}
          </Flex>
          <Stack w='full' spacing={4} px={4}>
            <Box border='1px' borderColor='system.outline' p={5} rounded='4px' bg='system.smoke'>
              <Flex alignItems='flex-start' justifyContent='space-between'>
                <Stack spacing='0px'>
                  <Title>{convertToLocalDate(detailOrder.transaction_date)}</Title>
                  <SubTitle color='jubelio.black'>{detailOrder.customer_name}</SubTitle>
                  <SubTitle color='jubelio.black'>{detailOrder.shipping_phone}</SubTitle>
                  <SubTitle color='jubelio.black'>-</SubTitle>
                </Stack>
                <Stack>
                  <BadgeProduct
                    mb='12px'
                    colorScheme={detailOrder.shipment_type === 'pickup_in_store' ? 'green' : 'batch'}
                  >
                    {detailOrder.shipment_type === 'pickup_in_store' ? 'Bopis' : 'Online'}
                  </BadgeProduct>
                </Stack>
              </Flex>
              {tabsKey === 1 && detailOrder.shipment_type === 'pickup_in_store' && (
                <ScanToken
                  token={token}
                  setToken={setToken}
                  searchToken={searchToken}
                  isTokenValid={isTokenValid}
                  detailOrder={detailOrder}
                  loadingCompleteOrder={loadingCompleteOrder}
                  completeOrder={completeOrder}
                  isLoading={isLoadingToken}
                />
              )}
              {tabsKey === 1 && detailOrder.shipment_type === null && (
                <ScanResi
                  detailOrder={detailOrder}
                  trackingNo={detailOrder.courier === 'Ambil Sendiri' ? 'AMBIL SENDIRI' : trackingNo}
                  setTrackingNo={setTrackingNo}
                  getShipmentOrder={getShipmentOrder}
                  sendTrackingNo={sendTrackingNo}
                  isResiValid={detailOrder.courier === 'Ambil Sendiri' ? true : isResiValid}
                  printShippingLabel={printShippingLabel}
                  completeOrder={completeOrder}
                  loadingComponent={loadingComponent}
                  setIsResiValid={setIsResiValid}
                  loadingCompleteOrder={loadingCompleteOrder}
                  createShipment={createShipment}
                />
              )}
            </Box>
            <Divider my={4} />
          </Stack>
          <Stack minH='250px' flex={1}>
            <Box flexGrow={1} textAlign='left' overflowY='auto' h='full'>
              <VStack px={4} position='relative' pb={4}>
                {detailOrder.items.length > 0 && (
                  <Title fontSize='14px' textAlign='left' w='full' pt={2}>
                    {detailOrder.items.length} items
                  </Title>
                )}
                {detailOrder.items.map((item: Items, index: number) => (
                  <CardItem key={index} item={item} />
                ))}
              </VStack>
            </Box>
            <Footer>
              <ListDetail detailOrder={detailOrder} showTotal={showTotal} onShowTotal={setShowTotal} />
              {tabsKey === 0 && (
                <HStack mt={3}>
                  {detailOrder.courier === null || detailOrder.courier === '' ? (
                    <Button variant='outline' size='md' w='full' onClick={onOpenShippingOption}>
                      Opsi Pengiriman
                    </Button>
                  ) : null}
                  <Button
                    variant='primary'
                    size='md'
                    w='full'
                    onClick={createOrUpdatePicklist}
                    isDisabled={detailOrder.courier === ''}
                  >
                    Proses Pesanan
                  </Button>
                </HStack>
              )}
              {tabsKey === 2 && (
                <HStack mt={3}>
                  <Button
                    variant='primary'
                    size='md'
                    w='full'
                    onClick={() => completeOrder(detailOrder.salesorder_id)}
                  >
                    Selesaikan Pesanan
                  </Button>
                </HStack>
              )}
            </Footer>
          </Stack>
        </React.Fragment>
      )}

      <ModalProcessBopis
        isOpen={isOpenProcessOrderBopis}
        onClose={onCloseProcessOrderBopis}
        detailOrder={detailOrder}
        itemList={itemList}
        setItemList={setItemList}
        refreshReadyToPick={refreshReadyToPick}
        setDetailOrder={setDetailOrder}
        orderSetting={orderSetting}
        defaultBin={defaultBin}
      />

      <ModalShippingOptionBopis
        isOpen={isOpenShippingOption}
        onClose={onCloseShippingOption}
        updateShipping={updateShipping}
        openCourierOption={onOpenCourierOption}
        detailOrder={detailOrder}
        setDetailOrder={setDetailOrder}
      />

      <ModalCourierOptionBopis
        isOpen={isOpenCourierOption}
        onClose={onCloseCourierOption}
        detailOrder={detailOrder}
        updateShipping={updateShipping}
        openShippingOption={onOpenShippingOption}
        setDetailOrder={setDetailOrder}
      />

      {Object.keys(detailOrder).length > 0 && (
        <Box display='none'>
          <ReceiptToPrint ref={componentRef} />
        </Box>
      )}
    </Flex>
  );
};

export default React.memo(DetailOrder);
