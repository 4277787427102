import { Box, TabProps, Text, useTab, VStack } from '@chakra-ui/react';
import React from 'react';

const CustomRoundedTab: React.FC<TabProps> = (props: TabProps) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  return (
    <Box
      w='full'
      textAlign='left'
      flexGrow={0}
      flexShrink={{ base: 0, sm: 0, md: 1, lg: 1 }}
      flexBasis='13%'
      maxW='13%'
      as='button'
      {...tabProps}
    >
      <VStack alignItems='center'>
        <VStack w='full' h='full' alignItems='center'>
          <Text fontSize='14px' color='jubelio.black'>
            {props.children}
          </Text>
        </VStack>
        <VStack align='flex-start' h='4px' w='full'>
          <Box
            w='full'
            h='4px'
            bg={isSelected ? 'jubelio.primary' : 'transparent'}
            className='Fill the remaining height'
            roundedTop='3xl'
          />
        </VStack>
      </VStack>
    </Box>
  );
};

export default React.memo(CustomRoundedTab);
