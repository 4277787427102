import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const EditIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 25 24' {...props}>
    <path d='M2.29785 6V8H14.5532V6H2.29785ZM2.29785 10V12H14.5532V10H2.29785ZM20.7217 10.13C20.5787 10.13 20.4357 10.19 20.3234 10.3L19.3021 11.3L21.3957 13.35L22.417 12.35C22.6417 12.14 22.6417 11.79 22.417 11.58L21.1098 10.3C21.06 10.2476 21.0001 10.2056 20.9334 10.1764C20.8667 10.1472 20.7947 10.1314 20.7217 10.13ZM18.7098 11.88L12.5106 17.94V20H14.6145L20.8034 13.93L18.7098 11.88ZM2.29785 14V16H10.4681V14H2.29785Z' />
  </Icon>
);

export default React.memo(EditIcon);
