import { Box, BoxProps, Flex, Switch, Text } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';

interface CardSettingDescriptionProps extends BoxProps {
  title: string;
  subtitle: string;
  isActive: boolean;
  clickButton: (e: boolean) => void;
}

const CardSettingDescription: React.FC<CardSettingDescriptionProps> = (
  props: CardSettingDescriptionProps
) => {
  const { title, subtitle, isActive, clickButton, ...rest } = props;

  return (
    <Box border='1px' borderColor='gray.200' borderRadius='4px' {...rest}>
      <Flex
        justifyContent='space-between'
        className='space-x-2'
        mb='5px'
        position='relative'
        mt={5}
        px={10}
        alignItems='center'
      >
        <Box fontSize='14px' w='80%'>
          <Title>{title}</Title>
          <Box py={2}>
            <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
          </Box>
        </Box>
        <Box w='20%' p={3} textAlign='center'>
          <Switch
            size='lg'
            colorScheme='red'
            isChecked={isActive}
            onChange={(e) => clickButton(e.target.checked)}
          />
        </Box>
      </Flex>
    </Box>
  );
};
export default React.memo(CardSettingDescription);
