import EscPosEncoder from '@revell29/esc-pos-encoder';
import { loadImage } from 'canvas';
import { stringSplitter } from 'lib/helpers';
import * as QRCode from 'qrcode';
import { IUserInfo } from 'types/auth.types';
import { StructSetting } from 'types/common.types';
import { ILocations } from 'types/register.types';
import { convertToLocalDate } from 'utils/datetime';

interface RawCommand {
  settingPrint: StructSetting | null;
  location: ILocations | undefined | null;
  profile: IUserInfo | null;
  paperSize: number;
  printCopy: number;
  orderNo: string;
  qrisData: string;
  grandTotal: string;
}

export async function QrisCommand({
  settingPrint,
  location,
  profile,
  paperSize,
  printCopy,
  orderNo,
  qrisData,
  grandTotal,
}: RawCommand): Promise<any> {
  const encoder = new EscPosEncoder();
  let result: any = encoder.initialize().setPinterType(Number(paperSize)).align('center');
  if (settingPrint?.logo && settingPrint?.logo.length > 0) {
    const img = await loadImage(settingPrint?.logo as string);
    if (paperSize === 58 || paperSize === 76) {
      result = result.align('center').image(img, 200, 56, 'atkinson');
    } else if (paperSize === 80) {
      result = result.align('center').image(img, 400, 120, 'atkinson');
    }
  }

  result = result
    .newline()
    .newline()
    .size(0.1)
    .bold(true)
    .line(`${settingPrint?.store_name}`)
    .bold(false)
    .line(`${settingPrint?.tagline}`);
  const lengthSplit = paperSize === 80 ? 45 : 28;

  if (location && location.address) {
    const arrayAddress = stringSplitter(lengthSplit, location?.address);
    arrayAddress?.forEach((address: string, _index: number) => {
      result = result.line(`${address}`);
    });
  }

  result = result
    .newline()
    .align('left')
    .line(`${settingPrint?.cashier_label}: ${profile?.user.full_name}`)
    .line(`${settingPrint?.date_label}: ${convertToLocalDate(new Date())}`)
    .line(`${settingPrint?.transaction_no_label}: ${orderNo}`);

  result = result.printLine('-');
  result = result.align('center').bold(true).line(`TAGIHAN`).bold(false).align('left');
  result = result.printLine('-');

  result = result.newline().align('center').line(`Silahkan scan kode QR untuk membayar`);
  const qrCode = await QRCode.toCanvas(qrisData);
  if (paperSize === 58 || paperSize === 76) {
    result = result.newline().align('center').image(qrCode, 280, 280, 'threshold');
  } else if (paperSize === 80) {
    result = result.newline().align('center').image(qrCode, 400, 400, 'threshold');
  }

  result = result
    .newline()
    .newline()
    .align('center')
    .line(`${grandTotal}`)
    .bold(true)
    .newline()
    .line(`Ini bukti pembayaran`)
    .bold(false)
    .line('Powered by Jubelio POS')
    .newline()
    .newline()
    .newline()
    .cut()
    .encode();

  const arrayCombine: Array<number> = [];
  printCopy = 1;
  for (let i = 0; i < Number(printCopy); i++) {
    result.map((b: number) => arrayCombine.push(b));
  }

  const byteArray = new Uint8Array(arrayCombine);
  return new Uint8Array(byteArray.buffer);
}
