import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CashRegisterIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 32 32' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5995 2.80078C12.9368 2.80078 12.3995 3.33804 12.3995 4.00078V8.00078C12.3995 8.66352 12.9368 9.20078 13.5995 9.20078H18.7996V10.8008H7.19937C6.65822 10.8008 6.18403 11.163 6.04165 11.685L3.64165 20.485C3.61358 20.5879 3.59937 20.6941 3.59937 20.8008V27.2008C3.59937 27.8635 4.13662 28.4008 4.79937 28.4008H27.1994C27.8621 28.4008 28.3994 27.8635 28.3994 27.2008V20.8008C28.3994 20.6941 28.3851 20.5879 28.3571 20.485L25.9571 11.685C25.8147 11.163 25.3405 10.8008 24.7994 10.8008H21.1996V9.20078H24.7995C25.4622 9.20078 25.9995 8.66352 25.9995 8.00078V4.00078C25.9995 3.33804 25.4622 2.80078 24.7995 2.80078H13.5995ZM23.5995 6.80078H19.9996H14.7995V5.20078H23.5995V6.80078ZM8.11592 13.2008H19.998L19.9996 13.2008L20.0011 13.2008H23.8828L25.6281 19.6003H20.0255C20.0088 19.5999 19.9921 19.5999 19.9755 19.6003H12.0237C12.007 19.5999 11.9903 19.5999 11.9736 19.6003H6.37061L8.11592 13.2008ZM11.7262 22.9369L11.2579 22.0003H5.99937V26.0008H25.9994V22.0003H20.7412L20.2729 22.9369L19.9412 23.6003H19.1996H12.7996H12.0579L11.7262 22.9369ZM9.5995 15.2013H12.1595V17.6013H9.5995V15.2013ZM14.7195 15.2013H17.2795V17.6013H14.7195V15.2013ZM22.3995 15.2013H19.8395V17.6013H22.3995V15.2013Z'
    />
  </Icon>
);

export default CashRegisterIcon;
