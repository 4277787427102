import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  text: string;
}

const PosDisabled: React.FC<Props> = ({ text }: Props) => {
  return (
    <Flex
      position='absolute'
      top={0}
      left={0}
      rounded='3px'
      textAlign='center'
      w='full'
      zIndex={5}
      h='calc(100vh - 90px)'
      alignItems='center'
      bg='rgba(255, 255, 255, 0.67)'
      justifyContent='center'
    >
      <Text
        fontSize='14px'
        fontWeight='600'
        color='black'
        border='1px'
        borderColor='jubelio.grey300'
        py='4px'
        px='12px'
        bg='white'
        rounded='4px'
      >
        {text}
      </Text>
    </Flex>
  );
};

export default PosDisabled;
