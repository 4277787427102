import { Box, Button, Text } from '@chakra-ui/react';
import * as React from 'react';

interface ToastProps {
  text: string;
  onClick: () => void;
  buttonText?: string;
}

const ToastVersion: React.FC<ToastProps> = ({ text, onClick, buttonText, ...rest }) => (
  <Box
    p={3}
    bg='blue.600'
    display='flex'
    justifyContent='space-between'
    alignItems='center'
    rounded={4}
    color='white'
    fontSize='sm'
    {...rest}
  >
    <Text maxW='15rem'>{text}</Text>
    <Button onClick={onClick} size='xs' ml={2} color='black'>
      {buttonText || 'Refresh'}
    </Button>
  </Box>
);

export default ToastVersion;
