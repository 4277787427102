import { Box, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { css } from '@emotion/react';
import React from 'react';

interface BadgePromotionProps extends IconButtonProps {
  counter: number;
}

const BadgePromotion: React.FC<BadgePromotionProps> = ({
  counter,
  icon,
  ...rest
}: BadgePromotionProps) => {
  return (
    <Tooltip hasArrow label='Promosi' bg='jubelio.grey200'>
      <IconButton
        css={css`
          position: relative !important;
        `}
        variant='ghost'
        {...rest}
        icon={
          <>
            {icon}
            {counter > 0 && (
              <Box
                as={'span'}
                color={'white'}
                position={'absolute'}
                top={'-5px'}
                right={'-6px'}
                minW='20px'
                fontSize={'12px'}
                fontWeight='400'
                bgColor='jubelio.primary'
                rounded={'full'}
                zIndex={100}
                p={'3px'}
              >
                {counter}
              </Box>
            )}
          </>
        }
      />
    </Tooltip>
  );
};

export default BadgePromotion;
