import { IItemReturn } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { getRoundMoney } from 'utils';

import { getSubMinusTotal } from './return';

export function getTotalItemPrice(item: IItemReturn): number {
  return Number(item.sell_price) * item.qty;
}

export function getCurrentTotalItemPrice(item: IItemReturn): number {
  return Number(item.sell_price) * item.qty_in_base;
}

export function getCurrentDiscountAmount(item: IItemReturn): number {
  if (item.qty < 0) {
    return item.disc_per_qty * item.qty_in_base;
  }

  return 0;
}

export function getDiscountAmount(item: IItemReturn): number {
  if (item.qty < 0) {
    return item.disc_per_qty * item.qty;
  }

  return 0;
}

export function getSubTotalReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.total;
  }, 0);
}

export function getTotalDiscountReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.disc_amount;
  }, 0);
}

export function getTotalAddDiscReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.addDisc;
  }, 0);
}

export function getTotalTaxReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.tax_amount;
  }, 0);
}

export function getTotalOtherFeeReturn(
  items: IItemReturn[],
  subTotal: number,
  addFee: number
): number {
  return items.reduce((total, item) => {
    const totals =
      total +
      ((Number(item.price) * Number(item.qty_in_base) - Number(item.disc_amount)) / subTotal) *
        addFee;
    return totals;
  }, 0);
}

export function getTotalItemsReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);
}

export function currentTotalAfterItemDiscount(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + getCurrentTotalItemPrice(item) - getCurrentDiscountAmount(item);
  }, 0);
}

export function totalAfterItemDiscount(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + getTotalItemPrice(item) - getDiscountAmount(item);
  }, 0);
}

export function getGrandTotalReturn(
  listItem: IItemReturn[],
  addDiscount: number,
  taxIncluded: boolean,
  addFee: number,
  clearOtherFee = false
): number {
  const saleTotal = totalAfterItemDiscount(listItem);
  const currentSaleTotal = currentTotalAfterItemDiscount(listItem);
  const otherFee = clearOtherFee ? 0 : getTotalOtherFeeReturn(listItem, saleTotal, addFee);
  const totalTax = getTotalTaxReturn(listItem);
  const totalDisc = getDiscountTransaksi(listItem, saleTotal, addDiscount);
  const total = currentSaleTotal - Math.abs(otherFee) + (taxIncluded ? 0 : totalTax) - totalDisc;
  return total;
}

export const getAmountDetailReturn = (
  items: IItemReturn[],
  taxIncluded: boolean,
  addDiscount: number,
  addFee: number,
  clearOtherFee = false
): ITotalDetail => {
  const saleTotal = totalAfterItemDiscount(items);
  const subTotalItem = getSubTotalReturn(items);
  const discountBarang = getTotalDiscountReturn(items);
  const discountTrx = getDiscountTransaksi(items, saleTotal, addDiscount);
  const totalTax = getTotalTaxReturn(items);
  const otherCost = clearOtherFee ? 0 : getTotalOtherFeeReturn(items, saleTotal, addFee);
  const totalItems = getTotalItemsReturn(items);
  const grandTotal = getGrandTotalReturn(items, addDiscount, taxIncluded, addFee, clearOtherFee);
  const roundMoney = clearOtherFee ? 0 : getRoundMoney(Math.abs(grandTotal));
  const haveToPay = grandTotal - roundMoney;
  const subMinusTotal = getSubMinusTotal(items);

  return {
    subTotalItem,
    discountBarang,
    discountTrx,
    totalTax,
    otherCost,
    totalItems,
    grandTotal,
    roundMoney,
    haveToPay,
    subMinusTotal,
    getTotalTransaction: 0,
    shippingCost: 0,
    insuranceCost: 0,
    discountOutlet: 0,
    salesPromotions: 0,
    salesDiscountAmount: 0,
    clearOtherFee,
  };
};

export function amountPerItemAfterDiscount(item: IItemReturn): number {
  if (item.qty < 0) {
    return getCurrentTotalItemPrice(item) - getCurrentDiscountAmount(item);
  } else {
    return getCurrentTotalItemPrice(item);
  }
}

export function amountPerItemAfterAddDiscount(
  item: IItemReturn,
  addDisc: number,
  discount_as_service_fee: boolean
): number {
  const finalItemDiscount = amountPerItemAfterDiscount(item);
  if (discount_as_service_fee) return finalItemDiscount;

  return finalItemDiscount - addDisc;
}

export function getAddDisc(
  price: number,
  qty: number,
  subTotal: number,
  addDiscount: number
): number {
  let addDisc = 0;
  if (qty < 0) {
    addDisc = ((Number(price) * qty) / subTotal) * addDiscount;
  }

  return addDisc;
}

export function getDiscountTransaksi(
  items: IItemReturn[],
  subTotal: number,
  addDiscount: number
): number {
  return items.reduce((total, item) => {
    const totals =
      total + ((Number(item.total) - Number(item.disc_amount)) / subTotal) * addDiscount;
    return totals;
  }, 0);
}

export function getOtherFee(
  price: number,
  qty: number,
  discAmount: number,
  subTotal: number,
  addFee: number
): number {
  let otherFee = 0;
  if (qty < 0) {
    otherFee = ((price * qty - discAmount) / subTotal) * addFee;
  }

  return otherFee;
}
