import React from 'react';
import { ISerialBatchNumber } from 'types/inventory';
import { IItemCart } from 'types/products.types';

type ISelectOptionsSNBN = {
  value: string;
  label: string;
};

type IUseItemSerialNumber = {
  locationId: number;
  isOnline: boolean;
  isOpen?: boolean;
  isFetching?: boolean;
  itemCart?: IItemCart;
  dataOptions?: ISerialBatchNumber[];
};

const useItemSerialNumber = ({
  dataOptions,
  isOnline,
  isOpen,
  itemCart,
  isFetching,
}: IUseItemSerialNumber) => {
  const [selectedSerialNumber, setSelectedSerialNumber] = React.useState<ISerialBatchNumber[]>([]);
  const [optionSerialNumber, setOptionSerialNumber] = React.useState<ISelectOptionsSNBN[]>([]);

  const optionsSerialNumber = React.useMemo(() => {
    const selectedSerialNos = new Set(selectedSerialNumber.map((sn) => sn.serial_no));

    return optionSerialNumber.filter((item) => !selectedSerialNos.has(item.value));
  }, [optionSerialNumber, selectedSerialNumber]);

  const getOfflineSNBN = async () => {
    const data = itemCart?.list_serial_number?.map((sn) => ({
      value: sn.serial_no || '',
      label: sn.serial_no || '',
    }));

    setOptionSerialNumber(data || []);
    if (itemCart?.use_serial_number) {
      let newSerialNumber = Array(itemCart?.quantity).fill({ serial_no: '', qty: 1 });

      if (itemCart.serial_number) {
        newSerialNumber = itemCart.serial_number.map((sn) => ({ ...sn, qty: sn.amount || 0 }));
        newSerialNumber = newSerialNumber.concat(
          Array(Number(itemCart?.quantity ?? 0) - itemCart.serial_number.length).fill({
            serial_no: '',
            qty: 1,
          })
        );
      }

      return setSelectedSerialNumber(newSerialNumber);
    }
  };

  const getOnlineSNBN = async (item: IItemCart) => {
    const serialNumbers = dataOptions?.map((item) => ({
      value: item.serial_no || '',
      label: item.serial_no || '',
    }));

    setOptionSerialNumber(serialNumbers || []);

    if (item?.use_serial_number) {
      let newSerialNumber = Array(item.quantity).fill({ serial_no: '', qty: 1 });

      if (item.serial_number) {
        newSerialNumber = item.serial_number.map((sn) => ({ ...sn, qty: sn.amount || 0 }));
        newSerialNumber = newSerialNumber.concat(
          Array(item.quantity - item.serial_number.length).fill({
            serial_no: '',
            qty: 1,
          })
        );
      }
      return setSelectedSerialNumber(newSerialNumber);
    }
  };

  const onChangeSerialNumber = (value: string, index: number) => {
    const findSN =
      selectedSerialNumber && selectedSerialNumber.find((sn) => sn.serial_no === value && value !== '');

    if (!findSN) {
      const listSelectedSerialNumber =
        selectedSerialNumber &&
        selectedSerialNumber.map((item, idx) =>
          index === idx ? { ...item, serial_no: value, amount: 1 } : item
        );

      setSelectedSerialNumber(listSelectedSerialNumber);
    }
  };

  const onCountSerialQty = (action: 'increase' | 'decrease', value: number) => {
    if (!selectedSerialNumber) return;
    setSelectedSerialNumber((prevSelected) => {
      if (action === 'increase') {
        return [...prevSelected, { serial_no: '', qty: value }];
      } else {
        return prevSelected.slice(0, -1);
      }
    });
  };

  const onChangeQtySerialNumber = (value: number, qtyOnCart: number) => {
    if (!selectedSerialNumber) return;

    const currentSelectedSerialNumber = [...selectedSerialNumber];
    const lengthArray = value > qtyOnCart ? value - Number(currentSelectedSerialNumber.length) : value;
    const newQty = Math.max(value, 0); // Ensure non-negative quantity

    const newSelectedSerialNumber =
      value > qtyOnCart
        ? currentSelectedSerialNumber.concat(Array(lengthArray).fill({ serial_no: '', qty: 1 }))
        : currentSelectedSerialNumber.splice(0, newQty);

    setSelectedSerialNumber(newSelectedSerialNumber);
  };

  React.useEffect(() => {
    if (!isOpen) return;
    if (isOnline && !isFetching) {
      getOnlineSNBN(itemCart as IItemCart);
    } else {
      getOfflineSNBN();
    }
  }, [isFetching, isOpen, itemCart, isOnline]);

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedSerialNumber([]);
      setOptionSerialNumber([]);
    }
  }, [isOpen, isOnline]);

  return {
    selectedSerialNumber,
    onChangeSerialNumber,
    onChangeQtySerialNumber,
    onCountSerialQty,
    optionsSerialNumber,
  };
};

export default useItemSerialNumber;
