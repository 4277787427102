import EscPosEncoder from '@revell29/esc-pos-encoder';
import { loadImage } from 'canvas';
import { PAYMENT } from 'constant';
import { stringSplitter } from 'lib/helpers';
import { IUserInfo } from 'types/auth.types';
import { IPaymentClosure, ItemSold } from 'types/closure.types';
import { StructSetting } from 'types/common.types';
import { ILocations, PickBankNote } from 'types/register.types';
import { combineAddress, currencyFormat, currencyFormatNotFraction, getPaymentsName } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

type IClosureData = {
  listPayments: IPaymentClosure[];
  listItemSold: ItemSold[];
  listBankNotes: PickBankNote[];
  closureId: number;
  openDate: Date;
  closingDate: Date;
  initCash: number;
  notes: string;
};

interface RawCommand {
  closureData: IClosureData;
  settingPrint: StructSetting | null;
  location: ILocations | undefined | null;
  profile: IUserInfo | null;
  paperSize: number;
  printCopy: number;
  reprint?: string;
}

export async function clossureCommand({
  closureData,
  settingPrint,
  location,
  profile,
  paperSize,
  printCopy,
  reprint,
}: RawCommand): Promise<any> {
  const isCashFraction = closureData.listBankNotes.some(
    (bank) => bank.closure_type === 'CLOSED' || !bank.closure_type
  );
  const encoder = new EscPosEncoder();
  let result: any = encoder.initialize().setPinterType(Number(paperSize)).align('center');
  if (settingPrint?.show_logo && settingPrint?.logo && settingPrint?.logo.length > 0) {
    const img = await loadImage(settingPrint?.logo as string);
    if (paperSize === 58 || paperSize === 76) {
      result = result.align('center').image(img, 200, 56, 'atkinson');
    } else if (paperSize === 80) {
      result = result.align('center').image(img, 400, 120, 'atkinson');
    }
  }

  result = result.newline().newline().size(0.1);

  if (settingPrint?.show_store_name) {
    result = result.bold(true).line(`${settingPrint?.store_name}`).bold(false);
  }

  if (settingPrint?.show_tagline) {
    result = result.line(`${settingPrint?.tagline}`);
  }
  const lengthSplit = paperSize === 80 ? 45 : 28;

  if (settingPrint?.show_store_information && location && location.address) {
    const arrayAddress = stringSplitter(lengthSplit, location?.address);
    const arrayAdressFull = stringSplitter(lengthSplit, combineAddress(location));
    if (settingPrint?.show_store_address && settingPrint?.show_store_address_full) {
      arrayAddress?.forEach((address: string, _index: number) => {
        result = result.line(`${address}`);
      });
    } else if (settingPrint?.show_store_address) {
      arrayAdressFull?.forEach((address: string, _index: number) => {
        result = result.line(`${address}`);
      });
    }
  }

  result = result.printLine('-');
  result = result.align('center').bold(true).line(`Penutupan Kasir`).bold(false).align('left');
  result = result.printLine('-');

  result = result.line(`No Penutupan: ${closureData.closureId}`);

  result = result
    .align('left')
    .line(`Kasir: ${reprint ? reprint : profile?.user.full_name}`)
    .line(`Jam Buka: ${convertToLocalDate(closureData.openDate)}`)
    .line(`Jam Tutup: ${convertToLocalDate(closureData.closingDate)}`);

  result = result.printLine('-');
  result = result.align('center').bold(true).line(`Pembayaran`).bold(false).align('left');
  result = result.printLine('-');

  // section of Payment Method
  for (const payment of closureData.listPayments) {
    result = result.bold(true).line(getPaymentsName(payment));

    if (payment.payment_id === PAYMENT.CASH) {
      result = result
        .bold(false)
        .oneLine(
          '-Pembukaan',
          `${
            settingPrint?.show_two_digit_fraction
              ? currencyFormat(closureData.initCash)
              : currencyFormatNotFraction(closureData.initCash)
          }`
        );
    }

    result = result
      .bold(false)
      .oneLine(
        '-Penjualan',
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(payment.total_sales)
            : currencyFormatNotFraction(payment.total_sales)
        }`
      );

    if ([PAYMENT.CASH, PAYMENT.STORE_CREDIT].includes(payment.payment_id)) {
      result = result.oneLine(
        '-Retur',
        `${
          settingPrint?.show_two_digit_fraction
            ? currencyFormat(payment.total_retur)
            : currencyFormatNotFraction(payment.total_retur)
        }`
      );
    }
  }

  // Section of Banks Note (Pecahan Uang)
  if (closureData.listBankNotes?.length > 0 && isCashFraction) {
    result = result.align('center').bold(true).line(`Pecahan Uang Tunai`).bold(false).align('left');
    result = result.printLine('-');

    for (const banks of closureData.listBankNotes) {
      if (banks.closure_type === 'CLOSED' || !banks.closure_type) {
        result = result.oneLine(
          `${
            settingPrint?.show_two_digit_fraction
              ? currencyFormat(Number(banks.note_amount))
              : currencyFormatNotFraction(Number(banks.note_amount))
          }`,
          `${banks?.banknote_count} `
        );
      }
    }
    result = result.printLine('-');
  }

  if (closureData.listItemSold.length > 0) {
    result = result.align('center').bold(true).line(`Barang`).bold(false).align('left');
    result = result.printLine('-');

    // Section of Items Sold
    for (const item of closureData.listItemSold) {
      result = result
        .bold(true)
        .line(`${item.item_code}`)
        .bold(false)
        .line(
          `(P) ${item.sales_qty} - (R) ${item.return_qty} = (T) ${item.sales_qty - item.return_qty}`
        );
    }
  }
  result = result.printLine('-');
  result = result.line(`Catatan: ${closureData.notes}`);
  result = result.printLine('-');

  // encode all command and loop according to Print Copy
  result = result.newline().newline().newline().cut().encode();
  const arrayCombine: Array<number> = [];
  for (let i = 0; i < Number(printCopy); i++) {
    result.map((b: number) => arrayCombine.push(b));
  }

  const byteArray = new Uint8Array(arrayCombine);
  return new Uint8Array(byteArray.buffer);
}
