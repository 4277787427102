import { DeleteIcon, SpinnerIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { InputNumber } from 'components/forms';
import { EditIcon } from 'components/icons';
import ListTotal from 'components/list/ListTotal';
import { MenuItemAction } from 'components/menu';
import { TooltipBatchNumber } from 'components/popover/BatchNumber';
import { TooltipPrice } from 'components/popover/Pricebook';
import { TooltipSerialNumber } from 'components/popover/SerialNumber';
import { SubTitle, Title } from 'components/typography';
import { alert } from 'constant/messages';
import { useCommon, useNotification, usePromotions } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { arraysHaveSameIds } from 'lib/helpers';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { decrementItem, incrementItem, removeItem, updateQuantityItem } from 'redux/reducer/sales';
import { PriceBook } from 'types/common.types';
import { IItemCart, IUpdateItemCart } from 'types/products.types';
import { IBatchNumber, ISerialNumber, ITotalDetail } from 'types/sales.types';
import {
  currencyFormat,
  debounce,
  getAmountItem,
  getDiscountItem,
  getOnlyDiscountItem,
  getSaleTotalAfterItemDiscount,
  getTaxAfterDiscount,
} from 'utils';

interface CardItemProps extends BoxProps {
  item: IItemCart & {
    is_free?: boolean;
    promotion_name?: string;
  };
  isNotFound?: boolean;
  onChangeItem: (item: IItemCart) => void;
  totalDetail?: ITotalDetail;
  onOpenConfirmRemovePromo: () => void;
  setDeletedItem: (param: IUpdateItemCart | null) => void;
  setRollbackQtyItem: (param: number | null) => void;
  rollbackQtyItem: number | null;
}

const CardItem: React.FC<CardItemProps> = ({
  item,
  onChangeItem,
  totalDetail,
  isNotFound = false,
  onOpenConfirmRemovePromo,
  setDeletedItem,
  setRollbackQtyItem,
  rollbackQtyItem,
  ...rest
}: CardItemProps) => {
  const dispatch = useAppDispatch();
  const { disableEditItem } = useCommon();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const profile = useAppSelector((state) => state.auth.profile);
  const usePromotion = useAppSelector((state) => state.commons.usePromotion);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const isLoadingCheckout = useAppSelector((state) => state.sales.isLoadingCheckout);
  const settings = useAppSelector((state) => state.commons.settings);
  const listPromotionsItems = useAppSelector(
    ({ sales: { listPromotionsItems } }) => listPromotionsItems,
    shallowEqual
  );
  const listAllPromotion = useAppSelector(
    ({ sales: { listAllPromotion } }) => listAllPromotion,
    shallowEqual
  );
  const { notification } = useNotification();
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');
  const [removedItemId, setRemovedItemId] = React.useState<number | null>(null);
  const [qty, setQty] = React.useState<number>(item.quantity);

  const { getPromotionItem } = usePromotions();

  const totalDiscount = React.useMemo(() => {
    return continuePayment ? item.discount_amount : getOnlyDiscountItem(item, listPromotionsItems);
  }, [item]);

  const totalAmount = React.useMemo(() => {
    const totalPrice = getAmountItem(item);
    return totalPrice - getDiscountItem(item, listPromotionsItems, true, totalPrice);
  }, [listPromotionsItems, item]);

  const isRemovedPromo = async (itemCart: IItemCart[]): Promise<boolean> => {
    try {
      const totalAfterDiscount = getSaleTotalAfterItemDiscount(itemCart, []);
      const result = await getPromotionItem(itemCart, totalAfterDiscount ?? 0);

      return arraysHaveSameIds(result.allPromotion, listAllPromotion, 'promotion_id');
    } catch (error: any) {
      notification('', error.message, 'error');
      setRemovedItemId(null);
      return false;
    }
  };

  const changeQty = React.useCallback(
    debounce(async (_item: IItemCart, qty: number) => {
      if (!isNaN(Number(qty)) && Number.isInteger(qty)) {
        const itemUpdate = { item_id: _item.item_id, quantity: qty <= 0 ? 1 : qty };

        if (_item.pos_check_stock && Number(_item.available) < qty) {
          notification('', 'Stok habis atau tidak mencukup', 'warning');
          return;
        }

        setDeletedItem({ item_id: _item.item_id, qty: qty <= 0 ? 1 : qty, action: 'UPDATE-QTY' });
        const modifyListItemCart = listItemCart.map((item) => {
          if (item.item_id === _item.item_id) {
            return { ...item, quantity: qty <= 0 ? 1 : qty };
          }
          return item;
        });

        if (isOnline && usePromotion) {
          setRemovedItemId(_item.item_id);
          const removedPromo = await isRemovedPromo(modifyListItemCart);
          if (!removedPromo) {
            onOpenConfirmRemovePromo();
          } else {
            dispatch(updateQuantityItem(itemUpdate));
          }
          setRemovedItemId(null);
        } else {
          dispatch(updateQuantityItem(itemUpdate));
        }
      }
    }, 300),
    [qty, usePromotion]
  );

  const handleChangeQty = (e: any) => {
    setQty(Number(e.target.value));
    changeQty(item, Number(e.target.value));
  };

  const incrementsQty = React.useCallback(
    async (itemId: number) => {
      const qty = item.quantity + 1;

      if (item.pos_check_stock && (item.available === 0 || qty > item.available)) {
        notification('', alert.stock_empty, 'warning');
        return;
      }
      setQty(qty);
      dispatch(incrementItem(itemId));
    },
    [item]
  );

  const debounceIncrements = debounce((itemId: number) => {
    incrementsQty(itemId);
  }, 1);

  const debounceDecrements = debounce(async (itemId: number) => {
    setQty(item.quantity - 1);
    const modifyListItemCart = listItemCart.map((item) => {
      if (item.item_id === itemId) {
        setDeletedItem({ item_id: itemId, qty: item.quantity - 1, action: 'DECREASE-QTY' });
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
    if (isOnline && usePromotion) {
      setRemovedItemId(item.item_id);
      const removedPromo = await isRemovedPromo(modifyListItemCart);
      if (!removedPromo) {
        onOpenConfirmRemovePromo();
      } else {
        dispatch(decrementItem(itemId));
      }
      setRemovedItemId(null);
    } else {
      dispatch(decrementItem(itemId));
    }
  }, 400);

  const deleteItem = debounce(async (itemId) => {
    const modifyListItemCart = listItemCart.map((item) => {
      if (item.item_id === itemId) {
        setDeletedItem({ item_id: itemId, qty: 0, action: 'DELETE-ITEM' });
        return { ...item, quantity: 0 };
      }
      return item;
    });
    if (isOnline && usePromotion) {
      setRemovedItemId(item.item_id);
      const removedPromo = await isRemovedPromo(modifyListItemCart);
      if (!removedPromo) {
        onOpenConfirmRemovePromo();
      } else {
        dispatch(removeItem(itemId));
      }
      setRemovedItemId(null);
    } else {
      dispatch(removeItem(itemId));
    }
  }, 400);

  React.useEffect(() => {
    if (rollbackQtyItem === item.item_id) {
      setQty(item.quantity);
      setRollbackQtyItem(null);
    } else if (!rollbackQtyItem) {
      setQty(item.quantity);
    }
  }, [rollbackQtyItem, item]);

  return (
    <Box position='relative' w='full'>
      <Box
        border='1px'
        borderColor={isNotFound ? 'red.500' : 'gray.200'}
        w='full'
        p='12px'
        borderRadius='4px'
        {...rest}
      >
        <Flex justifyContent='space-between' className='space-x-2' mb='5px'>
          <Tooltip label={item?.item_name} hasArrow bg='jubelio.black'>
            <Box fontWeight='bold' isTruncated w='60%'>
              <Title>{item?.item_name}</Title>
            </Box>
          </Tooltip>
          <Flex w='full' justifyContent='flex-end' alignItems='center'>
            <Box>
              {item.list_price_book && item.list_price_book.length > 0 && (
                <TooltipPrice
                  placement='bottom-end'
                  listBook={item.list_price_book as PriceBook[]}
                  sellPrice={Number(item.normal_price)}
                />
              )}
            </Box>
            <Title fontSize='14px' color='jubelio.purple'>
              {item.is_free
                ? 'Free'
                : currencyFormat(
                    item.slash_price && item.pos_slash_price !== 0
                      ? Number(item.slash_price[`slash_price_item_${item.item_id}`].promotion_price)
                      : Number(item.sell_price)
                  )}
            </Title>
          </Flex>
        </Flex>
        <Flex justifyContent='space-between'>
          <SubTitle fontSize='12px'>{item.item_code}</SubTitle>
          {!item.is_free && item.pos_slash_price !== 0 && (
            <Box fontSize='12px' color='gray.600' fontWeight='normal' as='del'>
              {currencyFormat(item.sell_price as number)}
            </Box>
          )}
        </Flex>
        {(profile?.setting?.enable_batch_number || profile?.setting?.enable_serial_number) && (
          <HStack mt={2}>
            {item.use_batch_number && <BadgeProduct colorScheme='batch'>Batch</BadgeProduct>}
            {item.use_serial_number && <BadgeProduct colorScheme='serial'>Serial</BadgeProduct>}
            {item.is_bundle && <BadgeProduct colorScheme='bundle'>Bundle</BadgeProduct>}
            {!item.use_batch_number && !item.use_serial_number && !item.is_bundle && (
              <BadgeProduct colorScheme='green'>Satuan</BadgeProduct>
            )}
            {item.serial_number && Array.isArray(item.serial_number) && item.serial_number.length > 0 && (
              <TooltipSerialNumber placement='right' listSn={item.serial_number as ISerialNumber[]} />
            )}
            {item.batch_number && Array.isArray(item.batch_number) && item.batch_number.length > 0 && (
              <TooltipBatchNumber placement='right' listBn={item.batch_number as IBatchNumber[]} />
            )}
          </HStack>
        )}
        <VStack w='full' alignItems='start' mt={2}>
          {Number(totalDiscount) > 0 && (
            <ListTotal
              fontSize='12px'
              leftText={`Diskon`}
              color='gray.600'
              rightText={<Text>{`-${currencyFormat(totalDiscount)}`}</Text>}
            />
          )}
          {item.is_free && (
            <ListTotal
              fontSize='12px'
              color='gray.600'
              leftText={item.promotion_name ? item.promotion_name : `Diskon 100%`}
              rightText={<Text>{`-${currencyFormat(Number(item.sell_price))}`}</Text>}
            />
          )}
          {Number(item.tax_percent) > 0 && (
            <ListTotal
              fontSize='12px'
              color='gray.600'
              leftText={`Pajak ${item.tax_percent}%`}
              rightText={
                <Text>
                  {currencyFormat(
                    continuePayment
                      ? item.tax_amount
                      : getTaxAfterDiscount({
                          item: item,
                          promotion: listPromotionsItems,
                          discountTrx: totalDetail?.salesDiscountAmount ?? 0,
                          subtotal: totalDetail?.subTotalItem ?? 0,
                          settings,
                        })
                  )}
                </Text>
              }
            />
          )}
        </VStack>
        <Divider my={4} bg='gray.200' />
        <HStack spacing={2}>
          <InputNumber
            value={qty}
            size='sm'
            maxWidth={{ base: '30%', sm: '40%', md: '35%', lg: '25%' }}
            disabled={disableEditItem || item.is_free || isLoadingCheckout || removedItemId === item.item_id}
            textAlign='center'
            onChange={handleChangeQty}
            decrement={() => debounceDecrements(item.item_id)}
            increment={() => debounceIncrements(item.item_id)}
          />
          {isLargeScreen && !disableEditItem && (
            <>
              {!item.is_free && (
                <IconButton
                  aria-label='remove'
                  icon={<DeleteIcon color='red.800' />}
                  variant='outline'
                  size='sm'
                  borderColor='red.800'
                  onClick={() => deleteItem(item.item_id)}
                  isDisabled={isLoadingCheckout || removedItemId === item.item_id}
                />
              )}

              <IconButton
                aria-label='remove'
                borderColor='jubelio.grey100'
                icon={<EditIcon h='20px' w='20px' />}
                variant='outline'
                size='sm'
                onClick={() => onChangeItem(item)}
                isDisabled={isLoadingCheckout || removedItemId === item.item_id}
              />
            </>
          )}
          {!isLargeScreen && !disableEditItem && (
            <MenuItemAction
              onDelete={() => deleteItem(item.item_id)}
              onEdit={() => onChangeItem(item)}
              isFreeItem={item.is_free}
              isDisabled={removedItemId === item.item_id}
            />
          )}

          {removedItemId === item.item_id && (
            <IconButton
              variant='ghost'
              icon={<SpinnerIcon />}
              size='sm'
              aria-label='spinner'
              isDisabled={removedItemId === item.item_id}
            />
          )}

          <Title w='full' ml='auto' textAlign='right'>
            {currencyFormat(item.is_free ? 0 : totalAmount)}
          </Title>
        </HStack>
        {item.notes && (
          <Box w='full' bg='jubelio.cream' mt={3} rounded='4px' p='9px'>
            <Title fontSize='12px' color='jubelio.grey200'>
              Notes
            </Title>
            <SubTitle color='jubelio.black' mt='8px' fontSize='12px'>
              {item.notes}
            </SubTitle>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(CardItem);
