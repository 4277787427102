import request from 'services/http/request';
import {} from 'types/auth.types';
import {
  ClosureRequest,
  IClosureClose,
  ICurrentClosure,
  IDetailClosure,
  IRequestClose,
  PaymentFragment,
} from 'types/closure.types';

export default {
  openClosure: async (payload: ClosureRequest): Promise<ICurrentClosure> => {
    const res = await request.post('/sales/pos/v3/closures/open', payload);
    return res.data;
  },

  getCurrentClosure: async (locationId: number, registerId: number): Promise<ICurrentClosure> => {
    try {
      const res = await request.get(
        `sales/pos/v3/locations/${locationId}/register/${registerId}/closure`
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  listPaymentClosure: async (closureId: number): Promise<IDetailClosure> => {
    try {
      const res = await request.get(`sales/pos/v3/closure/${closureId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  closeClosure: async (closure: IRequestClose): Promise<IClosureClose> => {
    try {
      const response = await request.post(`sales/pos/v3/closures/close`, closure);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  transactionPayment: async (closureId: number, paymentId: number): Promise<PaymentFragment> => {
    try {
      const res = await request.get(`sales/pos/v3/closure/${closureId}/payments/${paymentId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
