import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BugsIcon: React.FC<IconProps> = (props: IconProps) => (
  <Icon viewBox='0 0 14 16' {...props}>
    <path
      d='M13.6668 4.66667H11.3252C10.9502 4 10.4335 3.41667 9.8085 3L11.1668 1.675L9.99183 0.5L8.1835 2.30833C7.40862 2.12035 6.60004 2.12035 5.82516 2.30833L4.0085 0.5L2.8335 1.675L4.1835 3C3.5585 3.41667 3.05016 4.00833 2.67516 4.66667H0.333496V6.33333H2.07516C2.02516 6.60833 2.00016 6.88333 2.00016 7.16667V8H0.333496V9.66667H2.00016V10.5C2.00016 10.7833 2.02516 11.0583 2.07516 11.3333H0.333496V13H2.67516C3.00363 13.5678 3.44083 14.0653 3.96175 14.464C4.48267 14.8627 5.07708 15.1547 5.71099 15.3235C6.34489 15.4922 7.00584 15.5343 7.65603 15.4473C8.30622 15.3604 8.93288 15.1461 9.50016 14.8167C10.2585 14.3833 10.8918 13.75 11.3252 13H13.6668V11.3333H11.9252C11.9752 11.0583 12.0002 10.7833 12.0002 10.5V9.66667H13.6668V8H12.0002V7.16667C12.0002 6.88333 11.9752 6.60833 11.9252 6.33333H13.6668V4.66667ZM10.3335 10.5C10.3335 11.3841 9.98231 12.2319 9.35718 12.857C8.73206 13.4821 7.88422 13.8333 7.00016 13.8333C6.11611 13.8333 5.26826 13.4821 4.64314 12.857C4.01802 12.2319 3.66683 11.3841 3.66683 10.5V7.16667C3.66683 6.28261 4.01802 5.43477 4.64314 4.80964C5.26826 4.18452 6.11611 3.83333 7.00016 3.83333C7.88422 3.83333 8.73206 4.18452 9.35718 4.80964C9.98231 5.43477 10.3335 6.28261 10.3335 7.16667V10.5ZM8.66683 6.33333V8H5.3335V6.33333H8.66683ZM5.3335 9.66667H8.66683V11.3333H5.3335V9.66667Z'
      fill='#8999A5'
    />
  </Icon>
);

export default BugsIcon;
