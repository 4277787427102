import { Box, Button, Fade, HStack, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { ExclamationIcon } from 'components/icons';
import { Modal } from 'components/modal';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

interface PromotionPayment {
  promotion_id: number;
  payment_name: string;
  promotion_name: string;
}

interface ModalConfirmationPromotionProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: PromotionPayment[] | string;
  cancelText: string;
  okText: string;
  onSubmit?: () => void;
  loadingSubmit?: boolean;
  isOnline?: boolean;
  errorPayment: boolean;
}

const ModalConfirmationPromotion: React.FC<ModalConfirmationPromotionProps> = (
  props: ModalConfirmationPromotionProps
) => {
  const {
    isOpen,
    onClose,
    title,
    subtitle,
    cancelText,
    okText,
    onSubmit,
    loadingSubmit,
    isOnline,
    errorPayment,
  } = props;

  return (
    <Modal isShow={isOpen} onClose={onClose} disableBackrop>
      <Modal.Body className='py-8'>
        {errorPayment ? (
          <Stack direction={['column']}>
            <Box textAlign='center'>
              <Fade in={true}>
                <ExclamationIcon h='46px' w='46px' />
                <Title mt={4}>{title}</Title>
                <Box textAlign='left' pt='2'>
                  <SubTitle>Pembayaran berikut tidak berlaku untuk promo: </SubTitle>
                  <UnorderedList pb='2'>
                    <SubTitle>
                      {Array.isArray(subtitle) &&
                        subtitle.length > 0 &&
                        subtitle.map((item, index) => {
                          return (
                            <ListItem key={index}>{`${item.promotion_name} (${item.payment_name})`}</ListItem>
                          );
                        })}
                    </SubTitle>
                  </UnorderedList>
                  <SubTitle>Lanjutkan tanpa promosi? Pembayaran akan dihapus.</SubTitle>
                </Box>
              </Fade>
            </Box>
          </Stack>
        ) : (
          <Stack direction={['column']}>
            <Box textAlign='center'>
              <Fade in={true}>
                <ExclamationIcon h='46px' w='46px' />
                <Title mt={4}>{title}</Title>
                {Array.isArray(subtitle) ? (
                  <UnorderedList>
                    {subtitle.map((item, index) => (
                      <ListItem key={index}>{`${item.promotion_name} (${item.payment_name})`}</ListItem>
                    ))}
                  </UnorderedList>
                ) : (
                  <SubTitle>{subtitle}</SubTitle>
                )}
              </Fade>
            </Box>
          </Stack>
        )}
        <HStack mt={5} justifyContent='center' spacing={5}>
          <Button
            variant='outline'
            size='md'
            w='30%'
            onClick={onSubmit}
            isLoading={loadingSubmit}
            isDisabled={loadingSubmit || !isOnline}
          >
            {okText}
          </Button>
          <Button
            variant='primary'
            size='md'
            style={{ fontSize: 14 }}
            w='30%'
            onClick={onClose}
            isDisabled={loadingSubmit}
          >
            {cancelText}
          </Button>
        </HStack>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmationPromotion;
