import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { StructSetting } from 'types/common.types';
import { ILocations } from 'types/register.types';
import { combineAddress } from 'utils/receipt';

type HeaderProps = {
  structSetting: StructSetting | null;
  location: ILocations | null;
};

const Header: React.FC<HeaderProps> = ({ structSetting, location }: HeaderProps) => {
  return (
    <Box textAlign='center' whiteSpace='pre-wrap'>
      {structSetting?.show_logo && structSetting?.logo && (
        <Image
          src={structSetting?.logo}
          height='40px'
          alt='logo'
          objectFit='cover'
          width='auto'
          mb={3}
          display='inline-block'
        />
      )}
      {structSetting?.show_store_name && <Text fontWeight='bold'>{structSetting?.store_name}</Text>}
      {structSetting?.show_tagline && <Text>{structSetting?.tagline}</Text>}
      {structSetting?.show_store_information && (
        <div>
          {structSetting?.show_store_address && structSetting?.show_store_address_full ? (
            <Text>{location ? combineAddress(location) : ''}</Text>
          ) : (
            structSetting?.show_store_address && <Text>{location?.address}</Text>
          )}
          {structSetting?.show_store_phone_number && <Text>{location?.phone}</Text>}
        </div>
      )}
    </Box>
  );
};

export default Header;
