import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ExclamationIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox='0 0 52 45' {...props}>
      <path
        d='M26 0.666992L0.333374 45.0003H51.6667L26 0.666992ZM26 10.0003L43.57 40.3337H8.43004L26 10.0003ZM23.6667 19.3337V28.667H28.3334V19.3337H23.6667ZM23.6667 33.3337V38.0003H28.3334V33.3337'
        fill='#FF9800'
      />
    </Icon>
  );
};

export default ExclamationIcon;
