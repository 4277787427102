import { IContactCategory } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * @description Get all contact category
   * @returns {Promise<IContactCategory[]>}
   * @example await contactcategory.get()
   */
  get: async (): Promise<IContactCategory[]> => {
    const category = await db.contactcategory.toArray();
    return category;
  },
  /**
   * @description Get a contact category by id
   * @param {number} id
   * @returns {Promise<IContactCategory>}
   * @example await contactcategory.findOne(1)
   */
  findOne: async (id: number): Promise<IContactCategory | undefined> => {
    const category = await db.contactcategory.where('category_id').equals(id).first();
    return category;
  },
  /**
   * @description delete a contact category by id
   * @param {number} id
   * @returns {Promise<void>}
   * @example await contactcategory.delete(1)
   */
  delete: async (id: number): Promise<void> => {
    return db.contactcategory.delete(id);
  },
  /**
   * @description Create a new contactcategory
   * @param {IContactCategory} contactcategory
   * @returns {Promise<IContactCategory>}
   * @example await contactcategory.create({ name: 'John Doe', email: 'daasda' })
   */
  add: async (category: IContactCategory): Promise<boolean> => {
    await db.contactcategory.add(category);
    return true;
  },
  /**
   * @description clear all category from database
   * @returns {Promise<void>}
   * @example await contactcategory.clear()
   */
  clear: async (): Promise<void> => {
    return db.contactcategory.clear();
  },
  /**
   * @description update a contactcategory by id
   * @param {number} id
   * @param {IContactCategory} contactcategory
   * @returns {Promise<IContactCategory>}
   * @example await contactcategory.update(1, { name: 'John Doe', email: 'daasda' })
   */
  update: async (category: IContactCategory): Promise<boolean> => {
    await db.contactcategory.update(category.category_id, category);
    return true;
  },
};
