import { Flex } from '@chakra-ui/react';
import React from 'react';

const LoadingCheckout: React.FC = () => {
  return (
    <Flex
      position='absolute'
      top={0}
      left={0}
      rounded='3px'
      textAlign='center'
      w='full'
      zIndex={5}
      h='full'
      alignItems='center'
      bg='rgba(255, 255, 255, 0.67)'
      justifyContent='center'
    ></Flex>
  );
};

export default LoadingCheckout;
