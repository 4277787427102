import { AnyAction } from '@reduxjs/toolkit';
import { combineReducers, Reducer } from 'redux';
import AsyncStorage from 'redux-persist/es/storage';

import authReducer from './auth';
import closureReducer from './closure';
import registerReducer from './registers';
import returnReducer from './return';
import salesReducer from './sales';
import settingReducer from './settings';

const appReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  sales: salesReducer,
  commons: settingReducer,
  closure: closureReducer,
  return: returnReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/loginAction') {
    // this applies to all keys defined in persistConfig(s)
    if (state.auth.currentCompany !== action.payload.currentCompany) {
      AsyncStorage.removeItem('persist:root');
      state = {} as RootState;
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof appReducer>;
