import { Box, Grid, HStack, Skeleton, StackProps, Text, VStack } from '@chakra-ui/react';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { currencyFormat } from 'utils';

interface CardClosureState extends StackProps {
  title: string;
  amount: number;
  isLoading: boolean;
  subtitle: string;
}

const CardClosure: React.FC<CardClosureState> = (props: CardClosureState) => {
  const { title, amount, isLoading, subtitle } = props;
  return (
    <Grid
      rounded='8px'
      border='1px'
      borderColor='gray.200'
      boxShadow='base'
      py={6}
      w='full'
      textAlign='left'
      flexGrow={0}
      flexShrink={{ base: 0, sm: 0, md: 1, lg: 1 }}
      flexBasis='80%'
      maxW='80%'
    >
      <HStack alignItems='flex-start'>
        <VStack align='flex-start' h='full'>
          <Box
            w='1'
            h='full'
            bg='jubelio.primary'
            className='Fill the remaining height'
            roundedRight='3xl'
          />
        </VStack>
        <VStack w='full' h='full' alignItems='flex-start' pl='15'>
          <Skeleton isLoaded={isLoading}>
            <Text fontSize='14px' color='jubelio.black'>
              {title}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={isLoading}>
            <Title fontSize='20px'>{currencyFormat(amount as unknown as number)}</Title>
          </Skeleton>
          <Skeleton isLoaded={isLoading}>
            <SubTitle color='jubelio.grey200' fontSize='12px'>
              {subtitle}
            </SubTitle>
          </Skeleton>
        </VStack>
      </HStack>
    </Grid>
  );
};

export default React.memo(CardClosure);
