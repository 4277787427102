import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react';
import React from 'react';

const BadgeInformation: React.FC<TagProps> = ({ children, ...rest }: TagProps) => {
  return (
    <Tag {...rest} rounded='0.29rem'>
      <TagLeftIcon as={InfoOutlineIcon} color='jubelio.blue' />
      <TagLabel ml={2}>{children}</TagLabel>
    </Tag>
  );
};

export default BadgeInformation;
