import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Badge,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import NotfoundIcon from 'assets/svg/Notfound.svg';
import { CardProduct } from 'components/card';
import Pagination from 'components/commons/Pagination';
import { SearchBar } from 'components/forms';
import GridDisplay from 'components/list/GridDisplay';
import { EmptySearch } from 'components/state';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { setListFreeItemV2 } from 'redux/reducer/sales';
import productsRequest from 'services/http/products.request';
import { IProductList, Variant } from 'types/products.types';
import { IGetFreeItemsV2 } from 'types/promotion.types';
import { debounce, formatFreeItemVariants, formatItemVariants, getPagination } from 'utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  freeItems: IGetFreeItemsV2;
  decreaseCountFreeItem: () => void;
  skipFreeItem: () => void;
}

const ModalFreeItemsV2: React.FC<Props> = ({
  isOpen,
  onClose,
  freeItems,
  decreaseCountFreeItem,
  skipFreeItem,
}: Props) => {
  const { promotion_id, promotion_name, promotion_tier_id, count_multiply, includeItem, excludeItem } =
    freeItems;
  const dispatch = useAppDispatch();
  const listFreeItemV2 = useAppSelector((state) => state.sales.listFreeItemV2);
  const location = useAppSelector((state) => state.register.location);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const [page, setPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [pageVariant, setPageVariant] = React.useState<number>(1);
  const [pageSizeVariant, setPageSizeVariant] = React.useState<number>(15);
  const [selectedProduct, setSelectedProduct] = React.useState<IProductList | null>(null);
  const [listVariant, setListVariant] = React.useState<Variant[]>([]);
  const [searchProduct, setSearchProduct] = React.useState<string>('');
  const [searchVariant, setSearchVariant] = React.useState<string>('');
  const [cursor, setCursor] = React.useState<number>(0);

  const countFreeItem = React.useMemo(() => {
    const filterFreeItem = listFreeItemV2.filter((p) => p.promotion_id === Number(promotion_id));
    const total = filterFreeItem
      .map((a) => Number(a.qty))
      .reduce(function (a: number, b: number) {
        return a + b;
      }, 0);
    return total;
  }, [listFreeItemV2]);

  const { data: freeItemsPromotion, isLoading: isLoadingProducts } = useQuery({
    queryKey: [
      'free-item',
      {
        location_id: location?.location_id as number,
        cursor,
        page,
        pageSize,
        q: searchProduct,
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
        includeItem,
        excludeItem,
      },
    ],
    queryFn: productsRequest.getFreeItemsPromotion,
    enabled: isOnline && isOpen,
  });

  const variantPagination = React.useMemo(() => {
    if (listVariant) {
      return getPagination(listVariant, pageVariant, pageSizeVariant);
    }
  }, [pageVariant, pageSizeVariant, listVariant]);

  const chooseVariant = (variant: Variant) => {
    dispatch(
      setListFreeItemV2({
        item_id: variant.item_id,
        qty: 1,
        promotion_id: Number(promotion_id),
        promotion_name,
        variant,
        promotion_tier_id: Number(promotion_tier_id ?? 0),
        count_multiply: count_multiply,
      })
    );
    decreaseCountFreeItem();
    onClose();
  };

  const onSearchProduct = debounce(async (value: string) => {
    if (value !== '' || value !== null) {
      setCursor(0);
      setSearchProduct(value);
      setSearchVariant('');
    } else {
      setSearchProduct('');
    }
  }, 500);

  const onSearchVariant = React.useCallback(
    debounce((query: string) => {
      setPageVariant(1);
      setSearchProduct('');
      setSearchVariant(query);
      if (selectedProduct) {
        const listVariant = formatFreeItemVariants(selectedProduct.variants);
        if (!query) {
          setListVariant(listVariant);
          return;
        }
        const items: any = [];
        const search = new RegExp(query?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        for (let i = 0; i < listVariant?.length; i++) {
          if (
            search.test(listVariant[i].item_name?.toLowerCase()) ||
            search.test(listVariant[i].item_code?.toLowerCase()) ||
            search.test(listVariant[i].display_name?.toLowerCase() ?? '') ||
            (search.test(listVariant[i].barcode?.toLowerCase()) && query !== '' && listVariant[i] !== null)
          ) {
            items.push(listVariant[i]);
          }
        }
        setListVariant(items);
      }
    }, 500),
    [selectedProduct, pageVariant, pageSizeVariant]
  );

  const chooseProduct = (product: IProductList) => {
    if (product.variants && product.variants.length > 1) {
      const items = formatItemVariants(product);
      setSelectedProduct(product);
      setListVariant(items.variants);
    } else {
      chooseVariant(product.variants[0]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={skipFreeItem} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <HStack>
            {selectedProduct && (
              <IconButton
                variant='ghost'
                aria-label='save-icon'
                icon={<ArrowBackIcon fill='jubelio.grey200' fontSize={17} />}
                onFocus={(e) => e.preventDefault()}
                onClick={() => {
                  setSelectedProduct(null);
                  setListVariant([]);
                }}
                size='xs'
              />
            )}
            <HStack>
              <div>Barang Gratis - {promotion_name} </div>
              {countFreeItem > 0 && (
                <Badge variant='subtle' colorScheme='green' size='lg'>
                  {countFreeItem} barang terpilih
                </Badge>
              )}
            </HStack>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 10 }}>
            {selectedProduct && (
              <SearchBar
                placeholder='Cari variasi'
                onSearch={(e) => onSearchVariant(e.target.value)}
                value={searchVariant}
              />
            )}
            {!selectedProduct && (
              <SearchBar
                placeholder='Cari barang'
                onSearch={(e) => onSearchProduct(e.target.value)}
                value={searchProduct}
              />
            )}
          </div>
          <VStack overflowX='auto' maxHeight='400px' spacing={2} w='full'>
            {isLoadingProducts && (
              <div className='h-auto w-full'>
                <GridDisplay isLoading={isLoadingProducts} placeholder={Array.from({ length: 25 })} />
              </div>
            )}

            {!isLoadingProducts &&
              !selectedProduct &&
              freeItemsPromotion &&
              freeItemsPromotion?.data.length === 0 && (
                <div className='w-full'>
                  <EmptySearch title='Yah!' subtitle='Barang tidak ditemukan' icons={NotfoundIcon} />
                </div>
              )}

            {selectedProduct && variantPagination && variantPagination.length === 0 && (
              <div className='w-full'>
                <EmptySearch title='Yah!' subtitle='Barang tidak ditemukan' icons={NotfoundIcon} />
              </div>
            )}

            {!isLoadingProducts &&
            !selectedProduct &&
            freeItemsPromotion &&
            freeItemsPromotion?.data.length > 0 ? (
              <div className='w-full'>
                <SimpleGrid columns={[2, 3, 4, 4, 4]} gap={4}>
                  {freeItemsPromotion?.data
                    .sort((a, b) => (a.item_name < b.item_name ? -1 : 1))
                    .map((item: any, index: number) => (
                      <CardProduct
                        key={index}
                        product={item}
                        selectItems={() => chooseProduct(item)}
                        isVariants={false}
                        itemStock={item.available}
                      />
                    ))}
                </SimpleGrid>
              </div>
            ) : null}

            {variantPagination ? (
              <div className='mb-3 w-full'>
                <SimpleGrid columns={[2, 3, 4, 4, 4]} gap={4}>
                  {variantPagination
                    .sort((a, b) => (a.item_name < b.item_name ? -1 : 1))
                    .map((item: any, index: number) => (
                      <CardProduct
                        key={index}
                        product={item}
                        selectItems={() => chooseVariant(item)}
                        isVariants={true}
                        itemStock={item.available}
                      />
                    ))}
                </SimpleGrid>
              </div>
            ) : null}
          </VStack>
          <div style={{ marginTop: 5 }}>
            <Pagination
              page={selectedProduct ? pageVariant : page}
              pageSize={selectedProduct ? pageSizeVariant : pageSize}
              useCursor={isOnline ? true : false}
              totalPage={selectedProduct ? variantPagination?.length : 1}
              hasNext={selectedProduct ? false : freeItemsPromotion?.has_more}
              hasPrev={selectedProduct ? false : freeItemsPromotion?.has_previous}
              onNextCursor={() => {
                setCursor(() =>
                  freeItemsPromotion?.has_more ? (freeItemsPromotion?.next_cursor as number) : 0
                );
              }}
              onPrevCursor={() =>
                setCursor(() =>
                  freeItemsPromotion?.has_previous ? (freeItemsPromotion.prev_cursor as number) : 0
                )
              }
              onChangePage={(e) => {
                if (selectedProduct) setPageVariant(e.selected + 1);
                else setPage(e.selected + 1);
              }}
              withTotal={false}
              onChangeRow={(e) => {
                if (selectedProduct) setPageSizeVariant(e);
                else {
                  setPage(1);
                  setPageSize(e);
                }
              }}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(ModalFreeItemsV2);
