import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import * as React from 'react';
import { IShippingInfo } from 'types/common.types';
import { currencyFormat } from 'utils';

interface PresetPopover {
  shippingCost: number;
  values: IShippingInfo;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IShippingInfo) => void;
}

export const ShippingCostConfirmation: React.FC<PresetPopover> = ({
  shippingCost,
  values,
  isOpen,
  onClose,
  onSubmit,
}: PresetPopover) => {
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='right'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button variant='primary' loadingText='Menyimpan..' type='submit'>
          Simpan
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Ongkos Kirim</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Ongkos kirim Anda {currencyFormat(shippingCost)}. Apakah Anda yakin ingin menyimpan
          pengiriman ini?{' '}
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          <ButtonGroup size='sm'>
            <Button variant='outline' onClick={onClose}>
              Batal
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                onSubmit(values);
                onClose();
              }}
            >
              Ya, simpan
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
