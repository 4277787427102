import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

interface CardVoucherProps extends BoxProps {
  isSuccess: boolean;
  title: string;
  subtitle: string;
  description?: string;
}

const CardVoucher: React.FC<CardVoucherProps> = ({
  isSuccess,
  title,
  subtitle,
  description,
  ...rest
}: CardVoucherProps) => {
  return (
    <Box
      border='1px'
      borderColor='#DADADA'
      p={5}
      rounded='4px'
      alignSelf='center'
      w='full'
      {...rest}
    >
      <Flex alignItems='center' className='space-x-7'>
        {isSuccess ? (
          <CheckCircleIcon h='43px' w='43px' color='#9DC284' />
        ) : (
          <Box bgColor='#DF4D4D' p={3} rounded={50}>
            <CloseIcon h='19px' w='22px' color='white' />
          </Box>
        )}
        <Box>
          <Text fontWeight='bold'>{title}</Text>
          <Title fontWeight='normal'>{subtitle}</Title>
          {description && <SubTitle>{description}</SubTitle>}
        </Box>
      </Flex>
    </Box>
  );
};
export default React.memo(CardVoucher);
