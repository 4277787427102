import config from 'constant';
import * as React from 'react';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import io, { Socket } from 'socket.io-client';

import { useAppSelector } from './redux';

const SOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? config.SOCKETIO_PROD
    : localStorage.getItem('is_wms_migrated') === 'pos-wms'
    ? config.SOCKETIO_WMS_PROD
    : config.SOCKETIO_PROD;

type useSocketProps = {
  socket: Socket | undefined;
};

let socket: Socket | undefined;

const useSocket = (): useSocketProps => {
  const { profile, clientId } = useAppSelector((state) => state.auth);
  const { registerInfo } = useAppSelector((state) => state.register);

  const createSocket = React.useCallback(() => {
    if (window !== undefined) {
      socket = io(`${SOCKET_URL}`, {
        transports: ['websocket'],
        query: {
          locationId: `${registerInfo?.location_id}`,
          registerId: `${registerInfo?.register_id}`,
          companyId: `${profile?.companies[0].company_id}`,
          email: `${profile?.email}`,
          token: LocalStorageService.getItem('token'),
          clientId: `${clientId}`,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (!socket) {
      createSocket();
    }
  }, [socket]);

  return { socket };
};

export default useSocket;
