import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CardTransactionPromotion } from 'components/card';
import { Input } from 'components/forms';
import FormShipment from 'components/forms/shipment/FormShipment';
import { PresetPopover } from 'components/popover/Preset';
import { ShippingCostConfirmation } from 'components/popover/ShippingCostConfirmation';
import { alert } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { updateDetailOrder, updateShippingOrder } from 'redux/reducer/sales';
import shipmentRequest from 'services/http/shipment.request';
import { db } from 'services/indexdb/connection';
import useSWR from 'swr';
import { IPresets, IShippingInfo } from 'types/common.types';
import { ISalesState, ITotalDetail } from 'types/sales.types';
import { IGetRatesData } from 'types/shipment.types';
import { mappingShippingInfo } from 'utils';

import { Modal } from '.';

interface ModalTransactionProps {
  isOpen: boolean;
  totalDetail: ITotalDetail;
  onClose: () => void;
}

const ModalTransaction: React.FC<ModalTransactionProps> = ({
  isOpen,
  totalDetail,
  onClose,
}: ModalTransactionProps) => {
  const dispatch = useAppDispatch();
  const { notification } = useNotification();
  const sales = useAppSelector((state) => state.sales);
  const { settings } = useAppSelector((state) => state.commons);
  const { location } = useAppSelector((state) => state.register);
  const [dataItem, setDataItem] = React.useState<ISalesState>(sales as unknown as ISalesState);
  const [shipmentList, setShipmentList] = React.useState<IGetRatesData[]>([]);
  const [selectedShipment, setSelectedShipment] = React.useState<IGetRatesData>({} as IGetRatesData);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const {
    onOpen: onOpenShippingCostConfirm,
    onClose: onCloseShippingCostConfirm,
    isOpen: isOpenShippingCostConfirm,
  } = useDisclosure();

  const { data: shipmentCategoryList } = useSWR(
    isOpen && 'get-shipment-category',
    shipmentRequest.getServiceCategory
  );

  const presetDiscount = useLiveQuery<IPresets[]>(() => db.presetsdiscount.toArray(), []);

  const closePopup = () => {
    onCloseShippingCostConfirm();
    onClose();
    setShipmentList([]);
    setSelectedShipment({} as IGetRatesData);
  };

  const validateShippingCost = (values: IShippingInfo) => {
    if ((!values.service_category || values.service_category === 0) && (values.use_jubelio_shipment ?? false))
      return notification('', alert.miss_service_category_shipment, 'warning', 5000);
    if ((!values.courier || values.courier === '') && (values.use_jubelio_shipment ?? false))
      return notification('', alert.miss_courier_shipment, 'warning', 5000);
    if ((values.shipping_cost ?? 0) <= 0 && (dataItem.pos_is_shipping ?? false)) onOpenShippingCostConfirm();
    else onSubmitForm(values);
  };

  const onSubmitForm = (values: IShippingInfo) => {
    const newDataItem = {
      ...dataItem,
      discount_trx_amount:
        dataItem.discount_trx_amount > Number(totalDetail.totalAfterDiscount ?? 0)
          ? Number(totalDetail.totalAfterDiscount ?? 0)
          : dataItem.discount_trx_amount,
      shippingInfo: {
        ...values,
        shipping_cost: !values.shipping_cost ? 0 : Number(values.shipping_cost),
        insurance_cost: !values.shipping_insurance ? 0 : values.insurance_cost,
      },
    };
    onSave(newDataItem);
  };

  const onSave = (values: ISalesState) => {
    closePopup();
    dispatch(updateDetailOrder(values));
  };

  const selectPresetDiscount = (value: number, type: number) => {
    if (Number(type) === 1) {
      setDataItem({
        ...dataItem,
        discount_trx_percent: value,
        isDiscountPercentTrx: true,
      });
    } else {
      setDataItem({
        ...dataItem,
        discount_trx_amount: value,
        isDiscountPercentTrx: false,
      });
    }
  };

  const getShipmentList = async (
    service: number,
    values: IShippingInfo,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    try {
      setIsLoading(false);
      if (location && (!location.subdistrict_id || location.subdistrict_id === '')) {
        return notification('', alert.miss_subdistrict, 'warning', 5000);
      }

      if (location && (!location.post_code || location.post_code === '')) {
        return notification('', alert.miss_postcode, 'warning', 5000);
      }

      if (!values.shipping_subdistrict_id || values.shipping_subdistrict_id === '') {
        return notification('', alert.miss_subdistrict_recipient, 'warning', 5000);
      }

      if (!values.shipping_post_code || values.shipping_post_code === '') {
        return notification('', alert.miss_postcode_recipient, 'warning', 5000);
      }

      const totalWeight = sales.listItemCart
        .map((a) => Number(a.package_weight) * Number(a.quantity))
        .reduce(function (a, b) {
          return a + b;
        });

      const itemsShipment = sales.listItemCart.map((item) => ({
        quantity: item.quantity,
        weight: Number(item.package_weight) || 0,
        length: Number(item.package_length) || 0,
        width: Number(item.package_width) || 0,
        height: Number(item.package_height) || 0,
        item_id: item.item_id,
      }));

      const payload = {
        origin: {
          area_id: location?.subdistrict_id ?? '',
          zipcode: location?.post_code ?? '',
        },
        destination: {
          area_id: values.shipping_subdistrict_id ?? '',
          zipcode: values.shipping_post_code ?? '',
        },
        weight: Number(totalWeight) > 0 ? totalWeight : 1,
        service_category_id: service,
        total_value: totalDetail?.subTotalItem ?? 0,
        items: itemsShipment,
      };

      console.log(payload);

      const res = await shipmentRequest.getRates(payload);
      const shipment = res.data.length > 0 ? res.data[0] : ({} as IGetRatesData);
      const findShipment =
        res.data &&
        res.data.find(
          (shipment) =>
            shipment.courier_service_name.concat(' (Jubelio Shipment)') === sales.shippingInfo?.courier
        );
      setSelectedShipment(findShipment ?? shipment);
      if (setFieldValue) {
        setFieldValue('shipping_cost', findShipment ? findShipment?.rates : shipment.rates);
        setFieldValue(
          'insurance_cost',
          findShipment ? findShipment?.shipping_insurance : shipment.shipping_insurance
        );
        setFieldValue(
          'courier',
          findShipment
            ? findShipment.courier_service_name.concat(' (Jubelio Shipment)')
            : (shipment?.courier_service_name ?? '').concat(' (Jubelio Shipment)')
        );
        setFieldValue('service_category', Number(service));
      }
      setShipmentList(res.data);
      setIsLoading(true);
    } catch (error: any) {
      setShipmentList([]);
      let message = 'Unknown Error';
      if (error) message = error.response.data.code;
      setIsLoading(true);
      return notification('', message, 'warning', 5000);
    }
  };

  const initialValues: IShippingInfo = {
    shipping_full_name: sales.shippingInfo?.shipping_full_name ?? '',
    shipping_phone: sales.shippingInfo?.shipping_phone ?? '',
    shipping_address: sales.shippingInfo?.shipping_address ?? '',
    shipping_area: sales.shippingInfo?.shipping_area ?? '',
    shipping_city: sales.shippingInfo?.shipping_city ?? '',
    shipping_city_id: sales.shippingInfo?.shipping_city_id ?? '',
    shipping_district_id: sales.shippingInfo?.shipping_district_id ?? '',
    shipping_province: sales.shippingInfo?.shipping_province ?? '',
    shipping_province_id: sales.shippingInfo?.shipping_province_id ?? '',
    shipping_post_code: sales.shippingInfo?.shipping_post_code ?? '',
    shipping_subdistrict: sales.shippingInfo?.shipping_subdistrict ?? '',
    shipping_subdistrict_id: sales.shippingInfo?.shipping_subdistrict_id ?? '',
    shipping_cost: sales.shippingInfo?.shipping_cost ?? 0,
    shipping_insurance: sales.shippingInfo?.shipping_insurance ?? false,
    insurance_cost: sales.shippingInfo?.insurance_cost ?? 0,
    courier: sales.shippingInfo?.courier ?? '',
    service_category: sales.shippingInfo?.service_category ?? 0,
    total_value: sales.shippingInfo?.total_value ?? 0,
    use_jubelio_shipment: sales.shippingInfo?.use_jubelio_shipment ?? false,
  };

  const validate = (values: IShippingInfo) => {
    const errors: Record<string, string> = {};
    const posIsShipping = dataItem.pos_is_shipping ?? false;
    if (posIsShipping) {
      if (!values.shipping_full_name) {
        errors.shipping_full_name = 'Harap masukan nama penerima';
      }
      if (!values.shipping_phone) {
        errors.shipping_phone = 'Harap masukan no. telepon';
      } else {
        if (values.shipping_phone.length < 11) errors.shipping_phone = 'No. telepon minimal 11 karakter';
      }
      if (!values.shipping_province_id) {
        errors.shipping_province_id = 'Harap pilih propinsi';
      }
      if (!values.shipping_city_id) {
        errors.shipping_city_id = 'Harap pilih kota';
      }
      if (!values.shipping_district_id) {
        errors.shipping_district_id = 'Harap pilih kecamatan';
      }
      if (!values.shipping_subdistrict_id) {
        errors.shipping_subdistrict_id = 'Harap pilih kelurahan';
      }
      if (!values.shipping_post_code) {
        errors.shipping_post_code = 'Harap masukkan kode pos';
      }
      if (!values.shipping_address) {
        errors.shipping_address = 'Harap masukkan alamat';
      }
      if (!values.use_jubelio_shipment ?? false) {
        if (!values.courier) {
          errors.courier = 'Harap pilih kurir';
        }
      }
    }

    return errors;
  };

  React.useEffect(() => {
    if (!sales.order.shipping) {
      const resultMapping = mappingShippingInfo(sales?.shippingInfo, sales?.customerInfo);
      dispatch(updateShippingOrder(resultMapping));
    }
  }, [sales.customerInfo, sales.order]);

  React.useEffect(() => {
    if (isOpen && sales) {
      setDataItem(sales);
      if (
        settings.jubelio_shipment_active &&
        (sales.shippingInfo?.use_jubelio_shipment ?? false) &&
        sales.shippingInfo &&
        sales.shippingInfo.shipping_subdistrict_id !== '' &&
        sales.shippingInfo.shipping_post_code !== ''
      )
        getShipmentList(sales.shippingInfo?.service_category ?? 1, sales.shippingInfo);
    }
  }, [isOpen, sales.shippingInfo, settings]);

  return (
    <Modal
      isShow={isOpen}
      onClose={closePopup}
      title='Ubah Transaksi'
      className='max-w-2xl'
      style={{ maxWidth: 1000 }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={validateShippingCost}
        enableReinitialize={true}
      >
        {(formikProps) => {
          const { values, handleSubmit } = formikProps;
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <HStack flexDir={{ base: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}>
                  <VStack
                    width={{ base: 'full', sm: 'full', md: '40%', lg: '45%', xl: '45%' }}
                    px={4}
                    h='full'
                    spacing={5}
                    alignSelf='flex-start'
                  >
                    {settings.allow_discount_transaction_change && (
                      <FormControl>
                        <FormLabel htmlFor='diskon' fontSize='14px'>
                          Diskon
                        </FormLabel>
                        <Flex w='full' gridGap={2}>
                          <PresetPopover
                            placement='left'
                            name='Preset'
                            presets={presetDiscount ?? []}
                            selectPreset={selectPresetDiscount}
                          />
                          <Flex w='full'>
                            <Button
                              roundedRight={0}
                              variant={!dataItem.isDiscountPercentTrx ? 'primary' : 'outline'}
                              px={4}
                              onClick={() =>
                                setDataItem({
                                  ...dataItem,
                                  isDiscountPercentTrx: false,
                                })
                              }
                            >
                              Rp
                            </Button>
                            <Button
                              roundedLeft={0}
                              roundedRight={0}
                              variant={dataItem.isDiscountPercentTrx ? 'primary' : 'outline'}
                              px={4}
                              onClick={() => setDataItem({ ...dataItem, isDiscountPercentTrx: true })}
                            >
                              %
                            </Button>
                            {dataItem.isDiscountPercentTrx ? (
                              <Input
                                width='full'
                                max={100}
                                borderLeft={0}
                                roundedLeft={0}
                                onChange={(e) =>
                                  setDataItem({
                                    ...dataItem,
                                    discount_trx_percent:
                                      e.target.value > 100
                                        ? 100
                                        : Number(e.target.value) < 0
                                        ? 0
                                        : e.target.value,
                                  })
                                }
                                value={dataItem.discount_trx_percent}
                              />
                            ) : (
                              <Input
                                width='full'
                                format='currency'
                                borderLeft={0}
                                roundedLeft={0}
                                onChange={(e) =>
                                  setDataItem({
                                    ...dataItem,
                                    discount_trx_amount: Number(e) < 0 ? 0 : e,
                                  })
                                }
                                value={dataItem.discount_trx_amount}
                              />
                            )}
                          </Flex>
                        </Flex>
                      </FormControl>
                    )}
                    <FormControl>
                      <FormLabel htmlFor='diskon' fontSize='14px'>
                        Biaya Lainnya
                      </FormLabel>
                      <Flex w='full'>
                        <Button
                          roundedRight={0}
                          variant={!dataItem.isOtherCostPercent ? 'primary' : 'outline'}
                          px={4}
                          onClick={() => setDataItem({ ...dataItem, isOtherCostPercent: false })}
                        >
                          Rp
                        </Button>
                        <Button
                          roundedLeft={0}
                          roundedRight={0}
                          variant={dataItem.isOtherCostPercent ? 'primary' : 'outline'}
                          px={4}
                          onClick={() => setDataItem({ ...dataItem, isOtherCostPercent: true })}
                        >
                          %
                        </Button>
                        {dataItem.isOtherCostPercent ? (
                          <Input
                            width='full'
                            max={100}
                            borderLeft={0}
                            roundedLeft={0}
                            onChange={(e) =>
                              setDataItem({
                                ...dataItem,
                                other_cost_percent:
                                  e.target.value > 100
                                    ? 100
                                    : Number(e.target.value) < 0
                                    ? 0
                                    : e.target.value,
                              })
                            }
                            value={dataItem.other_cost_percent}
                          />
                        ) : (
                          <Input
                            width='full'
                            format='currency'
                            borderLeft={0}
                            roundedLeft={0}
                            onChange={(e) => setDataItem({ ...dataItem, other_cost: Number(e) < 0 ? 0 : e })}
                            value={dataItem.other_cost}
                          />
                        )}
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='notes' fontSize='14px'>
                        Catatan
                      </FormLabel>
                      <Textarea
                        id='notes'
                        value={dataItem.notes_trx ?? ''}
                        name='note'
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setDataItem({ ...dataItem, notes_trx: e.target.value })
                        }
                      />
                    </FormControl>
                    <Box mt={4} w='full'>
                      {sales.discount_trx_percent > 0 && <CardTransactionPromotion />}
                    </Box>
                  </VStack>

                  <VStack
                    w={{ base: '60%', lg: '55%' }}
                    position='relative'
                    borderLeft='1px'
                    borderColor='gray.200'
                    px={4}
                    h='100%'
                    style={{ overflowY: 'auto', maxHeight: '450px' }}
                    flex={1}
                    alignSelf='flex-start'
                  >
                    <FormShipment
                      dataItem={dataItem}
                      setDataItem={setDataItem}
                      isLoading={isLoading}
                      selectedShipment={selectedShipment}
                      shipmentList={shipmentList}
                      shipmentCategoryList={shipmentCategoryList?.data || []}
                      onSelectShipment={setSelectedShipment}
                      getShipmentList={getShipmentList}
                      formikProps={formikProps}
                    />
                  </VStack>
                </HStack>
              </Modal.Body>
              <Modal.Footer>
                <HStack spacing={2}>
                  <Button onClick={closePopup} variant='outline'>
                    Batal
                  </Button>
                  <ShippingCostConfirmation
                    isOpen={isOpenShippingCostConfirm}
                    onClose={onCloseShippingCostConfirm}
                    shippingCost={Number(values.shipping_cost ?? 0)}
                    values={values}
                    onSubmit={onSubmitForm}
                  />
                </HStack>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalTransaction;
