import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const TransactionIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 24 24' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.7999 2.69971C4.30285 2.69971 3.8999 3.10265 3.8999 3.59971V20.3997C3.8999 20.7044 4.05403 20.9883 4.30948 21.1543C4.56493 21.3204 4.88703 21.3459 5.16543 21.2221L7.46399 20.2006L9.32637 21.1938C9.59108 21.335 9.90873 21.335 10.1734 21.1938L11.9999 20.2197L13.8264 21.1938C14.0911 21.335 14.4087 21.335 14.6734 21.1938L16.5358 20.2006L18.8344 21.2221C19.1128 21.3459 19.4349 21.3204 19.6903 21.1543C19.9458 20.9883 20.0999 20.7044 20.0999 20.3997V3.59971C20.0999 3.10265 19.697 2.69971 19.1999 2.69971H4.7999ZM5.6999 19.0148V4.49971H18.2999V19.0148L16.8654 18.3773C16.6121 18.2647 16.321 18.2751 16.0764 18.4056L14.2499 19.3797L12.4234 18.4056C12.1587 18.2644 11.8411 18.2644 11.5764 18.4056L9.7499 19.3797L7.92343 18.4056C7.67881 18.2751 7.38772 18.2647 7.13438 18.3773L5.6999 19.0148ZM10.0434 5.68182L10.4758 7.51916L10.5193 7.70393C10.9541 7.57114 11.4161 7.49971 11.8945 7.49971C14.4133 7.49971 16.4999 9.49011 16.4999 11.9997C16.4999 14.5093 14.4133 16.4997 11.8945 16.4997C9.90407 16.4997 8.19265 15.2625 7.55413 13.5074L9.24567 12.892C9.62451 13.9334 10.6582 14.6997 11.8945 14.6997C13.4685 14.6997 14.6999 13.4666 14.6999 11.9997C14.6999 10.5328 13.4685 9.29971 11.8945 9.29971C11.5559 9.29971 11.2324 9.35719 10.9331 9.46222L11.0008 9.74977L11.3875 11.3925L9.80789 10.7983L8.06408 10.1424L6.92304 9.71319L7.66923 8.74914L8.88804 7.17447L10.0434 5.68182Z'
    />
  </Icon>
);

export default TransactionIcon;
