import React from 'react';
import authAction from 'services/http/auth.request';
import { IUserInfo } from 'types/auth.types';

import { useAppSelector } from './redux';

type UserInfoProps = {
  userInfo: IUserInfo;
  isLoading: boolean;
};

const useGetUserInfo = (): UserInfoProps => {
  const [userInfo, setUserInfo] = React.useState<IUserInfo>({} as IUserInfo);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const authSelector = useAppSelector((state) => state.auth);

  const getUserInfo = async (wmsUrl: string) => {
    try {
      setLoading(true);
      const user = await authAction.getUserInfo(wmsUrl);
      setUserInfo(user);
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const wmsUrl = authSelector.is_wms_migrated ? 'pos-wms' : 'pos';
    getUserInfo(wmsUrl);
  }, [authSelector.is_wms_migrated]);

  return { userInfo, isLoading };
};

export default useGetUserInfo;
