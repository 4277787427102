import { ILocations } from "types/register.types";

export const maskingDataCustomer = (dataMasking: string): string => {
  if (dataMasking === '-') return dataMasking;
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(dataMasking)) {
    const email = dataMasking.split('@')[0];
    if (email.length <= 4)
      return 'x'.repeat(email.length - 1).concat(email.slice(-1), '@', dataMasking.split('@')[1]);
    else
      return 'x'.repeat(email.length - 4).concat(email.slice(-4), '@', dataMasking.split('@')[1]);
  }
  return 'x'.repeat(dataMasking.length - 4) + dataMasking.slice(-4);
};


export const combineAddress = (locations: ILocations): string => {
  let address = '';
  if (locations.address && locations.address !== '')
    address = address.concat(' ', locations.address);
  if (locations.city && locations.city !== '') address = address.concat(' ', locations.city);
  if (locations.province && locations.province !== '')
    address = address.concat(' ', locations.province);
  if (locations.post_code && locations.post_code !== '')
    address = address.concat(' ', locations.post_code);
  return address;
};