import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ClosureIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox='0 0 24 24' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.49971 3.6002C4.49971 3.10314 4.90265 2.7002 5.39971 2.7002H18.5997C19.0968 2.7002 19.4997 3.10314 19.4997 3.6002V13.2002C19.4997 13.6973 19.0968 14.1002 18.5997 14.1002H12.8997V15.3002H14.9816C14.9941 15.2999 15.0067 15.2999 15.0192 15.3002H20.3997C20.8968 15.3002 21.2997 15.7031 21.2997 16.2002V20.4002C21.2997 20.8973 20.8968 21.3002 20.3997 21.3002H3.59971C3.10265 21.3002 2.69971 20.8973 2.69971 20.4002V16.2002C2.69971 15.7031 3.10265 15.3002 3.59971 15.3002H8.98019C8.99276 15.2999 9.0053 15.2999 9.01783 15.3002H11.0997V14.1002H5.39971C4.90265 14.1002 4.49971 13.6973 4.49971 13.2002V3.6002ZM8.44348 17.1002H4.49971V19.5002H19.4997V17.1002H15.5559L15.2047 17.8027L14.9559 18.3002H14.3997H9.59971H9.04348L8.79472 17.8027L8.44348 17.1002ZM6.29971 4.5002V12.3002H17.6997V4.5002H6.29971ZM15.5854 6.68353L11.3854 10.2835L10.7526 10.8259L10.1633 10.2366L8.36331 8.43659L9.6361 7.1638L10.8468 8.37448L14.414 5.31686L15.5854 6.68353Z'
      />
    </Icon>
  );
};

export default ClosureIcon;
