import { StackProps, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import clsx from 'clsx';
import BadgeProduct from 'components/badge/BadgeProduct';
import { CircleIcon } from 'components/icons';
import { Title } from 'components/typography';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import transactionRequest from 'services/http/transaction.request';
import { DetailOrderTransaction } from 'types';
import { currencyFormatNotFraction } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

interface TableHistoryTransaksiState extends StackProps {
  isLoading: boolean;
  listTransaction: DetailOrderTransaction[] | undefined;
  setOrder: (order: DetailOrderTransaction) => void;
  searchOnline: string;
  isReturn: boolean;
}

const Badge = ({ color, children }: { color: string; children: React.ReactNode }) => (
  <BadgeProduct p={1} withIcon={false} colorScheme={color} fontSize='10px'>
    {children}
  </BadgeProduct>
);

const useOrderBadges = (order: DetailOrderTransaction, tabsIndex: number) => {
  const isReturOrder = order.hasRetur || (order.pos_return_id && Number(order.pos_return_id) > 0);

  return React.useMemo(
    () => [
      { condition: tabsIndex === 0 && isReturOrder, color: 'batch', text: 'Retur' },
      { condition: order.is_canceled, color: 'red', text: 'Batal' },
      { condition: order.pos_is_unpaid, color: 'serial', text: 'Pelunasan' },
    ],
    [order, tabsIndex]
  );
};

const OrderBadges = ({ order, tabsIndex }: { order: DetailOrderTransaction; tabsIndex: number }) => {
  const badges = useOrderBadges(order, tabsIndex);

  return (
    <>
      {badges.map(({ condition, color, text }) =>
        condition ? (
          <Badge key={text} color={color}>
            {text}
          </Badge>
        ) : null
      )}
    </>
  );
};

const TableHistoryTransaksi: React.FC<TableHistoryTransaksiState> = (props: TableHistoryTransaksiState) => {
  const { notification } = useNotification();
  const { listTransaction, setOrder, searchOnline, isReturn } = props;
  const { tabsIndex } = useAppSelector((state) => state.commons);
  const [activeKey, setActiveKey] = React.useState<number | undefined>(undefined);

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'Nomor Transaksi',
        isNumeric: false,
      },
      {
        title: 'Waktu Dibuat',
        isNumeric: false,
      },
      {
        title: 'Status',
        isNumeric: false,
      },
      {
        title: 'Telah Dibayar',
        isNumeric: true,
      },
    ],
    []
  );

  const getOrderDetail = async (data: DetailOrderTransaction, index: number) => {
    try {
      if (searchOnline !== '') {
        const order = await transactionRequest.getTransactionDetail(data.salesorder_id);
        setOrder({
          ...data,
          ...order,
          promotions: order.promotions,
          is_return: order.pos_return_id !== null && order.return_canceled !== true ? 1 : 0,
          hasRetur: order.pos_return_id !== null && order.return_canceled !== true ? true : false,
          pos_return_id: order.pos_return_id,
          pos_return_no: order.pos_return_no,
          is_paid: 1,
        } as unknown as DetailOrderTransaction);
      } else {
        setOrder(data);
      }
      setActiveKey(index);
      return;
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  const getReturnDetail = async (data: DetailOrderTransaction, index: number) => {
    try {
      if (searchOnline !== '') {
        const order = await transactionRequest.getReturnDetail(data.pos_return_id);
        const notHavePayments = [
          {
            payment_amount: parseInt(order.grand_total),
            payment_charge: 0,
            payment_fee: 0,
            payment_id: -3,
            pos_payment_id: 0,
            pos_return_id: 0,
          },
        ];
        setOrder({
          ...data,
          ...order,
          is_return: 1,
          is_paid: 1,
          payments: !order.payments ? notHavePayments : order.payments,
          promotions: [],
        } as unknown as DetailOrderTransaction);
      } else {
        setOrder(data);
      }
      setActiveKey(index);
      return;
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      return notification('', message, 'warning', 5000);
    }
  };

  const colorStatus = (order: DetailOrderTransaction): string => {
    let color = '';
    if (order.is_paid || order.pos_return_id !== 0) color = 'green.400';
    if (order.is_paid === 0) color = 'unsync.800';

    return color;
  };

  return (
    <div className='relative h-full text-sm'>
      <Table size='sm' variant='simple' w='full'>
        <Thead position='sticky' top={0} className='rounded-lg'>
          <Tr>
            {tableHeader.map((theader, index) => (
              <Th key={`table-header-${index}`} bgColor='jubelio.grey100' p={4} isNumeric={theader.isNumeric}>
                <Text as='span' fontSize={['10px', '10px', '11px', '12px']}>
                  {theader.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listTransaction &&
            listTransaction.length > 0 &&
            listTransaction.map((order, index) => (
              <Tr
                key={index}
                className={clsx('text-sm hover:cursor-pointer hover:bg-red-50', {
                  'bg-red-50': activeKey === index,
                })}
                onClick={() => (isReturn ? getReturnDetail(order, index) : getOrderDetail(order, index))}
              >
                <Td py={4}>
                  <div className='flex items-center gap-2 pb-1'>
                    <CircleIcon color={colorStatus(order)} />
                    <Title fontWeight='bold'>
                      {tabsIndex === 0 ? order.salesorder_no : order.pos_return_no}
                    </Title>
                    <div className='flex items-center gap-2'>
                      <OrderBadges order={order} tabsIndex={tabsIndex} />
                    </div>
                  </div>
                </Td>
                <Td>{convertToLocalDate(order.transaction_date)}</Td>
                <Td>
                  {order.pos_is_unpaid === true || Number(order.pos_is_unpaid) === 1 ? (
                    <Text fontWeight='bold' color='jubelio.primary'>
                      Belum Lunas
                    </Text>
                  ) : (
                    <Text fontWeight='bold' color='system.green'>
                      Lunas
                    </Text>
                  )}
                </Td>
                <Td isNumeric>
                  {currencyFormatNotFraction(
                    Math.abs(Number(isReturn ? order.grand_total : order.payment_amount))
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableHistoryTransaksi;
