import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailOrderTransaction, ICommongSetting, IPosSetting } from 'types';
import { StructSetting } from 'types/common.types';

interface ITabsIndex {
  name: string;
  index: number;
}

interface PrintSetting {
  name: string;
  value: any;
}

const initialState: ICommongSetting = {
  settings: {} as IPosSetting,
  syncTransaction: false,
  tabsIndex: 0,
  subTabsIndex: 0,
  printer: {
    printerName: '',
    printerId: 0,
    paperSize: 80,
    printCopy: 1,
  },
  printMethod: 'popup',
  structSetting: null,
  printData: null,
  freshchatCustomConfig: false,
  usePromotion: true,
  useBarcode: false,
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state: ICommongSetting, action: PayloadAction<IPosSetting>) => {
      state.settings = action.payload;
    },
    setSyncTransaction: (state: ICommongSetting, action: PayloadAction<boolean>) => {
      state.syncTransaction = action.payload;
    },
    setTabs: (state: ICommongSetting, action: PayloadAction<ITabsIndex>) => {
      return {
        ...state,
        [action.payload.name]: action.payload.index,
      };
    },
    setPrinter: (state: ICommongSetting, action: PayloadAction<PrintSetting>) => {
      return {
        ...state,
        printer: {
          ...state.printer,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    setStructSetting: (state: ICommongSetting, action: PayloadAction<StructSetting>) => {
      state.structSetting = action.payload;
    },
    setStructData: (state: ICommongSetting, action: PayloadAction<DetailOrderTransaction | null>) => {
      state.printData = action.payload;
    },
    setFreshchatCustomConfig: (state: ICommongSetting, action: PayloadAction<boolean>) => {
      state.freshchatCustomConfig = action.payload;
    },
    setUsePromotion: (state: ICommongSetting, action: PayloadAction<boolean>) => {
      state.usePromotion = action.payload;
    },
    setPrintMethod: (state: ICommongSetting, action: PayloadAction<'popup' | 'usb'>) => {
      state.printMethod = action.payload;
    },
    setUseBarcode: (state: ICommongSetting, action: PayloadAction<boolean>) => {
      state.useBarcode = action.payload;
    },
  },
});

export const {
  setSettings,
  setSyncTransaction,
  setTabs,
  setPrinter,
  setStructSetting,
  setStructData,
  setFreshchatCustomConfig,
  setUsePromotion,
  setPrintMethod,
  setUseBarcode,
} = settingSlice.actions;
export default settingSlice.reducer;
