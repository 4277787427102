import { SerialNumber } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * This method is used to create a new serialnumber.
   *
   * @param {string} data - The data of the serialnumber.
   * @returns {Promise<void>}
   * @example serialnumber.addSerialNumber('serialnumber', [{id: "J&T REG", text: "J&T REG"}])}]);
   */
  addSerialNumber: async (data: SerialNumber[]): Promise<boolean> => {
    try {
      await db.serialnumber.bulkAdd(data);
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },

  /**
   * This method is used to get all serialnumber.
   * @returns {Promise<SerialNumber>}
   * @example with async - await serialnumber.getSerialNumber()
   * @example serialnumber.getSerialNumber().then(data => console.log(data))
   */
  getAll: async (): Promise<SerialNumber[]> => {
    try {
      return db.table('serialnumber').toArray();
    } catch (error: any) {
      return error;
    }
  },

  getByItemId: async (id: number): Promise<SerialNumber[] | undefined> => {
    return db.serialnumber.where('item_id').equals(id).toArray();
  },

  clear: async (): Promise<boolean> => {
    try {
      await db.table('serialnumber').clear();
      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },
  delete: async (serial_no: string): Promise<void> => {
    await db.serialnumber.where('serial_no').equals(serial_no).delete();
  },
};
