import { Input, InputGroup, InputLeftAddon, InputProps } from '@chakra-ui/react';
import React from 'react';
import NumberFormat from 'react-number-format';

type FormInputProps = InputProps & {
  isgrouped?: boolean;
  labelGroup?: string;
  format?: 'string' | 'number' | 'currency';
  onChange?: (e: any) => void;
  className?: string;
  minWidthAddon?: string;
  disabled?: boolean;
};

const FormInput: React.FC<FormInputProps> = ({
  isgrouped = false,
  labelGroup,
  format = 'string',
  className,
  minWidthAddon = 'auto',
  disabled,
  ...rest
}: FormInputProps) => {
  return (
    <InputGroup>
      {isgrouped && (
        <InputLeftAddon
          color='gray.400'
          bg='transparent'
          borderRight='1px'
          borderColor='gray.200'
          minW={minWidthAddon}
          p={2}
          children={labelGroup}
        />
      )}
      <Input
        as={format === 'currency' ? NumberFormatCustom : Input}
        borderRadius='4px'
        isgrouped={isgrouped ? 1 : 0}
        className={className}
        disabled={disabled}
        {...rest}
      />
    </InputGroup>
  );
};

interface NumberFormatCustomProps {
  onChange: (e: any) => void;
  isgrouped?: boolean;
  className?: string;
}
export const NumberFormatCustom: React.FC<NumberFormatCustomProps> = (
  props: NumberFormatCustomProps
) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      max={10000}
      className={other.className}
      thousandSeparator='.'
      decimalSeparator=','
      isNumericString
      onValueChange={(values) => {
        onChange(values.value);
      }}
    />
  );
};

export default React.memo(FormInput);
