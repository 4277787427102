import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image as ChakraImage,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import EmptyOrder from 'assets/svg/EmptyOrder.svg';
import { Buffer } from 'buffer';
import { BugsIcon } from 'components/icons';
import SyncIcon from 'components/icons/SyncIcon';
import { ModalCustomer, ModalEmail, ModalListCustomer } from 'components/modal';
import ModalAuthCancel from 'components/modal/ModalAuthCancel';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalPaymentReference from 'components/modal/ModalPaymentReference';
import ModalRequest from 'components/modal/ModalRequest';
import ReceiptToPrint from 'components/receipt/ReceiptToPrint';
import { SubTitle, Title } from 'components/typography';
import config from 'constant';
import messages, { alert, modal } from 'constant/messages';
import { useNotification, useOrder } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import usePosService from 'hooks/usePosService';
import useReturn from 'hooks/useReturn';
import { DeepLinker } from 'lib/deeplinker';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { clearCartReturn, createOrderReturnAction, setCustomerOrderReturn } from 'redux/reducer/return';
import { clearPaymentsList, continueDownPayment, togglePopup, updateIsCheckout } from 'redux/reducer/sales';
import { setStructData } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import transactionRequest from 'services/http/transaction.request';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';
import { DetailOrderTransaction } from 'types';
import { AuthorizedForm, ICustomerInfo, ICustomerInfoInput } from 'types/common.types';
import { IOrderReturn, ItemReturn } from 'types/return.types';
import { IOrderData } from 'types/sales.types';
import { IPaymentReferenceList } from 'types/transaction.types';
import { convertToLocalDate } from 'utils';
import { generatePosreturnNo } from 'utils/closures';
import { rawCommand } from 'utils/printer/transaction-command';
import { isMobile } from 'utils/user-agent';

import ButtonDetailHistory from './ButtonDetailHistory';
import CardItem from './CardItem';
import ListDetail from './ListDetail';
import Footer from '../cart/Footer';
import HeaderCart from '../cart/HeaderCart';

interface ICardDetailProps {
  detailOrder: DetailOrderTransaction;
}

export const DetailHistory: React.FC<ICardDetailProps> = ({ detailOrder }: ICardDetailProps) => {
  const [isSendOrder, setIsSendOrder] = React.useState<boolean>(false);
  const [isCancelOrder, setIsCancelOrder] = React.useState<boolean>(false);
  const [showTotal, setShowTotal] = React.useState<boolean>(true);
  const [isLoadingResend, setIsLoadingResend] = React.useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const [isLoadingPaymentReference, setIsLoadingPaymentReference] = React.useState<boolean>(false);
  const [listPaymentReference, seListPaymentReference] = React.useState<IPaymentReferenceList[]>();
  const [isLoadingEmail, setIsLoadingEmail] = React.useState<boolean>(false);
  const [authorizedCancelUser, setAuthorizedCancelUser] = React.useState<AuthorizedForm | undefined>(
    undefined
  );

  const detailOrderHistory = useAppSelector((state) => state.sales.detailOrderHistory);
  const componentRef = React.useRef(null);

  // ** Modal Hooks
  const {
    onOpen: onOpenAddCustomer,
    onClose: onCloseAddCustomer,
    isOpen: isOpenAddCustomer,
  } = useDisclosure();
  const { onOpen: onOpenCustomer, onClose: onCloseCustomer, isOpen: isOpenCustomer } = useDisclosure();
  const { isOpen: isOpenRequest, onOpen: onOpenRequest, onClose: onCloseRequest } = useDisclosure();
  const {
    onOpen: onOpenPaymentReference,
    onClose: onClosePaymentReference,
    isOpen: isOpenPaymentReference,
  } = useDisclosure();
  const { isOpen: isOpenEmail, onOpen: onOpenEmail, onClose: onCloseEmail } = useDisclosure();
  const { onOpen: onOpenAuthCancel, onClose: onCloseAuthCancel, isOpen: isOpenAuthCancel } = useDisclosure();

  // ** Custom Hooks & Redux Selector
  const { sendOrder, cancelOrder, referencePaymentQris } = useOrder();
  const { cancelReturn, sendOrderReturn } = useReturn();
  const { notification } = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isOnline = useAppSelector((state) => state.register.isOnline);

  // ** redux selector
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const location = useAppSelector((state) => state.register.location);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const settings = useAppSelector((state) => state.commons.settings);
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const printer = useAppSelector((state) => state.commons.printer);
  const tabsIndex = useAppSelector((state) => state.commons.tabsIndex);
  const profile = useAppSelector((state) => state.auth.profile);
  const isOpenPopup = useAppSelector((state) => state.sales.isOpenPopup);

  const [email, setEmail] = React.useState<string>('');
  const [addContactFromEmail, setAddContactFromEmail] = React.useState<boolean>(false);

  const socket = usePosService();

  const handleReactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleOnBeforeGetContent(),
    onAfterPrint: () => handleAfterPrint(),
  });

  const sendFailedOrder = async (order: IOrderData) => {
    try {
      setIsSendOrder(true);
      await sendOrder(order);
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    } finally {
      setIsSendOrder(false);
    }
  };

  const sendFailedReturn = async (orderReturn: IOrderReturn) => {
    try {
      setIsSendOrder(true);
      await sendOrderReturn(orderReturn);
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    } finally {
      setIsSendOrder(false);
    }
  };

  const checkAuthorizedUser = async () => {
    try {
      const payload = {
        user_id: authorizedCancelUser?.user?.user_id ?? 0,
        permission_id: config.ACL_AUTH_CANCEL,
        pin: authorizedCancelUser?.pin ?? '',
        location_id: location?.location_id ?? 0,
      };
      await commonRequest.checkAuthorizedUser(payload);
      return '';
    } catch (error: any) {
      return error.response?.data.code;
    }
  };

  const handleCancelOrder = async (order: DetailOrderTransaction) => {
    try {
      setIsCancelOrder(true);
      if (isOnline && settings.authorization_cancel) {
        const validateAuthorizedUser = await checkAuthorizedUser();
        if (validateAuthorizedUser !== '') {
          return notification('', validateAuthorizedUser, 'warning', 5000);
        }
      }
      const res = await cancelOrder({
        ...order,
        authorized_user_id: authorizedCancelUser?.user?.user_id,
      });
      detailOrder.is_canceled = res.is_canceled;
      notification('', alert.success_cancel_order, 'success', 3000);
    } catch (error: any) {
      if (!isOnline) {
        return notification('', alert.success_cancel_order, 'success', 3000);
      }
      return notification('', error?.response?.data?.code ?? error?.response?.data?.message, 'error', 3000);
    } finally {
      setIsCancelOrder(false);
    }
  };

  const handleCancelReturn = async (order: DetailOrderTransaction) => {
    try {
      setIsCancelOrder(true);
      if (isOnline && settings.authorization_cancel) {
        const validateAuthorizedUser = await checkAuthorizedUser();
        if (validateAuthorizedUser !== '') {
          return notification('', validateAuthorizedUser, 'warning', 5000);
        }
      }
      const res = await cancelReturn({
        ...order,
        authorized_user_id: authorizedCancelUser?.user?.user_id,
      });
      detailOrder.is_canceled = res.is_canceled;
      notification('Berhasil', alert.success_cancel_order, 'success', 3000);
    } catch (error: any) {
      notification('', error?.response?.data?.code ?? error?.response?.data?.message, 'error', 3000);
    } finally {
      setIsCancelOrder(false);
    }
  };

  const handleReturnOrder = async (order: DetailOrderTransaction) => {
    const newOrder = {
      ...order,
      pos_return_no: generatePosreturnNo({
        register_code: registerInfo?.register_code as string,
        location_code: location?.location_code as string,
      }),
      register_id: registerInfo?.register_id as number,
      location_id: location?.location_id as number,
      closure_id: currentClosure?.closure_id as number,
      discount_as_service_fee: order.service_fee && Number(order.service_fee) !== 0,
    };
    dispatch(createOrderReturnAction(newOrder));
    if (order.contact_id && order.contact_id !== -1) {
      navigate('/sales/return');
    } else {
      onOpenCustomer();
    }
  };

  const handleContinuePayment = async (order: DetailOrderTransaction) => {
    dispatch(
      continueDownPayment({
        ...order,
        closure_id: currentClosure?.closure_id as number,
      })
    );
    dispatch(updateIsCheckout(true));
    navigate('/sales/checkout');
  };

  // save customer into server
  // and then save customer into local
  const addCustomerAction = async (value: ICustomerInfoInput) => {
    if (addContactFromEmail) {
      await db.order
        .where('salesorder_no')
        .equals(detailOrder.salesorder_no)
        .modify({
          contact_id: value?.contact_id ?? 0,
          contact_name: value.contact_name,
          customer_name: value.contact_name,
          contact_email: value.email,
        });
      await sendEmail(value.email ?? '');
      onCloseAddCustomer();
      return;
    }

    dispatch(setCustomerOrderReturn(value));
    onCloseAddCustomer();
    onOpenCustomer();
  };

  const sendEmail = async (email: string) => {
    try {
      const payload = {
        trx_id:
          detailOrder.is_return && detailOrder.is_return === 1
            ? detailOrder.pos_return_id
            : detailOrder.salesorder_id,
        email: email,
        is_retur: detailOrder.is_return && detailOrder.is_return === 1 ? true : false,
      };
      await transactionRequest.sendEmailReceipt(payload);
      onCloseEmail();
    } catch (error: any) {
      notification('', error.response?.data.message, 'error', 3000);
      onCloseEmail();
    }
  };

  // Select customer before user return order
  // NOTE: If order has customer data will redirect to return page with customer data
  // If order has no customer data, user can select customer from list or create new customer
  const chooseReturnCustomer = (customer: ICustomerInfo) => {
    dispatch(setCustomerOrderReturn(customer));
    onCloseCustomer();
    navigate('/sales/return');
  };

  const updateStatusSync = React.useCallback(async () => {
    onCloseRequest();
    if (detailOrder.is_return && detailOrder.is_return === 1) {
      await returnOrder.updateStatusReturn(detailOrder.pos_return_no);
    } else {
      await orders.updateStatus(detailOrder.salesorder_no);
    }
  }, [detailOrder]);

  // Resend order if order is not synced yet
  // and then update status sync to 1 in indexdb
  const resendRequest = React.useCallback(
    async (payload: string) => {
      try {
        setIsLoadingResend(true);
        if (detailOrder.is_return && detailOrder.is_return === 1) {
          await sendOrderReturn(JSON.parse(payload));
        } else {
          await sendOrder(JSON.parse(payload));
        }
        onCloseRequest();
      } catch (error) {
        notification('', alert.error_send_order, 'error', 3000);
      } finally {
        setIsLoadingResend(false);
      }
    },
    [detailOrder]
  );

  const openPaymentReference = async (amount: number, isRefresh: boolean) => {
    setIsLoadingPaymentReference(true);
    const param = {
      register_id: registerInfo?.register_id as number,
      location_id: location?.location_id as number,
      amount,
    };
    const res = await transactionRequest.getPaymentReferenceList(param);
    seListPaymentReference(res);
    setIsLoadingPaymentReference(false);

    if (!isRefresh) {
      onOpenPaymentReference();
    }
  };

  const updateQrisRefNo = React.useCallback(
    async (noRef: string) => {
      try {
        await referencePaymentQris(detailOrder, noRef);
        onClosePaymentReference();
        notification('', alert.success_reference_payment_qris, 'success', 3000);
      } catch (error) {
        onClosePaymentReference();
        notification('', alert.error_reference_payment_qris, 'error', 3000);
      } finally {
        onClosePaymentReference();
      }
    },
    [detailOrder]
  );

  // Confirmation before user cancel order
  const submitConfirmation = async () => {
    const resOrder = {
      pos_return_id: detailOrder.pos_return_id,
      salesorder_id: detailOrder.salesorder_id,
      salesorder_no: detailOrder.salesorder_no,
      pos_return_no: detailOrder.pos_return_no,
      is_cancel_return: false,
      is_cancel_order: false,
    };
    if (detailOrder.is_return === 1) {
      handleCancelReturn(detailOrder);
      setOpenConfirmation(false);
      resOrder.is_cancel_return = true;
    } else {
      handleCancelOrder(detailOrder);
      setOpenConfirmation(false);
      resOrder.is_cancel_order = true;
    }

    if (socket) socket.send(JSON.stringify({ resOrder }));
  };

  // Handle print receipt order
  // NOTE: if using chrome based browser, receipt will be printed use WebUSB API
  // if using other browser, receipt will be printed use React-To-Print
  const handlePrint = async () => {
    const data = await rawCommand({
      orderData: detailOrder,
      settingPrint: structSetting,
      location,
      profile,
      posSetting: settings,
      isReturn: tabsIndex === 0 ? false : true,
      paperSize: Number(printer?.paperSize),
      printCopy: 1,
    });

    if (isMobile()) {
      const linker = DeepLinker({
        onIgnored: function () {
          handleReactToPrint();
          return true;
        },
        onFallback: function () {
          return true;
        },
      });
      linker.openUrl(`${config.POS_APP_PRINT}/${Buffer.from(data).toString('base64')}`);
    } else if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
      handleReactToPrint();
    }

    const socket = new WebSocket(config.WS_CONNECTION);
    socket.onopen = async () => {
      socket.send(data);
    };
    socket.onmessage = (event) => {
      const res = JSON.parse(event.data);
      if (res && res.message === 'error') {
        handleReactToPrint();
      }
    };
    socket.onclose = () => {
      handleReactToPrint();
      socket.close();
    };
  };

  // Dsipatch order detail to redux before dialog print opened to prevent data not updated
  // NOTE: Sometime order detail not updated, so we need to dispatch order detail to redux before print
  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        dispatch(
          setStructData({
            ...detailOrder,
            cashier_name:
              detailOrder.user_name ||
              detailOrder.username ||
              detailOrder.cashier_name ||
              profile?.user.full_name,
          })
        );
        resolve();
      }, 1000);
    });
  }, [detailOrder]);

  // Clear state struct from redux after print success
  const handleAfterPrint = React.useCallback(() => {
    dispatch(setStructData(null));
  }, [dispatch]);

  // disable button cancel
  const disableButtonCancel = React.useMemo(() => {
    if (isCancelOrder) return true;
    if (detailOrder.is_canceled) return true;
    if (detailOrder.hasRetur && !detailOrder.return_canceled) return true;
    if (currentClosure?.closure_id !== detailOrder.closure_id) return true;
    return false;
  }, [detailOrder, isCancelOrder, currentClosure]);

  // disable button send email
  const disableButtonSendEmail = React.useMemo(() => {
    if (!isOnline) return true;
    if (isCancelOrder) return true;
    if (detailOrder.is_canceled) return true;
    if (detailOrder.hasRetur && !detailOrder.return_canceled) return true;
    return false;
  }, [detailOrder, isCancelOrder]);

  const onSaveEmail = async (newEmail: string, isUpdateEmail: boolean) => {
    try {
      setIsLoadingEmail(true);
      const payload = {
        trx_id:
          detailOrder.is_return && detailOrder.is_return === 1
            ? detailOrder.pos_return_id
            : detailOrder.salesorder_id,
        email: newEmail,
        is_retur: detailOrder.is_return && detailOrder.is_return === 1 ? true : false,
      };
      await transactionRequest.sendEmailReceipt(payload);
      if (isUpdateEmail && detailOrder.contact_id) {
        commonRequest.updateEmailContact({
          email: newEmail,
          contact_id: detailOrder.contact_id,
        });
        await db.customer.where('contact_id').equals(detailOrder.contact_id).modify({ email: newEmail });
        const checkOrder = await db.order.where('salesorder_no').equals(detailOrder.salesorder_no).first();

        if (checkOrder) {
          await db.order.where('salesorder_no').equals(detailOrder.salesorder_no).modify({
            contact_email: newEmail,
          });
        }
      }
      notification('', alert.success_send_email, 'success', 3000);
      setIsLoadingEmail(false);
      onCloseEmail();
    } catch (error: any) {
      setIsLoadingEmail(false);
      onCloseEmail();
      notification('', error.response?.data.message, 'error', 3000);
    }
  };

  const handleCloseCustomerRetur = () => {
    onCloseCustomer();
    dispatch(clearCartReturn());
  };

  const onClickCancel = () => {
    if (settings.allow_cancel && settings.authorization_cancel) {
      onOpenAuthCancel();
      return;
    }
    setOpenConfirmation(true);
  };

  React.useEffect(() => {
    if (detailOrder) setEmail(detailOrder.contact_email ?? '');
  }, [detailOrder]);

  return (
    <Flex
      bg='white'
      position='relative'
      flexDir='column'
      w='full'
      borderLeft='1px'
      borderColor='gray.200'
      roundedBottomRight='lg'
      h='full'
      overflowY='auto'
    >
      {Object.keys(detailOrder).length === 0 && (
        <Stack alignItems='center' h='full' justifyContent='center' spacing={8}>
          <ChakraImage src={EmptyOrder} display='inline-block' />
          <Box textAlign='center' w='65%'>
            <Title>Pilih Untuk Detail Transaksi</Title>
            <SubTitle fontSize='16px' mt={4}>
              Cari barang dan lihat harga dari setiap barang yang ada
            </SubTitle>
          </Box>
        </Stack>
      )}
      {Object.keys(detailOrder).length > 0 && (
        <>
          {detailOrderHistory && <HeaderCart order={detailOrder} />}
          {!detailOrderHistory && (
            <React.Fragment>
              <Flex alignItems='center' justifyContent='space-between' p={4}>
                <Box>
                  <Title fontSize='12px'>
                    {detailOrder.is_return === 1 && !detailOrder.hasRetur
                      ? detailOrder.pos_return_no
                      : detailOrder.salesorder_no}
                  </Title>
                </Box>
              </Flex>
              <Stack w='full' spacing={4} px={4}>
                <Box border='1px' borderColor='system.outline' p={5} rounded='4px' bg='system.smoke'>
                  <Flex alignItems='center' justifyContent='space-between'>
                    <Stack spacing={2}>
                      <Title>{convertToLocalDate(detailOrder.transaction_date)}</Title>
                      <SubTitle>{detailOrder.customer_name}</SubTitle>
                    </Stack>
                    <HStack>
                      <IconButton
                        icon={<SyncIcon h='25px' />}
                        aria-label='edit'
                        variant='outline'
                        isLoading={isSendOrder}
                        isDisabled={detailOrder.is_paid === 1 || !isOnline}
                        onClick={() =>
                          detailOrder.is_return === 1 && !detailOrder.hasRetur
                            ? sendFailedReturn(JSON.parse(detailOrder.request_payload ?? ''))
                            : sendFailedOrder(JSON.parse(detailOrder.request_payload ?? ''))
                        }
                      />
                      <IconButton
                        icon={<BugsIcon h='20px' w='20px' />}
                        aria-label='edit'
                        variant='outline'
                        isDisabled={detailOrder.is_paid === 1}
                        onClick={() => onOpenRequest()}
                      />
                    </HStack>
                  </Flex>
                  {detailOrder.is_return === 1 && !detailOrder.hasRetur && (
                    <>
                      <Divider my={4} />
                      <Text fontSize='14px'>
                        No. Ref:&nbsp;<b>{detailOrder.salesorder_no}</b>
                      </Text>
                    </>
                  )}
                </Box>
                <Divider my={4} />
              </Stack>
            </React.Fragment>
          )}
          <Stack minH='250px' flex={1}>
            <Box flexGrow={1} textAlign='left' overflowY='auto' h='full' w='full'>
              <div className='flex w-full flex-col space-y-3 px-3'>
                {detailOrder.items?.length > 0 ? (
                  <>
                    {detailOrder.items.map((item: ItemReturn, index: number) => (
                      <CardItem key={index} item={item} returnMode={tabsIndex} />
                    ))}
                  </>
                ) : null}
              </div>
            </Box>
            <Footer>
              <ListDetail detailOrder={detailOrder} showTotal={showTotal} onShowTotal={setShowTotal} />
              {!detailOrderHistory && (
                <ButtonDetailHistory
                  detailOrder={detailOrder}
                  tabsIndex={tabsIndex}
                  openPaymentReference={openPaymentReference}
                  handlePrint={handlePrint}
                  onOpenEmail={onOpenEmail}
                  disableButtonSendEmail={disableButtonSendEmail}
                  disableButtonCancel={disableButtonCancel}
                  onClickCancel={onClickCancel}
                  handleContinuePayment={handleContinuePayment}
                  handleReturnOrder={handleReturnOrder}
                />
              )}
            </Footer>
          </Stack>
        </>
      )}
      {/* Modal for list customer */}
      <ModalListCustomer
        title='Pilih Pelanggan'
        isOpen={isOpenCustomer}
        onClose={handleCloseCustomerRetur}
        openAddCustomer={() => {
          onOpenAddCustomer();
          onCloseCustomer();
        }}
        chooseReturnCustomer={chooseReturnCustomer}
      />
      <ModalCustomer
        isOpen={isOpenAddCustomer}
        onClose={onCloseAddCustomer}
        onSubmit={(val) => addCustomerAction(val as ICustomerInfoInput)}
        email={email}
        addFromEmail={addContactFromEmail}
      />

      {/* Popup payload request order or return  */}
      <ModalRequest
        isOpen={isOpenRequest}
        onClose={onCloseRequest}
        orderNo={
          detailOrder.is_return && detailOrder.is_return === 1 && !detailOrder.hasRetur
            ? detailOrder.pos_return_no
            : detailOrder.salesorder_no
        }
        request={JSON.stringify(
          detailOrder.request_payload && JSON.parse(detailOrder.request_payload),
          null,
          2
        )}
        updateStatus={updateStatusSync}
        errorMessage={detailOrder.errorMessage}
        saveRequest={resendRequest}
        isLoading={isLoadingResend}
      />

      {/* Popup QRIS Reference */}
      <ModalPaymentReference
        title='Referensikan Pembayaran'
        isOpen={isOpenPaymentReference}
        onClose={onClosePaymentReference}
        detailOrder={detailOrder}
        listPaymentReference={listPaymentReference ?? []}
        getPaymentReference={openPaymentReference}
        isLoading={isLoadingPaymentReference}
        updateQrisRefNo={updateQrisRefNo}
      />

      {/* Popup Confirmation */}
      <ModalConfirmation
        title={
          detailOrder.is_return === 1 && !detailOrder.hasRetur
            ? modal.title_cancel_retur
            : modal.title_cancel_transaction
        }
        isOpen={openConfirmation}
        onClose={() => setOpenConfirmation(!openConfirmation)}
        subtitle={
          detailOrder.is_return === 1 && !detailOrder.hasRetur
            ? modal.subtitle_cancel_retur
            : modal.subtitle_cancel_transaction
        }
        cancelText={modal.cancel_retur_text}
        okText={modal.ok_retur_text}
        onSubmit={submitConfirmation}
        loadingSubmit={isCancelOrder}
        isOnline={true}
      />

      <ModalEmail
        isOpen={isOpenEmail}
        onClose={onCloseEmail}
        onSubmit={onSaveEmail}
        email={email}
        allowUpdateEmail={detailOrder.contact_id !== -1}
        loading={isLoadingEmail}
        onOpenAddCustomer={onOpenAddCustomer}
        setEmail={setEmail}
        addCustomer={setAddContactFromEmail}
      />

      <Box display='none'>
        {Object.keys(detailOrder).length > 0 ? <ReceiptToPrint ref={componentRef} /> : null}
      </Box>

      <ModalConfirmation
        title={messages.modal.title_change_transaction}
        subtitle={messages.modal.change_transaction}
        cancelText='Batal'
        okText='Ya'
        isOpen={isOpenPopup ?? false}
        isOnline
        onSubmit={() => {
          dispatch(clearPaymentsList());
          dispatch(updateIsCheckout(false));
          navigate('/sales');
        }}
        onClose={() => dispatch(togglePopup(false))}
      />
      <ModalAuthCancel
        isOpen={isOpenAuthCancel}
        onClose={onCloseAuthCancel}
        isReturn={tabsIndex !== 0}
        onSubmit={setOpenConfirmation}
        setAuthorizedCancelUser={setAuthorizedCancelUser}
      />
    </Flex>
  );
};

export default DetailHistory;
