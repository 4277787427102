import { Box, Flex, FlexProps, Image, Text } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';
import { IDetailPrice } from 'types/products.types';
import { currencyFormat } from 'utils';

interface Props extends FlexProps {
  product: IDetailPrice;
  isActive?: boolean;
}

const ListProduct: React.FC<Props> = (props: Props) => {
  const { product, isActive, ...rest } = props;

  return (
    <Flex
      alignItems='center'
      {...rest}
      py={2}
      borderBottom='1px'
      px={4}
      borderColor='gray.200'
      bg={isActive ? 'jubelio.red100' : 'white'}
      _hover={{ cursor: 'pointer', bg: 'jubelio.red100' }}
    >
      <Image src={product.thumbnail ? product.thumbnail[0]?.thumbnail : ''} boxSize='56px' />
      <Box mx={4} mr='auto'>
        <Text fontSize='14px' fontWeight='bold' color='jubelio.black'>
          {product.item_name}
        </Text>
        <Text color='gray.500' fontSize='12px'>
          {product.item_code}
        </Text>
      </Box>
      <Title fontSize='14px'>{currencyFormat(Number(product.sell_price))}</Title>
    </Flex>
  );
};

export default ListProduct;
