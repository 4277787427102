import { IDiscount } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * get all discounts from IndexDB
   * @returns {Promise<IDiscount[]>}
   * @example await discounts.get();
   * @example const discounts = await discounts.get();
   */
  get: async (): Promise<IDiscount[]> => {
    return db.discount.toArray();
  },
  /**
   * save discount to IndexDB
   * @param {IDiscount} discount
   * @returns {Promise<IDiscount>}
   * @example await discounts.save(discount);
   * @example const discount = await discounts.save(discount);
   */
  add: async (discount: IDiscount): Promise<number> => {
    return db.discount.add(discount);
  },
  /**
   * Bulk save discounts to IndexDB
   * @param {IDiscount[]} discounts
   * @returns {Promise<IDiscount[]>}
   * @example await discounts.bulkSave(discounts);
   * @example const discounts = await discounts.bulkSave(discounts);
   */
  bulkAdd: async (discounts: IDiscount[]): Promise<number> => {
    return db.discount.bulkAdd(discounts);
  },
  /**
   * Delete discount in IndexDB by id
   * @param {number} id
   * @returns {Promise<void>}
   * @example await discounts.delete(id);
   * @example const discounts = await discounts.delete(id);
   */
  delete: async (id: number): Promise<void> => {
    return db.discount.delete(id);
  },
  clear: async (): Promise<void> => {
    return db.discount.clear();
  },
  updateDiscount: async (discounts: IDiscount[]): Promise<void> => {
    try {
      await db.discount.clear();
      await db.discount.bulkAdd(discounts);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
