import config from 'constant';
import salesRequest from 'services/http/sales.request';
import { db } from 'services/indexdb/connection';
import { DetailOrderTransaction } from 'types';
import { IPayments } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import { IOrderData, IOrderPayment } from 'types/sales.types';
import { IPaymentsTransaction } from 'types/transaction.types';
import { mappingOrderData, mappingPaymentContinueDP } from 'utils';

import { useAppSelector } from './redux';
import usePayments from './usePayments';

const useOrderDownPayment = () => {
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const sendStructByEmail = useAppSelector((state) => state.sales.sendStructByEmail);
  const prevPayments = useAppSelector((state) => state.sales.prevPayments);
  const listPayments = useAppSelector((state) => state.sales.listPayments);

  const { getListAllPayments } = usePayments();

  const sendOrderDownPayment = async (
    order: IOrderData,
    isSync: boolean,
    listFreeItems?: IItemCart[]
  ): Promise<DetailOrderTransaction> => {
    try {
      const payload = mappingOrderData(order, listFreeItems ?? []);
      // Process online order asynchronously
      if (isOnline) {
        processOnlineContinuePaymentAsync(order, payload, isSync);
      }
      // Save updated order to IndexedDB immediately
      await updateOrderInIndexedDB(order, payload, isSync);

      const orderFromDb = await db.order.where('salesorder_no').equals(order.salesorder_no).first();
      return Promise.resolve(orderFromDb ? orderFromDb : payload);
    } catch (error) {
      handleContinuePaymentError(order.salesorder_no, error);
      return Promise.reject(error);
    }
  };

  const updateOrderInIndexedDB = async (order: IOrderData, payload: any, isSync: boolean) => {
    const checkOrder = await db.order.where('salesorder_no').equals(payload.salesorder_no).first();
    const reqPayload = order.request_payload && JSON.parse(order.request_payload);

    let listPaymentOrder = listPayments;
    if (listPaymentOrder.length === 0) {
      listPaymentOrder = await getListAllPayments();
      listPaymentOrder = listPaymentOrder.filter((payment) =>
        payload?.payments?.find((p: IPayments) => p.payment_id === payment.payment_id)
      );
    }

    if (checkOrder && checkOrder.payments) {
      const newPayment = checkOrder.payments.map((old: IPaymentsTransaction) => ({
        salesorder_id: old.salesorder_id,
        payment_id: old.payment_id,
        payment_amount: old.payment_amount,
        payment_fee: old.payment_fee ? old.payment_fee : 0,
        payment_charge: old.payment_charge,
        notes: old.notes ? old.notes : '',
        no_ref: old.no_ref ? old.no_ref : '',
        created_date: old.created_date,
      }));

      const mappingFinalPayments = getMappingFinalPayments(payload, listPaymentOrder);
      const updatedPayments = checkOrder.payments.concat(mappingFinalPayments);
      const paidAmount = calculatePaidAmount(updatedPayments);

      if (reqPayload) {
        const reqPayloadPayments = isSync ? newPayment : reqPayload.payments.concat(order.payments);
        reqPayload.payments = reqPayloadPayments;
        reqPayload.pos_is_unpaid = order.pos_is_unpaid;
      }

      await db.order
        .where('salesorder_no')
        .equals(payload.salesorder_no)
        .modify({
          payments: updatedPayments,
          pos_is_unpaid: isOnline ? order.pos_is_unpaid : order.pos_is_unpaid === true ? 1 : 0,
          payment_amount: paidAmount,
          request_payload: reqPayload ? JSON.stringify(reqPayload) : undefined,
        });
    } else {
      const paymentPayload = prevPayments as unknown as IOrderPayment[];
      payload.payments = paymentPayload.concat(
        getMappingFinalPayments(payload, listPaymentOrder) as unknown as IOrderPayment[]
      );
      payload.pos_is_unpaid = isOnline ? order.pos_is_unpaid : order.pos_is_unpaid === true ? 1 : 0;
      await db.order.put(payload);
    }
  };

  const processOnlineContinuePaymentAsync = async (
    order: IOrderData,
    payload: Omit<IOrderData, 'other_cost'>,
    isSync?: boolean
  ) => {
    try {
      const newPayload = {
        salesorder_id: payload.salesorder_id,
        salesorder_no: payload.salesorder_no,
        closure_id: payload.closure_id,
        payments: isSync
          ? mappingPaymentContinueDP(payload?.payments?.filter((item) => item.so_payment_id === 0) ?? [])
          : payload.payments,
        contact_id: payload.contact_id ?? -1,
        customer_name: payload.customer_name ?? 'Pelanggan Umum',
        customer_email: payload.customer?.email,
        transaction_date: payload.transaction_date,
        is_send_email: sendStructByEmail,
        authorized_user_id: payload.authorized_user_id,
        is_web: true,
      };

      await salesRequest.continuePaymentOrder(newPayload);

      // Update IndexedDB with successful online payment
      await db.order
        .where('salesorder_no')
        .equals(payload.salesorder_no)
        .modify({
          is_paid: 1,
          pos_is_unpaid: order.pos_is_unpaid ?? false,
        });
    } catch (error) {
      handleContinuePaymentError(order.salesorder_no, error);
    }
  };

  const handleContinuePaymentError = (salesorderNo: string, error: any) => {
    db.order
      .where('salesorder_no')
      .equals(salesorderNo)
      .modify({ errorMessage: error.response?.data?.message || 'Unknown error' });
  };

  const getMappingFinalPayments = (
    payload: Omit<IOrderData, 'other_cost'>,
    listPayments: any[]
  ): IPaymentsTransaction[] => {
    return listPayments.map((item) => {
      const findPayment = payload?.payments?.find(
        (payment) =>
          (payment.payment_id === config.VOUCHER && payment.no_ref === item.no_ref) ||
          payment.payment_id === item.payment_id
      );
      if (findPayment) {
        return {
          ...item,
          payment_charge: findPayment.payment_charge,
          payment_amount: findPayment.payment_amount,
          so_payment_id: isOnline ? (findPayment.payment_amount ? findPayment.payment_amount : 1) : 0,
        };
      }
      return item;
    });
  };

  const calculatePaidAmount = (payments: IPaymentsTransaction[]): number => {
    return payments.reduce((acc, cur) => acc + Number(cur.payment_amount), 0);
  };

  return {
    sendOrderDownPayment,
  };
};

export default useOrderDownPayment;
