import { Flex, FlexProps } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

interface CardProps extends FlexProps {
  children: React.ReactNode;
}

const CardMain = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, ...rest }: CardProps, refForward) => {
    const isOnline = useAppSelector((state) => state.register.isOnline);
    const initSync = useAppSelector((state) => state.register.initSync);
    const stepDownload = useAppSelector((state) => state.register.stepDownload);

    return (
      <Flex
        ref={refForward}
        flexDir={{ base: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
        bg='white'
        boxShadow='main-card'
        rounded='0.50rem'
        m={3}
        h={!isOnline || initSync || stepDownload === 'done' ? 'calc(100vh - 90px)' : 'calc(100vh - 30px)'}
        position='relative'
        {...rest}
      >
        {children}
      </Flex>
    );
  }
);

export default CardMain;
