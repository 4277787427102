import {
  Button,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import * as React from 'react';
import { IPresets } from 'types/common.types';
import { currencyFormat } from 'utils';

interface PresetPopover {
  name: string;
  placement?: Placement;
  presets: IPresets[];
  selectPreset: (value: number, type: number) => void;
  isDisable?: boolean;
}

export const PresetPopover: React.FC<PresetPopover> = ({
  name,
  presets,
  placement = 'bottom',
  selectPreset,
  isDisable,
}: PresetPopover) => {
  const initRef = React.useRef<HTMLButtonElement>(null);
  return (
    <Popover placement={placement} trigger='hover'>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant='outline' isDisabled={isDisable}>
              {name}
            </Button>
          </PopoverTrigger>
          <PopoverContent _focus={{ outline: 'none' }}>
            <PopoverArrow />
            <PopoverBody>
              <SimpleGrid columns={3} spacing={4}>
                {presets &&
                  presets.map((preset, index) => (
                    <Button
                      key={index}
                      variant='outline'
                      size='sm'
                      p={4}
                      ref={initRef}
                      onClick={() => {
                        selectPreset(
                          parseInt(preset.preset_amount),
                          preset.preset_type === 'TAX' ? preset.pos_preset_id : preset.amount_type
                        );
                        onClose();
                      }}
                    >
                      {preset.preset_type === 'TAX'
                        ? preset.preset_title
                        : preset.amount_type === 1
                        ? `${parseInt(preset.preset_amount)}%`
                        : currencyFormat(Number(preset.preset_amount))}
                    </Button>
                  ))}
              </SimpleGrid>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
