import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ChatIcon = (props: IconProps): JSX.Element => (
  <Icon width='19' height='18' viewBox='0 0 19 18' {...props}>
    <path
      d='M16.1111 1H2.88889C2.38792 1 1.90748 1.19901 1.55324 1.55324C1.19901 1.90748 1 2.38792 1 2.88889V17.0556L4.27439 14.6C4.60135 14.3548 4.99902 14.2222 5.40772 14.2222H16.1111C16.6121 14.2222 17.0925 14.0232 17.4468 13.669C17.801 13.3147 18 12.8343 18 12.3333V2.88889C18 2.38792 17.801 1.90748 17.4468 1.55324C17.0925 1.19901 16.6121 1 16.1111 1V1Z'
      stroke='#8999A5'
      strokeWidth='1.88889'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);

export default ChatIcon;
