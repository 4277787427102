import { Flex, FlexProps, Text } from '@chakra-ui/react';
import * as React from 'react';

type RowsProps = FlexProps & {
  leftText: React.ReactNode;
  rightText?: React.ReactNode;
};

const Rows: React.FC<RowsProps> = ({ leftText, rightText, ...rest }) => {
  return (
    <Flex alignItems='center' justifyContent='space-between' {...rest}>
      <Text>{leftText}</Text>
      <Text fontWeight='bold'>{rightText}</Text>
    </Flex>
  );
};

export default Rows;
