import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';
import { IGetRatesData } from 'types/shipment.types';
import { currencyFormat } from 'utils';

interface Props extends FlexProps {
  shipment: IGetRatesData;
  isActive: boolean;
  disabled: boolean;
}

const ListShipment: React.FC<Props> = (props: Props) => {
  const { shipment, isActive, disabled, ...rest } = props;

  return (
    <Flex
      alignItems='center'
      {...rest}
      py={2}
      border='1px'
      px={4}
      borderColor={isActive ? 'jubelio.primary' : 'gray.300'}
      borderRadius={4}
      _hover={{ cursor: 'pointer', borderColor: 'jubelio.primary' }}
      style={{ width: '100%' }}
      as={'button'}
      type='button'
      disabled={disabled}
    >
      {/* <Image src={NotFound} boxSize='56px' /> */}
      <Box mx={4} mr='auto' textAlign={'left'}>
        <Text fontSize='14px' fontWeight='bold' color='jubelio.black'>
          {shipment.courier_service_name} ( Jubelio Shipment )
        </Text>
        <Text color='jubelio.black' fontSize='12px'>
          {shipment.courier_service_code} - {shipment.courier_service_name}
        </Text>
      </Box>
      <div style={{ textAlign: 'right' }}>
        <Title fontSize='14px' color={'#E3590B'}>
          {currencyFormat(shipment.final_rates)}
        </Title>
        {shipment.final_rates !== shipment.rates && (
          <Text fontSize='11px' as='del'>
            {currencyFormat(shipment.rates)}
          </Text>
        )}
      </div>
    </Flex>
  );
};

export default ListShipment;
