import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IQueryParams } from 'types/request.type';
import { FragmentReturn, IReturnList } from 'types/return.types';
import {
  IDetailTransaction,
  IPaymentReferenceList,
  IRedeemVoucher,
  IRequestPaymentReference,
  IResponseUpdateQrisRefNo,
  ISendEmailReceipt,
  IUpdateQrisRefNo,
  TransactionFragment,
} from 'types/transaction.types';

import request from './request';

export default {
  getTransactions: async ({
    queryKey,
  }: QueryFunctionContext<[string, IQueryParams]>): Promise<TransactionFragment> => {
    const [, params] = queryKey;
    const res = await request.get(`sales/pos/v3/orders`, { params });
    return res.data;
  },
  getTransactionDetail: async (salesOrderId: number): Promise<IDetailTransaction> => {
    try {
      const res = await request.get(`sales/pos/v3/orders/${salesOrderId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getReturn: async ({ queryKey }: QueryFunctionContext<[string, IQueryParams]>): Promise<FragmentReturn> => {
    const [, params] = queryKey;
    const res = await request.get(`sales/pos/v3/return/list`, { params });
    return res.data;
  },
  getReturnDetail: async (returnId: number): Promise<IReturnList> => {
    try {
      const res = await request.get(`sales/pos/v3/return/list/${returnId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPaymentReferenceList: async (payload: IRequestPaymentReference): Promise<IPaymentReferenceList[]> => {
    try {
      const res = await request.post(`sales/pos/v3/qris/referensi-pembayaran`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateQrisRefNo: async (payload: IUpdateQrisRefNo): Promise<IResponseUpdateQrisRefNo> => {
    try {
      const res = await request.post(`sales/pos/v3/qris/update-noref`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  sendEmailReceipt: async (payload: ISendEmailReceipt): Promise<AxiosResponse> => {
    try {
      const res = await request.post(`email-receipt/send`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  redeemVoucher: async (payload: IRedeemVoucher): Promise<AxiosResponse> => {
    try {
      const res = await request.post(`sales/pos/v3/voucher/redeem`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
