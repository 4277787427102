import { Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

const LoadingCashier: React.FC = () => {
  return (
    <Flex
      position='absolute'
      top={0}
      left={0}
      rounded='3px'
      textAlign='center'
      w='full'
      zIndex={5}
      h='full'
      alignItems='center'
      bg='rgba(255, 255, 255, 0.67)'
      justifyContent='center'
    >
      <HStack
        bg='white'
        boxShadow='md'
        py={2}
        px={4}
        rounded='4px'
        borderColor='jubelio.gray200'
        border='1px'
      >
        <Spinner color='jubelio.primary' size='sm' />
        <Text>Harap Tunggu ...</Text>
      </HStack>
    </Flex>
  );
};

export default LoadingCashier;
