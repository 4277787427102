import { useToast } from '@chakra-ui/react';
import Bowser from 'bowser';
import SalesLayout from 'components/layout/SalesLayout';
import { ToastVersion } from 'components/utils';
import { app } from 'constant/messages';
import { PosServiceProvider } from 'context/PosServiceProvider';
import {
  useCommon,
  useGetLocations,
  useGetPosSetting,
  useGetTransaction,
  useNotification,
  useSocket,
} from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { clearCache, tracker } from 'lib/helpers';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { selectLocation, setInitSync, updateOutletDiscount } from 'redux/reducer/registers';
import { setStructSetting } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import authorizedUser from 'services/indexdb/authorized-user';
import discount from 'services/indexdb/discount';
import products from 'services/indexdb/products';
import promotion from 'services/indexdb/promotion';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

import packageJson from '../../package.json';

const HomePage = () => {
  const dispatch = useAppDispatch();
  const { notification } = useNotification();
  const { getPosSetting } = useGetPosSetting();
  const { socket } = useSocket();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { getPayments, getPresets, sendFailContact } = useCommon();
  const { sendFailTransaction } = useGetTransaction();
  const { pingRegister } = useGetLocations();
  const intervalSendRequst = 1000 * 60; //  1 minute
  const intervalPingRegister = 1000 * 30; //  30 second

  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const locationState = useAppSelector((state) => state.register.location);
  const profile = useAppSelector((state) => state.auth.profile);
  const isRunSync = useAppSelector((state) => state.register.isRunSync);

  const version = LocalStorageService.getItem('version')
    ? JSON.parse(LocalStorageService.getItem('version'))
    : '';

  const location = useLocation();
  const toast = useToast();
  const toastId = 'toast-version';

  // Metadata for Openreplay
  tracker.setMetadata('name', profile?.user.full_name ?? '');
  tracker.setMetadata('email', profile?.user.email ?? '');
  tracker.setMetadata('companyName', profile?.companies[0].company_name ?? '');
  tracker.setMetadata('registerId', String(registerInfo?.register_id) ?? '');
  tracker.setMetadata('registerName', `${registerInfo?.register_name} - ${registerInfo?.register_code}`);
  tracker.setMetadata('locationId', String(locationState?.location_id) ?? '');
  tracker.setMetadata('locationName', `${locationState?.location_name} - ${locationState?.location_code}`);

  React.useEffect(() => {
    if (!isOnline) return;
    if (!socket) return;

    // update data setting
    socket.on('update-setting', () => {
      getPosSetting();
    });

    // update detail product like price, discount, etc
    // from jubelio omnichannel
    socket.on('update-items', async (val) => {
      await products.updateProductInformation(val.items);
    });

    // update discount from backoffice pos
    socket.on('update-discount', async (val) => {
      await discount.updateDiscount(val);
      notification('', app.discount_update, 'success', 4000);
    });

    // update promotion from backoffice pos
    socket.on('update-promotion', async (val) => {
      await promotion.updatePromotion(val);
      notification('', app.promotion_update, 'success', 4000);
    });

    socket.on('update-struct', async () => {
      const res = await commonRequest.structPrint(locationState?.location_id as number);
      dispatch(setStructSetting(res));
    });

    // update payment from backoffice pos
    socket.on('update-payment', async () => {
      await getPayments();
    });

    socket.on('update-presets-tax', async () => {
      await getPresets('TAX');
      notification('', app.preset_update, 'success', 4000);
    });

    socket.on('update-presets-discount', async () => {
      await getPresets('DISCOUNT');
      notification('', app.preset_update, 'success', 4000);
    });

    socket.on('update-location', async (val) => {
      await dispatch(selectLocation(val));
      notification('', app.location_update, 'success', 4000);
    });

    socket.on('discount-outlet', async (val) => {
      await dispatch(updateOutletDiscount(val));
      notification('', app.outlet_discount, 'info', 4000);
    });

    socket.on('tax-outlet', async (val) => {
      await dispatch(updateOutletDiscount(val));
      notification('', app.outlet_tax, 'info', 4000);
    });

    socket.on('update-pin-user', async (val) => {
      for (const data of val) {
        await authorizedUser.updateAuthorizedUser(data);
      }
      notification('', app.auth_user_update, 'success', 4000);
    });

    socket.on('broadcast', () => {
      toast({
        id: toastId,
        duration: 15000, // 15 second
        render: () => (
          <ToastVersion
            onClick={() => dispatch(setInitSync(true))}
            text={app.broadcast}
            buttonText='Sync Ulang'
          />
        ),
      });
    });

    return () => {
      socket
        .off('update-setting')
        .off('update-qty')
        .off('update-items')
        .off('update-discount')
        .off('update-promotion')
        .off('update-struct')
        .off('update-payment')
        .off('update-presets-tax')
        .off('update-pricebook')
        .off('update-location')
        .off('tax-outlet')
        .off('discount-outlet')
        .off('update-pin-user')
        .off('disconnect')
        .off('connect')
        .off('broadcast');
    };
  }, [isOnline, socket]);

  React.useEffect(() => {
    if (!isOnline) return;
    if (process.env.NODE_ENV === 'production' && version !== packageJson.version) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          status: 'info',
          position: 'top',
          duration: 7000, // 15 second
          render: () => <ToastVersion onClick={clearCache} text='Versi baru tersedia' />,
        });
      }
    }
  }, [location]);

  const handleSyncTransaction = async () => {
    try {
      await Promise.all([sendFailTransaction(), sendFailContact()]);
      console.info('Success Sync Transaction and Contact');
    } catch (error) {
      notification('', 'Gagal mengirim transaksi atau pelanggan', 'error', 3000);
    }
  };

  React.useEffect(() => {
    if (!isOnline) return;
    const intervalId = setInterval(() => {
      if (!isRunSync) handleSyncTransaction();
    }, intervalSendRequst); // 30000 milliseconds = 30 seconds

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handlePingRegister = async () => {
    try {
      const browser = Bowser.getParser(window.navigator.userAgent);
      await pingRegister(registerInfo?.location_id ?? 0, registerInfo?.register_id ?? 0, {
        device_id: registerInfo?.device_info?.device_id ?? localStorage.getItem('device_id') ?? '',
        device_name:
          registerInfo?.device_info?.device_name ??
          browser.getOSName().concat(' - ', packageJson.version, ' - ', browser.getBrowserName()),
        device_type: registerInfo?.device_info?.device_type ?? 'WEB',
      });
    } catch (error) {
      notification('', 'Gagal melakukan ping ke server', 'error', 3000);
    }
  };

  React.useEffect(() => {
    if (!isOnline) return;
    const intervalId = setInterval(() => {
      handlePingRegister();
    }, intervalPingRegister); // 30 seconds

    handlePingRegister();
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [isOnline]); // Empty dependency array ensures this effect runs only once on mount

  return (
    <PosServiceProvider>
      <SalesLayout>
        <Outlet />
      </SalesLayout>
    </PosServiceProvider>
  );
};

export default HomePage;
