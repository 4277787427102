import { Box, BoxProps, Image, Text } from '@chakra-ui/react';
import NotfoundSVG from 'assets/svg/Notfound.svg';
import React from 'react';

interface Props extends BoxProps {
  text?: string;
}

const NotfoundInfo: React.FC<Props> = (props: Props) => {
  const { text, ...rest } = props;
  return (
    <Box mt={8} {...rest}>
      <Image src={NotfoundSVG} boxSize='80px' display='block' m='auto' />
      <Text fontWeight='bold' mt={3}>
        &quot;{text}&quot; Tidak ditemukan
      </Text>
    </Box>
  );
};

export default NotfoundInfo;
