import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const Heading: React.FC<TextProps> = ({ children, ...rest }: TextProps) => (
  <Text fontWeight='bold' color='jubelio.black' fontSize='24px' {...rest}>
    {children}
  </Text>
);

export default Heading;
