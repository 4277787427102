import { Box } from '@chakra-ui/react';
import React from 'react';
interface FooterProps {
  children: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }: FooterProps) => {
  return (
    <Box
      bottom={0}
      left='0'
      w='full'
      px={2}
      bg='white'
      py={3}
      mt='0px !important'
      borderTop='1px'
      borderColor='gray.100'
      boxShadow='lg-custom'
      roundedBottomEnd='lg'
      zIndex={0}
    >
      {children}
    </Box>
  );
};
export default Footer;
