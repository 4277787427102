import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import { IClosureState, IPaymentClosure, ItemSold } from 'types/closure.types';
import { PickBankNote } from 'types/register.types';
import { customDateFormat } from 'utils';

const infoUser: any = JSON.parse(localStorage.getItem('infoUser') || '{}');

const initialState: IClosureState = {
  checkCloseClosure: false,
  dataClosure: [],
  notes: '',
  close: {
    closure_id: Number(LocalStorageService.getItem('closure')),
    location_id: -2,
    register_id: -1,
    user_name: infoUser && infoUser.email,
    closing_date: customDateFormat(),
    cash_initial: Number(LocalStorageService.getItem('initialCash') || 0),
    notes: '',
    payment: [],
  },
  listPayments: [],
  isBanksNote: false,
  listBanksNote: [],
  listItemSold: [],
};

export const closureSlice = createSlice({
  name: 'closure',
  initialState,
  reducers: {
    getTakeNotes: (state: IClosureState, action: PayloadAction<string>) => {
      return {
        ...state,
        notes: action.payload,
        close: {
          ...state.close,
          notes: action.payload,
        },
      };
    },
    resetClosure: (state: IClosureState) => {
      state.notes = initialState.notes;
      state.close = initialState.close;
      state.checkCloseClosure = initialState.checkCloseClosure;
      state.dataClosure = initialState.dataClosure;
      state.listBanksNote = [];
      state.isBanksNote = false;
      state.listPayments = [];
      state.listItemSold = [];
    },
    checkBanksNote: (state: IClosureState, action: PayloadAction<boolean>) => {
      state.isBanksNote = action.payload;
    },
    saveBanksNote: (state: IClosureState, action: PayloadAction<PickBankNote[]>) => {
      state.listBanksNote = action.payload;
    },
    savePaymentLists: (state: IClosureState, action: PayloadAction<IPaymentClosure[]>) => {
      state.listPayments = action.payload;
    },
    setItemSold: (state: IClosureState, action: PayloadAction<ItemSold[]>) => {
      state.listItemSold = action.payload;
    },
  },
});

export const {
  getTakeNotes,
  resetClosure,
  checkBanksNote,
  saveBanksNote,
  savePaymentLists,
  setItemSold,
} = closureSlice.actions;

export default closureSlice.reducer;
