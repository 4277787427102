import { IPosSetting } from 'types';
import { IItemCart } from 'types/products.types';
import { TransactionPromotion } from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { ISalesState, ITotalDetail } from 'types/sales.types';
import { IGetTaxAfterDiscount, Items } from 'types/transaction.types';

import {
  getItemPromotion,
  getListFreeItems,
  getListFreeItemsV2,
  getSalesPromotions,
} from './promotions';

export const DISCOUNT_TYPE = {
  Percentage: 1,
  FixedAmount: 2,
};

/**
 * Get total amount of items in cart
 * @param items
 * @example getTotalItems(items)
 */
export function getTotalItemsCart(items: IItemCart[]): number {
  return items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);
}

/**
 * Get tax after discount of item in cart
 * @param item
 * @example getTaxAfterDiscount(item)
 */
export function getItemFinalDiscount({
  item,
  promotion,
  discountTransaction,
  settings,
  subTotal,
}: {
  item: IItemCart;
  promotion: TransactionPromotion[];
  discountTransaction: number;
  settings: IPosSetting;
  subTotal: number;
}): number {
  const afterDiscount = getDiscountItem(item, promotion);
  if (settings.discount_as_service_fee) {
    return afterDiscount;
  }

  const addDisc = getAdditionalDisc(item, subTotal, discountTransaction);
  return afterDiscount + addDisc;
}

export function getAdditionalDisc(
  item: IItemCart,
  subTotal: number,
  discTransaction: number
): number {
  const totalPriceItem = getAmountItem(item);
  return (totalPriceItem / subTotal) * discTransaction;
}

/**
 * This method is used to get tax per item after
 * added aditional discount and discount transaction
 *
 * @param afterDiscount
 * @param item
 * @param setting
 * @returns
 */
export function getItemTax(afterDiscount: number, item: IItemCart, isTaxInclude: boolean): number {
  if (item.tax_percent === 0 && item.tax_amount === 0) {
    return 0;
  }
  const total = getAmountItem(item) - afterDiscount;
  const tax_amount = (total * item.tax_percent) / 100;
  return isTaxInclude ? tax_amount / (1 + item.tax_percent * 0.01) : tax_amount;
}

/**
 * Get tax after discount of item in cart
 * @param item
 * @example getTaxAfterDiscount(item)
 */
export function getTaxAfterDiscount(param: IGetTaxAfterDiscount): number {
  const itemFinalDiscount = getItemFinalDiscount({
    item: param.item,
    promotion: param.promotion,
    discountTransaction: param.discountTrx,
    settings: param.settings,
    subTotal: param.subtotal,
  });
  const tax_amount = getItemTax(itemFinalDiscount, param.item, param.item.tax_included) ?? 0;
  return Number.isNaN(tax_amount) ? 0 : tax_amount;
}

/**
 * get discount per items
 * @param item
 * @example getDiscountItem(item)
 */
export function getDiscountItem(
  item: IItemCart,
  promotions?: TransactionPromotion[],
  isSum = true,
  amountItem?: number
): number {
  let discount = 0;
  const slashPrice = item.pos_slash_price !== 0 ? item.pos_slash_price : 0;

  const promotionDiscount = getItemPromotion(item, promotions as TransactionPromotion[]);
  const totalAmount = amountItem ? amountItem : getAmountItem(item);
  if (item.discount_amount !== 0 || item.discount_percent !== 0) {
    if (item.isDiscountPercent) {
      discount =
        ((totalAmount - Number(slashPrice) - promotionDiscount) * item.discount_percent) / 100;
    } else {
      discount = item.quantity * item.discount_amount;
    }
  }

  if (isSum) {
    return Number(slashPrice ?? 0) + discount + promotionDiscount;
  } else {
    return discount;
  }
}

/**
 * get only discount per items
 * @param item
 * @example getOnlyDiscountItem(item)
 */
export function getOnlyDiscountItem(item: IItemCart, promotion: TransactionPromotion[]): number {
  let discount = 0;
  const slashPrice = item.pos_slash_price !== 0 ? item.pos_slash_price : 0;
  const promotionDiscount = getItemPromotion(item, promotion as TransactionPromotion[]);
  if (item.discount_amount !== 0 || item.discount_percent !== 0) {
    if (item.isDiscountPercent) {
      discount =
        ((getAmountItem(item) - Number(slashPrice) - Number(promotionDiscount)) *
          item.discount_percent) /
        100;
    } else {
      discount = item.discount_amount;
    }
  }

  return discount;
}

/**
 * get only slash price per items
 * @param item
 * @example getOnlySlashPriceItem(item)
 */
export function getOnlySlashPriceItem(item: IItemCart): number {
  const slashPrice = item.pos_slash_price !== 0 ? item.pos_slash_price : 0;
  return slashPrice;
}

export function getAmountItem(item: IItemCart): number {
  return Number(item.sell_price) * item.quantity;
}

/**
 * get total per item after discount and tax
 * @param item
 * @example getTotalPerItem(item)
 */
export function getTotalPerItem(
  item: IItemCart,
  promotion?: TransactionPromotion[] // Item Promotions
): number {
  const afterDiscount = getAmountItem(item) - getDiscountItem(item, promotion);
  return afterDiscount;
}

/**
 * Get total after discount of items in cart
 * @param items
 * @example getTotalAfterDiscount(items)
 */
export function getSubTotalDiscount(items: IItemCart[]): number {
  return items.reduce((total, item) => {
    const discount_amount = Number(item.discount_amount);
    return total + discount_amount + Number(item.pos_slash_price);
  }, 0);
}

/**
 * Get total after discount of items in cart
 *
 * @param items
 * @param promotions
 * @returns {number}
 * @example getTotalAfterDiscount(items, promotions)
 */
export function getSaleTotalAfterItemDiscount(
  items: IItemCart[],
  promotions?: TransactionPromotion[]
): number {
  return items.reduce((total, item) => {
    return total + getAmountItem(item) - getDiscountItem(item, promotions);
  }, 0);
}

/**
 * This method is used to get the total discount of items in cart
 * which is the sum of the discount amount of each item in cart
 * and sum of the items of each item in cart
 *
 * @param sales - sales state
 * @return {number} - total discount of items in cart
 */
export function getAllItemsDiscount(sales: ISalesState): number {
  const totalAmount = getSubTotalPrice(
    sales.listItemCart,
    getListFreeItemsV2(sales.listFreeItemV2)
  );
  const afterDiscount = getSaleTotalAfterItemDiscount(
    sales.listItemCart,
    sales.listPromotionsItems
  );

  return totalAmount - afterDiscount;
}

/**
 * This method is used to get the amount of nominal items in cart
 * whit formula (sell_price * quantity)
 *
 * @param items - list of items in cart
 * @return {number} - amount of nominal items in cart
 * @example getSubTotal(items)
 */
export function getSubTotalPrice(items: IItemCart[], freeItems: IItemCart[]): number {
  let count = 0;
  count += items.reduce((total, item) => {
    const sell_price = item.sell_price as number;
    return total + item.quantity * sell_price;
  }, 0);

  count += freeItems.reduce((total, item) => {
    return total + item.quantity * Number(item.sell_price);
  }, 0);

  return count;
}

/**
 *This method is used to get the tax amount of the goods in the cart
 * which is the result of deducting the discount on all items in the cart
 *
 * @param items
 * @example getSubTotalTax(items)
 */
export function getSubTotalTax(
  sales: ISalesState,
  settings: IPosSetting,
  location: ILocations
): number {
  const discountTransaction = getFinalTransactionDiscount(sales, location);
  const subTotal = getSubTotalPrice(
    sales.listItemCart,
    getListFreeItems(sales.listTransactionPromotion)
  );
  return sales.listItemCart.reduce((total, item) => {
    const itemDiscount = getItemFinalDiscount({
      item,
      promotion: sales.listPromotionsItems,
      discountTransaction,
      settings,
      subTotal,
    });
    return total + getItemTax(itemDiscount, item, settings.tax_included);
  }, 0);
}

/**
 * Get discount transaction
 * (sum total after discount of items in cart)
 * then (subtotal * discount_percent) / 100
 * @param items
 * @example getDiscountTransaction(items)
 */
export function getDiscountTransaction(
  totalAfterDiscount: number,
  discountOutlet: number,
  sales: ISalesState
): number {
  if (sales.discount_trx_percent === 0 && sales.discount_trx_amount === 0) return 0;
  if (!sales.isDiscountPercentTrx) return sales.discount_trx_amount;

  const total = totalAfterDiscount - discountOutlet;
  return (total * sales.discount_trx_percent) / 100;
}

/**
 * Get subtotal after discount
 * @param items
 * @example getSubTotalAfterDiscount(items)
 */
export function getSubtotalAfterDiscount(items: IItemCart[]): number {
  return items.reduce((total, item) => {
    const totalDiscount = getTotalPerItem(item);
    return total + totalDiscount;
  }, 0);
}

/**
 * Get other cost of transaction
 * @param items
 * @example getOtherCost(items)
 */
export function getOtherCost({
  items,
  sales,
  outletDisc,
  trxDiscount,
}: {
  items: IItemCart[];
  sales: ISalesState;
  outletDisc: number;
  trxDiscount: number;
}): number {
  if (sales.other_cost === 0 && sales.other_cost_percent === 0) return 0;
  if (!sales.isOtherCostPercent) return sales.other_cost;

  const totalAfterDiscountItem = getSaleTotalAfterItemDiscount(items);
  const discountTransaction = outletDisc + trxDiscount;
  const totalSales = totalAfterDiscountItem - discountTransaction;

  return (totalSales * Number(sales.other_cost_percent)) / 100;
}

/**
 * Get shipping cost of transaction
 * @example getShippingCost()
 */
export function getShippingCost(sales: ISalesState): number {
  const shipping_cost = sales.shippingInfo ? Number(sales.shippingInfo.shipping_cost) : 0;
  return shipping_cost || 0;
}

/**
 * Get shipping cost of transaction
 * @example getShippingCost()
 */
export function getInsuranceCost(sales: ISalesState): number {
  const insurance_cost =
    sales.shippingInfo && sales.shippingInfo.shipping_cost
      ? Number(sales.shippingInfo.insurance_cost)
      : 0;
  return insurance_cost || 0;
}

export function getRoundMoney(grandTotal: number): number {
  const surplusMoney = grandTotal % 100;
  return surplusMoney === 0 ? surplusMoney : 100 - surplusMoney;
}

/**
 * Get grand total amount of items in cart
 * @param items
 * @example getGrandTotal(items)
 */
export function getGrandTotal({
  sales,
  discountTrx,
  totalTax,
  discountOutlet,
  otherCost,
  salesPromotions,
}: {
  sales: ISalesState;
  discountTrx: number;
  totalTax: number;
  discountOutlet: number;
  otherCost: number;
  salesPromotions: number;
}): number {
  const afterDiscount = sales.listItemCart.reduce(
    (total, item) => getTotalPerItem(item, sales.listPromotionsItems) + total,
    0
  );
  return (
    afterDiscount +
    totalTax +
    otherCost -
    discountTrx -
    salesPromotions -
    discountOutlet +
    getShippingCost(sales) +
    getInsuranceCost(sales)
  );
}

/**
 * Get total amount of items in history transaction
 * @param items
 * @example getTotalItems(items)
 */
export function getTotalItemsHistory(items: Items[]): number {
  return items.reduce((total, item) => {
    return total + Number(item.qty_in_base);
  }, 0);
}

/**
 * Get discount of outlet after discount of items in cart
 *
 * @param items
 * @param location
 * @example getDiscountOutlet(items, location)
 */
export function getDiscountOutlet(
  totalAfterDiscount: number,
  location: ILocations,
  continuePayment?: boolean
): number {
  if (continuePayment === true) return 0;
  if (Number(location.pos_discount) <= 0) return 0;

  if (location.pos_discount_type === DISCOUNT_TYPE.Percentage) {
    const discount = (totalAfterDiscount * Number(location.pos_discount)) / 100;
    const max_discount = location.pos_discount_max;
    if (max_discount) {
      return discount > max_discount ? Number(max_discount) : Number(discount);
    }
    return Number(discount);
  }

  return Number(location.pos_discount);
}

/**
 * This method is used to get all total amount of items in cart
 * like Subtotal, Diskon Barang, Diskon Transaksi, Pajak, Kembalian, dan Grand Total
 *
 * @param sales - sales state
 * @param location - location state
 * @param settings - settings state
 * @return {ITotalDetail} - total detail of transaction
 */
export const getAmountDetailTransaction = (
  sales: ISalesState,
  location: ILocations,
  settings: IPosSetting
): ITotalDetail => {
  const subTotalItem = getSubTotalPrice(
    sales.listItemCart,
    getListFreeItems(sales.listTransactionPromotion)
  );

  const shippingCost = Number(getShippingCost(sales));
  const insuranceCost = Number(getInsuranceCost(sales));
  const totalAfterDiscount = getSaleTotalAfterItemDiscount(
    sales.listItemCart,
    sales.listPromotionsItems
  );
  const discountOutlet = getDiscountOutlet(totalAfterDiscount, location, sales.continuePayment);
  const discountTrx = getDiscountTransaction(totalAfterDiscount, discountOutlet, sales);
  const totalTax = getSubTotalTax(sales, settings, location);
  const otherCost = getOtherCost({
    items: sales.listItemCart,
    sales,
    outletDisc: discountOutlet,
    trxDiscount: discountTrx,
  });
  let salesPromotions = getSalesPromotions(sales.listPromotionAmount);
  let getTotalTransaction = getGrandTotal({
    sales,
    discountTrx,
    totalTax: settings?.tax_included ? 0 : totalTax,
    discountOutlet,
    otherCost,
    salesPromotions,
  });
  if (getTotalTransaction < 0 && sales.listPromotionAmount.length > 0) {
    salesPromotions = salesPromotions + getTotalTransaction;
    getTotalTransaction = 0;
  }

  const filterNonactivePaymentList = sales.listPayments.filter((l) =>
    settings.nonactive_round_payment
      ? !settings.nonactive_round_payment.some((dl) => l.payment_id === dl.payment_id)
      : []
  );
  const roundingValue = getRoundMoney(getTotalTransaction);
  let roundMoney = roundingValue;

  if (settings.allow_rounding) {
    if (
      settings.nonactive_round_payment &&
      settings.nonactive_round_payment.length > 0 &&
      sales.listPayments.length > 0
    ) {
      if (filterNonactivePaymentList.length === 0 && roundingValue > 0) {
        roundMoney = 0;
      }
    }
  } else {
    roundMoney = 0;
  }

  const grandTotal = getTotalTransaction + roundMoney;
  const salesDiscountAmount = getFinalTransactionDiscount(sales, location);

  return {
    subTotalItem,
    discountTrx,
    otherCost,
    grandTotal,
    roundMoney,
    getTotalTransaction,
    shippingCost,
    insuranceCost,
    discountOutlet,
    totalTax,
    discountBarang: 0,
    totalItems: 0,
    haveToPay: 0,
    subMinusTotal: { minusTotal: 0, plustotal: 0 },
    salesPromotions,
    salesDiscountAmount,
    totalAfterDiscount,
  };
};

export function getFinalTransactionDiscount(sales: ISalesState, location: ILocations): number {
  const totalAfterDiscountItems = getSaleTotalAfterItemDiscount(
    sales.listItemCart,
    sales.listPromotionsItems
  );
  const discountOutlet = getDiscountOutlet(
    totalAfterDiscountItems,
    location,
    sales.continuePayment
  );
  const discountTransaction = getDiscountTransaction(
    totalAfterDiscountItems,
    discountOutlet,
    sales
  );
  const discountPromotions = getSalesPromotions(sales.listPromotionAmount);

  return discountOutlet + discountTransaction + discountPromotions;
}

export function countSlashPrice(items: IItemCart): number {
  const slashPrice = Number(items.slash_price?.slash_price_item);
  return items.quantity * Number(items.sell_price) - Number(slashPrice);
}
