import { AccordionPanel, Box, Flex, FlexProps, Text, VStack } from '@chakra-ui/react';
import ChecklistIcon from 'components/icons/ChecklistIcon';
import ChecklistVerifiedIcon from 'components/icons/ChecklistVerifiedIcon';
import { LIST_ALL_PAYMENT_TYPE } from 'constant';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification, usePayments } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setListSelectedPromotion } from 'redux/reducer/sales';
import { IPayments } from 'types/common.types';
import { IGetPromotionData } from 'types/promotion.types';
import { currencyFormat } from 'utils';

interface Props extends FlexProps {
  activePromotion?: IGetPromotionData[];
  anotherPromotion?: IGetPromotionData[];
}

const ListPromotion: React.FC<Props> = (props: Props) => {
  const { anotherPromotion, activePromotion } = props;
  const dispatch = useDispatch();
  const { notification } = useNotification();

  const listFreeItem = useAppSelector(({ sales: { listFreeItemV2 } }) => listFreeItemV2);
  const listSelectedPromotion = useAppSelector(
    ({ sales: { listSelectedPromotion } }) => listSelectedPromotion
  );

  const { getListAllPayments } = usePayments();

  const listPayments = useLiveQuery<IPayments[]>(() => {
    return getListAllPayments();
  }, []);

  const paymentList = (paymentId: number[]): string => {
    if (paymentId.length === 0) return '';
    const payment = paymentId.map((payment_id) =>
      listPayments?.find((f) => f.payment_id === payment_id)
    );
    const result = payment
      .map((p) => {
        const paymentName = p?.payment_name;
        const typePayment = LIST_ALL_PAYMENT_TYPE.find((f) => f.payment_type === p?.payment_type);
        let paymentMethod = '';
        if (paymentName) paymentMethod = `${paymentName}-${typePayment?.name}`;
        else paymentMethod = 'Semua Metode Pembayaran';
        return paymentMethod;
      })
      .join(', ');
    return result;
  };

  const selectPromotion = (promotion: IGetPromotionData) => {
    if (listSelectedPromotion.length > 0) {
      const alreadySelected = listSelectedPromotion.some(
        (p) => p.promotion_id === promotion.promotion_id
      );
      if (alreadySelected) {
        const removeSelected = listSelectedPromotion.filter(
          (p) => p.promotion_id !== promotion.promotion_id
        );
        return dispatch(setListSelectedPromotion(removeSelected));
      }
      const filterOverlap = listSelectedPromotion.filter((p) => p.is_overlap === true);
      if (filterOverlap.length === 0 || filterOverlap[0].is_overlap !== promotion.is_overlap) {
        return notification(
          '',
          'Promosi ini tidak dapat digabungkan dengan promosi yang lain',
          'warning',
          2000
        );
      }
    }
    const updateSelectedPromotion = [...listSelectedPromotion, promotion];
    dispatch(setListSelectedPromotion(updateSelectedPromotion));
  };

  const activeSelected = (promotionId: number): boolean => {
    if (listSelectedPromotion.length > 0) {
      return listSelectedPromotion.some((p) => p.promotion_id === promotionId);
    }
    return false;
  };

  return (
    <Box width='full'>
      {activePromotion ? (
        <AccordionPanel>
          <VStack>
            {activePromotion.map((p, index) => (
              <Flex
                w='full'
                alignItems='center'
                borderRadius='md'
                border='1px'
                borderColor='gray.200'
                p='1.5'
                key={index}
              >
                <Box mr='auto' textAlign='left'>
                  <Text fontSize='14px' fontWeight='bold' color='jubelio.black'>
                    {p.promotion_name}
                  </Text>
                  <Text fontSize='12px'>
                    Metode Pembayaran : {paymentList(p.promotion_payment)}
                  </Text>
                  {p.discount_reward.map((d, index) => (
                    <Text key={index} color='jubelio.black' fontSize='12px'>
                      Diskon{' '}
                      {d.discount_type === 'percentage'
                        ? `${d.discount_amount}% - ${currencyFormat(d.total_discount)}`
                        : currencyFormat(d.discount_amount)}
                    </Text>
                  ))}
                  {listFreeItem &&
                    listFreeItem
                      .filter((f) => f.promotion_id === p.promotion_id)
                      .map((l, index) => (
                        <Text color='jubelio.black' fontSize='12px' key={index}>
                          Gratis {l.variant.item_name}
                        </Text>
                      ))}
                </Box>
                <Box textAlign='right' pr='4' pl='2'>
                  <ChecklistVerifiedIcon color='jubelio.primary' />
                </Box>
              </Flex>
            ))}
          </VStack>
        </AccordionPanel>
      ) : (
        <AccordionPanel>
          <VStack>
            {anotherPromotion?.map((p, index) => (
              <Flex
                w='full'
                alignItems='center'
                borderRadius='md'
                border='1px'
                borderColor={activeSelected(p.promotion_id) ? 'jubelio.primary' : 'gray.200'}
                _hover={{
                  cursor: 'pointer',
                  bgColor: `${activeSelected(p.promotion_id) ? 'white' : 'jubelio.grey100'}`,
                }}
                p='1.5'
                onClick={() => {
                  selectPromotion(p);
                }}
                key={index}
              >
                <Box mr='auto' textAlign='left'>
                  <Text fontSize='14px' fontWeight='bold' color='jubelio.black'>
                    {p.promotion_name}
                  </Text>
                  <Text fontSize='12px'>
                    Metode Pembayaran : {paymentList(p.promotion_payment)}
                  </Text>
                  {p.discount_reward.length > 0 &&
                    p?.discount_reward.map((d, index) => (
                      <Text key={index} color='jubelio.black' fontSize='12px'>
                        Diskon{' '}
                        {d.discount_type === 'percentage'
                          ? `${d.discount_amount}% - ${currencyFormat(d.total_discount)}`
                          : currencyFormat(d.discount_amount)}
                      </Text>
                    ))}
                  {p.product_reward.length > 0 && (
                    <Text color='jubelio.black' fontSize='12px'>
                      Gratis Barang
                    </Text>
                  )}
                </Box>
                {activeSelected(p.promotion_id) && (
                  <Box textAlign='right' pr='4' pl='2'>
                    <ChecklistIcon color='jubelio.primary' />
                  </Box>
                )}
              </Flex>
            ))}
            {anotherPromotion?.length === 0 && (
              <Text fontSize='14px' color='jubelio.black' textAlign='center' my={10}>
                Tidak ada promosi
              </Text>
            )}
          </VStack>
        </AccordionPanel>
      )}
    </Box>
  );
};

export default ListPromotion;
