import { Button, Checkbox, FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { checkBanksNote, saveBanksNote } from 'redux/reducer/closure';
import registerRequest from 'services/http/register.request';
import { IBankNote, PickBankNote } from 'types/register.types';
import { amountBankNotes } from 'utils/closures';

import { Modal } from '.';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (cash: number) => void;
}

const ModalBanks: React.FC<ModalProps> = (props: ModalProps) => {
  const { isOpen, onClose, onSave } = props;
  const { isBanksNote, listBanksNote } = useAppSelector((state) => state.closure);
  const dispatch = useAppDispatch();

  const [cash, setCash] = React.useState(0);
  const [mapListBanks, setMapListBank] = React.useState<IBankNote[]>([]);

  const handleSave = (_cash: number, _listBanks: IBankNote[]) => {
    if (_cash < 0) _cash = 0;
    const filterBanks = _listBanks
      .map((val) => ({
        banknote_id: val.banknote_id,
        banknote_count: val.banknote_count ?? null,
        banknote_total: val.banknote_total ?? null,
        note_amount: val.note_amount,
      }))
      .filter((val) => val.banknote_count && val.banknote_count !== 0);

    if (filterBanks.length === 0) dispatch(checkBanksNote(false));
    dispatch(saveBanksNote(isBanksNote ? filterBanks : []));

    onSave(_cash);
    setMapListBank([]);
    onClose();
  };

  const handleBankNote = React.useCallback(
    (index: number, value: number, bankNote: PickBankNote) => {
      const result = mapListBanks.map((val) => {
        if (val.banknote_id === bankNote.banknote_id) {
          val.banknote_count = value ?? null;
          return val;
        }
        return val;
      });
      const filters = result.filter((val) => val.banknote_count && val.banknote_count > 0);
      const totalCash = amountBankNotes(filters);
      setCash(totalCash);
      setMapListBank(result);
    },
    [mapListBanks]
  );

  const getListBanks = async () => {
    try {
      const result = await registerRequest.getBankNotes();
      const totalCash = amountBankNotes(listBanksNote);
      const newMaps = result.data.map((val) => {
        const findBank = listBanksNote.find((bank) => bank.banknote_id === val.banknote_id);
        if (findBank) {
          return {
            ...val,
            banknote_count: findBank.banknote_count,
          };
        }

        return val;
      });
      setCash(totalCash);
      setMapListBank(newMaps);
    } catch (error: any) {
      throw Error(error);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getListBanks();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (!isBanksNote) {
      setMapListBank((state) => state.map((i) => ({ ...i, banknote_count: null })));
    }
  }, [isBanksNote]);

  return (
    <Modal
      isShow={isOpen}
      onClose={() => {
        onClose();
        setCash(0);
      }}
      disableBackrop
      title='Pecahan Uang Kas'
      className='max-w-xl'
    >
      <Modal.Body>
        <FormControl>
          <FormLabel htmlFor='initialCash'>Nilai Dihitung</FormLabel>
          <Input
            id='initialCash'
            isgrouped
            labelGroup='Rp'
            placeholder='Enter amount'
            format='currency'
            name='initialCash'
            onChange={(e) => setCash(e.replace(/,/g, ''))}
            value={cash}
            disabled={isBanksNote}
          />
        </FormControl>
        <FormControl mt={8} textAlign='center'>
          <Checkbox
            colorScheme='red'
            checked={isBanksNote}
            defaultChecked={isBanksNote}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(checkBanksNote(e.target.checked));
              setCash(0);
            }}
          >
            Masukan pecahan uang tunai
          </Checkbox>
        </FormControl>
        {isBanksNote && (
          <SimpleGrid columns={[2]} my={4} gap={2}>
            {mapListBanks &&
              mapListBanks.map((bank, index) => (
                <Input
                  key={index}
                  id={`bank-note-${index}`}
                  labelGroup={bank.note_text}
                  format='currency'
                  isgrouped
                  defaultValue={bank.banknote_count as number}
                  onChange={(e) => handleBankNote(index, Number(e), bank)}
                  name='banknotes'
                  minWidthAddon='100px'
                />
              ))}
          </SimpleGrid>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onClose();
            setCash(0);
            setMapListBank([]);
            dispatch(checkBanksNote(false));
          }}
          variant='outline'
          ml={2}
        >
          Batal
        </Button>
        <Button
          variant='primary'
          px={5}
          ml={3}
          type='submit'
          loadingText='Harap tunggu'
          isDisabled={cash === 0}
          onClick={() => handleSave(Number(cash), mapListBanks)}
        >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBanks;
