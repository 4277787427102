import trackerAxios from '@openreplay/tracker-axios';
import LoadingScreen from 'components/state/LoadingScreen';
import { COMPANY_ID } from 'constant';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { tracker } from 'lib/helpers';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { userInfo } from 'redux/reducer/auth';
import authRequest from 'services/http/auth.request';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

const AuthInit = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const profileUser = useAppSelector((state) => state.auth);

  const token = LocalStorageService.getItem('token');
  const { notification } = useNotification();
  const navigate = useNavigate();

  const INTERVAL_TEN_MINUTES = 300000;

  const [isLoading, setLoading] = React.useState<boolean>(true);

  if (COMPANY_ID.includes(authState.currentCompany)) {
    tracker.use(
      trackerAxios({
        ignoreHeaders: false,
      })
    );
    tracker.start();
    tracker.setUserID(profileUser.user?.email ?? '');
  }

  const requestUser = React.useCallback(async () => {
    try {
      const wmsUrl = authState.is_wms_migrated ? 'pos-wms' : 'pos';
      const res = await authRequest.getUserInfo(wmsUrl);
      dispatch(userInfo(res));
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        notification('', 'Session anda berakhir', 'warning', 5000);
        navigate('/login');
      }
    }
  }, [token, authState.is_wms_migrated]);

  React.useEffect(() => {
    requestUser();
  }, [token]);

  React.useEffect(() => {
    const reqUserInfo = setInterval(() => requestUser(), INTERVAL_TEN_MINUTES);
    return () => {
      clearInterval(reqUserInfo);
    };
  }, [token]);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return <React.Fragment>{isLoading ? <LoadingScreen /> : <Outlet />}</React.Fragment>;
};

export default AuthInit;
