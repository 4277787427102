import axios, { AxiosError, AxiosResponse } from 'axios';
import config from 'constant';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

import { WMS_API } from './wms-request';

export const API_URL = config.API_URL;

function getBaseUrl() {
  const isWms = LocalStorageService.getItem('is_pos_wms') === 'pos-wms';
  return isWms ? WMS_API : API_URL;
}

const fetchApi = axios.create({
  baseURL: API_URL,
  timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT) || 30000, // request timeout 30s default
});

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    if (error.config && error.response && error.response.status === 401) {
      window.location.href = '/';
      LocalStorageService.clear();
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

fetchApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.baseURL = getBaseUrl();
    config.headers['Authorization'] = token;
    return config;
  },
  (error) => Promise.reject(error)
);
fetchApi.interceptors.response.use(onResponse, onResponseError);

export default fetchApi;
