import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import * as React from 'react';
import { IShippingBopis } from 'types/bopis.types';
import { currencyFormat } from 'utils';

interface PresetPopover {
  shippingCost: number;
  values: IShippingBopis;
  isOpen: boolean;
  loadingSubmit: boolean;
  onClose: () => void;
  onSubmit: (values: IShippingBopis) => void;
}

export const ShippingCostConfirmationBopis: React.FC<PresetPopover> = ({
  shippingCost,
  values,
  isOpen,
  loadingSubmit,
  onClose,
  onSubmit,
}: PresetPopover) => {
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='right'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          variant='primary'
          loadingText='Menyimpan..'
          type='submit'
          isDisabled={loadingSubmit}
          isLoading={loadingSubmit}
        >
          Simpan
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Ongkos Kirim</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Ongkos kirim Anda {currencyFormat(shippingCost)}. Apakah Anda yakin ingin menyimpan pengiriman ini?{' '}
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          <ButtonGroup size='sm'>
            <Button variant='outline' onClick={onClose} isDisabled={loadingSubmit}>
              Batal
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                onSubmit(values);
                onClose();
              }}
              isDisabled={loadingSubmit}
              isLoading={loadingSubmit}
            >
              Ya, simpan
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
