import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface CardProps extends BoxProps {
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ children, ...rest }: CardProps) => {
  return (
    <Box
      bg='white'
      color='gray.600'
      p={4}
      rounded='4px'
      border='1px'
      boxShadow='main-card'
      borderColor='gray.200'
      role='group'
      transform='auto-gpu'
      transitionDuration='fast'
      transitionProperty='common'
      transitionTimingFunction='ease-out'
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
