import { ICourier } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * This method is used to create a new courier.
   *
   * @param {string} data - The data of the courier.
   * @returns {Promise<void>}
   * @example courier.addCourier('courier', [{id: "J&T REG", text: "J&T REG"}])}]);
   */
  addCourier: async (data: ICourier[]): Promise<boolean> => {
    try {
      await db.courier.bulkAdd(data);
      return Promise.resolve(true);
    } catch (error: any) {
      return Promise.reject(error);
    }
  },

  /**
   * This method is used to get all courier.
   * @returns {Promise<ICourier>}
   * @example with async - await courier.getCourier()
   * @example courier.getCourier().then(data => console.log(data))
   */
  getAll: async (): Promise<ICourier[]> => {
    try {
      return db.table('courier').toArray();
    } catch (error: any) {
      return error;
    }
  },

  clear: async (): Promise<boolean> => {
    try {
      await db.table('courier').clear();
      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },
};
