import { Box } from '@chakra-ui/react';
import React from 'react';

const CardProductSkeleton: React.FC = () => {
  return (
    <Box bg='white' rounded='4px' _hover={{ cursor: 'pointer' }} w='full'>
      {/* <Skeleton height='150px' roundedTop='md' /> */}
      <div className='h-[150px] w-full animate-pulse rounded-md bg-gray-200'></div>
      <div className='animate-pulse py-2'>
        <div className='mb-1 h-[10px] w-full animate-pulse rounded-full bg-gray-200'></div>
        <div className='h-[10px] w-[100px] animate-pulse rounded-full bg-gray-200'></div>
      </div>
    </Box>
  );
};

export default React.memo(CardProductSkeleton);
