import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MoreIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox='0 0 14 4' {...props}>
      <path
        fill='#8999A5'
        d='M10.3333 2.00004C10.3333 1.55801 10.5089 1.13409 10.8215 0.82153C11.1341 0.508969 11.558 0.333374 12 0.333374C12.442 0.333374 12.866 0.508969 13.1785 0.82153C13.4911 1.13409 13.6667 1.55801 13.6667 2.00004C13.6667 2.44207 13.4911 2.86599 13.1785 3.17855C12.866 3.49111 12.442 3.66671 12 3.66671C11.558 3.66671 11.1341 3.49111 10.8215 3.17855C10.5089 2.86599 10.3333 2.44207 10.3333 2.00004ZM5.33334 2.00004C5.33334 1.55801 5.50893 1.13409 5.82149 0.82153C6.13405 0.508969 6.55797 0.333374 7 0.333374C7.44203 0.333374 7.86595 0.508969 8.17851 0.82153C8.49107 1.13409 8.66667 1.55801 8.66667 2.00004C8.66667 2.44207 8.49107 2.86599 8.17851 3.17855C7.86595 3.49111 7.44203 3.66671 7 3.66671C6.55797 3.66671 6.13405 3.49111 5.82149 3.17855C5.50893 2.86599 5.33334 2.44207 5.33334 2.00004ZM0.333336 2.00004C0.333336 1.55801 0.508931 1.13409 0.821491 0.82153C1.13405 0.508969 1.55797 0.333374 2 0.333374C2.44203 0.333374 2.86595 0.508969 3.17851 0.82153C3.49107 1.13409 3.66667 1.55801 3.66667 2.00004C3.66667 2.44207 3.49107 2.86599 3.17851 3.17855C2.86595 3.49111 2.44203 3.66671 2 3.66671C1.55797 3.66671 1.13405 3.49111 0.821491 3.17855C0.508931 2.86599 0.333336 2.44207 0.333336 2.00004V2.00004Z'
      />
    </Icon>
  );
};

export default MoreIcon;
