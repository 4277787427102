import { Avatar, Box, BoxProps, Flex, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { BundleIcon } from 'components/icons';
import { OutofStock } from 'components/state';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { PriceBook } from 'types/common.types';
import { IProductList } from 'types/products.types';
import { currencyFormat } from 'utils';

export interface IProductCombine extends IProductList {
  price_book_id?: number;
  list_price_book?: PriceBook[];
  display_name?: string;
  pos_check_stock?: boolean;
  display_item_code?: string;
}
interface CardProductProps extends BoxProps {
  product: IProductCombine;
  selectItems?: () => void;
  isVariants?: boolean;
  itemStock?: number;
}

const TooltipMemo: React.FC<{
  product: IProductCombine;
  isVariants: boolean;
}> = ({ product, isVariants }) => {
  return (
    <Tooltip hasArrow label={product.item_name} bg='jubelio.black'>
      <Text as='span' noOfLines={1} fontSize='13px' fontWeight='600'>
        {isVariants ? product.display_item_code : product.item_name}
      </Text>
    </Tooltip>
  );
};

const CardProduct: React.FC<CardProductProps> = (props: CardProductProps) => {
  const { product, selectItems, isVariants = false, itemStock, ...rest } = props;
  const setting = useAppSelector((state) => state.commons.settings);

  const TooltipMemoize = React.memo(TooltipMemo);

  // Get stock of product
  const stockProduct = React.useMemo(() => {
    let stock = 0;
    if (!isVariants && product.variants) {
      stock = product.variants.reduce((acc, curr) => acc + (curr.available < 0 ? 0 : curr.available), 0);
    }

    if (isVariants) {
      stock = Number(itemStock);
    }

    return stock || 0;
  }, [product, isVariants, itemStock]);

  // Genereate badge color for product type (Serial, Batch, Bundle, Pieces)
  const getColorSchema = () => {
    let colorScheme = '';
    if (product.use_serial_number) {
      colorScheme = 'serial';
    } else if (product.use_batch_number) {
      colorScheme = 'batch';
    }

    return colorScheme;
  };

  const displayProductType = React.useCallback(
    (product: IProductList): string => {
      let productType = '';
      if (product.use_batch_number) {
        productType = 'BN';
      } else if (product.use_serial_number) {
        productType = 'SN';
      }

      return productType;
    },
    [product]
  );

  // disabled click card product if stock is 0
  const disableCard = React.useMemo(() => {
    if (product.variants && product.variants.length > 1 && stockProduct <= 0) {
      const findVariantCheckStock = product.variants.find((variant) => variant.pos_check_stock !== true);
      if (findVariantCheckStock) {
        return false;
      }
    }

    if (
      product.variants &&
      product.variants.length === 1 &&
      product.variants[0].pos_check_stock &&
      product.variants[0].available <= 0
    ) {
      return true;
    }

    if (isVariants && product.pos_check_stock) {
      if ((itemStock && itemStock <= 0) || itemStock === 0) {
        return true;
      }
    }

    return false;
  }, [product, itemStock, isVariants]);

  // Show pricebook information if product has pricebook
  // and user has permission to view pricebook

  return (
    <Box
      _hover={{ cursor: 'pointer' }}
      bg='white'
      boxShadow='shadow-item'
      border={1}
      rounded='4px'
      onClick={() => {
        if (!disableCard && selectItems) selectItems();
      }}
      {...rest}
    >
      <Box mb={2} rounded='4px' className='relative'>
        <Image
          src={product.thumbnail}
          w='full'
          h='170px'
          roundedTop='4px'
          objectFit='cover'
          fallback={
            <Avatar name={product.item_name} bg='#E2B3E5' w='full' h='170px' rounded={0} roundedTop='4px' />
          }
        />
        {!isVariants && (
          <Box className='absolute bottom-0'>
            {product.variants && product.variants[0].is_bundle === true ? (
              <VStack pl={0.5} pb={0.3}>
                <Tooltip hasArrow label='Bundle' bg='jubelio.black'>
                  <VStack px={1.5} bgColor='#E2B3E5' rounded='0.29rem'>
                    <BundleIcon height={25} width={25} />
                  </VStack>
                </Tooltip>
              </VStack>
            ) : (
              <BadgeProduct withIcon={false} colorScheme={getColorSchema()}>
                {displayProductType(product)}
              </BadgeProduct>
            )}
          </Box>
        )}
      </Box>
      <Box position='relative' rounded='4px' py={2} px={2} borderColor='gray.200'>
        {disableCard && <OutofStock />}

        <Box py={1}>
          <Box minH='20px'>
            <TooltipMemoize product={product} isVariants={isVariants} />
            {isVariants && product.display_name ? (
              <Text fontSize='11px'>{product.display_name?.replace(',', '-')}</Text>
            ) : null}
          </Box>
          {setting.allow_show_item_stock ? (
            <Flex>
              {product.total_variants > 1 ? (
                <Text fontSize='11px' color='gray.600' fontWeight='400' mr={2}>
                  Variasi {product.variants?.length}
                </Text>
              ) : null}
              <Text fontSize='11px' color='gray.600' fontWeight='400'>
                {`Stok: ${Number(product.total_qty) || Number(stockProduct)}`}
              </Text>
            </Flex>
          ) : null}
          <Box position='relative'>
            <Flex alignItems='center' fontSize='12px' color='jubelio.purple' fontWeight='600' pos='relative'>
              <Text>
                {!product.variants
                  ? currencyFormat(Number(product.sell_price))
                  : currencyFormat(Number(product.variants[0].sell_price))}
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardProduct;
