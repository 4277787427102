import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const Dashed: React.FC<BoxProps> = (props: BoxProps) => {
  return (
    <Box {...props}>
      <svg width='100%' viewBox='0 0 437 1' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <line
          x1='4.37114e-08'
          y1='0.5'
          x2='437'
          y2='0.500038'
          stroke='#AFB2B7'
          strokeDasharray='4 4'
        />
      </svg>
    </Box>
  );
};

export default Dashed;
