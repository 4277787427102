import { Button } from '@chakra-ui/react';
import * as React from 'react';

import { Modal } from '.';

interface IModalAlert {
  isShow: boolean;
  onClose: () => void;
  cancelRef?: React.RefObject<any>;
  isLoading?: boolean;
  openInitCash: () => void;
}

const ModalAlert: React.FC<IModalAlert> = (props: IModalAlert) => {
  const { isShow, onClose, openInitCash, isLoading } = props;

  return (
    <Modal isShow={isShow} onClose={onClose}>
      <Modal.Body className='p-8 text-center'>
        <h2 className='text-lg font-bold'>Masih ada Kasir yang aktif</h2>
        <p className='mt-3 text-[#163A50] opacity-50'>
          apakah ingin menyeselaikan terlebih dahulu atau tetap melanjutkan?
        </p>
        <div className='gap mt-8 flex w-full justify-center gap-2 text-center'>
          <Button variant='outline' onClick={onClose}>
            Tidak
          </Button>
          <Button
            variant='primary'
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={() => {
              onClose();
              openInitCash();
            }}
          >
            Ya, lanjutkan
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAlert;
