import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from './redux';

type UseOpenRegister = {
  registerAsCashier: () => void;
  registerAsPriceChecker: () => void;
  backToRegister: () => void;
};

const useOpenRegister = (): UseOpenRegister => {
  const isCashier = useAppSelector((state) => state.register.isCashier);
  const isPriceChecker = useAppSelector((state) => state.register.isPriceChecker);
  const lastSynced = useAppSelector((state) => state.register.lastSynced);
  const returnMode = useAppSelector((state) => state.return.returnMode);
  const currentClosure = useAppSelector((state) => state.register.currentClosure);
  const profile = useAppSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  const registerAsCashier = React.useCallback(() => {
    if (isCashier) {
      if (returnMode) {
        navigate('/sales/return');
      } else {
        if (currentClosure?.username === profile?.email) {
          navigate('/sales');
        } else {
          navigate('/register');
        }
      }
    } else {
      navigate('/register');
    }
  }, [isCashier]);

  const registerAsPriceChecker = React.useCallback(() => {
    if (isPriceChecker) {
      navigate('/price-checker');
    }
  }, [lastSynced]);

  const backToRegister = React.useCallback(() => {
    if (!currentClosure) {
      navigate('/register');
    }
  }, []);

  return { registerAsCashier, registerAsPriceChecker, backToRegister };
};

export default useOpenRegister;
