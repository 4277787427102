import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, HStack, Input, InputGroup, InputLeftElement, VStack } from '@chakra-ui/react';
import CardPickItem from 'components/ui/bopis/CardPickItem';
import { useNotification } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import useBopis from 'hooks/useBopis';
import React from 'react';
import bopisRequest from 'services/http/bopis.request';
import {
  IBinDefault,
  IDetailTransaction,
  IItemCreatePickList,
  IItemToPickList,
  IOrderSetting,
} from 'types/bopis.types';

import { Modal } from '.';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  detailOrder: IDetailTransaction;
  itemList: IItemToPickList[];
  orderSetting: IOrderSetting | null;
  defaultBin: IBinDefault | null;
  setItemList: (itemList: IItemToPickList[]) => void;
  refreshReadyToPick: () => void;
  setDetailOrder: (order: IDetailTransaction) => void;
}

const ModalProcessBopis: React.FC<Props> = (props: Props) => {
  const {
    isOpen,
    onClose,
    detailOrder,
    itemList,
    defaultBin,
    orderSetting,
    setItemList,
    refreshReadyToPick,
    setDetailOrder,
  } = props;
  const { assignItemToList, processPickList, getPickupLabel } = useBopis();
  const { notification } = useNotification();

  const [search, setSearch] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);

  const authSelector = useAppSelector((state) => state.auth);
  const { profile } = useAppSelector((state) => state.auth);

  const searchSerialNumber = async (key: string) => {
    try {
      if (key !== '') {
        setLoading(true);
        const response = await assignItemToList(key, itemList, authSelector.is_wms_migrated);
        setLoading(false);
        setSearch('');
        if (response && response.error_message) {
          return notification('', response.error_message, 'warning');
        }
      }
    } catch (error: any) {
      setLoading(false);
      setSearch('');
      return notification('', 'Item tidak ditemukan', 'warning');
    }
  };

  const updateQtyPick = async (e: any, salesorderDetailId: number) => {
    if (!isNaN(Number(e)) && Number.isInteger(Number(e))) {
      const items = itemList.map((el) => {
        if (Number(salesorderDetailId) === Number(el.salesorder_detail_id)) {
          if (Number(e) <= Number(el.qty_ordered)) {
            return Object.assign({}, el, {
              qty_picked: Number(e === '' ? 0 : e),
            });
          }
        }
        return el;
      });
      setItemList(items);
    }
  };

  const createPicklist = async (
    detailOrder: IDetailTransaction,
    items: IItemToPickList[],
    isWms: boolean
  ) => {
    setLoadingSubmit(true);
    if (isWms) await bopisRequest.processOrder(detailOrder.salesorder_id);
    const payload: IItemCreatePickList[] = items.map((i) => {
      const newItem: IItemCreatePickList = {
        picklist_detail_id: i.picklist_detail_id ?? 0,
        item_id: i.item_id,
        location_id: i.location_id,
        qty_ordered: Number(i.qty_ordered),
        qty_picked: Number(i.qty_picked),
        salesorder_detail_id: i.salesorder_detail_id,
        bundle_item_id: i.bundle_item_id,
        salesorder_id: i.salesorder_id,
        serials: i.serials ?? [],
      };
      if (isWms && defaultBin) newItem.bin_id = defaultBin?.bin_id;
      return newItem;
    });
    const res = await processPickList(
      detailOrder,
      payload,
      authSelector.is_wms_migrated,
      profile?.email ?? ''
    );
    if (res) {
      detailOrder.picklist_id = res.picklist_id;
      detailOrder.picklist_no = res.picklist_no;
      if (authSelector.is_wms_migrated && !orderSetting?.ignore_packing) {
        await bopisRequest.markPacklistCompleted(detailOrder.salesorder_id);
      }
      if (detailOrder.shipment_type === 'pickup_in_store') {
        const pickLabel = await getPickupLabel([detailOrder.salesorder_id], authSelector.is_wms_migrated);
        window.open(pickLabel.url, '_blank');
      }
      notification('', `Pesanan ${detailOrder.salesorder_no} berhasil di proses`, 'success', 3000);
    }
    setLoadingSubmit(false);
    onClose();
    refreshReadyToPick();
    setDetailOrder({} as IDetailTransaction);
    setItemList([] as IItemToPickList[]);
  };

  const disableButtonSimpan = () => {
    if (loadingSubmit) return true;
    if (itemList.find((i) => Number(i.qty_picked) !== Number(i.qty_ordered))) return true;
    return false;
  };

  const scanItem = (event: React.KeyboardEvent<HTMLElement>) => {
    if (search !== '') {
      if (event.key === 'Enter') {
        searchSerialNumber(search);
      }
    }
  };

  return (
    <Modal
      isShow={isOpen}
      onClose={onClose}
      title={`Proses Pesanan - ${detailOrder.salesorder_no}`}
      className='max-w-lg'
    >
      <Modal.Body>
        <HStack mb='16px' spacing='0px'>
          <InputGroup variant='outline' borderRadius='0px'>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input
              placeholder={'Scan Batch / Serial / Barcode'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value);
              }}
              onKeyDown={scanItem}
              value={search}
              variant='outline'
              rounded='4px'
              roundedBottomRight='0px'
              roundedTopRight='0px'
            />
          </InputGroup>
          <Button
            variant='outline'
            size='md'
            onClick={() => {
              searchSerialNumber(search);
            }}
            borderTopLeftRadius='0px'
            borderBottomLeftRadius='0px'
            isLoading={loading}
          >
            Scan
          </Button>
        </HStack>
        <VStack maxH='400px' overflowY='auto' spacing={2} w='full'>
          {itemList.map((item: IItemToPickList, index: number) => (
            <CardPickItem key={index} item={item} updateQtyPick={updateQtyPick} />
          ))}
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <Flex w='full'>
          <HStack justifyContent='flex-end' w='full'>
            <Button onClick={onClose} variant='outline' ml={2}>
              Batal
            </Button>
            <Button
              variant='primary'
              px={5}
              type='submit'
              loadingText='Harap tunggu'
              ml={3}
              isDisabled={disableButtonSimpan()}
              onClick={() => createPicklist(detailOrder, itemList, authSelector.is_wms_migrated)}
              isLoading={loadingSubmit}
            >
              Simpan
            </Button>
          </HStack>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProcessBopis;
