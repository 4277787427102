import { Button, VStack } from '@chakra-ui/react';
import IconPickupBlack from 'assets/images/icon-pickup-black.png';
import IconPickupWhite from 'assets/images/icon-pickup-white.png';
import IconShippingBlack from 'assets/images/icon-shipping-black.png';
import IconShippingWhite from 'assets/images/icon-shipping-white.png';
import ListShippingOption from 'components/ui/transaction/ListShippingOption';
import { useNotification } from 'hooks';
import React from 'react';
import { IDetailTransaction, IShippingBopis } from 'types/bopis.types';

import { Modal } from '.';

interface ModalCartProps {
  isOpen: boolean;
  detailOrder: IDetailTransaction;
  onClose: () => void;
  updateShipping: (detailTransaction: IDetailTransaction, values: IShippingBopis) => void;
  openCourierOption: () => void;
  setDetailOrder: (order: IDetailTransaction) => void;
}

const ModalShippingOptionBopis: React.FC<ModalCartProps> = ({
  isOpen,
  detailOrder,
  onClose,
  updateShipping,
  openCourierOption,
  setDetailOrder,
}: ModalCartProps) => {
  const { notification } = useNotification();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedOptionShipping, setSelectedOptionShipping] = React.useState<number>(0);

  const initialValues: IShippingBopis = {
    shipping_full_name: detailOrder.shipping_full_name,
    shipping_phone: detailOrder.shipping_phone ?? '',
    shipping_address: detailOrder.shipping_address ?? '',
    shipping_area: detailOrder.shipping_area ?? '',
    shipping_city: detailOrder.shipping_city ?? '',
    shipping_city_id: detailOrder.shipping_city_id ?? '',
    shipping_district_id: detailOrder.shipping_district_id ?? '',
    shipping_province: detailOrder.shipping_province ?? '',
    shipping_province_id: detailOrder.shipping_province_id ?? '',
    shipping_post_code: detailOrder.shipping_post_code ?? '',
    shipping_subdistrict: detailOrder.shipping_subdistrict ?? '',
    shipping_subdistrict_id: detailOrder.shipping_subdistrict_id ?? '',
    shipping_cost: Number(detailOrder.shipping_cost ?? 0),
    shipping_insurance: detailOrder.use_shipping_insurance ?? false,
    insurance_cost: Number(detailOrder.insurance_cost ?? 0),
    courier: detailOrder.courier ?? '',
    service_category: 0,
    total_value: Number(detailOrder.sub_total ?? 0),
    use_jubelio_shipment: false,
    shipping_area_id: 0,
  };

  const onSaveShipping = async () => {
    try {
      setLoading(true);
      const values: IShippingBopis = {
        ...initialValues,
        courier: 'Ambil Sendiri',
      };
      if (selectedOptionShipping === 1) await updateShipping(detailOrder, values);
      if (selectedOptionShipping === 2) {
        openCourierOption();
      }
      onClosePopupOptionShipping();
      if (selectedOptionShipping === 1) setDetailOrder({} as IDetailTransaction);
      setLoading(false);
    } catch (error) {
      notification('', 'Terjadi kesalahan, silahkan coba lagi', 'error');
    } finally {
      setLoading(false);
    }
  };

  const onClosePopupOptionShipping = () => {
    setSelectedOptionShipping(0);
    onClose();
  };

  return (
    <Modal isShow={isOpen} onClose={onClosePopupOptionShipping} title='Opsi Pengiriman' disableBackrop>
      <Modal.Body>
        <VStack spacing={4}>
          <ListShippingOption
            idx={1}
            title='Ambil Sendiri'
            icon={selectedOptionShipping === 1 ? IconPickupWhite : IconPickupBlack}
            isActive={selectedOptionShipping === 1}
            onClickOption={setSelectedOptionShipping}
          />
          <ListShippingOption
            idx={2}
            title='Dikirim'
            icon={selectedOptionShipping === 2 ? IconShippingWhite : IconShippingBlack}
            isActive={selectedOptionShipping === 2}
            onClickOption={setSelectedOptionShipping}
          />
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <Button
          isDisabled={selectedOptionShipping === 0 || loading}
          variant='primary'
          type='button'
          loadingText='Menyimpan..'
          isLoading={loading}
          ml={2}
          onClick={onSaveShipping}
        >
          Simpan
        </Button>
        <Button
          onClick={() => {
            onClosePopupOptionShipping();
          }}
          variant='outline'
          isDisabled={loading}
        >
          Batal
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ModalShippingOptionBopis);
