import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import fpPromise from '@fingerprintjs/fingerprintjs';
import { SubTitle, Title } from 'components/typography';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppDispatch } from 'hooks/redux';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAction } from 'redux/reducer/auth';
import authAction from 'services/http/auth.request';

import packageJson from '../../../package.json';

interface FormValues {
  email: string;
  password: string;
  fid?: string;
}

const FormLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const { notification } = useNotification();
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');
  const [fid, setFid] = React.useState<string>('');

  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  React.useEffect(() => {
    fpPromise
      .load()
      .then((fp) => fp.get())
      .then((result) => setFid(result.visitorId));
  }, []);

  const loginHandler = async (values: FormValues) => {
    try {
      const res = await authAction.login(values);

      dispatch(loginAction({ ...res, fid }));
      navigate('/register');
    } catch (error: any) {
      if (error.response) {
        notification('', error.response.data.code, 'error', 5000);
      }
      setLoading(false);
    }
  };

  return (
    <Box maxW='360px' w='full' px={3} py={10} position='relative'>
      <div className='flex flex-col text-left'>
        <Title fontSize={['18px', '18px', '21px', '22px', '24px']}>Selamat Datang</Title>
        <SubTitle color='jubelio.black'>Masukan email dan password untuk masuk ke Jubelio POS</SubTitle>
      </div>
      <div className='my-3 h-1 bg-gray-100' />
      <Formik
        initialValues={initialValues}
        validate={(values: FormValues) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Harap masukan email.';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Harap masukan password.';
          }
          return errors;
        }}
        onSubmit={(values: FormValues) => {
          setLoading(true);
          setTimeout(async () => {
            loginHandler({ ...values, fid });
          }, 500);
        }}
      >
        {({ values, errors, handleBlur, handleChange, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormControl mb={5} isInvalid={Boolean(errors.email && touched.email)}>
              <FormLabel htmlFor='email'>Email address</FormLabel>
              <Input
                id='email'
                type='email'
                name='email'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                size={isLargeScreen ? 'md' : 'sm'}
              />
              <FormErrorMessage>{touched.email && errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl mb={5} isInvalid={Boolean(errors.password && touched.password)}>
              <Flex justifyContent='space-between' alignItems='center'>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <Button
                  as='a'
                  variant='link'
                  href='https://app.jubelio.com/remind'
                  color='jubelio.primary'
                  fontWeight='normal'
                  target='_blank'
                  size={isLargeScreen ? 'md' : 'sm'}
                >
                  Lupa password?
                </Button>
              </Flex>
              <InputGroup size='md'>
                <Input
                  id='password'
                  type={show ? 'text' : 'password'}
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  size={isLargeScreen ? 'md' : 'sm'}
                />
                <InputRightElement width='3rem'>
                  <IconButton
                    aria-label='password'
                    h='2rem'
                    variant='ghost'
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none', outline: 'none' }}
                    size='sm'
                    onClick={() => setShow(!show)}
                    icon={
                      show ? (
                        <ViewOffIcon h='20px' w='20px' color='#8999A5' />
                      ) : (
                        <ViewIcon h='20px' w='20px' color='#8999A5' />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage>{touched.password && errors.password}</FormErrorMessage>
            </FormControl>
            <Button
              type='submit'
              isLoading={isLoading}
              isDisabled={isLoading || !values.email || !values.password}
              loadingText='Please wait'
              mt={4}
              w='full'
              size={isLargeScreen ? 'md' : 'sm'}
              variant='primary'
            >
              Masuk
            </Button>
            <SubTitle mt={6} textAlign='center'>
              Web Version v{packageJson.version}
            </SubTitle>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default FormLogin;
