import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BarcodeIcon: React.FC<IconProps> = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 512 512' fill='none' {...props}>
    <path
      stroke='currentColor'
      fill='currentColor'
      d='M141.812,85.711c0,6.627-5.372,12-12,12H51.186c-8.685,0-15.751,6.552-15.751,14.604v75.38c0,6.627-5.373,12-12,12c-6.628,0-12-5.373-12-12v-75.38c0-21.286,17.832-38.604,39.751-38.604h78.627C136.44,73.711,141.812,79.084,141.812,85.711zM488.565,312.305c-6.627,0-12,5.373-12,12v75.381c0,8.053-7.065,14.604-15.749,14.604h-78.629c-6.628,0-12,5.373-12,12s5.372,12,12,12h78.629c21.918,0,39.749-17.317,39.749-38.604v-75.381C500.565,317.678,495.193,312.305,488.565,312.305zM127.378,414.289H48.751c-8.686,0-15.751-6.551-15.751-14.604v-75.381c0-6.627-5.372-12-12-12s-12,5.373-12,12v75.381c0,21.286,17.832,38.604,39.751,38.604h78.627c6.628,0,12-5.373,12-12S134.006,414.289,127.378,414.289z M463.25,73.711h-78.628c-6.628,0-12,5.373-12,12s5.372,12,12,12h78.628c8.685,0,15.75,6.552,15.75,14.604v75.38c0,6.627,5.372,12,12,12s12-5.373,12-12v-75.38C503,91.029,485.168,73.711,463.25,73.711z M113.585,320.797V195.083c0-6.628-5.372-12-12-12s-12,5.372-12,12v125.714c0,6.628,5.372,12,12,12S113.585,327.425,113.585,320.797z M204.528,183.083c-6.628,0-12,5.372-12,12v125.714c0,6.628,5.372,12,12,12s12-5.372,12-12V195.083C216.528,188.455,211.156,183.083,204.528,183.083z M307.472,183.083c-6.628,0-12,5.372-12,12v125.714c0,6.628,5.372,12,12,12s12-5.372,12-12V195.083C319.472,188.455,314.1,183.083,307.472,183.083zM422.415,320.797V195.083c0-6.628-5.372-12-12-12s-12,5.372-12,12v125.714c0,6.628,5.372,12,12,12S422.415,327.425,422.415,320.797z'
    />
  </Icon>
);

export default BarcodeIcon;
