import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import request from 'services/http/request';
import { IPosSetting } from 'types';
import { ClosureRequest, IClosureOpen } from 'types/closure.types';
import {
  ContactDetail,
  ICompany,
  IContactCategoryRoot,
  IContactCountryList,
  ICourier,
  ICustomerInfo,
  ICustomerInfoInput,
  ICustomerRoot,
  IMarketplaceIntegration,
  IPayments,
  IPosVersion,
  IPresets,
  ISalesmenRoot,
  ITaxes,
  StructSetting,
  UserPinACL,
} from 'types/common.types';
import { IPromotions } from 'types/promotion.types';
import { IQueryParams } from 'types/request.type';
import { lastSyncDate } from 'utils';

import wmsRequest from './wms-request';

export default {
  getPosSetting: async (): Promise<IPosSetting> => {
    try {
      const response = await request.get('sales/pos/v3/settings');
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getClosure: async (payload: ClosureRequest): Promise<IClosureOpen> => {
    try {
      const response = await request.post('sales/pos/v3/closures/open', payload);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getPayments: async (): Promise<IPayments[]> => {
    try {
      const res = await request.get('sales/pos/v3/payments');
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPresets: async (type: string): Promise<IPresets[]> => {
    try {
      const res = await request.get(`sales/pos/v3/presets?type=${type}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTaxes: async (): Promise<ITaxes[]> => {
    try {
      const res = await request.get(`sales/pos/v3/taxes/lookup`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCustomer: async (page: number, pageSize: number, q = ''): Promise<ICustomerRoot> => {
    try {
      const res = await request.get(`sales/pos/v3/contacts?page=${page}&pageSize=${pageSize}&q=${q}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSalesman: async (page: number, pageSize: number, q = ''): Promise<ISalesmenRoot> => {
    try {
      const res = await request.get(`sales/pos/v2/salesmen?page=${page}&pageSize=${pageSize}&q=${q}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCourier: async (): Promise<ICourier[]> => {
    try {
      const res = await request.get(`systemsetting/key-pair/?type=internalShipment`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getContactCategory: async (page: number, pageSize: number, q = ''): Promise<IContactCategoryRoot> => {
    try {
      const res = await request.get(`contact/category/?page=${page}&pageSize=${pageSize}&q=${q}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMarketplace: async (): Promise<IMarketplaceIntegration[]> => {
    try {
      const res = await request.get(`marketplace/integration-status`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createCustomer: async (payload: ICustomerInfoInput): Promise<ICustomerInfo> => {
    try {
      payload = {
        ...payload,
        b_address: payload.s_address || '',
        b_city: payload.s_city || '',
        b_province: payload.s_province || '',
        b_post_code: payload.s_post_code || '',
        b_area: payload.s_area || '',
      };
      const res = await request.post('sales/pos/v3/contacts', payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  initSync: async (
    locationId: number,
    registerId: number,
    process: string,
    page: number,
    lastSync = ''
  ): Promise<Record<string, any>> => {
    try {
      const query = new URLSearchParams({
        step: process,
        page: String(page) ?? 1,
        pageSize: String(200),
        registerId: String(registerId),
        lastSync: lastSync ? lastSyncDate(lastSync) : '',
      }).toString();
      const res = await request.get(`sales/pos/v3/location/${locationId}/init-sync?${query}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateRestoreId: async (
    email: string,
    restore_id: string,
    company_id: number
  ): Promise<Record<string, any>> => {
    try {
      const response = await request.post('update-restoreid', {
        email,
        restore_id,
        company_id,
      });
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getCompanies: async (id: number, email: string, q?: string): Promise<ICompany[]> => {
    try {
      const addQuery = q ? `&q=${q}` : '';
      let res;

      const isWmsMigrated = localStorage.getItem('is_pos_wms');
      if (isWmsMigrated === 'pos') {
        res = await request.get(`companies/${id}?pageSize=25&page=1${addQuery}`);
      } else {
        res = await wmsRequest.get(`v2/companies/${id}?pageSize=25&page=1${addQuery}`);
      }

      return Promise.resolve(res?.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  switchCompany: async (companyId: number): Promise<AxiosResponse> => {
    try {
      const res = await request.post(`switch-company`, { companyId });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  structPrint: async (locationId: number): Promise<StructSetting> => {
    try {
      const res = await request.get(`sales/pos/v3/struct/register/${locationId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPosVersion: async (): Promise<IPosVersion> => {
    try {
      const res = await request.get(`pos/version?appPlatform=WEB`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getChangeLog: async (): Promise<IPosVersion[]> => {
    const res = await request.get(`/pos/version/change-log?appPlatform=WEB`);
    return res.data;
  },
  contactDetail: async (id: number): Promise<ContactDetail> => {
    try {
      const res = await request.get(`sales/pos/v3/contacts/credit/${id}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPromotion: async (locationId: number): Promise<IPromotions[]> => {
    try {
      const res = await request.get(`sales/pos/v2/locations/${locationId}/promotions`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateEmailContact: async (payload: { contact_id: number; email: string }): Promise<AxiosResponse> => {
    try {
      const res = await request.put(`sales/pos/v3/contacts/update`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getAuthorizedUser: async function (payload: {
    location_id: number;
    permission_id: number[];
  }): Promise<UserPinACL> {
    try {
      const response = await request.post(`/sales/pos/v3/authorized-user/list`, payload);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  checkAuthorizedUser: async (payload: {
    user_id: number;
    permission_id: number;
    pin: string;
    location_id: number;
  }): Promise<AxiosResponse> => {
    try {
      const response = await request.post(`sales/pos/v3/authorization-check`, payload);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getContactCountryList: async ({
    queryKey,
  }: QueryFunctionContext<[string, { q: string }]>): Promise<IContactCountryList> => {
    const [, params] = queryKey;
    const queryString = {
      pageSize: 50,
      page: 1,
      q: params.q,
    };
    const res = await wmsRequest.get(`contact/country/`, { params: queryString });
    return res.data;
  },
  logPos: async (action: string, request_payload: string): Promise<AxiosResponse> => {
    try {
      const res = await request.post(`sales/pos/v3/log-pos`, { action, request_payload });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getContacts: async ({ queryKey }: QueryFunctionContext<[string, IQueryParams]>): Promise<ICustomerRoot> => {
    const [, params] = queryKey;
    const response = await request.get(`sales/pos/v3/contacts/list`, { params });
    return response.data;
  },
};
