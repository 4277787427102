import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IPosVersion } from 'types/common.types';

import { Modal } from '.';
import packageJson from '../../../package.json';

interface ModalProps {
  isShow: boolean;
  listVersion: IPosVersion[];
  onClose: () => void;
}

const ModalVersion: React.FC<ModalProps> = (props: ModalProps) => {
  const { isShow, onClose, listVersion } = props;

  return (
    <Modal isShow={isShow} onClose={onClose} title='Histori Versi'>
      <Modal.Body className='max-h-[500px] overflow-y-auto p-5'>
        <div className='w-full '>
          {listVersion &&
            listVersion.map((version, index) => (
              <div className='mb-3 rounded-md border bg-white p-4 text-sm' key={index}>
                <div className='mb-4 flex items-center justify-between'>
                  <label className='text-md  font-bold'>{version.version_number}</label>
                  {packageJson.version === version.version_number && (
                    <label className='text-xs font-bold text-primary'>Versi Sekarang</label>
                  )}
                </div>
                <ReactMarkdown
                  children={version.changelog}
                  remarkPlugins={[remarkGfm]}
                  className='reactMarkDown'
                />
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVersion;
