import request from 'services/http/request';
import { IUserInfo, LoginValues } from 'types/auth.types';

export default {
  login: async (payload: LoginValues): Promise<IUserInfo> => {
    try {
      const res = await request.post('/login', payload, {
        headers: {
          Origin: 'https://pos-cashier-v3-enhance.staging-k8s.jubelio.com',
        },
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  logout: async (): Promise<IUserInfo> => {
    try {
      const res = await request.post('/logout');
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getUserInfo: async (wmsUrl: string): Promise<IUserInfo> => {
    try {
      const res = await request.get(`sales/${wmsUrl}/v3/userInfo`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
