import Bowser from 'bowser';
import React from 'react';

import useNotification from './useNotification';

export const usePrintUsb = () => {
  const [selectedPrinter, setSelectedPrinter] = React.useState<USBDevice>();

  const browser = Bowser.getParser(window.navigator.userAgent);
  const { notification } = useNotification();

  const getDevices = async () => {
    if (browser.getBrowserName() === 'Chrome') {
      console.log('[USB_DEVICES] Get devices called...');
      await navigator.usb.getDevices().then(async (devices) => {
        if (devices.length !== 0) {
          devices.forEach(async (device) => {
            console.log('[USB_DEVICES] Device', device.opened);
            if (device.opened === false) {
              setSelectedPrinter(device);
              return;
            }
          });
        }
      });
    }
  };

  const handleConnectDevice = async () => {
    return navigator.usb
      .requestDevice({
        filters: [],
      })
      .then(async (selectedDevice) => {
        setSelectedPrinter(selectedDevice);
        await selectedDevice.open().then(async () => {
          await selectedDevice.reset();
          await claimInterface(selectedDevice);
        });
      })
      .finally(() => {
        window.location.reload();
      });
  };

  const claimInterface = async (device: USBDevice) => {
    for (const config of device.configurations) {
      for (const iface of config.interfaces) {
        if (!iface.claimed) {
          await device.claimInterface(iface.interfaceNumber);
          return true;
        } else {
          console.log('[USB_DEVICES] Interface already claimed');
          return true;
        }
      }
    }

    return false;
  };

  const sendDatatoPrinter = async (data: Uint8Array, device: USBDevice) => {
    const endpointNumber = device.configurations[0].interfaces[0].alternate.endpoints[1].endpointNumber;
    await device.transferOut(endpointNumber, data);
  };

  const disconnectDevice = async (device: USBDevice) => {
    await device.close();
    await device.forget();
    setSelectedPrinter(undefined);
  };

  React.useEffect(() => {
    if (browser.getBrowserName() === 'Chrome') {
      navigator.usb.ondisconnect = () => {
        console.log('[USB_DEVICES] Disconnected');
        setSelectedPrinter(undefined);
      };
    }
  }, []);

  const printReceipt = async (data: Uint8Array) => {
    if (!selectedPrinter) {
      notification('', 'Printer tidak terhubung', 'error', 3000);
      return;
    }

    if (selectedPrinter?.opened === false) {
      await selectedPrinter?.open().then(async () => {
        await selectedPrinter?.reset();
        await selectedPrinter?.selectConfiguration(1);
        return claimInterface(selectedPrinter);
      });
    }

    const isClaimed = await claimInterface(selectedPrinter);
    if (isClaimed) {
      console.log('[INFO] Printing...');
      await sendDatatoPrinter(data, selectedPrinter);
      await selectedPrinter?.close();
      console.log('[INFO] Printing done');
      return;
    }

    console.log('Device not claimed');
  };

  return {
    printReceipt,
    disconnectDevice,
    selectedPrinter,
    handleConnectDevice,
    getDevices,
  };
};
