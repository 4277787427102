import { Tooltip } from '@chakra-ui/react';
import BadgeCart from 'components/badge/BadgeCart';
import { CloudFail } from 'components/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Maybe } from 'types';
import { ILocations, RegisterObject } from 'types/register.types';

type HeaderMachineInfoProps = {
  totalUnSyncTrx: number;
  locationPos: Maybe<ILocations | undefined>;
  registerInfo: Maybe<RegisterObject>;
};

export default function HeaderMachineInfo({
  totalUnSyncTrx,
  locationPos,
  registerInfo,
}: HeaderMachineInfoProps) {
  const navigate = useNavigate();

  return (
    <div className='relative flex items-center justify-between border-b border-gray-200 p-4 font-semibold'>
      <span>
        {locationPos?.location_name} - {registerInfo?.register_name}
      </span>
      {Number(totalUnSyncTrx) > 0 && (
        <Tooltip label={`Transaksi gagal terkirim ${Number(totalUnSyncTrx)}`} hasArrow>
          <div>
            <BadgeCart
              aria-label='cart'
              counter={Number(totalUnSyncTrx)}
              onClick={() => navigate('/sales/history')}
              icon={<CloudFail h='27px' w='27px' fill='#8999A5' />}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
