import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Stack,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Bowser from 'bowser';
import BadgePromotion from 'components/badge/BadgePromotion';
import ButtonSelect from 'components/button/ButtonSelect';
import ButtonSelectCustomer from 'components/button/ButtonSelectCustomer';
import { CardItem, CardItemReturn } from 'components/card';
import { CartIcon, EditIcon, SaveIcon } from 'components/icons';
import PromotionIcon from 'components/icons/PromotionIcon';
import {
  ModalCart,
  ModalChangeItem,
  ModalCustomer,
  ModalListCart,
  ModalListCustomer,
  ModalSalesman,
  ModalTransaction,
  ModalVoucherPromotion,
} from 'components/modal';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import { CartEmpty } from 'components/state';
import { Title } from 'components/typography';
import messages, { modal } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCheckTime, useCommon, useGetLocations, useNotification, useOrder, usePromotions } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { arraysHaveSameIds } from 'lib/helpers';
import { isEqual } from 'radash';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAction, setIsLogout } from 'redux/reducer/auth';
import { removeCustomerReturn, setCustomerOrderReturn } from 'redux/reducer/return';
import {
  applyPriceBook,
  applySlashPrice,
  checkoutAction,
  clearPaymentsList,
  decrementItem,
  removeCustomer,
  removeItem,
  removeSaleman,
  selectCustomer,
  setContactToOrder,
  setListAllPromotion,
  setListDiscountItems,
  setListPromotionAmount,
  setListPromotionItem,
  setListValidVoucher,
  setPromotionsFreeItems,
  setTotalDetail,
  setTransactionPromotion,
  togglePopup,
  updateIsCheckout,
  updateItemDetail,
  updateListFreeItemV2,
  updateLoadingCheckout,
  updateQuantityItem,
  updateShippingOrder,
} from 'redux/reducer/sales';
import authAction from 'services/http/auth.request';
import productsRequest from 'services/http/products.request';
import shipmentRequest from 'services/http/shipment.request';
import cart from 'services/indexdb/cart';
import { db } from 'services/indexdb/connection';
import { ICustomerInfo, ICustomerInfoInput, IShippingInfo } from 'types/common.types';
import { IItemCart, IUpdateItemCart } from 'types/products.types';
import { IGetPromotionData } from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { IItemReturn } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { getAmountDetailTransaction, getTotalItemsCart, mappingCheckoutData } from 'utils';
import { getListFreeItemsV2 } from 'utils/promotions';
import { getPromotionFreeItems } from 'utils/promotions/free-items';
import { checkDiscountAmount } from 'utils/promotions/minimal-transaction';

import DetailTotal from './DetailTotal';
import Footer from './Footer';
import HeaderCart from './HeaderCart';
import packageJson from '../../../../package.json';

export const CardDetaill: React.FC = () => {
  const [showTotal, setShowTotal] = React.useState<boolean>(true);
  const [showTotalReturn, setShowTotalReturn] = React.useState<boolean>(true);
  const [isLoadingPromotion, setIsLoadingPromotion] = React.useState<boolean>(false);
  const itemList = React.useRef<HTMLDivElement>(null);
  const [itemDetal, setItemDetal] = React.useState<IItemCart>({} as IItemCart);
  const [resultPromotion, setResultPromotion] = React.useState<any>();
  const [changeContact, setChangeContact] = React.useState<boolean>(false);
  const [missingProducts, setMissingProduct] = React.useState<string[]>([]);
  const [deletedItem, setDeletedItem] = React.useState<IUpdateItemCart | null>(null);
  const [deletedCustomer, setDeletedCustomer] = React.useState<boolean>(false);
  const [isCustomerAction, setIsCustomerAction] = React.useState<boolean>(false);
  const [isFreeItem, setIsFreeItem] = React.useState<boolean>(false);
  const [debounceTimeout, setDebounceTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const [rollbackQtyItem, setRollbackQtyItem] = React.useState<number | null>(null);

  // ** Redux selector
  const customer = useAppSelector((state) => state.return.customer);
  const listItemReturn = useAppSelector(({ return: { listItemReturn } }) => listItemReturn);
  const orderReturn = useAppSelector(({ return: { orderReturn } }) => orderReturn);
  const totalDetailReturn = useAppSelector((state) => state.return.totalDetail);
  const returnMode = useAppSelector((state) => state.return.returnMode);

  const sales = useAppSelector((state) => state.sales);
  const order = useAppSelector((state) => state.sales.order);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo, shallowEqual);
  const listItemCart = useAppSelector(({ sales: { listItemCart } }) => listItemCart, shallowEqual);
  const salesmenInfo = useAppSelector((state) => state.sales.salesmenInfo);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const listPromotionItem = useAppSelector(
    ({ sales: { listPromotionItem } }) => listPromotionItem,
    shallowEqual
  );
  const listFreeItemV2 = useAppSelector(({ sales: { listFreeItemV2 } }) => listFreeItemV2, shallowEqual);
  const listTransactionPromotion = useAppSelector(
    ({ sales: { listTransactionPromotion } }) => listTransactionPromotion,
    shallowEqual
  );
  const processPromotions = useAppSelector(
    ({ sales: { processPromotions } }) => processPromotions,
    shallowEqual
  );

  const listPromotionAmount = useAppSelector(
    ({ sales: { listPromotionAmount } }) => listPromotionAmount,
    shallowEqual
  );
  const listValidVoucher = useAppSelector(
    ({ sales: { listValidVoucher } }) => listValidVoucher,
    shallowEqual
  );

  const listAllPromotion = useAppSelector(
    ({ sales: { listAllPromotion } }) => listAllPromotion,
    shallowEqual
  );

  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const isOpenPopup = useAppSelector((state) => state.sales.isOpenPopup);

  const settings = useAppSelector((state) => state.commons.settings);
  const location = useAppSelector((state) => state.register.location);
  const isLoadingCheckout = useAppSelector((state) => state.sales.isLoadingCheckout);
  const isCheckout = useAppSelector((state) => state.sales.isCheckout);
  const isContact = useAppSelector((state) => state.sales.isContact);
  const initSync = useAppSelector((state) => state.register.initSync);
  const isLogout = useAppSelector((state) => state.auth.isLogout);
  const usePromotion = useAppSelector((state) => state.commons.usePromotion);
  const stepDownload = useAppSelector((state) => state.register.stepDownload);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { disableEditItem } = useCommon();
  const { notification, customNotification } = useNotification();
  const { pingRegister } = useGetLocations();
  const {
    getTransactionPromotion,
    getPromotionItem,
    getSlashPriceCartOnline,
    getSlashPrice,
    getPriceBookListByCustomer,
  } = usePromotions();
  const { currentHours } = useCheckTime();
  const { createOrder } = useOrder();

  // custom hook
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { onOpen: onOpenCustomer, onClose: onCloseCustomer, isOpen: isOpenCustomer } = useDisclosure();
  const {
    onOpen: onOpenAddCustomer,
    onClose: onCloseAddCustomer,
    isOpen: isOpenAddCustomer,
  } = useDisclosure();
  const { onOpen: onOpenCart, onClose: onCloseCart, isOpen: isOpenCart } = useDisclosure();
  const { onOpen: onOpenChangeItem, onClose: onCloseChangeItem, isOpen: isOpenChangeItem } = useDisclosure();
  const {
    onOpen: onOpenTransaction,
    onClose: onCloseTransaction,
    isOpen: isOpenTransaction,
  } = useDisclosure();
  const { onOpen: onOpenSalesman, onClose: onCloseSalesman, isOpen: isOpenSalesman } = useDisclosure();
  const { onOpen: onOpenVoucher, onClose: onCloseVoucher, isOpen: isOpenVoucher } = useDisclosure();
  const {
    onOpen: onOpenConfirmRemovePromo,
    onClose: onCloseConfirmRemovePromo,
    isOpen: isOpenConfirmRemovePromo,
  } = useDisclosure();
  const {
    onOpen: onOpenOfflinePromotion,
    onClose: onCloseOfflinePromotion,
    isOpen: isOpenOfflinePromotion,
  } = useDisclosure();

  const totalSavedCart = useLiveQuery(async () => {
    const result = await cart.get();
    return result && result.length;
  }, []);

  const listCart = React.useMemo(() => {
    return listItemCart;
  }, [listItemCart]);

  /**
   * Get grand total and all total detail from transaction
   */
  const totalDetail = React.useMemo(
    (): ITotalDetail => getAmountDetailTransaction(sales, location as ILocations, settings),
    [listCart, sales, location, settings]
  );

  /**
   * remove customer if customer is selected
   * and add customer to cart if customer is not selected
   */
  const customerAction = async () => {
    if (customerInfo) {
      if (returnMode) {
        dispatch(removeCustomerReturn());
      } else {
        if ((listPromotionAmount.length > 0 || listPromotionItem.length > 0) && !isCustomerAction) {
          setIsCustomerAction(true);
          onOpenConfirmRemovePromo();
        }
        if (deletedCustomer || listItemCart.length === 0) {
          await removeCustomerAction();
          setDeletedCustomer(false);
          setIsCustomerAction(false);
        } else if (!deletedCustomer && listPromotionAmount.length === 0 && listPromotionItem.length === 0) {
          await removeCustomerAction();
        }
      }
    } else {
      onOpenCustomer();
    }
  };

  const removeCustomerAction = async () => {
    dispatch(removeCustomer());
    const res = await db.customer.where('contact_id').equals(-1).first();

    if (isOnline) {
      getSlashPriceCartOnline(listItemCart, res as ICustomerInfo).then((result) => {
        dispatch(applySlashPrice(result.discount));
        dispatch(applyPriceBook(result.pricebook));
      });
    } else {
      getSlashPrice(listItemCart, res as ICustomerInfo).then((result) => {
        dispatch(applySlashPrice(result));
      });
      getPriceBookListByCustomer(listItemCart, res as ICustomerInfo).then((result) => {
        dispatch(applyPriceBook(result));
      });
    }
  };

  /**
   * display popup confirmation to remove customer if customer selected
   * and display popup list customer if customer not selected
   */
  const onChangeCustomer = () => {
    if (disableEditItem && !returnMode && !continuePayment && customerInfo) {
      setChangeContact(true);
      dispatch(togglePopup(true));
    } else {
      customerAction();
    }
  };

  /**
   * clear payment if user want to change transaction
   * and remove selected contact if user want to change contact
   */
  const onSubmitConfirmationChangeTransaction = async () => {
    dispatch(updateIsCheckout(false));
    dispatch(clearPaymentsList());
    if (changeContact) {
      customerAction();
      setChangeContact(false);
      return;
    }
    if (isLogout) {
      await authAction.logout();
      dispatch(logoutAction());
      window.location.href = '/';
    } else {
      navigate('/sales');
    }
  };

  /**
   * remove salesman if saleman is selected
   * and add salesman to cart if salesman is not selected
   */
  const salesmanAction = () => {
    if (salesmenInfo) {
      dispatch(removeSaleman());
    } else {
      onOpenSalesman();
    }
  };

  const calculateShippingCost = async () => {
    try {
      const totalWeight = sales.listItemCart
        .map((a: IItemCart) => Number(a.package_weight) * Number(a.quantity))
        .reduce((a, b) => a + b, 0);

      const itemsShipment = sales.listItemCart.map((item) => ({
        quantity: item.quantity,
        weight: Number(item.package_weight) || 0,
        length: Number(item.package_length) || 0,
        width: Number(item.package_width) || 0,
        height: Number(item.package_height) || 0,
        item_id: item.item_id,
      }));

      const payload = {
        origin: {
          area_id: location?.subdistrict_id ?? '',
          zipcode: location?.post_code ?? '',
        },
        destination: {
          area_id: sales.shippingInfo?.shipping_subdistrict_id ?? '',
          zipcode: sales.shippingInfo?.shipping_post_code ?? '',
        },
        weight: Number(totalWeight) > 0 ? totalWeight : 1,
        service_category_id: sales.shippingInfo?.service_category ?? 0,
        total_value: totalDetail.subTotalItem ?? 0,
        items: itemsShipment,
      };
      const res = await shipmentRequest.getRates(payload);
      const findShipment =
        res.data &&
        res.data.find(
          (shipment) =>
            shipment.courier_service_name.concat(' (Jubelio Shipment)') === sales.shippingInfo?.courier
        );

      const shippingInfo = {
        ...sales.shippingInfo,
        shipping_cost: findShipment?.final_rates ?? 0,
      };
      dispatch(updateShippingOrder(shippingInfo as unknown as IShippingInfo));
    } catch (error: any) {
      dispatch(updateLoadingCheckout(false));
      dispatch(updateIsCheckout(false));
      let message = 'Unknown Error';
      if (error) message = error.response.data.message;
      return notification('', message, 'warning', 5000);
    }
  };

  const snBnValidation = () => {
    for (const item of listItemCart) {
      if (item.batch_number) {
        const filterEmptyBatchNo = item.batch_number.filter((item) => item.batch_no === '');
        const filterFilledBatchNo = item.batch_number.filter((item) => item.batch_no !== '');
        if (filterEmptyBatchNo.length > 0 && filterFilledBatchNo.length > 0) {
          return 'Harap isi semua Batch No.';
        }
        if (filterFilledBatchNo.length > 0) {
          const sum = filterFilledBatchNo
            .map((a) => Number(a.qty))
            .reduce(function (a, b) {
              return a + b;
            });

          if (sum !== item.quantity) {
            return 'Total keseluruhan batch number jumlahnya harus sama dengan jumlah barang yang dibeli';
          }
        }
      }
      if (item.serial_number) {
        const filterEmptySerialNo = item.serial_number.filter((item) => item.serial_no === '');
        const filterFilledSerialNo = item.serial_number.filter((item) => item.serial_no !== '');
        if (filterEmptySerialNo.length > 0 && filterFilledSerialNo.length > 0) {
          return 'Harap isi semua Serial No.';
        }
        const duplicateIds = filterFilledSerialNo
          .map((e) => e['serial_no'])
          .map((e, i, final) => final.indexOf(e) !== i && i)
          .filter((obj: any) => item.serial_number && item.serial_number[obj])
          .map((e: any) => item.serial_number && item.serial_number[e]['serial_no']);

        if (duplicateIds.length > 0) {
          return 'Serial No. tidak boleh memiliki nilai yang sama';
        }
        if (filterFilledSerialNo.length !== item.quantity) {
          return 'Total keseluruhan Serial No. jumlahnya harus sama dengan jumlah barang yang dibeli';
        }
      }
    }
    return '';
  };

  const checkProductExist = async (
    items: Pick<
      IItemCart,
      | 'item_id'
      | 'item_code'
      | 'batch_number'
      | 'serial_number'
      | 'quantity'
      | 'use_batch_number'
      | 'use_serial_number'
    >[],
    location_id: number
  ) => {
    try {
      const res = await productsRequest.checkProductExist(items, location_id);
      return res;
    } catch (error: any) {
      dispatch(updateLoadingCheckout(false));
      dispatch(updateIsCheckout(false));
      if (error.response.data.statusCode === 400) {
        return error.response.data;
      }
      return error;
    }
  };

  const checkQtySnBn = (items: IItemCart[]) => {
    const unqualified: string[] = [];
    items.forEach((item) => {
      if (item.use_serial_number) {
        if (!item.serial_number) {
          unqualified.push(item.item_code);
        } else if (Number(item.quantity) !== Number(item.serial_number.length)) {
          unqualified.push(item.item_code);
        }
      }
      if (item.use_batch_number) {
        if (!item.batch_number) {
          unqualified.push(item.item_code);
        } else if (Number(item.quantity) !== Number(item.batch_number.length || 0)) {
          unqualified.push(item.item_code);
        }
      }
    });

    return unqualified;
  };

  /**
   * Checkout to payment page and mapping items cart to order Object
   */
  const checkout = async (total: ITotalDetail) => {
    dispatch(updateLoadingCheckout(true));
    if (!order.salesorder_no || order.salesorder_no === '') createOrder(); // generate orderno

    const validateSnBn = snBnValidation();
    if (validateSnBn !== '') {
      dispatch(updateLoadingCheckout(false));
      dispatch(updateIsCheckout(false));
      return notification('', validateSnBn, 'error', 5000);
    }

    if (sales.listPayments) dispatch(clearPaymentsList());
    const totalDiscount = total.discountTrx + total.salesPromotions + total.discountOutlet;
    const payload = mappingCheckoutData(sales, total, totalDiscount, settings);
    if (
      sales.pos_is_shipping &&
      settings.jubelio_shipment_active &&
      (sales.shippingInfo?.use_jubelio_shipment ?? false)
    ) {
      await calculateShippingCost();
    }

    if (isOnline) {
      const itemsToCheck = payload.items.map((i) => ({
        item_code: i.item_code,
        item_id: i.item_id,
        batch_number: i.batch_number,
        serial_number: i.serial_number,
        quantity: i.quantity,
        use_serial_number: i.use_serial_number,
        use_batch_number: i.use_batch_number,
        bundle_child: i.bundle_child?.map((b) => ({
          item_id: b.item_id,
          qty: b.qty,
          item_name: b.item_name,
        })),
        is_free: false,
      }));

      const freeItemsToCheck = getListFreeItemsV2(sales.listFreeItemV2).map((i: any) => ({
        item_code: i.item_code,
        item_id: i.item_id,
        batch_number: i.batch_number,
        serial_number: i.serial_number,
        quantity: i.quantity,
        use_serial_number: i.use_serial_number,
        use_batch_number: i.use_batch_number,
        bundle_child: i.bundle_child?.map((b: any) => ({
          item_id: b.item_id,
          qty: b.qty,
          item_name: b.item_name,
        })),
        is_free: true,
      }));

      const res = await checkProductExist([...itemsToCheck, ...freeItemsToCheck], location?.location_id ?? 0);
      if (res && res.message && res.attributes) {
        dispatch(updateLoadingCheckout(false));
        dispatch(updateIsCheckout(false));
        setMissingProduct(res.attributes);
        return notification('', res.message, 'error');
      }
    } else {
      if (settings.enable_require_snbn) {
        const resValidateSnBn = checkQtySnBn(payload.items);
        if (resValidateSnBn.length > 0) {
          const joinItemSnBn = resValidateSnBn.join(',');
          dispatch(updateLoadingCheckout(false));
          dispatch(updateIsCheckout(false));
          return notification('', `Jumlah SN/BN pada barang ${joinItemSnBn} tidak sesuai.`, 'error');
        }
      }
    }

    dispatch(
      checkoutAction({
        orders: payload.orders,
        listItemCart: payload.items,
      })
    );
    dispatch(setTotalDetail(total));

    if (!customerInfo) {
      const resCustomer = await db.customer.where('contact_id').equals(-1).first();
      dispatch(selectCustomer(resCustomer as ICustomerInfo));
      dispatch(setContactToOrder(resCustomer as ICustomerInfoInput));
    }

    if (customerInfo) dispatch(setContactToOrder(customerInfo as ICustomerInfoInput));

    dispatch(updateLoadingCheckout(false));
    dispatch(updateIsCheckout(true));
    navigate('/sales/checkout');
  };

  const freeItems = React.useMemo(() => {
    return getListFreeItemsV2(listFreeItemV2);
  }, [listFreeItemV2]);

  const onGetTransactionPromo = React.useCallback(() => {
    getTransactionPromotion(sales).then((result) => {
      if (resultPromotion) {
        dispatch(
          setTransactionPromotion({
            promotion: result,
            processPromotion: resultPromotion,
          })
        );
      }
    });
  }, [listCart, customerInfo, resultPromotion, deletedCustomer, listValidVoucher]);

  const onGetFreeItems = React.useCallback(() => {
    getPromotionFreeItems(listTransactionPromotion, processPromotions).then((res) => {
      dispatch(setPromotionsFreeItems(res));
    });
  }, [listTransactionPromotion, customerInfo]);

  const onGetPromotionItems = React.useCallback(() => {
    if (isOnline && usePromotion) {
      setIsLoadingPromotion(true);
      let saleAfterDiscount = totalDetail.totalAfterDiscount;
      getPromotionItem(listCart, totalDetail.totalAfterDiscount ?? 0)
        .then((result) => {
          dispatch(setListAllPromotion(result.allPromotion));
          dispatch(setListDiscountItems([]));
          if (listValidVoucher.length > 0) {
            const validVoucherList = listValidVoucher.filter((v) =>
              result.allPromotion.find((p) => p.promotion_id === v.promotion_id)
            );
            dispatch(setListValidVoucher(validVoucherList));
          }
          const comparePromotion = arraysHaveSameIds(result.allPromotion, listAllPromotion, 'promotion_id');
          if (!comparePromotion) {
            if (result.transactionPromotion.length > 0 && result.itemPromotion.length === 0) {
              customNotification('', 'Promosi', 'Promosi Tersedia', onOpenVoucher, 2000);
            }
            if (settings.choose_promotion_type === 'choose-promotion' && result.totalCount > 1) {
              if (listPromotionAmount.length > 0 || listPromotionItem.length > 0) {
                if (
                  listPromotionAmount[0]?.promotion.is_overlap === true ||
                  listPromotionItem[0]?.is_overlap === true
                ) {
                  const filterListPromotionAmount = result.allPromotion.filter(
                    (p) => p.is_overlap === true && p.discount_reward && p.discount_reward.length > 0
                  );
                  const filterListPromotionItem = result.allPromotion.filter(
                    (p) => p.is_overlap === true && p.product_reward && p.product_reward.length > 0
                  );
                  if (filterListPromotionAmount.length > 0 || filterListPromotionItem.length > 0) {
                    const transactionPromotion = [];
                    for (const promotion of filterListPromotionAmount) {
                      const p = checkDiscountAmount(promotion, saleAfterDiscount ?? 0);
                      if (p) {
                        saleAfterDiscount = Number(saleAfterDiscount ?? 0) - p.saleAfterDiscount;
                        transactionPromotion.push(p);
                      }
                    }
                    dispatch(setListPromotionAmount(transactionPromotion));
                    dispatch(setListPromotionItem(filterListPromotionItem));
                  }
                  if (listFreeItemV2.length > 0) {
                    if (filterListPromotionItem.length > 0) {
                      const filterFreeItemV2 = filterListPromotionItem.filter((f) =>
                        listFreeItemV2.find(
                          (s) =>
                            s.promotion_id === f.promotion_id && Number(s.qty) !== Number(f.counted_multiply)
                        )
                      );
                      if (filterFreeItemV2.length > 0) {
                        const filterUpdatedFreeItemV2 = listFreeItemV2.filter(
                          (f) => !filterFreeItemV2.find((s) => s.promotion_id === f.promotion_id)
                        );
                        dispatch(updateListFreeItemV2(filterUpdatedFreeItemV2));
                      } else {
                        const filterFreeItem = listFreeItemV2.filter((r) =>
                          filterListPromotionItem.find(
                            (s) =>
                              s.promotion_id === r.promotion_id && s.promotion_tier_id === r.promotion_tier_id
                          )
                        );
                        dispatch(updateListFreeItemV2(filterFreeItem));
                      }
                    } else {
                      dispatch(updateListFreeItemV2([]));
                    }
                  }
                } else {
                  onOpenVoucher();
                }
              } else {
                const filterOverlap: IGetPromotionData[] = result.allPromotion.filter(
                  (p) => p.is_overlap === true
                );
                const filterNotOverlap: IGetPromotionData[] = result.allPromotion.filter(
                  (p) => p.is_overlap === false
                );
                if (
                  (result.allPromotion[0].is_overlap && filterNotOverlap.length === 0) ||
                  (!result.allPromotion[0].is_overlap && filterOverlap.length === 0)
                ) {
                  dispatch(setListPromotionAmount(result.transactionPromotion));
                  dispatch(setListPromotionItem(result.itemPromotion));
                }
              }
            } else {
              dispatch(setListPromotionAmount(result.transactionPromotion));
              dispatch(setListPromotionItem(result.itemPromotion));
              dispatch(updateListFreeItemV2([]));
            }
          } else {
            if (listPromotionAmount.length === 0 && listPromotionItem.length === 0) {
              dispatch(setListPromotionAmount(result.transactionPromotion));
              dispatch(setListPromotionItem(result.itemPromotion));
              dispatch(updateListFreeItemV2([]));
            } else {
              let filterActivePromoItem = result.itemPromotion;
              if (settings.choose_promotion_type === 'choose-promotion') {
                const transactionPromotion = [];
                if (listPromotionAmount.length > 0) {
                  const filterActivePromoAmount = result.allPromotion.filter(
                    (r) =>
                      listPromotionAmount.find((s) => s.promotion.promotion_id === r.promotion_id) ||
                      (listPromotionAmount[0].promotion.is_overlap &&
                        r.is_overlap &&
                        r.discount_reward &&
                        r.discount_reward.length > 0)
                  );
                  for (const promotion of filterActivePromoAmount) {
                    const p = checkDiscountAmount(promotion, Number(saleAfterDiscount ?? 0));
                    if (p) {
                      saleAfterDiscount = Number(saleAfterDiscount ?? 0) - p.saleAfterDiscount;
                      transactionPromotion.push(p);
                    }
                  }
                }
                dispatch(setListPromotionAmount(transactionPromotion));
                if (listPromotionItem.length > 0) {
                  filterActivePromoItem = result.allPromotion.filter(
                    (r) =>
                      (listPromotionItem.find((s) => s.promotion_id === r.promotion_id) ||
                        (listPromotionItem[0].is_overlap && r.is_overlap)) &&
                      r.product_reward &&
                      r.product_reward.length > 0
                  );
                }
                dispatch(setListPromotionItem(filterActivePromoItem));
              } else {
                dispatch(setListPromotionAmount(result.transactionPromotion));
                dispatch(setListPromotionItem(result.itemPromotion));
              }
              if (listFreeItemV2.length > 0 && filterActivePromoItem.length > 0) {
                const filterFreeItemV2 = filterActivePromoItem.filter((f) =>
                  listFreeItemV2.find(
                    (s) => s.promotion_id === f.promotion_id && Number(s.qty) !== Number(f.counted_multiply)
                  )
                );
                if (filterFreeItemV2.length > 0) {
                  const filterUpdatedFreeItemV2 = listFreeItemV2.filter(
                    (f) => !filterFreeItemV2.find((s) => s.promotion_id === f.promotion_id)
                  );
                  dispatch(updateListFreeItemV2(filterUpdatedFreeItemV2));
                } else {
                  const filterFreeItem = listFreeItemV2.filter((r) =>
                    filterActivePromoItem.find(
                      (s) => s.promotion_id === r.promotion_id && s.promotion_tier_id === r.promotion_tier_id
                    )
                  );
                  dispatch(updateListFreeItemV2(filterFreeItem));
                }
              }
            }
          }

          setIsLoadingPromotion(false);
        })
        .catch(() => {
          setIsLoadingPromotion(false);
        });
    }
  }, [isOnline, listCart, customerInfo, currentHours, listAllPromotion, listFreeItemV2]);

  const getPromotionActive = () => {
    const currentPromotionAmount = listPromotionAmount.map((p) => p.promotion.promotion_id);
    const currentPromotionItem = listPromotionItem.map((p) => p.promotion_id);
    return [...new Set([...currentPromotionAmount, ...currentPromotionItem])].length;
  };

  const scrollToBottom = React.useCallback(() => {
    if (itemList.current !== null) {
      itemList.current.scrollTo(0, itemList.current.scrollHeight);
    }
  }, [listItemCart]);

  const disabledCheckoutButton = React.useCallback(
    (type: string) => {
      if (isLoadingCheckout) return true;
      const totalItems = getTotalItemsCart(listItemCart);
      if ((type === 'disabled' && totalItems <= 0) || isLoadingPromotion) return true;
      if (type === 'loading' && totalItems <= 0) return false;
      return false;
    },
    [isLoadingCheckout, isLoadingPromotion, listItemCart]
  );

  const submitConfirmRemovePromotion = () => {
    if (deletedItem?.action === 'DECREASE-QTY') {
      dispatch(decrementItem(deletedItem.item_id ?? 0));
    } else if (deletedItem?.action === 'UPDATE-QTY') {
      const itemUpdate = {
        item_id: deletedItem?.item_id ?? 0,
        quantity: Number(deletedItem?.qty ?? 0) <= 0 ? 1 : Number(deletedItem?.qty ?? 0),
      };
      dispatch(updateQuantityItem(itemUpdate));
    } else if (deletedItem?.action === 'UPDATE-ITEM') {
      dispatch(updateItemDetail(deletedItem?.item || ({} as IItemCart)));
    } else if (deletedItem?.action === 'DELETE-ITEM') {
      dispatch(removeItem(deletedItem?.item_id ?? 0));
    }
    if (customerInfo && !deletedCustomer && isCustomerAction) setDeletedCustomer(true);
    setDeletedItem(null);
    onCloseConfirmRemovePromo();
  };

  const cancelChangeTransaction = () => {
    dispatch(togglePopup(false));
    dispatch(setIsLogout(false));
  };

  const handlePingRegister = async () => {
    try {
      const browser = Bowser.getParser(window.navigator.userAgent);
      await pingRegister(registerInfo?.location_id ?? 0, registerInfo?.register_id ?? 0, {
        device_id: registerInfo?.device_info?.device_id ?? localStorage.getItem('device_id') ?? '',
        device_name:
          registerInfo?.device_info?.device_name ??
          browser.getOSName().concat(' - ', packageJson.version, ' - ', browser.getBrowserName()),
        device_type: registerInfo?.device_info?.device_type ?? 'WEB',
      });
      onCloseOfflinePromotion();
    } catch (error) {
      notification('', 'Gagal melakukan ping ke server', 'error', 3000);
    }
  };

  const deletePromotion = () => {
    dispatch(setListAllPromotion([]));
    dispatch(setListPromotionAmount([]));
    dispatch(setListPromotionItem([]));
    dispatch(setListValidVoucher([]));
    dispatch(updateListFreeItemV2([]));
    onCloseOfflinePromotion();
  };

  React.useEffect(() => {
    if (customerInfo && deletedCustomer) {
      customerAction();
    }
  }, [deletedCustomer]);

  // scroll to bottom of list item
  // when add new item to cart
  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  //  Observer if has promotion transaction
  React.useEffect(() => {
    if (isOnline) onGetTransactionPromo();
  }, [onGetTransactionPromo, isOnline]);

  React.useEffect(() => {
    if (!continuePayment && listCart.length > 0 && !isCheckout) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeout = setTimeout(() => {
        onGetPromotionItems();
      }, 800);

      setDebounceTimeout(timeout);
    }

    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [listCart, customerInfo, isCheckout, continuePayment]);

  React.useEffect(() => {
    if (listCart.length === 0) {
      dispatch(setListDiscountItems([]));
      dispatch(setListPromotionAmount([]));
      dispatch(setListPromotionItem([]));
      dispatch(setListAllPromotion([]));
      dispatch(updateListFreeItemV2([]));
      dispatch(setListValidVoucher([]));
    }
  }, [listCart]);

  // Observer if has promotion item child of getTransactionPromotion
  React.useEffect(() => {
    if (isOnline && processPromotions) {
      if (!isEqual(resultPromotion, processPromotions)) {
        setResultPromotion(processPromotions);
      }
    }
  }, [processPromotions, isOnline]);

  // Obsever if has promotion has rule free items or not
  React.useEffect(() => {
    if (isOnline) onGetFreeItems();
  }, [onGetFreeItems, isOnline]);

  React.useEffect(() => {
    setMissingProduct([]);
  }, [listItemCart]);

  React.useEffect(() => {
    if (!isOnline) {
      const findLimitedPromotionAmount = listPromotionAmount.find(
        (promo) =>
          (promo.promotion.is_limited && promo.promotion.quota > 0) ||
          promo.promotion.customer_limit ||
          (promo.promotion.use_voucher && Number(promo.promotion.quota_voucher ?? 0) > 0)
      );
      const findLimitedPromotionItem = listPromotionItem.find(
        (promo) =>
          (promo.is_limited && promo.quota > 0) ||
          promo.customer_limit ||
          (promo.use_voucher && Number(promo.quota_voucher ?? 0) > 0)
      );
      if (findLimitedPromotionAmount || findLimitedPromotionItem) {
        onOpenOfflinePromotion();
      }
    }
  }, [isOnline, listPromotionAmount, listPromotionItem]);

  return (
    <>
      <Flex
        bg='white'
        flexDir='column'
        w='full'
        borderLeft='1px'
        borderColor='gray.200'
        roundedBottomRight='lg'
        roundedTopEnd='lg'
        h={!isOnline || initSync || stepDownload === 'done' ? 'calc(100vh - 90px)' : 'calc(100vh - 30px)'}
        overflowY='auto'
      >
        <HeaderCart
          listItemCart={listItemCart}
          listItemReturn={listItemReturn}
          orderReturn={orderReturn}
          order={order}
          returnMode={returnMode}
          totalSavedCart={totalSavedCart ?? 0}
          onOpen={onOpen}
        />
        <Stack
          w='full'
          px={4}
          className='space-x-0 space-y-2 lg:space-x-2 lg:space-y-0'
          flexDir={['column', 'column', 'column', 'row', 'row']}
        >
          {!returnMode && (
            <ButtonSelect
              w='full'
              isSelected={salesmenInfo ? true : false}
              onClick={salesmanAction}
              isDisabled={returnMode || continuePayment || isLoadingCheckout}
            >
              {salesmenInfo ? salesmenInfo.salesmen_name : 'Pilih Saleman'}
            </ButtonSelect>
          )}
          <ButtonSelectCustomer
            w='full'
            isSelected={returnMode ? Boolean(customer) : Boolean(customerInfo)}
            onClick={onChangeCustomer}
            isDisabled={continuePayment || isLoadingCheckout || isContact}
            isContact={isContact}
          >
            {isContact
              ? 'Harap Tunggu'
              : returnMode
              ? customer?.contact_name
              : customerInfo?.contact_name ?? 'Pilih Customer'}
          </ButtonSelectCustomer>
        </Stack>
        <Divider my={3} mx={4} w='auto' />
        <Stack minH='250px' flex={1}>
          {listItemCart.length === 0 && !returnMode ? (
            <Flex h='full' alignItems='center' justifyContent='center'>
              <CartEmpty />
            </Flex>
          ) : null}
          {listItemReturn && listItemReturn.length === 0 && returnMode ? (
            <Flex h='full' alignItems='center' justifyContent='center'>
              <CartEmpty />
            </Flex>
          ) : null}
          {listCart.length > 0 && !returnMode && (
            <Box flexGrow={1} textAlign='left' overflowY='auto' ref={itemList} zIndex={2}>
              <VStack px={4} pb={4} zIndex={10}>
                {listCart.length > 0 ? (
                  <Title fontSize='14px' textAlign='left' w='full' pt={2}>
                    {listCart.length} items
                  </Title>
                ) : null}
                {listCart.map((item: IItemCart, index: number) => (
                  <CardItem
                    key={index}
                    item={item}
                    onChangeItem={(value) => {
                      setIsFreeItem(false);
                      onOpenChangeItem();
                      setItemDetal(value);
                    }}
                    totalDetail={totalDetail}
                    isNotFound={missingProducts.find((i) => i === item.item_code) ? true : false}
                    onOpenConfirmRemovePromo={onOpenConfirmRemovePromo}
                    setDeletedItem={setDeletedItem}
                    rollbackQtyItem={rollbackQtyItem}
                    setRollbackQtyItem={setRollbackQtyItem}
                  />
                ))}
                {freeItems.length !== 0
                  ? freeItems.map((item: IItemCart, index: number) => (
                      <CardItem
                        key={index}
                        item={item}
                        isNotFound={missingProducts.find((i) => i === item.item_code) ? true : false}
                        onChangeItem={(value) => {
                          setIsFreeItem(true);
                          onOpenChangeItem();
                          setItemDetal(value);
                        }}
                        onOpenConfirmRemovePromo={onOpenConfirmRemovePromo}
                        setDeletedItem={setDeletedItem}
                        rollbackQtyItem={rollbackQtyItem}
                        setRollbackQtyItem={setRollbackQtyItem}
                      />
                    ))
                  : null}
              </VStack>
            </Box>
          )}
          {listItemReturn && listItemReturn.length > 0 && returnMode ? (
            <Box flexGrow={1} textAlign='left' overflowY='auto' ref={itemList} h='full'>
              <VStack px={4} position='relative' pb={4}>
                {listItemReturn.length > 0 && (
                  <Title fontSize='14px' textAlign='left' w='full' pt={2}>
                    {listItemReturn.length} items
                  </Title>
                )}
                {listItemReturn.map((item: IItemReturn, index: number) => (
                  <CardItemReturn
                    key={index}
                    item={item}
                    onChangeItem={() => {
                      onOpenChangeItem();
                    }}
                  />
                ))}
              </VStack>
            </Box>
          ) : null}
          <Footer>
            {/* showing list total if item on cart exists */}
            {disableEditItem && !returnMode && !continuePayment ? (
              <Button
                w='full'
                mb={3}
                size='sm'
                variant='outline'
                leftIcon={<CartIcon color='jubelio.black' />}
                onClick={() => dispatch(togglePopup(true))}
              >
                Ubah Transaksi
              </Button>
            ) : null}
            {listItemCart.length > 0 && !returnMode ? (
              <DetailTotal
                showTotal={showTotal}
                onShowTotal={setShowTotal}
                totalDetail={totalDetail}
                taxIncluded={settings.tax_included ?? false}
                transactionPromotion={listPromotionAmount}
                isReturn={false}
              />
            ) : null}

            {listItemReturn && listItemReturn.length > 0 && returnMode ? (
              <DetailTotal
                totalDetail={totalDetailReturn as unknown as ITotalDetail}
                orderReturn={orderReturn}
                showTotal={showTotalReturn}
                onShowTotal={setShowTotalReturn}
                isReturn={true}
                taxIncluded={orderReturn.is_tax_included ?? false}
              />
            ) : null}

            {/* Hide all button if user's on checkout page */}
            {!disableEditItem ? (
              <div className='flex items-center space-x-2'>
                <Tooltip hasArrow label='Simpan transaksi' bg='jubelio.grey200'>
                  <IconButton
                    variant='outline'
                    aria-label='save-icon'
                    icon={<SaveIcon fill='jubelio.grey200' />}
                    onFocus={(e) => e.preventDefault()}
                    onClick={onOpenCart}
                    isDisabled={disabledCheckoutButton('disabled')}
                  />
                </Tooltip>
                <Tooltip hasArrow label='Ubah transaksi' bg='jubelio.grey200'>
                  <IconButton
                    variant='outline'
                    aria-label='edit-icon'
                    icon={<EditIcon width='25px' height='24px' fill='jubelio.grey200' />}
                    onFocus={(e) => e.preventDefault()}
                    onClick={onOpenTransaction}
                    isDisabled={disabledCheckoutButton('disabled')}
                  />
                </Tooltip>
                <BadgePromotion
                  variant='outline'
                  aria-label='edit-icon'
                  counter={getPromotionActive()}
                  icon={<PromotionIcon width='25px' height='24px' fill='jubelio.grey200' />}
                  onFocus={(e) => e.preventDefault()}
                  onClick={onOpenVoucher}
                  isDisabled={disabledCheckoutButton('disabled')}
                />
                <Button
                  isDisabled={disabledCheckoutButton('disabled')}
                  isLoading={disabledCheckoutButton('loading')}
                  variant='primary'
                  size='md'
                  w='full'
                  onClick={() => checkout(totalDetail)}
                >
                  Selanjutnya
                </Button>
              </div>
            ) : null}
          </Footer>
        </Stack>
      </Flex>
      {/* Modal Pop */}
      <ModalCart isOpen={isOpenCart} onClose={onCloseCart} totalOfAmount={totalDetail.grandTotal} />
      <ModalChangeItem
        isOpen={isOpenChangeItem}
        onClose={onCloseChangeItem}
        items={itemDetal}
        onOpenConfirmRemovePromo={onOpenConfirmRemovePromo}
        setDeletedItem={setDeletedItem}
        isFreeItem={isFreeItem}
      />
      <ModalVoucherPromotion isOpen={isOpenVoucher} onClose={onCloseVoucher} />
      <ModalTransaction isOpen={isOpenTransaction} onClose={onCloseTransaction} totalDetail={totalDetail} />
      {/* Modal for list customer */}
      <ModalListCustomer
        title='Pilih Pelanggan'
        isOpen={isOpenCustomer}
        onClose={onCloseCustomer}
        openAddCustomer={() => {
          onOpenAddCustomer();
          onCloseCustomer();
        }}
        chooseReturnCustomer={(value: ICustomerInfo) => {
          dispatch(setCustomerOrderReturn(value));
          onCloseCustomer();
        }}
      />

      <ModalSalesman title='Pilih Saleman' isOpen={isOpenSalesman} onClose={onCloseSalesman} />
      {/* Modal for create new customer */}
      <ModalCustomer
        isOpen={isOpenAddCustomer}
        onClose={onCloseAddCustomer}
        onSubmit={() => {
          onCloseAddCustomer();
          onOpenCustomer();
        }}
      />

      <ModalConfirmation
        title={messages.modal.title_change_transaction}
        subtitle={messages.modal.change_transaction}
        cancelText='Batal'
        okText='Ya'
        isOpen={isOpenPopup ?? false}
        isOnline
        onSubmit={onSubmitConfirmationChangeTransaction}
        onClose={cancelChangeTransaction}
      />

      <ModalConfirmation
        title={modal.title_change_transaction}
        isOpen={isOpenConfirmRemovePromo}
        onClose={() => {
          setIsCustomerAction(false);
          onCloseConfirmRemovePromo();
          setRollbackQtyItem(deletedItem?.item_id ?? null);
          setDeletedItem(null);
        }}
        subtitle={modal.subtitle_remove_confirmation}
        cancelText={modal.cancel_voucher_text}
        okText={modal.ok_continue_order_text}
        onSubmit={() => submitConfirmRemovePromotion()}
        loadingSubmit={false}
        isOnline={isOnline}
      />

      <ModalConfirmation
        title={modal.title_offline_promotion}
        isOpen={isOpenOfflinePromotion}
        onClose={deletePromotion}
        subtitle={modal.subtitle_offline_promotion}
        cancelText={modal.ok_delete_text}
        okText={modal.ok_offline_promotion}
        onSubmit={() => handlePingRegister()}
        loadingSubmit={false}
        isOnline={true}
      />

      <ModalListCart isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CardDetaill;
