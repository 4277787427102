import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ChecklistIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 32 32' {...props}>
    <path
      fill='currentColor'
      strokeWidth='2'
      d='M11.5,25.5a2,2,0,0,1-1.41-.59l-5.5-5.5a2,2,0,0,1,2.83-2.83l4.09,4.09L24.59,7.59a2,2,0,0,1,2.83,2.83l-14.5,14.5A2,2,0,0,1,11.5,25.5Z'
    />
  </Icon>
);

export default React.memo(ChecklistIcon);
