import React from 'react';
import { ISerialBatchNumber } from 'types/inventory';
import { IItemCart } from 'types/products.types';

type ISelectOptionsSNBN = {
  value: string;
  label: string;
};

type IUseItemBatchNumber = {
  locationId: number;
  isOnline: boolean;
  isOpen?: boolean;
  isFetching?: boolean;
  itemCart?: IItemCart;
  dataOptions?: ISerialBatchNumber[];
};

const useItemBatchNumber = ({ dataOptions, isOnline, isOpen, itemCart, isFetching }: IUseItemBatchNumber) => {
  const [selectedBatchNumber, setSelectedBatchNumber] = React.useState<ISerialBatchNumber[]>([]);
  const [optionBatchNumber, setOptionBatchNumber] = React.useState<ISelectOptionsSNBN[]>([]);

  // Exclude selected batch number from options batch number to avoid duplication
  const optionsBatchNumber = React.useMemo(() => {
    const selectedBatchNos = new Set(selectedBatchNumber.map((sn) => sn.batch_no));
    return optionBatchNumber.filter((item) => !selectedBatchNos.has(item.value));
  }, [optionBatchNumber, selectedBatchNumber]);

  // Get batch number from IndexedDB
  const getOfflineSNBN = async () => {
    const data = itemCart?.list_batch_number?.map((bn) => ({
      value: bn.batch_no || '',
      label: bn.batch_no || '',
    }));

    setOptionBatchNumber(data || []);
    if (itemCart?.use_batch_number) {
      const newBatchNumbers = itemCart.batch_number
        ? itemCart.batch_number.map((bn) => ({ ...bn, qty: bn.qty || 0 }))
        : [{ batch_no: '', qty: 0 }];

      return setSelectedBatchNumber(newBatchNumbers);
    }
  };

  // Get batch number from server and add default batch number
  const getOnlineSNBN = async () => {
    const batchNumber =
      dataOptions?.map((item) => ({
        value: item.batch_no || '',
        label: item.batch_no || '',
      })) || [];

    setOptionBatchNumber(batchNumber);

    if (itemCart?.use_batch_number) {
      const newBatchNumbers = itemCart.batch_number
        ? itemCart.batch_number.map((bn) => ({ ...bn, qty: bn.qty || 0 }))
        : [{ batch_no: '', qty: 0 }];

      return setSelectedBatchNumber(newBatchNumbers);
    }
  };

  // Update state selected batch number
  const onChangeBatchNumber = (value: string, index: number) => {
    const findBN =
      selectedBatchNumber && selectedBatchNumber.find((sn) => sn.batch_no === value && value !== '');

    // if batch number is not found, add new batch number to state
    if (!findBN) {
      const listSelectedBatchNumber =
        selectedBatchNumber &&
        selectedBatchNumber.map((item, idx) => (index === idx ? { ...item, batch_no: value, qty: 0 } : item));

      setSelectedBatchNumber(listSelectedBatchNumber);
    }
  };

  // Update batch number quantity when user click increase or decrease button
  // each batch number has its own quantity and it's not shared with other batch number
  const onCountBatchQty = (action: 'increase' | 'decrease', idx: number) => {
    if (!selectedBatchNumber) return;
    if (action === 'increase') {
      const newBatchNumber = selectedBatchNumber.map((item, i) => {
        if (i !== idx) return item;

        const findBN = isOnline
          ? dataOptions?.find((bn) => bn.batch_no === item.batch_no)
          : itemCart?.list_batch_number?.find((bn) => bn.batch_no === item.batch_no);

        console.log(findBN);
        const maxQty = Number(findBN?.qty || 0);
        const newQty = Math.min(Number(item.qty) + 1, maxQty);

        return {
          ...item,
          qty: newQty,
        };
      });

      setSelectedBatchNumber(newBatchNumber);
    } else if (action === 'decrease') {
      const newBatchNumber =
        selectedBatchNumber &&
        selectedBatchNumber.map((item, index: number) =>
          idx === index ? { batch_no: item.batch_no, qty: Number(item.qty) - 1 } : item
        );

      setSelectedBatchNumber(newBatchNumber && newBatchNumber.filter((item) => Number(item.qty) > 0));
    }
  };

  const addNewBatchNumber = () => {
    if (selectedBatchNumber && selectedBatchNumber.length > 0) {
      setSelectedBatchNumber([...selectedBatchNumber, { batch_no: '', qty: 0 }]);
    } else {
      setSelectedBatchNumber((prev) => [...prev, { batch_no: '', qty: 0 }]);
    }
  };

  const onChangeBatchNumberQty = (value: number, idx: number) => {
    if (!selectedBatchNumber || isNaN(value)) return;

    const updatedBatchNumbers = selectedBatchNumber.map((item, index) => {
      if (index !== idx) return item;

      // const maxQty = dataOptions?.find((bn) => bn.batch_no === item.batch_no)?.qty ?? 0;i
      const maxQty = isOnline
        ? dataOptions?.find((bn) => bn.batch_no === item.batch_no)?.qty ?? 0
        : itemCart?.list_batch_number?.find((bn) => bn.batch_no === item.batch_no)?.qty ?? 0;

      return {
        ...item,
        qty: Math.min(Math.max(value, 0), Number(maxQty)),
      };
    });

    setSelectedBatchNumber(updatedBatchNumbers.filter((item) => item.qty > 0));
  };

  React.useEffect(() => {
    if (!isOpen) return;
    if (isOnline && !isFetching) {
      getOnlineSNBN();
    } else {
      getOfflineSNBN();
    }
  }, [isFetching, isOpen, itemCart, isOnline]);

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedBatchNumber([]);
      setOptionBatchNumber([]);
    }
  }, [isOpen, isOnline]);

  return {
    onChangeBatchNumber,
    onChangeBatchNumberQty,
    onCountBatchQty,
    optionsBatchNumber,
    selectedBatchNumber,
    addNewBatchNumber,
  };
};

export default useItemBatchNumber;
