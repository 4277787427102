import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';

const Facebook: React.FC<IconProps> = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  >
    <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z'></path>
  </Icon>
);

export default Facebook;
