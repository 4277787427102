import { Button, Checkbox } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useSwithConnection from 'hooks/useSwithConnection';
import * as React from 'react';
import { setOnlineStatus, setSyncPayload } from 'redux/reducer/registers';

import { Modal } from '.';

interface ModalConnectionProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalConnection: React.FC<ModalConnectionProps> = (props: ModalConnectionProps) => {
  const { isOpen, onClose } = props;
  const [isRemoveData, setIsRemoveData] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { handleSyncDataToServer } = useSwithConnection();

  const message = {
    'offline-mode':
      'Sinkronkan data Diskon, Buku Harga, Customer, Barang, dan Stok untuk Mode Offline. Jika sudah pernah sinkron, lanjut ke Mode Offline untuk bekerja tanpa internet.',
    'online-mode': 'Sinkronkan data order dan transaksi saat Mode Offline untuk melanjutkan ke Mode Online.',
  };

  // Make POS to offline mode without downloading data
  const handleKeepOffline = () => {
    dispatch(setOnlineStatus(false));
    dispatch(setSyncPayload({ stepDownload: '' }));
    onClose();
  };

  // Make POS to online mode with downloading data
  const handleSyncData = () => {
    if (!isOnline) {
      dispatch(setOnlineStatus(true));
      if (isRemoveData) {
        setIsRemoveData(true);
        handleSyncDataToServer(!isOnline ? false : true, true);
      }
    } else {
      handleSyncDataToServer(!isOnline ? false : true);
    }
    onClose();
  };

  return (
    <Modal isShow={isOpen} onClose={onClose} className='max-w-xl'>
      <Modal.Body className='p-[3rem]'>
        <div className='space-y flex flex-col space-y-10'>
          <div className='flex flex-col space-y-5 text-center'>
            <h1 className='text-2xl font-bold'>Sinkron Data</h1>
            <div className='text-sm text-gray-600'>
              <p>{isOnline ? message['offline-mode'] : message['online-mode']}</p>
              {!isOnline && (
                <div>
                  <span className='flex items-center justify-center space-x-2'>
                    <Checkbox
                      colorScheme='red'
                      isChecked={isRemoveData}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIsRemoveData(e.target.checked);
                      }}
                    >
                      Hapus data perangkat
                    </Checkbox>
                  </span>
                  <p>*semakin banyak data yang disimpan akan memberatkan kerja sistem</p>
                </div>
              )}
            </div>
          </div>
          <div className='flex justify-center space-x-3'>
            <Button variant='outline' onClick={handleKeepOffline}>
              Lanjutkan Mode Offline
            </Button>
            <Button variant='primary' onClick={handleSyncData}>
              {isOnline ? 'Download Data' : 'Sinkronkan Data'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConnection;
