import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SyncIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 14 20' {...props}>
    <path
      d='M6.99992 15.0006C5.67384 15.0006 4.40207 14.4739 3.46438 13.5362C2.5267 12.5985 1.99992 11.3267 1.99992 10.0007C1.99992 9.16732 2.20825 8.35898 2.58325 7.66732L1.36659 6.45065C0.691379 7.51156 0.332902 8.7431 0.333252 10.0007C0.333252 11.7688 1.03563 13.4645 2.28587 14.7147C3.53612 15.9649 5.23181 16.6673 6.99992 16.6673V19.1673L10.3333 15.834L6.99992 12.5006V15.0006ZM6.99992 3.33398V0.833984L3.66659 4.16732L6.99992 7.50065V5.00065C8.326 5.00065 9.59777 5.52743 10.5355 6.46512C11.4731 7.4028 11.9999 8.67457 11.9999 10.0007C11.9999 10.834 11.7916 11.6423 11.4166 12.334L12.6333 13.5507C13.3085 12.4897 13.6669 11.2582 13.6666 10.0007C13.6666 8.23254 12.9642 6.53685 11.714 5.28661C10.4637 4.03636 8.76803 3.33398 6.99992 3.33398V3.33398Z'
      fill='#8999A5'
    />
  </Icon>
);

export default SyncIcon;
