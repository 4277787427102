import { alert } from 'constant/messages';
import { useNotification, useOrder, useReturn } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setInitSync } from 'redux/reducer/registers';
import commonRequest from 'services/http/common.request';
import orders from 'services/indexdb/orders';
import returnOrder from 'services/indexdb/return-order';

import useDownloadManager from './useDownloadManager';

const useSwithConnection = () => {
  const dispatch = useAppDispatch();
  const { initSync } = useAppSelector((state) => state.register);

  const { sendOrder, sendOrderError } = useOrder();
  const { sendOrderReturn, sendOrderReturnError } = useReturn();
  const { notification } = useNotification();
  const { startDownloadCommonData, startDownloadData, clearIndexDB, downloadDefaultContact } =
    useDownloadManager();

  const handleSyncDataToServer = async (downloadData = false, removeDataLocal = false) => {
    try {
      await commonRequest.logPos(downloadData ? 'DOWNLOAD-DATA' : 'CLEAR-DATA', '');
      if (!downloadData) {
        console.log('[INFO] Sending data to server befor go online');
        const failTransaction = await orders.get('', 3);
        const failReturn = await returnOrder.getUnsync('');

        const tasks = [
          ...failTransaction.map(async (transaction) => {
            try {
              await sendOrder(JSON.parse(transaction.request_payload));
            } catch (error: any) {
              const order = {
                ...JSON.parse(transaction.request_payload),
                errorMessage: error.response.data.message,
              };
              await sendOrderError(order);
            }
          }),
          ...failReturn.map(async (orderReturn) => {
            try {
              await sendOrderReturn(JSON.parse(orderReturn.request_payload));
            } catch (error: any) {
              const orderReturnError = {
                ...JSON.parse(orderReturn.request_payload),
                errorMessage: error.response.data.message,
              };
              await sendOrderReturnError(orderReturnError);
            }
          }),
        ];
        await clearIndexDB(!removeDataLocal);
        if (removeDataLocal) {
          await downloadDefaultContact();
        }
        tasks.push(startDownloadCommonData());
        await Promise.all(tasks);
      } else {
        console.log('[INFO] Downloading data from server to local before go offline');
        dispatch(setInitSync(true));
        await startDownloadData();
      }
    } catch (error) {
      notification('', alert.error_send_order, 'error', 3000);
    }
  };

  return { handleSyncDataToServer, initSync };
};

export default useSwithConnection;
