import { Button, FormControl, FormErrorMessage, FormLabel, Textarea, VStack } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { SubTitle, Title } from 'components/typography';
import { useLiveQuery } from 'dexie-react-hooks';
import { Form, Formik } from 'formik';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { resetOrder, updateTotalSavedCart } from 'redux/reducer/sales';
import cart from 'services/indexdb/cart';
import { db } from 'services/indexdb/connection';
import { ICartList } from 'types/sales.types';
import { currencyFormat } from 'utils';

import { Modal } from '.';

interface ModalCartProps {
  isOpen: boolean;
  onClose: () => void;
  totalOfAmount: number;
}

const ModalCart: React.FC<ModalCartProps> = ({ isOpen, onClose, totalOfAmount }: ModalCartProps) => {
  const dispatch = useAppDispatch();

  const { notification } = useNotification();
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    listItemCart,
    order,
    customerInfo,
    salesmenInfo,
    shippingInfo,
    pos_is_shipping,
    discount_trx_amount,
    discount_trx_percent,
    other_cost_percent,
    isOtherCostPercent,
    other_cost,
    cartId,
    isDiscountPercentTrx,
  } = useAppSelector((state) => state.sales);
  const getCart = useLiveQuery(() => db.cart.where('id').equals(Number(cartId)).first(), []);

  const initialValues: ICartList = {
    name: getCart ? getCart.name : customerInfo?.contact_name ? customerInfo.contact_name : '',
    salesorder_no: order?.salesorder_no ?? '',
    notes: '',
    isOtherCostPercent: isOtherCostPercent,
    otherCostAmount: other_cost ?? 0,
    discountAmount: 0,
    total: totalOfAmount,
    items: listItemCart,
    customer: customerInfo ?? null,
    salesmenInfo: salesmenInfo ?? null,
    shipping: pos_is_shipping ? shippingInfo : null,
    discount_trx_amount: discount_trx_amount ?? 0,
    discount_trx_percent: discount_trx_percent ?? 0,
    other_cost_percent: other_cost_percent ?? 0,
    isDiscountPercentTrx: isDiscountPercentTrx,
  };

  const validate = (values: ICartList) => {
    const errors: Record<string, string> = {};
    if (!values.name) {
      errors.name = 'Harap masukan nama transaksi';
    }

    return errors;
  };

  const onSave = async (values: ICartList) => {
    try {
      setLoading(true);
      const existName = await cart.findName(values.name);
      if (!existName) {
        await cart.add(values);
      } else {
        await cart.updateCart(values);
      }

      const getCart = await cart.get();
      setLoading(false);
      onClose();
      dispatch(resetOrder());
      dispatch(updateTotalSavedCart(getCart.length));
    } catch (error) {
      notification('', 'Terjadi kesalahan, silahkan coba lagi', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isShow={isOpen} onClose={onClose} title='Simpan Transaksi'>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSave}>
        {({ values, errors, handleBlur, handleChange, touched, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <SubTitle>Total Transaksi</SubTitle>
              <Title my={2} color='jubelio.primary'>
                {currencyFormat(totalOfAmount)}
              </Title>
              <div className='divider my-4' />
              <VStack spacing={4}>
                <FormControl
                  isInvalid={Boolean(errors.name && touched.name)}
                  display='flex'
                  alignItems='center'
                  className='space-x-5'
                >
                  <FormLabel w='92px' htmlFor='name' fontSize='14px'>
                    Nama
                  </FormLabel>
                  <VStack w='full' alignItems='flex-start'>
                    <Input
                      id='name'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                    />
                    <FormErrorMessage>{touched.name && errors.name}</FormErrorMessage>
                  </VStack>
                </FormControl>
                <FormControl display='flex' className='space-x-5' alignItems='center'>
                  <FormLabel w='92px' htmlFor='notes' fontSize='14px'>
                    Catatan
                  </FormLabel>
                  <Textarea
                    id='notes'
                    name='notes'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes as string}
                  />
                </FormControl>
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <Button
                isDisabled={loading || !values.name}
                variant='primary'
                type='submit'
                loadingText='Menyimpan..'
                isLoading={loading}
              >
                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(ModalCart);
