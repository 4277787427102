import { IOrderReturn, IResponseOrderReturn } from 'types/return.types';
import { IResponseCancel } from 'types/sales.types';

import request from './request';

export default {
  sendReturn: async (
    payload: IOrderReturn,
    isWmsMigrated = 'pos'
  ): Promise<IResponseOrderReturn> => {
    try {
      const res = await request.post(`/sales/${isWmsMigrated}/v3/sales/return`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  cancelReturn: async (
    pos_return_id: number,
    authorized_user_id?: number,
    is_web?: boolean
  ): Promise<IResponseCancel> => {
    try {
      const res = await request.post(`/sales/pos/v3/return/cancel`, {
        pos_return_id,
        authorized_user_id,
        is_web,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
