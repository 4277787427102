import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SyncIconDot = (props: IconProps): JSX.Element => (
  <Icon
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M3 2v6h6' />
    <path d='M21 12A9 9 0 0 0 6 5.3L3 8' />
    <path d='M21 22v-6h-6' />
    <path d='M3 12a9 9 0 0 0 15 6.7l3-2.7' />
    <circle cx='12' cy='12' r='1' />
  </Icon>
);

export default SyncIconDot;
