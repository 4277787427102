import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BundleIcon: React.FC<IconProps> = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.5 20C28.8431 20 27.5 21.3431 27.5 23V32.1158C27.5 33.3664 27.1093 34.5856 26.3824 35.6032L21.6764 42.1916C20.5861 43.7181 20 45.547 20 47.4228V74C20 77.3137 22.6863 80 26 80H47C48.0929 80 49.1175 79.7078 50 79.1973C50.8825 79.7078 51.9071 80 53 80H74C77.3137 80 80 77.3137 80 74V47.4228C80 45.547 79.4139 43.7181 78.3236 42.1916L73.6176 35.6032C72.8907 34.5856 72.5 33.3664 72.5 32.1158V23C72.5 21.3431 71.1569 20 69.5 20H57.5C55.8431 20 54.5 21.3431 54.5 23V32.1158C54.5 33.3664 54.1093 34.5856 53.3824 35.6032L50 40.3386L46.6176 35.6032C45.8907 34.5856 45.5 33.3664 45.5 32.1158V23C45.5 21.3431 44.1569 20 42.5 20H30.5ZM53 48.5V74H74V48.5H53ZM47 48.5H26V74H47V48.5ZM60.5 29H66.5V26H60.5V29ZM60.1482 35C59.7863 36.4618 59.1502 37.8511 58.2648 39.0907L55.8296 42.5H71.1704L68.7352 39.0907C67.8498 37.8511 67.2137 36.4618 66.8518 35H60.1482ZM39.5 29H33.5V26H39.5V29ZM31.2648 39.0907C32.1502 37.8511 32.7863 36.4618 33.1482 35H39.8518C40.2137 36.4618 40.8498 37.8511 41.7352 39.0907L44.1704 42.5H28.8296L31.2648 39.0907Z'
      fill='#B941B8'
    />
  </Icon>
);

export default BundleIcon;
