import { Box, Skeleton, StackProps, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { ClosureMenu } from 'components/menu';
import React from 'react';
import { IPaymentClosure } from 'types/closure.types';
import { PickBankNote } from 'types/register.types';
import { currencyFormat, getPaymentsName } from 'utils';
import { amountBankNotes } from 'utils/closures';

type IClosureData = {
  payments: IPaymentClosure[];
  banksnote: PickBankNote[];
  cashBank: number;
};

interface TableClosureState extends StackProps {
  closureData: IClosureData;
  isLoading: boolean;
  disableCash: boolean;
  selectedPaymentId: number;
  onOpenBank: () => void;
  countPayment: (value: number, payment_id: number) => void;
  getDetailPayment: (paymentId: number) => void;
}

const TableClosure: React.FC<TableClosureState> = (props: TableClosureState) => {
  const { closureData, isLoading, countPayment, getDetailPayment, disableCash, onOpenBank } = props;

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'Jenis Pembayaran',
        isNumeric: false,
      },
      {
        title: 'Nilai Seharusnya',
        isNumeric: true,
      },
      {
        title: 'Nilai Dihitung',
        isNumeric: true,
      },
      {
        title: 'Perbedaan',
        isNumeric: true,
      },
      {
        title: '',
        isNumeric: true,
      },
    ],
    []
  );

  const differentMoney = React.useCallback((payment: IPaymentClosure) => {
    return currencyFormat(payment.different_payment);
  }, []);

  const defaultValue = React.useCallback(
    (_closureData: IClosureData, payment: IPaymentClosure) => {
      if (_closureData.banksnote.length === 0) {
        return payment.payment_counted;
      }

      return disableCash && _closureData.banksnote.length > 0
        ? amountBankNotes(_closureData.banksnote)
        : 0;
    },
    [closureData.payments]
  );

  return (
    <Box
      bg='white'
      rounded='md'
      border='1px'
      borderColor='#ECEFF1'
      w='full'
      overflowX='auto'
      overflowY='auto'
      maxH='250px'
    >
      <Skeleton isLoaded={isLoading}>
        <Table size='sm' variant='simple'>
          <Thead position='sticky' top={0} zIndex={5}>
            <Tr>
              {tableHeader.map((theader, index) => (
                <Th
                  key={`table-header-${index}`}
                  borderRightColor='gray.200'
                  borderRightWidth='1px'
                  bgColor='jubelio.grey100'
                  p={4}
                  isNumeric={theader.isNumeric}
                >
                  <span>{theader.title}</span>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {closureData.payments.map((payment, index) => (
              <Tr key={index} fontSize='14px'>
                <Td borderRightColor='gray.200' borderRightWidth='1px'>
                  {getPaymentsName(payment)}
                </Td>
                <Td borderRightColor='gray.200' borderRightWidth='1px' isNumeric>
                  {currencyFormat(payment.payment_expected as unknown as number)}
                </Td>
                <Td borderRightColor='gray.200' borderRightWidth='1px' isNumeric>
                  <Input
                    w='full'
                    isgrouped
                    labelGroup='Rp'
                    placeholder='Enter amount'
                    format='currency'
                    name='initialCash'
                    disabled={payment.payment_id === -1 && disableCash}
                    onChange={(e) => countPayment(Number(e), payment.payment_id)}
                    defaultValue={
                      payment.payment_id !== -1
                        ? payment.payment_expected
                        : defaultValue(closureData, payment)
                    }
                  />
                </Td>
                <Td borderRightColor='gray.200' borderRightWidth='1px' isNumeric>
                  {differentMoney(payment)}
                </Td>
                <Td borderRightColor='gray.200' borderRightWidth='1px' textAlign='center'>
                  <ClosureMenu
                    isDisabled={payment.payment_id !== -1}
                    onClickDetail={() => getDetailPayment(payment.payment_id)}
                    onClickBanks={onOpenBank}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Skeleton>
    </Box>
  );
};

export default React.memo(TableClosure);
