import { setSettings } from 'redux/reducer/settings';
import commonAction from 'services/http/common.request';
import { IPosSetting } from 'types';

import { useAppDispatch } from './redux';

type GetSettingProps = {
  getPosSetting: () => Promise<IPosSetting>;
};

const useGetPosSetting = (): GetSettingProps => {
  const dispatch = useAppDispatch();

  const getPosSetting = async () => {
    try {
      const response = await commonAction.getPosSetting();
      dispatch(setSettings(response));
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return { getPosSetting };
};

export default useGetPosSetting;
