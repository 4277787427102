import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { Input } from 'components/forms';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import { setInitialCash } from 'redux/reducer/registers';
import registerRequest from 'services/http/register.request';
import { IBankNote, PickBankNote, RegisterFragment } from 'types/register.types';
import { amountBankNotes } from 'utils/closures';

import { Modal } from '.';

interface IModalLocatoin {
  isOpen: boolean;
  onClose: () => void;
  onSave: (cash: number, listBanks: PickBankNote[]) => void;
  register?: RegisterFragment;
}

const ModalInitCash: React.FC<IModalLocatoin> = (props: IModalLocatoin) => {
  const { isOpen, register, onClose, onSave } = props;
  const [cash, setCash] = React.useState(0);
  const [isShowBanks, setShowBanks] = React.useState<boolean>(false);
  const [mapListBanks, setMapListBank] = React.useState<IBankNote[]>([]);

  const dispatch = useAppDispatch();

  const handleSave = (cash: number, _listBanks: PickBankNote[]) => {
    if (cash < 0) cash = 0;
    dispatch(setInitialCash(Number(cash)));
    onSave(Number(cash), _listBanks);
  };

  const handleBankNote = React.useCallback(
    (index: number, value: number, bankNote: PickBankNote) => {
      const result = mapListBanks.map((val) => {
        if (val.banknote_id === bankNote.banknote_id) {
          val.banknote_count = value;
          return val;
        }
        return val;
      });
      const filters = result.filter((val) => val.banknote_count && val.banknote_count > 0);
      const totalCash = amountBankNotes(filters);
      setCash(totalCash);
      setMapListBank(result);
    },
    [mapListBanks]
  );

  const getListBanks = async () => {
    try {
      const result = await registerRequest.getBankNotes();
      setMapListBank(result.data);
    } catch (error: any) {
      throw Error(error);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      getListBanks();
    }
  }, [isOpen]);

  return (
    <Modal
      isShow={isOpen}
      onClose={onClose}
      disableBackrop
      title={`Kas Pembukaan ${register?.register_code} - ${register?.register_name}`}
      className='max-w-xl'
    >
      <Formik
        initialValues={{
          initialCash: cash ?? 0,
        }}
        validate={(values) => {
          const errors: Record<string, any> = {};
          if (Number(values.initialCash) < 0) {
            errors.initialCash = 'Harap isi kas pembukaan lebih dari 0';
          }
          return errors;
        }}
        onSubmit={(_values, actions) => {
          setTimeout(() => {
            handleSave(cash, mapListBanks);
            actions.setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, handleBlur, touched, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <FormControl isInvalid={Boolean(errors.initialCash && touched.initialCash)}>
                <FormLabel htmlFor='initialCash'>Kas Awal</FormLabel>
                <Input
                  id='initialCash'
                  isgrouped
                  labelGroup='Rp'
                  placeholder='Enter amount'
                  format='currency'
                  name='initialCash'
                  onChange={(e) => setCash(e.replace(/,/g, ''))}
                  value={cash}
                  onBlur={handleBlur}
                  disabled={isShowBanks}
                />
                <FormErrorMessage>{touched.initialCash && errors.initialCash}</FormErrorMessage>
              </FormControl>
              <FormControl mt={8} textAlign='center'>
                <Checkbox
                  colorScheme='red'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setShowBanks(e.target.checked);
                    setCash(0);
                  }}
                >
                  Masukan pecahan uang tunai
                </Checkbox>
              </FormControl>
              {isShowBanks && (
                <SimpleGrid columns={[2]} my={4} gap={2}>
                  {mapListBanks &&
                    mapListBanks.map((bank, index) => (
                      <Input
                        key={index}
                        id={`bank-note-${index}`}
                        labelGroup={bank.note_text}
                        format='currency'
                        isgrouped
                        onBlur={handleBlur}
                        onChange={(e) => handleBankNote(index, Number(e), bank)}
                        name='banknotes'
                        minWidthAddon='100px'
                      />
                    ))}
                </SimpleGrid>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  onClose();
                  setCash(0);
                  setShowBanks(false);
                  setMapListBank([]);
                }}
                variant='outline'
                ml={2}
              >
                Batal
              </Button>
              <Button
                variant='primary'
                px={5}
                type='submit'
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                loadingText='Harap tunggu'
                ml={3}
              >
                Simpan
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalInitCash;
