import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';

interface ModalProps extends React.ComponentProps<'div'> {
  isShow: boolean;
  children: React.ReactNode;
  disableBackrop?: boolean;
  title?: string;
  actionButton?: React.ReactNode;
  onClose: () => void;
}

interface BodyProps extends React.ComponentProps<'div'> {
  children: React.ReactNode;
}
interface FooterProps extends React.ComponentProps<'div'> {
  children: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({ children, className, ...rest }: BodyProps) => (
  <div {...rest} className={clsx('relative flex-auto p-4', className)}>
    {children}
  </div>
);

const Footer: React.FC<FooterProps> = ({ children, className }: FooterProps) => (
  <div
    className={clsx(
      'px-4 py-3',
      'sm:flex sm:flex-row-reverse sm:px-6',
      'bg-white',
      'border-t border-gray-200',
      className
    )}
  >
    {children}
  </div>
);

interface ModalSubComponent {
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
}

const Modal: React.FC<ModalProps> & ModalSubComponent = ({
  isShow,
  children,
  className,
  disableBackrop = false,
  onClose,
  title,
  actionButton,
  ...rest
}: ModalProps) => {
  const cancelButtonRef = React.useRef(null);

  return (
    <Dialog
      as='div'
      open={isShow}
      className='fixed inset-0 z-10 overflow-y-auto'
      initialFocus={cancelButtonRef}
      onClose={() => (disableBackrop ? {} : onClose())}
    >
      <div className='flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
        <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-40' />
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className='hidden sm:inline-block sm:h-screen sm:align-middle' aria-hidden='true'>
          &#8203;
        </span>

        <div
          {...rest}
          className={clsx(
            'transform bg-white shadow-xl transition-all sm:my-8',
            'relative inline-block overflow-hidden rounded-lg text-left align-bottom sm:align-middle',
            'sm:w-full',
            className ? className : 'max-w-lg'
          )}
        >
          {/*header*/}
          {title && (
            <div className='flex items-center justify-between rounded-t border-b border-solid border-slate-200 px-5 py-4'>
              <Dialog.Title>{title}</Dialog.Title>
              {actionButton ? (
                actionButton
              ) : (
                <button
                  className='float-right ml-auto border-0  p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none'
                  onClick={onClose}
                >
                  <span className='block h-6 w-6 text-2xl text-black opacity-5 outline-none focus:outline-none'>
                    ×
                  </span>
                </button>
              )}
            </div>
          )}
          {/* Body and Footer */}
          {children}
        </div>
      </div>
    </Dialog>
  );
};

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
