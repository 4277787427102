import { EditIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { CardMain } from 'components/card';
import { ModalCustomer, ModalListCustomer } from 'components/modal';
import ModalAuthReturn from 'components/modal/ModalAuthReturn';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import { TableReturn } from 'components/table';
import { Title } from 'components/typography';
import DetailTotal from 'components/ui/cart/DetailTotal';
import { modal } from 'constant/messages';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutAction, setIsLogout } from 'redux/reducer/auth';
import {
  clearCartReturn,
  setCustomerOrderReturn,
  setItemToOrderReturn,
  setTotalDetailReturn,
  setTotalDetailToOrderReturn,
  togglePopupReturn,
  updateIsCheckoutReturn,
  updateQuantityItemReturn,
} from 'redux/reducer/return';
import authAction from 'services/http/auth.request';
import { db } from 'services/indexdb/connection';
import { ICustomerInfo, ICustomerInfoInput } from 'types/common.types';
import { IItemReturn, IOrderReturn } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { currencyFormat, getAmountDetailReturn, getTotalItemsReturn } from 'utils';

const ReturnPage = () => {
  const settings = useAppSelector((state) => state.commons.settings);
  const orderReturn = useAppSelector((state) => state.return.orderReturn);
  const listItemReturn = useAppSelector((state) => state.return.listItemReturn);
  const customer = useAppSelector((state) => state.return.customer);
  const isOpenPopup = useAppSelector((state) => state.return.isOpenPopup);
  const isLogout = useAppSelector((state) => state.auth.isLogout);
  const isOnline = useAppSelector((state) => state.register.isOnline);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    onOpen: onOpenAddCustomer,
    onClose: onCloseAddCustomer,
    isOpen: isOpenAddCustomer,
  } = useDisclosure();
  const { onOpen: onOpenCustomer, onClose: onCloseCustomer, isOpen: isOpenCustomer } = useDisclosure();
  const { onOpen: onOpenAuthReturn, onClose: onCloseAuthReturn, isOpen: isOpenAuthReturn } = useDisclosure();

  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const [loadingCancel, setLoadingCancel] = React.useState<boolean>(false);
  const [isOtherFee, setOtherFee] = React.useState<boolean>(!settings.allow_rounding);

  const getServiceFee = (orderReturn: IOrderReturn) => {
    if (orderReturn) {
      if (orderReturn.service_fee && Number(orderReturn.service_fee) !== 0) {
        return Number(orderReturn.service_fee);
      } else {
        return Number(orderReturn.add_disc);
      }
    } else {
      return 0;
    }
  };

  /**
   * Get grand total and all total detail from transaction
   */
  const totalDetailReturn = React.useMemo(
    (): ITotalDetail =>
      getAmountDetailReturn(
        listItemReturn ?? [],
        orderReturn.is_tax_included ?? false,
        getServiceFee(orderReturn),
        orderReturn.add_fee ?? 0,
        isOtherFee
      ),
    [listItemReturn, isOtherFee, orderReturn]
  );

  const addCustomerAction = (value: ICustomerInfoInput) => {
    dispatch(setCustomerOrderReturn(value));
    onCloseAddCustomer();
    onOpenCustomer();
  };

  const chooseReturnCustomer = (customerPayload: ICustomerInfo) => {
    dispatch(setCustomerOrderReturn(customerPayload));
    onCloseCustomer();
  };

  const onClickReturn = () => {
    if (settings.allow_return && settings.authorization_return) {
      onOpenAuthReturn();
      return;
    }
    checkout();
  };

  /**
   * Checkout to payment page
   */
  const checkout = React.useCallback(async () => {
    if (settings.allow_return && settings.authorization_return) {
      onOpenAuthReturn();
    }
    if (!orderReturn.customer_id) {
      const res = await db.customer.where('contact_id').equals(-1).first();
      dispatch(setCustomerOrderReturn(res as ICustomerInfo));
    }
    dispatch(
      setItemToOrderReturn(orderReturn.service_fee && Number(orderReturn.service_fee) !== 0 ? true : false)
    );
    dispatch(setTotalDetailReturn(totalDetailReturn));
    dispatch(
      setTotalDetailToOrderReturn({
        ...totalDetailReturn,
        discount_as_service_fee:
          orderReturn.service_fee && Number(orderReturn.service_fee) !== 0 ? true : false,
      })
    );
    dispatch(updateIsCheckoutReturn(true));
    navigate('/sales/return-checkout');
  }, [orderReturn, totalDetailReturn]);

  /**
   * close notification to redirect to sales page and reset orderReturn data;
   **/
  const submitConfirmation = async () => {
    dispatch(togglePopupReturn(false));
    setLoadingCancel(true);
    dispatch(clearCartReturn());
    setLoadingCancel(false);
    setOpenConfirmation(false);
    if (isLogout) {
      await authAction.logout();
      dispatch(logoutAction());
      window.location.href = '/';
    } else {
      navigate('/sales/history');
    }
  };

  /**
   * update quantity item with input value
   */
  const changeQuantity = React.useCallback(
    (item: IItemReturn, qty: number) => {
      if (!isNaN(Number(qty)) && Number.isInteger(qty)) {
        const initQty = qty === 0 ? 0 : -qty;
        let itemUpdate = {
          item_id: 0,
          quantity: 0,
          discount_as_service_fee: false,
        };
        if (initQty > 0 || initQty <= item.qty) {
          itemUpdate = {
            item_id: item.item_id,
            quantity: item.qty,
            discount_as_service_fee:
              orderReturn.service_fee && Number(orderReturn.service_fee) !== 0 ? true : false,
          };
        } else if (initQty >= item.qty) {
          itemUpdate = {
            item_id: item.item_id,
            quantity: initQty,
            discount_as_service_fee:
              orderReturn.service_fee && Number(orderReturn.service_fee) !== 0 ? true : false,
          };
        }
        dispatch(updateQuantityItemReturn(itemUpdate));
      }
    },
    [settings]
  );

  const cancelChangeTransaction = () => {
    dispatch(togglePopupReturn(false));
    dispatch(setIsLogout(false));
  };

  /**
   * Observe if items in cart is empty
   */
  React.useEffect(() => {
    if (listItemReturn && listItemReturn.length === 0) {
      dispatch(clearCartReturn());
      navigate('/sales/history');
    }
  }, [listItemReturn]);

  return (
    <>
      <CardMain>
        <Box
          width={{
            base: 'full',
            sm: 'full',
            md: 'full',
            lg: 'full',
            xl: 'full',
          }}
          overflowY='auto'
          maxH='calc(100vh - 110px)'
        >
          <Box position='relative' h='full'>
            <div className='flex items-center justify-between px-4 py-3'>
              <Box>
                <Title fontSize='16px'>Retur Barang</Title>
              </Box>
              <Box>
                <Button
                  variant='outline'
                  size='sm'
                  px={7}
                  borderRadius='8px'
                  color='jubelio.black'
                  onClick={() => setOpenConfirmation(true)}
                  loadingText='Sinkronisasi...'
                >
                  <Text fontSize='14px'>Batalkan</Text>
                </Button>
              </Box>
            </div>
            <div className='divider' />
            <div className='px-4 py-3'>
              <HStack spacing={5}>
                <Text fontSize='14px' color='jubelio.black'>
                  No. Transaksi:
                </Text>
                <Text fontWeight='bold' fontSize='14px' color='jubelio.black'>
                  {orderReturn.pos_return_no}
                </Text>
              </HStack>
              <HStack spacing={5} mt={3}>
                <Text fontSize='14px' color='jubelio.black'>
                  Pelanggan:
                </Text>
                <Text fontWeight='bold' fontSize='14px' color='jubelio.black'>
                  {orderReturn.customer_name}
                </Text>
                {customer && customer.contact_id === -1 && (
                  <IconButton
                    aria-label='expand'
                    icon={<EditIcon h='20px' color='jubelio.grey200' />}
                    variant='ghost'
                    size='sm'
                    onClick={() => onOpenCustomer()}
                  />
                )}
              </HStack>
            </div>
            <div className='px-3'>
              <TableReturn listItemReturn={listItemReturn} changeQuantity={changeQuantity} />
            </div>
            <div className='mt-5 flex w-full justify-end space-y-4 px-4'>
              <div className='w-1/3'>
                {listItemReturn && listItemReturn.length > 0 && (
                  <DetailTotal
                    totalDetail={totalDetailReturn}
                    orderReturn={orderReturn}
                    showTotal={true}
                    isReturn={true}
                    taxIncluded={orderReturn.is_tax_included ?? false}
                    onChangeOtherFee={(val) => setOtherFee(val)}
                    isOtherFee={isOtherFee}
                  />
                )}
              </div>
            </div>
            <div className='flex w-full justify-end p-4'>
              <Button
                variant='primary'
                onClick={onClickReturn}
                ml={3}
                size='md'
                isDisabled={getTotalItemsReturn(listItemReturn ?? []) === 0}
                fontWeight='700'
              >
                Retur - {currencyFormat(Number(Math.abs(totalDetailReturn.haveToPay)))}
              </Button>
            </div>
          </Box>
        </Box>
      </CardMain>

      <ModalListCustomer
        title='Pilih Pelanggan'
        isOpen={isOpenCustomer}
        onClose={onCloseCustomer}
        openAddCustomer={() => {
          onOpenAddCustomer();
          onCloseCustomer();
        }}
        chooseReturnCustomer={chooseReturnCustomer}
      />
      <ModalCustomer
        isOpen={isOpenAddCustomer}
        onClose={onCloseAddCustomer}
        onSubmit={(val) => addCustomerAction(val as ICustomerInfoInput)}
      />
      <ModalConfirmation
        title={modal.title_cancel_retur}
        isOpen={openConfirmation}
        onClose={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        subtitle={modal.subtitle_cancel_retur}
        cancelText={modal.cancel_retur_text}
        okText={modal.ok_retur_text}
        onSubmit={submitConfirmation}
        loadingSubmit={loadingCancel}
        isOnline={isOnline}
      />
      <ModalConfirmation
        title={modal.title_cancel_retur}
        subtitle={modal.subtitle_cancel_retur + '9374298734'}
        cancelText={modal.cancel_retur_text}
        okText={modal.ok_retur_text}
        isOpen={isOpenPopup ?? false}
        isOnline
        onSubmit={submitConfirmation}
        onClose={cancelChangeTransaction}
      />
      <ModalAuthReturn isOpen={isOpenAuthReturn} onClose={onCloseAuthReturn} checkout={checkout} />
    </>
  );
};

export default ReturnPage;
