import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/input';
import React from 'react';
interface SearchBarProps extends InputGroupProps {
  placeholder: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  onEnter?: (e: string) => void;
  disableEnter?: boolean;
  value?: string;
  disabled?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
  const { placeholder, onSearch, readOnly, onEnter, disableEnter, value, disabled, ...rest } =
    props;
  const [defaultValue, setDefaultValue] = React.useState<string>(value as string);

  const handleEnter = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!disableEnter && value !== '') {
      if (event.key === 'Enter') {
        if (onEnter) onEnter(defaultValue);
        setDefaultValue('');
      }
    }
  };

  return (
    <InputGroup variant='outline' {...rest}>
      <InputLeftElement pointerEvents='none'>
        <SearchIcon color='gray.300' />
      </InputLeftElement>
      <Input
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onSearch(e);
          setDefaultValue(e.target.value);
        }}
        readOnly={readOnly}
        onKeyDown={handleEnter}
        value={defaultValue}
        variant='outline'
        rounded='4px'
        autoFocus
        disabled={disabled}
      />
    </InputGroup>
  );
};

export default SearchBar;
