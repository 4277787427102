import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

export const ShippingInsurance: React.FC = () => {
  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <IconButton
          aria-label='shippinginsurance'
          size='md'
          variant='unstyled'
          icon={<InfoOutlineIcon color='jubelio.grey200' />}
          textAlign='left'
        />
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: 'none' }} zIndex={11} w='auto' rounded='4px'>
        <PopoverArrow />
        <PopoverBody zIndex={11} p='14px'>
          <Box color='jubelio.black'>
            <Flex alignItems='center' gridGap={2}>
              <InfoOutlineIcon h='14px' w='14px' color='system.blue' />
              <Text fontWeight='bold' color='jubelio.black' fontSize='14px'>
                Asuransi Pengiriman
              </Text>
            </Flex>
            <Divider my={3} />
            <Text>Pengiriman ini sudah mendukung Asuransi Pengiriman</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
