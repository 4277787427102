import { DeleteIcon } from '@chakra-ui/icons';
import {
  HStack,
  IconButton,
  StackProps,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CircleIcon } from 'components/icons';
import SyncIcon from 'components/icons/SyncIcon';
import { Title } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { DetailOrderTransaction } from 'types';
import { convertToLocalDate } from 'utils/datetime';

interface TableDeviceTransactionSalesState extends StackProps {
  isLoading: boolean;
  listTransaction: DetailOrderTransaction[] | undefined;
  setOrder: (order: DetailOrderTransaction) => void;
  searchOnline: string;
  isReturn: boolean;
  openPopupSync: () => void;
  onOpenConfirmationDelete: () => void;
  setDeleteAll: (isAll: boolean) => void;
}

const TableDeviceTransactionSales: React.FC<TableDeviceTransactionSalesState> = (
  props: TableDeviceTransactionSalesState
) => {
  const { listTransaction } = props;
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { tabsIndex } = useAppSelector((state) => state.commons);

  const tableHeader = React.useMemo(
    () => [
      {
        title: 'Nomor Transaksi',
        isNumeric: false,
      },
      {
        title: 'Waktu Dibuat',
        isNumeric: false,
      },
      {
        title: 'Aksi',
        isNumeric: false,
      },
    ],
    []
  );

  const colorStatus = (order: DetailOrderTransaction): string => {
    let color = '';
    if (order.is_paid || order.pos_return_id !== 0) color = 'green.400';
    if (order.is_paid === 0) color = 'unsync.800';

    return color;
  };

  return (
    <div className='h-full max-h-[calc(100vh_-_300px)] overflow-y-auto rounded-lg border border-b-0'>
      <Table size='sm' variant='simple' w='full'>
        <Thead>
          <Tr>
            {tableHeader.map((theader, index) => (
              <Th
                key={`table-header-${index}`}
                bgColor='jubelio.grey100'
                p={4}
                isNumeric={theader.isNumeric}
                position='sticky'
                top={0}
                zIndex={10}
              >
                <Text as='span' fontSize={['10px', '10px', '11px', '12px']}>
                  {theader.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listTransaction &&
            listTransaction.length > 0 &&
            listTransaction.map((order, index) => (
              <Tr key={index}>
                <Td py={4}>
                  <div className='flex items-center gap-2 pb-1'>
                    <CircleIcon color={colorStatus(order)} />
                    <Title fontWeight='bold'>
                      {tabsIndex === 0 ? order.salesorder_no : order.pos_return_no}
                    </Title>
                  </div>
                </Td>
                <Td>{convertToLocalDate(order.transaction_date)}</Td>
                <Td>
                  <HStack>
                    <IconButton
                      aria-label='remove'
                      icon={<DeleteIcon color='jubelio.primary' />}
                      variant='outline'
                      size='sm'
                      borderColor='jubelio.primary'
                      onClick={() => {
                        console.log(order.key, 'order');
                        props.setOrder(order);
                        props.setDeleteAll(false);
                        props.onOpenConfirmationDelete();
                      }}
                      isDisabled={!isOnline}
                    />
                    <IconButton
                      aria-label='sync'
                      icon={<SyncIcon color='jubelio.primary' />}
                      variant='outline'
                      size='sm'
                      borderColor='lightgray'
                      onClick={() => {
                        props.setOrder(order);
                        props.openPopupSync();
                      }}
                      isDisabled={!isOnline || order.is_paid !== 0}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default React.memo(TableDeviceTransactionSales);
