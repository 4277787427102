import { IProductList } from 'types/products.types';

/**
 * @description Pagination helper function to get the current page of the list of products
 * @param items The list of products
 * @param page The current page as a number
 * @param perPage The number of products per page as a number
 * @returns {IProductList}
 * @example getPaginatedItems(products, 1, 10)
 */
export const getItemsPagination = (
  items: IProductList[],
  page: number,
  perPage: number
): IProductList[] => {
  if (!items || items.length === 0) {
    return [];
  }

  const seenItemGroupIds = new Set();
  const uniqueItems = items.filter((item) => {
    const duplicate = seenItemGroupIds.has(item.item_group_id);
    seenItemGroupIds.add(item.item_group_id);
    return !duplicate;
  });

  const start = (page - 1) * perPage;
  const end = start + perPage;

  return uniqueItems.slice(start, end);
};

export const getPagination = (list: Array<any>, page: number, perPage: number): Array<any> => {
  if (list && list.length > 0) {
    const start = (page - 1) * perPage;
    const end = page * perPage;
    return list.slice(start, end);
  }

  return [];
};

export const getTotalItems = (items: IProductList[]): number => {
  return items.length;
};
