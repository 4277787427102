import { useQuery } from '@tanstack/react-query';
import { PosDisabled } from 'components/state';
import { CardSettingGeneral } from 'components/ui/settings';
import { alert } from 'constant/messages';
import { useNotification, useOnlineStatus } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import useSwithConnection from 'hooks/useSwithConnection';
import React from 'react';
import commonRequest from 'services/http/common.request';

import packageJson from '../../../package.json';

const SettingGeneral: React.FC = () => {
  const posSetting = useAppSelector((state) => state.commons.settings);
  const { notification } = useNotification();
  const { handleSyncDataToServer } = useSwithConnection();
  const isOnlineStore = useAppSelector((state) => state.register.isOnline);
  const { isOnline: isOnlineNetwork } = useOnlineStatus();
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data: changeLogList } = useQuery({
    queryKey: ['changlogs'],
    queryFn: commonRequest.getChangeLog,
    enabled: isOnlineStore || isOnlineNetwork,
  });

  const checkUpdate = async () => {
    try {
      const latestVersion = await commonRequest.getPosVersion();
      if (latestVersion.version_number === packageJson.version) {
        notification('', 'Versi Jubelio POS anda sudah terbaru.', 'warning', 5000);
      } else {
        await handleSyncDataToServer();
        window.location.reload();
      }
    } catch (error: any) {
      notification('', 'Terjadi Kesalahan!', 'warning', 5000);
    }
  };

  const handleClearData = async () => {
    setLoading(true);
    await handleSyncDataToServer();
    setLoading(false);
  };

  return (
    <div className='w-full p-5'>
      {posSetting.pos_enabled === false && <PosDisabled text={alert.not_integrated_title} />}
      <CardSettingGeneral
        handleClearData={handleClearData}
        checkUpdate={checkUpdate}
        changeLog={changeLogList || []}
        loadingClearData={loading}
      />
    </div>
  );
};

export default SettingGeneral;
