import { Box, BoxProps } from '@chakra-ui/react';
import { NavbarRegister } from 'components/navbar';
import * as React from 'react';

const MainLayout: React.FC<BoxProps> = ({ children }: BoxProps) => {
  return (
    <Box h='100vh' w='ful'>
      <NavbarRegister />
      {children}
    </Box>
  );
};

export default MainLayout;
