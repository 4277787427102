import { Box, BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';

interface CardSettingDescriptionProps extends BoxProps {
  title: string;
  subtitle: string;
  labelButton: string;
  isSync?: boolean;
  isDisabled: boolean;
  loading?: boolean;
  clickButton: () => void;
}

const CardSettingDescription: React.FC<CardSettingDescriptionProps> = (
  props: CardSettingDescriptionProps
) => {
  const { title, subtitle, labelButton, clickButton, isSync, isDisabled, loading, ...rest } = props;

  return (
    <Box border='1px' borderColor='gray.200' borderRadius='4px' {...rest}>
      <Flex
        justifyContent='space-between'
        className='space-x-2'
        mb='5px'
        position='relative'
        mt={5}
        px={10}
        alignItems='center'
      >
        <Box fontSize='14px' w='80%'>
          <Title>{title}</Title>
          <Box py={2}>
            <Text dangerouslySetInnerHTML={{ __html: subtitle }} />
          </Box>
        </Box>
        <Box w='20%' p={3}>
          <Button
            variant='outline'
            size='md'
            onClick={clickButton}
            isLoading={loading}
            w='full'
            isDisabled={isSync || isDisabled}
          >
            {labelButton}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
export default CardSettingDescription;
