class LocalStorageService {
  /**
   * setItem saves the value to the local storage
   * @param key
   * @param value
   * @returns
   */
  setItem(key: string, value: any): boolean {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  /**
   * getItem returns the value from the local storage
   * @param key
   * @returns
   */
  getItem<T>(key: string): T | any {
    const data: string | null = localStorage.getItem(key);
    if (data !== null) {
      if (typeof data === 'string') {
        return data;
      } else {
        return JSON.parse(data);
      }
    }

    return null;
  }

  /**
   * clear all the value from the local storage
   * @returns
   */
  clear() {
    localStorage.clear();
  }

  /**
   * removeItem removes the value from the local storage
   * @param key
   * @returns
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

export default new LocalStorageService();
