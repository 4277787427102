import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const TagIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 18 14' {...props}>
    <path d='M3.58329 4.49998C3.91481 4.49998 4.23276 4.36828 4.46718 4.13386C4.7016 3.89944 4.83329 3.5815 4.83329 3.24998C4.83329 2.91846 4.7016 2.60052 4.46718 2.3661C4.23276 2.13168 3.91481 1.99998 3.58329 1.99998C3.25177 1.99998 2.93383 2.13168 2.69941 2.3661C2.46499 2.60052 2.33329 2.91846 2.33329 3.24998C2.33329 3.5815 2.46499 3.89944 2.69941 4.13386C2.93383 4.36828 3.25177 4.49998 3.58329 4.49998ZM13.5083 6.64998C13.8083 6.94998 14 7.36664 14 7.83331C14 8.29164 13.8166 8.70831 13.5083 9.00831L9.34163 13.175C9.18706 13.3303 9.00332 13.4536 8.80096 13.5377C8.5986 13.6219 8.38161 13.6652 8.16246 13.6652C7.94331 13.6652 7.72632 13.6219 7.52396 13.5377C7.3216 13.4536 7.13786 13.3303 6.98329 13.175L1.15829 7.34998C0.849959 7.04164 0.666626 6.62498 0.666626 6.16664V1.99998C0.666626 1.07498 1.40829 0.333313 2.33329 0.333313H6.49996C6.95829 0.333313 7.37496 0.516646 7.67496 0.816646L13.5083 6.64998ZM10.2833 1.75831L11.1166 0.92498L16.8416 6.64998C17.15 6.94998 17.3333 7.37498 17.3333 7.83331C17.3333 8.29164 17.15 8.70831 16.85 9.00831L12.3666 13.4916L11.5333 12.6583L16.2916 7.83331L10.2833 1.75831Z' />
  </Icon>
);

export default TagIcon;
