import {
  IDetailTransaction,
  IItemUpdateOrder,
  IShippingBopis,
  Items,
  IUpdateOrder,
} from 'types/bopis.types';

/**
 * @param {IDetailTransaction} detailOrder - detail of order data
 * @returns {IUpdateOrder} new payload for update order data
 */
export const mappingUpdateOrder = (
  detailOrder: IDetailTransaction,
  values: IShippingBopis
): IUpdateOrder => {
  const newItems: IItemUpdateOrder[] = detailOrder.items.map((item: Items) => {
    return {
      salesorder_detail_id: item.salesorder_detail_id,
      item_id: item.item_id,
      tax_id: item.tax_id,
      price: Number(item.price ?? 0),
      unit: item.unit,
      qty_in_base: Number(item.qty_in_base ?? 0),
      disc: Number(item.disc ?? 0),
      disc_amount: Number(item.disc_amount ?? 0),
      tax_amount: Number(item.tax_amount ?? 0),
      amount: Number(item.amount ?? 0),
      channel_order_detail_id: item.channel_order_detail_id,
      description: item.description,
      is_fbm: item.is_fbm,
      shipper: values.courier ?? '',
      location_id: detailOrder.location_id,
      tracking_no: detailOrder.tracking_no ?? '',
    };
  });
  const payload: IUpdateOrder = {
    salesorder_id: detailOrder.salesorder_id,
    salesorder_no: detailOrder.salesorder_no,
    shipping_full_name: values.shipping_full_name ?? '',
    shipping_address: values.shipping_address ?? '',
    shipping_subdistrict: values.shipping_subdistrict ?? '',
    shipping_area: values.shipping_area ?? '',
    shipping_city: values.shipping_city ?? '',
    shipping_province: values.shipping_province ?? '',
    shipping_post_code: values.shipping_post_code ?? '',
    shipping_country: detailOrder.shipping_country,
    shipping_province_id: String(values.shipping_province_id ?? ''),
    shipping_city_id: String(values.shipping_city_id ?? ''),
    shipping_district_id: String(values.shipping_district_id ?? ''),
    shipping_subdistrict_id: String(values.shipping_subdistrict_id ?? ''),
    shipping_phone: values.shipping_phone ?? '',
    contact_id: detailOrder.contact_id,
    customer_name: detailOrder.customer_name,
    transaction_date: detailOrder.transaction_date,
    is_tax_included: detailOrder.is_tax_included,
    note: detailOrder.note,
    sub_total: Number(detailOrder.sub_total ?? 0),
    total_disc: Number(detailOrder.total_disc ?? 0),
    total_tax: Number(detailOrder.total_tax ?? 0),
    grand_total:
      Number(detailOrder.grand_total ?? 0) -
      Number(detailOrder.shipping_cost ?? 0) +
      Number(values.shipping_cost ?? 0) -
      Number(detailOrder.insurance_cost ?? 0) +
      Number(values.insurance_cost ?? 0),
    ref_no: detailOrder.ref_no,
    location_id: detailOrder.location_id,
    salesmen_id: detailOrder.salesmen_id,
    source: detailOrder.source,
    store_id: detailOrder.store_id,
    is_canceled: detailOrder.is_canceled ?? false,
    cancel_reason: detailOrder.cancel_reason ?? '',
    cancel_reason_detail: detailOrder.cancel_reason_detail ?? '',
    shipping_cost: values.shipping_cost ?? 0,
    insurance_cost: Number(values.insurance_cost ?? 0),
    service_fee: Number(detailOrder.service_fee ?? 0),
    original_shipment_cost: Number(detailOrder.shipping_cost ?? 0),
    channel_status: detailOrder.channel_status,
    is_paid: detailOrder.is_paid,
    add_disc: Number(detailOrder.add_disc ?? 0),
    add_fee: Number(detailOrder.add_fee ?? 0),
    use_shipping_insurance: values.shipping_insurance ?? false,
    payment_method: detailOrder.payment_method,
    items: newItems,
    shipping_coordinate: detailOrder.shipping_coordinate,
    shipment_promotion_id: values.shipment_promotion_id,
  };

  return payload;
};
