import { Flex, FlexProps, Image } from '@chakra-ui/react';
import { Title } from 'components/typography';
import React from 'react';

interface Props extends FlexProps {
  idx: number;
  icon: string;
  title: string;
  isActive: boolean;
  onClickOption: (idx: number) => void;
}

const ListShippingOption: React.FC<Props> = (props: Props) => {
  const { idx, icon, title, isActive, onClickOption, ...rest } = props;

  return (
    <Flex
      alignItems='center'
      {...rest}
      border='1px'
      borderRadius={5}
      _hover={{ cursor: 'pointer', borderColor: 'jubelio.primary' }}
      style={{ width: '100%' }}
      as={'button'}
      type='button'
      className='space-x-8'
      boxShadow='main-card'
      p={5}
      borderColor={isActive ? 'jubelio.primary' : 'gray.300'}
      bg={isActive ? 'jubelio.primary' : 'white'}
      onClick={() => onClickOption(idx)}
    >
      <Image src={icon} w={30} />
      <Title color={isActive ? 'white' : 'black'}>{title}</Title>
    </Flex>
  );
};

export default ListShippingOption;
