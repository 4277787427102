import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'constant';
import { db } from 'services/indexdb/connection';
import { AuthState, IUserInfo } from 'types/auth.types';
import { uuidv4 } from 'utils';

// Initial state
const initialState: AuthState = {
  loggedIn: false,
  closure_id: null,
  profile: null,
  isLoadingUser: true,
  is_wms_migrated: false,
  currentCompany: 0,
  defaultLocation: -99,
  lastLocation: '',
  clientId: '',
  isLogout: false,
};

/**
 * Auth reducer slice for auth state
 * @param state Current state of the reducer slice (auth) or initial state if not defined yet (undefined)
 * @param action Action to be performed on the reducer slice
 * @returns New state of the reducer slice
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, action: PayloadAction<IUserInfo>) => {
      const userData = action.payload;
      if (userData) {
        // clear all data if companyId is different
        if (state.currentCompany !== userData.currentCompany) {
          db.products.clear();
          db.presetsdiscount.clear();
          db.presetstax.clear();
          db.customer.clear();
          db.salesmen.clear();
          db.cart.clear();
          db.discount.clear();
          db.promotion.clear();
          db.promotionitems.clear();
          db.paymentmethod.clear();
          // db.order.clear();
          // db.return.clear();
          db.pricebook.clear();
          db.courier.clear();
          db.batchnumber.clear();
          db.serialnumber.clear();
        }
        localStorage.setItem('token', action.payload.token as string);
        localStorage.setItem(
          'is_pos_wms',
          userData.is_wms_migrated ||
            userData.userName === config.SUPPORT_EMAIL_V2 ||
            userData.userName === config.SUPPORT_EMAIL_WMS
            ? 'pos-wms'
            : 'pos'
        );
        const deviceId = localStorage.getItem('device_id');
        if (!deviceId) {
          localStorage.setItem('device_id', userData.fid ?? '');
        }
        state.currentCompany = Number(userData.currentCompany);
        state.defaultLocation = Number(
          userData.currentLocation && userData.currentLocation !== null
            ? userData.currentLocation
            : -99
        );
        state.is_wms_migrated =
          userData.userName === config.SUPPORT_EMAIL_V2 ||
          userData.userName === config.SUPPORT_EMAIL_WMS
            ? true
            : userData.is_wms_migrated;
        state.loggedIn = true;
        state.clientId = uuidv4();
      }
    },
    userInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.profile = action.payload;
      state.isLoadingUser = false;
    },
    logoutAction: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('is_pos_wms');
      state.loggedIn = false;
      state.profile = null;
      state.isLoadingUser = true;
      state.isLogout = false;
    },
    saveLastLocation: (state, action: PayloadAction<string>) => {
      state.lastLocation = action.payload;
    },
    setIsLogout: (state, action: PayloadAction<boolean>) => {
      state.isLogout = action.payload;
    },
  },
});

export const { loginAction, userInfo, logoutAction, saveLastLocation, setIsLogout } =
  authSlice.actions;
export default authSlice.reducer;
