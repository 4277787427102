import {
  IBinDefault,
  ICourierWmsList,
  ICreateShipping,
  IDetailTransaction,
  IGetResi,
  IItemToPickList,
  IOrderSetting,
  IPicklistDetail,
  IRequestCreatePicklist,
  IRequestItemToPickList,
  IRequestPacklist,
  IRequestUpdateAirwayBill,
  IResponseCompleteShipment,
  IResponseCreateInvoice,
  IResponseCreatePicklist,
  IResponsePickupLabel,
  IResponseSellBarcode,
  IResponseShipperPickupTime,
  IUpdateOrder,
  IUpdateShipment,
} from 'types/bopis.types';
import { ResponseSuccess } from 'types/common.types';

import request from './request';
import wmsRequest from './wms-request';

export default {
  getTransactionDetail: async (orderId: number): Promise<IDetailTransaction> => {
    try {
      const res = await request.get(`/sales/pos/v3/orders/bopis/${orderId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getItemToPickupList: async (
    data: IRequestItemToPickList,
    isWms: boolean
  ): Promise<IItemToPickList[]> => {
    try {
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/sales/picklists/items-to-pick/`, data);
      } else {
        res = await request.post(`sales/picklists/items-to-pick/`, data);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createPicklist: async (
    data: IRequestCreatePicklist,
    isWms: boolean
  ): Promise<IResponseCreatePicklist> => {
    try {
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/wms/sales/picklists/`, data);
      } else {
        res = await request.post(`sales/picklists/`, data);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPicklistDetail: async (id: number): Promise<IPicklistDetail> => {
    try {
      const res = await request.get(`sales/picklists/${id}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  scanBarcode: async (data: string, isWms: boolean): Promise<IResponseSellBarcode> => {
    try {
      const payload = {
        barcode: data,
      };
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/inventory/items/to-sell-barcode`, payload);
      } else {
        res = await request.post(`/inventory/items/to-sell-barcode`, payload);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPickupLabel: async (ids: Array<number>, isWms: boolean): Promise<IResponsePickupLabel> => {
    try {
      let res;
      if (!isWms) {
        res = await request.get(`reports/pos/pickup-label?ids=[${ids}]`);
      } else {
        res = await wmsRequest.get(`/reports/shipping-label/?ids[0]=${ids}&tz=Asia%2FJakarta`);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  printPickList: async (id: number, isWms: string): Promise<IResponsePickupLabel> => {
    try {
      let res;
      if (isWms === 'pos') {
        res = await request.get(`reports/pick-list/?id=${id}&tz=Asia%2FJakarta`);
      } else {
        res = await wmsRequest.get(`/reports/wms/pick-list/?ids%5B0%5D=${id}`);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createInvoice: async (id: number): Promise<IResponseCreateInvoice> => {
    try {
      const payload = { salesorder_id: id };
      const res = await request.post(`sales/pos/v2/online-orders/complete`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  completeOrder: async (id: number, isWms: boolean): Promise<ResponseSuccess> => {
    try {
      const payload = { ids: [id] };
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/sales/orders/mark-as-complete`, payload);
      } else {
        res = await request.post(`/sales/orders/mark-as-complete`, payload);
      }

      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  savePacklist: async (payload: IRequestPacklist): Promise<ResponseSuccess> => {
    try {
      const res = await wmsRequest.post(`/wms/sales/packlist`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getShipmentOrder: async (ids: number, isWms: boolean): Promise<IGetResi[]> => {
    try {
      const payload = {
        ids: [ids],
      };
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/wms/sales/shipments/orders/`, payload);
      } else {
        res = await request.post(`sales/shipments/orders/`, payload);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateAirWaybill: async (payload: IRequestUpdateAirwayBill): Promise<ResponseSuccess> => {
    try {
      const res = await request.post(`sales/orders/save-airwaybill/`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  printShippingLabel: async (id: number): Promise<IResponsePickupLabel> => {
    try {
      const res = await request.get(`reports/shipping-label/?ids%5B0%5D=${id}&tz=Asia%2FJakarta`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  scanToken: async (token: string): Promise<ResponseSuccess<any>> => {
    try {
      const res = await request.post('/sales/pos/v3/orders/bopis/scan-token', {
        token,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateOrder: async (payload: IUpdateOrder, isWms: boolean): Promise<ResponseSuccess<any>> => {
    try {
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/sales/orders/`, payload);
      } else {
        res = await request.post(`/sales/orders/`, payload);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getOrderSetting: async (): Promise<IOrderSetting> => {
    try {
      const res = await wmsRequest.get(`/systemsetting/order-setting`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  markPacklistCompleted: async (id: number): Promise<ResponseSuccess> => {
    try {
      const payload = { ids: [id] };
      const res = await wmsRequest.post(`/wms/sales/packlist/mark-as-complete/`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getDefaultBin: async (locationId: number): Promise<IBinDefault> => {
    try {
      const res = await wmsRequest.get(`/wms/default-bin/${locationId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  processOrder: async (id: number): Promise<ResponseSuccess> => {
    try {
      const payload = { salesorder_ids: [id] };
      const res = await wmsRequest.post(`/wms/sales/ready-to-pick`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  createShippingOrder: async (payload: ICreateShipping): Promise<{ shipment_number: string }> => {
    try {
      const res = await wmsRequest.post(`/wms/shipments/`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getCourierWms: async (): Promise<ICourierWmsList[]> => {
    try {
      const res = await wmsRequest.get(`/wms/couriers`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  markCompletedShipment: async (shipmentId: number): Promise<IResponseCompleteShipment> => {
    try {
      const res = await wmsRequest.get(`/sales/shipments/mark-as-completed/${shipmentId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateShipment: async (payload: IUpdateShipment): Promise<ResponseSuccess> => {
    try {
      const res = await wmsRequest.post(`/sales/shipments/`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  shipperPickupTime: async (ids: number, isWms: boolean): Promise<IResponseShipperPickupTime[]> => {
    try {
      const payload = {
        ids: [ids],
      };
      let res;
      if (isWms) {
        res = await wmsRequest.post(`/shipment/shipper-pickup-time/`, payload);
      } else {
        res = await request.post(`/shipment/shipper-pickup-time/`, payload);
      }
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  courierMapping: async (courier_name: string): Promise<{ courier_id: number }> => {
    try {
      const res = await wmsRequest.get(
        `/wms/sales/courier-id/?courierName=${encodeURIComponent(courier_name)}`
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateManualTrackingNo: async (
    payload: IRequestUpdateAirwayBill & { picklist_id: number }
  ): Promise<ResponseSuccess> => {
    try {
      const res = await wmsRequest.post(`/sales/packlists/`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
