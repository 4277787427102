import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const PromotionIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='-4 -5 30 33' {...props}>
    <path d='M12.79 21L3 11.21v2c0 .53.21 1.04.59 1.41l7.79 7.79c.78.78 2.05.78 2.83 0l6.21-6.21c.78-.78.78-2.05 0-2.83z' />
    <path d='M11.38 17.41c.39.39.9.59 1.41.59c.51 0 1.02-.2 1.41-.59l6.21-6.21c.78-.78.78-2.05 0-2.83L12.62.58C12.25.21 11.74 0 11.21 0H5C3.9 0 3 .9 3 2v6.21c0 .53.21 1.04.59 1.41zM5 2h6.21L19 9.79L12.79 16L5 8.21z' />
  </Icon>
);

export default React.memo(PromotionIcon);
