import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ClockIcon: React.FC<IconProps> = (props: IconProps) => (
  <Icon viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M7.99992 13.334C10.9333 13.334 13.3333 10.934 13.3333 8.00065C13.3333 5.06732 10.9333 2.66732 7.99992 2.66732C5.06659 2.66732 2.66659 5.06732 2.66659 8.00065C2.66659 10.934 5.06659 13.334 7.99992 13.334ZM7.99992 1.33398C11.6666 1.33398 14.6666 4.33398 14.6666 8.00065C14.6666 11.6673 11.6666 14.6673 7.99992 14.6673C4.33325 14.6673 1.33325 11.6673 1.33325 8.00065C1.33325 4.33398 4.33325 1.33398 7.99992 1.33398ZM8.33325 8.53399L5.13325 10.4007L4.66659 9.46732L7.33325 7.93398V4.66732H8.33325V8.53399Z'
      fill='#8999A5'
    />
  </Icon>
);

export default ClockIcon;
