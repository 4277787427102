import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SaveIcon = (props: IconProps): JSX.Element => (
  <Icon width='24px' height='24px' viewBox='0 0 24 24' {...props}>
    <path d='M17 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM19 19H5V5H16.17L19 7.83V19ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12ZM6 6H15V10H6V6Z' />
  </Icon>
);

export default React.memo(SaveIcon);
