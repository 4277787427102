import { Button, Radio, RadioGroup } from '@chakra-ui/react';
import Bowser from 'bowser';
import { InputNumber } from 'components/forms';
import { PrintIcon } from 'components/icons';
import ComponentTestPrint from 'components/receipt/TestPrint';
import { usePrint } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { usePrintUsb } from 'hooks/usePrintUsb';
import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import { setPrinter, setPrintMethod } from 'redux/reducer/settings';
import { testCommand } from 'utils/printer/test-command';

const Receipt: React.FC = () => {
  const { printer, structSetting, printMethod } = useAppSelector((state) => state.commons);
  const { profile } = useAppSelector((state) => state.auth);
  const { location } = useAppSelector((state) => state.register);
  const dispatch = useAppDispatch();
  const componentPrintRef = React.useRef(null);
  const { printReceipt, disconnectDevice, selectedPrinter, handleConnectDevice, getDevices } = usePrintUsb();
  const browser = Bowser.getParser(window.navigator.userAgent);

  const handleReactToPrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const handleSelectPrintMethod = (method: 'popup' | 'usb') => {
    if (method === 'popup') {
      disconnectDevice(selectedPrinter as USBDevice);
    }
    dispatch(setPrintMethod(method));
  };

  const handleTestPrint = async () => {
    const data = await testCommand({
      settingPrint: structSetting,
      location,
      profile,
      paperSize: Number(printer?.paperSize),
      printCopy: printer?.printCopy,
    });

    if (printMethod === 'usb') {
      await printReceipt(data);
    } else {
      // custom hook to print receipt
      usePrint({
        dataPrint: data,
        onErroPrint: () => handleReactToPrint(),
        onIgnorePrint: () => handleReactToPrint(),
      });
    }
  };

  const onChangeHandler = (name: string, value: React.ChangeEvent<HTMLInputElement> | number) => {
    dispatch(
      setPrinter({
        name,
        value,
      })
    );
  };

  const onDecrement = (printCopy: number) => {
    onChangeHandler('printCopy', printCopy - 1);
  };

  const onIncrement = (printCopy: number) => {
    onChangeHandler('printCopy', printCopy + 1);
  };

  React.useEffect(() => {
    getDevices();
  }, []);

  return (
    <div className='flex w-full flex-col gap-4 p-5'>
      <div className='rounded-md border p-4'>
        <h4 className='mb-10 font-bold'>Atur Jumlah Copy Print yang anda butuhkan</h4>
        <InputNumber
          value={printer.printCopy}
          size='md'
          maxWidth={{ base: '30%', sm: '40%', md: '35%', lg: '20%' }}
          textAlign='center'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChangeHandler('printCopy', Number(e.target.value))
          }
          decrement={() => onDecrement(printer.printCopy)}
          increment={() => onIncrement(printer.printCopy)}
        />
      </div>
      <div className='rounded-md border p-4'>
        <h4 className='mb-10 font-bold'>Pilih Ukuran Kertas</h4>
        <RadioGroup
          onChange={(e: string) => onChangeHandler('paperSize', Number(e))}
          value={`${printer.paperSize}`}
        >
          <div className='flex gap-10'>
            <Radio colorScheme='red' value='58'>
              58mm
            </Radio>
            <Radio colorScheme='red' value='80'>
              80mm
            </Radio>
          </div>
        </RadioGroup>
      </div>
      {browser.getBrowserName() === 'Chrome' && (
        <div className='rounded-md border p-4'>
          <h4 className='mb-5 font-bold'>Metode Print Struk</h4>
          <div className='mb-5 rounded-lg border p-4 text-sm'>
            Jika memilih USB, anda harus mengizinkan perizinan browser untuk mengakses printer USB anda.
          </div>
          <RadioGroup
            onChange={(e: 'popup' | 'usb') => handleSelectPrintMethod(e)}
            value={printMethod || 'popup'}
          >
            <Radio colorScheme='red' value='popup'>
              Popup
            </Radio>
            <Radio colorScheme='red' value='usb'>
              USB
            </Radio>
          </RadioGroup>
        </div>
      )}
      {printMethod === 'usb' && (
        <div className='rounded-md border p-4'>
          <h4 className='mb-5 font-bold'>Printer Terhubung</h4>
          {selectedPrinter ? (
            <div className='flex justify-between'>
              <p>
                {selectedPrinter?.manufacturerName} - {selectedPrinter?.productName}
              </p>
              <Button variant='outline' onClick={() => disconnectDevice(selectedPrinter)}>
                Disconnect
              </Button>
            </div>
          ) : (
            <Button variant='outline' onClick={() => handleConnectDevice()}>
              Pilih Printer Thermal USB
            </Button>
          )}
        </div>
      )}
      <div className='rounded-md border p-4'>
        <h4 className='mb-5 font-bold'>Test Print Struk</h4>
        <Button variant='outline' leftIcon={<PrintIcon />} onClick={handleTestPrint}>
          Cetak Struk
        </Button>
      </div>

      <div className='hidden'>
        <ComponentTestPrint ref={componentPrintRef} />
      </div>
    </div>
  );
};

export default Receipt;
