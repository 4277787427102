import { DISCOUNT_TYPES, PROMOTION_TYPES } from 'constant';
import { get } from 'lodash';
import { IItemCart } from 'types/products.types';
import {
  getChecklistsDiscountRes,
  IFreeItemsV2,
  IValidVoucherList,
  ResPromotionTransactionAmount,
  TransactionPromotion,
} from 'types/promotion.types';

import { formatBuyXGetY } from './buyx-gety';
import { formatFreeItemTransaction, formatFreeItemV2 } from './free-items';
import { formatMinimalQuantity, formatMinimalQuantityV2 } from './minimal-quantity';
import { formatMinimalTransaction } from './minimal-transaction';

type ChecklistDiscountResp = {
  [key: string]: {
    discount: number;
    total: number;
    items: IItemCart[];
  };
};

export const formatPromotion = (promotions: TransactionPromotion[]): unknown => {
  const result = [];
  for (const promotion of promotions) {
    if (promotion.rules.promotion_type === PROMOTION_TYPES.MinimalTransaction) {
      result.push(formatMinimalTransaction(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.MinimalQuantity) {
      result.push(formatMinimalQuantity(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.FreeItemTransaction) {
      result.push(formatFreeItemTransaction(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.BuyXGetY) {
      result.push(formatBuyXGetY(promotion));
    }
  }

  return result;
};

export const formatPromotionV2 = (
  discountReward: ResPromotionTransactionAmount[],
  productReward: IFreeItemsV2[],
  validVouchers: IValidVoucherList[]
): unknown => {
  const bufferPromotion = [];
  for (const promotion of discountReward) {
    bufferPromotion.push(formatMinimalQuantityV2(promotion, validVouchers));
  }

  for (const promotion of productReward) {
    bufferPromotion.push(formatFreeItemV2(promotion, validVouchers));
  }

  const result = bufferPromotion.reduce((acc: any, current: any) => {
    // Find if the promotion with the same promotion_id already exists in the accumulator
    const existingPromo = acc.find((item: any) => item.promotion_id === current.promotion_id);

    if (existingPromo) {
      // If it exists, push the current detail to the existing detail array
      existingPromo.detail.push(current.detail);
    } else {
      // If it does not exist, create a new entry in the accumulator
      acc.push({
        promotion_id: current.promotion_id,
        detail: [current.detail],
        voucher_code: current.voucher_code,
      });
    }

    return acc;
  }, []);

  return result;
};
/**
 * Func to get total discount of promotion Minimal Quantity
 *
 * @param promotion
 * @returns
 */
export const getChecklistsDiscount = (
  promotion: getChecklistsDiscountRes
): ChecklistDiscountResp => {
  const discount: Record<string, any> = {};
  let totalPrice = 0;

  for (const ckey in promotion.checkLists) {
    discount[ckey] = {
      discount: 0,
      total: 0,
      items: promotion.checkLists[ckey].items,
      min_qty: 0,
    };

    for (const item of promotion.checkLists[ckey]?.items ?? []) {
      let item_price = item.sell_price as number;
      if (!promotion.rules.ignore_item_discount)
        item_price -= get(item, 'pos_slash_price', 0) / item.quantity;

      // discount[ckey].min_qty += promotion.checkLists[ckey].expected;
      discount[ckey].total += item_price;
    }
    totalPrice += discount[ckey].total;
  }

  let totalDiscount = 0;
  for (const ckey in discount) {
    if (promotion.rules.discount_type === DISCOUNT_TYPES.Percentage) {
      discount[ckey].discount =
        (discount[ckey].total * promotion.rules.discount_amount) /
        100 /
        discount[ckey].items.length;
    } else {
      discount[ckey].discount =
        (discount[ckey].total * promotion.rules.discount_amount) / totalPrice;
    }

    if (promotion.rules.multiplicative && Number(promotion?.multiplier) > 1) {
      discount[ckey].discount = discount[ckey] * Number(promotion.multiplier ?? 0);
    }
    totalDiscount += discount[ckey].discount;
  }

  if (
    promotion.rules.discount_type === DISCOUNT_TYPES.Percentage &&
    promotion.rules.total_discount > 0 &&
    totalDiscount > promotion.rules.total_discount
  ) {
    for (const ckey in discount) {
      discount[ckey].discount =
        (discount[ckey].discount * promotion.rules.total_discount) / totalDiscount;
    }
  }

  return discount;
};

export function getItemPromotion(item: IItemCart, promotions: TransactionPromotion[]): number {
  let itemPromotion = 0;
  const listPromo = promotions ? promotions : [];
  for (const p of listPromo) {
    itemPromotion += p['itemsDiscountDetails'][`p_${item.item_id}`] ?? 0;
  }

  return itemPromotion;
}

export function getSalesPromotions(promotions: ResPromotionTransactionAmount[]): number {
  let discount = 0;
  for (const p of promotions) {
    const promoWithoutFreeItems = get(p, 'transactionDiscount');
    if (promoWithoutFreeItems) {
      discount += p.transactionDiscount ?? 0;
    }
  }

  return discount;
}

export function getListFreeItems(listTransactionPromotion: TransactionPromotion[]): any {
  const combinedFreeItems: any = {};
  if (listTransactionPromotion) {
    for (const promo of listTransactionPromotion) {
      // free items by sku
      for (const item of promo.free_items ?? []) {
        const ik = `key_${item.item_id}`;
        if (combinedFreeItems[ik] === undefined) {
          combinedFreeItems[ik] = item;
        } else {
          combinedFreeItems[ik] = {
            ...combinedFreeItems[ik],
            quantity: combinedFreeItems[ik].quantity + item.quantity,
          };
        }
      }

      // free items by product
      for (const product of Object.values(promo.free_products ?? {})) {
        if (product.selectedItem === null) continue;

        const ik = `key_${product.selectedItem.item_id}`;
        if (combinedFreeItems[ik] === undefined) {
          combinedFreeItems[ik] = product.selectedItem;
        } else {
          combinedFreeItems[ik] = {
            ...combinedFreeItems[ik],
            quantity: combinedFreeItems[ik].quantity + product.selectedItem.quantity,
          };
        }
      }

      // free items by category
      for (const category of Object.values(promo.free_categories ?? {})) {
        if (category.selectedItem === null) continue;

        const ik = `key_${category.selectedItem.item_id}`;
        if (combinedFreeItems[ik] === undefined) {
          combinedFreeItems[ik] = category.selectedItem;
        } else {
          combinedFreeItems[ik] = {
            ...combinedFreeItems[ik],
            quantity: combinedFreeItems[ik].quantity + category.selectedItem.quantity,
          };
        }
      }
    }
  }

  return Object.values(combinedFreeItems);
}

export function getListFreeItemsV2(listFreeItem: IFreeItemsV2[]): any {
  const combinedFreeItems: any = {};
  if (listFreeItem.length > 0) {
    for (const item of listFreeItem) {
      // free items by sku
      const ik = `key_${item.item_id}`;
      const variant = item.variant;
      if (combinedFreeItems[ik] === undefined) {
        combinedFreeItems[ik] = Object.assign({}, variant, {
          quantity: item.qty,
          is_free: true,
          promotion_name: item.promotion_name,
        });
      } else {
        combinedFreeItems[ik] = {
          ...combinedFreeItems[ik],
          quantity:
            combinedFreeItems[ik].promotion_id === item.promotion_id
              ? combinedFreeItems[ik].quantity + item.qty
              : combinedFreeItems[ik].quantity,
          is_free: true,
          promotion_name: item.promotion_name,
        };
      }
    }
  }

  return Object.values(combinedFreeItems);
}

export function listPromoNotFreeItems(
  promotions: ResPromotionTransactionAmount[],
  validVoucher: IValidVoucherList[]
): ResPromotionTransactionAmount[] {
  const promolist = [];
  if (promotions.length === 0) return [];
  for (const p of promotions) {
    const transactionDiscount = get(p, 'transactionDiscount');
    const findValidVoucher = validVoucher.find((v) => v.promotion_id === p.promotion.promotion_id);
    if (
      (p.promotion.use_voucher && findValidVoucher && transactionDiscount) ||
      (!p.promotion.use_voucher && transactionDiscount)
    ) {
      promolist.push(p);
    }
  }

  return promolist;
}
