import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IProductResponse, IResponseSerialBatchNumber, IResponseStock } from 'types/inventory';
import {
  FragmentProductPrice,
  IDetailPrice,
  IItemCart,
  IProductList,
  IProductRoot,
} from 'types/products.types';
import { IQueryParams } from 'types/request.type';

import request from './request';

const isWmsMigrated = localStorage.getItem('is_pos_wms');

export default {
  getProducts: async (
    page: number,
    pageSize: number,
    locationId: number,
    q = ''
  ): Promise<IProductRoot<IProductList[]>> => {
    try {
      const res = await request.get(
        `sales/${isWmsMigrated}/v3/locations/${locationId}/items?page=${page}&pageSize=${pageSize}&q=${q}`
      );
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkPrice: async (
    locationId: number,
    page: number,
    pageSize: number,
    q: string
  ): Promise<FragmentProductPrice> => {
    try {
      const response = await request.get(
        `sales/pos/v3/locations/${locationId}/items/price-checker?page=${page}&pageSize=${pageSize}&q=${q}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkPriceDetail: async (locationId: number, itemId: number): Promise<IDetailPrice> => {
    try {
      const response = await request.get(
        `/sales/pos/v3/locations/${locationId}/items/price-checker/${itemId}`
      );
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  checkProductExist: async (
    items: Pick<IItemCart, 'item_code' | 'item_id'>[],
    location_id: number
  ): Promise<AxiosResponse> => {
    try {
      const response = await request.post(`sales/pos/v3/item-check`, { items, location_id });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getProductGroups: async ({
    queryKey,
  }: QueryFunctionContext<[string, IQueryParams]>): Promise<IProductResponse<IProductList[]>> => {
    const [, qs] = queryKey;
    const params = {
      pageSize: String(qs.pageSize),
      cursor: String(qs.cursor),
      q: qs.q,
    };
    const response = await request.get(`/sales/pos/v3/inventory/location/${qs.locationId}/product/list`, {
      params,
    });

    return response.data;
  },

  getItemsStock: async (data: {
    location_id: number;
    item_ids: number[];
  }): Promise<{ data: IResponseStock[] }> => {
    const response = await request.post(
      `/sales/pos/v3/inventory/location/${data.location_id}/product/stock/items`,
      {
        item_ids: data.item_ids,
      }
    );
    return response.data;
  },
  getSerialBatchNumber: async ({
    queryKey,
  }: QueryFunctionContext<[string, IQueryParams]>): Promise<IResponseSerialBatchNumber> => {
    const [, params] = queryKey;
    const response = await request.get(
      `/sales/pos/v3/inventory/location/${params.locationId}/product/snbn/${params.itemId}`
    );
    return response.data;
  },
  scanItems: async (q: string, location_id: number): Promise<IProductList> => {
    try {
      const params = { q };
      const response = await request.get(
        `/sales/pos/v3/inventory/location/${location_id}/product/scan-item`,
        {
          params,
        }
      );
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getFreeItemsPromotion: async ({
    queryKey,
  }: QueryFunctionContext<[string, IQueryParams]>): Promise<IProductResponse<IProductList[]>> => {
    const [, qs] = queryKey;
    const params = {
      pageSize: String(qs.pageSize),
      cursor: String(qs.cursor),
      q: qs.q,
    };
    const payload = {
      items_include: qs.includeItem,
      items_exclude: qs.excludeItem,
    };
    const response = await request.post(
      `/sales/pos/v3/inventory/location/${qs.location_id}/product/free-item`,
      payload,
      {
        params,
      }
    );
    return response.data;
  },
};
