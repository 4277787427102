import { IMarketplaceIntegration } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * @description Get all contact marketplace
   * @returns {Promise<IMarketplaceIntegration[]>}
   * @example await marketplace.get()
   */
  get: async (): Promise<IMarketplaceIntegration[]> => {
    const marketplace = await db.marketplace.toArray();
    return marketplace;
  },
  /**
   * @description Get a contact marketplace by id
   * @param {number} id
   * @returns {Promise<IMarketplaceIntegration>}
   * @example await marketplace.findOne(1)
   */
  findOne: async (id: number): Promise<IMarketplaceIntegration | undefined> => {
    const marketplace = await db.marketplace.where('channel_id').equals(id).first();
    return marketplace;
  },
  /**
   * @description delete a contact marketplace by id
   * @param {number} id
   * @returns {Promise<void>}
   * @example await marketplace.delete(1)
   */
  delete: async (id: number): Promise<void> => {
    return db.marketplace.delete(id);
  },
  /**
   * @description Create a new marketplace
   * @param {IMarketplaceIntegration} marketplace
   * @returns {Promise<IMarketplaceIntegration>}
   * @example await marketplace.create({ name: 'John Doe', email: 'daasda' })
   */
  add: async (marketplace: IMarketplaceIntegration): Promise<boolean> => {
    await db.marketplace.add(marketplace);
    return true;
  },
  /**
   * @description clear all marketplace from database
   * @returns {Promise<void>}
   * @example await marketplace.clear()
   */
  clear: async (): Promise<void> => {
    return db.marketplace.clear();
  },
  /**
   * @description update a marketplace by id
   * @param {number} id
   * @param {IMarketplaceIntegration} marketplace
   * @returns {Promise<IMarketplaceIntegration>}
   * @example await marketplace.update(1, { name: 'John Doe', email: 'daasda' })
   */
  update: async (marketplace: IMarketplaceIntegration): Promise<boolean> => {
    await db.marketplace.update(marketplace.channel_id, marketplace);
    return true;
  },
};
