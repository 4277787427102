import { IconButton, Menu as MenuChakra, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MoreIcon } from 'components/icons';
import * as React from 'react';

type MenuProps = {
  isDisabled: boolean;
  onClickDetail: () => void;
  onClickBanks?: () => void;
};

const ClosureMenu: React.FC<MenuProps> = ({
  onClickDetail,
  onClickBanks,
  isDisabled,
}: MenuProps) => {
  return (
    <MenuChakra isLazy={true} lazyBehavior='unmount' matchWidth={true} preventOverflow={true}>
      <MenuButton
        size='sm'
        as={IconButton}
        aria-label='Options'
        icon={<MoreIcon />}
        variant='outline'
      />
      <MenuList fontSize='13px' fontWeight='800' w='100px' p={2} border={0} boxShadow='md'>
        <MenuItem
          my={2}
          as='button'
          p={2}
          rounded='sm'
          onClick={onClickBanks}
          isDisabled={isDisabled}
          color={isDisabled ? 'gray.400' : ''}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
        >
          Masukan Pecahan Uang Tunai
        </MenuItem>
        <MenuItem my={2} p={2} rounded='sm' onClick={onClickDetail}>
          Lihat Detail Transaksi
        </MenuItem>
      </MenuList>
    </MenuChakra>
  );
};

export default ClosureMenu;
