import { Box, BoxProps, Divider, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { InputNumber } from 'components/forms';
import { TagIcon } from 'components/icons';
import ListTotal from 'components/list/ListTotal';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { Items } from 'types/bopis.types';
import { currencyFormat } from 'utils';

interface CardItemProps extends BoxProps {
  item: Items;
}

const CardItem: React.FC<CardItemProps> = ({ item, ...rest }: CardItemProps) => {
  return (
    <Box border='1px' borderColor='gray.200' p='12px' w='full' borderRadius='4px' {...rest}>
      <Flex justifyContent='space-between' className='space-x-2' mb='5px' position='relative'>
        <Tooltip label={item.item_name} hasArrow bg='jubelio.black'>
          <Text fontSize='14px' fontWeight='bold' isTruncated w='60%'>
            {item.item_name}
          </Text>
        </Tooltip>
        <Title w='40%' textAlign='right' fontSize='14px' color='jubelio.purple'>
          <TagIcon mr={2} fill='jubelio.purple' />
          {currencyFormat(Number(item.price))}
        </Title>
      </Flex>
      <SubTitle fontSize='12px'>{item.item_code}</SubTitle>
      <HStack mt={2}>
        {item.use_serial_number && <BadgeProduct colorScheme='serial'>Serial</BadgeProduct>}
        {item.use_batch_number && <BadgeProduct colorScheme='batch'>Batch</BadgeProduct>}
        {item.is_bundle && <BadgeProduct colorScheme='bundle'>Bundle</BadgeProduct>}
        {!item.use_serial_number && !item.use_batch_number && !item.is_bundle && (
          <BadgeProduct colorScheme='green'>Satuan</BadgeProduct>
        )}
      </HStack>
      <VStack w='full' alignItems='start' mt={2}>
        {Number(item.disc_amount) > 0 && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText={`Diskon`}
            rightText={<Text>{`-${currencyFormat(Number(item.disc_amount))}`}</Text>}
          />
        )}
        {Number(item.tax_amount) > 0 && (
          <ListTotal
            fontSize='12px'
            color='gray.500'
            leftText='Pajak'
            rightText={<Text>{currencyFormat(Number(item.tax_amount))}</Text>}
          />
        )}
      </VStack>
      <Divider my={4} bg='gray.200' />
      <HStack spacing={2}>
        <InputNumber
          value={Math.abs(Number(item.qty_in_base))}
          size='sm'
          disabled
          textAlign='center'
        />
        <Title w='full' ml='auto' textAlign='right'>
          {currencyFormat(
            Number(item.price) * Math.abs(Number(item.qty_in_base)) - Number(item.disc_amount)
          )}
        </Title>
      </HStack>
    </Box>
  );
};
export default React.memo(CardItem);
