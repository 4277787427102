import { ChakraProvider } from '@chakra-ui/provider';
import { ToastProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'theme/theme';

import './theme/index.css';
import './theme/print.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const persistor = persistStore(store);
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 4 * 60 * 1000, // 3 minutes
      staleTime: 3 * 60 * 1000, // 5 minutes
    },
  },
});
const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme} resetCSS>
            <App />
            <ToastProvider />
          </ChakraProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
