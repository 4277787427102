import { Box, BoxProps, Image } from '@chakra-ui/react';
import { Heading, SubTitle } from 'components/typography';
import React from 'react';

interface BoxImageProps extends BoxProps {
  startIcon: string;
  title: string;
  subtitle: string;
}

const BoxImage: React.FC<BoxImageProps> = (props: BoxImageProps) => {
  const { startIcon, title, subtitle, ...rest } = props;
  return (
    <Box
      h='full'
      d='flex'
      flexDir='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
      {...rest}
    >
      <Image src={startIcon} boxSize={{ base: '100px', lg: '200px' }} />
      <Heading mt='32px'>{title}</Heading>
      <SubTitle mt='12px'>{subtitle}</SubTitle>
    </Box>
  );
};

export default BoxImage;
