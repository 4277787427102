import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ChevronRight: React.FC<IconProps> = (props: IconProps) => (
  <Icon width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
    <path d='M10.5859 9.41406L17.1726 15.9994L10.5859 22.5847L13.4153 25.4141L22.8286 15.9994L13.4153 6.58473L10.5859 9.41406Z' />
  </Icon>
);

export default ChevronRight;
