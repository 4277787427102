import EscPosEncoder from '@revell29/esc-pos-encoder';
import { loadImage } from 'canvas';
import { stringSplitter } from 'lib/helpers';
import { IUserInfo } from 'types/auth.types';
import { StructSetting } from 'types/common.types';
import { ILocations } from 'types/register.types';
import { currencyFormat } from 'utils';
import { convertToLocalDate } from 'utils/datetime';

interface RawCommand {
  settingPrint: StructSetting | null;
  location: ILocations | undefined | null;
  profile: IUserInfo | null;
  paperSize: number;
  printCopy: number;
}

export async function testCommand({
  settingPrint,
  location,
  profile,
  paperSize,
  printCopy,
}: RawCommand): Promise<any> {
  const encoder = new EscPosEncoder();
  let result: any = encoder.initialize().setPinterType(Number(paperSize)).align('center');
  if (settingPrint?.logo && settingPrint?.logo.length > 0) {
    const img = await loadImage(settingPrint?.logo as string);
    if (paperSize === 58 || paperSize === 76) {
      result = result.align('center').image(img, 200, 56, 'atkinson');
    } else if (paperSize === 80) {
      result = result.align('center').image(img, 400, 120, 'atkinson');
    }
  }

  result = result
    .newline()
    .newline()
    .size(0.1)
    .bold(true)
    .line(`${settingPrint?.store_name}`)
    .bold(false)
    .line(`${settingPrint?.tagline}`);
  const lengthSplit = paperSize === 80 ? 45 : 28;

  if (location && location.address) {
    const arrayAddress = stringSplitter(lengthSplit, location?.address);
    arrayAddress?.forEach((address: string, _index: number) => {
      result = result.line(`${address}`);
    });
  }

  result = result
    .newline()
    .newline()
    .align('left')
    .line(`${settingPrint?.transaction_no_label}: JP/RECEIPT/TEST`)
    .line(`${settingPrint?.cashier_label}: ${profile?.user.full_name}`)
    .line(`${settingPrint?.date_label}: ${convertToLocalDate(new Date())}`);

  if (
    settingPrint &&
    settingPrint.show_customer_name &&
    !settingPrint.show_email &&
    !settingPrint.show_phone_number
  ) {
    result = result.line(`Pelanggan: Pelangan Umum`);
  } else {
    if (settingPrint?.show_customer_name) {
      result = result.line(`Pelanggan: Pelanggan Umum`);
    }

    if (settingPrint?.show_email) {
      result = result.line(`Email: pelanggan@gmail.com`);
    }

    if (settingPrint?.show_email) {
      result = result.line(`Telp: -`);
    }
  }

  result = result.align('center').bold(true).line(`TEST PRINT`).bold(false).align('left');
  result = result.printLine('-');

  result = result
    .bold(true)
    .line(`Produk Test`)
    .bold(false)
    .line(`BRG-TEST`)
    .oneLine(`1 @ ${currencyFormat(10000)}`, `${currencyFormat(Number(1) * Number(10000))}`);

  result = result.oneLine('Subtotal', currencyFormat(Number(10000)));
  result = result
    .bold(true)
    .oneLine(`Total (1)`, currencyFormat(Number(10000)))
    .printLine('-')
    .bold(false);

  result = result.oneLine('Kas', currencyFormat(10000));
  result = result.printLine('-');
  result = result.newline().newline().align('center');

  if (settingPrint?.instagram_footer) {
    result = result.line(`IG ${settingPrint.instagram_footer}`);
  }

  if (settingPrint?.facebook_footer) {
    result = result.line(`FB ${settingPrint.facebook_footer}`);
  }

  if (settingPrint?.twitter_footer) {
    result = result.line(`Twitter ${settingPrint.twitter_footer}`);
  }

  // encode all command and loop according to Print Copy
  const splitLengthFooter = paperSize === 80 ? 45 : 28;
  result = result
    .text(String(settingPrint?.note ?? ''), splitLengthFooter)
    .newline()
    .newline()
    .newline()
    .cut()
    .encode();

  const arrayCombine: Array<number> = [];
  for (let i = 0; i < Number(printCopy); i++) {
    result.map((b: number) => arrayCombine.push(b));
  }

  const byteArray = new Uint8Array(arrayCombine);
  return new Uint8Array(byteArray.buffer);
}
