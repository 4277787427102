import { Stack, StackProps } from '@chakra-ui/react';
import React from 'react';

const BoxPayments: React.FC<StackProps> = ({ children }: StackProps) => {
  return (
    <Stack border='1px' borderColor='system.outline' rounded='4px' py='16px' px={3} h='100%'>
      {children}
    </Stack>
  );
};
export default BoxPayments;
