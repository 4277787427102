import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { TagIcon } from 'components/icons';
import * as React from 'react';
import { PriceBook } from 'types/common.types';
import { currencyFormat } from 'utils';

interface PresetPopover {
  placement?: Placement;
  listBook: PriceBook[];
  sellPrice: number;
}

export const TooltipPrice: React.FC<PresetPopover> = ({ placement, listBook, sellPrice }: PresetPopover) => {
  return (
    <Popover trigger='hover' placement={placement} isLazy>
      <PopoverTrigger>
        <button className='mr-2'>
          <TagIcon fill='jubelio.purple' />
        </button>
      </PopoverTrigger>
      <PopoverContent _focus={{ outline: 'none' }} zIndex={11} w='auto' rounded='4px'>
        <PopoverArrow />
        <PopoverBody zIndex={11} p='14px'>
          <Box color='jubelio.black'>
            <Flex alignItems='center' gridGap={2}>
              <InfoOutlineIcon h='16px' w='16px' color='system.blue' />
              <Text fontWeight='bold' color='jubelio.black' fontSize='14px'>
                Harga Bertingkat
              </Text>
            </Flex>
            <Divider my={3} />
            {listBook &&
              listBook.map((item, index) => {
                return (
                  <div key={index}>
                    {index === 0 && item.min_unit !== 1 && (
                      <Box>
                        <Text as='span'>
                          Pembelian <b>1</b> Barang :{' '}
                          <Text as='span' color='jubelio.purple'>
                            {currencyFormat(sellPrice)}
                          </Text>
                        </Text>
                      </Box>
                    )}
                    <Text as='span'>
                      Pembelian{' '}
                      {item.min_unit > 1 ? (
                        <>
                          &gt; <b>{item.min_unit - 1}</b>
                        </>
                      ) : (
                        <b>{item.min_unit}</b>
                      )}{' '}
                      Barang :{' '}
                      <Text as='span' color='jubelio.purple'>
                        {currencyFormat(item.retail_price)}
                      </Text>
                    </Text>
                  </div>
                );
              })}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
