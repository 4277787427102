import { QuestionOutlineIcon, SettingsIcon } from '@chakra-ui/icons';
import { Avatar, IconButton, MenuDivider, MenuItem } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { SignOutIcon } from 'components/icons';
import config from 'constant';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutAction, setIsLogout } from 'redux/reducer/auth';
import { togglePopupReturn } from 'redux/reducer/return';
import { togglePopup } from 'redux/reducer/sales';
import { setFreshchatCustomConfig } from 'redux/reducer/settings';
import authAction from 'services/http/auth.request';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

const MenuProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // ** Redux Store
  const profile = useAppSelector((state) => state.auth.profile);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const locationState = useAppSelector((state) => state.register.location);

  const handleCommonRoutes = () => {
    if (location.pathname === '/sales/checkout') {
      dispatch(togglePopup(true));
      return true;
    }

    if (['/sales/return', '/sales/return-checkout'].includes(location.pathname)) {
      dispatch(togglePopupReturn(true));
      return true;
    }

    return false;
  };

  const handleLogout = async () => {
    dispatch(setIsLogout(true));
    if (handleCommonRoutes()) return;

    await authAction.logout();
    dispatch(logoutAction());
    window.location.href = '/';
  };

  const handleRouteSetting = () => {
    if (handleCommonRoutes()) return;

    dispatch(setFreshchatCustomConfig(false));
    navigate('/setting/store-info');
  };

  return (
    <div className='relative z-20'>
      <Menu placement='right-start'>
        <MenuButton className='w-[70px] bg-transparent' as={IconButton} aria-label='Menu'>
          <Avatar
            h='32px'
            w='32px'
            src={`${config.IMAGE_URL}?token=${LocalStorageService.getItem('token')}`}
          />
        </MenuButton>
        <MenuList>
          <MenuItem>
            <div className='flex flex-col justify-start'>
              <span className='text-sm'>{locationState?.location_name}</span>
              <span className='text-sm'>{registerInfo?.register_name}</span>
            </div>
          </MenuItem>
          <MenuItem>
            <div className='flex flex-col justify-start'>
              <p className='text-xs text-gray-500'>
                {profile?.user.full_name ? profile?.user.full_name : '-'}
              </p>
              <p className='text-xs'> {profile?.user.email}</p>
            </div>
          </MenuItem>
          <MenuDivider />
          <MenuItem icon={<SettingsIcon w='16px' h='16px' />} onClick={handleRouteSetting}>
            Pengaturan
          </MenuItem>
          <MenuItem
            icon={<QuestionOutlineIcon w='16px' h='16px' />}
            onClick={() => {
              window.open('https://education.jubelio.com/', '_blank');
            }}
          >
            Bantuan
          </MenuItem>
          <MenuDivider />
          <MenuItem icon={<SignOutIcon w='16px' h='16px' />} color='red' onClick={handleLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default MenuProfile;
