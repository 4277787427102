import { ICartList } from 'types/sales.types';

import { db } from './connection';
export default {
  get: async (): Promise<ICartList[]> => {
    return db.cart.toArray();
  },
  add: async (value: ICartList): Promise<void> => {
    await db.cart.add(value);
  },
  filter: async (query: string): Promise<ICartList[]> => {
    return db.cart
      .filter((customer) => {
        const search = new RegExp(query.toLowerCase());
        return (
          search.test(customer.name ? customer.name.toLowerCase() : '') ||
          search.test(customer.salesorder_no ? customer.salesorder_no.toLowerCase() : '')
        );
      })
      .toArray();
  },
  findName: async (query: string): Promise<ICartList> => {
    return db.cart.where('name').equals(query).first();
  },
  delete: async (id: number): Promise<void> => {
    await db.cart.delete(id);
  },
  /**
   * clear all cart info from IndexDB
   * @returns {Promise<void>}
   * @example await cart.clear();
   */
  clear: async (): Promise<void> => {
    return db.cart.clear();
  },

  updateCart: async (value: ICartList): Promise<number> => {
    return db.cart
      .where('name')
      .equals(value.name)
      .modify({
        ...value,
      });
  },
};
