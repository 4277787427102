import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import ListTotal from 'components/list/ListTotal';
import { Dashed } from 'components/utils';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { IDetailTransaction } from 'types/bopis.types';
import { currencyFormat } from 'utils';

interface Props {
  showTotal: boolean;
  detailOrder: IDetailTransaction;
  onShowTotal: (state: boolean) => void;
}

const ListDetail: React.FC<Props> = ({ showTotal, onShowTotal, detailOrder }: Props) => {
  const { settings } = useAppSelector((state) => state.commons);

  return (
    <VStack w='full' spacing='8px' px='5px' justifyItems='start'>
      {showTotal && (
        <>
          <ListTotal
            leftText={`Sub Total`}
            rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.sub_total)))}</Text>}
          />
          {Number(detailOrder.total_disc) > 0 && (
            <ListTotal
              leftText='Diskon Barang'
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(Number(detailOrder.total_disc))}
                </Text>
              }
            />
          )}
          {(Number(detailOrder.service_fee) !== 0 || Number(detailOrder.add_disc) !== 0) && (
            <ListTotal
              leftText='Diskon Transaksi'
              rightText={
                <Text color='jubelio.primary'>
                  {currencyFormat(
                    Math.abs(
                      settings?.discount_as_service_fee
                        ? Number(detailOrder.service_fee)
                        : Number(detailOrder.add_disc)
                    )
                  )}
                </Text>
              }
            />
          )}
          {Number(detailOrder.shipping_cost) > 0 && (
            <ListTotal
              leftText='Ongkos Kirim'
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.shipping_cost)))}</Text>}
            />
          )}

          {Number(detailOrder.insurance_cost) > 0 && (
            <ListTotal
              leftText='Asuransi Pengiriman'
              rightText={
                <Text>{currencyFormat(Math.abs(Number(detailOrder.insurance_cost)))}</Text>
              }
            />
          )}

          {Number(detailOrder.total_tax) > 0 && (
            <ListTotal
              leftText='Pajak'
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.total_tax)))}</Text>}
            />
          )}

          {Number(detailOrder.add_fee) !== 0 && (
            <ListTotal
              leftText='Biaya Lainnya'
              rightText={<Text>{currencyFormat(Math.abs(Number(detailOrder.add_fee)))}</Text>}
            />
          )}
          <Dashed w='full' pt={2} />
        </>
      )}

      <ListTotal
        leftText={'Total'}
        pt='5px'
        rightText={
          <HStack>
            <Text color='jubelio.primary' fontWeight='bold'>
              {currencyFormat(Math.abs(Number(Number(detailOrder.grand_total))))}
            </Text>
            <IconButton
              variant='ghost'
              aria-label='add to shopping cart'
              _focus={{ boxShadow: 'none' }}
              onClick={() => onShowTotal(!showTotal)}
              icon={
                showTotal ? (
                  <ChevronDownIcon h='20px' w='20px' />
                ) : (
                  <ChevronUpIcon h='20px' w='20px' />
                )
              }
              size='sm'
            />
          </HStack>
        }
      />
    </VStack>
  );
};

export default ListDetail;
