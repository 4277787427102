import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CheckCircleIcon: React.FC<IconProps> = (props) => {
  return (
    <Icon viewBox='0 0 56 56' {...props}>
      <path
        d='M27.9996 4.66602C15.1663 4.66602 4.66626 15.166 4.66626 27.9994C4.66626 40.8327 15.1663 51.3327 27.9996 51.3327C40.8329 51.3327 51.3329 40.8327 51.3329 27.9994C51.3329 15.166 40.8329 4.66602 27.9996 4.66602ZM27.9996 46.666C17.7096 46.666 9.33293 38.2894 9.33293 27.9994C9.33293 17.7094 17.7096 9.33268 27.9996 9.33268C38.2896 9.33268 46.6663 17.7094 46.6663 27.9994C46.6663 38.2894 38.2896 46.666 27.9996 46.666ZM38.7096 17.686L23.3329 33.0627L17.2896 27.0427L13.9996 30.3327L23.3329 39.666L41.9996 20.9993L38.7096 17.686Z'
        fill='#4CAF50'
      />
    </Icon>
  );
};

export default CheckCircleIcon;
