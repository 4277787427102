import { Divider, VStack } from '@chakra-ui/layout';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { IPayments } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import { currencyFormat } from 'utils';

interface Props {
  subtitle: string;
  grandTotal: number;
  duePayment: number;
  listPayments: IPayments[];
  listItemCart: IItemCart[];
  isReturn: boolean;
  continuePayment?: boolean;
  remainingPayment?: number;
}

const BoxTotal: React.FC<Props> = ({
  subtitle,
  grandTotal,
  duePayment,
  listPayments,
  listItemCart,
  isReturn,
  continuePayment,
  remainingPayment,
}: Props) => {
  const getDuePayment = React.useMemo(() => {
    if (!isReturn && duePayment === 0 && listPayments.length === 0) {
      return grandTotal;
    }
    if (isReturn && listPayments.length === 0) {
      return 0;
    }
    return Math.abs(duePayment);
  }, [duePayment, listPayments, listItemCart]);

  return (
    <VStack
      justifyContent='center'
      px={3}
      spacing={3}
      className='rounded border border-gray-300 p-8'
    >
      <SubTitle color='jubelio.black'>{subtitle}</SubTitle>
      <Title fontSize={{ base: '20px', sm: '18px', md: '19px' }} color='jubelio.primary'>
        {currencyFormat(Math.abs(continuePayment ? Number(remainingPayment) : grandTotal))}
      </Title>
      <Divider />
      <SubTitle color='jubelio.black'>
        {duePayment > 0 && !isReturn ? 'Kembalian' : 'Sisa'} : {currencyFormat(getDuePayment)}
      </SubTitle>
    </VStack>
  );
};

export default BoxTotal;
