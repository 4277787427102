import { Box, Button, Text, useToast } from '@chakra-ui/react';
import React from 'react';
interface NotificationProps {
  notification: (
    id: string,
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration?: number
  ) => void;

  customNotification: (
    id: string,
    title: string,
    message: string,
    onOpenVoucher: () => void,
    duration?: number
  ) => void;
}

const useNotification = (): NotificationProps => {
  const toast = useToast();

  const notification = (
    id = '',
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration = 5000
  ) => {
    toast({
      id,
      description: message ?? 'Terjadi kesalahan pada aplikasi',
      status: status,
      duration: duration ?? 3000,
      position: 'top',
      isClosable: true,
    });
  };

  const customNotification = (
    id = '',
    title: string,
    message: string,
    onOpenVoucher: () => void,
    duration = 5000
  ) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        position: 'top',
        duration: duration || 5000,
        isClosable: true,
        render: ({ onClose }) => (
          <Box
            p={3}
            bg='blue.500'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            rounded={4}
            color='white'
            fontSize='sm'
          >
            <Text maxW='15rem'>{message}</Text>
            <Button
              onClick={() => {
                onOpenVoucher();
                onClose();
              }}
              size='xs'
              ml={2}
              color='black'
            >
              Lihat
            </Button>
          </Box>
        ),
      });
    }
  };

  return { notification, customNotification };
};

export default useNotification;
