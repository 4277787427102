import { Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react';
import { CircleIcon } from 'components/icons';
import React from 'react';

interface Props extends TagProps {
  withIcon?: boolean;
}

const BadgeProduct: React.FC<Props> = ({ children, withIcon = true, ...rest }: Props) => {
  return (
    <Tag {...rest} px={2} py={1} rounded='0.29rem'>
      {withIcon && <TagLeftIcon as={CircleIcon} />}
      <TagLabel fontWeight='bold'>{children}</TagLabel>
    </Tag>
  );
};

export default BadgeProduct;
