import { Flex, Image, VStack } from '@chakra-ui/react';
import BadgeProduct from 'components/badge/BadgeProduct';
import { SubTitle, Title } from 'components/typography';
import React from 'react';
import { IItemCart } from 'types/products.types';

interface IProps {
  item: IItemCart;
}

const CardProductDetail: React.FC<IProps> = ({ item }: IProps) => {
  const getColorSchema = () => {
    let colorScheme = 'green';
    if (item.use_serial_number) {
      colorScheme = 'serial';
    } else if (item.use_batch_number) {
      colorScheme = 'batch';
    } else if (item.is_bundle) {
      colorScheme = 'bundle';
    }

    return colorScheme;
  };

  return (
    <Flex
      border='1px'
      borderColor='jubelio.grey'
      p={3}
      rounded='4px'
      mb={4}
      bg='jubelio.grey100'
      position='relative'
    >
      <Image src={item.thumbnail} alt={item.item_name} boxSize='120px' rounded='8px' />
      <VStack ml='15px' spacing={2} w='full' alignItems='start'>
        <Title>{item.item_name}</Title>
        <SubTitle color='jubelio.black'>#{item.item_code}</SubTitle>
        <SubTitle>Stok Sisa: {item.available}</SubTitle>
        <BadgeProduct mb='12px' colorScheme={getColorSchema()}>
          {item.use_batch_number
            ? 'Batch'
            : item.use_serial_number
            ? 'Serial'
            : item.is_bundle
            ? 'Bundle'
            : 'Satuan'}
        </BadgeProduct>
      </VStack>
    </Flex>
  );
};

export default CardProductDetail;
