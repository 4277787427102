import { Box, Button, IconButton, SimpleGrid, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Buffer } from 'buffer';
import { CardMain } from 'components/card';
import { SaveIcon } from 'components/icons';
import { ModalCart, ModalNotif, ModalPayment, ModalPaymentCash, ModalVoucher } from 'components/modal';
import ModalAuthDP from 'components/modal/ModalAuthDP';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ModalConfirmationPromotion from 'components/modal/ModalConfirmationPromotion';
import ModalConfirmationRounding from 'components/modal/ModalConfirmationRounding';
import ModalPaymentQris from 'components/modal/ModalPaymentQris';
import ComponentToPrintQRISDynamic from 'components/receipt/QRISDynamicToPrint';
import ComponentToPrint from 'components/receipt/ReceiptToPrint';
import LoadingCashier from 'components/state/LoadingCashier';
import CardDetail from 'components/ui/cart';
import { BoxEmail, BoxTotal, ListPayments } from 'components/ui/checkout';
import BoxPayments from 'components/ui/checkout/BoxPayments';
import BoxPromotion from 'components/ui/checkout/BoxPromotion';
import { DetailHistory } from 'components/ui/transaction/DetailHistory';
import config, { LIST_PAYMENT_TYPE, PAYMENT_TYPE } from 'constant';
import messages, { modal } from 'constant/messages';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNotification, useOrder, usePayments, usePromotions } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useOrderDownPayment from 'hooks/useOrderDownPayment';
import { usePrintUsb } from 'hooks/usePrintUsb';
import { DeepLinker } from 'lib/deeplinker';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  checkoutAction,
  clearPaymentsList,
  deleteContinuePayment,
  deletePayment,
  resetOrder,
  setContactToOrder,
  setListAllPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListValidVoucher,
  setLoadingSendOrder,
  setPaymentMethod,
  setPaymentMethodContinuePayment,
  setPosIsShipping,
  setSendStructByEmail,
  setTotalDetail,
  togglePopup,
  updateAddFee,
  updateListFreeItemV2,
  updateTotalOrder,
} from 'redux/reducer/sales';
import { setStructData } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import transactionRequest from 'services/http/transaction.request';
import cart from 'services/indexdb/cart';
import paymentMethod from 'services/indexdb/payment-method';
import { AuthorizedForm, IPayments } from 'types/common.types';
import { IItemCart } from 'types/products.types';
import { IGetPromotionData, IPaymentGetPromotion, IValidatePromotionList } from 'types/promotion.types';
import { ILocations } from 'types/register.types';
import { IFormPaymentMethod, IOrderData, ITotalDetail } from 'types/sales.types';
import {
  columnSuggestMoney,
  currencyFormat,
  dateUTCBuyItems,
  getAmountDetailTransaction,
  getSuggestMoney,
  isJsonString,
  mappingCheckoutData,
} from 'utils';
import { rawCommand } from 'utils/printer/transaction-command';
import { removeDuplicates } from 'utils/promotion';
import { getListFreeItems, getListFreeItemsV2 } from 'utils/promotions';
import { isMobile } from 'utils/user-agent';

import { DetailOrderTransaction } from '../../types';

// NOTE : This is for electron app
// import appRuntime from '../../appRuntime';

const CheckoutPage = () => {
  const [payments, setPaymets] = React.useState<IPayments[]>([]);
  const [isLoadingOrder, setIsLoadingOrder] = React.useState<boolean>(false);
  const componentRef = React.useRef(null);
  const componentRefQRISDynamic = React.useRef(null);
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const [loadingCheckoutDP, setLoadingCheckoutDP] = React.useState<boolean>(false);
  const [orderPrint, setOrderPrint] = React.useState<DetailOrderTransaction | null>(null);
  const [email, setEmail] = React.useState<string>('');
  const [voucherData, setVoucherData] = React.useState<IFormPaymentMethod>({} as IFormPaymentMethod);
  const [dataPayment, setDataPayment] = React.useState<{
    total: number;
    isDp?: boolean;
    dpAmount?: number;
  } | null>(null);
  const [formDataPayment, setFormDataPayment] = React.useState<IFormPaymentMethod | null>(null);
  const [idxDeletedPayment, setIdxDeletedPayment] = React.useState<number | null>(null);
  const [includeRoundMoney, setIncludeRoundMoney] = React.useState<boolean>(false);
  const [loadingAuthDP, setLoadingAuthDP] = React.useState<boolean>(false);
  const [isRecalculate, setIsRecalculate] = React.useState<boolean>(false);
  const [errorPromotion, setErrorPromotion] = React.useState<{
    promotion_id?: number;
    message: string;
    data?: any;
  } | null>(null);
  const [errorPaymentMethod, setErrorPaymentMethod] = React.useState<boolean>(false);
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');

  // custom hooks
  const navigate = useNavigate();
  const { getListPayments } = usePayments();
  const dispatch = useAppDispatch();
  const { sendOrder } = useOrder();
  const { sendOrderDownPayment } = useOrderDownPayment();
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const { isOpen: isOpenCash, onOpen: onOpenCash, onClose: onCloseCash } = useDisclosure();
  const { isOpen: isOpenPayment, onOpen: onOpenPayment, onClose: onClosePayment } = useDisclosure();
  const { isOpen: isOpenNotif, onOpen: onOpenNotif, onClose: onCloseNotif } = useDisclosure();
  const { isOpen: isOpenQris, onOpen: onOpenQris, onClose: onCloseQris } = useDisclosure();
  const { onOpen: onOpenCart, onClose: onCloseCart, isOpen: isOpenCart } = useDisclosure();
  const { onOpen: onOpenVoucher, onClose: onCloseVoucher, isOpen: isOpenVoucher } = useDisclosure();
  const {
    onOpen: onOpenConfirmVoucher,
    onClose: onCloseConfirmVoucher,
    isOpen: isOpenConfirmVoucher,
  } = useDisclosure();
  const {
    isOpen: isOpenNotifDownPayment,
    onOpen: onOpenNotifDownPayment,
    onClose: onCloseNotifDownPayment,
  } = useDisclosure();
  const {
    onOpen: onOpenConfirmRounding,
    onClose: onCloseConfirmRounding,
    isOpen: isOpenConfirmRounding,
  } = useDisclosure();
  const { onOpen: onOpenAuthDP, onClose: onCloseAuthDP, isOpen: isOpenAuthDP } = useDisclosure();
  const {
    onOpen: onOpenConfirmPromotion,
    onClose: onCloseConfirmPromotion,
    isOpen: isOpenConfirmPromotion,
  } = useDisclosure();

  // ** Redux selector
  const orderReducer = useAppSelector((state) => state.sales.order);
  const listPayments = useAppSelector((state) => state.sales.listPayments);
  const duePayment = useAppSelector((state) => state.sales.duePayment);
  const customerInfo = useAppSelector((state) => state.sales.customerInfo);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const sendStructByEmail = useAppSelector((state) => state.sales.sendStructByEmail);
  const posIsShipping = useAppSelector((state) => state.sales.pos_is_shipping);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const remainingPayment = useAppSelector((state) => state.sales.remainingPayment);
  const listTransactionPromotion = useAppSelector((state) => state.sales.listTransactionPromotion);
  const storeTotalDetail = useAppSelector((state) => state.sales.totalDetail);
  const cartId = useAppSelector((state) => state.sales.cartId);
  const listPromotionAmount = useAppSelector((state) => state.sales.listPromotionAmount);
  const listPromotionItem = useAppSelector((state) => state.sales.listPromotionItem);

  const detailOrderHistory = useAppSelector((state) => state.sales.detailOrderHistory);
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const settings = useAppSelector((state) => state.commons.settings);
  const printer = useAppSelector((state) => state.commons.printer);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);
  const location = useAppSelector((state) => state.register.location);
  const profile = useAppSelector((state) => state.auth.profile);
  const sales = useAppSelector((state) => state.sales);
  const isCheckout = useAppSelector((state) => state.sales.isCheckout);
  const printMethod = useAppSelector((state) => state.commons.printMethod);

  const { notification } = useNotification();
  const { validatePromotion } = usePromotions();
  const { printReceipt, getDevices } = usePrintUsb();
  const totalDetails = React.useMemo(
    (): ITotalDetail => getAmountDetailTransaction(sales, location as ILocations, settings),
    [listItemCart, sales, location, settings]
  );

  const listAllPaymentMethod = useLiveQuery<IPayments[]>(() => {
    return paymentMethod.get();
  }, []);

  const disablePayment = React.useMemo(() => {
    const haveToPay = continuePayment ? remainingPayment : Number(orderReducer.grand_total);
    if (duePayment > haveToPay) {
      return true;
    } else if ((duePayment === 0 || duePayment > 0) && listPayments.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [duePayment, orderReducer.grand_total, listPayments, listItemCart, remainingPayment, continuePayment]);

  const suggestMoneyMemoize = React.useMemo(
    () => getSuggestMoney(Number(continuePayment ? remainingPayment : orderReducer.grand_total)),
    [remainingPayment, orderReducer.grand_total]
  ); // generate suggest money

  const generateColumns = React.useMemo(() => columnSuggestMoney(suggestMoneyMemoize), [suggestMoneyMemoize]);

  /**
   * @description: choose type payment method
   * and show popup payment method
   */
  const choosePayment = React.useCallback(async (paymentType: number) => {
    const listPaymentMethod = await getListPayments(paymentType);
    setPaymets(listPaymentMethod);

    if (paymentType === PAYMENT_TYPE.QRIS) {
      const qrisDynamicMethod = listPaymentMethod.filter(
        (payment) => payment.payment_id === config.QRIS_DYNAMIC
      );
      const qrisStaticMethod = listPaymentMethod.filter(
        (payment) => payment.payment_id === config.QRIS_STATIC
      );
      let paymentData: IPayments[] = [];
      if (isOnline && qrisDynamicMethod) {
        paymentData = qrisDynamicMethod;
      } else {
        paymentData = qrisStaticMethod;
      }
      const resValidateQRIS = validateQRISPayment(listPaymentMethod);
      if (resValidateQRIS !== '') return notification('', resValidateQRIS, 'info');

      const mapPayments = paymentData.map((i) => {
        return { payment_id: i.payment_id, payment_name: i.payment_name };
      });
      if (sales.order.promotions.length > 0) {
        if (isOnline) {
          const mapPromotion = sales.order.promotions.map((p: any) => {
            let promo: IValidatePromotionList = { promotion_id: p.promotion_id };
            if (p.voucher_code && p.voucher_code !== '') {
              promo = { ...promo, voucher_code: p.voucher_code };
            }
            return promo;
          });
          const isValidPromotion: {
            isValid: boolean;
            message: string;
          } = await validatePromotion(
            listItemCart,
            totalDetails.totalAfterDiscount ?? 0,
            mapPayments,
            mapPromotion
          );
          if (!isValidPromotion.isValid) {
            const parseError = JSON.parse(isValidPromotion.message);
            setErrorPaymentMethod(true);
            setErrorPromotion(parseError);
            onOpenConfirmPromotion();
            return;
          }
        } else {
          const mapPromotionAmount = listPromotionAmount.map((p) => p.promotion);
          const concatListPromotion = [...listPromotionItem, ...mapPromotionAmount];
          const removeDuplicatePromotion = removeDuplicates(concatListPromotion, 'promotion_id');
          const validatePromotion = validatePaymentOffline(removeDuplicatePromotion, mapPayments);

          if (validatePromotion) {
            setErrorPromotion(validatePromotion);
            setErrorPaymentMethod(true);
            onOpenConfirmPromotion();
            return;
          }
        }
      }
    }

    switch (paymentType) {
      case PAYMENT_TYPE.CASH:
        onOpenCash();
        break;
      case PAYMENT_TYPE.QRIS:
        onOpenQris();
        break;
      case PAYMENT_TYPE.VOUCHER:
        onOpenVoucher();
        break;
      default:
        onOpenPayment();
        break;
    }
  }, []);

  //#region  //*=========== Rounding Validation ===========
  const isRoundingAllowed = () => {
    return (
      settings.allow_rounding &&
      settings.nonactive_round_payment &&
      settings.nonactive_round_payment.length > 0 &&
      Number(storeTotalDetail?.roundMoney) > 0
    );
  };

  const isRoundingApplicable = (filterNonactivePaymentList: IPayments[]) => {
    return (
      (filterNonactivePaymentList.length !== 0 && Number(totalDetails?.roundMoney ?? 0) === 0) ||
      (filterNonactivePaymentList.length === 0 && Number(totalDetails?.roundMoney ?? 0) > 0)
    );
  };

  const calculateNominal = (formData: IFormPaymentMethod) => {
    let nominal = Number(formData.payment_amount ?? 0);
    if (Number(formData.payment_amount ?? 0) === Math.abs(duePayment)) {
      if (Number(totalDetails?.roundMoney ?? 0) <= 0) {
        nominal += Number(storeTotalDetail?.roundMoney ?? 0);
        setIncludeRoundMoney(true);
      } else {
        nominal -= Number(storeTotalDetail?.roundMoney ?? 0);
        setIncludeRoundMoney(false);
      }
    }
    return nominal;
  };

  const calculateNominalCash = (total: number) => {
    let nominal = Number(total);
    if (Number(total) === Math.abs(duePayment)) {
      if (Number(totalDetails?.roundMoney ?? 0) <= 0) {
        nominal += Number(storeTotalDetail?.roundMoney ?? 0);
        setIncludeRoundMoney(true);
      } else {
        nominal -= Number(storeTotalDetail?.roundMoney ?? 0);
        setIncludeRoundMoney(false);
      }
    }
    return nominal;
  };
  //#endregion  //*======== Rounding Validation ===========

  const validateQRISPayment = (listPaymentMethod: IPayments[]) => {
    const qrisDynamic = listPaymentMethod.find((payment) => payment.payment_id === config.QRIS_DYNAMIC);

    const qrisStatic = listPaymentMethod.find((payment) => payment.payment_id === config.QRIS_STATIC);

    if (isOnline && !qrisDynamic && !qrisStatic) {
      return 'Pembayaran QRIS anda belum aktif';
    }

    if (!isOnline && !qrisStatic) {
      return 'Pembayaran QRIS anda belum aktif';
    }

    if (Number(orderReducer.grand_total ?? 0) > config.MAX_QRIS_AMOUNT) {
      return 'Batas maksimal menggunakan QRIS sebesar Rp 10.000.000';
    }

    if (Number(orderReducer.grand_total ?? 0) <= 1) {
      return 'Batas minimal menggunakan QRIS sebesar Rp 1';
    }

    return '';
  };

  //#region  //*=========== Payment Region ===========
  const mappingNewListPayment = (newListPayments: IPayments[]): IPayments[] => {
    const filterNonactivePaymentList = settings.nonactive_round_payment
      ? newListPayments.filter((l) =>
          settings.nonactive_round_payment
            ? !settings.nonactive_round_payment.some((dl) => Number(l.payment_id) === Number(dl.payment_id))
            : []
        )
      : [];

    return filterNonactivePaymentList;
  };

  const submitPayment = (formData: IFormPaymentMethod) => {
    const newListPayments = [...listPayments];
    newListPayments.push(formData.payments);

    const filterNonactivePaymentList = mappingNewListPayment(newListPayments);
    let nominal = Number(formData.payment_amount ?? 0);

    if (isRoundingAllowed()) {
      if (isRoundingApplicable(filterNonactivePaymentList)) {
        nominal = calculateNominal(formData);
        onClosePayment();
        onOpenConfirmRounding();
        setFormDataPayment({ ...formData, payment_amount: nominal });
      } else {
        handlePayment({ ...formData, payment_amount: nominal });
      }
    } else {
      handlePayment({ ...formData, payment_amount: nominal });
    }
  };

  const submitPaymentCash = async (total: number, isDp?: boolean, dpAmount?: number) => {
    const listPayment = await getListPayments(PAYMENT_TYPE.CASH);
    const payment = listPayment.find((item) => item.payment_type === PAYMENT_TYPE.CASH);
    const newListPayments = [...listPayments];
    if (payment) newListPayments.push(payment);

    const filterNonactivePaymentList = mappingNewListPayment(newListPayments);
    let nominal = Number(total);

    if (isRoundingAllowed()) {
      if (isRoundingApplicable(filterNonactivePaymentList)) {
        nominal = calculateNominalCash(total);
        setIncludeRoundMoney(true);
        onOpenConfirmRounding();
        setDataPayment({ total: nominal, isDp, dpAmount });
      } else {
        handleSuggestPayment(PAYMENT_TYPE.CASH, nominal, isDp, dpAmount);
      }
    } else {
      handleSuggestPayment(PAYMENT_TYPE.CASH, nominal, isDp, dpAmount);
    }
  };

  const choosePaymentSuggestSame = () => {
    const total =
      listPayments.length > 0
        ? Math.abs(duePayment)
        : continuePayment
        ? remainingPayment
        : orderReducer.grand_total;
    submitPaymentCash(total ?? 0);
  };

  // This function handles the payment process by updating the payment method
  // and dispatching the appropriate action based on whether it's a continued payment.
  const handlePayment = (formData: IFormPaymentMethod) => {
    // Create the payload by merging the provided payment data and other necessary fields.
    const payload = {
      ...formData.payments,
      no_ref: formData.no_ref,
      notes: formData.notes,
      payment_charge: 0,
      payment_amount: formData.payment_amount,
      created_date: dateUTCBuyItems(),
    };

    // If it's a continued payment, dispatch the 'setPaymentMethodContinuePayment' action,
    // otherwise dispatch the 'setPaymentMethod' action.
    if (continuePayment) {
      dispatch(setPaymentMethodContinuePayment(payload));
    } else {
      dispatch(setPaymentMethod(payload));
    }
    // Close the payment modal and reset the payment form data.
    onClosePayment();
    setFormDataPayment(null);
  };

  // This function handles the suggestion of a payment method by fetching the list of payments,
  // finding the required payment type, updating its data, and dispatching the appropriate action.
  const handleSuggestPayment = async (
    paymentType: number,
    value: number,
    isDp?: boolean,
    dpAmount?: number
  ) => {
    const listPayment = await getListPayments(paymentType);
    const payment = listPayment.find((item) => item.payment_type === paymentType);

    setDataPayment(null);
    if (payment) {
      payment.payment_amount = value;
      payment.payment_charge = isDp ? Number(value) - Number(dpAmount) : 0;
      payment.created_date = dateUTCBuyItems();
      if (isDp && Number(dpAmount) < totalDetails.grandTotal) {
        payment.is_dp = isDp;
        payment.dp_amount = Number(dpAmount);
      }

      if (continuePayment) {
        dispatch(setPaymentMethodContinuePayment(payment));
      } else {
        dispatch(setPaymentMethod(payment));
      }
    }
  };

  const shouldOpenConfirmRounding = (
    newListPayments: IPayments[],
    filterNonactivePaymentList: IPayments[]
  ) => {
    if (
      settings.allow_rounding &&
      settings.nonactive_round_payment &&
      settings.nonactive_round_payment.length > 0 &&
      Number(totalDetails?.roundMoney) > 0
    ) {
      if (
        newListPayments.length > 0 &&
        ((filterNonactivePaymentList.length !== 0 && Number(totalDetails?.roundMoney ?? 0) === 0) ||
          (filterNonactivePaymentList.length === 0 && Number(totalDetails?.roundMoney ?? 0) > 0))
      ) {
        return true;
      } else if (Number(totalDetails.grandTotal ?? 0) !== Number(sales.order.grand_total ?? 0)) {
        return true;
      }
    }
    return false;
  };

  // ** Handler to delete paymant and show popup DP if user use DP
  const handleDeletePayment = (indexPayment: number) => {
    const newListPayments = listPayments.filter((_item, key) => key !== indexPayment);
    const filterNonactivePaymentList = settings.nonactive_round_payment
      ? newListPayments.filter(
          (l) =>
            !settings.nonactive_round_payment?.some((dl) => Number(l.payment_id) === Number(dl.payment_id))
        )
      : [];

    if (shouldOpenConfirmRounding(newListPayments, filterNonactivePaymentList)) {
      setIdxDeletedPayment(indexPayment);
      setIncludeRoundMoney(Number(totalDetails?.roundMoney ?? 0) < 0);
      onOpenConfirmRounding();
    } else {
      confirmDeletePayment(indexPayment);
    }
  };

  const confirmDeletePayment = (indexPayment: number) => {
    if (continuePayment) {
      dispatch(deleteContinuePayment(indexPayment));
    } else {
      dispatch(deletePayment(indexPayment));
    }
    setIdxDeletedPayment(null);
  };
  //#endregion  //*======== Payment Region ===========

  const sendDataPrint = (data: Uint8Array, order: DetailOrderTransaction) => {
    const socket = new WebSocket(config.WS_CONNECTION);
    socket.onopen = () => socket.send(data);
    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response && response.message === 'error') setOrderPrint(order);
      if (response && response.message === 'success') onOpenNotif();
    };
    // if socket is closed or not connected Show print dialog
    socket.onclose = () => {
      setOrderPrint(order);
      socket.close();
    };
  };

  //#endregion  //*======== Socket Handler Print ===========

  //#region  //*=========== Transaction Handler ===========
  const checkOrderPayloadItems = (orderPayload: IOrderData) => {
    if (orderPayload.items && orderPayload.items.length === 0) {
      dispatch(setLoadingSendOrder(false));
      setIsLoadingOrder(false);
      setLoadingCheckoutDP(false);
      return notification('', 'Barang tidak terinput, harap input ulang transaksi', 'error', 5000);
    }
    return false;
  };

  const handleVoucherPayment = async (orderPayload: IOrderData) => {
    const voucherPayment =
      orderPayload.payments && orderPayload.payments.find((payment) => payment.payment_id === config.VOUCHER);

    if (isOnline && voucherPayment) {
      const resRedeemVoucher = await redeemVouchers(orderPayload);
      if (resRedeemVoucher !== '') {
        return notification('', resRedeemVoucher, 'warning', 5000);
      }
    }
    return false;
  };

  const printOrder = async (data: Uint8Array, resOrder: DetailOrderTransaction) => {
    if (printMethod === 'usb') {
      await printReceipt(data);
      onOpenNotif();
    } else {
      if (isMobile()) {
        const linker = DeepLinker({
          onIgnored: function () {
            setOrderPrint(resOrder);
          },
          onFallback: function () {
            onOpenNotif();
          },
        });
        linker.openUrl(`${config.POS_APP_PRINT}/${Buffer.from(data).toString('base64')}`);
      } else if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
        setOrderPrint(resOrder);
      } else {
        sendDataPrint(data, resOrder);
      }
    }
  };

  const handleSendTransaction = async (
    orderPayload: IOrderData,
    freeItems: IItemCart[],
    selectedCartId: number,
    isDp: boolean
  ) => {
    try {
      setLoadingCheckoutDP(true);

      if (isOpenAuthDP) onCloseAuthDP();
      if (loadingAuthDP) setLoadingAuthDP(false);
      if (checkOrderPayloadItems(orderPayload)) return;
      if (await handleVoucherPayment(orderPayload)) return;

      const resOrder = continuePayment
        ? await sendOrderDownPayment({ ...orderPayload, pos_is_unpaid: duePayment < 0 }, false)
        : await sendOrder(
            { ...orderPayload, pos_is_unpaid: isDp, transaction_date: dateUTCBuyItems() },
            freeItems
          );

      resOrder.cashier_name = profile?.user.full_name;
      const data = await rawCommand({
        orderData: resOrder,
        settingPrint: structSetting,
        location,
        profile,
        posSetting: settings,
        totalDetail: storeTotalDetail as ITotalDetail,
        paperSize: Number(printer?.paperSize),
        printCopy: Number(printer?.printCopy),
        listTransactionPromotion,
      });

      printOrder(data, resOrder);

      if (selectedCartId) await cart.delete(selectedCartId);
      if (isDp) setOpenConfirmation(false);
    } catch (error: any) {
      dispatch(setLoadingSendOrder(false));
    } finally {
      setIsLoadingOrder(false);
      setLoadingCheckoutDP(false);
    }
  };

  const validatePaymentOffline = (
    promotionList: IGetPromotionData[],
    paymentList: IPaymentGetPromotion[]
  ): {
    promotion_id?: number;
    message: string;
    data?: any;
  } | null => {
    const invalidPayments: IPaymentGetPromotion[] = [];
    promotionList.map((promotion: IGetPromotionData) => {
      const allPayment = promotion.promotion_payment.find((p) => p === -99);
      if (promotion.promotion_payment && promotion.promotion_payment.length > 0 && !allPayment) {
        const invalidPaymentList = paymentList.filter(
          (payloadPayment) =>
            !promotion.promotion_payment.find(
              (promotionPayment) => Number(promotionPayment) === Number(payloadPayment.payment_id)
            )
        );
        if (invalidPaymentList.length > 0) {
          const joinPaymentName = invalidPaymentList.map((o) => o.payment_name).join(', ');
          const listInvalid = {
            promotion_id: promotion.promotion_id,
            payment_name: joinPaymentName,
            promotion_name: promotion.promotion_name,
          };
          invalidPayments.push(listInvalid);
        }
      }
    });

    if (invalidPayments.length > 0) {
      const filterInvalidPayments = invalidPayments.filter(
        (payment, idx, self) =>
          idx ===
          self.findIndex(
            (s) =>
              s.payment_id === payment.payment_id &&
              s.payment_name === payment.payment_name &&
              s.promotion_name === payment.promotion_name
          )
      );
      return {
        data: filterInvalidPayments,
        message: 'Metode pembayaran tidak valid',
      };
    }
    return null;
  };

  const finishTransaction = async (order: IOrderData, duePayment: number) => {
    dispatch(setLoadingSendOrder(true));
    const mapPayments = listPayments.map((i) => {
      return { payment_id: i.payment_id, payment_name: i.payment_name };
    });
    if (sales.order.promotions.length > 0) {
      if (isOnline) {
        const mapPromotion = sales.order.promotions.map((p: any) => {
          let promo: IValidatePromotionList = { promotion_id: p.promotion_id };
          if (p.voucher_code && p.voucher_code !== '') {
            promo = { ...promo, voucher_code: p.voucher_code };
          }
          return promo;
        });
        const isValidPromotion: {
          isValid: boolean;
          message: string;
        } = await validatePromotion(
          listItemCart,
          totalDetails.totalAfterDiscount ?? 0,
          mapPayments,
          mapPromotion
        );
        if (!isValidPromotion.isValid) {
          const jsonString = isJsonString(isValidPromotion.message);
          let parseError;
          if (jsonString) {
            parseError = JSON.parse(isValidPromotion.message);
            setErrorPaymentMethod(true);
          } else {
            parseError = { message: isValidPromotion.message };
            setErrorPaymentMethod(false);
          }
          setErrorPromotion(parseError);
          onOpenConfirmPromotion();
          return;
        }
      } else {
        const mapPromotionAmount = listPromotionAmount.map((p) => p.promotion);
        const concatListPromotion = [...listPromotionItem, ...mapPromotionAmount];
        const removeDuplicatePromotion = removeDuplicates(concatListPromotion, 'promotion_id');
        const validatePromotion = validatePaymentOffline(removeDuplicatePromotion, mapPayments);

        if (validatePromotion) {
          setErrorPromotion(validatePromotion);
          setErrorPaymentMethod(true);
          onOpenConfirmPromotion();
        }
        dispatch(setLoadingSendOrder(false));
        return;
      }
    }

    if (order.items && order.items.length === 0) {
      dispatch(setLoadingSendOrder(false));
      return notification('', 'Kesalahan pada sistem, harap input ulang transaksi', 'error', 5000);
    }

    if (sendStructByEmail && orderReducer.customer?.email === '') {
      dispatch(setLoadingSendOrder(false));
      return notification('', 'Harap isi email', 'warning', 5000);
    }

    if (!settings.allow_paylater && duePayment < 0) {
      dispatch(setLoadingSendOrder(false));
      return notification('', 'Total pembayaran kurang dari nominal yang harus dibayar', 'warning', 5000);
    }

    if (duePayment < 0 && settings.allow_paylater) {
      dispatch(setLoadingSendOrder(false));
      setOpenConfirmation(true);
    } else {
      if (continuePayment && settings.authorization_paylater) {
        dispatch(setLoadingSendOrder(false));
        onOpenAuthDP();
      } else {
        handleSendTransaction(order, getListFreeItemsV2(sales.listFreeItemV2), Number(cartId), false);
      }
    }
  };
  //#endregion  //*======== Transaction Handler ===========

  const removePromotion = async (promotionId: number) => {
    const filterAllPromotion = sales.listAllPromotion.filter((item) => item.promotion_id !== promotionId);
    await dispatch(setListAllPromotion(filterAllPromotion));

    const filterFreeItem = sales.listFreeItemV2.filter((item) => item.promotion_id !== promotionId);
    await dispatch(updateListFreeItemV2(filterFreeItem));

    const filterValidVoucher = sales.listValidVoucher.filter((item) => item.promotion_id !== promotionId);
    await dispatch(setListValidVoucher(filterValidVoucher));

    const filterPromotionItem = sales.listPromotionItem.filter((item) => item.promotion_id !== promotionId);
    await dispatch(setListPromotionItem(filterPromotionItem));

    const filterPromotionAmount = sales.listPromotionAmount.filter(
      (item) => item.promotion.promotion_id !== promotionId
    );
    await dispatch(setListPromotionAmount(filterPromotionAmount));
    setIsRecalculate(true);
    dispatch(setLoadingSendOrder(false));
    dispatch(clearPaymentsList());
    setErrorPaymentMethod(false);
    onCloseConfirmPromotion();

    const findQRIS = payments.find(
      (payment) => payment.payment_id === config.QRIS_DYNAMIC || payment.payment_id === config.QRIS_STATIC
    );

    if (findQRIS) {
      onOpenQris();
    }
  };

  const recalculateOrder = React.useCallback(() => {
    const totalDetail = getAmountDetailTransaction(sales, location as ILocations, settings);
    const totalDiscount = totalDetail.discountTrx + totalDetail.salesPromotions + totalDetail.discountOutlet;
    const payload = mappingCheckoutData(sales, totalDetail, totalDiscount, settings);
    dispatch(
      checkoutAction({
        orders: payload.orders,
        listItemCart: payload.items,
      })
    );
    dispatch(setTotalDetail(totalDetail));
    setIsRecalculate(false);
  }, [sales]);

  const submitVoucher = (formData: IFormPaymentMethod) => {
    const findPayment = sales.listPayments.find((payment) => payment.payment_id !== -5);
    if (findPayment) {
      setVoucherData(formData);
      onCloseVoucher();
      onOpenConfirmVoucher();
    } else {
      handlePayment(formData);
      onCloseVoucher();
    }
  };

  const submitConfirmationVoucher = () => {
    listPayments.forEach(async (payment, index) => {
      if (payment.payment_id !== config.VOUCHER) dispatch(deletePayment(index));
    });
    handlePayment(voucherData);
    setVoucherData({} as IFormPaymentMethod);
    onCloseConfirmVoucher();
    onCloseVoucher();
  };

  const redeemVouchers = async (orderPayload: IOrderData) => {
    try {
      const payload = {
        salesorder_no: orderPayload.salesorder_no,
        payments: orderPayload.payments,
      };
      await transactionRequest.redeemVoucher(payload);
      return '';
    } catch (error: any) {
      dispatch(setLoadingSendOrder(false));
      setIsLoadingOrder(false);
      setLoadingCheckoutDP(false);
      return error.response?.data.code;
    }
  };
  //#endregion  //*======== Voucher Handler ===========

  const checkAuthorizedUser = async (values: AuthorizedForm) => {
    try {
      const payload = {
        user_id: values.user?.user_id ?? 0,
        permission_id: config.ACL_AUTH_DP,
        pin: values.pin ?? '',
        location_id: location?.location_id ?? 0,
      };
      await commonRequest.checkAuthorizedUser(payload);
      return '';
    } catch (error: any) {
      setIsLoadingOrder(false);
      return error.response?.data.code;
    }
  };

  // ** close notification to redirect to sales page and reset order data;
  const closeNotification = () => {
    onCloseNotif();
    dispatch(resetOrder());
    if (continuePayment) {
      navigate('/sales/history');
    } else {
      navigate('/sales');
    }
  };

  // ** close notification to redirect to sales page and reset order data;
  const backToHistorypage = (directToHistory: boolean) => {
    onCloseNotifDownPayment();
    dispatch(resetOrder());
    if (directToHistory || continuePayment) {
      navigate('/sales/history');
    } else {
      navigate('/sales');
    }
  };

  //#region  //*=========== React-to-print ===========
  const handleOnBeforeGetContent = React.useCallback(
    (printPayload: DetailOrderTransaction | null) => {
      return new Promise<void>((resolve) => {
        dispatch(setStructData(printPayload));
        resolve();
      });
    },
    [dispatch]
  );

  const handleAfterPrint = React.useCallback(() => {
    if (orderPrint?.pos_is_unpaid === true || Number(orderPrint?.pos_is_unpaid) === 1) {
      onOpenNotifDownPayment();
    } else {
      onOpenNotif();
    }
    dispatch(setStructData(null));
    dispatch(setTotalDetail(null));
    setIsLoadingOrder(false);
  }, [orderPrint, onOpenNotifDownPayment, onOpenNotif, dispatch]);

  const handleReactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleOnBeforeGetContent(orderPrint),
    onAfterPrint: handleAfterPrint,
  });
  //#endregion  //*======== React-to-print ===========

  // ** Trigger this function just user using browser other than chrome
  const handleReactToPrintQRISDynamic = useReactToPrint({
    content: () => componentRefQRISDynamic.current,
  });

  const submitConfirmationRounding = (total: number) => {
    dispatch(updateTotalOrder(total));
    if (idxDeletedPayment !== null) {
      confirmDeletePayment(idxDeletedPayment);
    }
    if (dataPayment) {
      handleSuggestPayment(
        PAYMENT_TYPE.CASH,
        dataPayment?.total ?? 0,
        dataPayment?.isDp,
        dataPayment?.dpAmount
      );
    }
    if (formDataPayment) {
      handlePayment(formDataPayment);
    }
    onCloseConfirmRounding();
  };

  const onSubmitPopupDP = () => {
    if (!settings.authorization_paylater) {
      dispatch(setLoadingSendOrder(true));
      handleSendTransaction(orderReducer, getListFreeItems(listTransactionPromotion), Number(cartId), true);
    } else {
      setOpenConfirmation(false);
      onOpenAuthDP();
    }
  };

  const onSubmitAuthorizationDP = async (values: AuthorizedForm) => {
    setLoadingAuthDP(true);
    if (isOnline && settings.authorization_paylater) {
      const validateAuthorizedUser = await checkAuthorizedUser(values);
      if (validateAuthorizedUser !== '') {
        return notification('', validateAuthorizedUser, 'warning', 5000);
      }
    }
    handleSendTransaction(
      { ...orderReducer, authorized_user_id: values.user?.user_id },
      getListFreeItems(listTransactionPromotion),
      Number(cartId),
      true
    );
  };

  const closePopupConfirmPromotion = () => {
    dispatch(clearPaymentsList());
    dispatch(setLoadingSendOrder(false));
    onCloseConfirmPromotion();
  };

  React.useEffect(() => {
    if (!continuePayment) dispatch(updateTotalOrder(totalDetails.grandTotal));
  }, [totalDetails.grandTotal, continuePayment]);

  React.useEffect(() => {
    dispatch(updateAddFee({ roundMoney: totalDetails.roundMoney, otherCost: totalDetails.otherCost }));
  }, [totalDetails.roundMoney, totalDetails.otherCost]);

  React.useEffect(() => {
    dispatch(
      setTotalDetail({
        ...totalDetails,
        roundMoney: Number(totalDetails.roundMoney ?? 0),
      })
    );
  }, [totalDetails.shippingCost]);

  React.useEffect(() => {
    if (orderReducer.customer) setEmail(orderReducer.customer?.email ?? '');
  }, [orderReducer.customer]);

  React.useEffect(() => {
    if (orderPrint !== null) handleReactToPrint();
  }, [orderPrint]);

  React.useEffect(() => {
    console.log(JSON.stringify(sales.order));
  }, [sales.order]);

  // disable store kredit
  const disableStoreKredit = React.useCallback(() => {
    if (!customerInfo) return true;
    if (disablePayment) return true;
    if (Number(customerInfo?.store_credit) < Number(orderReducer.grand_total)) return true;
    if (Number(orderReducer.customer?.contact_id) === -1) return true;
    if (listPayments.length > 0) return true;
    return false;
  }, [customerInfo, disablePayment, orderReducer.customer, listPayments]);

  const disableFinishTransaction = React.useCallback(() => {
    const voucherPayments = payments.filter((p) => p.payment_id === -5);
    if (voucherPayments && voucherPayments.length > 0 && !isOnline) return true;
    if (!listPayments.length) return true;
    if (isLoadingOrder) return true;
    if (!settings.allow_paylater && duePayment < 0) return true;
    return false;
  }, [listPayments, isLoadingOrder, settings, duePayment, isOnline]);

  // prevent user back to page
  React.useEffect(() => {
    window.history.pushState(null, document.title, document.URL);
    window.addEventListener('popstate', function () {
      window.history.pushState(null, document.title, document.URL);
      dispatch(togglePopup(true));
    });
  }, []);

  React.useEffect(() => {
    if (listItemCart.length === 0 || !isCheckout) {
      navigate('/sales');
    }
  }, [listItemCart, isCheckout]);

  React.useEffect(() => {
    const qrisDynamic = orderReducer.payments
      ? orderReducer.payments.find((payment) => payment.payment_id === config.QRIS_DYNAMIC)
      : null;
    if (qrisDynamic) {
      finishTransaction(orderReducer, duePayment);
    }
  }, [orderReducer.payments, duePayment]);

  React.useEffect(() => {
    if (isRecalculate) recalculateOrder();
  }, [isRecalculate, sales]);

  React.useEffect(() => {
    if (printMethod === 'usb') {
      getDevices();
    }
  }, [printMethod]);

  return (
    <CardMain>
      {sales.loadingSendOrder && <LoadingCashier />}
      <Box width={{ base: '55%', lg: '60%' }} position='relative' display='block'>
        <div className='flex items-center border-b border-gray-200 p-4 font-semibold'>
          {location?.location_name} - {registerInfo?.register_name}
        </div>
        <div className='flex max-h-[calc(100vh_-_205px)] flex-1 flex-col space-y-3 overflow-y-auto p-8'>
          <BoxTotal
            subtitle={'Total yang harus dibayarkan'}
            grandTotal={orderReducer.grand_total || 0}
            duePayment={duePayment}
            listPayments={listPayments}
            listItemCart={listItemCart}
            isReturn={false}
            continuePayment={continuePayment}
            remainingPayment={remainingPayment}
          />

          {/* List selected payments */}
          <BoxPayments>
            {listPayments.map((payment, index) => (
              <ListPayments key={index} payments={payment} onDelete={() => handleDeletePayment(index)} />
            ))}
          </BoxPayments>

          {/* List type payments method */}
          <div className='grid grid-cols-4 gap-3'>
            {LIST_PAYMENT_TYPE.map((item, index) => (
              <Button
                key={index}
                variant='payments'
                size={isLargeScreen ? 'lg' : 'md'}
                onClick={() => choosePayment(item.payment_type)}
                isDisabled={
                  disablePayment ||
                  (item.payment_type === PAYMENT_TYPE.QRIS && listPayments.length > 0) ||
                  (!isOnline &&
                    item.payment_type === PAYMENT_TYPE.QRIS &&
                    !listAllPaymentMethod?.find((p) => p.payment_id === config.QRIS_STATIC))
                }
              >
                {item.name}
              </Button>
            ))}
          </div>

          {/* List suggested payment money */}
          <SimpleGrid columns={generateColumns} gap={3}>
            <>
              <Button
                variant='payments'
                size={isLargeScreen ? 'lg' : 'md'}
                onClick={choosePaymentSuggestSame}
                isDisabled={disablePayment}
              >
                Sama
              </Button>
              {suggestMoneyMemoize.map((value, index) => (
                <Button
                  key={index}
                  variant='payments'
                  size={isLargeScreen ? 'lg' : 'md'}
                  onClick={() => submitPaymentCash(value)}
                  isDisabled={disablePayment}
                >
                  {currencyFormat(value)}
                </Button>
              ))}
            </>
          </SimpleGrid>

          {/* Other payment method */}
          {customerInfo && Number(customerInfo.store_credit) !== 0 && (
            <div className='flex h-full w-full'>
              <Button
                variant='payments'
                size='lg'
                width='full'
                isDisabled={disableStoreKredit()}
                onClick={() => choosePayment(PAYMENT_TYPE.STORE_CREDIT)}
              >
                Store Kredit {customerInfo && `- ${currencyFormat(Number(customerInfo?.store_credit))}`}
              </Button>
            </div>
          )}

          <BoxEmail
            sendStructByEmail={sendStructByEmail}
            isShippingActive={posIsShipping}
            isReturn={false}
            email={email}
            address={posIsShipping ? orderReducer.shipping?.shipping_address || '' : ''}
            setSendStructByEmail={setSendStructByEmail}
            setPosIsShipping={setPosIsShipping}
            setContactToOrder={setContactToOrder}
            customer={orderReducer.customer ?? null}
            setEmail={setEmail}
            totalDetail={totalDetails}
          />

          {(listPromotionAmount.length > 0 || listPromotionItem.length > 0) && (
            <BoxPromotion listPromotionAmount={listPromotionAmount} listPromotionItem={listPromotionItem} />
          )}
        </div>
        <div className='absolute bottom-0 right-0 flex w-full space-x-2 bg-white p-4'>
          <IconButton
            variant='outline'
            aria-label='save-icon'
            icon={<SaveIcon fill='jubelio.grey200' />}
            isDisabled={continuePayment}
            onClick={onOpenCart}
          />
          <Button
            isDisabled={disableFinishTransaction()}
            variant='primary'
            size='md'
            w='full'
            loadingText='Sending...'
            isLoading={isLoadingOrder}
            onClick={() => finishTransaction(orderReducer, duePayment)}
          >
            Selesaikan Transaksi
          </Button>
        </div>
      </Box>

      <Box w={{ base: '45%', lg: '40%' }} position='relative'>
        {!continuePayment ? (
          <CardDetail />
        ) : (
          <DetailHistory detailOrder={detailOrderHistory as DetailOrderTransaction} />
        )}
      </Box>

      {/* Popup Payment Method */}
      <ModalPayment
        isOpen={isOpenPayment}
        onClose={onClosePayment}
        paymentsMethod={payments}
        onSave={submitPayment}
        duePayments={duePayment}
        listPayments={listPayments}
        isReturn={false}
        continuePayment={continuePayment}
        remainingPayment={remainingPayment}
      />

      <ModalPaymentCash
        isOpen={isOpenCash}
        onClose={onCloseCash}
        duePayments={duePayment}
        grand_total={orderReducer.grand_total || 0}
        listPayments={listPayments}
        onSubmit={submitPaymentCash}
        continuePayment={continuePayment}
        remainingPayment={remainingPayment}
      />

      <ModalPaymentQris
        isOpen={isOpenQris}
        onClose={onCloseQris}
        duePayments={duePayment}
        paymentsMethod={payments}
        listPayments={listPayments}
        onSubmit={handlePayment}
        isReturn={false}
        continuePayment={continuePayment}
        remainingPayment={remainingPayment}
        printQrisDynamic={handleReactToPrintQRISDynamic}
      />

      {/* Popup success */}
      <ModalNotif
        isOpen={isOpenNotif}
        onClose={closeNotification}
        title='Transaksi Berhasil'
        text={`Transaksi order #${orderReducer.salesorder_no} telah berhasil`}
        showButton={false}
      />

      {/* Popup success Down Payment */}
      <ModalNotif
        isOpen={isOpenNotifDownPayment}
        onClose={() => backToHistorypage(false)}
        title='DP Berhasil'
        text={`Anda dapat menyelesaikan pembayaran pada menu History Transaksi > Belum Lunas`}
        showButton={true}
        textButton='Menuju ke History Transaksi'
        onClickButton={() => backToHistorypage(true)}
      />

      <ModalCart
        isOpen={isOpenCart}
        onClose={onCloseCart}
        totalOfAmount={storeTotalDetail?.grandTotal ?? 0}
      />

      <ModalConfirmation
        title={`Pembayaran kurang ${currencyFormat(Math.abs(duePayment))}`}
        isOpen={openConfirmation}
        onClose={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        subtitle={'Apakah Anda ingin melanjutkan sebagai DP?'}
        cancelText={modal.cancel_retur_text}
        okText={'Ya, Bayar DP'}
        onSubmit={onSubmitPopupDP}
        loadingSubmit={loadingCheckoutDP}
        isOnline={true}
      />

      <ModalVoucher
        isOpen={isOpenVoucher}
        onClose={onCloseVoucher}
        onSubmit={submitVoucher}
        paymentsMethod={payments}
      />

      <ModalConfirmation
        title={modal.title_voucher_confirmation}
        isOpen={isOpenConfirmVoucher}
        onClose={onCloseConfirmVoucher}
        subtitle={modal.subtitle_voucher_confirmation}
        cancelText={modal.cancel_voucher_text}
        okText={modal.ok_voucher_text}
        onSubmit={submitConfirmationVoucher}
        loadingSubmit={false}
        isOnline={isOnline}
      />

      <ModalConfirmationRounding
        title={modal.title_payment_rounding}
        isOpen={isOpenConfirmRounding}
        onClose={onCloseConfirmRounding}
        subtitle={modal.change_payment_rounding}
        cancelText={modal.cancel_voucher_text}
        okText={modal.ok_rounding_text}
        onSubmit={submitConfirmationRounding}
        totalDetail={totalDetails}
        roundMoney={storeTotalDetail?.roundMoney ?? 0}
        includeRounding={includeRoundMoney}
      />

      <Box display='none'>{orderPrint && <ComponentToPrint ref={componentRef} />}</Box>

      <Box display='none'>
        {sales.qrisDynamic && <ComponentToPrintQRISDynamic ref={componentRefQRISDynamic} />}
      </Box>

      <ModalAuthDP
        isOpen={isOpenAuthDP}
        isLoading={loadingAuthDP}
        onClose={onCloseAuthDP}
        checkout={onSubmitAuthorizationDP}
      />

      <ModalConfirmationPromotion
        title={messages.modal.title_validate_promotion}
        errorPayment={errorPaymentMethod}
        subtitle={
          errorPaymentMethod
            ? errorPromotion?.data
            : `${errorPromotion?.message}. ${messages.modal.subtitle_validate_promotion}`
        }
        cancelText={
          errorPaymentMethod
            ? messages.modal.cancel_text_payment_promotion
            : messages.modal.complete_text_requirement_promotion
        }
        okText={messages.modal.ok_continue_order_text}
        isOpen={isOpenConfirmPromotion}
        isOnline
        onSubmit={() => removePromotion(errorPromotion?.promotion_id ?? 0)}
        onClose={closePopupConfirmPromotion}
      />
    </CardMain>
  );
};

export default CheckoutPage;
