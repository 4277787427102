import { Image } from '@chakra-ui/react';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

interface Props {
  icons: string;
  title: string;
  subtitle: string;
}

const EmptySearch: React.FC<Props> = ({ icons, title, subtitle }: Props) => {
  return (
    <div className='flex h-full flex-col items-center justify-center py-5'>
      <Image src={icons} boxSize='150px' display='inline-block' />
      <Title mt={2}>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </div>
  );
};

export default EmptySearch;
