import { Box, Button, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { Buffer } from 'buffer';
import { CardMain } from 'components/card';
import { ModalNotif } from 'components/modal';
import ModalConfirmation from 'components/modal/ModalConfirmation';
import ComponentToPrint from 'components/receipt/ReceiptToPrint';
import LoadingCashier from 'components/state/LoadingCashier';
import CardDetail from 'components/ui/cart';
import { BoxEmail, BoxTotal, ListPayments } from 'components/ui/checkout';
import BoxPayments from 'components/ui/checkout/BoxPayments';
import config, { LIST_PAYMENT_TYPE, PAYMENT_TYPE } from 'constant';
import { modal } from 'constant/messages';
import { useNotification, usePayments } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useReturn from 'hooks/useReturn';
import { DeepLinker } from 'lib/deeplinker';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { logoutAction, setIsLogout } from 'redux/reducer/auth';
import {
  clearCartReturn,
  deletePaymentReturn,
  setCustomerEmailOrderReturn,
  setPaymentMethodReturn,
  setSendStructByEmailReturn,
  togglePopupReturn,
} from 'redux/reducer/return';
import { setStructData } from 'redux/reducer/settings';
import authAction from 'services/http/auth.request';
import commonRequest from 'services/http/common.request';
import { DetailOrderTransaction } from 'types';
import { IPayments } from 'types/common.types';
import { IOrderReturn } from 'types/return.types';
import { debounce } from 'utils';
import { rawCommand } from 'utils/printer/transaction-command';
import { isMobile } from 'utils/user-agent';

const CheckoutPage = () => {
  const [isLoadingOrder, setIsLoadingOrder] = React.useState<boolean>(false);
  const [orderPrint, setOrderPrint] = React.useState<DetailOrderTransaction | null>(null);
  const [lockScreen, setLockScreen] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const componentRef = React.useRef(null);
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');

  const MAX_UNIQUE_PAYMENT_TYPE = 1;

  // custom hooks
  const navigate = useNavigate();
  const { getListPayments } = usePayments();
  const dispatch = useAppDispatch();
  const { notification } = useNotification();
  const { sendOrderReturn } = useReturn();
  const { isOpen: isOpenNotif, onOpen: onOpenNotif, onClose: onCloseNotif } = useDisclosure();

  const { orderReturn, duePayment, listPayments, sendStructByEmail, customer } = useAppSelector(
    (state) => state.return
  );
  const listItemReturn = useAppSelector((state) => state.return.listItemReturn);
  const isOpenPopup = useAppSelector((state) => state.return.isOpenPopup);
  const settings = useAppSelector((state) => state.commons.settings);
  const structSetting = useAppSelector((state) => state.commons.structSetting);
  const printer = useAppSelector((state) => state.commons.printer);
  const profile = useAppSelector((state) => state.auth.profile);
  const location = useAppSelector((state) => state.register.location);
  const isLogout = useAppSelector((state) => state.auth.isLogout);
  const isOnline = useAppSelector((state) => state.register.isOnline);
  const registerInfo = useAppSelector((state) => state.register.registerInfo);

  const disablePayment = React.useMemo(() => {
    return duePayment === 0 && listPayments.length > 0;
  }, [duePayment, listPayments]);

  /**
   * @description: choose type payment method
   * and show popup payment method
   */
  const choosePayment = React.useCallback(
    debounce(async (paymentType: number) => {
      if (disablePayment) return;

      // prevent race condition when user click on same payment type
      if (listPayments.length >= MAX_UNIQUE_PAYMENT_TYPE) return;

      const listPaymentMethod = await getListPayments(paymentType);
      // Check if we already have this payment type
      const existingPayment = listPayments.find((p) => p.payment_type === paymentType);
      if (existingPayment) {
        console.log('Payment type already exists'); // For debugging
        return;
      }

      const payload: IPayments & { is_store_credit: boolean } = {
        ...listPaymentMethod[0],
        payment_amount: orderReturn.grand_total ?? 0,
        is_store_credit: paymentType === PAYMENT_TYPE.STORE_CREDIT,
      };

      dispatch(setPaymentMethodReturn(payload));
    }, 200),
    [disablePayment, listPayments, orderReturn.grand_total]
  );

  const handleDeletePayment = (indexPayment: number) => {
    dispatch(deletePaymentReturn(indexPayment));
  };

  //  Trigger this function just user using browser other than chrome
  const handleReactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleOnBeforeGetContent(orderPrint),
    onAfterPrint: () => {
      onOpenNotif();
      dispatch(setStructData(null));
      setIsLoadingOrder(false);
    },
  });

  const sendDataPrint = (data: Uint8Array, order: DetailOrderTransaction) => {
    const socket = new WebSocket(config.WS_CONNECTION);
    socket.onopen = () => socket.send(data);
    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response && response.message === 'error') setOrderPrint(order);
      if (response && response.message === 'success') onOpenNotif();
    };
    // if socket is closed or not connected Show print dialog
    socket.onclose = () => {
      setOrderPrint(order);
      socket.close();
    };
  };

  const checkAuthorizedUser = async (orderPayload: IOrderReturn) => {
    try {
      const payload = {
        user_id: orderPayload.authorized_user_id ?? 0,
        permission_id: config.ACL_AUTH_RETURN,
        pin: orderPayload.authorized_user_pin ?? '',
        location_id: location?.location_id ?? 0,
      };
      await commonRequest.checkAuthorizedUser(payload);
      return '';
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      if (error instanceof AxiosError) message = error.response?.data.code;
      setLockScreen(false);
      setIsLoadingOrder(false);
      return message;
    }
  };

  const handleSendTransaction = async (orderPayload: IOrderReturn) => {
    try {
      setLockScreen(true);
      setIsLoadingOrder(true);

      if (isOnline && settings.authorization_return) {
        const validateAuthorizedUser = await checkAuthorizedUser(orderPayload);
        if (validateAuthorizedUser !== '') {
          return notification('', validateAuthorizedUser, 'warning', 5000);
        }
      }

      const resOrder = await sendOrderReturn(orderPayload);
      resOrder.cashier_name = profile?.user.full_name;

      const data = await rawCommand({
        orderData: resOrder,
        settingPrint: structSetting,
        location,
        profile,
        posSetting: settings,
        isReturn: true,
        paperSize: Number(printer?.paperSize),
        printCopy: Number(printer?.printCopy),
      });

      if (isMobile()) {
        const linker = DeepLinker({
          onIgnored: function () {
            setOrderPrint(resOrder);
          },
          onFallback: function () {
            onOpenNotif();
          },
        });
        linker.openUrl(`${config.POS_APP_PRINT}/${Buffer.from(data).toString('base64')}`);
      } else if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
        setOrderPrint(resOrder);
      } else {
        sendDataPrint(data, resOrder);
      }

      setIsLoadingOrder(false);
    } catch (error: any) {
      setLockScreen(false);
      setIsLoadingOrder(false);
    }
  };

  /**
   * close notification to redirect to sales page and reset orderReturn data;
   **/
  const closeNotification = () => {
    onCloseNotif();
    dispatch(clearCartReturn());
    navigate('/sales');
  };

  // Dsipatch order detail to redux before dialog print opened to prevent data not updated
  // NOTE: Sometime order detail not updated, so we need to dispatch order detail to redux before print
  const handleOnBeforeGetContent = (printPayload: DetailOrderTransaction | null) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        dispatch(setStructData(printPayload));
        resolve();
      }, 200);
    });
  };

  const submitConfirmation = async () => {
    dispatch(togglePopupReturn(false));
    dispatch(clearCartReturn());
    if (isLogout) {
      await authAction.logout();
      dispatch(logoutAction());
      window.location.href = '/';
    } else {
      navigate('/sales');
    }
  };

  const cancelChangeTransaction = () => {
    dispatch(togglePopupReturn(false));
    dispatch(setIsLogout(false));
  };

  React.useEffect(() => {
    if (orderPrint !== null) {
      handleReactToPrint();
    }
  }, [orderPrint]);

  React.useEffect(() => {
    if (orderReturn) {
      setEmail(orderReturn?.customer_email ?? '');
    }
  }, [orderReturn]);

  React.useEffect(() => {
    if (listItemReturn && listItemReturn.length === 0) {
      dispatch(clearCartReturn());
      navigate('/sales');
    }
  }, []);

  return (
    <CardMain>
      {lockScreen && <LoadingCashier />}
      <Box width={{ base: '55%', lg: '60%' }} position='relative' display='block'>
        <div className='flex items-center border-b border-gray-200 p-4 font-semibold'>
          {location?.location_name} - {registerInfo?.register_name}
        </div>
        <div className='flex max-h-[calc(100vh_-_205px)] flex-1 flex-col space-y-3 overflow-y-auto p-8'>
          <BoxTotal
            subtitle={'Total yang harus dikembalikan'}
            grandTotal={orderReturn.grand_total ?? 0}
            duePayment={duePayment ?? 0}
            listPayments={listPayments}
            listItemCart={[]}
            isReturn={true}
          />

          {/* List selected payments */}
          <BoxPayments>
            {listPayments.map((payment, index) => (
              <ListPayments
                key={payment.payment_id}
                payments={payment}
                onDelete={() => handleDeletePayment(index)}
              />
            ))}
          </BoxPayments>

          {/* List type payments method */}
          <div className='grid grid-cols-2 gap-3'>
            {settings.allow_payment_return &&
              LIST_PAYMENT_TYPE.map(
                (item) =>
                  item.payment_type === PAYMENT_TYPE.CASH && (
                    <Button
                      key={item.payment_type}
                      variant='payments'
                      size={isLargeScreen ? 'lg' : 'md'}
                      onClick={() => choosePayment(item.payment_type)}
                      isDisabled={disablePayment}
                    >
                      {item.name}
                    </Button>
                  )
              )}
            <Button
              variant='payments'
              size={isLargeScreen ? 'lg' : 'md'}
              onClick={() => choosePayment(PAYMENT_TYPE.STORE_CREDIT)}
              isDisabled={disablePayment}
            >
              Store Kredit
            </Button>
          </div>
          <BoxEmail
            sendStructByEmail={sendStructByEmail}
            isShippingActive={false}
            isReturn={true}
            email={email}
            setSendStructByEmail={setSendStructByEmailReturn}
            setContactToOrder={setCustomerEmailOrderReturn}
            customer={customer ?? null}
            setEmail={setEmail}
          />
          <div className='absolute bottom-0 left-0 right-0 flex w-full space-x-2 bg-white p-4'>
            <Button
              isDisabled={!disablePayment || isLoadingOrder}
              variant='primary'
              size='md'
              w='full'
              loadingText='Sending...'
              isLoading={isLoadingOrder}
              onClick={() => handleSendTransaction(orderReturn)}
            >
              Selesaikan Transaksi
            </Button>
          </div>
        </div>
      </Box>
      <Box w={{ base: '45%', lg: '40%' }} position='relative'>
        <CardDetail />
      </Box>
      {/* Popup success */}
      <ModalNotif
        isOpen={isOpenNotif}
        onClose={closeNotification}
        title='Pengembalian Berhasil'
        text={`Transaksi order #${orderReturn.pos_return_no} telah berhasil`}
        showButton={false}
      />
      <ModalConfirmation
        title={modal.title_cancel_retur}
        subtitle={modal.subtitle_cancel_retur}
        cancelText={modal.cancel_retur_text}
        okText={modal.ok_retur_text}
        isOpen={isOpenPopup}
        isOnline
        onSubmit={submitConfirmation}
        onClose={cancelChangeTransaction}
      />
      <Box display='none'>{orderPrint && <ComponentToPrint ref={componentRef} />}</Box>
    </CardMain>
  );
};

export default CheckoutPage;
