import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

interface ListTotalProps extends FlexProps {
  leftText: React.ReactNode;
  rightText: React.ReactNode;
}

const ListTotal: React.FC<ListTotalProps> = (props: ListTotalProps) => {
  const { leftText, rightText, ...rest } = props;
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      fontSize={['12px', '11px', '12px', '13px', '14px']}
      w='full'
      {...rest}
    >
      {leftText}
      {rightText}
    </Flex>
  );
};

export default ListTotal;
