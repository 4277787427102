import { IPayments } from 'types/common.types';

import { db } from './connection';

export default {
  /**
   * Bulk add payment methods into indexdb
   *
   * @param {IPayments[]} payments
   * @returns {Promise<number>} last index
   * @memberof PaymentMethod
   * @example PaymentMethod.bulkAdd(payments)
   */
  bulkdAdd: async (paymentMethods: IPayments[]): Promise<number> => {
    return db.paymentmethod.bulkAdd(paymentMethods);
  },

  /**
   * Get all payment methods from indexdb
   * @returns {Promise<IPayments[]>} payments
   * @memberof PaymentMethod
   * @example PaymentMethod.get()
   */
  get: async (): Promise<IPayments[]> => {
    return db.paymentmethod.toArray();
  },
  filter: async (query: string | number, column: string): Promise<IPayments[]> => {
    return db.paymentmethod.where(column).equals(query).toArray();
  },
  clear: async (): Promise<void> => {
    return db.paymentmethod.clear();
  },
};
