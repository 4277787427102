import BopisPage from 'pages/bopis';
import CheckoutPage from 'pages/checkout';
import CheckoutReturnPage from 'pages/checkout/return';
import ClosurePage from 'pages/closures';
import HistoryPage from 'pages/history';
import ReturnPage from 'pages/return';
import SalesPage from 'pages/sales';

/**
 * @description List route for the application.
 */
const privateRoute = [
  {
    path: '',
    component: SalesPage,
    auth: true,
  },
  {
    path: 'closure',
    component: ClosurePage,
    auth: true,
  },
  {
    path: 'checkout',
    component: CheckoutPage,
    auth: true,
  },
  {
    path: 'return-checkout',
    component: CheckoutReturnPage,
    auth: true,
  },
  {
    path: 'history',
    component: HistoryPage,
    auth: true,
  },
  {
    path: 'return',
    component: ReturnPage,
    auth: true,
  },
  {
    path: 'marketplace',
    component: BopisPage,
    auth: true,
  },
];

export default privateRoute;
