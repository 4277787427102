import { Button, Divider, FlexProps, Select, Text, useMediaQuery } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import ReactPaginate from 'react-paginate';

type PaginationProps = FlexProps & {
  onChangePage?: (event: { selected: number }) => void;
  onChangeRow: (event: any) => void;
  totalPage?: number;
  pageSize: number;
  pageRange?: number;
  refPage?: React.Ref<any>;
  page?: number;
  withTotal?: boolean;
  useCursor?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
  onNextCursor?: () => void;
  onPrevCursor?: () => void;
};

const Pagination: React.FC<PaginationProps> = ({
  onChangePage,
  totalPage,
  onChangeRow,
  pageSize,
  pageRange = 2,
  refPage,
  page,
  withTotal = true,
  useCursor = false,
  hasNext,
  hasPrev,
  onNextCursor,
  onPrevCursor,
}: PaginationProps) => {
  const [isLargeScreen] = useMediaQuery('(min-width: 978px)');
  const start = Number(totalPage) > 0 ? (Number(page) - 1) * pageSize + 1 : 0;
  const end = Number(page) * Number(pageSize);
  return (
    <div className='font-size-14px bottom-0 left-0 flex w-full items-center justify-between bg-white'>
      <div className='flex items-center justify-between space-x-2'>
        {withTotal && (
          <div className='flex space-x-3'>
            {isLargeScreen && (
              <>
                <Text>
                  {start} - {totalPage && totalPage >= end ? end : totalPage} of {totalPage || 0}
                </Text>
                <Divider orientation='vertical' h='25px' bg='jubelio.grey300' />
              </>
            )}

            <Text>Baris</Text>
          </div>
        )}

        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeRow(e.target.value)}
          w='auto'
          fontSize={isLargeScreen ? '14px' : '12px'}
          size={isLargeScreen ? 'md' : 'sm'}
          value={pageSize}
          rounded='md'
        >
          <option value='5'>5</option>
          <option value='15'>15</option>
          <option value='25'>25</option>
          <option value='50'>50</option>
          <option value='100'>100</option>
        </Select>
      </div>
      {useCursor && (
        <div className='flex items-center justify-center gap-2 px-4 py-2'>
          <Button
            variant='outline'
            size='sm'
            onClick={() => onPrevCursor?.()}
            isDisabled={!hasPrev}
            className={clsx(!hasPrev && 'cursor-not-allowed opacity-50')}
          >
            <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M6.82992 14.9993C6.68052 14.9998 6.53291 14.9668 6.39794 14.9028C6.26296 14.8388 6.14404 14.7453 6.04992 14.6293L1.21992 8.6293C1.07284 8.45037 0.992432 8.22592 0.992432 7.9943C0.992432 7.76267 1.07284 7.53823 1.21992 7.3593L6.21992 1.3593C6.38966 1.15508 6.63357 1.02666 6.898 1.00228C7.16242 0.977899 7.4257 1.05956 7.62992 1.2293C7.83414 1.39904 7.96256 1.64295 7.98694 1.90738C8.01132 2.1718 7.92966 2.43508 7.75992 2.6393L3.28992 7.9993L7.60992 13.3593C7.7322 13.5061 7.80988 13.6848 7.83376 13.8744C7.85764 14.0639 7.82672 14.2563 7.74467 14.4289C7.66261 14.6014 7.53285 14.7468 7.37074 14.8479C7.20863 14.949 7.02095 15.0015 6.82992 14.9993Z'
                fill='#7F8084'
              />
            </svg>
          </Button>
          <Button
            variant='outline'
            size='sm'
            onClick={() => onNextCursor?.()}
            isDisabled={!hasNext}
            className={clsx(!hasNext && 'cursor-not-allowed opacity-50')}
          >
            <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.16984 14.9993C1.31923 14.9998 1.46684 14.9668 1.60182 14.9028C1.7368 14.8388 1.85572 14.7453 1.94984 14.6293L6.77984 8.6293C6.92692 8.45037 7.00732 8.22592 7.00732 7.9943C7.00732 7.76267 6.92692 7.53823 6.77984 7.3593L1.77984 1.3593C1.6101 1.15508 1.36619 1.02666 1.10176 1.00228C0.837334 0.977899 0.574053 1.05956 0.369836 1.2293C0.16562 1.39904 0.0371957 1.64295 0.012816 1.90738C-0.0115638 2.1718 0.0700977 2.43508 0.239836 2.6393L4.70984 7.9993L0.389837 13.3593C0.267554 13.5061 0.189877 13.6848 0.165997 13.8744C0.142118 14.0639 0.173034 14.2563 0.25509 14.4289C0.337146 14.6014 0.466906 14.7468 0.629016 14.8479C0.791127 14.949 0.978803 15.0015 1.16984 14.9993Z'
                fill='#7F8084'
              />
            </svg>
          </Button>
        </div>
      )}
      {!useCursor && (
        <ReactPaginate
          pageCount={!totalPage ? 0 : Math.ceil(totalPage / pageSize)}
          ref={refPage}
          onPageChange={onChangePage}
          forcePage={page && page - 1}
          nextLinkClassName={'pages-link'}
          previousLinkClassName={'pages-link'}
          pageClassName={'page-items'}
          pageLinkClassName={'pages-number'}
          breakClassName={'pages-link'}
          activeClassName={'pages-active'}
          activeLinkClassName={'pages-active-number'}
          breakLinkClassName={'page-items'}
          containerClassName={'pagination'}
          previousLabel={
            <Button variant='outline' size='sm'>
              <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.82992 14.9993C6.68052 14.9998 6.53291 14.9668 6.39794 14.9028C6.26296 14.8388 6.14404 14.7453 6.04992 14.6293L1.21992 8.6293C1.07284 8.45037 0.992432 8.22592 0.992432 7.9943C0.992432 7.76267 1.07284 7.53823 1.21992 7.3593L6.21992 1.3593C6.38966 1.15508 6.63357 1.02666 6.898 1.00228C7.16242 0.977899 7.4257 1.05956 7.62992 1.2293C7.83414 1.39904 7.96256 1.64295 7.98694 1.90738C8.01132 2.1718 7.92966 2.43508 7.75992 2.6393L3.28992 7.9993L7.60992 13.3593C7.7322 13.5061 7.80988 13.6848 7.83376 13.8744C7.85764 14.0639 7.82672 14.2563 7.74467 14.4289C7.66261 14.6014 7.53285 14.7468 7.37074 14.8479C7.20863 14.949 7.02095 15.0015 6.82992 14.9993Z'
                  fill='#7F8084'
                />
              </svg>
            </Button>
          }
          nextLabel={
            <Button variant='outline' size='sm'>
              <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M1.16984 14.9993C1.31923 14.9998 1.46684 14.9668 1.60182 14.9028C1.7368 14.8388 1.85572 14.7453 1.94984 14.6293L6.77984 8.6293C6.92692 8.45037 7.00732 8.22592 7.00732 7.9943C7.00732 7.76267 6.92692 7.53823 6.77984 7.3593L1.77984 1.3593C1.6101 1.15508 1.36619 1.02666 1.10176 1.00228C0.837334 0.977899 0.574053 1.05956 0.369836 1.2293C0.16562 1.39904 0.0371957 1.64295 0.012816 1.90738C-0.0115638 2.1718 0.0700977 2.43508 0.239836 2.6393L4.70984 7.9993L0.389837 13.3593C0.267554 13.5061 0.189877 13.6848 0.165997 13.8744C0.142118 14.0639 0.173034 14.2563 0.25509 14.4289C0.337146 14.6014 0.466906 14.7468 0.629016 14.8479C0.791127 14.949 0.978803 15.0015 1.16984 14.9993Z'
                  fill='#7F8084'
                />
              </svg>
            </Button>
          }
          breakLabel='...'
          marginPagesDisplayed={1}
          pageRangeDisplayed={pageRange}
        />
      )}
    </div>
  );
};

export default Pagination;
