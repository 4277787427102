import { Box, Button, Fade, HStack, Stack } from '@chakra-ui/react';
import { ExclamationIcon } from 'components/icons';
import { Modal } from 'components/modal';
import { SubTitle, Title } from 'components/typography';
import React from 'react';

interface ModalConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  cancelText: string;
  okText: string;
  onSubmit?: () => void;
  loadingSubmit?: boolean;
  isOnline?: boolean;
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = (props: ModalConfirmationProps) => {
  const { isOpen, onClose, title, subtitle, cancelText, okText, onSubmit, loadingSubmit } = props;

  return (
    <Modal isShow={isOpen} onClose={onClose} disableBackrop>
      <Modal.Body className='py-8'>
        <Stack direction={['column']}>
          <Box textAlign='center'>
            <Fade in={true}>
              <ExclamationIcon h='46px' w='46px' />
              <Title mt={4}>{title}</Title>
              <SubTitle>{subtitle}</SubTitle>
            </Fade>
          </Box>
        </Stack>
        <HStack mt={5} justifyContent='center' spacing={5}>
          <Button
            variant='outline'
            size='md'
            style={{ fontSize: 14 }}
            w='30%'
            onClick={onClose}
            isDisabled={loadingSubmit}
          >
            {cancelText}
          </Button>
          <Button
            variant='primary'
            size='md'
            w='30%'
            onClick={onSubmit}
            isLoading={loadingSubmit}
            isDisabled={loadingSubmit}
          >
            {okText}
          </Button>
        </HStack>
      </Modal.Body>
    </Modal>
  );
};

export default ModalConfirmation;
