import { ITEM_ID_TYPES, ITEM_TYPES } from 'constant';
import { IItemCart } from 'types/products.types';
import { IPromotions, TransactionPromotion } from 'types/promotion.types';

/**
 * Check if the promotion is valid for the transaction
 *
 * TODO: Refactoring this function to make it more readable and simple
 * @param promotion
 * @param itemCart
 * @returns
 */
function checkBuyX(promotion: IPromotions, itemCart: IItemCart[]) {
  const checkItems = promotion.items.filter((item) => item.item_type === ITEM_TYPES.BUY_X);
  const checkLists: any = {};
  for (const item of checkItems) {
    const ckey = `${item.id_type}_${item.item_id}`;
    checkLists[ckey] = { current: 0, expected: item.qty, items: [] };
  }

  for (const item of itemCart) {
    const pkey = `${ITEM_ID_TYPES.Product}_${item.item_group_id}`;
    const skey = `${ITEM_ID_TYPES.SKU}_${item.item_id}`;
    const ckey = `${ITEM_ID_TYPES.Category}_${item.item_category_id}`;
    if (checkLists[pkey]) {
      checkLists[pkey]['current'] += item.quantity;
      checkLists[pkey]['items'].push(item);
    }
    if (checkLists[skey]) {
      checkLists[skey]['current'] += item.quantity;
      checkLists[skey]['items'].push(item);
    }
    if (checkLists[ckey]) {
      checkLists[ckey]['current'] += item.quantity;
      checkLists[ckey]['items'].push(item);
    }
  }

  let multiplier = null;
  for (const k in checkLists) {
    // all check lists should be passed to apply promotion
    if (checkLists[k]['current'] < checkLists[k]['expected'])
      return { status: false, items: {}, multiplier: 1 };

    // get minimal multiplier of all check lists
    const m =
      checkLists[k]['expected'] > 0
        ? Number(checkLists[k]['current'] / checkLists[k]['expected'])
        : 1;
    multiplier = multiplier === null ? m : m < multiplier ? m : multiplier;
  }

  // 2020-11-05 update: no multiplier for Buy X Get Y
  return { status: true, checkLists, multiplier: 1 };
}

export const getFreeItems = (promotion: TransactionPromotion): any => {
  const freeItems = promotion.rules?.items.filter((item) => item.item_type === ITEM_TYPES.GET_Y);
  return freeItems.map((item) => ({
    id_type: item.id_type,
    item_id: item.item_id,
    qty: Number(promotion.multiplier) > 1 ? item.qty * Number(promotion.multiplier) : item.qty,
  }));
};

type FormatBuyXGetYRes = {
  promotion_id: number;
  detail: {
    type: string;
    items: Array<{
      item_id: number;
      qty: number;
    }>;
  };
};

export function formatBuyXGetY(promotion: TransactionPromotion): FormatBuyXGetYRes {
  const items = promotion.free_items?.map((item) => ({ item_id: item.item_id, qty: item.amount }));

  for (const prod of Object.values(promotion.free_products || {})) {
    if (prod.selectedItem !== null) {
      items?.push({ item_id: prod.selectedItem.item_id, qty: prod.selectedItem.amount });
    }
  }

  for (const cat of Object.values(promotion.free_categories || {})) {
    if (cat.selectedItem !== null) {
      items?.push({ item_id: cat.selectedItem.item_id, qty: cat.selectedItem.amount });
    }
  }

  return {
    promotion_id: promotion.rules.promotion_id,
    detail: {
      type: 'free_item',
      items: items || [],
    },
  };
}

export function validatePromoBuyXGetY(
  promotion: IPromotions,
  itemCart: IItemCart[]
): TransactionPromotion | null {
  const result = checkBuyX(promotion, itemCart);
  if (!result.status) return null;

  const p: TransactionPromotion = {
    rules: promotion,
    checkLists: result.checkLists,
    multiplier: result.multiplier,
  };

  p['freeItems'] = getFreeItems(p);
  p['free_items'] = [];
  p['free_categories'] = {};
  p['free_products'] = [];

  return p;
}
