import * as React from 'react';
import { Scalars } from 'types';

type CheckTimeRes = {
  currentHours: number;
};

const useCheckTime = (): CheckTimeRes => {
  const date = new Date();
  const [currentHours, setCurrentHours] = React.useState<Scalars['Date']>(date.getHours());

  React.useEffect(() => {
    setInterval(() => {
      const dateNow = new Date();
      if (dateNow.getHours() !== currentHours) {
        setCurrentHours(dateNow.getHours());
      }
    }, 5000);
  }, []);

  return { currentHours };
};
export default useCheckTime;
