import { StackProps, Td, Tr } from '@chakra-ui/react';
import { InputNumber } from 'components/forms';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import {
  decrementBatchNumberReturn,
  decrementSerialNumberReturn,
  incrementBatchNumberReturn,
  incrementSerialNumberReturn,
  updateQtyBatchNumberReturn,
  updateQtySerialNumberReturn,
  updateQuantityItemReturn,
} from 'redux/reducer/return';
import { Maybe } from 'types';
import { IBatchNumberReturn, ISerialNumberReturn } from 'types/return.types';
import { currencyFormat } from 'utils';

interface TableReturnSnBnState extends StackProps {
  title: string;
  listSn: Maybe<ISerialNumberReturn[]>;
  listBn: Maybe<IBatchNumberReturn[]>;
  sell_price: number;
  item_id: number;
}

const TableReturnSnBn: React.FC<TableReturnSnBnState> = (props: TableReturnSnBnState) => {
  const { title, listSn, listBn, sell_price, item_id } = props;
  const dispatch = useAppDispatch();
  const orderReturn = useAppSelector((state) => state.return.orderReturn);

  React.useEffect(() => {
    if (listBn) {
      const totalQty = listBn.reduce((sum, item) => {
        return sum + item.qty;
      }, 0);
      const itemUpdate = {
        item_id,
        quantity: -totalQty,
        discount_as_service_fee: orderReturn.service_fee && Number(orderReturn.service_fee) !== 0,
      };
      dispatch(updateQuantityItemReturn(itemUpdate));
    }
  }, [listBn]);

  React.useEffect(() => {
    if (listSn) {
      const totalQty = listSn.reduce((sum, item) => {
        return sum + item.qty;
      }, 0);
      const itemUpdate = {
        item_id,
        quantity: -totalQty,
        discount_as_service_fee: orderReturn.service_fee && Number(orderReturn.service_fee) !== 0,
      };
      dispatch(updateQuantityItemReturn(itemUpdate));
    }
  }, [listSn]);

  return (
    <>
      <Tr>
        <Td></Td>
        <Td
          borderRightColor='gray.200'
          borderRightWidth='1px'
          fontWeight='700'
          fontSize='12px'
          colSpan={3}
        >
          NO. {title}
        </Td>
        <Td borderRightColor='gray.200' borderRightWidth='1px' fontWeight='700' fontSize='12px'>
          QTY
        </Td>
        <Td borderRightColor='gray.200' borderRightWidth='1px'></Td>
        {/* <Td borderRightColor='gray.200' borderRightWidth='1px'></Td> */}
        <Td
          borderRightColor='gray.200'
          borderRightWidth='1px'
          fontWeight='700'
          fontSize='12px'
          textAlign='right'
          colSpan={2}
        >
          PENGEMBALIAN
        </Td>
      </Tr>
      {listBn &&
        listBn.length > 0 &&
        listBn.map((item: IBatchNumberReturn, index: number) => {
          return (
            <Tr key={`batchnumber-${index}`}>
              <Td></Td>
              <Td borderRightColor='gray.200' borderRightWidth='1px' fontSize='14px' colSpan={3}>
                {item.batch_no}
              </Td>
              <Td
                borderRightColor='gray.200'
                borderRightWidth='1px'
                fontSize='14px'
                textAlign='center'
              >
                {item.oldQty}
              </Td>
              <Td borderRightColor='gray.200' borderRightWidth='1px'>
                <InputNumber
                  value={Math.abs(item.qty)}
                  size='sm'
                  maxWidth='100%'
                  textAlign='center'
                  decrement={() => {
                    dispatch(
                      decrementBatchNumberReturn({
                        batch_no: item.batch_no,
                        item_id,
                      })
                    );
                  }}
                  increment={() => {
                    dispatch(
                      incrementBatchNumberReturn({
                        batch_no: item.batch_no,
                        item_id,
                      })
                    );
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateQtyBatchNumberReturn({
                        item_id,
                        batch_no: item.batch_no,
                        qty: Number(e.target.value),
                      })
                    );
                  }}
                />
              </Td>
              {/* <Td borderRightColor='gray.200' borderRightWidth='1px'></Td> */}
              <Td
                borderRightColor='gray.200'
                borderRightWidth='1px'
                fontSize='14px'
                textAlign='right'
                colSpan={2}
              >
                {currencyFormat(sell_price * item.qty)}
              </Td>
            </Tr>
          );
        })}

      {listSn &&
        listSn.length > 0 &&
        listSn.map((item: ISerialNumberReturn, index: number) => {
          return (
            <Tr key={`serialnumber-${index}`}>
              <Td></Td>
              <Td borderRightColor='gray.200' borderRightWidth='1px' fontSize='14px' colSpan={3}>
                {item.serial_no}
              </Td>
              <Td
                borderRightColor='gray.200'
                borderRightWidth='1px'
                fontSize='14px'
                textAlign='center'
              >
                {item.oldQty}
              </Td>
              <Td borderRightColor='gray.200' borderRightWidth='1px'>
                <InputNumber
                  value={Math.abs(item.qty)}
                  size='sm'
                  maxWidth='100%'
                  textAlign='center'
                  decrement={() => {
                    dispatch(
                      decrementSerialNumberReturn({
                        serial_no: item.serial_no,
                        item_id,
                      })
                    );
                  }}
                  increment={() => {
                    dispatch(
                      incrementSerialNumberReturn({
                        serial_no: item.serial_no,
                        item_id,
                      })
                    );
                  }}
                  onChange={(e) => {
                    dispatch(
                      updateQtySerialNumberReturn({
                        item_id,
                        serial_no: item.serial_no,
                        qty: Number(e.target.value),
                      })
                    );
                  }}
                />
              </Td>
              {/* <Td borderRightColor='gray.200' borderRightWidth='1px'></Td> */}
              <Td
                borderRightColor='gray.200'
                borderRightWidth='1px'
                fontSize='14px'
                textAlign='right'
                colSpan={2}
              >
                {currencyFormat(sell_price * item.qty)}
              </Td>
            </Tr>
          );
        })}
    </>
  );
};

export default React.memo(TableReturnSnBn);
