import { RepeatIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { SearchBarSales } from 'components/forms';
import React from 'react';

type HeaderSearchBarProps = {
  search: string;
  isLoadingProducts: boolean;
  isFetchingProducts: boolean;
  isBarcodeMode: boolean;
  scanItems: (e: string) => Promise<void>;
  searchItems: (search: string) => void;
  onSwitchBarcodeMode: (e: string) => void;
  onRefresh: () => void;
};

export default function HeaderSearchBar({
  scanItems,
  searchItems,
  isLoadingProducts,
  isFetchingProducts,
  isBarcodeMode,
  onSwitchBarcodeMode,
  onRefresh,
}: HeaderSearchBarProps) {
  return (
    <div className='mt-2 flex space-x-2 bg-white px-3 pb-3'>
      <SearchBarSales
        placeholder={isBarcodeMode ? 'Scan barcode' : 'Cari barang'}
        onSearch={(e) => searchItems(e.target.value)}
        onEnter={scanItems}
        onSwitchBarcodeMode={onSwitchBarcodeMode}
      />
      <IconButton
        variant='outline'
        aria-label='add-icon'
        isDisabled={isLoadingProducts || isFetchingProducts}
        isLoading={isLoadingProducts || isFetchingProducts}
        icon={<RepeatIcon />}
        onClick={onRefresh}
      />
    </div>
  );
}
