import { Box, Fade, Spinner, Stack } from '@chakra-ui/react';
import { CheckCircleIcon } from 'components/icons';
import Modal from 'components/modal/Modal';
import { ProgressItems } from 'components/state';
import { SubTitle, Title } from 'components/typography';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

interface ModalSyncProps {
  isOpen: boolean;
  onClose: () => void;
  isGetCustomer: boolean;
  isGetItem: boolean;
  isGetDiscount: boolean;
  isGetPricebook: boolean;
  isGetSerialNumber: boolean;
  isGetBatchNumber: boolean;
}

const ModalSync: React.FC<ModalSyncProps> = (props: ModalSyncProps) => {
  const { isOpen, onClose } = props;

  const {
    stepDownload,
    percentBatch,
    percentCustomer,
    percentSerial,
    percentDiscount,
    percentItem,
    percentPricebook,
    percentStock,
  } = useAppSelector((state) => state.register);

  return (
    <Modal isShow={isOpen} onClose={onClose} disableBackrop>
      <Modal.Body className='py-10'>
        <Stack direction={['column']}>
          <Box textAlign='center'>
            {stepDownload === '' && (
              <>
                <ProgressItems percent={percentDiscount} />
                <Box textAlign='center'>
                  <Title>Mendownload Diskon</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}
            {stepDownload === 'pricebook' && (
              <>
                <ProgressItems percent={percentPricebook} />
                <Box textAlign='center'>
                  <Title>Mendownload Buku Harga</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}
            {stepDownload === 'serialnumber' && (
              <>
                <ProgressItems percent={percentSerial} />
                <Box textAlign='center'>
                  <Title>Mendownload Serial Number</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}
            {stepDownload === 'batchnumber' && (
              <>
                <ProgressItems percent={percentBatch} />
                <Box textAlign='center'>
                  <Title>Mendownload Batch Number</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}
            {stepDownload === 'contact' && (
              <>
                <ProgressItems percent={percentCustomer} />
                <Box textAlign='center'>
                  <Title>Mendownload Customer</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}
            {stepDownload === 'item' && (
              <>
                <ProgressItems percent={percentItem} />
                <Box textAlign='center'>
                  <Title>Mendownload Barang</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}

            {stepDownload === 'stock' && (
              <>
                <ProgressItems percent={percentStock} />
                <Box textAlign='center'>
                  <Title>Mensinkronkan Stok Barang</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}

            {stepDownload === 'merged-item' && (
              <>
                <Spinner color='jubelio.primary' size='lg' />
                <Box textAlign='center'>
                  <Title>Menggabungkan Stok dengan Barang</Title>
                  <SubTitle>Harap tunggu. Proses membutuhkan beberapa waktu</SubTitle>
                </Box>
              </>
            )}

            {stepDownload === 'success' ? (
              <Fade in={true}>
                <CheckCircleIcon h='46px' w='46px' />
                <Title mt={4}>Download Berhasil!</Title>
                <SubTitle>Semua item berhasil didownload.</SubTitle>
              </Fade>
            ) : null}
          </Box>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ModalSync);
