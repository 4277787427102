/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const customTheme = {
  option: (provided: any) => ({
    ...provided,
    fontSize: '12px !important',
    zIndex: 999,
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: '12px !important',
    zIndex: 999,
  }),
  control: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    border: '1px solid #ECEFF1',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #ECEFF1',
    },
    'input:focus': {
      // Overwrittes the different states of border
      boxShadow: 'none !important',
      outline: 'none !important',
      border: '0px !important',
    },
  }),
};

export default customTheme;
