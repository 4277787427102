import React from 'react';
import { setInitSync } from 'redux/reducer/registers';
import batchnumber from 'services/indexdb/batch-number';
import { db } from 'services/indexdb/connection';
import { getPriceBookList } from 'services/indexdb/pricebook';
import products from 'services/indexdb/products';
import serialnumber from 'services/indexdb/serial-number';
import { IListStockV2, IProductList, IProductRoot } from 'types/products.types';

import { useAppDispatch, useAppSelector } from './redux';
import useCheckTime from './useCheckTime';

type useInitProductProps = {
  getAllProduct: (productList: IProductRoot<IProductList[]>) => Promise<void>;
  pickedItem: number;
  pickedStock: number;
  totalItem: number;
  isGetItem: boolean;
  setTotalItem: (totalItem: number) => void;
  setPickedItem: (pickedItem: number) => void;
  setPickedStock: (pickedStock: number) => void;
  getItemStock: (listStock: IListStockV2[]) => Promise<void>;
  mappingStockToItem: () => Promise<void>;
};

const useInitProducts = (): useInitProductProps => {
  const [pickedItem, setPickedItem] = React.useState<number>(0);
  const [totalItem, setTotalItem] = React.useState<number>(0);
  const [isGetItem, setIsGetItem] = React.useState<boolean>(true);
  const [pickedStock, setPickedStock] = React.useState<number>(0);
  const dispatch = useAppDispatch();

  const lastSynced = useAppSelector((state) => state.register.lastSynced);
  const { currentHours } = useCheckTime();
  const locationId = useAppSelector((state) => state.register.location?.location_id);

  const getAllProduct = async (productList: IProductRoot<IProductList[]>): Promise<void> => {
    try {
      setIsGetItem(true);
      if (productList.data.length > 0) {
        const pricebooks = await db.pricebook.toArray();
        const listProduct = await Promise.all(
          productList.data.map(async (items) => {
            const variants = await Promise.all(
              items.variants?.map(async (variant) => {
                const [pricebook, listSerialNumber, listBatchNumber] = await Promise.all([
                  getPriceBookList(pricebooks, variant, null, currentHours, locationId as number),
                  serialnumber.getByItemId(variant.item_id),
                  batchnumber.getByItemId(variant.item_id),
                ]);

                const itemIdKey = `item_${variant.item_id}`;
                const listPriceBookKey = `list_price_book_item_${variant.item_id}`;

                const variantData = {
                  ...variant,
                  available: 0,
                  list_serial_number: listSerialNumber,
                  list_batch_number: listBatchNumber,
                };

                if (pricebook !== undefined && pricebook[itemIdKey] === variant.item_id) {
                  return {
                    ...variantData,
                    normal_price: variant.sell_price,
                    list_price_book: pricebook[listPriceBookKey],
                  };
                } else {
                  return {
                    ...variantData,
                    list_price_book: [],
                  };
                }
              })
            );

            return {
              ...items,
              variants: variants,
            };
          })
        );

        if (lastSynced !== '') {
          await products.updateProductInformation(listProduct);
          return;
        }
        const uniqueProducts = await Promise.all(
          listProduct.filter(async (product) => {
            const existingProduct = await db.products
              .where('item_group_id')
              .equals(product.item_group_id)
              .first();
            return !existingProduct;
          })
        );

        await products.bulkAdd(uniqueProducts);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const mappingStockToItem = async (): Promise<void> => {
    console.log('[INFO] start mapping stock to item');
    const [listProduct, listStock] = await Promise.all([db.products.toArray(), db.stock.toArray()]);

    const totalProduct = await db.products.count();
    console.log('[INFO] Total product', totalProduct);
    const mergedProduct: IProductList[] = listProduct.map((product) => {
      const updatedVariants = product.variants.map((variant) => {
        const child = listStock.find(
          (stock) => stock.item_group_id === product.item_group_id && stock.item_id === variant.item_id
        );
        if (child) {
          return {
            ...variant,
            available: child.qty,
          };
        }

        return variant;
      });

      return {
        ...product,
        variants: updatedVariants,
      };
    });

    await db.products.bulkPut(mergedProduct);
    console.log('[INFO] Total product after mapping', await db.products.count());
    console.log('[INFO] finished mapping stock to item');
  };

  const getItemStock = async (listStocks: IListStockV2[]): Promise<void> => {
    await db.stock.bulkAdd(listStocks);
  };

  React.useEffect(() => {
    if (pickedItem !== 0 && pickedItem === 100) {
      setTimeout(() => {
        setIsGetItem(false);
      }, 1000);
    }
  }, [pickedItem]);

  React.useEffect(() => {
    if (pickedStock !== 0 && pickedStock === 100) {
      setTimeout(() => {
        dispatch(setInitSync(false));
      }, 1000);
    }
  }, [pickedStock]);

  return {
    getAllProduct,
    pickedItem,
    totalItem,
    isGetItem,
    setTotalItem,
    setPickedItem,
    getItemStock,
    pickedStock,
    setPickedStock,
    mappingStockToItem,
  };
};

export default useInitProducts;
