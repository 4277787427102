import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const PrintIcon: React.FC<IconProps> = (props: IconProps) => (
  <Icon viewBox='0 0 20 19' {...props}>
    <path
      d='M17 5.4267C18.66 5.4267 20 6.79521 20 8.49053V14.6182H16V18.7033H4V14.6182H0V8.49053C0 6.79521 1.34 5.4267 3 5.4267H4V0.320312H16V5.4267H17ZM6 2.36287V5.4267H14V2.36287H6ZM14 16.6607V12.5756H6V16.6607H14ZM16 12.5756H18V8.49053C18 7.92882 17.55 7.46925 17 7.46925H3C2.45 7.46925 2 7.92882 2 8.49053V12.5756H4V10.5331H16V12.5756ZM17 9.00116C17 9.56287 16.55 10.0224 16 10.0224C15.45 10.0224 15 9.56287 15 9.00116C15 8.43946 15.45 7.97989 16 7.97989C16.55 7.97989 17 8.43946 17 9.00116Z'
      fill={`${props.fill ? props.fill : '#8999A5'}`}
    />
  </Icon>
);

export default PrintIcon;
