import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const Title: React.FC<TextProps> = ({ children, ...rest }: TextProps) => (
  <Text fontWeight='bold' color='jubelio.black' fontSize={{ base: '12px', lg: '14px' }} {...rest}>
    {children}
  </Text>
);

export default Title;
