import { ICustomerInfo, PriceBook } from 'types/common.types';
import { IProductList, Variant } from 'types/products.types';
import { getPriceBooks } from 'utils/promotion';

import { db } from './connection';

/**
 * Get price book list for items
 */
export const getPriceBookList = async (
  populatePriceBook: PriceBook[],
  variant: Variant,
  customerInfo: ICustomerInfo | null,
  currentHours: number,
  locationId: number
): Promise<PriceBook> => {
  const resCustomer = await db.customer.where('contact_id').equals(-1).first();

  const listPriceBookItem: PriceBook = {} as PriceBook;
  const ik = `item_${variant.item_id}`;
  const price_book_id = `price_book_id_item_${variant.item_id}`;
  const list_price_book = `list_price_book_item_${variant.item_id}`;

  const itemPriceBook = populatePriceBook.filter(
    (pricebook: PriceBook) => pricebook.item_id === variant.item_id
  );

  const selectedCustomer = (customerInfo ?? resCustomer) as ICustomerInfo;
  if (itemPriceBook.length > 0) {
    const priceBookList = getPriceBooks(itemPriceBook, selectedCustomer, currentHours, locationId);

    listPriceBookItem[price_book_id] = itemPriceBook[0].price_book_id;
    listPriceBookItem[ik] = variant.item_id;
    listPriceBookItem[list_price_book] = priceBookList;
  }

  return listPriceBookItem;
};

export default {
  bulkAdd: async (data: PriceBook[]): Promise<number> => {
    return db.pricebook.bulkAdd(data);
  },
  /**
   * Update pricebook data and mapping to product list
   *
   * @param data - Pricebook data from API or Websockets
   * @param getPriceBookList - Func params to get pricebook from hooks
   */
  updatePricebook: async (
    data: PriceBook[],
    customer: ICustomerInfo | null,
    currentHours: number,
    locationId: number
  ): Promise<void> => {
    // Create a map for faster lookups
    const pricebookMap = new Map(data.map((pb) => [pb.item_group_id, pb]));

    // Get all products that need updating
    const productsToUpdate = await db.products
      .filter((product: IProductList) =>
        product.variants.some((variant: Variant) => variant.price_book || variant.list_price_book)
      )
      .toArray();

    // Prepare bulk update
    const updates: Array<{ item_group_id: number; variants: Variant[] }> = [];
    for (const product of productsToUpdate) {
      const pricebook = pricebookMap.get(product.item_group_id);
      if (pricebook) {
        const updatedVariants = await Promise.all(
          product.variants.map(async (variant: Variant) => {
            const newPricebook = await getPriceBookList(data, variant, customer, currentHours, locationId);
            if (newPricebook && variant.item_id === newPricebook[`item_${variant.item_id}`]) {
              return {
                ...variant,
                normal_price: variant.sell_price,
                price_book: newPricebook[`price_book_id_item_${variant.item_id}`],
                list_price_book: newPricebook[`list_price_book_item_${variant.item_id}`],
              };
            }
            return { ...variant, price_book: null, list_price_book: [] };
          })
        );
        updates.push({
          item_group_id: product.item_group_id,
          variants: updatedVariants,
        });
      }
    }
    // Perform bulk update
    await Promise.all(
      updates.map((update) => db.products.update(update.item_group_id, { variants: update.variants }))
    );
  },
};
