import { Box, Divider, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import TablePaymentDetail from 'components/table/TablePaymentDetail';
import { Title } from 'components/typography';
import React from 'react';
import { Maybe } from 'types';
import { IPaymentTrx } from 'types/closure.types';
import { currencyFormat } from 'utils';

import { Modal } from '.';

interface ModalDetailPaymentClosureProps {
  isOpen: boolean;
  onClose: () => void;
  dataPayment: Maybe<IPaymentTrx> | undefined;
}

const ModalDetailPaymentClosure: React.FC<ModalDetailPaymentClosureProps> = (
  props: ModalDetailPaymentClosureProps
) => {
  const { isOpen, onClose, dataPayment } = props;

  return (
    <Modal isShow={isOpen} onClose={onClose} title='Detail Pembayaran' className='max-w-xl'>
      <Modal.Body>
        <Divider my={4} size='lg' borderColor='system.outline' />
        <SimpleGrid columns={[3]} gap={2} justifyContent='center'>
          <VStack alignItems='flex-start' px='6'>
            <Text fontSize='14px' color='jubelio.black'>
              Penjualan
            </Text>
            <Title fontSize='16px'>
              {currencyFormat(dataPayment?.totalSales as unknown as number)}
            </Title>
          </VStack>
          <VStack alignItems='flex-start' px='6'>
            <Text fontSize='14px' color='jubelio.primary'>
              Retur
            </Text>
            <Title fontSize='16px' color='jubelio.primary'>
              {currencyFormat(Math.abs(dataPayment?.totalRetur as unknown as number))}
            </Title>
          </VStack>
          <VStack alignItems='flex-start' px='6' borderLeft='1px' borderLeftColor='system.outline'>
            <Text fontSize='14px' color='jubelio.black'>
              Total
            </Text>
            <Title fontSize='16px'>
              {currencyFormat(
                ((dataPayment?.totalSales || 0) -
                  Math.abs(dataPayment?.totalRetur || 0)) as unknown as number
              )}
            </Title>
          </VStack>
        </SimpleGrid>
        <Divider my={4} size='lg' borderColor='system.outline' />
        <Box py='3'>
          <Title>History Transaksi</Title>
          <TablePaymentDetail dataPayment={dataPayment} />
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ModalDetailPaymentClosure);
