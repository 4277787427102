import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import * as React from 'react';

interface PresetPopover {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (value?: boolean) => void;
}

export const QRISDynamic: React.FC<PresetPopover> = ({
  isOpen,
  onOpen,
  onClose,
  onSubmit,
}: PresetPopover) => {
  return (
    <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement='top' closeOnBlur={false}>
      <PopoverTrigger>
        <Button variant='primary' onClick={onOpen}>
          Selesai
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Selesaikan Pembayaran?</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Apa Anda yakin ingin menyelesaikan Pembayaran? Direkomendasikan untuk Foto Bukti Pembayaran
          Pelanggan.
        </PopoverBody>
        <PopoverFooter display='flex' justifyContent='flex-end'>
          <ButtonGroup size='sm'>
            <Button variant='outline' onClick={onClose}>
              Batal
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                onSubmit(true);
                onClose();
              }}
            >
              Ya
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
