import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const FullscreenIcon = (props: IconProps): JSX.Element => (
  <Icon viewBox='0 0 14 14' {...props}>
    <path d='M0 0H5V2H2V5H0V0ZM9 0H14V5H12V2H9V0ZM12 9H14V14H9V12H12V9ZM5 12V14H0V9H2V12H5Z' />
  </Icon>
);

export default FullscreenIcon;
