import { ITEM_ID_TYPES } from 'constant';
import { get } from 'lodash';
import {
  CheckItemList,
  CheckMinQtyFunc,
  CheckPromotionFunc,
  CheckPromotionResp,
  DiscountDetailPromo,
  getChecklistsDiscountRes,
  IGetPromotionData,
  IValidVoucherList,
  ResPromotionTransactionAmount,
} from 'types/promotion.types';

import { getChecklistsDiscount } from '.';

/**
 * @description Check minimal quantity of items with rules of promotion
 *  If promotion is not valid, return false and null for checkLists
 *  If promotion is valid, return true and checkLists with items and multiplier for discount
 *
 * @param promotion - promotion object
 * @param itemCart - item cart object
 * @returns  { status: boolean, checkLists: CheckItemList | null, items: IItemCart[] | null, multiplier: number | null }
 */
const checkMininalQuantity: CheckMinQtyFunc = (promotion, itemCart) => {
  const checkLists: any = {};
  for (const item of promotion.items) {
    const ckey = `${item.id_type}_${item.item_id}`;
    checkLists[ckey] = {
      current: 0,
      expected: item.qty,
      items: [],
    };
  }

  for (const item of itemCart) {
    const pkey = `${ITEM_ID_TYPES.Product}_${item.item_group_id}`;
    const skey = `${ITEM_ID_TYPES.SKU}_${item.item_id}`;
    const ckey = `${ITEM_ID_TYPES.Category}_${item.item_category_id}`;
    if (checkLists[pkey]) {
      checkLists[pkey]['current'] += item.quantity;
      checkLists[pkey]['items'].push(item);
    }
    if (checkLists[skey]) {
      checkLists[skey]['current'] += item.quantity;
      checkLists[skey]['items'].push(item);
    }
    if (checkLists[ckey]) {
      checkLists[ckey]['current'] += item.quantity;
      checkLists[ckey]['items'].push(item);
    }
  }

  let multiplier = null;
  for (const key in checkLists) {
    // all check lists should be passed to apply promotion
    if (checkLists[key]['current'] < checkLists[key]['expected']) {
      return {
        status: false,
        checkLists: null,
        items: null,
        multiplier: 1,
      };
    }
    // get minimal multiplier of all check lists
    const m =
      checkLists[key]['expected'] > 0
        ? Number(checkLists[key]['current'] / checkLists[key]['expected'])
        : 1;
    multiplier = multiplier === null ? m : m < multiplier ? m : multiplier;
  }

  return { status: true, checkLists, multiplier, items: null };
};

export type MinimalQuantityPromotion = {
  promotion_id: number;
  detail: {
    type: string;
    items: number;
  };
};

type FormatMinimalQuantityRes = {
  promotion_id: number;
  detail: {
    type: string;
    items: Array<{
      item_id: number;
      discount: number;
    }>;
  };
};

type FormatMinimalQuantityResV2 = {
  promotion_id: number;
  voucher_code?: string;
  detail: {
    type: string;
    items: Pick<IGetPromotionData, 'discount_reward'>;
  };
};

export const formatMinimalQuantity = (promotion: CheckPromotionResp): FormatMinimalQuantityRes => {
  return {
    promotion_id: promotion.rules.promotion_id,
    detail: {
      type: 'item',
      items: promotion.discountDetails as DiscountDetailPromo,
    },
  };
};

export const formatMinimalQuantityV2 = (
  promotion: ResPromotionTransactionAmount,
  listValidVoucher: IValidVoucherList[]
): FormatMinimalQuantityResV2 => {
  const findVoucher = listValidVoucher.find(
    (v) => v.promotion_id === promotion.promotion.promotion_id
  );

  const result: FormatMinimalQuantityResV2 = {
    promotion_id: promotion.promotion.promotion_id,
    detail: {
      type: 'discount',
      items: {
        discount_reward: promotion.promotion.discount_reward,
      },
    },
  };

  if (findVoucher?.voucher_code) {
    result.voucher_code = findVoucher.voucher_code;
  }
  return result;
};

type getItemsPromotionsDiscount = (promotion: getChecklistsDiscountRes) => {
  discountDetails: DiscountDetailPromo;
  discountItems: number;
};

export const getItemsPromotionsDiscount: getItemsPromotionsDiscount = (promotion) => {
  const discountItems: any = {};
  const discountDetails = [];
  const discount = getChecklistsDiscount(promotion);
  for (const key in discount) {
    for (const item of discount[key].items) {
      let basePrice = Number(item.sell_price);
      if (!promotion.rules.ignore_item_discount)
        basePrice -= get(item, 'pos_slash_price', 0) / item.quantity;

      const item_disc = (basePrice * discount[key].discount) / discount[key].total;
      discountItems[`p_${item.item_id}`] = item_disc;
      discountDetails.push({
        item_id: item.item_id,
        discount: item_disc,
      });
    }
  }

  return { discountItems, discountDetails };
};

export const validateMinimalQuantity = ({ promotion, itemCart }: CheckPromotionFunc): any => {
  const result = checkMininalQuantity(promotion, itemCart);
  if (!result.status) return null;

  const p: getChecklistsDiscountRes = {
    rules: promotion,
    checkLists: result.checkLists as CheckItemList,
    multiplier: result.multiplier as number,
  };

  const { discountItems, discountDetails } = getItemsPromotionsDiscount(p);
  p['itemsDiscountDetails'] = discountItems;
  p['discountDetails'] = discountDetails;
  return p;
};
