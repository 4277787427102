import { IAnnouncement, IPosVersion } from 'types/common.types';
import { IResponseCancel } from 'types/sales.types';

import request from './request';

export default {
  getAnnouncement: async (): Promise<IAnnouncement> => {
    try {
      const res = await request.get('/sales/pos/v2/announcement');
      return Promise.resolve(res.data.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  setShowAnnouncement: async (pos_announcement_id: number): Promise<IResponseCancel> => {
    try {
      const res = await request.post(`/sales/pos/v2/announcement/set-shown`, {
        pos_announcement_id,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getVersionApps: async (): Promise<IPosVersion> => {
    try {
      const res = await request.get('/pos/v3/version');
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
