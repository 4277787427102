import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

const SubTitle: React.FC<TextProps> = ({ children, ...rest }: TextProps) => (
  <Text
    fontWeight='500'
    className='text-gray-500'
    fontSize={['12px', '11px', '12px', '14px', '14px']}
    {...rest}
  >
    {children}
  </Text>
);

export default SubTitle;
