import { Box, Button, Flex } from '@chakra-ui/react';
import BadgeCart from 'components/badge/BadgeCart';
import { CartIcon } from 'components/icons';
import { SubTitle, Title } from 'components/typography';
import { useCommon } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearCartReturn } from 'redux/reducer/return';
import {
  resetOrder,
  setListAllPromotion,
  setListPromotionAmount,
  setListPromotionItem,
  setListValidVoucher,
  updateIsCheckout,
  updateListFreeItemV2,
  updateLoadingCheckout,
} from 'redux/reducer/sales';
import cart from 'services/indexdb/cart';
import { DetailOrderTransaction } from 'types';
import { IItemCart } from 'types/products.types';
import { IItemReturn, IOrderReturn } from 'types/return.types';
import { IOrderData } from 'types/sales.types';

type HeaderProps = {
  listItemCart?: IItemCart[];
  listItemReturn?: IItemReturn[] | null;
  orderReturn?: IOrderReturn;
  order?: IOrderData | DetailOrderTransaction;
  returnMode?: boolean;
  totalSavedCart?: number;
  onOpen?: () => void;
};

const HeaderCart: React.FC<HeaderProps> = ({
  listItemReturn,
  returnMode,
  totalSavedCart,
  order,
  orderReturn,
  onOpen,
}: HeaderProps) => {
  const { disableEditItem } = useCommon();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cartId = useAppSelector((state) => state.sales.cartId);
  const listItemCart = useAppSelector((state) => state.sales.listItemCart);
  const continuePayment = useAppSelector((state) => state.sales.continuePayment);
  const isLoadingCheckout = useAppSelector((state) => state.sales.isLoadingCheckout);

  const resetTransaction = () => {
    dispatch(updateLoadingCheckout(false));
    dispatch(updateIsCheckout(false));
    if (listItemCart?.length > 0 || (listItemReturn && listItemReturn.length > 0)) {
      dispatch(updateLoadingCheckout(false));
      dispatch(updateIsCheckout(false));
      if (returnMode) {
        dispatch(clearCartReturn());
      } else {
        if (cartId) cart.delete(cartId);
        dispatch(resetOrder());
        deletePromotion();
      }
      if (disableEditItem) navigate('/sales');
    }
  };
  const deletePromotion = () => {
    dispatch(setListAllPromotion([]));
    dispatch(setListPromotionAmount([]));
    dispatch(setListPromotionItem([]));
    dispatch(setListValidVoucher([]));
    dispatch(updateListFreeItemV2([]));
  };

  return (
    <Flex alignItems='center' justifyContent='space-between' p={4}>
      <Box>
        <Title fontWeight='bold'>Detail Order</Title>
        {!returnMode && listItemCart.length > 0 ? (
          <SubTitle fontWeight='semibold'>{order?.salesorder_no}</SubTitle>
        ) : null}
        {returnMode && listItemReturn && listItemReturn.length > 0 ? (
          <SubTitle fontWeight='semibold'>{orderReturn?.pos_return_no}</SubTitle>
        ) : null}
      </Box>
      <div className='flex h-full items-center gap-5'>
        {!continuePayment && location.pathname !== '/sales/checkout' ? (
          <BadgeCart
            aria-label='cart'
            counter={Number(totalSavedCart)}
            onClick={onOpen}
            icon={<CartIcon h='20px' w='20px' fill='#8999A5' />}
            isDisabled={isLoadingCheckout}
          />
        ) : null}
        <div className='h-8 border'></div>
        <Button variant='link' color='red.800' onClick={resetTransaction}>
          Reset
        </Button>
      </div>
    </Flex>
  );
};

export default HeaderCart;
