import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import React from 'react';

const BadgeError: React.FC<TagProps> = ({ children, ...rest }: TagProps) => {
  return (
    <Tag {...rest} fontSize='12px' rounded='0.29rem'>
      <TagLabel>{children}</TagLabel>
    </Tag>
  );
};

export default BadgeError;
